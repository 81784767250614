import React, { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { WidgetProps } from '../types';
import Select from '../../base-ui/select';
import { ErrorFormMessage } from '..';

export const ChoiceWidget: React.FC<WidgetProps<string>> = (props) => {
  const { values } = props;

  const form = useFormContext();

  const prepareOptions = useMemo(() => values.choices?.map((elem) => ({ label: elem, value: elem })), [values.choices]);

  const rules = useMemo(() => {
    return {
      required: {
        value: values.required,
        message: 'Обязательное поле',
      },
    };
  }, [props.values.required]);

  return (
    <div>
      <Controller
        name={values.slug}
        control={form.control}
        rules={rules}
        render={({ value, onChange }) => (
          <Select
            label={values.description}
            placeholder={values.placeholder}
            options={prepareOptions}
            onChange={(val) => onChange(val?.label)}
            value={prepareOptions?.find((e) => e.label === value)}
            isSearchable={false}
            menuPosition="fixed"
          />
        )}
      />
      <ErrorFormMessage>
        <ErrorMessage errors={form.errors} name={values.slug} />
      </ErrorFormMessage>
    </div>
  );
};
