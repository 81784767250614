import React, { useCallback, useEffect, useState } from 'react';
import { parseHtml } from 'utils/htmlParser';
import { ArrowButton } from 'components/base-ui/button';
import { BaseModal } from 'components/base-ui/base-modal';
import { Feedback } from 'components/feedback';
import {
  ButtonWrapper,
  Header,
  ModalCloseButton,
  ModalCloseWrapper,
  ModalContainer,
  ModalContentWrapper,
} from './styles';
import { api } from '../../../utils/api';
import { ApplicationFormIntegrationEnum } from '../../../services';

interface ModalProps {
  onClick: () => void;
  header: string;
  content: string;
  showButton: boolean;
}

const Modal: React.FC<ModalProps> = ({ onClick, header, content, showButton }) => {
  const [showForm, setShowForm] = useState(false);
  const [formId, setFormId] = useState(0);
  const handleShowForm = useCallback(() => setShowForm(true), []);
  const handleCloseForm = useCallback(() => setShowForm(false), []);

  useEffect(() => {
    api.applicationForm.applicationFormsList().then((res) => {
      const form = res.data.results.filter((el) => el.integration === ApplicationFormIntegrationEnum.Sale);
      setFormId(form[0].id || 0);
    });
  }, []);

  return (
    <>
      <BaseModal isVisible={showForm} onClose={handleCloseForm}>
        <Feedback formId={formId} />
      </BaseModal>
      <ModalContainer>
        <Header>{header}</Header>
        <ModalCloseWrapper>
          <ModalCloseButton name="iconCrossBaseWhite" onClick={onClick} />
        </ModalCloseWrapper>
        <ModalContentWrapper>{parseHtml(content)}</ModalContentWrapper>
        <ButtonWrapper>
          {showButton && (
            <ArrowButton white gradient onClick={handleShowForm}>
              ПОЛУЧИТЬ СКИДКУ
            </ArrowButton>
          )}
        </ButtonWrapper>
      </ModalContainer>
    </>
  );
};

export default Modal;
