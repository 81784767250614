import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../../../../../../utils/constants';
import { Span } from '../../../../../../base-ui/typo';
import HtmlContainer from '../../../../../../base-ui/html-container';
import { HtmlRenderer } from '../../../../../../../utils/htmlParser';

const Title = styled(Span)`
  display: table-cell;
  font-weight: 400;
  color: ${COLORS.secondary};
  padding-right: 20px;
`;

const Li = styled.li`
  display: table-row;
  margin-bottom: 0;
  &:not(:first-child) > * {
    padding-top: 8px;
  }
`;

const Description = styled(HtmlContainer)`
  display: table-cell;
  color: ${COLORS.gray3};
`;

interface ParametersItemProps {
  title: string;
  description: string;
}

const ParametersItem: React.FC<ParametersItemProps> = ({ title, description }) => {
  return (
    <Li>
      <Title>{title}</Title>

      <Description>
        <HtmlRenderer html={description} />
      </Description>
    </Li>
  );
};

export default ParametersItem;
