import { makeAutoObservable, runInAction } from 'mobx';

import { Nullable } from 'web';

import { api } from 'utils/api';

import { MedicineProgram, MedicineCategoryPage, MedicineCenter } from 'services';
import _ from 'lodash';
import { AxiosError } from 'axios';
import { Block, TravelLineWidgetBlock } from '../../utils/blocks';

export default class MedicineCenterStore {
  fetching = false;

  error: Nullable<Error> = null;

  count = 0;

  page: Nullable<MedicineCategoryPage> = null;

  program: Nullable<MedicineProgram> = null;

  medicineCenter: Nullable<MedicineCenter> = null;

  showTravelLineInProgram = false;

  constructor() {
    makeAutoObservable(this);
  }

  fetchMedicineCenter(id: number, options?: unknown): Promise<void> {
    this.setError(null);
    this.setFetching(true);
    return api.medicineCenters
      .medicineCentersRead({ id }, options)
      .then((res) => {
        runInAction(() => {
          this.setMedicineCenter(res.data);
        });
      })
      .catch((err: AxiosError) => this.setError(err))
      .finally(() => this.setFetching(false));
  }

  fetchProgram(id: number, options?: unknown): Promise<void> {
    this.setError(null);
    this.setFetching(true);
    return api.medicinePrograms
      .medicineProgramsRead({ id }, options)
      .then((res) => {
        runInAction(() => {
          const content = res.data.content as unknown as Block[];
          const travelLineBlock = _.head(
            content.filter((block) => block.type === 'travel_line_widget'),
          ) as TravelLineWidgetBlock;
          this.setProgram(res.data);
          this.setShowTravelLine(travelLineBlock.value);
        });
      })
      .catch((err: AxiosError) => this.setError(err))
      .finally(() => this.setFetching(false));
  }

  setFetching(value: boolean): void {
    this.fetching = value;
  }

  setError(value: Nullable<Error>): void {
    this.error = value;
  }

  setCount(value: number): void {
    this.count = value;
  }

  setProgram(value: MedicineProgram): void {
    this.program = value;
  }

  setMedicineCenter(value: MedicineCenter): void {
    this.medicineCenter = value;
  }

  setShowTravelLine(flag: boolean): void {
    this.showTravelLineInProgram = flag;
  }

  fetchProgramData = async (programId: number, centerId: number): Promise<void> => {
    await this.fetchProgram(programId);
    await this.fetchMedicineCenter(centerId);
  };

  cleanUp = (): void => {
    this.page = null;
    this.program = null;
    this.error = null;
    this.medicineCenter = null;
    this.showTravelLineInProgram = false;
  };
}
