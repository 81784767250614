import React from 'react';
import { DefaultLayout } from 'layouts';
import ScrollToTopOnMount from 'components/base-ui/scroll-to-top';
import Breadcrumbs from 'components/base-ui/breadcrumbs';
import { AboutUs } from 'components/about-us';
import { BreadCrumbsWrapper, Container } from './styled';

function AboutUsPage() {
  return (
    <DefaultLayout>
      <ScrollToTopOnMount />
      <Container>
        <BreadCrumbsWrapper>
          <Breadcrumbs lineStyle={true} white={true} />
        </BreadCrumbsWrapper>
        <AboutUs />
      </Container>
    </DefaultLayout>
  );
}

export default AboutUsPage;
