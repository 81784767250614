import React from 'react';
import { COLORS } from 'utils/constants';

interface IconRatePropsType {
  width?: string;
  height?: string;
  color?: string;
}

export const IconRate: React.FC<IconRatePropsType> = ({ width = '18', height = '17', color = COLORS.primary }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 0L11.751 5.63638L18 6.4936L13.452 10.8341L14.562 17L9 14.0455L3.43725
         17L4.548 10.8341L0 6.4936L6.249 5.63638L9 0Z"
        fill={color}
        fillOpacity="0.8"
      />
    </svg>
  );
};

export const IconEmptyRate: React.FC<IconRatePropsType> = ({ width = '18', height = '17', color = COLORS.gray5 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.99902 2.27986L10.8514 6.075L11.0854 6.55458L11.6141 6.6271L15.8079 7.2024L12.7606 10.1107L12.3716
        10.482L12.4668 11.0113L13.2121 15.1511L9.46814 13.1624L8.99905 12.9132L8.52996 13.1624L4.78543 15.1511L5.53118
        11.0114L5.62654 10.482L5.23743 10.1107L2.19012 7.2024L6.38393 6.6271L6.91262 6.55458L7.14669
        6.075L8.99902 2.27986Z"
        stroke={color}
        strokeWidth="2"
      />
    </svg>
  );
};
