import React, { useCallback, useMemo, useState } from 'react';
import { HtmlRenderer } from 'utils/htmlParser';
import Icon from 'components/base-ui/icon';
import { VideoBox } from 'components/base-ui/video-box';
import { VideoItemType } from 'store/projects/types';
import { useHistory } from 'react-router-dom';
import { Feedback } from 'components/feedback';
import { BaseModal } from 'components/base-ui/base-modal';
import { Nullable } from 'web';
import {
  Wrapper,
  Inner,
  Outer,
  Header,
  Description,
  Content,
  Title,
  ButtonWrapper,
  ButtonVideo,
  ButtonLink,
} from './styled';

type Column = {
  buttonAction: 'link' | 'application_form' | 'video';
  buttonLink: { link: string; text: string };
  dynamicForm: number | null;
  text: string;
  video: VideoItemType;
};

type Props = {
  data: {
    preview: string;
    columns: Column[];
  };
};

export const CreatorsBannerBlock: React.FC<Props> = ({ data }) => {
  const hystory = useHistory();
  const [isLightBoxOpen, setIsLightBoxOpen] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [formId, setFormId] = useState<number | null>(null);
  const [videoData, setVideoData] = useState<Nullable<VideoItemType>>(null);

  const video = useMemo(
    () => data.columns.filter((button) => button.buttonAction === 'video')?.map((item) => item.video),
    [data.columns],
  );

  const onClick = useCallback((column: Column) => {
    if (column.buttonAction === 'link') {
      hystory.push(column.buttonLink.link);
    }

    if (column.buttonAction === 'application_form') {
      if (!column.dynamicForm) return;
      setFormId(column.dynamicForm);
      setShowForm(true);
    }
  }, []);

  const openVideoBox = useCallback(
    (file: VideoItemType) => {
      setVideoData({ ...file, resolutions: file.resolutions.filter((resolution) => resolution.url.length > 0) });
    },
    [data],
  );

  const closeVideoBox = useCallback(() => {
    setVideoData(null);
  }, []);

  return (
    <Wrapper>
      <Outer>
        <Inner />
      </Outer>

      <Content>
        <Header>Программа</Header>

        <Title />

        <Description>
          <HtmlRenderer html={data.preview} />
        </Description>

        <ButtonWrapper>
          {data.columns.map((column) =>
            column.buttonAction === 'video' ? (
              <ButtonVideo onClick={() => openVideoBox(column.video)}>
                {column.text}
                <div style={{ width: '24px', height: '24px' }}>
                  <Icon name="iconArrowWhite" />
                </div>
              </ButtonVideo>
            ) : (
              <ButtonLink onClick={() => onClick(column)}>
                {column.text}
                <div style={{ width: '24px', height: '24px' }}>
                  <Icon name="iconArrowWhite" />
                </div>
              </ButtonLink>
            ),
          )}
        </ButtonWrapper>
      </Content>

      <VideoBox videos={video} onClose={closeVideoBox} isLightBoxOpen={!!videoData} video={videoData ?? undefined} />

      <BaseModal isVisible={showForm} onClose={() => setShowForm(false)}>
        <Feedback formId={formId} />
      </BaseModal>
    </Wrapper>
  );
};
