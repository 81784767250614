import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { WarningPopup } from 'components/base-ui/warning-popup';
import { useCartStore, useCategoryStore } from 'store';
import { useDeviceType } from 'utils/hooks';
import { Div, Section } from 'components/cart/styles';
import { MapRenderer } from 'components/renderers/map';
import Breadcrumbs from 'components/base-ui/breadcrumbs';
import BaseSection from 'components/base-ui/section';
import Addresses from 'components/base-ui/addresses';
import Bag from './bag';
import UserInfo from './user-info';

const Cart: React.FC = observer(() => {
  const deviceType = useDeviceType();
  const store = useCartStore();
  const categoryStore = useCategoryStore();

  useEffect(() => store.fetchCart(), [store]);
  useEffect(() => categoryStore.fetchCategoryList(), [categoryStore]);
  useEffect(() => store.fetchErrorScreen(), []);

  const isTablet = useMemo(() => deviceType === 'tablet', [deviceType]);

  return (
    <Div>
      <Section>
        <Breadcrumbs />
      </Section>

      <Section>
        <Bag cartData={store.cartProducts} />
      </Section>

      {store.errorScreen && (
        <WarningPopup
          header={store.errorScreen?.title || ''}
          link={store.errorScreen?.detail || ''}
          description={store.errorScreen?.content || ''}
          handleCloseErrorScreen={store.closeErrorScreen}
        />
      )}

      {Object.keys(store.gropedCart).map((categoryId) => {
        const [category] = categoryStore.categoryList.filter((c) => c.id === +categoryId);

        return category ? (
          <>
            <BaseSection heading="Данные пользователя" marginless paddinglessHead={isTablet}>
              <UserInfo categoryId={+categoryId} />
            </BaseSection>

            {categoryId && (
              <BaseSection heading="Адреса" borderless marginless paddingless>
                <Addresses categoryId={+categoryId} />
              </BaseSection>
            )}

            <Section>
              <MapRenderer mapFrame={category.mapFrame} />
            </Section>
          </>
        ) : null;
      })}
    </Div>
  );
});

export default Cart;
