import styled from 'styled-components';
import { BREAKPOINTS } from 'utils/constants';

const Container = styled.div`
  display: flex;
  gap: 24px;
  padding: 32px 16px 0px 16px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 32px 72px 0px 72px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding: 40px 162px 0px 162px;
  }
`;

export const Styled = { Container };
