import React, { useCallback } from 'react';

import { NewsItemProps } from 'web';

import { useHistory } from 'react-router-dom';

import { formatDate } from 'utils/date';

import { HtmlRenderer } from 'utils/htmlParser';
import { Styled } from './styles';

interface NewsCardProps {
  data: NewsItemProps;
}

export const NewsCard: React.FC<NewsCardProps> = ({ data }) => {
  const history = useHistory();

  const cardClickHandler = useCallback(() => {
    history.push(`/news/${data.id}`);
  }, [data.id]);

  return (
    <Styled.Wrapper onClick={cardClickHandler}>
      <Styled.ImgWrapper>
        <img alt={data.paragraph?.header} src={data.mainPageImage} />
      </Styled.ImgWrapper>

      <Styled.Content>
        <Styled.NewsCardDate>{formatDate(data.date)}</Styled.NewsCardDate>
        <Styled.Title>
          <HtmlRenderer html={data.paragraph?.header || ''} />
        </Styled.Title>
      </Styled.Content>
    </Styled.Wrapper>
  );
};
