import React, { useCallback, useState } from 'react';
import Avatar from 'components/base-ui/avatar';
import DEFAULT_SRC from 'assets/img/assol_avatar.png';
import { useProfileStore } from 'store/profile';
import { ReplyArrowButton, ReplyTextArea, ReplyWrapper } from './styles';

interface Props {
  isSub?: boolean;
  toName?: string;
  onAddNewComment: (comment: string) => void;
}

export const AddNewComment: React.FC<Props> = (props) => {
  const { profile } = useProfileStore();

  const [newComment, setNewComment] = useState('');

  React.useEffect(() => {
    if (props.toName) {
      setNewComment(`${props.toName}, `);
    }
  }, []);

  const onClick = useCallback(() => {
    props.onAddNewComment(newComment);
    setNewComment('');
  }, [newComment]);

  const onChangeComment = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNewComment(e.currentTarget.value);
  }, []);

  return (
    <ReplyWrapper>
      <Avatar src={profile?.image || DEFAULT_SRC} size="small" />
      <ReplyTextArea
        isSub={props.isSub}
        placeholder={props.isSub ? 'Напишите ответ...' : 'Напишите комментарий...'}
        value={newComment}
        onChange={onChangeComment}
        autoFocus
      />
      {newComment.length > 0 && (
        <ReplyArrowButton isSub={props.isSub} onClick={onClick}>
          Отправить
        </ReplyArrowButton>
      )}
    </ReplyWrapper>
  );
};
