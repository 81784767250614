import React, { useCallback } from 'react';
import { COLORS } from 'utils/constants';
import { IconViews } from 'components/base-ui/icon-views';
import Icon from 'components/base-ui/icon';
import { MagazineArticle } from 'services';
import { useProfileStore, useProjectsStore } from 'store';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Button,
  ButtonText,
  Container,
  Content,
  Count,
  Description,
  Footer,
  FooterIconsWrapper,
  FooterItem,
  Header,
  ImageWrapper,
} from './styles';
import { api } from '../../../../utils/api';
import { IconLikeWithTooltip } from '../../../base-ui/icon-like-with-tooltip';
import { useDeviceType } from '../../../../utils/hooks';

export const SmallCard: React.FC<(MagazineArticle & { onShowModal: (id: number) => void }) | null> = ({
  header,
  likesCount,
  description,
  image,
  isLikedByUser,
  isInUserFavorites,
  id,
  ...rest
}) => {
  const store = useProjectsStore();
  const profileStore = useProfileStore();
  const history = useHistory();
  const location = useLocation();
  const device = useDeviceType();
  const articleRegexp = /^\/(magazines)\/[0-9]+$/;

  const onReadClick = useCallback(
    (ev: React.MouseEvent<HTMLElement>) => {
      ev.stopPropagation();
      if (location?.pathname.match(articleRegexp)) {
        history.push(`${location.pathname}/${id}`);
      } else {
        api.projects.projectsMagazinesArticlesRead({ id: id || 0 }).then((res) => {
          history.push(`/projects/magazines/${res.data.magazine.id}/${id}`);
        });
      }
    },
    [id],
  );

  const onLikeClick = useCallback(() => {
    if (id) {
      isLikedByUser
        ? store.handleLikeDelete(id, profileStore.profile?.id!)
        : store.handleLikePost(id, profileStore.profile);
    }
  }, [id, isLikedByUser]);

  return (
    <Container visible={!!id}>
      <ImageWrapper onClick={onReadClick} image={image || ''} />
      <Content>
        <Header onClick={onReadClick}>{header}</Header>
        <Description onClick={onReadClick}>{description}</Description>
        <Footer>
          <FooterIconsWrapper>
            <IconLikeWithTooltip
              isLiked={!!isLikedByUser}
              onClick={onLikeClick}
              likedUsers={rest.likeAuthors || []}
              likesCount={likesCount || 0}
              newsId={id!}
              onShowModal={() => rest.onShowModal(id!)}
            />
            <FooterItem>
              <IconViews height="24px" width="24px" color={COLORS.gray4} />
            </FooterItem>
            <Count>{rest.viewsCount}</Count>
          </FooterIconsWrapper>
          <FooterIconsWrapper>
            <Button onClick={onReadClick}>
              <ButtonText>читать</ButtonText>
              <Icon name="iconArrowGrey" />
            </Button>
          </FooterIconsWrapper>
        </Footer>
      </Content>
    </Container>
  );
};
