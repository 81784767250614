import React, { useCallback, useMemo } from 'react';
import { useNewsCommentsStore } from 'store';
import { observer } from 'mobx-react-lite';
import { Container, Like, LikeIcon, ReplyButton } from './styles';
import { useGuideCommentsStore } from '../../../../../store/guide-comments';

interface Props {
  visible: boolean;
  onReply: () => void;
  likesCount?: number;
  commentId?: number;
  parentCommentId?: number | null;
  isLikedByUser?: boolean;
  isSub?: boolean;
}

export const CommentFooter: React.FC<Props> = observer((props) => {
  const guideCommentsStore = useGuideCommentsStore();

  const replyButtonText = useMemo(() => (props.visible ? 'Промолчать' : 'Ответить'), [props.visible]);

  const onLike = useCallback(() => {
    if (props.commentId) {
      props.isLikedByUser
        ? guideCommentsStore.toggleLikeDelete(props.commentId, props.isSub ? props.parentCommentId : undefined)
        : guideCommentsStore.toggleLikePost(props.commentId, props.isSub ? props.parentCommentId : undefined);
    }
  }, [props.isLikedByUser, props.commentId, props.parentCommentId]);

  return (
    <Container>
      <ReplyButton onClick={props.onReply}>{replyButtonText}</ReplyButton>
      <Like role="button" onClick={onLike}>
        <LikeIcon name="iconThumb" />
        {props.likesCount ?? 0}
      </Like>
    </Container>
  );
});
