import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';
import { AdjustableButton } from '../../../base-ui/button';

interface ContainerProps {
  direction?: 'column' | 'row';
  justify?: 'center' | 'flex-start' | 'flex-end' | 'space-around' | 'space-between' | 'space-evenly';
  gap?: string;
}

const Container = styled.div<ContainerProps>`
  width: 100%;
  display: flex;
  padding: 24px;
  gap: ${({ gap }) => gap};
  flex-direction: ${({ direction = 'row' }) => direction};
  justify-content: ${({ justify }) => justify};

  background: ${COLORS.gray6};
  border-radius: 2px;

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: 432px;
  }
`;

const CurrencyContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  align-items: center;
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Title = styled.span`
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;

  color: ${COLORS.gray3};

  & p {
    margin: 0 0 0.2em 0;
  }
`;

const CurrencyTitle = styled.span`
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;

  color: ${COLORS.gray2};
`;

const CurrencyText = styled.span<{ through?: boolean }>`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  ${({ through }) => through && 'text-decoration-line: line-through'};

  color: ${COLORS.gray3};
`;

const Description = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: ${COLORS.gray2};
`;

const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  svg {
    width: 24px;
    height: 24px;
    & * {
      fill: ${COLORS.secondary};
    }
  }
`;

const OrderButton = styled(AdjustableButton)`
  width: auto;
  align-self: flex-start;

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    align-self: flex-end;
  }
`;

const FeedbackButton = styled(AdjustableButton)`
  width: 100%;
  align-self: flex-start;
  @media screen and (min-width: ${BREAKPOINTS.mobile}) {
    width: auto;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: 100%;
  }
`;

const PriceInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const Styled = {
  Title,
  IconWrapper,
  Container,
  Description,
  CurrencyTitle,
  CurrencyContainer,
  CurrencyText,
  FeedbackButton,
  OrderButton,
  PriceInfoContainer,
};
