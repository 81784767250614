import styled from 'styled-components';
import { BREAKPOINTS } from 'utils/constants';

export const Wrapper = styled.div`
  display: none;
  width: 100%;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    display: block;
    width: 100%;
    padding: 50px 32px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding: 100px 125px 50px 125px;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
`;
