import styled, { css } from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';
import { OverFlowScrollbar } from 'styles/global';
import { IconSvg } from 'assets/icons';

const Container = styled.div<{ height?: number }>`
  display: flex;
  flex-direction: column;
  gap: 24px;
  ${(props) => css`
    max-height: ${props.height ? `${props.height}px` : '340px'};
  `};
  flex-grow: 1;
`;

const Header = styled.span<{ expanded?: boolean; collapsable?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${(props) =>
    props.collapsable &&
    css`
      cursor: pointer;
    `};
`;

const Subtitle = styled.span`
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-size: 24px;
    line-height: 32px;
  }
`;

const Participants = styled.div`
  ${OverFlowScrollbar};
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow-y: auto;
  height: 100%;
  margin-top: -12px;
  padding-right: 16px;

  ::-webkit-scrollbar-track {
    border: 1px solid ${COLORS.white};
  }
`;

const IconDown = styled(IconSvg.CrossDownSmall)`
  width: 24px;
  height: 24px;
  transition: all 0.3s ease-out;
  & * {
    fill: ${COLORS.secondary};
  }
  &.expanded {
    transform: rotate(180deg);
  }
  &.collapsed {
    transform: rotate(0deg);
  }
`;

export const Styled = { Container, Subtitle, Participants, Header, IconDown };
