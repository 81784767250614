import styled from 'styled-components';
import { BREAKPOINTS } from 'utils/constants';

export const Container = styled.div<{ bg: string }>`
  height: 349px;
  background-image: url(${(props) => props.bg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: right;
  background-position-y: bottom;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    height: 464px;
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    height: 848px;
  }
`;
