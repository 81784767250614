import React from 'react';
import iconAlertCircle from 'assets/icons/icon_alert_circle_red.svg';
import { WarningContainer, Wrapper } from './styles';

export function Warning() {
  return (
    <Wrapper>
      <img width={20} height={20} src={iconAlertCircle} alt="Ошибка!" />
      <WarningContainer>Пожалуйста, заполните все поля перед оформлением заказа</WarningContainer>
    </Wrapper>
  );
}
