import React, { createContext, useContext } from 'react';

import MediaProductsStore from './store';

const store = new MediaProductsStore();

const MediaProductsStateContext = createContext<MediaProductsStore>(store);

export const MediaProductsStateProvider: React.FC = ({ children }) => (
  <MediaProductsStateContext.Provider value={store}>{children}</MediaProductsStateContext.Provider>
);

export const useMediaProductsStore = (): MediaProductsStore => useContext(MediaProductsStateContext);
