import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useCategoryStore } from 'store';
import { BREAKPOINTS } from 'utils/constants';
import { Block } from 'utils/blocks';
import { FrameAdaptiveSize } from 'web';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import Breadcrumbs from 'components/base-ui/breadcrumbs';
import { BlocksRender } from 'components/renderers';

const Div = styled.div`
  padding: 56px 0 58px 0;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 56px 0 80px 0;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding: 56px 136px 124px 136px;
  }
`;

const Section = styled.section`
  width: 100%;
`;

const BreadcrumbsSection = styled(Section)`
  @media screen and (min-width: ${BREAKPOINTS.tablet}) and (max-width: calc(${BREAKPOINTS.laptop} - 1px)) {
    padding: 0 56px;
  }
`;

const GoodsList: React.FC = observer(() => {
  const store = useCategoryStore();
  const { trackPageView } = useMatomo();

  const { categoryId } = useParams<{ categoryId: string }>();
  useEffect(() => {
    store.fetchCategory({ id: parseInt(categoryId) });
    document.title = store.category?.name ?? 'Маркет';
  }, [categoryId, store.category?.name]);

  useEffect(() => {
    if (
      store.category?.name?.toLowerCase() !== 'маркет' &&
      document.title.toLowerCase() !== 'отдых' &&
      document.title.toLowerCase() !== 'культура' &&
      document.title.toLowerCase() !== 'сообщества'
    ) {
      trackPageView({});
    }
  }, [store.category?.name]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    const iFrameBlockSizeArray = store?.category?.page?.content
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      .filter((item) => item.type === 'iframe_size')
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
      .map((item) => item.value) as unknown as FrameAdaptiveSize[];
    if (iFrameBlockSizeArray && iFrameBlockSizeArray.length > 0) {
      store.setIframeSize(iFrameBlockSizeArray);
    } else {
      store.setIframeSize([]);
    }
  }, [store.category?.page]);

  const handleCloseErrorScreen = useCallback(
    () => store.closeErrorScreen({ id: parseInt(categoryId) }),
    [store, categoryId],
  );

  return (
    <Div>
      <BreadcrumbsSection>
        <Breadcrumbs />
      </BreadcrumbsSection>

      {store.category?.page?.content && (
        <BlocksRender
          blocks={store.category.page.content as unknown as Block[]}
          loading={store.fetching}
          options={{ productType: 'goods' }}
          errorScreen={store.category?.errorScreen ?? undefined}
          closeErrorScreenCallBack={handleCloseErrorScreen}
        />
      )}
    </Div>
  );
});

export default GoodsList;
