import React from 'react';
import { SmallHeaderType } from 'store/projects/types';
import { Body, Container, Header } from './styles';

interface SmallBannerProps {
  smallBannerData: SmallHeaderType;
}

export const SmallBanner: React.FC<SmallBannerProps> = ({ smallBannerData }) => {
  return (
    <Container>
      <Header>{smallBannerData.header}</Header>
      <Body>{smallBannerData.description}</Body>
    </Container>
  );
};
