import { makeAutoObservable, runInAction } from 'mobx';

import { Nullable } from 'web';

import { api } from 'utils/api';

import {
  MedicineProgram,
  MedicineCenter,
  MedicineProgramsApiMedicineProgramsListRequest,
  MedicineCategoryPage,
  MedicineDirection,
  MedicineDirectionsApiMedicineDirectionsListRequest,
} from 'services';
import { ExtendedMedicineDirection } from 'components/medicine/types';
import { AxiosError } from 'axios';

export default class MedicineStore {
  fetching = false;

  error: Nullable<Error> = null;

  count = 0;

  page: Nullable<MedicineCategoryPage> = null;

  programs: MedicineProgram[] = [];

  medicineCenters: MedicineCenter[] = [];

  medicineDirections: ExtendedMedicineDirection[] = [];

  activeDirections: Set<number> = new Set<number>();

  constructor() {
    makeAutoObservable(this);
  }

  fetchMainPage(): Promise<void> {
    this.setError(null);
    this.setFetching(true);
    return api.medicinePage
      .midicinePageList()
      .then((res) => {
        runInAction(() => {
          this.setPage(res.data);
        });
      })
      .catch((err: AxiosError) => this.setError(err))
      .finally(() => this.setFetching(false));
  }

  fetchMedicineCenters(props?: MedicineProgramsApiMedicineProgramsListRequest, options?: unknown): Promise<void> {
    this.setError(null);
    this.setFetching(true);
    return api.medicineCenters
      .medicineCentersList(props, options)
      .then((res) => {
        runInAction(() => {
          this.setCount(res.data.count);
          this.setMedicineCenters(res.data.results);
        });
      })
      .catch((err: AxiosError) => this.setError(err))
      .finally(() => this.setFetching(false));
  }

  fetchMedicineDirections(
    props?: MedicineDirectionsApiMedicineDirectionsListRequest,
    options?: unknown,
  ): Promise<void> {
    this.setError(null);
    this.setFetching(true);
    return api.medicineDirections
      .medicineDirectionsList(props, options)
      .then((res) => {
        runInAction(() => {
          this.setCount(res.data.count);
          this.setMedicineDirections(res.data.results);
        });
      })
      .catch((err: AxiosError) => this.setError(err))
      .finally(() => this.setFetching(false));
  }

  fetchPrograms(props?: MedicineProgramsApiMedicineProgramsListRequest, options?: unknown): Promise<void> {
    this.setError(null);
    this.setFetching(true);
    return api.medicinePrograms
      .medicineProgramsList(props, options)
      .then((res) => {
        runInAction(() => {
          this.setCount(res.data.count);
          this.setPrograms(res.data.results);
        });
      })
      .catch((err: AxiosError) => this.setError(err))
      .finally(() => this.setFetching(false));
  }

  setFetching(value: boolean): void {
    this.fetching = value;
  }

  setError(value: Nullable<Error>): void {
    this.error = value;
  }

  setCount(value: number): void {
    this.count = value;
  }

  setPage(value: MedicineCategoryPage): void {
    this.page = value;
  }

  setPrograms(value: MedicineProgram[]): void {
    this.programs = value;
  }

  setMedicineCenters(value: MedicineCenter[]): void {
    this.medicineCenters = value;
  }

  setMedicineDirections(value: MedicineDirection[]): void {
    this.medicineDirections = value;
  }

  toggleDirections(id: number): void {
    const isActive = this.activeDirections.has(id);
    this.medicineDirections = this.medicineDirections.map((item) => {
      return item.id === id ? { ...item, active: !isActive } : item;
    });
    if (isActive) {
      this.activeDirections.delete(id);
    } else {
      this.activeDirections.add(id);
    }

    this.fetchPrograms({
      directionsId: Array.from(this.activeDirections) as unknown as number,
    }).catch((err: AxiosError) => this.setError(err));
  }

  fetchMainPageData = (): void => {
    this.fetchPrograms().catch((err: AxiosError) => this.setError(err));
    this.fetchMainPage().catch((err: AxiosError) => this.setError(err));
    this.fetchMedicineCenters().catch((err: AxiosError) => this.setError(err));
    this.fetchMedicineDirections().catch((err: AxiosError) => this.setError(err));
  };

  cleanUp = (): void => {
    this.page = null;
    this.programs = [];
    this.medicineCenters = [];
    this.medicineDirections = [];
    this.activeDirections = new Set<number>();
    this.error = null;
  };
}
