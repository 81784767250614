import React, { useMemo, useState } from 'react';
import { VideoItemType } from 'store/projects/types';
import ReactPlayer from 'react-player';
import { observer } from 'mobx-react-lite';
import { Container } from 'components/base-ui/container';
import { IconSvg } from 'assets/icons';
import { useDeviceState } from 'hooks/useDeviceProvider';
import { PlayIcon } from 'components/renderers/assol-award/galery/video-list/styles';
import { VideoGalleryStore } from '../model';
import { Styled } from '../styles';
import { Duration } from '../Duration';
import { SettingsDropdown } from '../SettingsDropdown';
import { useControlsMethods } from './useControlsMethods';

const SLIDER_MAX = 1;
const SLIDER_MIN = 0;
const VOLUME_STEP = 0.01;
const SEEK_STEP = 0.001;

interface ControlsProps {
  video: VideoItemType;
  player: ReactPlayer;
  visible: boolean;
  toggleFullScreen: () => void;
  onClose: () => void;
  isFullscreen: boolean;
}

const platform = navigator.platform;

export const Controls = observer<ControlsProps>((props) => {
  const store = VideoGalleryStore();
  const [showSettings, setShowSettings] = useState(false);
  const { isLessTablet } = useDeviceState();

  const isIPhone = useMemo(() => platform.includes('iPhone'), []);

  const {
    handleVolumeChange,
    handleSeekChange,
    handleSeekMouseUp,
    handleSeekMouseDown,
    handleOnPlayClick,
    toggleMute,
    handleToggleShowSettings,
  } = useControlsMethods(store, props.player, setShowSettings);

  const playingIcon = useMemo(() => {
    return store.playing ? <IconSvg.Pause /> : <IconSvg.Play />;
  }, [store.playing]);

  const volumeIcon = useMemo(() => {
    return store.muted ? <IconSvg.Mute onClick={toggleMute} /> : <IconSvg.Volume onClick={toggleMute} />;
  }, [store.muted]);

  return (
    <>
      <Styled.ControlContainer className={props.visible ? 'visible' : undefined}>
        <Styled.Slider
          min={SLIDER_MIN}
          max={SLIDER_MAX}
          step={SEEK_STEP}
          value={store.played}
          onBeforeChange={handleSeekMouseDown}
          onChange={handleSeekChange}
          onAfterChange={handleSeekMouseUp}
        />

        <Styled.BottomLineContainer>
          <Container gap="24px">
            {!isLessTablet && <Styled.IconButton onClick={handleOnPlayClick}>{playingIcon}</Styled.IconButton>}
            <Container gap="8px">
              <Styled.IconButton>{volumeIcon}</Styled.IconButton>
              {!isLessTablet && (
                <Styled.Slider
                  width="60px"
                  min={SLIDER_MIN}
                  max={SLIDER_MAX}
                  step={VOLUME_STEP}
                  value={store.volume}
                  onChange={handleVolumeChange}
                />
              )}
            </Container>

            <Styled.DurationContainer>
              <Duration seconds={(props.player?.getDuration() ?? 0) * store.played} />
              <span>/</span>
              <Duration seconds={props.player?.getDuration() ?? 0} />
            </Styled.DurationContainer>
          </Container>
          <SettingsDropdown
            visible={showSettings}
            onVisibleChange={setShowSettings}
            resolutions={props.video?.resolutions}
          >
            <Container gap="24px" align="center">
              <Styled.IconButton onClick={handleToggleShowSettings}>
                <IconSvg.Settings />
              </Styled.IconButton>

              {!isIPhone && (
                <Styled.IconButton onClick={props.toggleFullScreen}>
                  {props.isFullscreen ? <IconSvg.Downsize /> : <IconSvg.Upsize />}
                </Styled.IconButton>
              )}
            </Container>
          </SettingsDropdown>
        </Styled.BottomLineContainer>
      </Styled.ControlContainer>
      {isLessTablet && props.visible && <PlayIcon onClick={handleOnPlayClick}>{playingIcon}</PlayIcon>}
    </>
  );
});
