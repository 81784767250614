import React from 'react';
import styled from 'styled-components';

import Header from '../../components/base-ui/header';
import Main from '../../components/base-ui/main';
import Footer from '../../components/base-ui/footer';
import { PrintLessWrap } from '../../components/base-ui/print-less';
import { BREAKPOINTS } from '../../utils/constants';

const Div = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  width: 100%;
  height: 100%;
  max-width: inherit;
  min-height: inherit;
  position: relative;
  overflow-x: hidden;
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    overflow-x: unset;
  }
`;

const DefaultLayout: React.FC = ({ children }) => {
  return (
    <Div>
      <PrintLessWrap isHeader>
        <Header />
      </PrintLessWrap>

      <Main>{children}</Main>

      <PrintLessWrap>
        <Footer />
      </PrintLessWrap>
    </Div>
  );
};

export default DefaultLayout;
