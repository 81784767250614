import styled from 'styled-components';
import { BREAKPOINTS } from 'utils/constants';

export const StContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 60px;
  margin: 100px auto;
  overflow-x: hidden;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin: 110px auto 120px auto;
  }

  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    margin: 140px auto;
  }

  @media screen and (min-width: ${BREAKPOINTS.wideDesktop}) {
    margin: 140px auto 114px auto;
  }
`;
