import styled, { css } from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';
import { H5 } from 'components/base-ui/typo';
import IconButton from 'components/base-ui/icon-button';
import { ArrowButton, TextButton } from 'components/base-ui/button';

interface FormProps {
  visible?: boolean;
}

export const visibleStyles = css`
  opacity: 1;
  visibility: visible;
`;
export const hiddenStyles = css`
  opacity: 0;
  visibility: hidden;
`;

export const Form = styled.form<FormProps>`
  width: 100%;
  position: relative;
  background-color: ${COLORS.white};
  display: ${(props) => (!props.visible ? 'none' : null)};
`;

export const Head = styled.div`
  display: flex;
  margin-bottom: 32px;
  align-items: flex-start;
  justify-content: space-between;
`;
export const Title = styled(H5)`
  font-weight: 400;
  color: ${COLORS.primary};
`;
export const CloseButton = styled(IconButton)`
  filter: invert(53%) sepia(7%) saturate(766%) hue-rotate(176deg) brightness(126%) contrast(59%);

  &:hover,
  &:focus,
  &:active {
    filter: none;
  }
`;
export const RatingWrapper = styled.div`
  padding-top: 76px;
`;
export const ResetWrapper = styled.div`
  padding-top: 30px;
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding-top: 53px;
    position: absolute;
  }
`;
export const ResetButton = styled(TextButton)`
  font-size: 12px;
  line-height: 16px;
  color: ${COLORS.primary};

  &:hover,
  &:focus,
  &:active {
    color: ${COLORS.secondary};
  }
`;
export const SubmitButton = styled(ArrowButton)`
  width: 290px;
`;
export const Body = styled.div`
  margin-bottom: 56px;
`;
export const FilterColumns = styled.div<{ height?: number }>`
  & > * {
    padding-top: 32px;
  }

  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  overflow: hidden;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    column-gap: 16px;
    ${(props) =>
      props.height &&
      css`
        height: ${props.height + 42}px;
      `}
    & > * {
      width: calc((100% - 30px) / 2);
    }
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    column-gap: 128px;

    & > * {
      width: calc((100% - 270px) / 3);
    }
  }
`;

export const FilterAside = styled.div<{ elHeight?: number }>``;

export const CheckboxContainer = styled.div`
  display: flex;
`;

export const CheckboxWrapper = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: ${COLORS.gray};
  margin: 10px 5px 5px 0;
`;
