import { Nullable } from 'web';
import { action, makeObservable, observable } from 'mobx';
import AbstractStore from '../AbstractStore';
import { BasePage } from '../../services';
import { api } from '../../utils/api';

export default class BasePageStore extends AbstractStore {
  page: Nullable<BasePage> = null;

  constructor() {
    super();
    makeObservable(this, {
      ...this.annotations,
      page: observable,
      setPage: action,
      fetchPage: action,
    });
  }

  fetchPage = (pageId: number) => {
    this.fetchData(() => api.basePage.basePageRead({ id: pageId }).then((res) => this.setPage(res.data)));
  };

  setPage(page: BasePage) {
    this.page = page;
  }
}
