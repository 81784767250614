import styled, { css } from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';
import { OverFlowScrollbar } from 'styles/global';

const FontStyles = css`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-size: 16px;
    line-height: 24px;
  }
`;

const SettingsPopper = styled.div`
  border-radius: 4px;
  color: ${COLORS.white};
  background: ${COLORS.gray1};
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  visibility: hidden;
  z-index: 3;

  &.visibly {
    visibility: visible;
  }
`;

const PopperButton = styled.button<{ isMain?: boolean }>`
  ${FontStyles};
  padding: ${(props) => (props.isMain ? '7px 20px' : '7px 10px')};
  display: flex;
  gap: 10px;
  background: transparent;
  color: ${COLORS.white};
  width: 100%;
  border: none;
  outline: none;
  cursor: pointer;
  transition: background-color 300ms cubic-bezier(0.645, 0.045, 0.355, 1);

  @media (hover: hover) and (pointer: fine) {
    :hover {
      background: ${COLORS.secondary};
    }
  }
`;

const Title = styled.div`
  padding: 0 20px 8px 10px;
  gap: 10px;
  display: flex;
  color: ${COLORS.white};
  width: 100%;
  border-bottom: solid 1px ${COLORS.gray3};
  margin-bottom: 8px;

  svg {
    width: 16px;
    height: 16px;
  }
`;

const TitleText = styled.div`
  ${FontStyles};
  display: flex;
  align-self: center;
  justify-self: center;
  color: ${COLORS.white};
`;

const Value = styled.span`
  color: ${COLORS.gray5};
  font-size: 12px;
  line-height: 16px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const SelectedValue = styled.span`
  color: ${COLORS.gray4};
`;

const ContentWrapper = styled.div`
  ${OverFlowScrollbar};

  ::-webkit-scrollbar-track {
    border: 1px solid ${COLORS.gray1};
  }

  color: ${COLORS.gray4};
  max-height: 60px;
  overflow-y: auto;

  @media screen and (min-width: ${BREAKPOINTS.mobile}) {
    max-height: 90px;
  }
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    max-height: unset;
  }
`;

const IconButton = styled.button<{ size?: number }>`
  cursor: pointer;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: unset;
  padding: 0;
  margin: 0;

  & svg {
    width: ${(props) => props.size ?? 24}px;
    height: ${(props) => props.size ?? 24}px;
    fill: ${COLORS.white};
  }

  &:disabled {
    cursor: default;
    pointer-events: none;
  }

  &:focus {
    outline: none;
  }
`;

export const Styled = {
  Title,
  TitleText,
  PopperButton,
  SettingsPopper,
  IconButton,
  Value,
  SelectedValue,
  ContentWrapper,
};
