import React, { useCallback, useMemo, useState } from 'react';

import { useCarousel, useDeviceType } from 'utils/hooks';
import Icon from 'components/base-ui/icon';
import { Lightbox } from 'components/base-ui/lightbox';
import { PhotoCarouselProps } from './types';
import { Container, Item, Image, CarouselBtnRight, CarouselBtnLeft } from './styles';

const VISIBLE_LENGTH = 3;

export const PhotoCarousel: React.FC<PhotoCarouselProps> = ({ data }) => {
  const { list, decrement, increment } = useCarousel(data);

  const deviceType = useDeviceType();
  const isMobile = useMemo(() => deviceType === 'mobile', [deviceType]);
  const isTablet = useMemo(() => deviceType === 'tablet', [deviceType]);

  const visibleItems = useMemo(() => (isMobile || isTablet ? data.length : VISIBLE_LENGTH), [isMobile, isTablet, data]);

  const itemsToShow = useMemo(() => list.slice(0, visibleItems), [list, visibleItems]);

  const [isLightBoxOpen, setIsLightBoxOpen] = useState(false);
  const [lightBoxItem, setLightBoxItem] = useState<string[]>([]);

  const handleToggleLightBox = useCallback(
    (id?: string) => {
      const allItems = data.map((item) => item.url);
      setLightBoxItem(allItems);
      const currentItem = itemsToShow.filter((item) => item.id === id).map((item) => item.url)[0];
      const indexCurrentItem = allItems.indexOf(currentItem);
      if (indexCurrentItem && indexCurrentItem !== 0) {
        const itemsBeforeCurrent = allItems.slice(0, indexCurrentItem);
        const itemsAfterCurrent = allItems.slice(indexCurrentItem + 1, allItems.length);
        setLightBoxItem([currentItem, ...itemsAfterCurrent, ...itemsBeforeCurrent]);
      }
      setIsLightBoxOpen((prevState) => !prevState);
    },
    [itemsToShow],
  );

  return (
    <Container>
      {!isMobile && !isTablet && (
        <CarouselBtnLeft onClick={decrement}>
          <Icon name="iconArrowBlack" />
        </CarouselBtnLeft>
      )}

      {itemsToShow.map((el) => (
        <Item key={el.id} hidden={false} onClick={() => handleToggleLightBox(el.id)}>
          <Image src={el.url} />
        </Item>
      ))}

      {!isMobile && !isTablet && (
        <CarouselBtnRight onClick={increment}>
          <Icon name="iconArrowBlack" />
        </CarouselBtnRight>
      )}
      <Lightbox
        showLightBox={isLightBoxOpen}
        toggler={isLightBoxOpen}
        sources={lightBoxItem}
        handleClose={handleToggleLightBox}
      />
    </Container>
  );
};
