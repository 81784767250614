import React from 'react';
import { COLORS } from 'utils/constants';

interface IconViewsPropsType {
  width?: string;
  height?: string;
  color?: string;
}

export const IconViews: React.FC<IconViewsPropsType> = ({ width = '22', height = '15', color = COLORS.white }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 2C14.79 2 18.17 4.13 19.82 7.5C18.17 10.87 14.79 13 11 13C7.21 13 3.83 10.87 2.18 7.5C3.83
        4.13 7.21 2 11 2ZM11 0C6 0 1.73 3.11 0 7.5C1.73 11.89 6 15 11 15C16 15 20.27 11.89 22 7.5C20.27 3.11
        16 0 11 0ZM11 5C12.38 5 13.5 6.12 13.5 7.5C13.5 8.88 12.38 10 11 10C9.62 10 8.5 8.88 8.5 7.5C8.5 6.12
        9.62 5 11 5ZM11 3C8.52 3 6.5 5.02 6.5 7.5C6.5 9.98 8.52 12 11 12C13.48 12 15.5 9.98 15.5 7.5C15.5
        5.02 13.48 3 11 3Z"
        fill={color}
      />
    </svg>
  );
};
