import React from 'react';
import { observer } from 'mobx-react-lite';
import { Styled } from './styles';

export const Title: React.VFC<{
  title?: string;
}> = observer((props) => {
  if (!props.title) return null;
  return (
    <Styled.TitleContainer>
      <Styled.TitleText>{props.title}</Styled.TitleText>
    </Styled.TitleContainer>
  );
});
