import React, { useMemo } from 'react';
import { transformToCurrency } from 'utils/functions';
import { MedicineCenterTypeEnum } from 'services';
import { Styled } from '../styles';

interface Props {
  price?: string | null;
  discountPrice?: string | null;
  unit?: string | null;
  buttonText?: string | null;
  onButtonClick: () => void;
  type?: MedicineCenterTypeEnum;
}

export const PriceInfo: React.VFC<Props> = (props) => {
  const isClinic = useMemo(() => props.type === MedicineCenterTypeEnum.Clinic, [props.type]);

  return (
    <Styled.Container direction="column" gap="24px">
      <Styled.PriceInfoContainer>
        <Styled.CurrencyTitle>Стоимость</Styled.CurrencyTitle>
        <Styled.CurrencyContainer>
          {props.discountPrice && (
            <Styled.CurrencyTitle>{`${transformToCurrency(Number(props.discountPrice))} руб.`}</Styled.CurrencyTitle>
          )}
          {props.price && (
            <Styled.CurrencyText through>{`${transformToCurrency(Number(props.price))} руб.`}</Styled.CurrencyText>
          )}
          {!isClinic && props.unit && <Styled.CurrencyText>{props.unit}</Styled.CurrencyText>}
        </Styled.CurrencyContainer>
      </Styled.PriceInfoContainer>
      {isClinic && props.buttonText && (
        <Styled.OrderButton onClick={props.onButtonClick} gradient borderless white>
          {props.buttonText}
        </Styled.OrderButton>
      )}
    </Styled.Container>
  );
};
