import React from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { GuidePage } from 'services';
import { useSearchStore } from 'store';
import { GuidePageCard } from 'components/renderers/guide-pages/card';
import Table from 'components/base-ui/table';
import { SearchSection } from '../index';

export const GuideSearchTable: React.FC = observer(() => {
  const store = useSearchStore();
  const { searchValue } = useParams<{ searchValue: string }>();

  return store.fieldData('guidePages').length ? (
    <SearchSection compact heading="Гид" noFirstOfTypeOffset>
      <Table<GuidePage, never>
        count={store.fieldCount('guidePages')}
        currentPage={store.fieldPage('guidePages')}
        cards={<GuidePageCard items={store.fieldData('guidePages') as GuidePage[]} />}
        onPagination={(page) => store.onPaginate(searchValue, 'guidePages', 'guide_pages', page)}
      />
    </SearchSection>
  ) : null;
});
