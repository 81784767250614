import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { CategoryProps, SubstitutedCategoryType } from 'web';

import { BankingProduct, InsuranceProduct, MediaProduct, ProductList } from 'services';

import { BREAKPOINTS } from 'utils/constants';

import { Container, Grid } from 'components/base-ui/cards';
import Item from './item';

// TODO: extend card props and styles for better css reuse
// as these styles below are repeated in goods/shared/cards
const StyledContainer = styled(Container)`
  margin-bottom: 144px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin-bottom: 168px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    margin-bottom: 114px;
  }
`;

const StyledGrid = styled(Grid)`
  row-gap: 40px;
  column-gap: 62px;
  grid-auto-rows: 472px;
  padding: 0 32px 16px 32px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    justify-content: space-around;
  }
`;

interface CardsProps<T> {
  items: T[];
  category?: CategoryProps;
  handleShowModal?: (id: number) => void;
}

function Cards<T extends ProductList | BankingProduct | InsuranceProduct | MediaProduct>({
  items,
  category,
  handleShowModal,
}: CardsProps<T>) {
  const { categoryType, categoryId } = useParams<{ categoryType: SubstitutedCategoryType; categoryId: string }>();

  const memoizedCategory = useMemo((): CategoryProps => {
    return category ?? { id: categoryId, type: categoryType };
  }, [category, categoryId, categoryType]);

  return (
    <StyledContainer>
      <StyledGrid mode="auto-columns" width="256px">
        {items.map(el => (
          <Item key={el.imageThumbnail} data={el} category={memoizedCategory} handleShowModal={handleShowModal} />
        ))}
      </StyledGrid>
    </StyledContainer>
  );
}

export default Cards;
