import React from 'react';
import Brand from 'components/base-ui/brand';
import { ReactComponent as Divider } from 'assets/img/shapes/lineMain.svg';
import { useAuthStore } from 'store';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import {
  StBrandWrapper,
  StButton,
  StContent,
  StDescription,
  StDividerWrapper,
  StErrorMessage,
  StHeader,
  StHrDiv,
} from './styled';
import { VerifyInput } from '../verify-input';

interface Props {
  infoText: string[];
}

const REDIRECT_LINK = 'redirect_link';

export const VerifyContent: React.FC<Props> = observer((props) => {
  const store = useAuthStore();
  const history = useHistory();

  const [verCode, setVerCode] = React.useState('');
  const [error, setError] = React.useState(false);

  const onSubmit = React.useCallback(() => {
    if (verCode.length < 4 || !Number(verCode)) {
      setError(true);
      return;
    }

    store.signIn({ emailCode: verCode, email: store.email }).then(() => {
      if (store.error !== null) {
        setError(true);
        return;
      }

      const link = localStorage.getItem(REDIRECT_LINK);
      if (link) {
        window.location.replace(link);
        localStorage.removeItem(REDIRECT_LINK);
      } else {
        history.push('/');
      }
    });
  }, [store, history, verCode, error]);

  const onCodeChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setVerCode(e.currentTarget.value);
    setError(false);
  }, []);

  return (
    <StContent>
      <StBrandWrapper>
        <Brand color="main" size="default" />
      </StBrandWrapper>
      <StDividerWrapper>
        <Divider />
      </StDividerWrapper>
      <StHeader>платформа</StHeader>
      <VerifyInput value={verCode} onChange={onCodeChange} error={error} />
      <StHrDiv error={error} />
      {error && <StErrorMessage>Некорректный код</StErrorMessage>}
      <StButton onClick={onSubmit} disabled={error || verCode.length < 4}>
        Войти
      </StButton>
      {props.infoText?.map((item) => <StDescription key={item}>{item}</StDescription>)}
    </StContent>
  );
});
