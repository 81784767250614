import styled from 'styled-components';
import Link from 'components/base-ui/link';

export const StTitle = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const StLink = styled(Link)<{ nested: boolean }>`
  color: inherit;
  text-transform: ${(props) => (props.nested ? 'unset' : 'uppercase')};
`;
