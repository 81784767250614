import { useEffect, useRef, useState, RefObject, useMemo } from 'react';
import { SliderItem } from 'web';

interface SliderDataProps {
  items: SliderItem[];
  isActive?: boolean | null;
  periodicity: number;
}

interface Args extends IntersectionObserverInit {
  freezeOnceVisible?: boolean;
}

export const useIntersectionObserver = (
  elementRef: RefObject<Element>,
  { threshold = 0, root = null, rootMargin = '0%', freezeOnceVisible = false }: Args,
): IntersectionObserverEntry | undefined => {
  const [entry, setEntry] = useState<IntersectionObserverEntry>();

  const frozen = entry?.isIntersecting && freezeOnceVisible;

  const updateEntry = ([entry]: IntersectionObserverEntry[]): void => {
    setEntry(entry);
  };

  useEffect(() => {
    const node = elementRef?.current; // DOM Ref

    if (frozen ?? !node) return;

    const observerParams = { threshold, root, rootMargin };
    const observer = new IntersectionObserver(updateEntry, observerParams);

    observer.observe(node);

    return () => observer.disconnect();
  }, [elementRef, JSON.stringify(threshold), root, rootMargin, frozen]);

  return entry;
};

export const useSliderData = ({ isActive, periodicity, items }: SliderDataProps) => {
  const [current, setCurrent] = useState(0);

  const ref = useRef<HTMLDivElement | null>(null);
  // eslint-disable-next-line no-undef
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const observer = useIntersectionObserver(ref, { threshold: 0.2 });

  const visible = useMemo(() => {
    return observer?.isIntersecting && observer?.intersectionRatio >= 0.2;
  }, [observer?.isIntersecting, observer?.intersectionRatio]);

  const slideToNext = () => {
    setCurrent((prev) => (prev + 1) % items.length);
  };

  const slideToPrev = () => {
    setCurrent((prev) => (prev - 1 >= 0 ? prev - 1 : items.length - 1));
  };

  function loop(status: boolean) {
    if (status && isActive && periodicity > 0 && visible) {
      intervalRef.current = setInterval(() => {
        slideToNext();
      }, periodicity);
    } else if (intervalRef.current) {
      clearInterval(intervalRef.current ?? undefined);
      intervalRef.current = null;
    }
  }

  useEffect(() => {
    if (isActive && ref.current) {
      loop(true);
      return () => {
        if (intervalRef.current && !visible) loop(false);
      };
    }
  }, [isActive, ref.current, visible]);

  useEffect(() => {
    return () => {
      if (intervalRef.current) loop(false);
    };
  }, []);

  return { current, setCurrent, ref, loop, slideToNext, slideToPrev };
};
