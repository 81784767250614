import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';
import { Span } from 'components/base-ui/typo';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow: hidden;
  font-size: 14px;
  line-height: 20px;
`;

const NewsCardDate = styled(Span)`
  font-size: 14px;
  line-height: 20px;

  color: ${COLORS.primary};
`;

const Title = styled.span`
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;

  color: ${COLORS.secondary};

  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const Wrapper = styled.div`
  cursor: pointer;
  display: flex;
  width: 288px;
  flex-direction: column;
  gap: 16px;

  &:hover ${Title}, &:focus ${Title}, &:active ${Title} {
    color: ${COLORS.primary};
  }

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 320px;
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: 288px;
  }
`;

const ImgWrapper = styled.div`
  width: 288px;
  height: 288px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.35);

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 320px;
    height: 320px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: 288px;
    height: 288px;
  }
`;

export const Styled = {
  NewsCardDate,
  Content,
  Title,
  Wrapper,
  ImgWrapper,
};
