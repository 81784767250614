import React, { createContext, useContext } from 'react';

import CompanyStore from './store';

const store = new CompanyStore();

const CompanyStateContext = createContext<CompanyStore>(store);

export const CompanyStateProvider: React.FC = ({ children }) => (
  <CompanyStateContext.Provider value={store}>{children}</CompanyStateContext.Provider>
);

export const useCompanyStore = (): CompanyStore => useContext(CompanyStateContext);
