import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { observer } from 'mobx-react-lite';
import { GuideComment } from 'services';

import { head } from 'lodash';
import { AddNewComment } from '../add-comment/add-new-comment';
import { Container, Grid, SubCommentsShowMoreButton } from './style';
import { CommentHeader } from './comment-header/comment-header';
import { CommentBody } from './comment-body/comment-body';
import { CommentFooter } from './comment-footer/comment-footer';
import { useGuideCommentsStore } from '../../../../store/guide-comments';
import { GuideCommentWithChildrens } from '../../../../store/guide-comments/store';

interface ItemProps {
  data: GuideCommentWithChildrens;
  isSub?: boolean;
}

const GuideCommentsItem: React.FC<ItemProps> = observer(({ data, isSub }) => {
  const guideCommentsStore = useGuideCommentsStore();

  const [visibleReply, setVisibleReply] = useState(false);
  const [subCommentsCount, setSubCommentsCount] = useState(10);

  useEffect(() => {
    if (data.childrenComments) {
      guideCommentsStore.addSubComments(data.childrenComments);
    }
  }, [data.childrenComments]);

  const addSubComment = (comment: string) => {
    const newComment: GuideComment = {
      content: comment,
      parentComment: isSub ? data.parentComment : data.id,
      guide: data.guide,
    };
    guideCommentsStore.addComment(newComment);
    setVisibleReply(false);
  };

  const fetchNextSubComments = useMemo(() => {
    return () => {
      if (data.id) {
        setSubCommentsCount(subCommentsCount + 1);
        guideCommentsStore.fetchSubComments({
          guideId: +data.guide,
          parentId: data.id,
          count: subCommentsCount + 1,
        });
      }
      setSubCommentsCount(subCommentsCount + 6);
    };
  }, [subCommentsCount, data.id, data.guide, guideCommentsStore]);

  const isShowMoreReplyButton =
    Number(data?.childrenCommentsCount) > 5 &&
    data.id &&
    Number(head(guideCommentsStore.comments.filter((comment) => comment.id === data.id))?.childrens?.length) <
      Number(data?.childrenCommentsCount);

  const handleReplyClick = useCallback(() => {
    setVisibleReply(!visibleReply);
  }, [visibleReply]);

  const renderChildrens = (childrens: GuideComment[]) => {
    return childrens.map((c) => <GuideCommentsItem data={c} isSub={true} />);
  };

  return (
    <>
      <Container isSub={isSub} hasChildren={Boolean(data.childrenCommentsCount)}>
        <Grid>
          <CommentHeader profile={data.profile} createDate={data.created} />

          <CommentBody content={data.content} />

          <CommentFooter
            visible={visibleReply}
            onReply={handleReplyClick}
            likesCount={data.likesCount}
            commentId={data.id}
            parentCommentId={data.parentComment}
            isLikedByUser={data.isLikedByUser}
            isSub={isSub}
          />
        </Grid>
      </Container>

      {data.id &&
        renderChildrens(head(guideCommentsStore.comments.filter((comment) => comment.id === data.id))?.childrens || [])}

      {isShowMoreReplyButton && (
        <SubCommentsShowMoreButton onClick={fetchNextSubComments}>ПОКАЗАТЬ БОЛЬШЕ ОТВЕТОВ</SubCommentsShowMoreButton>
      )}

      {visibleReply && (
        <AddNewComment onAddNewComment={addSubComment} isSub={true} toName={data.profile?.firstName ?? ''} />
      )}
    </>
  );
});
export default GuideCommentsItem;
