import styled from 'styled-components';
import { BREAKPOINTS } from 'utils/constants';
import Icon from 'components/base-ui/icon';

export const StVideo = styled.div`
  width: 288px;
  cursor: pointer;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 656px;
  }

  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    width: 644px;
  }

  @media screen and (min-width: ${BREAKPOINTS.wideDesktop}) {
    width: 732px;
  }
`;

export const StImageWrapper = styled.div<{ bg: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 288px;
  height: 163px;
  overflow: hidden;
  border-radius: 8px;
  position: relative;
  background: url(${(props) => props.bg});
  background-size: cover;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 656px;
    height: 371px;
  }

  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    width: 644px;
    height: 362px;
  }

  @media screen and (min-width: ${BREAKPOINTS.wideDesktop}) {
    width: 732px;
    height: 413px;
  }
`;

export const StIcon = styled(Icon)`
  width: 60px;
  height: 60px;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
`;
