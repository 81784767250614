import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { usePortalDirectionsStore } from 'store';
import { Accordion } from '@szhsin/react-accordion';
import { ItemKey } from '@szhsin/react-accordion/types/utils/constants';
import { TransitionStatus } from 'react-transition-state';
import { useHistory } from 'react-router-dom';
import { PortalDirectionItem, PortalDirectionSubItem } from 'services';
import { authService } from 'utils/authentication';
import { StAccordionItem, StMenu } from './styled';
import { ItemHeader } from './item-header';

interface MenuProps {
  visible: boolean;
  onCloseMenu: () => void;
}

interface AccordianStateProps {
  key: ItemKey;
  current: Readonly<{ status: TransitionStatus; isMounted: boolean; isEnter: boolean; isResolved: boolean }>;
}

const Menu: React.FC<MenuProps> = observer((props) => {
  const store = usePortalDirectionsStore();
  const history = useHistory();

  useEffect(() => store.fetchDirections(), []);

  const onMenuItemClick = useCallback(
    (params: AccordianStateProps) => {
      if (params.key === 'logout') {
        authService.onLogout();
        history.push('/login');
        return;
      }

      const element = JSON.parse(String(params.key)) as PortalDirectionItem | PortalDirectionSubItem;

      if ('subItems' in element && element.subItems?.length && element.subItems?.length > 0) {
        return;
      }

      if ((params.current.status === 'entered' || params.current.status === 'preEnter') && 'link' in element) {
        props.onCloseMenu();
        history.push(element.link);
        return;
      }

      props.onCloseMenu();
    },
    [history],
  );

  return (
    <StMenu visible={props.visible}>
      <Accordion allowMultiple transition onStateChange={onMenuItemClick}>
        {store.forMainMenu.map((el) => (
          <StAccordionItem
            key={JSON.stringify(el)}
            itemKey={JSON.stringify(el)}
            header={<ItemHeader item={el} />}
            nested={false}
          >
            <Accordion onStateChange={onMenuItemClick}>
              {el.subItems?.map((subEl) => (
                <StAccordionItem
                  key={JSON.stringify(subEl)}
                  itemKey={JSON.stringify(subEl)}
                  header={<ItemHeader item={subEl} />}
                  nested={true}
                />
              ))}
            </Accordion>
          </StAccordionItem>
        ))}
        <StAccordionItem key="logout" itemKey="logout" header="ВЫЙТИ" nested={false} />
      </Accordion>
    </StMenu>
  );
});

export default Menu;
