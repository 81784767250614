import React from 'react';
import styled from 'styled-components';
import { BREAKPOINTS } from 'utils/constants';

const Container = styled.div`
  display: none;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    display: inherit;
    width: 100%;
    height: 616px;
    margin: 40px 0;
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    display: inherit;
    width: 698px;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 616px;
  object-fit: cover;
`;

export const ArticleBigImage: React.FC<{ image: string }> = ({ image }) => {
  return (
    <Container>
      <Image src={image} alt="центральное изображение" />
    </Container>
  );
};
