import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';
import Section from 'components/base-ui/section';
import Paragraph from 'components/base-ui/paragraph';

interface OuterProps {
  src: string;
}

interface InnerProps {
  active?: boolean;
}

const offsetX = '-65px';
const bgWidth = '983.5px';
const bgGradient = COLORS.linearGradient;

export const BannerWrapper = styled.div`
  height: 500px;
  position: relative;
  color: ${COLORS.white};
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    height: 784px;
  }
  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    height: 860px;
  }
`;
export const BannerOuter = styled.div<OuterProps>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: inherit;
  background-size: cover;
  background-image: url(${(props) => props.src});
  background-position: top center;
  transition: background-image 0.2s ease-out;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    background-position: center;
  }
`;
export const BannerInner = styled.div<InnerProps>`
  position: absolute;
  top: 0;
  left: 0;
  height: inherit;
  width: 100vw;

  &:after {
    content: '';
    top: 0;
    height: inherit;
    width: ${bgWidth};
    position: absolute;
    mix-blend-mode: multiply;
    transform: skewX(-6deg);
    background: ${bgGradient};
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: top 0 left 0;
    left: ${(props) => (props.active ? '-91px' : 'unset')};
    right: ${(props) => (props.active ? 'unset' : '35px')};

    @media screen and (min-width: ${BREAKPOINTS.tablet}) {
      right: unset;
      left: ${(props) => (props.active ? '-91px' : '-436px')};
    }

    @media screen and (min-width: ${BREAKPOINTS.laptop}) {
      left: ${offsetX};
    }
  }
`;
export const InnerCircle = styled.div`
  top: 108px;
  left: -14.84%;
  width: 328px;
  height: 328px;
  position: absolute;
  border-radius: 50%;
  mix-blend-mode: multiply;
  background-color: ${COLORS.primary};

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    top: 180px;
    left: 4.56%;
  }
`;
export const BannerNext = styled.div`
  top: 0;
  right: 0;
  z-index: 10;
  width: 136px;
  display: none;
  position: absolute;
  mix-blend-mode: multiply;
  background: ${bgGradient};

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    display: block;
    height: 784px;
  }
  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    height: 860px;
  }
`;
export const BannerContent = styled.div`
  font-size: 14px;
  line-height: 20px;
  z-index: 5;
  width: 100%;
  height: inherit;
  a {
    text-decoration: none;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: ${COLORS.white};
    :hover {
      text-decoration: underline;
    }
  }
`;
export const StyledSection = styled(Section)`
  margin-bottom: 0;
`;
export const StyledParagraph = styled(Paragraph)``;
