import React from 'react';
import { HtmlRenderer } from 'utils/htmlParser';
import { StTitleCenter } from './styled';

interface Props {
  text: string;
}

export const AboutCenterHeader: React.FC<Props> = (props) => {
  return (
    <StTitleCenter>
      <HtmlRenderer html={props.text} />
    </StTitleCenter>
  );
};
