import React from 'react';
import { AboutPartnersPartner } from 'web';
import { ItemImage } from '../ItemImage';
import { ItemVideo } from '../ItemVideo';

interface Props {
  partner: AboutPartnersPartner;
  onPlay: () => void;
}

export const Media: React.FC<Props> = (props) => {
  return props.partner.mediaType === 'image' ? (
    <ItemImage images={props.partner.images} />
  ) : (
    <ItemVideo onPlay={props.onPlay} video={props.partner.video} />
  );
};
