import React, { useEffect, useMemo } from 'react';
import { AwardCarousel, Banner } from 'components/renderers/assol-award';
import { observer } from 'mobx-react-lite';
import { useProjectsStore } from 'store';
import { MediaBlock } from 'components/renderers/assol-award/media-block';
import { Gallery, VideoGallery } from 'components/renderers/assol-award/galery';
import { useMatomo } from '@datapunt/matomo-tracker-react';

export const AssolAward = observer(() => {
  const store = useProjectsStore();
  const { trackPageView } = useMatomo();

  useEffect(() => {
    store.fetchAwardPadeData().then(() => store.fetchAwards());
    document.title = 'Премия Ассоль';
    trackPageView({});
  }, []);

  const isVideoInGallery = useMemo(
    () => store?.awardVideoGallery && store.awardVideoGallery.videos.length > 0,
    [store.awardVideoGallery?.videos],
  );

  const dataVideo = useMemo(
    () => store?.awardVideoGallery?.videos.filter((video) => video !== null),
    [store.awardVideoGallery?.videos],
  );

  return (
    <>
      {store?.awardPageBannerData && <Banner data={store.awardPageBannerData} />}
      {store?.awards.length && !store.fetching && <AwardCarousel data={store.awards} />}
      {store?.awardMediaData && <MediaBlock data={store.awardMediaData} />}
      {store?.videoWithTextData?.video && <MediaBlock data={store.videoWithTextData} />}
      {store?.awardPhotoGallery && store.awardPhotoGallery.image.length > 0 && (
        <Gallery type="photo" data={store.awardPhotoGallery} />
      )}
      {isVideoInGallery && dataVideo && <VideoGallery data={dataVideo} header={store?.awardVideoGallery?.header} />}
    </>
  );
});
