interface ValidateNumbersOptions {
  min?: number;
  max?: number;
}

export const validateNumber = (value: number, options: ValidateNumbersOptions) => {
  const { min, max } = options;
  if (typeof min === 'number' && value < min) {
    return min;
  }
  if (typeof max === 'number' && value > max) {
    return max;
  }
  return value;
};
