import styled from 'styled-components';

import { COLORS } from '../../../utils/constants';

export const Textarea = styled.textarea`
  width: 100%;
  resize: none;
  font-size: 16px;
  font-family: inherit;
  line-height: 24px;
  padding: 7px 12px;
  border-radius: 2px;
  border: 1px solid ${COLORS.gray5};

  &::placeholder {
    color: ${COLORS.gray4};
  }
`;
