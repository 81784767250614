import { RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import qs, { ParsedQuery } from 'query-string';
import * as d3 from 'd3';

import { Nullable } from 'web';

import { useMatomo } from '@datapunt/matomo-tracker-react';
import { stringifyQueryParams } from './functions';
import { BREAKPOINTS, INITIAL_PAGE, PAGE_LIMIT } from './constants';

interface Dimensions {
  width: number;
  height: number;
}

export type DeviceType = 'mobile' | 'tablet' | 'laptop' | 'desktop';

export const getWindowDimensions = (): Dimensions => {
  const { clientWidth: width, clientHeight: height } = document.documentElement;
  return {
    width,
    height,
  };
};

export const getBreakpointsDimensions = (): Dimensions => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export const useWindowDimensions = (): Dimensions => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};

export const useBreakpointsDimensions = (): Dimensions => {
  const [windowDimensions, setWindowDimensions] = useState(getBreakpointsDimensions());

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getBreakpointsDimensions());
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};

const getDeviceType = (): DeviceType => {
  const { innerWidth: width } = window;

  if (width < parseInt(BREAKPOINTS.tablet)) {
    return 'mobile';
  }

  if (width < parseInt(BREAKPOINTS.laptop)) {
    return 'tablet';
  }

  if (width < parseInt(BREAKPOINTS.desktop)) {
    return 'laptop';
  }

  return 'desktop';
};

export const useDeviceType = (): DeviceType => {
  const [deviceType, setDeviceType] = useState('desktop' as DeviceType);

  const onWidthResize = useCallback(() => {
    setDeviceType(getDeviceType());
  }, []);

  useEffect(() => {
    setDeviceType(getDeviceType());
    window.addEventListener('resize', onWidthResize);
    return () => window.removeEventListener('resize', onWidthResize);
  }, []);

  return deviceType;
};

export const useCarousel = <T = unknown>(
  items: T[],
  shift?: number,
): {
  list: T[];
  current: number;
  decrement: () => void;
  increment: () => void;
} => {
  const [current, setCurrent] = useState(0);

  useEffect(() => setCurrent(shift ?? 0), [shift]);

  const list = useMemo(() => {
    return [...items.slice(current), ...items.slice(0, current)];
  }, [items, current]);

  const decrement = useCallback(() => {
    setCurrent((prev) => (prev === 0 ? items.length - 1 : prev - 1));
  }, [items, setCurrent]);

  const increment = useCallback(() => {
    setCurrent((prev) => (prev === items.length - 1 ? 0 : prev + 1));
  }, [items, setCurrent]);

  return { list, current, decrement, increment };
};

export const useToggle = (value = false): [boolean, () => void] => {
  const [active, setActive] = useState(value);

  const toggleActive = useCallback(() => {
    setActive((prev) => !prev);
  }, [setActive]);

  return [active, toggleActive];
};

export const useQueryParams = <T extends object = object>(): T &
  ParsedQuery<number | string | string[]> & { page: number } => {
  const { search } = useLocation();

  // sonarjs/prefer-immediate-return - это правило не понимает, что ниже не простая переменная, а мемоизированная
  // eslint-disable-next-line
  const params = useMemo(() => {
    const { page, ...otherParams } = qs.parse(search, { arrayFormat: 'comma', parseNumbers: true });

    return {
      ...otherParams,
      page: page ?? INITIAL_PAGE,
    };
  }, [search]);

  return params as T & { page: number };
};

export const usePagination = <T extends HTMLElement>({
  ref,
  path,
  count,
  error,
  withRouter = false,
  limit = PAGE_LIMIT,
  initialPage = INITIAL_PAGE,
}: {
  path?: string;
  count: number;
  limit?: number;
  error?: Nullable<Error>;
  withRouter?: boolean;
  initialPage?: number;
  ref?: RefObject<T>;
}): {
  page: number;
  onPagination: (value: number) => void;
  onPaginationWithRouter: (value: number) => void;
} => {
  const history = useHistory();
  const { trackPageView } = useMatomo();
  const queryParams = useQueryParams();

  const [page, setPage] = useState(initialPage);

  const numberOfPages = useMemo(() => Math.ceil(count / limit), [count, limit]);
  const stringifiedQueryParams = useMemo(() => stringifyQueryParams(queryParams), [queryParams]);

  const onPagination = useCallback(
    (value: number) => {
      if (value > 0 && (value <= numberOfPages || numberOfPages === 0)) {
        setPage(() => value);

        if (ref && ref.current) {
          ref.current.scrollIntoView();
        }
      }
    },
    [ref, page, numberOfPages, setPage],
  );

  const onPaginationWithRouter = useCallback(
    (value: number) => {
      onPagination(value);

      const pathname = path ?? history.location.pathname;

      if (value < 0 || value > numberOfPages) {
        return history.push({ pathname });
      }

      if (stringifiedQueryParams) history.push({ pathname, search: `?page=${value}&${stringifiedQueryParams}` });
      else history.push({ pathname, search: `?page=${value}` });

      trackPageView({});
    },
    [path, history, numberOfPages, stringifiedQueryParams, onPagination],
  );

  useEffect(() => {
    if (page !== initialPage) {
      return withRouter ? onPaginationWithRouter(initialPage) : onPagination(initialPage);
    }
  }, [page, initialPage, onPagination]);

  useEffect(() => {
    if (error) onPaginationWithRouter(INITIAL_PAGE);
  }, [error, onPaginationWithRouter]);

  return { page, onPagination, onPaginationWithRouter };
};

interface Orderable {
  sortOrder?: number | null;
}

export const useSortOrder = <T extends Orderable>(): ((a: T, b: T) => number) => {
  return (a: T, b: T) => {
    return a.sortOrder && b.sortOrder ? a.sortOrder - b.sortOrder : 0;
  };
};

export const useD3 = <T extends d3.BaseType, Datum>(
  renderChartFn: (svg: d3.Selection<T, Datum, null, undefined>) => unknown,
  dependencies: unknown[],
) => {
  const ref: RefObject<T> = useRef(null);

  useEffect(() => {
    if (ref.current) {
      // @ts-expect-error skip
      renderChartFn(d3.select(ref.current));
    }
  }, [...dependencies, renderChartFn]);

  return ref;
};

type UseHandleRectHeight = number | 'auto';

export const useHandleRect = <T extends HTMLElement = HTMLDivElement>(
  active?: boolean,
  defaultHeight?: number,
): [UseHandleRectHeight, (node: Nullable<T>) => void] => {
  const [height, setHeight] = useState<UseHandleRectHeight>(0);

  const handleRect = useCallback(
    (node: Nullable<T>) => {
      if (node === null) return setHeight(() => 'auto');

      if (active) return setHeight(() => 'auto');

      const rect = node.getBoundingClientRect();

      return defaultHeight === undefined
        ? setHeight(() => rect.height)
        : setHeight(() => (rect.height > defaultHeight ? defaultHeight : rect.height));
    },
    [active, setHeight],
  );

  return [height, handleRect];
};

export const useTextMeasure = (): [RefObject<HTMLCanvasElement>, (text: string) => Nullable<number>] => {
  const ref: RefObject<HTMLCanvasElement> = useRef<HTMLCanvasElement | null>(null);

  const measureText = useCallback(
    (text: string): Nullable<number> => {
      if (ref.current === null) return null;

      const ctx = ref.current.getContext('2d');

      return ctx ? ctx.measureText(text).width : null;
    },
    [ref],
  );

  return [ref, measureText];
};
