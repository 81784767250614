import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { usePageContentStore, usePortalDirectionsStore } from '../../../store';

import Section from '../../base-ui/section';
import Directions from '../../carousels/directions';

enum SectionsAreas {
  Title = 'SectionsCarouselTitle',
}

const pageSlug = 'index';
enum SectionsElementsSlugs {
  Title = 'sections-carousel',
}

const PortalDirectionRenderer: React.FC = observer(() => {
  const store = usePortalDirectionsStore();
  const pageContentStore = usePageContentStore<SectionsElementsSlugs, SectionsAreas>();

  useEffect(() => store.fetchDirections(), []);

  useEffect(
    () =>
      pageContentStore.fetchContent(
        { slug: pageSlug },
        {
          [SectionsElementsSlugs.Title]: SectionsAreas.Title,
        },
      ),
    [],
  );

  const Content = useMemo(() => {
    const info = pageContentStore.content[SectionsAreas.Title];
    if (!info) return;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    return info.filter((item) => item.type === 'text').map((item) => <div key={item.key}>{item.value}</div>);
  }, [pageContentStore.content[SectionsAreas.Title]]);

  return (
    <Section heading={Content} noFirstOfTypeOffset>
      <Directions items={store.forMainPage} />
    </Section>
  );
});

export default PortalDirectionRenderer;
