import React from 'react';

import { useMatomo } from '@datapunt/matomo-tracker-react';
import { DefaultLayout } from '../../layouts';

import MainComponent from '../../components/main';
import ScrollToTopOnMount from '../../components/base-ui/scroll-to-top';

const MainPage: React.FC = () => {
  const { trackPageView } = useMatomo();

  React.useEffect(() => {
    document.title = 'Платформа АССОЛЬ';
    trackPageView({});
  }, []);

  return (
    <>
      <ScrollToTopOnMount />

      <DefaultLayout>
        <MainComponent />
      </DefaultLayout>
    </>
  );
};

export default MainPage;
