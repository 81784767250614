import styled, { css } from 'styled-components';
import { BREAKPOINTS } from 'utils/constants';
import { CarouselButtonWrapperProps } from 'web';

export const Div = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  padding: 20px 16px 62px 16px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 20px 0 62px 0;
  }
`;

export const Grid = styled.div`
  display: grid;
  column-gap: 80px;
  grid-auto-flow: column;
  grid-template-columns: repeat(1, 256px);

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    grid-template-columns: repeat(2, 256px);
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    grid-template-columns: repeat(2, 256px);
  }

  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    grid-template-columns: repeat(3, 256px);
  }
  @media screen and (min-width: ${BREAKPOINTS.wideDesktop}) {
    grid-template-columns: repeat(4, 256px);
  }
`;

export const leftStyles = css`
  left: -8px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    left: -32px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    left: 16px;
  }
`;

const rightStyles = css`
  right: -8px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    right: -32px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    right: 16px;
  }
`;

export const ButtonWrapper = styled.div<CarouselButtonWrapperProps>`
  top: 168px;
  z-index: 15;
  width: 48px;
  height: 48px;
  position: absolute;

  ${(props) => (props.direction === 'left' ? leftStyles : rightStyles)}
`;
