// TODO: extend card props and styles for better css reuse
// as these styles below are repeated in goods/shared/cards
import React from 'react';
import styled from 'styled-components';

import { GuidePage } from '../../../services';

import { BREAKPOINTS } from '../../../utils/constants';

import { Container, Grid } from '../../base-ui/cards';
import { GuidePageCardItem } from './item';

const StyledContainer = styled(Container)`
  margin-bottom: 144px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin-bottom: 168px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    margin-bottom: 114px;
  }
`;

const StyledGrid = styled(Grid)`
  row-gap: 40px;
  column-gap: 62px;
  padding: 0 32px 16px 32px;
  justify-content: space-around;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 0 88px 16px 88px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding: 0 32px 16px 32px;
  }
`;

interface CardsProps {
  items: GuidePage[];
  handleShowModal?: (guideId: number) => void;
}

export const GuidePageCard: React.FC<CardsProps> = ({ items, handleShowModal }) => {
  return (
    <StyledContainer>
      <StyledGrid mode="auto-columns" width="256px">
        {items.map((el) => (
          <GuidePageCardItem key={el.id} data={el} handleShowModal={handleShowModal} />
        ))}
      </StyledGrid>
    </StyledContainer>
  );
};

export default GuidePageCard;
