import React, { useCallback, useMemo, useState } from 'react';
import { LinkItUrl } from 'react-linkify-it';
import { getTrimIndex } from 'utils/helpers';
import { ShowMore, StSmall } from './styles';

interface Props {
  content: string;
}

export const CommentBody: React.FC<Props> = (props) => {
  const [collapsed, setCollapsed] = useState(props.content.length > 300);

  const content = useMemo(() => {
    return collapsed ? props.content.slice(0, getTrimIndex(props.content, 300)) : props.content;
  }, [collapsed]);

  const onMoreClick = useCallback(() => setCollapsed(false), []);

  return (
    <StSmall>
      <LinkItUrl>
        {content} {collapsed && <ShowMore onClick={onMoreClick}>читать больше...</ShowMore>}
      </LinkItUrl>
    </StSmall>
  );
};
