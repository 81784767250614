import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { CartProduct } from 'services';

import { useDeviceType } from 'utils/hooks';
import { COLORS, BREAKPOINTS } from 'utils/constants';

import { H1 } from 'components/base-ui/typo';

import { useCartStore } from 'store';
import telImg from 'assets/img/telephone-red.png';
import { BaseModal } from 'components/base-ui/base-modal';
import BagAbout from '../about';
import BagItem from './item';
import { WineInstruction } from './wine-instruction';

const Div = styled.div`
  margin-top: 40px;
  margin-bottom: 34px;

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    margin-bottom: 126px;
  }
`;

const HeadingWrapper = styled.div`
  width: 100%;
  margin-bottom: 38px;
  text-align: center;
`;

const Heading = styled(H1)`
  color: ${COLORS.secondary};
`;

const Grid = styled.div`
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    display: grid;
    column-gap: 48px;
    grid-template-columns: 1fr 368px;
  }
`;

const BagList = styled.ul`
  margin: 0;
  display: grid;
  row-gap: 32px;
  padding: 0 16px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 0;
    column-gap: 16px;
    grid-template-columns: repeat(2, 1fr);
  }
`;

const ModalContainer = styled.div`
  text-align: center;
  p {
    margin-top: 16px;
    line-height: 24px;
    margin-bottom: 0;
  }
  img {
    max-width: 338px;
    width: 100%;
  }
`;

interface BagProps {
  cartData: CartProduct[];
}

const Bag: React.FC<BagProps> = observer(({ cartData }) => {
  const deviceType = useDeviceType();
  const isDesktop = useMemo(() => deviceType === 'desktop', [deviceType]);

  const { successAction, resetSuccessState, cart } = useCartStore();

  const history = useHistory();

  const successModalClose = useCallback(() => {
    resetSuccessState();
    history.push('/market');
  }, []);

  return (
    <Div>
      <HeadingWrapper>
        <Heading>Корзина ({cartData.length})</Heading>
      </HeadingWrapper>

      <Grid>
        <BagList>
          {cartData.map((c) => (
            <BagItem data={c} key={c.product?.id} />
          ))}
        </BagList>

        {isDesktop && <BagAbout />}
      </Grid>

      <WineInstruction />

      <BaseModal isVisible={successAction} onClose={successModalClose}>
        <ModalContainer>
          <H1>Ваш заказ успешно отправлен</H1>
          <p>
            В ближайшее время с вами <br />
            свяжется менеджер
          </p>
          <img src={telImg} alt="Ваш заказ успешно отправлен" />
        </ModalContainer>
      </BaseModal>
    </Div>
  );
});

export default Bag;
