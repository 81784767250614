import React, { useMemo } from 'react';
import { useDeviceState } from 'hooks/useDeviceProvider';
import { Styled } from './styles';

interface PhotoProps {
  src: string;
  name?: string;
  company?: string;
  variant: 'modal' | 'winnerCard';
  withDots: boolean;
  inResponsiveBox?: boolean;
}

export function Photo(props: PhotoProps) {
  const withInfo = useMemo(() => {
    return !!props.name || !!props.company;
  }, [props.name, props.company]);
  return (
    <Styled.Wrapper withInfo={withInfo} type={props.variant} inResponsiveBox={props.inResponsiveBox}>
      <img src={props.src} alt={props.name} />
      <Styled.Info withDots={props.withDots} type={props.variant}>
        {props.name && <Styled.Title>{props.name}</Styled.Title>}
        {props.company && <Styled.Subtitle>{props.company}</Styled.Subtitle>}
      </Styled.Info>
    </Styled.Wrapper>
  );
}

export function PhotoResponsive(props: PhotoProps) {
  const { isLessTablet } = useDeviceState();

  if (isLessTablet && props.variant === 'winnerCard') {
    return (
      <Styled.Box>
        <Styled.Content>
          <Photo {...props} inResponsiveBox />
        </Styled.Content>
      </Styled.Box>
    );
  }

  return <Photo {...props} />;
}
