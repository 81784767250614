import styled from 'styled-components';
import { ArrowButton } from 'components/base-ui/button';
import { BREAKPOINTS, COLORS } from 'utils/constants';

export const StActions = styled.div<{showActions: boolean}>`
  display: ${props => (props.showActions ? 'flex' : 'none')};
  flex-wrap: wrap;
  gap: 24px;
  justify-content: center;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    justify-content: flex-start;
  }
`;

export const StOfferButton = styled(ArrowButton)`
  width: 100%;
  max-width: 296px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 210px;
  }
`;

export const StDetailButton = styled.button`
  width: 100%;
  max-width: 296px;
  height: 48px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  border: none;
  background-color: ${COLORS.white};
  color: ${COLORS.secondary};
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  :active {
    background-color: transparent;
  }

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 160px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    :hover {
      background-color: ${COLORS.primary};
      color: ${COLORS.white};
    }
  }
`;
