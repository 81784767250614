import { makeObservable, observable, action } from 'mobx';

import { api } from 'utils/api';

import { Category, CategoriesApiCategoriesReadRequest } from 'services';
import { FrameAdaptiveSize } from 'web';
import { AxiosError } from 'axios';
import AbstractStore from '../AbstractStore';

type NullableCategory = Category | null;

export default class CategoryStore extends AbstractStore {
  category: NullableCategory = null;

  iframeSize: FrameAdaptiveSize[] = [];

  categoryList: Category[] = [];

  constructor() {
    super();
    makeObservable(this, {
      ...this.annotations,
      category: observable,
      iframeSize: observable,
      categoryList: observable,
      fetchCategory: action,
      setCategory: action,
      fetchCategoryList: action,
      setCategoryList: action,
      setIframeSize: action,
      closeErrorScreen: action,
      cleanCategory: action,
    });
  }

  fetchCategory(props: CategoriesApiCategoriesReadRequest, options?: unknown) {
    this.fetchData(() => api.categories.categoriesRead(props, options).then((res) => this.setCategory(res.data)));
  }

  fetchCategoryList() {
    this.fetchData(() => api.categories.categoriesList().then((res) => this.setCategoryList(res.data.results)));
  }

  closeErrorScreen = (props: CategoriesApiCategoriesReadRequest, options?: unknown) => {
    this.setError(null);

    api.closeErrorScreen
      .closeErrorScreenCreate({
        data: {
          screen: this.category?.errorScreen?.id || 0,
        },
      })
      .then(() => this.fetchCategory(props, options))
      .catch((err: AxiosError) => this.setError(err));
  };

  setCategoryList(value: Category[]) {
    this.categoryList = value;
  }

  setCategory(value: Category): void {
    this.category = value;
  }

  setIframeSize(size: FrameAdaptiveSize[]) {
    this.iframeSize = size;
  }

  cleanCategory() {
    this.category = null;
  }
}
