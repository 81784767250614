import React, { useMemo, useCallback } from 'react';
import styled from 'styled-components';

import { Shop } from '../../../../services';

import { COLORS, BREAKPOINTS, UNICODE_CYRILLIC_OFFSET } from '../../../../utils/constants';

import { H2, Span } from '../../typo';
import Link from '../../link';

const Container = styled.div`
  position: relative;
  word-break: break-word;
  padding: 0 25px;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  &:not(:last-of-type) {
    border-right: 1px solid ${COLORS.gray5};
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    word-break: normal;
    padding: 0 34px;
    &:nth-child(5) {
      border-right: none;
    }
  }
`;

const Index = styled(H2)`
  font-weight: 400;
  margin-bottom: 4px;
  color: ${COLORS.primary};
`;

const Info = styled.div`
  display: flex;
  margin-bottom: 24px;
  flex-direction: column;
`;

const InfoText = styled(Span)`
  color: ${COLORS.gray3};
`;

const InfoLink = styled(Link)`
  color: ${COLORS.secondary};
`;

const Contacts = styled.div`
  display: flex;
  flex-direction: column;
`;

interface ItemProps {
  idx: number;
  data: Shop;
}

const Item: React.FC<ItemProps> = ({ idx, data }) => {
  const stripSecondsFromHours = useCallback((el: string) => {
    return el.split(':').slice(0, 2).join(':');
  }, []);

  const memoizedIndex = useMemo(() => {
    return String.fromCharCode(idx + UNICODE_CYRILLIC_OFFSET);
  }, [idx]);

  const memoizedWorkingHours = useMemo(() => {
    return `${stripSecondsFromHours(data.workBeginning)} - ${stripSecondsFromHours(data.workEnding)}`;
  }, [data.workBeginning, data.workEnding, stripSecondsFromHours]);

  return (
    <Container>
      <Index>{memoizedIndex}</Index>

      <Info>
        <InfoText>
          <div>{data.name}</div>
          <div>{data.address}</div>
        </InfoText>

        <InfoLink to="/">{data.city.name}</InfoLink>
      </Info>

      <Contacts>
        <InfoText>{memoizedWorkingHours}</InfoText>
        <InfoText>{data.phone}</InfoText>
      </Contacts>
    </Container>
  );
};

export default Item;
