import React, { useMemo } from 'react';
import BaseSection from 'components/base-ui/section';
import { BaseModal } from 'components/base-ui/base-modal';
import { Feedback } from 'components/feedback';
import { StButton, StButtonWrapper } from './styled';

interface Props {
  formId: number;
  buttonText: string;
}

export const ApplicationFormBlock: React.FC<Props> = (props) => {
  const [showForm, setShowForm] = React.useState(false);

  const buttonText = useMemo(() => {
    return props.buttonText.length > 0 ? props.buttonText : 'заказать';
  }, [props.buttonText]);

  return (
    <>
      <BaseSection compact borderless transparent>
        <StButtonWrapper>
          <StButton onClick={() => setShowForm(true)}>{buttonText}</StButton>
        </StButtonWrapper>
      </BaseSection>
      <BaseModal isVisible={showForm} onClose={() => setShowForm(false)}>
        <Feedback formId={props.formId} />
      </BaseModal>
    </>
  );
};
