import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import { CarouselButtonWrapperProps } from 'web';

import { PortalDirectionItem } from 'services';

import { BREAKPOINTS } from 'utils/constants';

import IconButton from 'components/base-ui/icon-button';
import Card from './card';

const Wrapper = styled.div`
  overflow-x: hidden;
  width: 100%;
  position: relative;
}

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    display: flex;
    justify-content: center;
  }
`;

const Button = styled(IconButton)<CarouselButtonWrapperProps>`
  top: 200px;
  position: absolute;
  z-index: 1;
  ${(props) => (props.direction === 'left' ? 'left: 16px;' : 'right: 16px;')};
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    ${(props) => (props.direction === 'left' ? 'left: 88px;' : 'right: 107px;')}
  }
`;

const Grid = styled.div`
  position: relative;
  display: grid;
  column-gap: 32px;
  grid-auto-flow: column;
  grid-auto-columns: 100%;
  padding: 0 32px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 5px 0;
    column-gap: 16px;
    grid-auto-columns: 256px;
  }
`;

interface DirectionsProps {
  items: PortalDirectionItem[];
}

const Directions: React.FC<DirectionsProps> = (props) => {
  const { items } = props;
  const [list, setList] = useState<PortalDirectionItem[]>(items);
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const [visibleItems, setVisibleItems] = useState<number[]>([]);
  const fistLoadComponent = useRef(true);
  const [visibleArrow, setVisibleArrow] = useState(true);

  useEffect(() => {
    if (fistLoadComponent.current) setList(items);
  }, [items]);

  useEffect(() => setVisibleArrow(Boolean(visibleItems.some((e) => e === 0))), [visibleItems]);

  const handleDecrementList = useCallback(() => {
    fistLoadComponent.current = false;
    setList((prevState) => {
      const newList = [...prevState];
      return newList.splice(-1).concat(newList);
    });
  }, [list, fistLoadComponent]);

  const handleIncrementList = useCallback(() => {
    fistLoadComponent.current = false;
    setList((prevState) => {
      const newList = [...prevState];
      return newList.splice(1).concat(newList);
    });
  }, [list, fistLoadComponent]);

  const handleShiftList = useCallback(
    (count: number) => {
      setList((prevState) => {
        const newList = [...prevState];
        const shift = -Math.abs(count);
        return newList.splice(shift).concat(newList);
      });
    },
    [list],
  );

  const updateVisibleItems = useCallback(
    (index: number, visible: number) => {
      setVisibleItems((prevState) => {
        const x = [...prevState];
        x[index] = visible;
        return x;
      });
    },
    [list],
  );

  useEffect(() => {
    if (fistLoadComponent.current && visibleItems.length > 0 && visibleItems.length === list.length) {
      const shift = visibleItems.findIndex((e) => e === 1);
      if (shift > 0) {
        handleShiftList(shift);
        fistLoadComponent.current = false;
      }
    }
  }, [visibleItems, list, fistLoadComponent.current]);

  const preparedList = useMemo(() => {
    return list.map((itemList) => (
      <Card data={itemList} key={itemList.id} wrapper={wrapperRef.current} updateVisibleItems={updateVisibleItems} />
    ));
  }, [list, wrapperRef.current]);

  return (
    <Wrapper ref={wrapperRef}>
      {visibleArrow && (
        <Button name="iconArrowLeft" round shadow gradient onClick={handleDecrementList} direction="left" />
      )}
      <Grid>{preparedList}</Grid>
      {visibleArrow && (
        <Button name="iconArrowRight" round shadow gradient onClick={handleIncrementList} direction="right" />
      )}
    </Wrapper>
  );
};

export default Directions;
