import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { TableItemProps, ViewMode } from 'web';

import { AlcoholContentsLimit, ProductAttribute, ProductPriceLimit, ProductsApiProductsListRequest } from 'services';

import PaginationComponent from '../pagination';
import Filters from './filters';
import { BREAKPOINTS } from '../../../utils/constants';

const Section = styled.section`
  width: 100%;
  position: relative;
  overflow: hidden;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    overflow-x: visible;
    overflow-y: unset;
  }
`;

const TableSection = styled(Section)``;

const PaginationWrapper = styled.div`
  top: calc(100% + 62px);
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
`;

interface Props<T> {
  count?: number;
  limit?: number;
  currentPage?: number;
  cards: React.ReactElement<{ items: T[] }>;
  onPagination?: (value: number) => void;
}

type ListProps<T> = Required<{
  withList: boolean;
  viewMode: ViewMode;
  list: React.ReactElement<{ items: T[] }>;
  onModeChange: (value: ViewMode) => void;
}>;

type FiltersProps<T> = Required<{
  attributes: T[];
  withFilters: boolean;
  priceLimits: ProductPriceLimit;
  alcoholLimits: AlcoholContentsLimit;
  // TODO: consider to make it generic
  defaultValues: Omit<Partial<ProductsApiProductsListRequest>, 'page' | 'limit'>;
  // onClear?: () => void;
  onSearch: (value: ProductsApiProductsListRequest) => void;
}>;

type TableProps<T extends TableItemProps, A extends ProductAttribute> = Props<T> &
  (NonNullable<unknown> | ListProps<T> | FiltersProps<A> | (ListProps<T> & FiltersProps<A>));

function Table<T extends TableItemProps, A extends ProductAttribute>({
  count,
  limit,
  currentPage,
  onPagination,
  ...restProps
}: TableProps<T, A>) {
  // const [mode, setMode] = useState<ViewMode>('card');

  const withList = useMemo(() => 'withList' in restProps && restProps.withList, [restProps]);

  const onFiltersModeClick = useCallback(
    (value: ViewMode) => {
      // setMode(() => value);

      if ('onModeChange' in restProps) {
        restProps.onModeChange(value);
      }
    },
    [restProps],
  );

  const TableFilters = useCallback(() => {
    return 'withFilters' in restProps && restProps.withFilters ? (
      <Section>
        <Filters<A>
          withList={withList}
          priceLimits={restProps.priceLimits}
          alcoholLimits={restProps.alcoholLimits}
          attributes={restProps.attributes}
          defaultValues={restProps.defaultValues}
          onClick={onFiltersModeClick}
          // onClear={restProps.onClear}
          onSearch={restProps.onSearch}
        />
      </Section>
    ) : null;
  }, [withList, restProps, onFiltersModeClick]);

  const TableContent = useCallback(() => {
    return 'withList' in restProps && restProps.withList && restProps.viewMode === 'list'
      ? restProps.list
      : restProps.cards;
  }, [restProps]);

  const TablePagination = useCallback(() => {
    return onPagination ? (
      <PaginationWrapper>
        <PaginationComponent count={count} limit={limit} current={currentPage} onPagination={onPagination} />
      </PaginationWrapper>
    ) : null;
  }, [count, limit, currentPage, onPagination]);

  return (
    <>
      <TableFilters />

      <TableSection>
        <TableContent />
        <TablePagination />
      </TableSection>
    </>
  );
}

export default Table;
