import React from 'react';
import styled from 'styled-components';

import { Company } from '../../../../services';

import Link from '../../../base-ui/link';

const StyledLink = styled(Link)`
  height: 60px;
  display: block;
  padding-left: 15px;
  padding-right: 15px;
  &[href='/'] {
    pointer-events: none;
    cursor: default;
  }
  &:not([href='/']) {
    &:hover {
      transform: scale(1.3) translateY(2px);
    }
  }
`;

const StyledImg = styled.img`
  height: 40px;
  width: 135px;
  object-fit: contain;
  filter: grayscale(100%) contrast(50%);
  transition: filter 0.5s;
  :hover {
    filter: unset;
  }
`;

interface ItemProps {
  data: Company;
}

const Item: React.FC<ItemProps> = ({ data }) => (
  <StyledLink target="_blank" to={data.link ?? ''}>
    <StyledImg src={data.icon} />
  </StyledLink>
);

export default Item;
