import React from 'react';
import BaseSection from 'components/base-ui/section';
import { Column, Header, Point, PointsWrapper, Wrapper } from './styled';
import { HeaderIcon, PointIcon } from './components';

type Props = {
  data: {
    header: string;
    points: string[];
  }[];
};

export const CreatorsListingColumnsBlock: React.FC<Props> = (props) => {
  return (
    <Wrapper>
      {props.data.map((column, index) => (
        <Column key={column.header} className={index === props.data.length - 1 ? 'last' : undefined}>
          <Header>
            <HeaderIcon index={index} count={props.data.length} />
            {column.header}
          </Header>
          <PointsWrapper>
            {column.points.map((point) => (
              <Point key={point}>
                <PointIcon index={index} count={props.data.length} />
                {point}
              </Point>
            ))}
          </PointsWrapper>
        </Column>
      ))}
    </Wrapper>
  );
};
