import styled from 'styled-components';
import { TextButton } from 'components/base-ui/button';
import { COLORS } from 'utils/constants';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
`;
export const Row = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  height: 100%;
  margin-bottom: 70px;
`;
export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 80px;
  :first-child {
    margin-left: 0;
  }
`;
export const ButtonWrapper = styled.div`
  margin-bottom: 76px;
`;
export const Button = styled(TextButton)`
  color: ${(props) => (props.disabled ? COLORS.gray5 : COLORS.secondary)};
  font-size: 14px;
  border: 1px solid ${(props) => (props.disabled ? COLORS.gray5 : COLORS.secondary)};
  width: 160px;
  height: 48px;
  text-transform: uppercase;
`;
