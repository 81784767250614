import React from 'react';
import styled from 'styled-components';

import { ButtonLink } from 'web';

import { BREAKPOINTS } from 'utils/constants';

import IconButton from 'components/base-ui/icon-button';
import { HtmlRenderer } from 'utils/htmlParser';

const Div = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-rows: 1fr auto auto;
  padding: 168px 16px 16px 16px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 224px 152px 207.5px 56px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding: 224px 80px 207.5px 136px;
    max-width: calc(656px + 80px + 136px);
  }
`;

const Header = styled.div`
  display: grid;
  min-height: 0;
  position: relative;
  margin-bottom: 16px;
  grid-template-rows: auto 1fr;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin-bottom: 24px;
  }
`;

const Description = styled.div`
  overflow-y: auto;
  padding-right: 16px;

  & > p {
    font-size: 14px;
    line-height: 16px;
  }

  h4 {
    font-size: 20px;
    line-height: 26px;
    margin: 0 0 20px 0;
    font-weight: 400;
  }
`;

const CloseWrapper = styled.div`
  top: 8px;
  right: 0;
  width: 16px;
  height: 16px;
  position: absolute;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    top: 0;
    right: unset;
    left: calc(100% + 40px);
  }
`;

interface DetailProps {
  text: string;
  buttonLink: ButtonLink;
  onClick: () => void;
}

const Detail: React.FC<DetailProps> = ({ text, buttonLink, onClick }) => {
  return (
    <Div>
      <Header>
        <Description>
          <HtmlRenderer html={text} />
        </Description>

        <CloseWrapper>
          <IconButton name="iconCrossSmall" onClick={onClick} />
        </CloseWrapper>
      </Header>
    </Div>
  );
};

export default Detail;
