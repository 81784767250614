import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import { ParagraphValue } from 'web';

import HtmlContainer from '../html-container';

import './index.css';
import { HtmlRenderer } from '../../../utils/htmlParser';

interface TabsComponentProps {
  items: ParagraphValue[];
}

const TabsComponent: React.FC<TabsComponentProps> = ({ items }) => {
  return (
    <Tabs>
      <TabList>
        {items.map((el) => (
          <Tab key={el.header}>{el.header}</Tab>
        ))}
      </TabList>

      {items.map((el) => (
        <TabPanel key={el.header}>
          <HtmlContainer>
            <HtmlRenderer html={el.text} />
          </HtmlContainer>
        </TabPanel>
      ))}
    </Tabs>
  );
};

export default TabsComponent;
