import styled from 'styled-components';
import { BREAKPOINTS } from '../../../utils/constants';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-top: 32px;
  align-items: center;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
  }
  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    gap: 80px;
  }
`;

const Title = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 32px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-bottom: 35px;
`;

export const Styled = { Container, Title, Content };
