import React, { useMemo } from 'react';
import iconAlertCircle from 'assets/icons/icon_alert_circle.svg';
import { observer } from 'mobx-react-lite';
import { useCartStore } from 'store';
import {
  Container,
  Title,
  SubTitle,
  StepContainer,
  StepItem,
  StepNumber,
  StepTitle,
  StepNumberInner,
  Attention,
} from './styles';

export const WineInstruction: React.FC = observer(() => {
  const { wineInstruction } = useCartStore();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const parseRes = (el) => {
    if (el && Array.isArray(el) && el.length > 0) {
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,
      @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call */
      const hasProperty = el[0].hasOwnProperty('value');
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (hasProperty && Array.isArray(el[0].value)) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        return el[0].value as string[];
      }
      return [];
    }
    return [];
  };

  const renderSteps = useMemo(() => {
    const steps = parseRes(wineInstruction.wineCardInstruction);
    return steps.map((item, index) => {
      return (
        <StepItem>
          <StepNumber>
            <StepNumberInner>{index + 1}</StepNumberInner>
          </StepNumber>
          <StepTitle>{item}</StepTitle>
        </StepItem>
      );
    });
  }, [wineInstruction]);

  return (
    <Container>
      <Title>{wineInstruction.header}</Title>
      <SubTitle>{wineInstruction.description}</SubTitle>
      <StepContainer>{renderSteps}</StepContainer>
      <Attention>
        <img width={20} height={20} src={iconAlertCircle} alt="Внимание!" />
        {wineInstruction.note}
      </Attention>
    </Container>
  );
});
