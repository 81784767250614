import React, { createContext, useContext } from 'react';

import InsuranceProductsStore from './store';

const store = new InsuranceProductsStore();

const InsuranceProductsStateContext = createContext<InsuranceProductsStore>(store);

export const InsuranceProductsStateProvider: React.FC = ({ children }) => (
  <InsuranceProductsStateContext.Provider value={store}>{children}</InsuranceProductsStateContext.Provider>
);

export const useInsuranceProductsStore = (): InsuranceProductsStore => useContext(InsuranceProductsStateContext);
