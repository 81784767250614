import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';

export const StInfo = styled.div`
  font-size: 18px;
  font-style: normal;
  line-height: 28px;
  color: ${COLORS.gray3};
  width: 288px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 656px;
  }

  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    width: 644px;
  }

  @media screen and (min-width: ${BREAKPOINTS.wideDesktop}) {
    width: 732px;
  }
`;

export const StTitle = styled.div`
  font-weight: 700;
  margin-bottom: 16px;

  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    margin-bottom: 24px;
  }
`;

export const StText = styled.div`
  font-weight: 400;
`;
