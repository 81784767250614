import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BigCard, MediumCard, SmallCard } from 'components/articles/cards';
import { MagazineArticle } from 'services';
import _ from 'lodash';
import { Button, ButtonWrapper, Container, Row } from './styles';
import { LikeModalContent } from '../../../components/base-ui/like-modal-content';
import { LikeModal } from '../../../components/base-ui/like-modal';

interface ArticleTabletProps {
  data: MagazineArticle[];
}

const LIKES_LIMIT = 70;

export const ArticlesTablet: React.FC<ArticleTabletProps> = ({ data }) => {
  const [paginationData, setPaginationData] = useState<MagazineArticle[][]>([]);
  const [showModal, setShowModal] = useState(false);
  const [articleId, setArticleId] = useState<number | null>(null);

  const LIMIT = 8;

  useEffect(() => {
    setPaginationData([data.slice(0, LIMIT)]);
  }, []);

  const onClick = useCallback(() => {
    setPaginationData(_.chunk(data, LIMIT));
  }, [data]);

  const isButtonActive = useMemo(() => {
    return data.length > LIMIT && paginationData.length < 2;
  }, [data, paginationData]);

  const onShowModal = useCallback((id: number) => {
    setArticleId(id);
    setShowModal(true);
  }, []);

  return (
    <>
      <Container>
        {paginationData.map((item) => (
          <>
            <Row>
              <BigCard onShowModal={onShowModal} {...item[0]} />
            </Row>

            <Row>
              <MediumCard onShowModal={onShowModal} {...item[1]} />
              <MediumCard onShowModal={onShowModal} {...item[2]} />
            </Row>

            <Row>
              <SmallCard onShowModal={onShowModal} {...item[3]} />
              <SmallCard onShowModal={onShowModal} {...item[4]} />
            </Row>

            <Row>
              <BigCard onShowModal={onShowModal} {...item[5]} />
            </Row>

            <Row>
              <SmallCard onShowModal={onShowModal} {...item[6]} />
              <SmallCard onShowModal={onShowModal} {...item[7]} />
            </Row>
          </>
        ))}

        <ButtonWrapper>
          {!!data.length && (
            <Button onClick={onClick} disabled={!isButtonActive}>
              {' '}
              Показать все{' '}
            </Button>
          )}
        </ButtonWrapper>
      </Container>
      <LikeModal visible={showModal} onClose={() => setShowModal(false)}>
        <LikeModalContent cardType="article" id={articleId} limitPerPage={LIKES_LIMIT} />
      </LikeModal>
    </>
  );
};
