import React, { createContext, useContext } from 'react';

import CategoryStore from './store';

const store = new CategoryStore();

const CategoryStateContext = createContext<CategoryStore>(store);

export const CategoryStateProvider: React.FC = ({ children }) => (
  <CategoryStateContext.Provider value={store}>{children}</CategoryStateContext.Provider>
);

export const useCategoryStore = (): CategoryStore => useContext(CategoryStateContext);
