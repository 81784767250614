import React from 'react';
import { observer } from 'mobx-react-lite';
import { usePageContentStore } from 'store';
import { VerifyModal } from './verify-modal';
import { VerifyContent } from './verify-content';

interface StepProps {
  next: () => void;
  cancel: () => void;
}

enum ParagraphAreas {
  loginCode = 'loginCode',
}
enum ParagraphElementsSlugs {
  loginCodeForm = 'login-code-form',
}
const pageSlug = 'login-code';

const StepTwo: React.FC<StepProps> = observer((props) => {
  const pageContentStore = usePageContentStore<ParagraphElementsSlugs, ParagraphAreas>();

  React.useEffect(() => {
    pageContentStore.fetchContent(
      { slug: pageSlug },
      {
        [ParagraphElementsSlugs.loginCodeForm]: ParagraphAreas.loginCode,
      },
    );
  }, [pageSlug]);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const infoText = React.useMemo(() => {
    const info = pageContentStore.content[ParagraphAreas.loginCode];
    if (!info) return;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return info?.filter((item) => item.type === 'text')?.map((item) => item.value);
  }, [pageContentStore.content[ParagraphAreas.loginCode]]);

  return (
    <VerifyModal onCancel={props.cancel}>
      <VerifyContent infoText={infoText ?? []} />
    </VerifyModal>
  );
});

export default StepTwo;
