import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { api } from 'utils/api';
import { Block } from 'utils/blocks';
import { BREAKPOINTS } from 'utils/constants';

import { BlocksRender } from 'components/renderers';

import BreadcrumbsWrapper from 'components/base-ui/breadcrumbs-wrapper';
import Breadcrumbs from 'components/base-ui/breadcrumbs';

const Div = styled.div`
  padding: 0 0 48px 0;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 0 0 256px 0;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding: 0 136px 232px 136px;
  }
`;

const StyledBreadcrumbsWrapper = styled(BreadcrumbsWrapper)`
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 0 56px 0 56px;
  }
`;

const Market: React.FC = observer(() => {
  const [fetching, setFetching] = useState(false);
  const [blocks, setContent] = useState<Block[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      setFetching(() => true);

      const { data } = await api.globalCategoriesPage.gloabalCategoryPageList();
      setContent(() => data.content as unknown as Block[]);
      setFetching(() => false);
    };

    fetchData();
  }, [setFetching, setContent]);

  return (
    <Div>
      <StyledBreadcrumbsWrapper>
        <Breadcrumbs />
      </StyledBreadcrumbsWrapper>

      <BlocksRender blocks={blocks} loading={fetching} />
    </Div>
  );
});

export default Market;
