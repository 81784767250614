import React from 'react';
import { AwardWinners } from 'services';
import { Container, Header, Image, ImageWrapper, SubHeader, Text } from './styles';

interface ItemProps {
  winner: AwardWinners;
}

export const Item: React.FC<ItemProps> = ({ winner }) => {
  return (
    <Container>
      {winner && (
        <>
          <ImageWrapper>
            <Image src={winner.image} />
          </ImageWrapper>
          <Header>{winner.title}</Header>
          <SubHeader>{winner.subTitle}</SubHeader>
          <Text>{winner.text}</Text>
        </>
      )}
    </Container>
  );
};
