import { makeObservable, observable, action } from 'mobx';

import { Nullable } from 'web';

import { api } from 'utils/api';

import { InsuranceCategory } from 'services';

export default class InsuranceCategoryStore {
  fetching = false;

  error: Nullable<Error> = null;

  category: Nullable<InsuranceCategory> = null;

  constructor() {
    makeObservable(this, {
      fetching: observable,
      error: observable,
      category: observable,
      fetchCategory: action,
      setError: action,
      setFetching: action,
      setCategory: action,
      closeErrorScreen: action,
    });
  }

  fetchCategory(options?: unknown): Promise<void> {
    this.setError(null);
    this.setFetching(true);

    return api.insuranceCategory
      .insuranceCategoryList(options)
      .then((res) => this.setCategory(res.data))
      .catch((err: Error) => this.setError(err))
      .finally(() => this.setFetching(false));
  }

  closeErrorScreen = () => {
    this.setError(null);

    api.closeErrorScreen
      .closeErrorScreenCreate({
        data: {
          screen: this.category?.errorScreen?.id || 0,
        },
      })
      .then(() => this.fetchCategory())
      .catch((err: Error) => this.setError(err));
  };

  setFetching(value: boolean): void {
    this.fetching = value;
  }

  setError(value: Nullable<Error>): void {
    this.error = value;
  }

  setCategory(value: Nullable<InsuranceCategory>): void {
    this.category = value;
  }
}
