import React, { useEffect, useMemo } from 'react';
import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';

import { useCompanyStore } from '../../../store';

import { useDeviceType } from '../../../utils/hooks';

import { PropsWithSectionOptions } from '../blocks';

import BaseSection from '../../base-ui/section';
import CompaniesGraph from '../../about/companies-graph';

type CompanyGroupAnimatedProps = PropsWithSectionOptions<object>;

export const CompanyGroupAnimated: React.FC<CompanyGroupAnimatedProps> = observer(({ options }) => {
  const deviceType = useDeviceType();

  const isDesktop = useMemo(() => deviceType === 'desktop', [deviceType]);

  const store = useCompanyStore();

  useEffect(() => autorun(() => store.fetchCompanies()), [store]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {!store.error && !store.fetching && isDesktop && store.graphData && (
        <BaseSection
          heading="Компании Группы и Компании-Партнеры"
          paddingless
          noLastOfTypeMargin={options?.noLastOfTypeMargin}
        >
          <CompaniesGraph data={store.graphData} />
        </BaseSection>
      )}
    </>
  );
});
