import React, { useCallback, ChangeEvent } from 'react';
import styled from 'styled-components';

import Input from '../input';
import IconButton from '../icon-button';
import { validateNumber } from '../../../utils/validators';

const Div = styled.div`
  display: flex;
  align-items: center;
`;

const StyledButton = styled(IconButton)`
  height: 16px;
`;

const StyledInput = styled(Input)`
  width: 32px;
  height: 28px;
  margin: 0 3px;
  padding: 2px 4px;
  font-size: 18px;
  line-height: 24px;
  text-align: center;

  /* Chrome, Safari, Edge, Opera */
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  -moz-appearance: textfield;
`;

interface InputAmountProps {
  id?: string;
  value: number;
  placeholder?: string;
  className?: string;
  min?: number;
  max?: number;
  onChange?: (data: number) => void;
}

const InputAmount: React.FC<InputAmountProps> = ({
  id = 'amount',
  placeholder = '0',
  value = 0,
  className,
  min,
  max,
  onChange,
}) => {
  const decrement = useCallback(() => {
    onChange && onChange(validateNumber(value - 1, { min }));
  }, [value, min, onChange]);

  const increment = useCallback(() => {
    onChange && onChange(validateNumber(value + 1, { max }));
  }, [value, max, onChange]);

  return (
    <Div>
      <StyledButton name="iconMinus" onClick={decrement} />

      <StyledInput
        id={id}
        placeholder={placeholder}
        className={className}
        type="number"
        min={min ?? 0}
        max={max}
        value={value}
        onChange={(evt: ChangeEvent<HTMLInputElement>) => {
          evt.persist();
          onChange && onChange(parseInt(evt.target.value));
        }}
      />

      <StyledButton name="iconPlus" onClick={increment} />
    </Div>
  );
};

export default InputAmount;
