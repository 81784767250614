import React from 'react';
import { AboutPartnersPartner } from 'web';
import { StContainer } from './styled';
import { AboutPartnersHeader } from './components/header';
import { PartnersItem } from './components/partnersItem';

interface Props {
  header: string;
  text: string;
  partners: AboutPartnersPartner[];
}

export const AboutPartners: React.FC<Props> = (props) => {
  return (
    <StContainer>
      <AboutPartnersHeader header={props.header} text={props.text} />
      {props.partners.map((partner) => (
        <PartnersItem partner={partner} key={partner.text} />
      ))}
    </StContainer>
  );
};
