import React, { useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { AwardParticipants } from 'services';
import { Container } from 'components/base-ui/container';
import { Gallery, Photo } from 'components/assol-winners/WinnersAndNominees/ui/shared';
import { useDeviceState } from 'hooks/useDeviceProvider';
import { Styled } from '../styles';
import { Description } from '../Description';
import { Participants } from '../Participants';

interface Props {
  participant: AwardParticipants;
}

export const TeamContent: React.FC<Props> = observer((props) => {
  const [height, setHeight] = useState(0);
  const { isLessTablet, isLessLaptop } = useDeviceState();

  const hasPhoto = useMemo(() => {
    if (props.participant && props.participant.items.length > 4) return false;
    return props.participant?.items.some((item) => !!item.image);
  }, [props.participant]);

  const withImage = useMemo(() => {
    if (hasPhoto) return false;
    return !!props.participant?.image;
  }, [hasPhoto]);

  const isTwoColumns = useMemo(() => {
    return (hasPhoto || withImage) && !isLessLaptop;
  }, [hasPhoto]);

  const measuredRef = useCallback((node: HTMLDivElement) => {
    if (node) {
      setHeight(node.getBoundingClientRect().height);
    }
  }, []);

  return (
    <Styled.Container isTwoColumn={isTwoColumns}>
      <Styled.Title>{props.participant.projectName}</Styled.Title>
      <Container>
        <Styled.Card ref={measuredRef}>
          {hasPhoto && <Gallery type="modal" participants={props.participant.items} />}
          {withImage && <Photo withDots={false} variant="modal" src={props.participant.image!} />}
          {props.participant.description && (
            <Description description={props.participant.description} collapsable={isLessTablet} />
          )}
          {!isTwoColumns && <Participants participants={props.participant?.items} collapsable={isLessTablet} />}
        </Styled.Card>
        {isTwoColumns && height && (
          <>
            <Styled.Divider /> <Participants height={height} participants={props.participant?.items} />
          </>
        )}
      </Container>
    </Styled.Container>
  );
});
