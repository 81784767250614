import React, { createContext, useContext } from 'react';
import AboutUsStore from './store';

const store = new AboutUsStore();

const AboutUsStateContext = createContext<AboutUsStore>(store);

export const AboutUsStateProvider: React.FC = ({ children }) => (
  <AboutUsStateContext.Provider value={store}>{children} </AboutUsStateContext.Provider>
);

export const useAboutUsStore = (): AboutUsStore => useContext(AboutUsStateContext);
