import React, { createContext, useContext } from 'react';

import GlobalCategoriesStore from './store';

const store = new GlobalCategoriesStore();

const GlobalCategoriesStateContext = createContext<GlobalCategoriesStore>(store);

export const GlobalCategoriesStateProvider: React.FC = ({ children }) => (
  <GlobalCategoriesStateContext.Provider value={store}>{children}</GlobalCategoriesStateContext.Provider>
);

export const useGlobalCategoriesStore = (): GlobalCategoriesStore => useContext(GlobalCategoriesStateContext);
