import React from 'react';
import { Container } from 'components/base-ui/container';
import { ReactComponent as IconArrowRight } from 'assets/icons/icon_arrow_grey.svg';
import { Styled } from './styles';

interface CardProps {
  title: string;
  id: number;
  src?: string | null;
  subtitle?: string | null;
  onClick: (id: number) => void;
}

export const Card: React.VFC<CardProps> = (props) => {
  const handleOnClick = () => {
    return props.onClick(props.id);
  };

  return (
    <Styled.Wrapper onClick={handleOnClick}>
      {props.src && (
        <Styled.ImgWrapper>
          <img src={props.src} alt={props.title} />
        </Styled.ImgWrapper>
      )}
      <Container gap="16px">
        <Container direction="column">
          <Styled.Title>{props.title}</Styled.Title>
          {props.subtitle && <Styled.Subtitle>{props.subtitle}</Styled.Subtitle>}
        </Container>
        <Styled.IconWrapper>
          <IconArrowRight />
        </Styled.IconWrapper>
      </Container>
    </Styled.Wrapper>
  );
};
