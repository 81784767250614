import styled from 'styled-components';
import { BREAKPOINTS } from 'utils/constants';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;
  padding: 0 16px;
  gap: 32px 80px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    flex-direction: row;
    align-items: flex-start;
    gap: 40px 96px;
    padding: 0;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    gap: 40px 80px;
  }
`;

export const SwiperContainer = styled.div`
  position: relative;
  width: 100%;

  .swiper-slide {
    width: 256px;
    margin: 0 24px 0 0;
    @media screen and (min-width: ${BREAKPOINTS.tablet}) {
      margin: 0 64px 0 0;
    }

    &:first-child {
      margin-left: 16px;
      @media screen and (min-width: ${BREAKPOINTS.tablet}) {
        margin-left: calc((100% - 608px) / 2);
      }
    }

    &:last-child {
      margin-right: 16px;
      @media screen and (min-width: ${BREAKPOINTS.tablet}) {
        margin-right: calc((100% - 608px) / 2);
      }
    }
  }
`;
export const Styled = { Container, SwiperContainer };
