import styled from 'styled-components';
import { BREAKPOINTS, HEADER_HEIGHT, COLORS } from 'utils/constants';

interface ItemBlurProps {
  color: string;
}

export const Div = styled.div``;

export const Container = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    box-shadow: 0 3px 18px 0 rgba(0, 0, 0, 0.75);
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: 100%;
    flex-direction: row-reverse;
    justify-content: space-between;
    height: calc(100vh - ${HEADER_HEIGHT});
    box-shadow: none;
  }
`;

export const Main = styled.div`
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    background-color: unset;
    display: inline-flex;
    flex-direction: column;
    height: 216px;
    position: relative;
    color: white;
    transition: all 0.4s;
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    display: block;
    height: unset;
    position: absolute;
    bottom: 48px;
    left: 48px;
    opacity: 0;
    color: white;
    white-space: nowrap;
    transition: all 0.4s;
  }
`;

export const MailMobile = styled.div<ItemBlurProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${(props) => props.color};
  color: white;
  height: 107px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 16px 28px 0 16px;
`;

export const ItemBlur = styled.div<ItemBlurProps>`
  display: none;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    display: block;
    position: absolute;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    &:after {
      content: '';
      left: 0;
      position: absolute;
      height: 100%;
      width: 70%;
      mix-blend-mode: multiply;
      background-size: auto;
      background-repeat: no-repeat;
      background-position: top 0 left 0;
      background: ${(props) => props.color};
    }
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: inherit;
    width: 100%;
    &:after {
      content: '';
      bottom: 0;
      left: 0;
      position: absolute;
      height: 330px;
      width: inherit;
      mix-blend-mode: multiply;
      background-size: auto;
      background-repeat: no-repeat;
      background-position: top 0 left 0;
      background: ${(props) => props.color};
    }
  }
`;

export const Step = styled.div`
  position: absolute;
  font-weight: 600;
  font-size: 44px;
  color: white;
  user-select: none;
  line-height: 1;
  bottom: 15px;
  right: 15px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    position: static;
    font-size: 100px;
    font-weight: 600;
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    position: absolute;
    width: 116px;
    font-size: 24px;
    font-weight: 300;
    bottom: 14px;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 14px;
  }
`;

export const StepVrTxt = styled.div`
  display: none;
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    display: block;
    font-size: 24px;
    font-weight: 600;
    writing-mode: vertical-rl;
    transform: rotate(-180deg);
    margin-bottom: 20px;
    text-transform: uppercase;
  }
`;

export const Item = styled.div<{ src: string }>`
  position: relative;
  min-height: 224px;
  background: url(${(props) => props.src}) no-repeat right/cover;
  padding: 24px 16px;
  color: ${COLORS.white};

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 51px 44px;
    height: 216px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    justify-content: flex-end;
    align-items: flex-end;
    overflow: hidden;
    cursor: default;
    height: 100%;
    transition: flex-basis 0.7s;
    flex-basis: 116px;
    min-width: 116px;
    padding: 0;
    &:last-child {
      flex-basis: 100%;
      ${Main} {
        opacity: 1;
      }
      ${Step} {
        opacity: 0;
      }
    }
    &:hover {
      flex-basis: 100%;
      & ~ div {
        flex-basis: 116px;
        ${Main} {
          opacity: 0;
        }
        ${Step} {
          opacity: 1;
        }
      }
      ${Main} {
        opacity: 1;
      }
      ${Step} {
        display: none;
      }
    }
  }
`;

export const Number = styled.div`
  display: none;
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    display: block;
    font-size: 100px;
    font-weight: 600;
  }
`;

export const Title = styled.div`
  font-size: 24px;
  line-height: 26px;
  text-transform: uppercase;
  font-weight: 600;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding-top: 61px;
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding-top: 16px;
  }
`;
export const Description = styled.div`
  font-size: 14px;
  line-height: 28px;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 70%;
    font-size: 18px;
    line-height: 28px;
    margin-top: 10px;
    margin-right: 200px;
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: unset;
    margin-right: unset;
    -webkit-line-clamp: 3;
    margin-top: 16px;
  }
`;
