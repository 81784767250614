import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import { Comment } from '../../../../services';

import { COLORS } from '../../../../utils/constants';

import Collapse from '../../../base-ui/collapse';
import Icon from '../../../base-ui/icon';
import { Small } from '../../../base-ui/typo';
import { TextButton } from '../../../base-ui/button';
import Footer from './footer';
import GoodCommentsItem from './comment';
import { pluralize } from '../../../../utils/lang';

const Container = styled.div`
  display: grid;
  row-gap: 28px;
  grid-auto-rows: auto;
`;

const CommentsList = styled.ul`
  margin: 0;
  padding: 0;
  display: grid;
  row-gap: 16px;
  list-style: none;
`;

const More = styled.div`
  color: ${COLORS.primary};
`;

const MoreButton = styled(TextButton)`
  display: grid;
  color: inherit;
  column-gap: 8px;
  grid-template-columns: 16px auto;
`;

const MoreIcon = styled(Icon)`
  width: 16px;
  height: 16px;
`;

const MoreText = styled(Small)`
  color: inherit;
`;

interface CommentsProps {
  items: Comment[];
}

const Comments: React.FC<CommentsProps> = ({ items }) => {
  const [showAll, setShowAll] = useState(false);

  const [previewComments, otherComments] = useMemo(() => {
    const filtered = items.filter((el) => el.show);
    return showAll ? [filtered, []] : [filtered.slice(0, 2), filtered.slice(2)];
  }, [items, showAll]);

  const otherCommentsCount = useMemo(() => otherComments.length, [otherComments.length]);

  const onShowAllClick = useCallback(() => setShowAll(() => true), [setShowAll]);

  const pluralizeCommentCount = useMemo(() => {
    return pluralize(otherCommentsCount, ['отзыв', 'отзыва', 'отзывов']);
  }, [otherCommentsCount]);

  return (
    <Collapse title="Отзывы" value={true}>
      <Container>
        <CommentsList>
          {previewComments.map((el) => (
            <GoodCommentsItem key={el.id} data={el} />
          ))}
        </CommentsList>

        {!showAll && !!otherCommentsCount && (
          <More>
            <MoreButton onClick={onShowAllClick}>
              <MoreIcon name="iconViewmore" />
              <MoreText>Еще {pluralizeCommentCount}</MoreText>
            </MoreButton>
          </More>
        )}

        <Footer />
      </Container>
    </Collapse>
  );
};

export default Comments;
