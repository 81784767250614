import { css } from 'styled-components';

import { BREAKPOINTS, COLORS } from '../utils/constants';

const marginlessStyles = css`
  margin-top: 0;
`;

export const h1Styles = css`
  ${marginlessStyles};

  font-weight: 700;

  font-size: 28px;
  line-height: 32px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-size: 44px;
    line-height: 48px;
  }
`;

export const h2Styles = css`
  ${marginlessStyles};

  font-size: 24px;
  line-height: 32px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-size: 34px;
    line-height: 40px;
  }
`;

export const h3Styles = css`
  ${marginlessStyles};

  font-size: 22px;
  line-height: 28px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-size: 28px;
    line-height: 32px;
  }
`;

export const h4Styles = css`
  ${marginlessStyles};

  font-size: 21px;
  line-height: 24px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const h5Styles = css`
  ${marginlessStyles};

  font-size: 20px;
  line-height: 26px;
`;

interface H6Props {
  bold?: boolean;
}

export const h6Styles = css<H6Props>`
  ${marginlessStyles};

  font-size: 18px;
  line-height: 28px;

  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
`;

interface SpanProps {
  bold?: boolean;
}

export const spanStyles = css<SpanProps>`
  font-size: 16px;
  line-height: 24px;

  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
`;

export const smallStyles = css`
  font-size: 14px;
  line-height: 20px;
`;

export const microStyles = css`
  font-size: 12px;
  line-height: 16px;
`;

export const informerStyles = css`
  font-size: 9px;
  line-height: 16px;
`;

export const capsStyles = css`
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
`;
