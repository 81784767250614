import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 288px;
  margin: 24px 0;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 698px;
    margin: 40px 0;
  }
`;
export const Header = styled.h2`
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: ${COLORS.secondary};
  margin-bottom: 8px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin-bottom: 24px;
  }
`;
export const Content = styled.div`
  img {
    width: 288px;
    height: 288px;
    object-fit: cover;
    float: left;
    margin-bottom: 8px;
    @media screen and (min-width: ${BREAKPOINTS.tablet}) {
      width: 329px;
      height: 329px;
      margin-right: 40px;
    }
  }
  p {
    display: inline;
    font-size: 18px;
    line-height: 28px;
    color: ${COLORS.gray2};
  }
`;
