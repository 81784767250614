import React, { useMemo } from 'react';
import { useCarousel, useDeviceType } from 'utils/hooks';

import Icon from 'components/base-ui/icon';
import { ChronologyDirectionProps } from './types';
import { Container, Item, Title, Year, CarouselBtnRight, CarouselBtnLeft } from './styles';

const VISIBLE_LENGTH = 4;

export const ChronologyCarousel: React.FC<ChronologyDirectionProps> = ({ data }) => {
  const { list, decrement, increment } = useCarousel(data);
  const deviceType = useDeviceType();
  const isMobileOrTablet = useMemo(() => deviceType === 'mobile' || deviceType === 'tablet', [deviceType]);

  const visibleItems = useMemo(() => (isMobileOrTablet ? data.length : VISIBLE_LENGTH), [isMobileOrTablet, data]);

  const itemsToShow = useMemo(() => list.slice(0, visibleItems), [list, visibleItems]);
  return (
    <Container>
      {!isMobileOrTablet && (
        <CarouselBtnLeft onClick={decrement}>
          <Icon name="iconArrowBlack" />
        </CarouselBtnLeft>
      )}
      {itemsToShow.map((el) => (
        <Item key={el.header}>
          <Year>{el.header}</Year>
          <Title>{el.text}</Title>
        </Item>
      ))}
      {!isMobileOrTablet && (
        <CarouselBtnRight onClick={increment}>
          <Icon name="iconArrowBlack" />
        </CarouselBtnRight>
      )}
    </Container>
  );
};
