import React from 'react';
import ScrollToTopOnMount from 'components/base-ui/scroll-to-top';
import { DefaultLayout } from 'layouts';
import { Guide } from 'components/guide';

const GuidePage: React.FC = () => {
  return (
    <>
      <ScrollToTopOnMount />

      <DefaultLayout>
        <Guide />
      </DefaultLayout>
    </>
  );
};

export default GuidePage;
