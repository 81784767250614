import React, { useCallback } from 'react';
import { usePortal } from 'hooks/usePortal';
import ReactDOM from 'react-dom';
import {
  StModalCloseWrapper,
  StModalContainer,
  StModalContent,
  StModalMask,
  StModalOverflow,
  StModalWindow,
} from './styled';
import IconButton from '../icon-button';

interface Props {
  visible: boolean;
  onClose: () => void;
}

export const LikeModal: React.FC<Props> = (props) => {
  const portalRef = usePortal('like-modal');

  React.useEffect(() => {
    if (!props.visible) return;
    document.body.style.overflowY = props.visible ? 'hidden' : 'visible';
  }, [props.visible]);

  const handleModalClose = useCallback(() => {
    document.body.style.overflowY = 'visible';
    props.onClose();
  }, []);

  return props.visible
    ? portalRef &&
        ReactDOM.createPortal(
          <StModalOverflow padding={0}>
            <StModalMask onClick={handleModalClose} />
            <StModalContainer centered={true}>
              <StModalWindow>
                <StModalCloseWrapper>
                  <IconButton name="iconCrossBaseWhite" onClick={handleModalClose} />
                </StModalCloseWrapper>
                <StModalContent>{props.children}</StModalContent>
              </StModalWindow>
            </StModalContainer>
          </StModalOverflow>,
          portalRef,
        )
    : null;
};
