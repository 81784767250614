import styled from 'styled-components';
import { BREAKPOINTS } from 'utils/constants';

export const Container = styled.div<{ isScrollable?: boolean }>`
  display: flex;
  gap: 24px;
  flex-direction: column;
`;

const Title = styled.span`
  padding-left: 16px;
  font-weight: 400;
  font-size: 34px;
  line-height: 40px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin: 0 auto;
    padding-left: 0;
    width: 608px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: 926px;
  }
`;

export const Styled = { Title, Container };
