import React, { useEffect, useState, useCallback, useRef, RefObject } from 'react';
import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { GuidePage } from '../../../../../services';

import { useGuidePagesStore } from '../../../../../store';

import { PAGE_LIMIT } from '../../../../../utils/constants';

import Table from '../../../../base-ui/table';
import Cards from '../../../../renderers/guide-pages/card';
import { LikeModal } from '../../../../base-ui/like-modal';
import { LikeModalContent } from '../../../../base-ui/like-modal-content';
import { useDeviceType } from '../../../../../utils/hooks';

const Container = styled.div``;

const FavoriteGuides: React.FC = observer(() => {
  const store = useGuidePagesStore();

  const ref: RefObject<HTMLDivElement> = useRef(null);

  const [page, setPage] = useState<number>(1);
  const [likeModalNewsId, setLikeModalNewsId] = React.useState<number | null>(null);
  const [showLikeModal, setShowLikeModal] = React.useState(false);
  const device = useDeviceType();

  const likesLimit = React.useMemo(() => {
    if (device === 'mobile') return 70;
    return 40;
  }, [device]);

  const handleShowModal = useCallback((id: number) => {
    setLikeModalNewsId(id);
    setShowLikeModal(true);
  }, []);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  useEffect(() => autorun(() => store.fetchPages({ page, limit: PAGE_LIMIT, isInUserFavorites: true })), [store, page]);

  const onPagination = useCallback(
    (idx: number) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      store.fetchPages({ page: idx, limit: PAGE_LIMIT, isInUserFavorites: true });
      setPage(() => idx);

      if (ref.current) {
        ref.current.scrollIntoView();
      }
    },
    [ref, store, setPage],
  );

  return (
    <Container ref={ref}>
      <Table<GuidePage, never>
        count={store.count}
        currentPage={page}
        cards={<Cards items={store.pages} handleShowModal={handleShowModal} />}
        onPagination={onPagination}
      />
      <LikeModal visible={showLikeModal} onClose={() => setShowLikeModal(false)}>
        <LikeModalContent cardType="guide" id={likeModalNewsId} limitPerPage={likesLimit} />
      </LikeModal>
    </Container>
  );
});

export default FavoriteGuides;
