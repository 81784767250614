import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from '../../../utils/constants';
import { H4, H6 } from '../../base-ui/typo';
import { TextButton } from '../../base-ui/button';

export const Div = styled.div`
  height: 400px;
  padding: 24px 16px;
  border-radius: 2px;
  background: ${COLORS.white};
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.08);

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    height: 352px;
  }
`;

export const Grid = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
`;

export const Header = styled.div`
  padding-bottom: 8px;
  border-bottom: 1px solid ${COLORS.gray5};
`;

export const Heading = styled(H4)`
  font-weight: 400;
  color: ${COLORS.secondary};
`;

export const Body = styled.div`
  padding: 16px 0;
  overflow: auto;
`;

export const ProductList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const Footer = styled.div`
  display: grid;
  row-gap: 12px;
  padding-top: 8px;
`;

export const Total = styled.div`
  display: grid;
  padding-bottom: 28px;
  grid-template-columns: auto 1fr;
  border-bottom: 1px solid ${COLORS.gray5};
`;

export const TotalText = styled(H6)`
  color: ${COLORS.secondary};
`;

export const TotalPrice = styled(H6)`
  text-align: end;
  color: ${COLORS.secondary};
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ActionsButton = styled(TextButton)`
  font-size: 12px;
  line-height: 16px;
  color: ${COLORS.primary};
  text-transform: lowercase;
`;
