import React from 'react';
import BaseSection from 'components/base-ui/section';
import { Wrapper, ItemsWrapper, Item, Header, Text, Number } from './styled';

type Props = {
  data: {
    number: number;
    text: string;
  }[];
};

export const CreatorsHowItWasBlock: React.FC<Props> = (props) => {
  const getColors = (index: number, count: number) => {
    for (let i = 0; i <= count; i += 3) {
      if (index === i) return '#D12B5E';
      if (index === i - 1) return '#C0CD40';
    }
    return '#009CDD';
  };

  return (
    <Wrapper>
      <Header>Как это было</Header>
      <ItemsWrapper>
        {props.data.map((item, index) => (
          <Item key={item.text + item.number}>
            <Number color={getColors(index, props.data.length)}>{item.number}</Number>
            <Text>{item.text}</Text>
          </Item>
        ))}
      </ItemsWrapper>
    </Wrapper>
  );
};
