import styled from 'styled-components';
import { COLORS, BREAKPOINTS } from 'utils/constants';

export const Container = styled.div`
  margin-top: 38px;
  padding: 0 16px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 0;
  }
`;

export const Title = styled.div`
  padding-bottom: 24px;
  border-bottom: 1px solid ${COLORS.gray5};
  font-size: 20px;
  color: #3b5275;
`;

export const SubTitle = styled.div`
  font-size: 16px;
  line-height: 24px;
  padding-top: 14px;
`;

export const StepContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  grid-auto-flow: column;
  margin-top: 68px;
  overflow: auto;
`;

export const StepItem = styled.div`
  position: relative;
  min-width: 120px;
  flex-grow: 1;
  flex-shrink: 0;
  &:not(:last-child) {
    padding-right: 16px;
    @media screen and (min-width: ${BREAKPOINTS.tablet}) {
      padding-right: 40px;
    }
  }
  &:not(:last-child):before {
    content: '';
    position: absolute;
    top: 24px;
    width: 100%;
    border-top: 1px solid ${COLORS.gray5};
  }
`;

export const StepNumber = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #a8b4c2;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${COLORS.white};
  font-size: 18px;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    width: 36px;
    height: 36px;
    border-radius: inherit;
    background-color: #6e829d;
  }
`;

export const StepTitle = styled.div`
  margin-top: 24px;
  font-size: 12px;
  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    font-size: 16px;
  }
`;

export const StepNumberInner = styled.div`
  z-index: 2;
`;

export const Attention = styled.div`
  color: ${COLORS.gray4};
  max-width: 286px;
  font-size: 12px;
  line-height: 16px;
  padding-top: 14px;
  border-top: 1px solid ${COLORS.gray4};
  margin-top: 40px;
  display: flex;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin-top: 80px;
  }
  img {
    margin-right: 10px;
  }
`;
