import styled, { css } from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';

export const Container = styled.div<{ height?: number | undefined }>`
  display: flex;
  column-gap: 44px;
  justify-content: flex-start;
  overflow: hidden;
  overflow-x: auto;
  padding-bottom: 8px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 55px 0 55px 24px;
    border-top: 1px solid ${COLORS.gray6};
    border-bottom: 1px solid ${COLORS.gray6};
  }
  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    padding-bottom: 90px;
    justify-content: center;
    overflow: visible;
  }
  ${(props) =>
    props.height &&
    css`
      height: ${props.height}px;
    `}
`;
export const ArrowButton = styled.button<{ direction: 'left' | 'right' }>`
  background: transparent;
  box-shadow: none;
  border: none;
  cursor: pointer;
  display: none;
  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    display: flex;
    margin-top: 13%;
  }
  ${(props) =>
    props.direction === 'left' &&
    css`
      svg {
        transform: rotate(180deg);
      }
    `}
`;
