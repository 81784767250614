import React, { useCallback, useMemo, useState } from 'react';

import { CategoryProps } from 'web';

import { ProductList } from 'services';

import { useCartStore, useProductListStore, useProfileStore } from 'store';

import { useDeviceType } from 'utils/hooks';
import { validateNumber } from 'utils/validators';
import IconButton from 'components/base-ui/icon-button';
import InputAmount from 'components/base-ui/input-amount';
import { Discount, DiscountText } from 'components/base-ui/discount';

import { useChip } from 'components/goods/item/chip';
import { GoodChipContent } from 'components/goods/shared/chip-content';
import { HtmlRenderer } from 'utils/htmlParser';
import {
  AddWrapper,
  Div,
  FavoriteWrapper,
  Grid,
  InputWrapper,
  Price,
  PriceWithoutDiscount,
  PriceWrapper,
  Tag,
  TagsWrapper,
  Title,
  TitleContainer,
  TitleWrapper,
  Wrapper,
  Like,
  LikesScore,
} from 'components/goods/shared/list/item/styles';
import { RatingScore } from 'components/goods/shared/cards/item/styles';
import { COLORS } from 'utils/constants';
import { IconRate } from 'components/base-ui/icon-rate';
import { IconEmptyLike, IconLike } from 'components/base-ui/icon-like';

interface ItemProps {
  data: ProductList;
  category: CategoryProps;
}

const Item: React.FC<ItemProps> = ({ data, category }) => {
  const deviceType = useDeviceType();
  const profileStore = useProfileStore();

  const [amount, setAmount] = useState(1);

  const cartStore = useCartStore();

  const isDesktop = useMemo(() => deviceType === 'desktop', [deviceType]);

  const memoizedPrice = useMemo(() => {
    const price = data.discountPrice || data.price;
    return price ? `${price} руб.` : 'Нет цены';
  }, [data.discountPrice, data.price]);

  const to = useMemo(() => {
    return `/goods/${category.type}/${category.id}/${data.id}`;
  }, [data.id, category.type, category.id]);

  const onChange = useCallback(
    (value: number) => {
      setAmount(() => validateNumber(value, { min: 1 }));
    },
    [setAmount],
  );

  const [trigger, Chip] = useChip();

  const memoizedDiscount = useMemo(() => `-${data.discountPercent}%`, [data.discountPercent]);

  const productProperties = useMemo(() => {
    return data.properties?.map((p) => p.name) || [];
  }, [data.properties]);

  const addToCart = useCallback(() => {
    cartStore.addToCart(Number(data.id), amount);

    trigger();
  }, [data.id, amount, cartStore, trigger]);

  const averageRating = useMemo(() => (data.averageRating ? parseFloat(data.averageRating) : 0), [data.averageRating]);

  const productListStore = useProductListStore();

  const handleLikeClick = useCallback(() => {
    if (data.id) {
      data.isLikedByUser
        ? productListStore.handleLikeDelete(data.id, profileStore.profile?.id!)
        : productListStore.handleLikePost(data.id, profileStore.profile);
    }
  }, [data.isLikedByUser, data.id]);

  return (
    <Div>
      <Chip content={<GoodChipContent product={data} />} />

      <Grid>
        <TitleWrapper>
          <Title to={to}>
            <TitleContainer marginless>
              <HtmlRenderer html={data.name} />
            </TitleContainer>
          </Title>
        </TitleWrapper>

        {{
          /* isDesktop */
        } && (
          <>
            <Wrapper>
              {data.discountPercent && (
                <Discount>
                  <DiscountText>{memoizedDiscount}</DiscountText>
                </Discount>
              )}
            </Wrapper>

            <Wrapper>
              <IconRate color={averageRating ? COLORS.copper : COLORS.white} />
              <RatingScore>{averageRating || null}</RatingScore>
            </Wrapper>
            <FavoriteWrapper>
              <Like onClick={handleLikeClick}>
                {data.isLikedByUser ? <IconLike color={COLORS.copper} /> : <IconEmptyLike color={COLORS.copper} />}
              </Like>
              <LikesScore>{data.likesCount}</LikesScore>
            </FavoriteWrapper>
          </>
        )}

        <TagsWrapper>
          {productProperties.map((el, idx) => (
            <Tag key={el} to={to}>
              {el + (idx === productProperties.length - 1 ? '' : ',')}
            </Tag>
          ))}
        </TagsWrapper>

        <PriceWrapper>
          {isDesktop && data.discountPrice && <PriceWithoutDiscount>{data.price} руб.</PriceWithoutDiscount>}
          <Price>{memoizedPrice}</Price>
        </PriceWrapper>

        <InputWrapper>
          <InputAmount value={amount} onChange={onChange} min={1} />
        </InputWrapper>

        <AddWrapper>
          <IconButton name="iconBag" round gradient onClick={addToCart} />
        </AddWrapper>
      </Grid>
    </Div>
  );
};

export default Item;
