import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { COLORS } from 'utils/constants';

interface OrderingContentProps {
  type: 'product' | 'service';
  onChange: (ord: ProductOrderingEnum) => void;
}

const List = styled.ul`
  padding: 0;
  width: 100%;
  list-style-type: none;
  margin: 0 !important;
`;

const Item = styled.li<{ selected?: boolean }>`
  color: ${COLORS.primary};
  cursor: pointer;
  background-color: #fff;
  padding: 10px;
  margin: 0;

  &:hover {
    color: ${COLORS.secondary};
    background-color: ${COLORS.gray8};
  }
`;

export enum ProductOrderingEnum {
  Name = 'name',
  Price = 'discount_price_or_price',
  DiscountPercent = 'discount_percent',
  Created = 'created',
}

export const ProductOrdering = {
  [ProductOrderingEnum.Name]: 'Название',
  [ProductOrderingEnum.Price]: 'Цена',
  [ProductOrderingEnum.DiscountPercent]: 'Скидка',
  [ProductOrderingEnum.Created]: 'Новизна',
};

export const OrderingContent: React.FC<OrderingContentProps> = ({ type, onChange }) => {
  const [selected, setSelected] = useState<ProductOrderingEnum | undefined>();

  const orderTypes = useMemo(() => {
    const serviceOrdering = {
      [ProductOrderingEnum.Name]: 'Название',
    };
    return type === 'product' ? ProductOrdering : serviceOrdering;
  }, [type]);

  const onSelect = useCallback((key: ProductOrderingEnum) => {
    setSelected(key);
    onChange(key);
  }, []);

  const renderList = useMemo(
    () =>
      Object.entries(orderTypes).map(([key, name]) => (
        <Item onClick={() => onSelect(key as ProductOrderingEnum)} selected={key === selected} children={name} />
      )),
    [orderTypes, onSelect],
  );

  return <List children={renderList} />;
};
