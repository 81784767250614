import styled from 'styled-components';
import Slider from 'react-slick';
import { BREAKPOINTS } from 'utils/constants';

export const StItem = styled.div`
  width: 288px;
  overflow: hidden;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 656px;
  }

  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    width: 644px;
  }

  @media screen and (min-width: ${BREAKPOINTS.wideDesktop}) {
    width: 732px;
  }
`;

export const StImageWrapper = styled.div`
  width: 288px;
  height: 163px;
  overflow: hidden;
  border-radius: 8px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 656px;
    height: 371px;
  }

  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    width: 644px;
    height: 362px;
  }

  @media screen and (min-width: ${BREAKPOINTS.wideDesktop}) {
    width: 732px;
    height: 413px;
  }
`;

export const StImage = styled.img`
  width: 100%;
  height: auto;
`;

export const StSlider = styled(Slider)`
  .slick-next {
    width: 95px;
    z-index: 5;
    padding-right: 32px;
  }

  .slick-prev {
    width: 95px;
    z-index: 5;
    padding-left: 32px;
  }
`;
