import React, { useCallback, useMemo, useRef } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';

import { Nullable, SelectOption } from 'web';

import { useParams } from 'react-router-dom';
import {
  AlcoholContentsLimit,
  ProductAttribute,
  ProductPriceLimit,
  ProductsApiProductsListRequest,
  PropertyProductAttribute,
} from 'services';

import { useGlobalCategoriesStore } from 'store/global-categories';

import { Checkbox } from 'components/base-ui/checkbox-filter';
import { MultiSelect } from 'components/base-ui/multi-select';
import { Container } from 'components/base-ui/container';
import { Block } from 'utils/blocks';
import {
  Body,
  CheckboxContainer,
  CheckboxWrapper,
  CloseButton,
  FilterAside,
  FilterColumns,
  Form,
  Head,
  ResetButton,
  ResetWrapper,
  SubmitButton,
  Title,
} from './styles';
import Rating from './rating';
import Range from './range';
import { useColumnsHeight } from '../useColumnsHeight';

export interface SelectField {
  name: string;
  options: SelectOption[];
}

/*
  FIXME: For some reason using useFieldArray with Controller is extremely bugged
  it doesn't actually change fields, but instead returns a weirdly transformed version of them
  see https://spectrum.chat/react-hook-form/help/usefieldarray-doesnt-update-fields~38f3406b-6237-410e-a357-940f6895f957
*/
export interface SelectValue {
  name: Nullable<SelectOption>;
}

// TODO: Consider making it generic
type Inputs = Omit<Partial<ProductsApiProductsListRequest>, 'page' | 'limit'> & {
  priceRange: [number, number];
  alcoholRange: [number, number];
  properties: SelectValue[];
};

interface MenuProps<T extends ProductAttribute> {
  visible: boolean;
  attributes: T[];
  priceLimits: ProductPriceLimit;
  alcoholLimits: AlcoholContentsLimit;
  // TODO: make it generic
  defaultValues?: Omit<Partial<ProductsApiProductsListRequest>, 'page' | 'limit'>;
  onClose?: () => void;
  onSearch: (value: ProductsApiProductsListRequest) => void;
}

function Menu<T extends ProductAttribute>({
  visible,
  attributes,
  priceLimits,
  alcoholLimits,
  defaultValues,
  onClose,
  // onClear,
  onSearch,
}: MenuProps<T>) {
  const noSelectFilters = ['Цвет', 'Объем, л.', 'Объем, л', 'Объем', 'Крепость'];

  const [min, max] = useMemo((): [number, number] => {
    return priceLimits ? [parseInt(priceLimits.minPrice || '0'), parseInt(priceLimits.maxPrice || '0')] : [0, 0];
  }, [priceLimits]);

  const [alcoholMin, alcoholMax] = useMemo((): [number, number] => {
    return alcoholLimits
      ? [parseInt(alcoholLimits.minAlcoholContent || '0'), parseInt(alcoholLimits.maxAlcoholContent || '0')]
      : [0, 0];
  }, [alcoholLimits]);

  const didReset = useRef(false);
  const prepareOption = useCallback(
    (el: PropertyProductAttribute): SelectOption => ({
      label: el.name,
      value: el.id ? el.id.toString() : el.name,
    }),
    [],
  );

  const prepareAttribute = useCallback(
    (el: ProductAttribute): SelectField => ({
      name: el.name,
      options: el.properties.map(prepareOption),
    }),
    [prepareOption],
  );

  const fieldsOptions = useMemo(() => attributes.map(prepareAttribute), [attributes, prepareAttribute]);

  const { updateFiltersHeights, columnsHeight } = useColumnsHeight(fieldsOptions.length);

  const resetDefaultValues = useCallback(
    (): Inputs => ({
      averageRatingGte: !didReset.current ? defaultValues?.averageRatingGte ?? 0 : 0,
      priceRange: !didReset.current
        ? [defaultValues?.discountPriceOrPriceGte ?? min, defaultValues?.discountPriceOrPriceLte ?? max]
        : [min, max],
      alcoholRange: !didReset.current
        ? [defaultValues?.alcoholContentGte ?? alcoholMin, defaultValues?.alcoholContentLte ?? alcoholMax]
        : [alcoholMin, alcoholMax],
      properties: attributes.map((_, idx) => {
        const el = fieldsOptions[idx].options.find(
          (opt) => defaultValues?.propertiesIdContains?.includes(parseInt(opt.value)),
        );
        return { name: el && !didReset.current ? el : null };
      }),
    }),
    [min, max, didReset, attributes, defaultValues, prepareAttribute],
  );

  const { control, reset, handleSubmit } = useForm<Inputs>({
    defaultValues: resetDefaultValues(),
  });

  const { fields } = useFieldArray<SelectValue>({
    control,
    name: 'properties',
  });

  const onSubmit = useCallback(
    ({ properties, priceRange, alcoholRange, ...values }: Inputs) => {
      const parsedMultiProperties = properties
        .map((el) => {
          if (Array.isArray(el.name)) {
            return el.name.map((el) => el.value);
          }
          return el;
        })
        .filter((el) => Array.isArray(el) && el.length > 0)
        .map((el) => `[${el}]`);
      const priceGte = priceRange?.[0] ? priceRange[0] : min;
      const priceLte = priceRange?.[1] ? priceRange[1] : max;
      const alcoholGte = alcoholRange?.[0] ? alcoholRange[0] : alcoholMin;
      const alcoholLte = alcoholRange?.[1] ? alcoholRange[1] : alcoholMax;
      const data: ProductsApiProductsListRequest = {
        ...values,
        multiselectPropertiesId: `[${parsedMultiProperties}]`,
        discountPriceOrPriceGte: priceGte === min ? undefined : priceGte,
        discountPriceOrPriceLte: priceLte === max ? undefined : priceLte,
        alcoholContentGte: alcoholGte === alcoholMin ? undefined : alcoholGte,
        alcoholContentLte: alcoholLte === alcoholMax ? undefined : alcoholLte,
      };
      onSearch(data);

      if (onClose) {
        onClose();
      }
    },
    [min, max, onSearch, onClose],
  );

  const onReset = useCallback(() => {
    if (!didReset.current) didReset.current = true;
    sessionStorage.clear();
    reset({ ...resetDefaultValues() });
  }, [didReset, reset, resetDefaultValues]);

  const categoriesStore = useGlobalCategoriesStore();

  const { categoryId } = useParams<{ categoryId: string }>();

  const [currentCategory] = useMemo(
    () => categoriesStore.categories.filter((c) => c.id === +categoryId),
    [categoryId, categoriesStore],
  );

  const filterHeader = useMemo(() => {
    const block: Block[] = currentCategory?.page.content as unknown as Block[];
    return block?.filter((val) => val.type === 'filter_header').map((val) => val.value)[0];
  }, [currentCategory]);

  return (
    <Form visible={visible} onSubmit={handleSubmit(onSubmit)}>
      <Head>
        <Title>{filterHeader ?? ''}</Title>
        <CloseButton name="iconCrossBase" onClick={onClose} />
      </Head>
      <Body>
        <FilterColumns height={columnsHeight}>
          <>
            {fields.map((el, idx) => {
              if (!noSelectFilters.includes(fieldsOptions[idx].name)) {
                return (
                  <div key={el.id}>
                    <Controller
                      key={`properties[${idx}].id`}
                      name={`properties[${idx}].name`}
                      control={control}
                      render={({ onChange }) => (
                        <MultiSelect
                          name={`properties[${idx}].name`}
                          onChange={onChange}
                          options={fieldsOptions[idx].options}
                          label={fieldsOptions[idx].name}
                          index={idx}
                          updateFiltersHeights={updateFiltersHeights}
                        />
                      )}
                    />
                  </div>
                );
              }
              if (fieldsOptions[idx].name === 'Крепость') {
                return (
                  <div key={el.id}>
                    <Controller
                      name="alcoholRange"
                      control={control}
                      render={({ value, onChange }) => (
                        <Range
                          range={[alcoholMin, alcoholMax]}
                          value={value}
                          onChange={onChange}
                          slider={false}
                          title="Крепость, %"
                          index={idx}
                          updateFiltersHeights={updateFiltersHeights}
                        />
                      )}
                    />
                  </div>
                );
              }
              return (
                <CheckboxWrapper key={el.id}>
                  {fieldsOptions[idx].name}
                  <CheckboxContainer>
                    <Controller
                      name={`properties[${idx}].name`}
                      key={`properties[${idx}].id`}
                      control={control}
                      render={({ onChange }) => (
                        <Checkbox
                          options={fieldsOptions[idx].options}
                          name={`properties[${idx}].name`}
                          onChange={onChange}
                          index={idx}
                          updateFiltersHeights={updateFiltersHeights}
                        />
                      )}
                    />
                  </CheckboxContainer>
                </CheckboxWrapper>
              );
            })}
          </>
          <FilterAside className="aside">
            {currentCategory && currentCategory.hasBasket && (
              <Container direction="column" gap="56px">
                <Controller
                  name="priceRange"
                  control={control}
                  render={({ value, onChange }) => <Range range={[min, max]} value={value} onChange={onChange} />}
                />

                <Controller
                  name="averageRatingGte"
                  control={control}
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  render={({ value, onChange }) => <Rating value={value} onChange={onChange} />}
                />
              </Container>
            )}

            <ResetWrapper>
              <ResetButton type="button" onClick={onReset}>
                Сбросить все
              </ResetButton>
            </ResetWrapper>
          </FilterAside>
        </FilterColumns>
      </Body>

      <div>
        <SubmitButton type="submit" white gradient borderless>
          Применить
        </SubmitButton>
      </div>
    </Form>
  );
}

export default Menu;
