import React, { useCallback, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { FrameAdaptiveSize, SubstitutedCategoryType } from 'web';
import {
  useCategoryStore,
  useFinanceCategoryStore,
  useGuideCategoryStore,
  useInsuranceCategoryStore,
  useMediaCategoryStore,
} from 'store';
import { Block } from 'utils/blocks';
import { BREAKPOINTS } from 'utils/constants';
import Breadcrumbs from 'components/base-ui/breadcrumbs';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { BlocksRender } from 'components/renderers';
import { ServicesNavigation } from 'components/services/shared/navigation';

const Div = styled.div`
  height: 100%;
  position: relative;
`;

const BreadcrumbsSection = styled.section`
  width: 100%;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) and (max-width: calc(${BREAKPOINTS.laptop} - 1px)) {
    padding: 0 56px;
  }
`;

type ServicesCategoryStore =
  | ReturnType<typeof useCategoryStore>
  | ReturnType<typeof useFinanceCategoryStore>
  | ReturnType<typeof useInsuranceCategoryStore>
  | ReturnType<typeof useMediaCategoryStore>
  | ReturnType<typeof useGuideCategoryStore>;

const ServicesList: React.FC = observer(() => {
  const { trackPageView } = useMatomo();

  const { categoryType, categoryId } = useParams<{ categoryType: SubstitutedCategoryType; categoryId: string }>();
  const categoryStore = useCategoryStore();

  const store = useMemo((): ServicesCategoryStore => {
    switch (categoryType) {
      case 'finance':
        return useFinanceCategoryStore();

      case 'insurance':
        return useInsuranceCategoryStore();

      case 'media':
        return useMediaCategoryStore();

      case 'catalog':
        return useCategoryStore();

      default:
        return useCategoryStore();
    }
  }, [categoryType]);

  useEffect(() => {
    store.fetchCategory({ id: parseInt(categoryId) });
    document.title = store.category?.name ?? 'Услуги';
  }, [categoryId, store.category?.name]);

  useEffect(() => {
    if (store.category?.name && store.category?.name?.toLowerCase() !== 'услуги') {
      trackPageView({});
    }
    return function clearStore () {
      if (categoryType === 'finance' || categoryType === 'insurance' || categoryType === 'media') {
        categoryStore.cleanCategory();
      }
    };
  }, [store.category?.name]);

  useEffect(() => {
    if (categoryType !== 'catalog') return;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    const iFrameBlockSizeArray = (store?.category?.page?.content
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      .filter(item => item.type === 'iframe_size')
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
      .map(item => item.value) as unknown) as FrameAdaptiveSize[];
    if (iFrameBlockSizeArray && iFrameBlockSizeArray.length > 0) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      store.setIframeSize(iFrameBlockSizeArray);
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      store?.setIframeSize([]);
    }
  }, [store.category?.page]);

  const handleCloseErrorScreen = useCallback(
    () => store.closeErrorScreen({ id: parseInt(categoryId) }), [store, categoryId],
  );

  return (

    <Div>
      <ServicesNavigation white />

      <BreadcrumbsSection>
        <Breadcrumbs />
      </BreadcrumbsSection>

      {store.category?.page && (
      <BlocksRender
        blocks={(store.category.page.content as unknown) as Block[]}
        loading={store.fetching}
        options={{
            categoryType,
            cardType: store.category.cardType,
            productType: 'services',
            section: { compact: true },
          }}
        errorScreen={store.category?.errorScreen ?? undefined}
        closeErrorScreenCallBack={handleCloseErrorScreen}
      />
      )}
    </Div>

  );
});

export default ServicesList;
