import styled, { css } from 'styled-components';
import { COLORS } from 'utils/constants';

const Button = styled.button<{ reverse?: boolean; vector: 'right' | 'left' }>`
  position: absolute;
  top: calc(50% - 20px);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: rgba(35, 35, 35, 0.65);
  padding: 8px;
  margin: 0;
  transition: visibility 0s;
  ${(props) =>
    props.reverse &&
    css`
      transform: rotate(180deg);
    `}
  ${(props) => `${props.vector}: 5px`};

  & svg > * {
    width: 24px;
    height: 24px;
    fill: ${COLORS.white};
  }

  &:disabled {
    cursor: default;
    pointer-events: none;
  }

  &:focus {
    outline: none;
  }
`;

export const Styled = { Button };
