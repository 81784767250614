import { makeObservable, observable, action, toJS, computed } from 'mobx';

import { AxiosError } from 'axios';
import AbstractStore from '../AbstractStore';

import { api } from '../../utils/api';

import { PageContent } from '../../services';

// PageContent - динамический элемент

type IContent<TPageArea extends string> = {
  [pageArea in TPageArea]: PageContent['items'];
};

export default class PageContentStore<
  TPageElementsSlugs extends string,
  TPageArea extends string,
> extends AbstractStore {
  _content: IContent<TPageArea> = {} as IContent<TPageArea>;

  constructor() {
    super();
    makeObservable(this, {
      ...this.annotations,
      _content: observable,
      content: computed,
      fetchContent: action,
      setAreaContent: action,
      reset: action,
    });
  }

  fetchContent = (
    { slug: pageSlug, title }: Partial<PageContent>,
    contentParameters: { [elementSlug in TPageElementsSlugs]: TPageArea },
  ) => {
    const elementSlugs = Object.keys(contentParameters);

    this.fetchData(() => {
      const requests = elementSlugs.map((elementSlug) => {
        return api.pageContent.pageContentList({ slug: pageSlug, title }).then(({ data: { results } }) => {
          const [pageContent] = results;
          if (!pageContent.items) return;
          const slugElementInfo = pageContent.items.find((elem) => elem.slug === elementSlug);

          if (slugElementInfo) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            this.setAreaContent(contentParameters[elementSlug], slugElementInfo.content as PageContent['items']);
          }
        });
      });

      return Promise.all(requests).catch((err: AxiosError) => this.setError(err));
    });
  };

  setAreaContent = (pageArea: TPageArea, content: PageContent['items']) => {
    this._content[pageArea] = content;
  };

  reset = () => {
    this._content = {} as IContent<TPageArea>;
  };

  get content(): IContent<TPageArea> {
    return toJS(this._content);
  }
}
