import React from 'react';
import styled from 'styled-components';

import { ImageWithDescription } from 'web';

import { COLORS } from '../../../utils/constants';

import { microStyles } from '../../../styles/text';

import { Span } from '../typo';
import HtmlContainer from '../html-container';
import { HtmlRenderer } from '../../../utils/htmlParser';

const Container = styled.div``;

const IconWrapper = styled.div`
  margin-bottom: 16px;
`;

const TextWrapper = styled.div`
  display: grid;
  row-gap: 16px;
  grid-template-rows: auto 1fr;
`;

const Heading = styled(Span)`
  color: ${COLORS.secondary};
`;

const ImgIcon = styled.img`
  width: auto;
  height: 48px;
`;

const Description = styled(HtmlContainer)`
  ${microStyles}
`;

interface IconWithDescriptionProps {
  data: ImageWithDescription;
}

const IconWithDescription: React.FC<IconWithDescriptionProps> = ({ data }) => {
  return (
    <Container>
      <IconWrapper>
        <ImgIcon src={data.image} />
      </IconWrapper>

      <TextWrapper>
        <Heading>{data.header}</Heading>
        <Description>
          <HtmlRenderer html={data.text} />
        </Description>
      </TextWrapper>
    </Container>
  );
};

export default IconWithDescription;
