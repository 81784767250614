import { makeObservable, observable, action } from 'mobx';

import { Nullable } from 'web';

import { api } from '../../utils/api';

import { InsuranceProduct, InsuranceProductsApiInsuranceProductsListRequest } from '../../services';

export default class InsuranceProductsStore {
  fetching = false;

  error: Nullable<Error> = null;

  count = 0;

  products: InsuranceProduct[] = [];

  constructor() {
    makeObservable(this, {
      fetching: observable,
      error: observable,
      count: observable,
      products: observable,
      fetchProducts: action,
      setError: action,
      setFetching: action,
      setCount: action,
      setProducts: action,
    });
  }

  fetchProducts(props: InsuranceProductsApiInsuranceProductsListRequest, options?: unknown): Promise<void> {
    this.setError(null);
    this.setFetching(true);

    return api.insuranceProducts
      .insuranceProductsList(props, options)
      .then((res) => {
        this.setCount(res.data.count);
        this.setProducts(res.data.results);
      })
      .catch((err: Error) => this.setError(err))
      .finally(() => this.setFetching(false));
  }

  setFetching(value: boolean): void {
    this.fetching = value;
  }

  setError(value: Nullable<Error>): void {
    this.error = value;
  }

  setCount(value: number): void {
    this.count = value;
  }

  setProducts(value: InsuranceProduct[]): void {
    this.products = value;
  }
}
