import React from 'react';
import styled from 'styled-components';

import { COLORS, BREAKPOINTS } from '../../../../utils/constants';

import Icon from '../../../base-ui/icon';
import { Micro } from '../../../base-ui/typo';

const Div = styled.div`
  display: flex;
  margin-bottom: 40px;
`;

const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 12px;
`;

const DescriptionWrapper = styled.div`
  margin-right: 16px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin-right: 64px;
  }
`;

const Description = styled(Micro)`
  color: ${COLORS.gray4};
`;

const Alert: React.FC = () => {
  return (
    <Div>
      <IconWrapper>
        <Icon name="iconAlertCircle" />
      </IconWrapper>

      <DescriptionWrapper>
        <Description>
          Все заполненные данные будут автоматически добавляться в формы заявок - это ускорит процесс оформления.
        </Description>
      </DescriptionWrapper>
    </Div>
  );
};

export default Alert;
