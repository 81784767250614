import styled from 'styled-components';
import { BREAKPOINTS } from 'utils/constants';

export const Div = styled.div`
  padding: 68px 0 58px 0;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 68px 56px 80px 56px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding: 68px 136px 124px 136px;
  }
`;
export const Section = styled.section`
  width: 100%;
`;
