import styled from 'styled-components';
import React from 'react';
import { COLORS } from '../../utils/constants';
import Icon from './icon';

export const MoreButtonWrapper = styled.div`
  color: ${COLORS.primary};
  cursor: pointer;
  margin-bottom: 15px;
  margin-left: 15px;
`;

const ButtonFitIcon = styled(Icon)`
  margin-right: 6px;
  position: relative;
  top: 2px;
`;

export const MoreButton: React.FC<{ onClick: (e: React.MouseEvent<HTMLDivElement>) => void }> = (props) => {
  return (
    <MoreButtonWrapper onClick={props.onClick}>
      <ButtonFitIcon name="iconViewmore" />
      {props.children}
    </MoreButtonWrapper>
  );
};
