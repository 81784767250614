import React from 'react';
import { HtmlRenderer } from 'utils/htmlParser';
import { StHeader, StText } from './styled';

interface Props {
  header: string;
  text: string;
}

export const AboutProjectGroupHeader: React.FC<Props> = (props) => {
  return (
    <>
      <StHeader>{props.header}</StHeader>
      <StText>
        <HtmlRenderer html={props.text} />
      </StText>
    </>
  );
};
