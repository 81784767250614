import styled from 'styled-components';

import { COLORS } from '../../../utils/constants';

export const Discount = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(167, 156, 117, 0.8);
`;

export const DiscountText = styled.div`
  color: ${COLORS.white};
`;
