const TOKEN = 'token';

const IS_USER = 'is_user';

const TIME_RATE_LIMIT = 'time_rate_limit';
const TIME_RATE_LIMIT_TIMESTAMP = 'time_rate_limit_timestamp';

interface AuthorizationHeader {
  Authorization: string;
}

class Authentication {
  private saveToken(token: string) {
    localStorage.setItem(TOKEN, token);
    localStorage.setItem(IS_USER, IS_USER);
  }

  private removeToken() {
    localStorage.removeItem(TOKEN);
  }

  private getToken() {
    return localStorage.getItem(TOKEN);
  }

  onRequest(headers: AuthorizationHeader) {
    const token = this.getToken();
    token && (headers.Authorization = `Token ${token}`);
    return headers;
  }

  onAuth(token: string) {
    this.saveToken(token);
  }

  onError() {
    this.removeToken();
    if (window.location.pathname !== '/login') window.location.href = `${window.location.origin}/login`;
  }

  isUser() {
    const isUser = localStorage.getItem(IS_USER);
    if (isUser) {
      return JSON.stringify(isUser) === IS_USER;
    }
    return false;
  }

  onLogout = () => {
    localStorage.setItem(IS_USER, 'logout');
    this.removeToken();
  };

  initTimeRateLimit() {
    localStorage.setItem(TIME_RATE_LIMIT, JSON.stringify(true));
    localStorage.setItem(TIME_RATE_LIMIT_TIMESTAMP, JSON.stringify(new Date().getTime()));
  }
}

export const authService = new Authentication();
