import React from 'react';
import styled from 'styled-components';
import { AssolAward } from 'components/assol-award';
import { DefaultLayout } from 'layouts';
import ScrollToTopOnMount from 'components/base-ui/scroll-to-top';
import Breadcrumbs from 'components/base-ui/breadcrumbs';
import { BASIC_FONT_FAMILY, BREAKPOINTS, COLORS } from 'utils/constants';

const Container = styled.div`
  margin-top: 53px;
  font-family: ${BASIC_FONT_FAMILY};
`;

const BreadcrumbsWrapper = styled.div`
  position: absolute;
  top: 53px;
  width: 100%;
  background-color: ${COLORS.white};
  opacity: 0.8;
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    background-color: unset;
  }
`;

function AssolAwardPaga() {
  return (
    <DefaultLayout>
      <ScrollToTopOnMount />
      <Container>
        <BreadcrumbsWrapper>
          <Breadcrumbs lineStyle />
        </BreadcrumbsWrapper>
        <AssolAward />
      </Container>
    </DefaultLayout>
  );
}

export default AssolAwardPaga;
