import React from 'react';
import { AboutOurPeopleElement } from 'web';
import { useDeviceType } from 'utils/hooks';
import { StButtonWrapper, StElements, StHideButton, StMoreButton } from './styled';
import { SmallElement } from './components/small-element';
import { BigElement } from './components/big-element';

interface Props {
  elements: AboutOurPeopleElement[];
}

export const Elements: React.FC<Props> = (props) => {
  const deviceType = useDeviceType();

  const [elementsToShow, setElementsToShow] = React.useState<AboutOurPeopleElement[]>([]);
  const [currentCountElements, setCurrentCountElements] = React.useState(0);

  const cardCount = props.elements.length;

  const CARDS_PER_VIEW = React.useMemo(() => {
    if (deviceType !== 'desktop') return 5;
    return 7;
  }, [deviceType]);

  React.useEffect(() => {
    setElementsToShow(props.elements.slice(0, CARDS_PER_VIEW));
    setCurrentCountElements(CARDS_PER_VIEW);
  }, [CARDS_PER_VIEW]);

  const onShowMore = React.useCallback(() => {
    setElementsToShow([
      ...elementsToShow,
      ...props.elements.slice(currentCountElements, currentCountElements + CARDS_PER_VIEW),
    ]);
    setCurrentCountElements(currentCountElements + CARDS_PER_VIEW);
  }, [elementsToShow, currentCountElements, CARDS_PER_VIEW]);

  const onHide = React.useCallback(() => {
    setElementsToShow(props.elements.slice(0, CARDS_PER_VIEW));
    setCurrentCountElements(CARDS_PER_VIEW);
  }, [CARDS_PER_VIEW]);

  const bigCardsIndexes = React.useMemo(() => {
    const indexes = [];
    for (let i = 0; i < 1000; i++) {
      indexes.push(i * CARDS_PER_VIEW);
      indexes.push(i * CARDS_PER_VIEW - 1);
    }
    return indexes;
  }, [CARDS_PER_VIEW]);

  return (
    <>
      <StElements>
        {deviceType === 'mobile' && elementsToShow.map((element) => <SmallElement element={element} />)}
        {(deviceType === 'tablet' || deviceType === 'laptop') &&
          elementsToShow.map((element) => <BigElement element={element} />)}
        {deviceType === 'desktop' &&
          elementsToShow.map((element, index) => {
            return bigCardsIndexes.includes(index) ? (
              <BigElement element={element} />
            ) : (
              <SmallElement element={element} />
            );
          })}
      </StElements>
      <StButtonWrapper>
        {cardCount > elementsToShow.length && <StMoreButton onClick={onShowMore}>Показать больше</StMoreButton>}
        {elementsToShow.length > CARDS_PER_VIEW && <StHideButton onClick={onHide}>Свернуть</StHideButton>}
      </StButtonWrapper>
    </>
  );
};
