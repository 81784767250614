import { RefObject } from 'react';

import { GlobalCategory, GlobalCategoryCategoryTypeEnum } from 'services';
import { PAGE_LIMIT } from './constants';

export const snakeCaseToCamelCase = (str: string) =>
  str.split('_').reduce((res, word, i) => {
    return i === 0 ? word.toLowerCase() : `${res}${word.charAt(0).toUpperCase()}${word.substr(1).toLowerCase()}`;
  }, '');

export const camelCaseToSnakeCase = (str: string) => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const getLinkPath = (el: GlobalCategory) => {
  if (el.categoryType === GlobalCategoryCategoryTypeEnum.Medicine) {
    return `/market/${el.categoryType}`;
  }
  const section = el.hasBasket ? 'goods' : 'services';
  return `/${section}/${el.categoryType}/${el.id}`;
};

export const editObject = <T extends object, K extends keyof T>(obj: T, key: K, value: T[K]) => {
  obj[key] = value;
  return obj;
};

export const measureTextWidthOnCanvas = (text: string, ref: RefObject<HTMLCanvasElement>): number | null => {
  if (ref.current === null) return null;

  const ctx = ref.current.getContext('2d');
  return ctx ? ctx.measureText(text).width : null;
};

export const randomNumberInRange = (min: number, max: number) => Math.floor(Math.random() * (max - min)) + min;

export const randomNumberUntilMax = (max: number) => randomNumberInRange(0, max);

const FORBIDDEN_QUERY_PARAMS = ['page', 'category', 'subcategory'];

export const stringifyQueryParams = <T extends object = object>(queryParams: T): string => {
  const filter = ([key, value]: [string, unknown]) => {
    if (FORBIDDEN_QUERY_PARAMS.includes(key)) return false;

    // return Array.isArray(value) ? value.length !== 0 : value !== undefined && value !== null;
    return Array.isArray(value) ? value.length !== 0 : !!value; // Чтобы нулевые параметры не попадали в URL
  };

  return Object.entries(queryParams)
    .filter(filter)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
};

export const calculateCardsLimit = (
  clientWidth: number,
  cardWidth: number,
  cardGaps: number,
  padding: number,
  rows = 3,
) => {
  if (clientWidth < 1863) return PAGE_LIMIT;
  const contentWidth = clientWidth - padding * 2;
  return Math.floor((contentWidth + cardGaps) / (cardWidth + cardGaps)) * rows;
};

export const transformToCurrency = (amount: number): string => {
  return amount.toLocaleString('ru-RU');
};

export const capitalizeFirstLetter = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
