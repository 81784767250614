import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { BREAKPOINTS } from 'utils/constants';

import { MainPage } from 'services';
import { api } from 'utils/api';
import { Block } from 'utils/blocks';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { BlocksRender } from '../renderers';
import Breadcrumbs from '../base-ui/breadcrumbs';

const Container = styled.div`
  height: 100%;
  position: relative;
`;

const BreadcrumbsWrapper = styled.div`
  top: 56px;
  left: 0;
  width: 100%;
  z-index: 100;
  position: absolute;
  margin-top: 12px;
  padding: 0 16px 0 0;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 0 32px 0 32px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding: 0 32px 0 136px;
  }
`;

const About: React.FC = () => {
  const [fetching, setFetching] = useState(false);
  const [aboutProjectPage, setAboutProjectPage] = useState<MainPage | null>(null);
  const { trackPageView } = useMatomo();

  useEffect(() => {
    const fetchData = async () => {
      setFetching(() => true);

      const { data } = await api.aboutProjectPage.aboutProjectPageList();

      setAboutProjectPage(() => data);
      setFetching(() => false);
    };

    fetchData();
    trackPageView({});
  }, [setFetching, setAboutProjectPage]);

  return (
    <Container>
      <BreadcrumbsWrapper>
        <Breadcrumbs white={true} />
      </BreadcrumbsWrapper>

      <BlocksRender
        blocks={aboutProjectPage?.content as unknown as Block[]}
        loading={fetching}
        options={{ section: { compact: true } }}
      />
    </Container>
  );
};

export default About;
