import React from 'react';
import styled from 'styled-components';
import ScrollToTopOnMount from 'components/base-ui/scroll-to-top';
import Breadcrumbs from 'components/base-ui/breadcrumbs';
import { DefaultLayout } from 'layouts';
import { useParams } from 'react-router-dom';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { OurPeopleArticle } from '../../../components/articles/our-people-article';

const BreadcrumbsWrapper = styled.div`
  position: relative;
  margin-top: 56px;
  color: #464a53;
`;

function OurPeopleArticlePage() {
  const { articleId } = useParams<{ articleId: string }>();
  const { trackPageView } = useMatomo();

  React.useEffect(() => {
    trackPageView({});
  }, []);

  return (
    <DefaultLayout>
      <ScrollToTopOnMount />
      <BreadcrumbsWrapper>
        <Breadcrumbs lineStyle={true} />
      </BreadcrumbsWrapper>
      <div>
        <OurPeopleArticle articleId={Number(articleId)} />
      </div>
    </DefaultLayout>
  );
}

export default OurPeopleArticlePage;
