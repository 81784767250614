import React from 'react';
import { useParams } from 'react-router-dom';

import { SubstitutedCategoryType } from 'web';

import { useApplicationStore, useProfileStore, usePromocodeStore } from 'store';
import { api, useLink } from 'utils/api';
import { MediaProductSpecialServiceEnum } from 'services';
import { useChip } from 'components/goods/item/chip';

export const useSubscribe = ({
  link,
  subscribeFromPage,
  category,
  havePage,
  product,
  promocode,
  specialService,
  buttonAction,
}: {
  link?: string;
  subscribeFromPage?: boolean;
  category?: SubstitutedCategoryType;
  havePage?: boolean;
  product?: number;
  promocode?: boolean;
  specialService?: MediaProductSpecialServiceEnum;
  buttonAction?: string;
} = {}): [() => void, React.FC | null, boolean] => {
  const { categoryType, productId } = useParams<{ categoryType: SubstitutedCategoryType; productId: string }>();

  category = category || categoryType;
  product = product || +productId;

  const promocodeStore = usePromocodeStore();
  const applicationStore = useApplicationStore();
  const openLink = useLink();
  const [trigger, Chip] = useChip();

  const { profile } = useProfileStore();

  if (promocode) {
    return [
      () => promocodeStore.fetchPromocode().then(trigger),
      () => <Chip content="Промокод отправлен вам на почту" error={Boolean(promocodeStore.error)} />,
      true,
    ];
  }

  if (subscribeFromPage && category === 'catalog' && buttonAction === 'link') {
    return [() => openLink(link || '/'), null, true];
  }

  if (category === 'media' && specialService === MediaProductSpecialServiceEnum.SportExpress) {
    return [
      () => {
        applicationStore.createMediaApplication({
          email: String(profile?.user?.email),
          product: String(product),
        });
        trigger();
      },
      () => <Chip content="Вы успешно оставили заявку на получение услуги" />,
      true,
    ];
  }
  if (subscribeFromPage && category === 'media') {
    return [
      () => {
        applicationStore.createMediaApplication({
          email: String(profile?.user?.email),
          product: String(product),
        });
        trigger();
      },
      () => <Chip content="Вы успешно оставили заявку на получение услуги" />,
      true,
    ];
  }

  if (category === 'finance' && subscribeFromPage && link) {
    return [
      () => {
        const newTab = window.open();
        newTab?.location.assign(link);
      },
      null,
      true,
    ];
  }

  if (category === 'finance') {
    return [
      () => {
        const newTab = window.open();
        api.bankingProducts.bankingProductsLinkList({ id: Number(product) }).then(res => {
          return newTab?.location.assign(res.data.link);
        });
},
      null,
      true,
    ];
  }

  if (havePage) {
    return [() => openLink(link || '/'), null, true];
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  return [() => {}, null, false];
};
