import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';

import { GlobalCategoryCardTypeEnum, ProductList } from 'services';

import { useGlobalCategoriesStore, useSearchStore } from 'store';

import Cards from 'components/goods/shared/cards';
import List from 'components/goods/shared/list';
import Table from 'components/base-ui/table';
import Section from 'components/base-ui/section';
import ProductListItem from 'components/services/shared/product';
import Leasure from 'components/services/shared/leasure';

export const ProductsSearchRenderer: React.FC = observer(() => {
  const searchStore = useSearchStore();
  const globalCategoriesStore = useGlobalCategoriesStore();

  useEffect(() => autorun(() => globalCategoriesStore.fetchCategories()), [globalCategoriesStore]);

  const { searchValue } = useParams<{ searchValue: string }>();

  return (
    <>
      {Object.entries(searchStore.groupedProducts).map(([categoryId, products]) => {
        const currentCategory = globalCategoriesStore.categories.find((c) => c.id === +categoryId);

        if (currentCategory?.hasBasket) {
          return (
            <Section compact heading={currentCategory.name} noFirstOfTypeOffset>
              <Table<ProductList, never>
                cards={
                  <Cards
                    items={products as unknown as ProductList[]}
                    category={{ id: categoryId, type: currentCategory.categoryType }}
                  />
                }
                list={
                  <List
                    items={products as unknown as ProductList[]}
                    category={{ id: categoryId, type: currentCategory.categoryType }}
                  />
                }
                count={searchStore.fieldCount('products')}
                currentPage={searchStore.fieldPage('products')}
                onPagination={(page) => searchStore.onPaginate(searchValue, 'products', 'products', page, +categoryId)}
                limit={searchStore.limit}
              />
            </Section>
          );
        }

        if (currentCategory?.cardType === GlobalCategoryCardTypeEnum.List) {
          return (
            <Section compact heading={currentCategory.name} noFirstOfTypeOffset>
              {products.map((p) => (
                <ProductListItem
                  key={p.id}
                  data={p}
                  category={{ id: categoryId, type: currentCategory.categoryType }}
                />
              ))}
            </Section>
          );
        }

        return (
          <Section compact heading={currentCategory?.name} noFirstOfTypeOffset>
            <Leasure
              count={searchStore.fieldCount('products')}
              items={products as unknown as ProductList[]}
              limit={searchStore.limit}
              categoryId={categoryId}
              categoryType="catalog"
              currentPage={searchStore.fieldPage('products')}
              onPagination={(page) => searchStore.onPaginate(searchValue, 'products', 'products', page)}
            />
          </Section>
        );
      })}
    </>
  );
});
