import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';

import { Container as BaseContainer } from 'components/base-ui/container';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 53px;
  margin: 64px 0 70px 0;
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin: 0 auto;
    width: 610px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: 928px;
  }

  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    width: 1328px;
  }
`;

export const Container = styled(BaseContainer)`
  flex-direction: column;
  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    flex-direction: row;
    align-items: flex-start;
    gap: 80px;
  }
`;

export const Header = styled.div`
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  color: ${COLORS.gray2};
  margin: 0 16px 16px 16px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin: 0 0 30px;
    font-size: 44px;
    line-height: 48px;
  }
`;

export const Styled = { Content, Header, Container, Wrapper };
