import styled from 'styled-components';
import { COLORS } from 'utils/constants';

const Title = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: ${COLORS.gray2};
`;

const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  svg {
    width: 24px;
    height: 24px;
    & * {
      stroke: ${COLORS.secondary};
    }
  }
`;

export const Styled = { Title, IconWrapper };
