import styled, { css } from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';
import { TextSize } from './types';

const Text = styled.span<{ size: TextSize }>`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  word-wrap: break-word;
  ${(props) =>
    props.size === TextSize.Default &&
    css`
      @media screen and (min-width: ${BREAKPOINTS.tablet}) {
        font-size: 24px;
        line-height: 32px;
      }
    `}
`;

const Company = styled.span<{ size: TextSize }>`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  word-wrap: break-word;

  ${(props) =>
    props.size === TextSize.Default &&
    css`
      @media screen and (min-width: ${BREAKPOINTS.tablet}) {
        font-size: 20px;
        line-height: 26px;
      }
    `}

  color: ${COLORS.gray3};
`;

export const Styled = { Text, Company };
