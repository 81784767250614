import React, { useEffect } from 'react';
import { renderRoutes, RouteConfigComponentProps } from 'react-router-config';

import { DefaultLayout } from '../../layouts';

import ScrollToTopOnMount from '../../components/base-ui/scroll-to-top';

type ProfilePageProps = RouteConfigComponentProps;

const ProfilePage: React.FC<ProfilePageProps> = ({ route }) => {
  useEffect(() => {
    document.title = 'Профиль';
  }, []);

  return (
    <>
      <ScrollToTopOnMount />
      <DefaultLayout>{route && renderRoutes(route.routes)}</DefaultLayout>
    </>
  );
};

export default ProfilePage;
