import React, { useCallback, useMemo } from 'react';

import { CategoryProps } from 'web';

import { ProductList } from 'services';

import { useProductListStore, useProfileStore } from 'store';
import HtmlContainer from 'components/base-ui/html-container';
import { Discount, DiscountText } from 'components/base-ui/discount';

import { HtmlRenderer } from 'utils/htmlParser';
import {
  ActionsWrapper,
  DiscountWrapper,
  Div,
  Image,
  ImageWrapper,
  RatingScore,
  Rating,
} from 'components/goods/shared/cards/item/styles';
import { IconRate } from 'components/base-ui/icon-rate';
import { COLORS } from 'utils/constants';
import Dropdown from './dropdown';
import Footer from './footer';
import { IconLikeWithTooltip } from '../../../../base-ui/icon-like-with-tooltip';

interface ItemProps {
  data: ProductList;
  category?: Partial<CategoryProps>;
  onShowLikeModal?: (id: number) => void;
}

const Item: React.FC<ItemProps> = ({ data, category, onShowLikeModal }) => {
  const memoizedDiscount = useMemo(() => `-${data.discountPercent}%`, [data.discountPercent]);
  const profileStore = useProfileStore();

  const to = useMemo(() => {
    return category?.id && category.type ? `/goods/${category.type}/${category.id}/${data.id}` : '/';
  }, [data.id, category]);

  const averageRating = useMemo(() => (data.averageRating ? parseFloat(data.averageRating) : 0), [data.averageRating]);

  const productListStore = useProductListStore();

  const handleLikeClick = useCallback(() => {
    if (data.id) {
      data.isLikedByUser
        ? productListStore.handleLikeDelete(data.id, profileStore.profile?.id!)
        : productListStore.handleLikePost(data.id, profileStore.profile);
    }
  }, [data.isLikedByUser, data.id]);

  const handleShowLikeModal = useCallback(() => {
    if (data.id && onShowLikeModal) {
      onShowLikeModal(data.id);
    }
  }, [data.id]);

  const tooltipOffset = React.useMemo(() => {
    if (data.likesCount && data.likesCount >= 6) {
      return {
        left: '-200px',
        leftMobile: '-210px',
        arrowLeft: '210px',
        arrowLeftMobile: '243px',
      };
    }

    if (data.likesCount && data.likesCount > 4) {
      return {
        left: '-200px',
        leftMobile: '-160px',
        arrowLeft: '210px',
        arrowLeftMobile: '193px',
      };
    }

    return {
      left: '-120px',
      leftMobile: '-120px',
      arrowLeft: '150px',
      arrowLeftMobile: '150px',
    };
  }, [data.likesCount]);

  return (
    <Div>
      <Dropdown data={data} to={to}>
        <HtmlContainer>
          <HtmlRenderer html={data?.description || ''} />
        </HtmlContainer>
      </Dropdown>

      <ImageWrapper to={to}>
        <Image src={data.imageThumbnail || ''} />

        {data.discountPercent && (
          <DiscountWrapper>
            <Discount>
              <DiscountText>{memoizedDiscount}</DiscountText>
            </Discount>
          </DiscountWrapper>
        )}
      </ImageWrapper>

      <ActionsWrapper>
        <Rating>
          <IconRate width="20px" height="20px" color={averageRating ? COLORS.copper : COLORS.white} />
          <RatingScore>{averageRating || null}</RatingScore>
        </Rating>
        <Rating>
          <IconLikeWithTooltip
            isLiked={!!data.isLikedByUser}
            onClick={handleLikeClick}
            likedUsers={data.likeAuthors || []}
            likesCount={data.likesCount || 0}
            newsId={data.id!}
            onShowModal={handleShowLikeModal}
            color={COLORS.copper}
            iconWidth="20px"
            iconHeight="18px"
            left={tooltipOffset.left}
            leftMobile={tooltipOffset.leftMobile}
            arrowLeft={tooltipOffset.arrowLeft}
            arrowLeftMobile={tooltipOffset.arrowLeftMobile}
          />
        </Rating>
      </ActionsWrapper>

      <Footer data={data} category={category} />
    </Div>
  );
};

export default Item;
