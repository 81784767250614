import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AwardBusiness } from 'services';
import { useBreakpointsDimensions, useDeviceType } from 'utils/hooks';
import Select from 'components/base-ui/select';
import AnimateHeight from 'react-animate-height';
import { Container } from 'components/base-ui/container';
import { TagItem } from './TagItem';
import { StyledContainer, StyledButton, StyledButtonText, StyledIconDown } from './styles';

interface BusinessesProps {
  data: AwardBusiness[];
  currentBusiness: AwardBusiness | null;
  onBusinessClick: (id: number) => void;
}

export const Businesses: React.FC<BusinessesProps> = ({ data, currentBusiness, onBusinessClick }) => {
  const deviceType = useDeviceType();
  const isMobile = useMemo(() => deviceType === 'mobile', [deviceType]);
  const selectOptions = useMemo(
    () => data.map((item) => ({ label: item.name, value: item.id?.toString() || '' })),
    [data],
  );
  const selectValue = useMemo(
    () => ({ label: currentBusiness?.name || '', value: currentBusiness?.id?.toString() || '' }),
    [currentBusiness],
  );

  const { width } = useBreakpointsDimensions();
  const [fullHeight, setFullHeight] = useState<number>(0);
  const minHeight = 42;
  const [height, setHeight] = useState<string | number | undefined>(minHeight);
  const collapsable = useMemo(() => minHeight < fullHeight, [width, fullHeight, minHeight]);

  const handleShowLess = useCallback(() => {
    setHeight(minHeight);
  }, [minHeight]);

  const handleShowMore = useCallback(() => {
    setHeight('auto');
  }, []);

  useEffect(() => {
    if (height !== 'auto') {
      setHeight(minHeight > fullHeight && fullHeight !== 0 ? 'auto' : minHeight);
    }
  }, [minHeight, fullHeight]);

  const measuredRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (node !== null) {
        setFullHeight(Math.round(node.getBoundingClientRect().height));
      }
    },
    [width],
  );

  return (
    <StyledContainer>
      {!isMobile && (
        <AnimateHeight height={height}>
          <Container ref={(node) => measuredRef(node)} gap="24px" wrap="wrap" width="100%">
            {data.map((item) => (
              <TagItem
                key={item.id}
                active={item.id === currentBusiness?.id}
                onClick={() => onBusinessClick(item.id || 1)}
                text={item.name}
              />
            ))}
          </Container>
        </AnimateHeight>
      )}
      {isMobile && (
        <Container>
          <Select options={selectOptions} value={selectValue} onChange={(ev) => onBusinessClick(Number(ev?.value))} />
        </Container>
      )}
      {collapsable && !isMobile && (
        <StyledButton onClick={height === 'auto' ? handleShowLess : handleShowMore}>
          <StyledButtonText>{height === 'auto' ? 'Показать Меньше' : 'Показать больше'}</StyledButtonText>
          <StyledIconDown className={height === 'auto' ? 'expanded' : 'collapsed'} />
        </StyledButton>
      )}
    </StyledContainer>
  );
};
