import React, { createContext, useContext } from 'react';

import PageContentStore from './store';

const store = new PageContentStore();

const PageContentStateContext = createContext<PageContentStore<string, string>>(store);

export const PageContentStateProvider: React.FC = ({ children }) => (
  <PageContentStateContext.Provider value={store}>{children}</PageContentStateContext.Provider>
);

export const usePageContentStore = <TPageElementsSlugs extends string, TPageAreas extends string>(): PageContentStore<
  TPageElementsSlugs,
  TPageAreas
> => {
  return useContext(PageContentStateContext);
};
