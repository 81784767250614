import React, { useMemo } from 'react';
import styled from 'styled-components';

import { ImageWithDescription } from 'web';

import { useDeviceType } from '../../../utils/hooks';
import { BREAKPOINTS } from '../../../utils/constants';

import Section from '../../base-ui/section';
import IconWithDescription from '../../base-ui/icon-with-description';

interface IconWithDescriptionProps {
  value: ImageWithDescription[];
}

const ItemServicesItemsWrapper = styled.div`
  display: grid;
  row-gap: 40px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    column-gap: 80px;
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    grid-auto-flow: column;
    grid-template-columns: unset;
  }
`;

const IconWithDescriptionRenderer: React.FC<IconWithDescriptionProps> = ({ value }) => {
  const deviceType = useDeviceType();

  const isMobile = useMemo(() => deviceType === 'mobile', [deviceType]);
  return (
    <Section compact noFirstOfTypeOffset>
      {!isMobile && (
        <ItemServicesItemsWrapper>
          {value.map((el, index) => (
            <IconWithDescription key={index} data={el} />
          ))}
        </ItemServicesItemsWrapper>
      )}
    </Section>
  );
};
export default IconWithDescriptionRenderer;
