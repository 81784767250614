import React from 'react';
import { AwardParticipantsList } from 'services';
import { Styled } from './styles';
import { CardList } from './CardList';

export const Grid: React.FC<{
  data: AwardParticipantsList[];
  isScrollable?: boolean;
  title: string;
}> = (props) => {
  return (
    <Styled.Container>
      <Styled.Title>{props.title}</Styled.Title>
      <CardList data={props.data} isScrollable={props.isScrollable} />
    </Styled.Container>
  );
};
