import { action, computed, makeObservable, observable } from 'mobx';
import AbstractStore from '../AbstractStore';
import { GuidePage, Profile } from '../../services';
import { api } from '../../utils/api';

export default class GuideStore extends AbstractStore {
  page: GuidePage | null = null;

  constructor() {
    super();
    makeObservable(this, {
      ...this.annotations,
      page: observable,
      setPage: action,
      resetPage: action,
      fetchPage: action,
      userRating: computed,
      relatedGuides: computed,
    });
  }

  fetchPage = (pageId: number) => {
    this.fetchData(() => api.guide.pages.guidePageRead({ id: pageId }).then((res) => this.setPage(res.data)));
  };

  get userRating() {
    return this.page?.userRating ? +this.page.userRating : 0;
  }

  get relatedGuides() {
    return this.page?.relatedGuides || [];
  }

  setPage(page: GuidePage) {
    this.page = page;
  }

  handleFavoritePost(id: number) {
    api.guide.pages.guidePageToggleFavoriteCreate({ id });
    if (this.page) {
      this.setPage({ ...this.page, isInUserFavorites: true });
    }
  }

  handleFavoriteDelete(id: number) {
    api.guide.pages.guidePageToggleFavoriteDelete({ id });
    if (this.page) {
      this.setPage({ ...this.page, isInUserFavorites: false });
    }
  }

  handleLikePost(id: number, profile: Profile | null) {
    api.guide.pages.guidePageToggleLikeCreate({ id });
    if (this.page && profile?.user?.email) {
      this.setPage({
        ...this.page,
        isLikedByUser: true,
        likesCount: this.page?.likesCount ? this.page.likesCount + 1 : 1,
        likeAuthors:
          this.page.likeAuthors && this.page.likeAuthors.length < 6
            ? [
                ...this.page.likeAuthors,
                {
                  id: profile.id,
                  email: profile.user.email,
                  image: profile.image,
                  fullName: `${profile?.lastName} ${profile?.firstName}`,
                },
              ]
            : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              [...this.page.likeAuthors],
      });
    }
  }

  handleLikeDelete(id: number, userId: number) {
    api.guide.pages.guidePageToggleLikeDelete({ id });
    if (this.page) {
      this.setPage({
        ...this.page,
        isLikedByUser: false,
        likesCount: this.page?.likesCount ? this.page.likesCount - 1 : 0,
        likeAuthors: this.page.likeAuthors ? this.page.likeAuthors?.filter((item) => item.id !== userId) : [],
      });
    }
  }

  resetPage = () => {
    this.page = null;
  };
}
