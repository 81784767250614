import React, { useCallback } from 'react';
import styled from 'styled-components';

import { observer } from 'mobx-react-lite';
import { runInAction } from 'mobx';
import { BREAKPOINTS } from '../../../../utils/constants';

import HtmlContainer from '../../../base-ui/html-container';
import { HtmlRenderer } from '../../../../utils/htmlParser';
import Stars from '../../../base-ui/stars';
import { useGuideStore } from '../../../../store';
import { api } from '../../../../utils/api';

const StyledContainer = styled(HtmlContainer)`
  margin: 0 16px;
  max-width: 1072px;
  padding-bottom: 24px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin: 0 88px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    margin: 0 32px;
  }

  img {
    max-width: 100%;
  }
`;

const StarsWrapper = styled.div`
  margin-top: 20px;
`;

interface ContentProps {
  value: string;
}

const Content: React.FC<ContentProps> = observer(({ value }) => {
  const store = useGuideStore();

  const changeRating = useCallback(
    async (ratingValue: number) => {
      await api.guide.rating.guideRatingCreate({ data: { guide: String(store.page?.id), ratingValue } });
      runInAction(() =>
        store.setPage({
          ...store.page!,
          userRating: String(ratingValue),
        }),
      );
    },
    [store.page],
  );

  return (
    <StyledContainer>
      <HtmlRenderer html={value} />
      <StarsWrapper>
        <Stars value={store.userRating} onChange={changeRating} />
      </StarsWrapper>
    </StyledContainer>
  );
});

export default Content;
