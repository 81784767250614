import React, { useCallback, useEffect, useMemo } from 'react';
import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';

import { useNewsStore } from 'store';

import { DeviceType, useDeviceType } from 'utils/hooks';

import Section from 'components/base-ui/section';
import { Button } from 'pages/articles/mobile/styles';
import { Container } from 'components/base-ui/container';
import { useHistory } from 'react-router-dom';
import { NewsCard } from './card';
import { Styled } from './styles';

const countMap: Record<DeviceType, number> = {
  desktop: 4,
  laptop: 3,
  tablet: 2,
  mobile: 2,
};

export const NewsMainPagePreview: React.FC = observer(() => {
  const deviceType = useDeviceType();
  const history = useHistory();
  const store = useNewsStore();

  useEffect(() => {
    autorun(() => {
      store.fetchNews({ isShowOnMainPage: true, limit: 4 });
    });
    return store.cleanUp;
  }, []);

  const memoizedItems = useMemo(
    () => store.newsForPreview.slice(0, countMap[deviceType]),
    [deviceType, store.newsForPreview],
  );

  const handleOnClick = useCallback(() => {
    history.push('/news');
  }, []);

  if (!memoizedItems.length) return null;

  return (
    <Section compact noFirstOfTypeOffset heading="Новости">
      <Container gap="32px" direction="column">
        <Styled.Container>
          {memoizedItems.map((el) => (
            <NewsCard key={el.id} data={el} />
          ))}
        </Styled.Container>
        <Container width="100%" justify="center">
          <Button onClick={handleOnClick}>Все новости</Button>
        </Container>
      </Container>
    </Section>
  );
});
