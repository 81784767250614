export enum TextSize {
  Small = 'small',
  Default = 'default',
}

export interface ParticipantProps {
  name: string;
  company?: string | null;
  gap?: string;
  size: TextSize;
}
