import styled from 'styled-components';
import { BREAKPOINTS } from 'utils/constants';
import { ArrowButton } from 'components/base-ui/swiper-arrow-buttons';

const SwiperContainer = styled.div`
  position: relative;
  width: 100%;
  padding: 80px 0;

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    margin: 0 auto;
    width: 834px;
  }
  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    width: 1170px;
  }

  @media screen and (min-width: ${BREAKPOINTS.wideDesktop}) {
    width: 1300px;
  }

  .swiper-slide {
    margin: 0 24px 0 0;
    width: 224px;

    @media screen and (min-width: ${BREAKPOINTS.tablet}) {
      width: 340px;
      margin: 0 44px 0 0;
    }

    @media screen and (min-width: ${BREAKPOINTS.laptop}) {
      width: 368px;
      margin: 0;
    }

    &:first-child {
      margin-left: 24px;

      @media screen and (min-width: ${BREAKPOINTS.tablet}) {
        margin-left: 80px;
      }

      @media screen and (min-width: ${BREAKPOINTS.laptop}) {
        margin-left: 0;
      }
    }

    &:last-child {
      margin-right: 24px;

      @media screen and (min-width: ${BREAKPOINTS.tablet}) {
        margin-right: 80px;
      }

      @media screen and (min-width: ${BREAKPOINTS.laptop}) {
        margin-right: 0;
      }
    }
  }
`;

const CardsWrapper = styled.div`
  padding: 80px 86px;
  display: flex;
  justify-content: center;
  gap: 76px;

  @media screen and (min-width: ${BREAKPOINTS.wideDesktop}) {
    gap: 100px;
  }
`;

const NormalSwiperContainer = styled.div`
  position: relative;
  width: 100%;
  padding: 80px 0;

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    margin: 0 auto;
    width: 834px;
  }
  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    width: 1170px;
  }

  @media screen and (min-width: ${BREAKPOINTS.wideDesktop}) {
    width: 1258px;
  }
`;

const SwiperButton = styled(ArrowButton)`
  cursor: auto;
  pointer-events: none;
  opacity: 0;
  ${(props) => `${props.vector}: -80px`};

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    opacity: 1;
    cursor: pointer;
    pointer-events: auto;
  }

  @media screen and (min-width: ${BREAKPOINTS.wideDesktop}) {
    ${(props) => `${props.vector}: -124px`};
  }
`;

export const Styled = { SwiperContainer, CardsWrapper, NormalSwiperContainer, SwiperButton };
