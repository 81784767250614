import styled from 'styled-components';

import { COLORS, BREAKPOINTS } from 'utils/constants';

import Input from '../../base-ui/input';
import { OutlineButton } from '../../base-ui/button';

export const Form = styled.form`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const FormField = styled.div`
  width: 280px;

  &:not(:last-of-type) {
    margin-bottom: 16px;

    @media screen and (min-width: ${BREAKPOINTS.tablet}) {
      margin-bottom: 25px;
    }
  }
`;

export const FormLabel = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: ${COLORS.gray7};
  align-self: flex-start;
  margin-bottom: 4px;
`;

export const StyledInput = styled(Input)`
  > input {
    border-color: ${COLORS.white};
  }
`;

export const StyledButton = styled(OutlineButton)`
  margin-bottom: 12px;
  background-color: white;
  color: ${COLORS.secondary};
  border-color: ${COLORS.white};

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin-bottom: 16px;
  }
`;

export const CaptchaField = styled.div`
  margin-bottom: 14px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin-bottom: 25px;
  }
`;
