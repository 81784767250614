import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';
import IconButton from 'components/base-ui/icon-button';

const ModalOverflow = styled.div<{ padding: number }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 16px;
  z-index: 100001;

  overflow-x: auto;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.73);
  transition: background-color 0.1s linear;
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding-right: ${(props) => props.padding}px;
    padding-left: ${(props) => props.padding}px;
  }
`;

const ModalMask = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
`;

const ModalWindow = styled.div`
  position: relative;
  padding: 40px 16px 24px 16px;
  background: ${COLORS.white};
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.08);
  border-radius: 2px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 48px;
  }
`;

const ModalCloseWrapper = styled.div`
  top: 8px;
  right: 8px;
  position: absolute;
  z-index: 2;
  cursor: pointer;
  svg {
    width: 14px;
    height: 14px;
  }
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    svg {
      width: 24px;
      height: 24px;
    }
    top: 19px;
    right: 19px;
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    top: 30px;
    right: 32px;
  }
`;

const ModalContainer = styled.div<{ centered?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 50px;
  }
`;

const ModalContent = styled.div`
  color: ${COLORS.white};
`;

const ModalCloseButton = styled(IconButton)``;

export const Styled = {
  ModalCloseButton,
  ModalContainer,
  ModalWindow,
  ModalContent,
  ModalMask,
  ModalOverflow,
  ModalCloseWrapper,
};
