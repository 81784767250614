import styled from 'styled-components';
import { BREAKPOINTS } from 'utils/constants';

export const StProjectGroupsPeoples = styled.div`
  display: flex;
  gap: 50px;
  justify-content: center;
  flex-wrap: wrap;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    gap: 40px;
    justify-content: flex-start;
  }
  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
  }
  @media screen and (min-width: ${BREAKPOINTS.wideDesktop}) {
  }
`;

export const StProjectGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 100px auto 80px auto;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin: 120px auto;
    width: 656px;
  }
  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    margin: 140px auto;
    width: 1328px;
  }
  @media screen and (min-width: ${BREAKPOINTS.wideDesktop}) {
    width: 1520px;
  }
`;
