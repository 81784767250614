import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { Child, ChildSexEnum } from 'services';

import { useProfileStore } from 'store';

import { useDeviceType } from 'utils/hooks';
import { BREAKPOINTS, COLORS } from 'utils/constants';

import Input from 'components/base-ui/input';
import InputGroup from 'components/base-ui/input-group';
import Radio from 'components/base-ui/radio';
import IconButton from 'components/base-ui/icon-button';
import Calendar from 'components/base-ui/calendar';
import { formatInputDate } from 'utils/date';

const Div = styled.div`
  position: relative;
  padding: 36px 0 32px 0;

  &:not(:last-of-type) {
    border-bottom: 1px solid ${COLORS.gray5};
  }

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 28px 0 32px 0;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding: 28px 0 40px 0;
  }
`;

const Container = styled.li`
  display: grid;
  row-gap: 12px;
  column-gap: 32px;
  margin-bottom: 0;
  grid-template-columns: repeat(1, 100%);

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    grid-template-columns: repeat(2, 256px);
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    grid-template-columns: repeat(3, 288px);
  }
`;

interface InputWrapperProps {
  row: '1' | '2';
  column: '1' | '2' | '3';
}

const InputWrapper = styled.div<InputWrapperProps>`
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    grid-row: ${(props) => props.row};
    grid-column: ${(props) => props.column};
  }
`;

const RemoveButtonWrapper = styled.div`
  top: 30px;
  right: 8px;
  width: 16px;
  height: 16px;
  position: absolute;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    top: 40px;

    &:after {
      content: '';
      top: 0;
      left: -40px;
      width: 1px;
      height: 48px;
      position: absolute;
      background-color: ${COLORS.gray5};
    }
  }
`;

const RemoveButton = styled(IconButton)`
  filter: invert(25%) sepia(13%) saturate(557%) hue-rotate(176deg) brightness(82%) contrast(87%);
`;

interface ItemProps {
  idx: number;
  child: Child | Partial<Child>;
  disabled?: boolean;
}

const Item: React.FC<ItemProps> = observer(({ idx, child, disabled }) => {
  const deviceType = useDeviceType();

  const id = useMemo(() => `user__child-${idx}`, [idx]);

  const isDesktop = useMemo(() => deviceType === 'desktop', [deviceType]);

  const profileStore = useProfileStore();

  const removeChild = useCallback(
    () => !disabled && profileStore.removeChild(Number(child.id)),
    [disabled, child.id, profileStore],
  );

  const onChange = (key: keyof Child) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      profileStore.editChild({ ...child, [key]: e.target.value });
    };
  };

  const handleGender = (value?: string) => {
    if (value) {
      profileStore.editChild({ ...child, sex: value as ChildSexEnum });
    }
  };

  const handleBirthday = (value: Date | null) => {
    profileStore.editChild({ ...child, birthday: formatInputDate(value?.toString()) });
  };

  return (
    <Div>
      <Container>
        <InputWrapper row="1" column="1">
          <Input id={id.concat('-name')} label="Имя" value={child.name || ''} onChange={onChange('name')} />
        </InputWrapper>

        <InputWrapper row={isDesktop ? '1' : '2'} column={isDesktop ? '2' : '1'}>
          <Calendar
            id={id.concat('-birth-date')}
            value={child.birthday ? new Date(child.birthday) : null}
            label="Дата рождения"
            onChange={handleBirthday}
            unlimitedChoiseDates={true}
          />
        </InputWrapper>

        <InputWrapper row="1" column={isDesktop ? '3' : '2'}>
          <InputGroup label="Пол">
            <Radio
              id={id.concat('-gender--male')}
              name="gender"
              value={ChildSexEnum.Male}
              label="Мужской"
              checked={child.sex === ChildSexEnum.Male}
              onClick={handleGender}
            />
            <Radio
              id={id.concat('-gender--female')}
              name="gender"
              value={ChildSexEnum.Female}
              label="Женский"
              checked={child.sex === ChildSexEnum.Female}
              onClick={handleGender}
            />
          </InputGroup>
        </InputWrapper>
      </Container>

      <RemoveButtonWrapper>
        <RemoveButton name="iconCrossSmall" onClick={removeChild} />
      </RemoveButtonWrapper>
    </Div>
  );
});

export default Item;
