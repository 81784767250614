import React from 'react';

import { ServicesListComponent } from '../../../components/services';
import ScrollToTopOnMount from '../../../components/base-ui/scroll-to-top';
import { DefaultLayout } from '../../../layouts';

const ServicesListPage: React.FC = () => {
  return (
    <>
      <ScrollToTopOnMount />

      <DefaultLayout>
        <ServicesListComponent />
      </DefaultLayout>
    </>
    );
};

export default ServicesListPage;
