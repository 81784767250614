import React from 'react';
import { AboutProjectPeopleItem } from 'web';
import { AboutProjectGroupHeader } from './components/header';
import { StProjectGroup, StProjectGroupsPeoples } from './styled';
import { ProjectGroupItem } from './components/project-group-item';

interface Props {
  header: string;
  text: string;
  people: AboutProjectPeopleItem[];
}

export const AboutProjectGroup: React.FC<Props> = (props) => {
  return (
    <StProjectGroup>
      <AboutProjectGroupHeader header={props.header} text={props.text} />
      <div>
        <StProjectGroupsPeoples>
          {props.people.map((item) => (
            <ProjectGroupItem key={item.header} people={item} />
          ))}
        </StProjectGroupsPeoples>
      </div>
    </StProjectGroup>
  );
};
