import styled, { css } from 'styled-components';
import Link from 'components/base-ui/link';
import IconButton from 'components/base-ui/icon-button';
import { H6 } from 'components/base-ui/typo';
import { COLORS } from 'utils/constants';

export const Div = styled.div`
  display: grid;
  grid-template-rows: 84px 1fr auto minmax(104px, auto);
`;
export const ImageWrapper = styled(Link)`
  display: block;
  overflow: hidden;
  position: relative;
`;
export const Image = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin-top: 7px;
`;
export const DiscountWrapper = styled.div`
  top: 20px;
  left: 4px;
  position: absolute;
`;
export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 12px 16px 0;
`;
export const FavoriteButton = styled(IconButton)``;

export const RatingScore = styled(H6)`
  margin-left: 8px;
  line-height: 1em;
  padding-top: 3px;
  font-weight: normal;
  color: ${COLORS.copper};
`;

export const Rating = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const filledStyles = css`
  & > img {
    filter: invert(30%) sepia(12%) saturate(793%) hue-rotate(9deg) brightness(100%) contrast(88%);
  }
`;

export const Like = styled.div`
  width: 20px;
  height: 20px;
`;
