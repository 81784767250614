import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';

export const SmallHeader = styled.div`
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-size: 18px;
    line-height: 28px;
  }
`;
export const BigHeader = styled.div`
  font-weight: 700;
  font-size: 64px;
  line-height: 77px;
  margin-bottom: 142px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin-bottom: 189px;
  }
`;
export const Text = styled.div`
  font-size: 16px;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-clamp: 3;
`;
export const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 27px;
  left: 16px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    display: none;
    left: 40px;
    bottom: 36px;
  }
`;
export const Button = styled.div`
  display: flex;
  align-items: center;
`;
export const ButtonText = styled.div`
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  margin-right: 7px;
`;
export const ButtonIcon = styled.div`
  display: none;
  height: 24px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    display: block;
  }
`;
export const Item = styled.div<{ bg: string }>`
  width: 321px;
  height: 442px;
  background: url(${(props) => props.bg}) no-repeat;
  background-size: cover;
  grid-row: 1;
  grid-column: 1;
  cursor: pointer;
  color: ${COLORS.white};
  padding: 36px 10px 0 10px;
  position: relative;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 56px 53px 82px 40px;
    width: 413px;
    height: 528px;
    :hover {
      background: ${COLORS.primary};
      padding: 35px 52px 0 41px;

      & > ${ButtonWrapper} {
        display: block;
      }

      & > ${SmallHeader} {
        font-size: 8px;
      }

      & > ${BigHeader} {
        font-size: 32px;
        line-height: 39px;
        margin-bottom: 16px;
      }

      & > ${Text} {
        -webkit-line-clamp: 11;
        line-clamp: 11;
      }
    }
  }
`;
export const Container = styled.div`
  background: ${COLORS.linearGradient};
  display: grid;
  grid-row-gap: 28px;
  align-items: flex-end;
`;
