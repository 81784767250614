import React from 'react';

import { DefaultLayout } from '../../layouts';

import AboutComponent from '../../components/about';
import ScrollToTopOnMount from '../../components/base-ui/scroll-to-top';

const AboutPage: React.FC = () => {
  document.title = 'О нас';

  return (
    <>
      <ScrollToTopOnMount />

      <DefaultLayout>
        <AboutComponent />
      </DefaultLayout>
    </>
  );
};

export default AboutPage;
