import React from 'react';
import styled from 'styled-components';

import { COLORS } from '../../../../../../utils/constants';

import { TextButton } from '../../../../../base-ui/button';
import { Span } from '../../../../../base-ui/typo';
import Icon from '../../../../../base-ui/icon';
import { useProfileStore } from '../../../../../../store/profile';

const Container = styled.li`
  display: inline;
  margin-bottom: 0;
  margin-right: 4px;
`;

const ItemButton = styled(TextButton)`
  display: inline-flex;
  align-items: center;
`;

const ItemButtonText = styled(Span)`
  font-size: 16px;
  line-height: 24px;
  color: ${COLORS.gray2};
`;

const ItemRemoveIcon = styled(Icon)`
  width: 16px;
  height: 16px;
  margin: 0 8px;
  filter: invert(20%) sepia(12%) saturate(233%) hue-rotate(184deg) brightness(90%) contrast(83%);
`;

interface HobbieProps {
  name: string;
  id: number;
  disabled?: boolean;
}

const Item: React.FC<HobbieProps> = ({ name, id, disabled }) => {
  const profileStore = useProfileStore();
  const removeHobby = () => {
    profileStore.removeHobby(id);
  };
  return (
    <Container>
      <ItemButton>
        <ItemButtonText>{name}</ItemButtonText>
        {!disabled && <ItemRemoveIcon name="iconCrossSmall" onClick={removeHobby} />}
      </ItemButton>
    </Container>
  );
};

export default Item;
