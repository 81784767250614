import React from 'react';

import { HtmlRenderer } from 'utils/htmlParser';
import { Styled } from './styles';

interface BriefProps {
  header: string;
  preview?: string;
}

export const Brief: React.FC<BriefProps> = (props) => {
  return (
    <Styled.Div>
      <Styled.Head>
        <Styled.Title>
          <HtmlRenderer html={props.header} />
        </Styled.Title>
        {props.preview && (
          <Styled.Body>
            <Styled.Description>
              <HtmlRenderer html={props.preview} />
            </Styled.Description>
          </Styled.Body>
        )}
      </Styled.Head>
    </Styled.Div>
  );
};
