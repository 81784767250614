import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Nullable } from 'web';

interface DropdownProps {
  visible: boolean;
  dropdownContent: React.JSX.Element;
  onVisibleChange: (visible: boolean) => void;
}

const DropdownContainer = styled.div`
  position: relative;
`;

const DropdownComponent = styled.div<{ visible: boolean }>`
  position: absolute;
  top: 42px;
  width: 288px;
  display: ${(props) => (props.visible ? 'block' : 'none')};
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.08);
  border-radius: 2px;
  z-index: 9999;
  background: #fff;
`;

export const Dropdown: React.FC<DropdownProps> = ({ visible, dropdownContent, onVisibleChange, children }) => {
  const node = useRef<Nullable<HTMLDivElement>>(null);

  const [open, setOpen] = useState(visible);

  const handleClick = (e: MouseEvent) => {
    if (node.current?.contains(e.target as Node)) {
      // inside click
      return;
    }
    // outside click
    setOpen(false);
    onVisibleChange(false);
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);
  useEffect(() => {
    setOpen(visible);
  }, [visible]);
  return (
    <DropdownContainer>
      {children}
      <DropdownComponent visible={open} ref={node}>
        {dropdownContent}
      </DropdownComponent>
    </DropdownContainer>
  );
};
