import styled, { css } from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';
import Link from 'components/base-ui/link';
import HtmlContainer from 'components/base-ui/html-container';
import { H6, Micro } from 'components/base-ui/typo';
import IconButton from 'components/base-ui/icon-button';

export const Div = styled.div`
  position: relative;
  padding: 8px 0;
  margin-bottom: 16px;

  &:after {
    content: '';
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    position: absolute;
    background: ${COLORS.gray5};
  }
`;
export const Grid = styled.div`
  display: grid;
  row-gap: 10px;
  column-gap: 5px;
  grid-template-columns: minmax(150px, 1fr) auto auto;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    column-gap: 10px;
    grid-auto-flow: column;
    grid-template-columns: 175px 1fr 120px auto 48px;
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    column-gap: 40px;
    grid-template-columns: 175px 48px 120px 1fr 24px 200px 66px 48px;
  }
`;
export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const TitleWrapper = styled(Wrapper)`
  @media screen and (max-width: calc(${BREAKPOINTS.tablet} - 1px)) {
    grid-row: 1;
    grid-column: 1 / 3;
  }
`;
export const Title = styled(Link)`
  font-size: 18px;
  line-height: 20px;
  color: ${COLORS.secondary};
`;
export const TitleContainer = styled(HtmlContainer)`
  font-size: inherit;
  line-height: inherit;
  color: inherit;
`;
export const FavoriteWrapper = styled(Wrapper)`
  grid-column: 5;
`;
export const TagsWrapper = styled(Wrapper)`
  flex-wrap: wrap;

  @media screen and (max-width: calc(${BREAKPOINTS.tablet} - 1px)) {
    grid-row: 2;
    grid-column: 1;
  }
`;
export const Tag = styled(Link)`
  font-size: 14px;
  line-height: 20px;
  color: ${COLORS.gray3};
  overflow-wrap: anywhere;

  &:not(:last-of-type) {
    margin-right: 0.5ch;
  }
`;
export const PriceWrapper = styled(Wrapper)`
  @media screen and (max-width: calc(${BREAKPOINTS.tablet} - 1px)) {
    grid-row: 1;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    display: grid;
    column-gap: 12px;
    grid-auto-flow: column;
    grid-auto-columns: auto;
  }
`;
export const PriceWithoutDiscount = styled(Micro)`
  color: ${COLORS.gray4};
  text-decoration: line-through;
`;
export const Price = styled(H6)`
  text-align: end;
  color: ${COLORS.secondary};
  /* white-space: nowrap; */
`;
export const InputWrapper = styled(Wrapper)`
  @media screen and (max-width: calc(${BREAKPOINTS.tablet} - 1px)) {
    grid-row: 2;
    grid-column: 2;
  }
`;
export const AddWrapper = styled(Wrapper)`
  @media screen and (max-width: calc(${BREAKPOINTS.tablet} - 1px)) {
    grid-row: 2;
    margin-left: 11px;
    display: flex;
    justify-content: flex-end;
  }
`;

export const filledStyles = css`
  & > img {
    filter: invert(30%) sepia(12%) saturate(793%) hue-rotate(9deg) brightness(100%) contrast(88%);
  }
`;

export const StarButton = styled(IconButton)`
  opacity: 0.8;
  ${filledStyles};
`;

export const Like = styled.div`
  width: 20px;
  height: 20px;
`;

export const LikesScore = styled.div`
  color: ${COLORS.copper};
  font-weight: 400;
  font-size: 16px;
  height: 24px;
  margin-left: 10px;
`;
