import React, { useEffect } from 'react';
import styled from 'styled-components';

import { BREAKPOINTS, COLORS } from '../../../utils/constants';

import IconButton from '../icon-button';

const ModalWrapper = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;

  overflow-x: auto;
  overflow-y: auto;
  z-index: 999999;
  position: fixed;
  background: rgba(0, 0, 0, 0.73);
`;

const ModalContainer = styled.div`
  position: relative;
  margin: 60px auto;
  isolation: isolate;
  border-radius: 2px;
  color: ${COLORS.white};
  width: calc(100% - 16px * 2);
  padding: 70px 24px 56px 24px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.08);
  background: ${COLORS.linearGradient};

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin: 10% auto;
    width: 656px;
    padding: 24px 32px 56px 32px;
  }
`;

const ModalCloseWrapper = styled.div`
  top: 23px;
  right: 32px;
  position: absolute;
  z-index: 2;
`;

const ModalCloseButton = styled(IconButton)``;

const ModalChildrenWrapper = styled.div`
  height: 372px;
  display: grid;
  row-gap: 16px;
  grid-template-rows: 1fr auto;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    height: 368px;
    column-gap: 32px;
    padding-right: 40px;
    /* grid-template-rows: 1fr auto; */
    grid-template-columns: repeat(2, 1fr);
    position: relative;
    z-index: 1;
  }
`;

interface ModalProps {
  onClick: () => void;
}

const Modal: React.FC<ModalProps> = ({ onClick, children }) => {
  useEffect(() => {
    const width = window.innerWidth - document.documentElement.clientWidth;
    document.body.style.overflowY = 'hidden';
    document.body.style.width = `calc(100% - ${width}px)`;
    return () => {
      document.body.style.width = 'unset';
      document.body.style.overflowY = 'visible';
    };
  }, []);

  return (
    <ModalWrapper>
      <ModalContainer>
        <ModalCloseWrapper>
          <ModalCloseButton name="iconCrossBaseWhite" onClick={onClick} />
        </ModalCloseWrapper>

        <ModalChildrenWrapper>{children}</ModalChildrenWrapper>
      </ModalContainer>
    </ModalWrapper>
  );
};

export default Modal;
