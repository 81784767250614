import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { Block } from 'utils/blocks';
import { Div, Section } from 'components/news/list/styles';
import { useNewsStore } from 'store';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { BlocksRender } from '../../renderers';

import Breadcrumbs from '../../base-ui/breadcrumbs';

const NewsList: React.FC = observer(() => {
  const store = useNewsStore();
  const { trackPageView } = useMatomo();

  useEffect(() => {
    store.fetchMainNews();
    document.title = 'Новости';
    trackPageView({});
  }, []);

  const handleCloseErrorScreen = useCallback(() => store.closeErrorScreen(), [store.mainNews]);

  return (
    <Div>
      <Section>
        <Breadcrumbs />
      </Section>
      {store.mainNews?.content && (
        <BlocksRender
          blocks={store.mainNews.content as unknown as Block[]}
          errorScreen={store.mainNews.errorScreen}
          closeErrorScreenCallBack={handleCloseErrorScreen}
        />
      )}
    </Div>
  );
});

export default NewsList;
