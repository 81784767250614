import styled, { css } from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';
import { OverFlowScrollbar } from 'styles/global';

const Container = styled.div<{ withScroll?: boolean }>`
  ${OverFlowScrollbar};
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow-y: auto;
  ::-webkit-scrollbar-track {
    border: 1px solid ${COLORS.gray6};
  }

  ${(props) =>
    props.withScroll &&
    css`
      padding-right: 32px;
    `}

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: 398px;
    max-height: 552px;
  }
`;

const ScrollContainer = styled.div`
  padding: 24px 32px 24px 32px;
`;

const Title = styled.span`
  font-weight: 400;
  font-size: 28px;
  line-height: 32px;
  word-wrap: break-word;

  color: ${COLORS.secondary};
`;

const Subtitle = styled.span`
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  word-wrap: break-word;
`;

const Text = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  word-wrap: break-word;
`;

const Company = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: ${COLORS.gray3};
`;

const Participants = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 100%;
  margin-top: -12px;
`;

export const Styled = { Container, Title, Subtitle, Text, Company, Participants, ScrollContainer };
