import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${COLORS.gray3};
  width: 320px;
  background-color: ${COLORS.white};
  padding-top: 16px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding-top: 32px;
    margin-top: -80px;
    z-index: 9;
    width: 698px;
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: 1136px;
    padding-top: 48px;
  }
`;

export const Header = styled.div`
  text-align: center;
  color: ${COLORS.gray2};
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 24px;
  width: 288px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-size: 44px;
    line-height: 48px;
    width: 698px;
    margin-bottom: 40px;
  }
`;

export const Body = styled.div`
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  width: 288px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 698px;
  }
`;
