import styled from 'styled-components';
import { fade } from 'styles';
import { BREAKPOINTS, COLORS } from 'utils/constants';
import { H1 } from 'components/base-ui/typo';
import HtmlContainer from 'components/base-ui/html-container';
import { DivProps } from './index';

export const Div = styled.div<DivProps>`
  z-index: 5;
  height: inherit;
  animation: ${fade} 0.2s;
  padding: 10px 16px 75px 16px;
  max-width: 100vw;
  grid-template-rows: auto 1fr auto;
  display: ${(props) => (props.active ? 'grid' : 'none')};

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 0 0 80px 56px;
    max-width: calc(384px + 71.5px + 56px);
    grid-template-rows: repeat(3, min-content);
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding: 0 0 80px 136px;
    max-width: calc(545px + 112px + 136px);
  }
`;
export const Title = styled(H1)`
  margin-bottom: 16px;
`;
export const Content = styled.div`
  width: 100%;
  min-height: 0;
  overflow-x: visible;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 384px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: 488px;
    overflow-x: hidden;
  }
`;
export const Description = styled(HtmlContainer)`
  font-size: 14px;
  line-height: 20px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 7;
  overflow: hidden;
  & > p {
    display: inline;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  a {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: ${COLORS.white};
  }

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-size: 14px;
    line-height: 20px;
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    & > p {
      overflow: visible;
    }
  }
`;
export const ButtonWrapper = styled.div`
  width: 210px;
  margin-top: 20px;
  z-index: 9;
`;
