import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';

export const Wrapper = styled.div`
  border-top: 1px solid ${COLORS.gray5};
  display: flex;
  width: 550px;
  margin-top: 20px;
  padding: 10px;
  font-size: 12px;
  @media screen and (max-width: ${BREAKPOINTS.tablet}) {
    width: auto;
  }
`;

export const WarningContainer = styled.div`
  margin-left: 10px;
  color: ${COLORS.red};
  line-height: 20px;
`;
