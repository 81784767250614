import React, { createContext, useContext } from 'react';

import FooterStore from './store';

const store = new FooterStore();

const FooterStateContext = createContext<FooterStore>(store);

export const FooterStateProvider: React.FC = ({ children }) => (
  <FooterStateContext.Provider value={store}>{children}</FooterStateContext.Provider>
);

export const useFooterStore = (): FooterStore => useContext(FooterStateContext);
