import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { useProfileStore } from '../../../../../store';

import { BREAKPOINTS } from '../../../../../utils/constants';

import ChildrenItem from './item';

const Div = styled.div`
  position: relative;
  margin-bottom: 40px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    grid-template-columns: repeat(2, 256px);
  }
`;

const ChildrenList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const UserChildren: React.FC = observer(() => {
  const { userChilds } = useProfileStore();
  return (
    <Div>
      <ChildrenList>
        {userChilds.map((p, index) => (
          <ChildrenItem child={p} idx={index} />
        ))}
      </ChildrenList>
    </Div>
  );
});

export default UserChildren;
