import React, { ReactText, useCallback, useState } from 'react';
import styled, { css } from 'styled-components';

import { useHandleRect } from 'utils/hooks';
import { COLORS } from 'utils/constants';

import IconButton from 'components/base-ui/icon-button';

const shared = css`
  top: 53px;
  left: 0;
  width: 100%;
  position: fixed;
  z-index: 1000000;
`;

const ChipBG = styled.div<{ error?: boolean }>`
  ${shared};
  height: 185px;
  mix-blend-mode: multiply;
  background: ${(props) => (props.error ? '#C31B1C' : COLORS.linearGradient)};
`;

const ChipContainer = styled.div`
  ${shared};
  padding: 32px 0 40px 0;
  color: ${COLORS.white};
`;

const ChipCloseWrapper = styled.div`
  top: 16px;
  right: 16px;
  position: absolute;
  isolation: isolate;
`;

const ChipCloseButton = styled(IconButton)``;

const ChipContent = styled.div`
  margin: 0 auto;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  isolation: isolate;
`;

interface GoodsItemChipProps {
  visible: boolean;
  content: React.JSX.Element | ReactText;
  onClose: () => void;
  error?: boolean;
}

const Chip: React.FC<GoodsItemChipProps> = ({ visible, content, onClose, error }) => {
  const [height, handleRect] = useHandleRect<HTMLDivElement>();

  return visible ? (
    <>
      <ChipBG style={{ height }} error={error} />
      <ChipContainer ref={handleRect}>
        <ChipContent>{content}</ChipContent>

        <ChipCloseWrapper>
          <ChipCloseButton name="iconCrossSmall" onClick={onClose} />
        </ChipCloseWrapper>
      </ChipContainer>
    </>
  ) : null;
};

type ChipProps = Pick<GoodsItemChipProps, 'content' | 'error'>;

export const useChip = (timeout = 2000): [() => void, React.FC<ChipProps>] => {
  const [visible, setVisible] = useState<boolean>(false);

  const trigger = useCallback(() => {
    setVisible(true);
    setTimeout(() => setVisible(false), timeout);
  }, [timeout]);

  const onClose = useCallback(() => setVisible(false), []);

  const ChipComponent: React.FC<ChipProps> = ({ content, error }) => (
    <Chip visible={visible} content={content} onClose={onClose} error={error} />
  );

  return [trigger, ChipComponent];
};
