import { makeAutoObservable, runInAction } from 'mobx';

import { Nullable } from 'web';

import { api } from 'utils/api';

import { AxiosError } from 'axios';
import { MediaProduct, MediaProductsApiMediaProductsListRequest } from '../../services';

export default class MediaProductsStore {
  fetching = false;

  error: Nullable<Error> = null;

  count = 0;

  products: MediaProduct[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  fetchProducts(props: MediaProductsApiMediaProductsListRequest, options?: unknown): Promise<void> {
    this.setError(null);
    this.setFetching(true);
    return api.mediaProducts
      .mediaProductsList(props, options)
      .then((res) => {
        runInAction(() => {
          this.setCount(res.data.count);
          this.setProducts(res.data.results);
        });
      })
      .catch((err: AxiosError) => this.setError(err))
      .finally(() => this.setFetching(false));
  }

  setFetching(value: boolean): void {
    this.fetching = value;
  }

  setError(value: Nullable<Error>): void {
    this.error = value;
  }

  setCount(value: number): void {
    this.count = value;
  }

  setProducts(value: MediaProduct[]): void {
    this.products = value;
  }
}
