import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';

import { SubstitutedCategoryType } from 'web';

import { Product, SearchResult } from 'services';

import { useSearchStore } from 'store';

import ProductListItem from 'components/services/shared/product';
import { MoreButton } from 'components/base-ui/more-button-wrapper';
import { SearchSection } from '../index';

interface PaginatedListProps {
  field: keyof SearchResult;
  category?: SubstitutedCategoryType;
  heading?: string;
}

export const SearchPaginatedList: React.FC<PaginatedListProps> = observer((props) => {
  const store = useSearchStore();

  const { searchValue } = useParams<{ searchValue: string }>();

  const restCount = useMemo(() => {
    const totalRest = store.fieldCount(props.field) - store.fieldData(props.field).length;
    return totalRest > store.limit ? store.limit : totalRest;
  }, [store, props.field]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {store.fieldData(props.field).length > 0 && (
        <SearchSection compact heading={props.heading} noFirstOfTypeOffset>
          {(store.fieldData(props.field) as Product[]).map((el) => (
            <ProductListItem key={el.id} data={el} category={{ id: el.category ?? undefined, type: props.category }} />
          ))}

          {restCount > 0 && (
            <MoreButton onClick={() => store.fetchNext(searchValue, props.field)}>
              Еще {restCount} результатов
            </MoreButton>
          )}
        </SearchSection>
      )}
    </>
  );
});
