import React, { useCallback, useState } from 'react';
import FsLightbox, { FsLightboxProps } from 'fslightbox-react';

interface LightboxProps extends FsLightboxProps {
  handleClose: () => void;
  showLightBox: boolean;
}

export const Lightbox: React.FC<LightboxProps> = (props) => {
  const { sources, handleClose, showLightBox } = props;
  const [crutchCSS, setCrutchCSS] = useState<string>();

  const onInit = useCallback(() => {
    const scrollWidth = window.innerWidth - document.documentElement.clientWidth;

    // fixed jumping a scroll and fixed flickering after closing

    setCrutchCSS(`
      .fslightbox-open {
        height: unset !important;
      }
      body {
        margin-right: unset !important;
        width: calc(100% - ${scrollWidth}px);
      }
      .fslightbox-fade-out-strong {
        animation: none;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s 0.25s, opacity 0.25s ease;
      }
      .fslightbox-container {
        background: #464A53;
      }
      .fslightbox-slide-btn {
        border-radius: 25px;
      }
      .fslightbox-slide-number-container {
        display: none;
      }
      .fslightbox-toolbar {
        background: unset;
      }
    `);
  }, []);

  return showLightBox ? (
    <>
      <style>{crutchCSS}</style>
      <FsLightbox
        toggler={true}
        openOnMount={true}
        sources={sources}
        onClose={handleClose}
        onInit={onInit}
        disableLocalStorage
      />
    </>
  ) : null;
};
