import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { Application } from 'services';

import { useCartStore, useProfileStore, useShopsStore } from 'store';

import { useDeviceType } from 'utils/hooks';
import { REGEXP } from 'utils/constants';
import { useHistory } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { Warning } from 'components/cart/user-info/warning';
import {
  AboutWrapper,
  ButtonDiv,
  Div,
  ErrorWrapper,
  Form,
  FormField,
  Grid,
  InputsWrapper,
  SelectShop,
  StyledArrowButton,
  StyledOutlineButton,
} from 'components/cart/user-info/styles';
import Input from 'components/base-ui/input';

import About from '../about';

interface UserInfoProps {
  categoryId: number;
}

const UserInfo: React.FC<UserInfoProps> = observer((props) => {
  const deviceType = useDeviceType();

  const isDesktop = useMemo(() => deviceType === 'desktop', [deviceType]);
  const history = useHistory();
  const { profile } = useProfileStore();
  const { application, initApplicationFromProfile, editApplication, createApplication } = useCartStore();
  const { shops } = useShopsStore();
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { handleSubmit, errors, control, getValues, reset } = useForm();

  const wineMarketLink = `/goods/catalog/${props.categoryId}`;

  const [error, setError] = useState(false);

  useEffect(() => {
    reset({
      lastName: application?.lastName,
      firstName: application?.firstName,
      patronymic: application?.patronymic,
      phone: application?.phone,
      email: application?.email,
    });
  }, [application]);

  useEffect(() => {
    if (Object.keys(errors).length) {
      setError(true);
    } else setError(false);
  }, [errors]);

  useEffect(() => {
    initApplicationFromProfile(profile!);
  }, [profile]);

  const onChange = (key: keyof Application) => {
    return (e: ChangeEvent<HTMLInputElement>) => {
      editApplication(key, e.target.value);
    };
  };

  const createNewApplication = useCallback(() => {
    createApplication(props.categoryId);
  }, [props.categoryId, createApplication]);

  const handleShop = (shopId?: string | null) => {
    if (shopId) {
      editApplication('shop', +shopId);
    }
  };

  const readShopId = () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const value = getValues('shops');
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
    handleShop(value?.value || null);
  };

  return (
    <Form onSubmit={handleSubmit(createNewApplication)}>
      <Div>
        <Grid>
          <InputsWrapper>
            <FormField onChange={onChange('lastName')}>
              <Controller
                control={control}
                name="lastName"
                defaultValue={application?.lastName || ''}
                rules={{
                  required: {
                    value: true,
                    message: 'Укажите вашу фамилию',
                  },
                  pattern: {
                    value: REGEXP.onlyCyrillic,
                    message: 'Фамилия должна быть написана на кириллице',
                  },
                }}
                render={({ value, onChange }) => (
                  <Input
                    id="user--last-name"
                    onChange={onChange}
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    value={value}
                    label="Фамилия"
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    error={errors.lastName && ''}
                  />
                )}
              />
              {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
              {errors.lastName && <ErrorWrapper>{errors.lastName.message}</ErrorWrapper>}
            </FormField>

            <FormField onChange={onChange('firstName')}>
              <Controller
                control={control}
                name="firstName"
                rules={{
                  required: {
                    value: true,
                    message: 'Укажите ваше имя',
                  },
                  pattern: {
                    value: REGEXP.onlyCyrillic,
                    message: 'Имя должно быть написано на кириллице',
                  },
                }}
                render={({ value, onChange }) => (
                  <Input
                    id="user--first-name"
                    onChange={onChange}
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    value={value}
                    label="Имя"
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    error={errors.firstName && ''}
                  />
                )}
              />
              {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
              {errors.firstName && <ErrorWrapper>{errors.firstName.message}</ErrorWrapper>}
            </FormField>

            <FormField onChange={onChange('patronymic')}>
              <Controller
                control={control}
                name="patronymic"
                rules={{
                  required: {
                    value: true,
                    message: 'Укажите ваше отчество',
                  },
                  pattern: {
                    value: REGEXP.onlyCyrillic,
                    message: 'Отчество должно быть написано на кириллице',
                  },
                }}
                render={({ value, onChange }) => (
                  <Input
                    id="user--patronymic"
                    onChange={onChange}
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    value={value}
                    label="Отчество"
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    error={errors.patronymic && ''}
                  />
                )}
              />
              {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
              {errors.patronymic && <ErrorWrapper>{errors.patronymic.message}</ErrorWrapper>}
            </FormField>

            <FormField onChange={onChange('phone')}>
              <Controller
                control={control}
                name="phone"
                rules={{
                  required: {
                    value: true,
                    message: 'Укажите номер телефона',
                  },
                  pattern: {
                    value: REGEXP.phoneNumber,
                    message: 'Номер телефона указан неверно',
                  },
                }}
                render={({ value, onChange }) => (
                  <Input
                    id="user--phone"
                    onChange={onChange}
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    value={value}
                    label="Телефон"
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    error={errors.phone && ''}
                  />
                )}
              />
              {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
              {errors.phone && <ErrorWrapper>{errors.phone.message}</ErrorWrapper>}
            </FormField>

            <FormField onChange={onChange('email')}>
              <Controller
                control={control}
                name="email"
                rules={{
                  required: {
                    value: true,
                    message: 'Укажите ваш email',
                  },
                  pattern: {
                    value: REGEXP.email,
                    message: 'Email указан неверно',
                  },
                }}
                render={({ value, onChange }) => (
                  <Input
                    id="user--email"
                    onChange={onChange}
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    value={value}
                    label="Email"
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    error={errors.email && ''}
                  />
                )}
              />
              {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
              {errors.email && <ErrorWrapper>{errors.email.message}</ErrorWrapper>}
            </FormField>

            <FormField onClick={readShopId}>
              <Controller
                control={control}
                name="shops"
                rules={{
                  required: {
                    value: true,
                    message: 'Выберите пункт самовывоза',
                  },
                }}
                render={({ value, onChange }) => (
                  <SelectShop
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    borderError={errors.shops}
                    label="Пункт самовывоза"
                    options={shops.map((s) => ({
                      label: `${s.city.name}, ${s.name}, ${s.address}` || '',
                      value: String(s.id),
                    }))}
                    onChange={onChange}
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    value={value}
                  />
                )}
              />
              {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
              {errors.shops && <ErrorWrapper>{errors.shops.message}</ErrorWrapper>}
            </FormField>
            {error && <Warning />}
          </InputsWrapper>

          {!isDesktop && (
            <AboutWrapper>
              <About />
            </AboutWrapper>
          )}
        </Grid>

        <ButtonDiv>
          <StyledOutlineButton onClick={() => history.push(wineMarketLink)}>Продолжить покупки</StyledOutlineButton>
          <StyledArrowButton white gradient type="submit">
            Оформить заказ
          </StyledArrowButton>
        </ButtonDiv>
      </Div>
    </Form>
  );
});

export default UserInfo;
