import { action, makeObservable, observable } from 'mobx';
import AbstractStore from '../AbstractStore';

export default class UnderConstructionStore extends AbstractStore {
  modalState = false;

  sectionTitle = '';

  constructor() {
    super();
    makeObservable(this, {
      ...this.annotations,
      modalState: observable,
      sectionTitle: observable,
      setModalVisible: action,
    });
  }

  setModalVisible(state: boolean) {
    this.modalState = state;
  }

  setSectionTitle(title: string) {
    this.sectionTitle = title;
  }

  setModalToggle() {
    this.modalState = !this.modalState;
  }
}
