import React, { createContext, useContext } from 'react';

import ProductAttributeStore from './store';

const store = new ProductAttributeStore();

const ProductAttributeStateContext = createContext<ProductAttributeStore>(store);

export const ProductAttributeStateProvider: React.FC = ({ children }) => (
  <ProductAttributeStateContext.Provider value={store}>{children}</ProductAttributeStateContext.Provider>
);

export const useProductAttributeStore = (): ProductAttributeStore => useContext(ProductAttributeStateContext);
