import React, { useCallback, useMemo, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import { ParticipantItems } from 'services';
import { Container } from 'components/base-ui/container';
import { useDeviceState } from 'hooks/useDeviceProvider';
import { Swiper as SwiperType } from 'swiper/types';
import { Styled } from './styles';
import { PhotoResponsive } from '../Photo';
import { Participant, TextSize } from '../Participant';

export const Gallery: React.VFC<{
  participants: ParticipantItems[];
  type: 'modal' | 'winnerCard';
}> = (props) => {
  const participantsWithPhoto = useMemo(() => props.participants.filter((item) => !!item.image), [props.participants]);
  const { isLessTablet } = useDeviceState();
  const swiperRef = useRef<SwiperType>();

  const handleOnPrevClick = useCallback(() => swiperRef.current?.slidePrev(), [swiperRef.current]);
  const handleOnNextClick = useCallback(() => swiperRef.current?.slideNext(), [swiperRef.current]);

  return (
    <Styled.SwiperWrapper type={props.type} withDescriptionOutside={Boolean(isLessTablet) && props.type === 'modal'}>
      <Swiper
        watchOverflow
        speed={500}
        modules={[Navigation, Pagination]}
        slidesPerView={1}
        loop={participantsWithPhoto.length > 1}
        navigation={{ prevEl: '.slick-arrow-left', nextEl: '.slick-arrow-right', enabled: !isLessTablet }}
        pagination={{ clickable: true }}
      >
        {participantsWithPhoto.map((item) => (
          <SwiperSlide key={item.id}>
            <Container gap="8px" direction="column">
              <PhotoResponsive
                variant={props.type}
                key={item.id}
                name={item.name}
                src={item.image!}
                company={item.company?.name}
                withDots={participantsWithPhoto.length > 1}
              />
              {isLessTablet && props.type === 'modal' && (
                <Participant size={TextSize.Small} name={item.name} company={item.company?.name} />
              )}
            </Container>
          </SwiperSlide>
        ))}
      </Swiper>
      <Styled.SwiperButton onClick={handleOnNextClick} vector="right" />
      <Styled.SwiperButton onClick={handleOnPrevClick} vector="left" />
    </Styled.SwiperWrapper>
  );
};
