import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Container } from 'components/base-ui/container';
import { AssolWinnersAndParticipantsProvider, useAssolParticipantsStore } from 'store/assol-participants';
import { useHistory, useParams } from 'react-router-dom';
import { toJS } from 'mobx';
import { useDeviceState } from 'hooks/useDeviceProvider';
import { Styled } from './styles';
import { Nominations, Grid, WinnerCard } from './ui';

const WinnersAndNomineesContent: React.VFC = observer(() => {
  const store = useAssolParticipantsStore();
  const { awardId, nominationId } = useParams<{ awardId: string; nominationId: string }>();
  const { nominations, currentNomination, winners, aloneWinner, nominees, setCurrentNomination } = store;
  const history = useHistory();

  const { isDesktop, isLessLaptop } = useDeviceState();

  useEffect(() => {
    store.fetchNominations(Number(awardId), nominationId ? Number(nominationId) : undefined);
    return store.cleanUpAll;
  }, []);

  useEffect(() => {
    if (currentNomination?.id) {
      history.push(`/projects/award/${awardId}/${currentNomination?.id}`);
      store.fetchNominationData(currentNomination, Number(awardId));
    }
  }, [currentNomination]);

  if (!currentNomination?.id) return null;

  return (
    <Styled.Wrapper>
      <Styled.Content>
        {isDesktop && <Styled.Header>{currentNomination?.name}</Styled.Header>}
        <Styled.Container>
          <Nominations
            collapsable={!isDesktop}
            current={toJS(currentNomination?.id)}
            nominations={toJS(nominations)}
            setCurrentNomination={toJS(setCurrentNomination)}
          />
          {!isDesktop && <Styled.Header>{currentNomination?.name}</Styled.Header>}
          <Container direction="column" gap="53px">
            {aloneWinner && <WinnerCard winner={aloneWinner} />}
            {winners && Boolean(winners?.length) && <Grid title="Победители" data={toJS(winners)} />}
            {!isLessLaptop && Boolean(nominees?.length) && <Grid title="Номинанты" data={toJS(nominees) ?? []} />}
          </Container>
        </Styled.Container>
      </Styled.Content>
      {isLessLaptop && Boolean(nominees?.length) && <Grid title="Номинанты" data={nominees ?? []} isScrollable />}
    </Styled.Wrapper>
  );
});

export const WinnersAndNominees: React.VFC = () => {
  return (
    <AssolWinnersAndParticipantsProvider>
      <WinnersAndNomineesContent />
    </AssolWinnersAndParticipantsProvider>
  );
};
