import React, { createContext, useContext } from 'react';
import CreatorsStore from './store';

const store = new CreatorsStore();

const CreatorsStateContext = createContext<CreatorsStore>(store);

export const CreatorsStateProvider: React.FC = ({ children }) => (
  <CreatorsStateContext.Provider value={store}>{children}</CreatorsStateContext.Provider>
);

export const useCreatorsStore = (): CreatorsStore => useContext(CreatorsStateContext);
