import React, { useMemo } from 'react';
import styled from 'styled-components';

import { useDeviceType } from '../../utils/hooks';

import Header from '../../components/base-ui/header';
import Main from '../../components/base-ui/main';
import { PrintLessWrap } from '../../components/base-ui/print-less';

const Div = styled.div`
  width: 100%;
  height: 100%;
  max-width: inherit;
  min-height: inherit;
`;

const NotFoundLayout: React.FC = ({ children }) => {
  const deviceType = useDeviceType();

  const isMobile = useMemo(() => deviceType === 'mobile', [deviceType]);

  return (
    <Div>
      {!isMobile && (
        <PrintLessWrap isHeader>
          <Header />
        </PrintLessWrap>
      )}

      <Main>{children}</Main>
    </Div>
  );
};

export default NotFoundLayout;
