import { makeObservable, observable, action } from 'mobx';

import { Nullable } from 'web';

import { api } from '../../utils/api';

import { PromoCode } from '../../services';

export default class PromocodeStore {
  fetching = false;

  error: Nullable<Error> = null;

  promocode: Nullable<PromoCode> = null;

  constructor() {
    makeObservable(this, {
      fetching: observable,
      error: observable,
      promocode: observable,
      fetchPromocode: action,
      setError: action,
      setFetching: action,
      setPromocode: action,
    });
  }

  fetchPromocode(options?: unknown): Promise<void> {
    this.setError(null);
    this.setFetching(true);

    return api.promocode
      .promocodeCreate(options)
      .then((res) => this.setPromocode(res.data))
      .catch((err: Error) => this.setError(err))
      .finally(() => this.setFetching(false));
  }

  setFetching(value: boolean): void {
    this.fetching = value;
  }

  setError(value: Nullable<Error>): void {
    this.error = value;
  }

  setPromocode(value: Nullable<PromoCode>): void {
    this.promocode = value;
  }
}
