import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ScrollToTopOnMount from 'components/base-ui/scroll-to-top';
import { DefaultLayout } from 'layouts';
import Breadcrumbs from 'components/base-ui/breadcrumbs';
import { useProjectsStore } from 'store';
import { Magazine } from 'services';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Cover } from './cover';
import { BreadcrumbsContainer, Button, ButtonWrapper, Container, Covers, CoverWrapper } from './styles';

const MagazinesPage: React.FC = () => {
  const store = useProjectsStore();
  const { trackPageView } = useMatomo();
  const [data, setData] = useState<Magazine[]>([]);
  const [currentData, setCurrentData] = useState<Magazine[]>([]);

  useEffect(() => {
    store.fetchMagazineData().then(() => {
      setData(store?.magazineData || []);
      document.title = 'Журнал Ассоль';
      trackPageView({});
    });
  }, []);

  useEffect(() => {
    setCurrentData(data?.slice(0, 4) || []);
  }, [data]);

  const onClick = useCallback(() => {
    if (data.length > 4) {
      setCurrentData(data);
    }
  }, [data]);

  const isButtonDActive = useMemo(() => {
    return data.length > 4 && data.length !== currentData.length;
  }, [data, currentData]);

  return (
    <>
      <ScrollToTopOnMount />
      <DefaultLayout>
        <BreadcrumbsContainer>
          <Breadcrumbs lineStyle={true} />
        </BreadcrumbsContainer>
        <Container>
          <Covers>
            {currentData?.map((cover) => (
              <CoverWrapper key={cover.id}>
                <Cover
                  id={cover?.id || 0}
                  cover={cover?.image || ''}
                  link={cover?.pdfFile || ''}
                  title={cover?.title || ''}
                />
              </CoverWrapper>
            ))}
          </Covers>
          <ButtonWrapper>
            <Button onClick={onClick} disabled={!isButtonDActive}>
              {' '}
              другие выпуски{' '}
            </Button>
          </ButtonWrapper>
        </Container>
      </DefaultLayout>
    </>
  );
};

export default MagazinesPage;
