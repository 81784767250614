import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import { COLORS } from 'utils/constants';
import { ReactComponent as AlertErrorIcon } from 'assets/icons/icon_alert_circle.svg';

interface InputFieldsetProps {
  id: string;
  error?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onInput?: (e: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  autocomplete?: string;
}

export const InputFieldset: React.FC<InputFieldsetProps> = (props) => {
  const { id, error, disabled, onChange, onInput, autocomplete } = props;

  return (
    <>
      <Wrapper>
        {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
        <>
          <InputElement
            disabled={disabled}
            id={id}
            type="text"
            onChange={onChange}
            onInput={onInput}
            autoComplete={autocomplete}
          />
        </>
      </Wrapper>
      {error && (
        <ErrorContainer>
          <AlertErrorIcon /> <ErrorText> {error} </ErrorText>
        </ErrorContainer>
      )}
    </>
  );
};

const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
  padding: 0 5px;
  svg {
    margin-right: 5px;
    height: auto;
    width: 24px;
    flex-shrink: 0;
    & > path {
      fill: ${COLORS.white};
    }
  }
`;

const ErrorText = styled.div`
  color: ${COLORS.white};
  font-size: 12px;
  .inputFieldset-icon-error {
    fill: ${COLORS.red};
  }
`;

const Wrapper = styled.div`
  position: relative;
`;

const InputElement = styled.input<Partial<InputFieldsetProps>>`
  position: relative;
  background-color: transparent;
  color: ${COLORS.white};
  font-size: 16px;
  border: 1px solid ${COLORS.gray5};
  border-radius: 2px;
  padding: 16.5px 14px;
  width: 100%;
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: ${COLORS.white};
    transition: background-color 5000s ease-in-out 0s;
    caret-color: ${COLORS.white};
  }
`;
