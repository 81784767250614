import { createGlobalStyle, css } from 'styled-components';
import { BASIC_FONT_FAMILY, COLORS } from '../utils/constants';

export const OverFlowScrollbar = css`
  ::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
  ::-webkit-scrollbar-track {
    background-color: ${COLORS.gray5};
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 1.5px;
    background-color: ${COLORS.primary};
  }
`;

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html {
    font-size: 16px;
    font-weight: 300;
    font-family: ${BASIC_FONT_FAMILY};
    color: ${COLORS.gray2}
  }

  body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;

      overflow-x: hidden;
  }

  html, body {
    min-height: 100%;
  }

  a {
    text-decoration: none;
  }

  a, button, input, textarea {
    transition: all 0.2s ease-out;

    &:hover,
    &:focus,
    &:active {
      outline: 0;
    }
  }

  p {
    margin: 0 0 1em 0;
  }

  #root {
    width: 100%;
    height: 100%;
    max-width: 100vw;
    min-height: 100vh;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  li {
    margin-bottom: 16px;
  }

`;

export default GlobalStyle;
