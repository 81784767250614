import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';

export const StTextCenter = styled.div`
  margin: 60px auto 100px auto;
  width: 288px;
  color: var(--medium, ${COLORS.gray3});
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  h3 {
    font-weight: 700;
  }

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin: 100px auto 110px auto;
    width: 540px;
    font-size: 24px;
    line-height: 32px;
    h3 {
      font-size: 28px;
      line-height: 32px;
      font-weight: 700;
    }
  }

  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    h3 {
      font-size: 44px;
      line-height: 48px;
      font-weight: 700;
    }
    width: 1020px;
    margin: 140px auto;
  }

  @media screen and (min-width: ${BREAKPOINTS.wideDesktop}) {
    margin: 140px auto;
  }
`;
