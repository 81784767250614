import React, { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { usePortal } from 'hooks/usePortal';
import { Styled } from './styles';
import { IconSvg } from '../../../../../../../assets/icons';

interface BaseModalProps {
  isVisible: boolean;
  onClose: () => void;
  centered?: boolean;
}

export const ModalWrapper: React.FC<BaseModalProps> = (props) => {
  const { isVisible, onClose, centered } = props;
  const [scrollBarWidth, setScrollBarWidth] = useState(0);

  const portalRef = usePortal('participants-modal');

  const handleModalClose = useCallback(() => {
    document.body.style.width = 'unset';
    document.body.style.overflowY = 'visible';
    onClose();
  }, []);

  useEffect(() => {
    if (!isVisible) return;
    const width = window.innerWidth - document.documentElement.clientWidth;
    document.body.style.overflowY = isVisible ? 'hidden' : 'visible';
    document.body.style.width = `calc(100% - ${width}px)`;
    setScrollBarWidth(width);
    return handleModalClose;
  }, [isVisible]);

  return isVisible
    ? portalRef &&
        ReactDOM.createPortal(
          <Styled.ModalOverflow padding={scrollBarWidth}>
            <Styled.ModalMask onClick={handleModalClose} />
            <Styled.ModalContainer centered={centered}>
              <Styled.ModalWindow>
                <Styled.ModalCloseWrapper onClick={handleModalClose}>
                  <IconSvg.CrossBase />
                </Styled.ModalCloseWrapper>
                {props.children}
              </Styled.ModalWindow>
            </Styled.ModalContainer>
          </Styled.ModalOverflow>,
          portalRef,
        )
    : null;
};
