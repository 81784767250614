import React from 'react';
import styled from 'styled-components';

import { COLORS } from '../../../utils/constants';

const InputDiv = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const InputLabel = styled.label`
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 4px;
  color: ${COLORS.gray};
`;

const InputChildren = styled.div`
  height: 100%;
  min-height: 40px;
  display: flex;
  align-items: flex-end;
`;

interface InputGroupProps {
  label?: string;
}

const InputGroup: React.FC<InputGroupProps> = ({ label, children }) => {
  return (
    <InputDiv>
      {label && <InputLabel>{label}</InputLabel>}
      <InputChildren>{children}</InputChildren>
    </InputDiv>
  );
};

export default InputGroup;
