import React, { createContext, useContext } from 'react';

import ProductListStore from './store';

const store = new ProductListStore();

const ProductStateContext = createContext<ProductListStore>(store);

export const ProductListStateProvider: React.FC = ({ children }) => (
  <ProductStateContext.Provider value={store}>{children}</ProductStateContext.Provider>
);

export const useProductListStore = (): ProductListStore => useContext(ProductStateContext);
