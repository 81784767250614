import React from 'react';

import { DefaultLayout } from '../../layouts';

import ScrollToTopOnMount from '../../components/base-ui/scroll-to-top';
import SearchComponent from '../../components/search';

const SearchPage: React.FC = () => {
  document.title = 'Поиск';

  return (
    <>
      <ScrollToTopOnMount />

      <DefaultLayout>
        <SearchComponent />
      </DefaultLayout>
    </>
  );
};

export default SearchPage;
