// @ts-nocheck
/* eslint-disable */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SmallBanner } from 'components/base-ui/small-banner';
import ScrollToTopOnMount from 'components/base-ui/scroll-to-top';
import { DefaultLayout } from 'layouts';
import Section from 'components/base-ui/section';
import { Header, TlBlock, TlBlockLabel, TlBlockSelect, TlHotelSelect } from 'pages/travel-line-form/styles';

import { api } from 'utils/api';
import Modal from './modal';

type TravelLineFormProps = {
  isMedicine?: boolean;
};

export const TravelLineForm: React.FC<TravelLineFormProps> = ({ isMedicine }) => {
  const ref = useRef<HTMLDivElement>(null);

  const [bannerData, setBannerData] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  const UrlParams = useMemo(() => URLSearchParams, [URLSearchParams]);

  useEffect(() => {
    api.smallBanner.smallBannerList().then(res => {
      setBannerData(res.data[0]);
      setModalData(res.data[0].content[0].value);
    });
  }, []);

  const onBannerClick = useCallback(() => {
    setModalVisible(prev => !prev);
  }, [modalVisible]);

  const onLoad = useCallback(() => {
    const URLSearchParams =
      UrlParams ||
      (function() {
        function URLSearchParams(query) {
          let index;
          let key;
          let value;
          let pairs;
          let i;
          let length;
          const dict = Object.create(null);
          this[secret] = dict;
          if (!query) return;
          if (typeof query === 'string') {
            if (query.startsWith('?')) {
              query = query.slice(1);
            }
            for (pairs = query.split('&'), i = 0, length = pairs.length; i < length; i++) {
              value = pairs[i];
              index = value.indexOf('=');
              if (index > -1) {
                appendTo(dict, decode(value.slice(0, index)), decode(value.slice(index + 1)));
              } else if (value.length) {
                appendTo(dict, decode(value), '');
              }
            }
          } else if (isArray(query)) {
            for (i = 0, length = query.length; i < length; i++) {
              value = query[i];
              appendTo(dict, value[0], value[1]);
            }
          } else if (query.forEach) {
            query.forEach(addEach, dict);
          } else {
            for (key in query) {
              appendTo(dict, key, query[key]);
            }
          }
        }

        const isArray = Array.isArray;
        const URLSearchParamsProto = URLSearchParams.prototype;
        const find = /[!'\(\)~]|%20|%00/g;
        const plus = /\+/g;
        const replace = { '!': '%21', "'": '%27', '(': '%28', ')': '%29', '~': '%7E', '%20': '+', '%00': '\0' };
        const replacer = function(match) {
          return replace[match];
        };
        const secret = `__URLSearchParams__:${Math.random()}`;

        function addEach(value, key) {
          appendTo(this, key, value);
        }

        function appendTo(dict, name, value) {
          const res = isArray(value) ? value.join(',') : value;
          if (name in dict) dict[name].push(res);
          else dict[name] = [res];
        }

        function decode(str) {
          return decodeURIComponent(str.replace(plus, ' '));
        }

        function encode(str) {
          return encodeURIComponent(str).replace(find, replacer);
        }

        URLSearchParamsProto.append = function append(name, value) {
          appendTo(this[secret], name, value);
        };
        URLSearchParamsProto.delete = function del(name) {
          delete this[secret][name];
        };
        URLSearchParamsProto.get = function get(name) {
          const dict = this[secret];
          return name in dict ? dict[name][0] : null;
        };
        URLSearchParamsProto.getAll = function getAll(name) {
          const dict = this[secret];
          return name in dict ? dict[name].slice(0) : [];
        };
        URLSearchParamsProto.has = function has(name) {
          return name in this[secret];
        };
        URLSearchParamsProto.set = function set(name, value) {
          this[secret][name] = [`${value}`];
        };
        URLSearchParamsProto.forEach = function forEach(callback, thisArg) {
          const dict = this[secret];
          Object.getOwnPropertyNames(dict).forEach(function(name) {
            dict[name].forEach(function(value) {
              callback.call(thisArg, value, name, this);
            }, this);
          }, this);
        };
        URLSearchParamsProto.toJSON = function toJSON() {
          return {};
        };
        URLSearchParamsProto.toString = function toString() {
          const dict = this[secret];
          const query = [];
          let i;
          let key;
          let name;
          let value;
          for (key in dict) {
            name = encode(key);
            for (i = 0, value = dict[key]; i < value.length; i++) {
              query.push(`${name}=${encode(value[i])}`);
            }
          }
          return query.join('&');
        };
        const dP = Object.defineProperty;
        const gOPD = Object.getOwnPropertyDescriptor;
        const createSearchParamsPollute = function(search) {
          function append(name, value) {
            URLSearchParamsProto.append.call(this, name, value);
            name = this.toString();
            search.set.call(this._usp, name ? `?${name}` : '');
          }

          function del(name) {
            URLSearchParamsProto.delete.call(this, name);
            name = this.toString();
            search.set.call(this._usp, name ? `?${name}` : '');
          }

          function set(name, value) {
            URLSearchParamsProto.set.call(this, name, value);
            name = this.toString();
            search.set.call(this._usp, name ? `?${name}` : '');
          }

          return function(sp, value) {
            sp.append = append;
            sp.delete = del;
            sp.set = set;
            return dP(sp, '_usp', { configurable: true, writable: true, value });
          };
        };
        const createSearchParamsCreate = function(polluteSearchParams) {
          return function(obj, sp) {
            dP(obj, '_searchParams', { configurable: true, writable: true, value: polluteSearchParams(sp, obj) });
            return sp;
          };
        };
        const updateSearchParams = function(sp) {
          const append = sp.append;
          sp.append = URLSearchParamsProto.append;
          URLSearchParams.call(sp, sp._usp.search.slice(1));
          sp.append = append;
        };
        const verifySearchParams = function(obj, Class) {
          if (!(obj instanceof Class)) {
            throw new TypeError(
              `${"'searchParams' accessed on an object that " + 'does not implement interface '}${Class.name}`,
            );
          }
        };
        const upgradeClass = function(Class) {
          const ClassProto = Class.prototype;
          const searchParams = gOPD(ClassProto, 'searchParams');
          const href = gOPD(ClassProto, 'href');
          const search = gOPD(ClassProto, 'search');
          let createSearchParams;
          if (!searchParams && search && search.set) {
            createSearchParams = createSearchParamsCreate(createSearchParamsPollute(search));
            Object.defineProperties(ClassProto, {
              href: {
                get() {
                  return href.get.call(this);
                },
                set(value) {
                  const sp = this._searchParams;
                  href.set.call(this, value);
                  if (sp) updateSearchParams(sp);
                },
              },
              search: {
                get() {
                  return search.get.call(this);
                },
                set(value) {
                  const sp = this._searchParams;
                  search.set.call(this, value);
                  if (sp) updateSearchParams(sp);
                },
              },
              searchParams: {
                get() {
                  verifySearchParams(this, Class);
                  return this._searchParams || createSearchParams(this, new URLSearchParams(this.search.slice(1)));
                },
                set(sp) {
                  verifySearchParams(this, Class);
                  createSearchParams(this, sp);
                },
              },
            });
          }
        };
        upgradeClass(HTMLAnchorElement);
        if (/^function|object$/.test(typeof URL) && URL.prototype) upgradeClass(URL);
        return URLSearchParams;
      })();
    (function(URLSearchParamsProto) {
      const iterable = (function() {
        try {
          return !!Symbol.iterator;
        } catch (error) {
          return false;
        }
      })();
      if (!('forEach' in URLSearchParamsProto)) {
        URLSearchParamsProto.forEach = function forEach(callback, thisArg) {
          const names = Object.create(null);
          this.toString()
            .replace(/=[\s\S]*?(?:&|$)/g, '=')
            .split('=')
            .forEach(function(name) {
              if (!name.length || name in names) return;
              (names[name] = this.getAll(name)).forEach(function(value) {
                callback.call(thisArg, value, name, this);
              }, this);
            }, this);
        };
      }
      if (!('keys' in URLSearchParamsProto)) {
        URLSearchParamsProto.keys = function keys() {
          const items = [];
          this.forEach((value, name) => {
            items.push(name);
          });
          const iterator = {
            next() {
              const value = items.shift();
              return { done: value === undefined, value };
            },
          };
          if (iterable) {
            iterator[Symbol.iterator] = function() {
              return iterator;
            };
          }
          return iterator;
        };
      }
      if (!('values' in URLSearchParamsProto)) {
        URLSearchParamsProto.values = function values() {
          const items = [];
          this.forEach(value => {
            items.push(value);
          });
          const iterator = {
            next() {
              const value = items.shift();
              return { done: value === undefined, value };
            },
          };
          if (iterable) {
            iterator[Symbol.iterator] = function() {
              return iterator;
            };
          }
          return iterator;
        };
      }
      if (!('entries' in URLSearchParamsProto)) {
        URLSearchParamsProto.entries = function entries() {
          const items = [];
          this.forEach((value, name) => {
            items.push([name, value]);
          });
          const iterator = {
            next() {
              const value = items.shift();
              return { done: value === undefined, value };
            },
          };
          if (iterable) {
            iterator[Symbol.iterator] = function() {
              return iterator;
            };
          }
          return iterator;
        };
      }
      if (iterable && !(Symbol.iterator in URLSearchParamsProto)) {
        URLSearchParamsProto[Symbol.iterator] = URLSearchParamsProto.entries;
      }
      if (!('sort' in URLSearchParamsProto)) {
        URLSearchParamsProto.sort = function sort() {
          const entries = this.entries();
          let entry = entries.next();
          let done = entry.done;
          const keys = [];
          const values = Object.create(null);
          let i;
          let key;
          let value;
          while (!done) {
            value = entry.value;
            key = value[0];
            keys.push(key);
            if (!(key in values)) {
              values[key] = [];
            }
            values[key].push(value[1]);
            entry = entries.next();
            done = entry.done;
          }
          keys.sort();
          for (i = 0; i < keys.length; i++) {
            this.delete(keys[i]);
          }
          for (i = 0; i < keys.length; i++) {
            key = keys[i];
            this.append(key, values[key].shift());
          }
        };
      }
    })(URLSearchParams.prototype);

    const SetUrlParam = function(key, value) {
      const search = new URLSearchParams(window.location.search);
      search.set(key, value);
      const path = `${window.location.pathname}?${search.toString()}`;
      window.history.pushState(false, false, path);
    };

    const select = document.getElementById('tl-hotel-select');

    select.addEventListener('change', function() {
      SetUrlParam('hotel_id', this.value);
    });
  }, []);

  const contextHotelName = React.useMemo(() => {
    return isMedicine ? 'TL-INT-white-nights-mmc_2022-11-23' : 'TL-INT-igora-assol';
  }, [isMedicine]);

  const updateForm = (w: Window) => {
    const q = [
      ['setContext', contextHotelName, 'ru'],
      [
        'embed',
        'booking-form',
        {
          container: 'tl-booking-form',
        },
      ],
    ];
    const h = ['ru-ibe.tlintegration.ru', 'ibe.tlintegration.ru', 'ibe.tlintegration.com'];
    const t = (w.travelline = w.travelline || {});
    const ti = (t.integration = t.integration || {});
    ti.__cq = ti.__cq ? ti.__cq.concat(q) : q;
    if (!ti.__loader) {
      ti.__loader = true;
      const d = w.document;
      const c = d.getElementsByTagName('head')[0] || d.getElementsByTagName('body')[0];

      function e(s, f) {
        return function() {
          w.TL || (c.removeChild(s), f());
        };
      }

      (function l(h) {
        if (h.length === 0) return;
        const s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = !0;
        s.src = `https://${h[0]}/integration/loader.js`;
        s.onerror = s.onload = e(s, () => {
          l(h.slice(1, h.length));
        });
        c.appendChild(s);
      })(h);
    }
  };

  useEffect(() => {
    onLoad();
    updateForm(window);
  }, [ref.current, onLoad]);

  return (
    <>
      {modalVisible && modalData && (
        <Modal
          onClick={onBannerClick}
          header={modalData?.header || ''}
          content={modalData?.richText || ''}
          showButton={modalData?.buttonsVisibility}
        />
      )}
      <ScrollToTopOnMount />
      {bannerData?.showsBlock && !isMedicine && (
        <SmallBanner
          header={bannerData?.header || ''}
          image={bannerData?.image || ''}
          description={bannerData?.description}
          buttonsVisibility={bannerData?.buttonsVisibility}
          onClick={onBannerClick}
        />
      )}

      <DefaultLayout>
        <Section noFirstOfTypeOffset>
          <TlBlock className="tl-block">
            {isMedicine ? (
              <Header>Бронирование номеров в санатории «Белые Ночи»</Header>
            ) : (
              <TlBlockLabel className="tl-block-select-label">Выберите отель:</TlBlockLabel>
            )}
            <TlBlockSelect id="tl-block-select" visible={!isMedicine}>
              <TlHotelSelect id="tl-hotel-select" />
            </TlBlockSelect>
            <div id="tl-booking-form">&nbsp;</div>
          </TlBlock>
        </Section>
      </DefaultLayout>
    </>
  );
};
