import React from 'react';
import { DefaultLayout } from 'layouts';
import ScrollToTopOnMount from 'components/base-ui/scroll-to-top';
import styled from 'styled-components';

import { ScarletSails } from 'components/scarlet-sails';
import Breadcrumbs from '../../components/base-ui/breadcrumbs';

const Container = styled.div`
  margin-top: 53px;
`;

const BreadCrumbsWrapper = styled.div`
  position: absolute;
  top: 69px;
  left: 0;
  z-index: 999;
`;

function ScarletSailsPage() {
  return (
    <DefaultLayout>
      <ScrollToTopOnMount />
      <Container>
        <BreadCrumbsWrapper>
          <Breadcrumbs lineStyle={true} />
        </BreadCrumbsWrapper>
        <ScarletSails />
      </Container>
    </DefaultLayout>
  );
}

export default ScarletSailsPage;
