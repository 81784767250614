import styled from 'styled-components';
import { BREAKPOINTS } from 'utils/constants';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 16px;

  margin: 0 auto;
  gap: 56px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 0;
    width: 608px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: 1000px;
  }

  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    width: 1328px;
  }
  @media screen and (min-width: ${BREAKPOINTS.wideDesktop}) {
    width: 1504px;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  margin: 56px auto 56px auto;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin: 56px auto 76px auto;
  }
`;

export const BreadcrumbsWrapper = styled.section`
  width: 100%;
  top: 56px;
  left: 0;
  z-index: 100;
  position: absolute;
  margin-top: 12px;
`;
