import React from 'react';
import styled from 'styled-components';

import { ImageWithDescription } from 'web';
import { BREAKPOINTS } from '../../../utils/constants';
import { HtmlRenderer } from '../../../utils/htmlParser';

const Container = styled.div`
  display: grid;
  row-gap: 32px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    row-gap: 36px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    row-gap: unset;
    column-gap: 48px;
    grid-template-columns: 1fr 481px;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 191px;
  overflow: hidden;
  position: relative;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    height: 335px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    height: 504px;
  }
`;

const Image = styled.img`
  top: 50%;
  left: 50%;
  width: 100%;
  position: absolute;
  transform: translate(-50%, -50%);

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: auto;
    height: 100%;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

type ImageWithDescriptionBlockProps = Pick<ImageWithDescription, 'image' | 'text'>;

export const ImageWithDescriptionBlock: React.FC<ImageWithDescriptionBlockProps> = ({ image, text }) => {
  return (
    <Container>
      <ImageWrapper>
        <Image src={image} />
      </ImageWrapper>

      <TextWrapper>
        <HtmlRenderer html={text} />
      </TextWrapper>
    </Container>
  );
};
