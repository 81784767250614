import React, { createContext, useContext } from 'react';

import MedicineCenterStore from './store';

const store = new MedicineCenterStore();

const MedicineCenterStateContext = createContext<MedicineCenterStore>(store);

export const MedicineCenterProvider: React.FC = ({ children }) => (
  <MedicineCenterStateContext.Provider value={store}>{children}</MedicineCenterStateContext.Provider>
);

export const useMedicineCenterStore = (): MedicineCenterStore => useContext(MedicineCenterStateContext);
