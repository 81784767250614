import React from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { MagazineArticle } from 'services';
import { useSearchStore } from 'store';
import Table from 'components/base-ui/table';
import ArticleCards from 'components/articles/list/cards';
import { SearchSection } from '../index';

export const ArticleSearchTable: React.FC = observer(() => {
  const store = useSearchStore();
  const { searchValue } = useParams<{ searchValue: string }>();

  return store.fieldData('magazineArticle').length ? (
    <SearchSection compact heading="Статьи журналов" noFirstOfTypeOffset>
      <Table<MagazineArticle, never>
        count={store.fieldCount('magazineArticle')}
        currentPage={store.fieldPage('magazineArticle')}
        cards={<ArticleCards items={store.fieldData('magazineArticle') as MagazineArticle[]} />}
        onPagination={(page) => store.onPaginate(searchValue, 'magazineArticle', 'magazine_article', page)}
      />
    </SearchSection>
  ) : null;
});
