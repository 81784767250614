import React, { useCallback } from 'react';
import { AwardType } from 'store/projects/types';
import Icon from 'components/base-ui/icon';
import { useHistory, useLocation } from 'react-router-dom';
import { BigHeader, Button, ButtonIcon, ButtonText, ButtonWrapper, Container, Item, SmallHeader, Text } from './styles';

interface AwardItemProps {
  data: AwardType;
}

export const AwardItem: React.FC<AwardItemProps> = ({ data }) => {
  const history = useHistory();
  const location = useLocation();

  const onItemClick = useCallback(() => {
    history.push(`${location.pathname}/${data.id}`);
  }, []);

  return (
    <Container>
      <Item bg={data?.image || ''} onClick={onItemClick}>
        <SmallHeader>{data.header}</SmallHeader>
        <BigHeader>{data.year}</BigHeader>
        <Text>{data.description}</Text>
        <ButtonWrapper>
          <Button>
            <ButtonText>победители премии</ButtonText>
            <ButtonIcon>
              <Icon name="iconArrowWhite" />
            </ButtonIcon>
          </Button>
        </ButtonWrapper>
      </Item>
    </Container>
  );
};
