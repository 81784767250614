import React from 'react';
import styled, { css } from 'styled-components';

import { MediaProduct } from '../../../../../services';

import { BREAKPOINTS, COLORS } from '../../../../../utils/constants';

import { spanStyles } from '../../../../../styles/text';

import { H4 } from '../../../../base-ui/typo';
import HtmlContainer from '../../../../base-ui/html-container';
import { HtmlRenderer } from '../../../../../utils/htmlParser';

const Div = styled.div`
  margin-left: 16px;
  padding: 24px 0 16px 0;
  border-bottom: 1px solid ${COLORS.gray5};

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin-left: 32px;
    padding: 24px 0;
  }
`;

const Head = styled.div`
  margin-bottom: 8px;
  padding-right: 42px;

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    max-width: 632px;
    padding-right: 0;
  }
`;

const Title = styled(H4)`
  font-weight: 400;
`;

const Body = styled.div`
  /* margin-bottom: 40px; */

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    max-width: 632px;
  }
`;

const lineClamp = css`
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    -webkit-line-clamp: 4;
  }
`;

const Description = styled(HtmlContainer)`
  ${spanStyles}
  color: #606060;

  > p {
    ${lineClamp}
  }
`;

interface BriefProps {
  data: Pick<MediaProduct, 'name' | 'description'>;
}

const Brief: React.FC<BriefProps> = ({ data }) => {
  return (
    <Div>
      <Head>
        <Title>{data.name}</Title>
      </Head>

      <Body>
        <Description>
          <HtmlRenderer html={data.description || ''} />
        </Description>
      </Body>
    </Div>
  );
};

export default Brief;
