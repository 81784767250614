import React, { createContext, useContext } from 'react';

import NewsStore from './store';

const store = new NewsStore();

const NewsStateContext = createContext<NewsStore>(store);

export const NewsStateProvider: React.FC = ({ children }) => (
  <NewsStateContext.Provider value={store}>{children}</NewsStateContext.Provider>
);

export const useNewsStore = (): NewsStore => useContext(NewsStateContext);
