import React, { createContext, useContext } from 'react';

import BankingProductsStore from './store';

const store = new BankingProductsStore();

const BankingProductsStateContext = createContext<BankingProductsStore>(store);

export const BankingProductsStateProvider: React.FC = ({ children }) => (
  <BankingProductsStateContext.Provider value={store}>{children}</BankingProductsStateContext.Provider>
);

export const useBankingProductsStore = (): BankingProductsStore => useContext(BankingProductsStateContext);
