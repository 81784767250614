import React from 'react';
import styled, { css } from 'styled-components';

import { BREAKPOINTS, COLORS } from '../../../utils/constants';

import HtmlContainer from '../../base-ui/html-container';
import { HtmlRenderer } from '../../../utils/htmlParser';

const Container = styled.div`
  position: relative;
  margin-bottom: 16px;
`;

const AboutBannerImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50% 50% 50% 50% / 0% 0% 100% 100%;
  object-fit: cover;
`;

interface AboutBannerCircleProps {
  size: 'small' | 'medium' | 'large';
}

const smallStyles = css`
  width: 5.625vw;
  height: 5.625vw;
  left: 29vw;
  top: 53vw;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    top: 49vw;
    left: 23vw;
  }
  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    top: 38vw;
    left: 18vw;
  }
`;

const mediumStyles = css`
  width: 8.75vw;
  height: 8.75vw;
  left: 19vw;
  top: 41.5vw;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    top: 36vw;
    left: 16vw;
  }
  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    top: 27vw;
    left: 11vw;
  }
`;

const largeStyles = css`
  width: 17.5vw;
  height: 17.5vw;
  right: 18vw;
  top: 40vw;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    top: 34.5vw;
    right: 16vw;
  }
  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    top: 25vw;
  }
`;

const AboutBannerCircle = styled.div<AboutBannerCircleProps>`
  position: absolute;
  border-radius: 50%;
  mix-blend-mode: multiply;
  background-color: ${COLORS.secondary};

  ${(props) => {
    switch (props.size) {
      case 'small':
        return smallStyles;
      case 'medium':
        return mediumStyles;
      default:
        return largeStyles;
    }
  }}
`;

const AboutBannerTextContainer = styled(HtmlContainer)`
  margin: 32px auto 0 auto;
  padding: 0 16px;
  color: #7a7a7a;
  text-align: center;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin-top: 58px;
    padding: 0 56px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding: 0 248px;
    margin-top: 97px;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  padding-top: 56%;
  margin-top: 25px;
  margin-left: -30px;
  width: calc(100% + 60px);

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding-top: 51.5%;
    margin-left: -33px;
    width: calc(100% + 66px);
  }

  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    width: 100%;
    margin-left: -5px;
    width: calc(100% + 10px);
    padding-top: 40%;
    max-height: 560px;
  }
`;

interface AboutBannerProps {
  image: string;
  text: string;
}

const AboutBanner: React.FC<AboutBannerProps> = ({ text, image }) => {
  return (
    <Container>
      <ImageContainer>
        <AboutBannerImage src={image} />

        <AboutBannerCircle size="small" />
        <AboutBannerCircle size="medium" />
        <AboutBannerCircle size="large" />
      </ImageContainer>

      <AboutBannerTextContainer>
        <HtmlRenderer html={text} />
      </AboutBannerTextContainer>
    </Container>
  );
};

export default AboutBanner;
