import styled from 'styled-components';
import { COLORS } from 'utils/constants';

export const Item = styled.div`
  width: 288px;
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
`;
export const ImageWrapper = styled.div`
  width: 288px;
  height: 288px;
`;
export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
export const Header = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: ${COLORS.secondary};
  margin-top: 8px;
`;
export const SubHeader = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: ${COLORS.gray3};
  margin-bottom: 8px;
`;
export const Text = styled.div`
  font-size: 14px;
  line-height: 24px;
  color: ${COLORS.gray3};
`;
