import React from 'react';
import { HtmlRenderer } from 'utils/htmlParser';
import { useDeviceType } from 'utils/hooks';
import { MobileImage, Text, Wrapper } from './styled';

type Props = {
  image: string;
  text: string;
};

export const FullImage: React.FC<Props> = ({ image, text }) => {
  const device = useDeviceType();

  return (
    <Wrapper image={image}>
      {device === 'mobile' && <MobileImage src={image} />}
      <Text>
        <HtmlRenderer html={text} />
      </Text>
    </Wrapper>
  );
};
