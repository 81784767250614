import React, { useCallback, useEffect, useMemo } from 'react';
import Slider from 'components/base-ui/slider';
import { Container } from 'components/base-ui/container';
import { Body, Div, Header, StyledInput, Title } from 'components/base-ui/table/filters/menu/range/styles';
import useResizeObserver from 'use-resize-observer';

interface RangeProps {
  range: [number, number];
  value: [number, number];
  onChange: (value: [number, number]) => void;
  placeholder?: [string, string];
  slider?: boolean;
  title?: string;
  index?: number;
  updateFiltersHeights?: (index: number, height: number) => void;
}

const PADDING_HEIGHT = 32;

const Range: React.FC<RangeProps> = React.memo(
  ({
    range: [min, max],
    value: [from, to],
    onChange,
    placeholder = ['с', 'до'],
    slider = true,
    title = 'Цена, р.',
    updateFiltersHeights,
    index,
  }) => {
    const defaultValue = useMemo((): [number, number] => [min, max], [min, max]);
    const { ref, height } = useResizeObserver<HTMLDivElement>();

    useEffect(() => {
      if (height && index && updateFiltersHeights) {
        updateFiltersHeights(index, height + PADDING_HEIGHT);
      }
    }, [height]);

    const onChangeFromHandler = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        onChange([parseInt(event.target.value), to]);
      },
      [to],
    );

    const onChangeToHandler = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        onChange([from, parseInt(event.target.value)]);
      },
      [from],
    );

    const onChangeSliderHandler = useCallback(
      (value?: number | readonly number[]) => {
        onChange(Array.isArray(value) ? (value as [number, number]) : defaultValue);
      },
      [defaultValue],
    );

    return (
      <Div ref={ref}>
        <Title>{title}</Title>
        <Container direction="column" gap="16px">
          <Header>
            <StyledInput
              small
              id="from"
              type="number"
              placeholder={placeholder[0]}
              min={min}
              max={to}
              value={from}
              onChange={onChangeFromHandler}
            />
            <StyledInput
              small
              id="to"
              type="number"
              placeholder={placeholder[1]}
              min={from}
              max={max}
              value={to}
              onChange={onChangeToHandler}
            />
          </Header>

          <Body display={slider ? '' : 'none'}>
            <Slider value={[from, to]} min={min} max={max} onChange={onChangeSliderHandler} />
          </Body>
        </Container>
      </Div>
    );
  },
);

export default Range;
