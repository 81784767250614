import React from 'react';
import { HtmlRenderer } from 'utils/htmlParser';
import { StColumn, StColumnHeader, StColumns, StContainer, StHeader } from './styled';

interface Column {
  header: string;
  text: string;
}

interface Props {
  header: string;
  columns: Column[];
}

export const AboutColumns: React.FC<Props> = (props) => {
  return (
    <StContainer>
      <StHeader>
        <HtmlRenderer html={props.header} />
      </StHeader>
      <StColumns>
        {props.columns.map((column) => (
          <StColumn key={column.text}>
            <StColumnHeader>{column.header}</StColumnHeader>
            <HtmlRenderer html={column.text} />
          </StColumn>
        ))}
      </StColumns>
    </StContainer>
  );
};
