import styled from 'styled-components';
import { BREAKPOINTS } from 'utils/constants';
import { Span } from 'components/base-ui/typo';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    flex-direction: row;
    align-items: center;
  }
`;

export const UserInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const UserInfo = styled(Span)`
  margin-bottom: 14px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin-bottom: unset;
  }
`;
