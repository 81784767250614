import styled from 'styled-components';
import { AccordionItem } from '@szhsin/react-accordion';
import { BREAKPOINTS, COLORS } from 'utils/constants';

export const StMenu = styled.div<{ visible: boolean }>`
  position: absolute;
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.visible ? '1' : '0')};
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 100;
  overflow-y: auto;
  height: calc(100vh - 56px);
  background-color: ${COLORS.white};
  padding: 40px 0 44px 0;
  transition: all 250ms;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 0;
    max-width: 320px;
    height: unset;
    max-height: calc(100vh - 56px);
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding: 116px 56px 74px 56px;
  }
`;

export const StAccordionItem = styled(AccordionItem)<{ nested: boolean }>`
  border-bottom: 1px solid ${COLORS.gray6};
  .szh-accordion__item {
    &-btn {
      cursor: pointer;
      display: flex;
      align-items: center;
      width: 100%;
      height: 56px;
      margin: 0;
      padding: 0 16px;
      font-size: 14px;
      font-weight: ${(props) => (props.nested ? '400' : '700')};
      line-height: 24px;
      text-align: left;
      color: ${(props) => (props.nested ? COLORS.secondary : COLORS.primary)};
      background-color: ${(props) => (props.nested ? COLORS.gray7 : 'transparent')};
      border: none;
      -webkit-tap-highlight-color: transparent;
      :active {
        background-color: ${(props) => (props.nested ? COLORS.gray6 : COLORS.white)};
      }
    }
    &-content {
      transition: height 0.25s cubic-bezier(0, 0, 0, 1);
    }
  }

  .chevron-down {
    margin-left: auto;
    transition: transform 0.25s cubic-bezier(0, 0, 0, 1);
  }

  &.szh-accordion__item--expanded {
    .szh-accordion__item-btn {
      //панель с вложенными при раскрытие
    }
    .chevron-down {
      transform: rotate(180deg);
    }
  }
`;
