import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, ButtonWrapper, Container, Header, Link } from './styles';

interface ModalProps {
  id: number;
  link: string;
  title: string;
}

export const Modal: React.FC<ModalProps> = ({ id, link, title }) => {
  const history = useHistory();
  const location = useLocation();
  const handleViewClick = useCallback(() => {
    history.push(`${location.pathname}/${id}`);
  }, [id]);

  return (
    <div>
      <Container>
        <Header>{title}</Header>
        <ButtonWrapper>
          <Button onClick={handleViewClick}>Посмотреть</Button>
          <Button>
            <Link href={link} download target="_blank">
              Скачать
            </Link>
          </Button>
        </ButtonWrapper>
      </Container>
    </div>
  );
};
