import React, { useMemo } from 'react';

import { useDeviceType } from '../../../utils/hooks';

import Section from '../../base-ui/section';
import Steps from '../../base-ui/steps';

import { PropsWithSectionOptions } from '../blocks';

type StepsRendererProps = PropsWithSectionOptions<{
  value: string[];
}>;

const StepsRenderer: React.FC<StepsRendererProps> = ({ value, options }) => {
  const deviceType = useDeviceType();

  const isTablet = useMemo(() => deviceType === 'tablet', [deviceType]);

  return (
    <Section compact noLastOfTypeMargin={options?.noLastOfTypeMargin} paddinglessRightBody={isTablet}>
      <Steps items={value} scrollable paddingRight={isTablet} />
    </Section>
  );
};

export default StepsRenderer;
