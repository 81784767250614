import React from 'react';
import { StMenuWrapper, StIcon } from './styled';

interface Props {
  isOpen: boolean;
  onClick: () => void;
}

export const MenuIcon: React.FC<Props> = (props) => {
  return (
    <StMenuWrapper isOpen={props.isOpen} onClick={props.onClick}>
      {props.isOpen ? <StIcon name="iconCrossBase" /> : <StIcon name="iconMenu" />}
    </StMenuWrapper>
  );
};
