import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { CarouselButtonWrapperProps } from 'web';

import { useShopsStore } from '../../../store';

import { useCarousel } from '../../../utils/hooks';
import { BREAKPOINTS, COLORS } from '../../../utils/constants';

import IconButton from '../icon-button';
import Item from './item';

const Container = styled.div`
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin-left: -56px;
    width: calc(100% + 112px);
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    margin: 0;
    width: 100%;
    padding: 0 48px;
    position: relative;
    display: grid;
    grid-template-columns: auto 1fr auto;
  }
`;

const Grid = styled.div<{ listLength: number }>`
  display: grid;
  grid-template-columns: repeat(10, 80vw);
  overflow-x: auto;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    grid-template-columns: repeat(10, 30vw);
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    overflow: hidden;
    grid-auto-flow: column;
    grid-template-columns: repeat(${(props) => props.listLength}, calc(100% / 5));
  }
`;

const ButtonWrapper = styled.div<CarouselButtonWrapperProps>`
  display: none;

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    display: flex;
    align-items: center;
    padding-bottom: 34px;
    z-index: 15;
    width: 48px;
    height: 100%;
    background-color: ${COLORS.white};
  }
`;

interface AddressesProps {
  categoryId?: number;
}

const Addresses: React.FC<AddressesProps> = observer((props) => {
  const store = useShopsStore();

  const { categoryId } = useParams<{ categoryId: string }>();

  useEffect(() => autorun(() => store.fetchShops()), [store]);

  const filteredItems = useMemo(() => {
    const currentCategoryId = props.categoryId || parseInt(categoryId);

    if (currentCategoryId) {
      // @ts-expect-error skip TODO fix type in swagger
      return store.shops.filter((s) => s.category === currentCategoryId);
    }

    return store.shops;
  }, [store.shops, categoryId, props.categoryId]);
  const { list, current, decrement, increment } = useCarousel(filteredItems);

  return (
    <Container>
      <ButtonWrapper direction="left">
        <IconButton name="iconArrowLeft" round shadow gradient onClick={decrement} />
      </ButtonWrapper>
      <Grid listLength={list.length}>
        {list.map((el, idx) => (
          <Item key={el.id} idx={(current + idx) % list.length} data={el} />
        ))}
      </Grid>
      <ButtonWrapper direction="right">
        <IconButton name="iconArrowRight" round shadow gradient onClick={increment} />
      </ButtonWrapper>
    </Container>
  );
});

export default Addresses;
