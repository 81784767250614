import React from 'react';
import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';

const StContainer = styled.div`
  max-height: 568px;
  position: relative;
  color: ${COLORS.white};
  overflow: hidden;
  margin-top: 56px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    max-height: unset;
  }
`;

const StVideo = styled.video`
  height: auto;
  width: 100%;
  min-height: 100%;
`;

interface AnimationProps {
  file: string;
}

export const Animation: React.FC<AnimationProps> = (props) => {
  return (
    <StContainer>
      <StVideo playsInline muted autoPlay controls={false} loop>
        <source src={props.file} type="video/mp4" />
      </StVideo>
    </StContainer>
  );
};
