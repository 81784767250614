import React, { useCallback, useMemo } from 'react';
import { PortalDirectionSubItem } from 'services';
import defaultIcon from 'assets/icons/icon_menu_item_default.svg';
import { useHistory } from 'react-router-dom';
import { StSubItem, StSubMenu } from './styled';

interface Props {
  subItems?: PortalDirectionSubItem[];
}

export const SubMenu: React.FC<Props> = (props) => {
  const history = useHistory();

  const manyItems = useMemo(() => {
    return Boolean(props.subItems && props.subItems.length > 4);
  }, [props.subItems]);

  const onItemClick = useCallback(
    (link: string) => {
      history.push(link);
    },
    [history],
  );

  return (
    <StSubMenu manyItems={manyItems}>
      {props.subItems?.map((subItem) => (
        <StSubItem onClick={() => onItemClick(subItem.link)}>
          <img src={subItem.icon ?? defaultIcon} alt="icon" />
          {subItem.title}
        </StSubItem>
      ))}
    </StSubMenu>
  );
};
