import React from 'react';
import { useCarousel } from 'utils/hooks';
import { GalleryItem } from '../item';
import { Container } from './styles';

interface GalleryCarouselProps {
  data: string[];
  type: 'photo' | 'video';
  onClick: (data: string) => void;
}

export const GalleryCarousel: React.FC<GalleryCarouselProps> = ({ data, onClick, type }) => {
  const { list } = useCarousel(data);

  return (
    <Container>
      {list.map((item) => (
        <GalleryItem data={item} onClick={onClick} type={type} />
      ))}
    </Container>
  );
};
