import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';
import { ArrowButton, OutlineButton } from 'components/base-ui/button';
import Select, { SelectProps } from 'components/base-ui/select';

interface MySelect extends SelectProps {
  borderError: boolean;
}

export const Div = styled.div`
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding: 0 32px;
  }
`;
export const Grid = styled.div`
  display: grid;
  margin-bottom: 24px;

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    margin-bottom: 48px;
  }

  @media screen and (min-width: ${BREAKPOINTS.tablet}) and (max-width: calc(${BREAKPOINTS.laptop} - 1px)) {
    column-gap: 16px;
    grid-template-columns: 1fr 320px;
  }
`;
export const InputsWrapper = styled.div`
  display: grid;
  row-gap: 12px;
  padding: 0 16px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 0;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    column-gap: 32px;
    grid-template-columns: repeat(3, 288px);
  }
`;
export const AboutWrapper = styled.div`
  margin-top: 24px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin-top: 20px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    margin-top: 0;
  }
`;
export const ButtonDiv = styled.div`
  display: grid;
  row-gap: 8px;
  padding: 0 16px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    column-gap: 16px;
    padding: 0 0 80px 0;
    grid-auto-flow: column;
    grid-template-columns: 1fr 320px;
    border-bottom: 1px solid ${COLORS.gray5};
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding: 40px 0;
    border-top: 1px solid ${COLORS.gray5};
    grid-template-columns: repeat(auto-fill, 240px);
  }
`;
export const StyledOutlineButton = styled(OutlineButton)`
  color: ${COLORS.primary};
  border-color: ${COLORS.primary};
`;
export const StyledArrowButton = styled(ArrowButton)`
  display: block;
  margin: 0 0 0 -1px;
  width: calc(100% + 2px);
`;
export const Form = styled.form``;
export const FormField = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
export const ErrorWrapper = styled.div`
  font-size: 12px;
  color: ${COLORS.red};
`;
export const SelectShop = styled(Select)<MySelect>`
  border: ${(props) => (props.borderError ? `1px solid ${COLORS.red}` : '')};
  border-radius: 2px;
`;
