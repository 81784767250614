import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { BREAKPOINTS, COLORS } from '../../utils/constants';

import { H1, H2 } from '../base-ui/typo';
import { OutlineButton } from '../base-ui/button';

import notFoundImage from '../../assets/img/banners/404.png';

const Div = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
`;

const DivBG = styled.div`
  top: 0;
  left: 0;
  position: absolute;
  width: inherit;
  height: inherit;
  mix-blend-mode: multiply;
  background: ${COLORS.linearGradient};
`;

const DivImage = styled.div`
  top: 0;
  left: 0;
  position: absolute;
  mix-blend-mode: multiply;
  width: inherit;
  height: inherit;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${notFoundImage});
`;

const DivContent = styled.div`
  width: inherit;
  height: inherit;
  isolation: isolate;
  color: ${COLORS.white};
  padding: 136px 0 0 16px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 196px 0 0 56px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding: 196px 0 0 136px;
  }
`;

const DivContentTitle = styled(H1)`
  font-size: 144px;
  line-height: 144px;
  margin-bottom: 8px;
`;

const DivContentText = styled(H2)`
  margin: 0 0 16px 8px;
  margin-bottom: 16px;

  font-size: 28px;
  line-height: 32px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-size: 44px;
    line-height: 48px;
    margin: 0 0 32px 0;
  }
`;

const DivContentButton = styled(OutlineButton)`
  width: 240px;
  margin-left: 8px;
  color: ${COLORS.gray5};
  border-color: ${COLORS.gray5};

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin-left: 0;
  }
`;

const NotFoundComponent: React.FC = () => {
  const history = useHistory();

  const onClick = useCallback(() => history.push('/'), [history]);

  return (
    <Div>
      <DivBG />
      <DivImage />

      <DivContent>
        <DivContentTitle>404</DivContentTitle>
        <DivContentText>Страница не найдена</DivContentText>

        <DivContentButton onClick={onClick}>На главную</DivContentButton>
      </DivContent>
    </Div>
  );
};

export default NotFoundComponent;
