import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';
import { ArrowButton } from 'components/base-ui/swiper-arrow-buttons';

const SwiperWrapper = styled.div<{ withDescriptionOutside: boolean; type: 'modal' | 'winnerCard' }>`
  position: relative;
  width: 100%;
  border-radius: ${(props) => (props.type === 'modal' ? '8px' : '0')};

  overflow: hidden;

  .swiper-pagination {
    ${(props) => (props.withDescriptionOutside ? 'top: 174px' : ' bottom: 8px')};
    @media screen and (min-width: ${BREAKPOINTS.tablet}) {
      bottom: 16px;
    }

    .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: ${COLORS.white};
      margin: 0 8px;
      opacity: 1;
      @media screen and (min-width: ${BREAKPOINTS.tablet}) {
        width: 16px;
        height: 16px;
      }
    }

    .swiper-pagination-bullet-active {
      background-color: ${COLORS.secondary};
    }
  }

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: ${(props) => (props.type === 'modal' ? '480px' : ' 608px;')};
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: 466px;
  }
`;

const SwiperButton = styled(ArrowButton)`
  z-index: 1;
  opacity: 0;
  cursor: auto;
  pointer-events: none;
  ${(props) => `${props.vector}: 16px`};
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    opacity: 1;
    cursor: pointer;
    pointer-events: auto;
  }
`;

export const Styled = { SwiperButton, SwiperWrapper };
