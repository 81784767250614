import styled, { css } from 'styled-components';
import { BREAKPOINTS, COLORS } from '../../utils/constants';

const shared = css`
  min-height: inherit;
  background-size: cover;
`;

export const Wrapper = styled.div`
  ${shared};
  position: relative;
  display: flex;
  justify-content: center;
  background-color: ${COLORS.primary};

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    background-color: ${COLORS.linearGradient};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
`;
