import { action, makeObservable, observable } from 'mobx';
import { api } from 'utils/api';
import AbstractStore from 'store/AbstractStore';
import { Application, ApplicationForm, ApplicationFormIntegrationEnum, DynamicApplication } from 'services';
import { AxiosError } from 'axios';

export default class DynamicApplicationStore extends AbstractStore {
  formData: ApplicationForm | undefined | null;

  integrationType: ApplicationFormIntegrationEnum | null = null;

  application: Partial<Application> | null = null;

  constructor() {
    super();
    makeObservable(this, {
      ...this.annotations,
      formData: observable,
      integrationType: observable,
      application: observable,
      setFormData: action,
      fetchDynamicForm: action,
      createDynamicApplication: action,
      resetStore: action,
    });
  }

  fetchDynamicForm(formId: number) {
    this.setError(null);
    this.setFetching(true);
    api.applicationForm
      .applicationFormsRead({ id: formId })
      .then((res) => {
        this.setFormData(res.data as unknown as ApplicationForm);
        this.setIntegrationType(res.data.integration as unknown as ApplicationFormIntegrationEnum);
      })
      .catch((error: AxiosError) => this.setError(error))
      .finally(() => this.setFetching(false));
  }

  createDynamicApplication(data: DynamicApplication, successAction?: () => void, userInfo?: Partial<Application>) {
    this.setFetching(true);
    api.dynamicApplications
      .dynamicApplicationsCreate({
        data: {
          ...data,
          ...userInfo,
          form: Number(this.formData?.id),
        },
      })
      .catch((err: AxiosError) => this.setError(err))
      .finally(() => {
        if (!this.error && successAction) successAction();
        this.setFetching(false);
      });
  }

  setFetching(value: boolean) {
    this.fetching = value;
  }

  setFormData(value: ApplicationForm) {
    this.formData = value;
  }

  setIntegrationType(value: ApplicationFormIntegrationEnum) {
    this.integrationType = value;
  }

  resetStore() {
    this.formData = null;
    this.integrationType = null;
  }
}
