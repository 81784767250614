import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { Container } from 'components/base-ui/container';
import { ParticipantItems } from 'services';
import { HtmlRenderer } from 'utils/htmlParser';
import { useDeviceState } from 'hooks/useDeviceProvider';
import { Styled } from './styles';
import { Participant, TextSize } from '../../shared';

export const Info: React.FC<{
  title: string;
  team?: string | null;
  description?: string | null;
  participants: ParticipantItems[];
}> = (props) => {
  const deviceState = useDeviceState();
  const [withScroll, setWithScroll] = useState(false);

  const heightRef = useRef() as MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    if (heightRef.current) {
      const hasScroll = heightRef.current.scrollHeight > heightRef.current.clientHeight;
      setWithScroll(hasScroll);
    }
  }, [heightRef, deviceState]);

  return (
    <Styled.ScrollContainer>
      <Styled.Container ref={heightRef} withScroll={withScroll}>
        <Styled.Title>{props.title}</Styled.Title>
        <Container direction="column" gap="8px">
          <Styled.Subtitle>{props.team}</Styled.Subtitle>
          {props.description && (
            <Styled.Text>
              <HtmlRenderer html={props.description} />
            </Styled.Text>
          )}
        </Container>
        <Styled.Subtitle>Состав команды:</Styled.Subtitle>
        <Styled.Participants>
          {props.participants.map((participant) => (
            <Participant
              size={TextSize.Small}
              key={participant.id}
              name={participant.name}
              company={participant.company?.name}
            />
          ))}
        </Styled.Participants>
      </Styled.Container>
    </Styled.ScrollContainer>
  );
};

export const AloneInfo: React.FC<{
  title: string;
  name?: string | null;
  company?: string | null;
  description?: string | null;
}> = (props) => {
  const deviceState = useDeviceState();
  const [withScroll, setWithScroll] = useState(false);

  const heightRef = useRef() as MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    if (heightRef.current) {
      const hasScroll = heightRef.current.scrollHeight > heightRef.current.clientHeight;
      setWithScroll(hasScroll);
    }
  }, [heightRef, deviceState]);

  return (
    <Styled.ScrollContainer>
      <Styled.Container ref={heightRef} withScroll={withScroll}>
        <Styled.Title>{props.title}</Styled.Title>
        <Container direction="column" gap="8px">
          <Container direction="column" gap="2px">
            <Styled.Subtitle>{props.name}</Styled.Subtitle>
            <Styled.Subtitle>{props.company}</Styled.Subtitle>
          </Container>
          {props.description && (
            <Styled.Text>
              <HtmlRenderer html={props.description} />
            </Styled.Text>
          )}
        </Container>
      </Styled.Container>
    </Styled.ScrollContainer>
  );
};
