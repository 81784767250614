import React, { useCallback, useEffect, useMemo, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { Container } from 'components/base-ui/container';
import { ExtendedMedicineDirection } from 'components/medicine/types';
import { useBreakpointsDimensions, useDeviceType } from 'utils/hooks';
import { Styled } from './styles';
import { TagItem } from './TagItem';
import { getMinHeight } from './lib';

export function Tags(props: { data: ExtendedMedicineDirection[]; toggleDirections: (id: number) => void }) {
  const deviceType = useDeviceType();
  const { width } = useBreakpointsDimensions();
  const [fullHeight, setFullHeight] = useState<number>(0);
  const minHeight = useMemo(() => getMinHeight(deviceType), [deviceType, fullHeight]);
  const [height, setHeight] = useState<string | number | undefined>(minHeight);
  const collapsable = useMemo(() => minHeight < fullHeight, [width, fullHeight, minHeight]);

  const handleShowLess = useCallback(() => {
    setHeight(minHeight);
  }, [minHeight]);

  const handleShowMore = useCallback(() => {
    setHeight('auto');
  }, []);

  useEffect(() => {
    if (height !== 'auto') {
      setHeight(minHeight > fullHeight && fullHeight !== 0 ? 'auto' : minHeight);
    }
  }, [minHeight, fullHeight]);

  const measuredRef = useCallback(
    (node: HTMLElement | null) => {
      if (node !== null) {
        setFullHeight(Math.round(node.getBoundingClientRect().height));
      }
    },
    [width],
  );

  return (
    <Styled.Container>
      <Styled.Title>Направление</Styled.Title>
      <AnimateHeight height={height}>
        <Container ref={measuredRef} gap="24px" wrap="wrap" width="100%">
          {props.data.map((direction) => (
            <TagItem
              key={direction.id}
              text={direction.name}
              active={direction.active}
              onClick={() => props.toggleDirections(direction.id)}
            />
          ))}
        </Container>
      </AnimateHeight>
      {collapsable && (
        <Styled.Button onClick={height === 'auto' ? handleShowLess : handleShowMore}>
          <Styled.ButtonText>{height === 'auto' ? 'Показать Меньше' : 'Показать больше'}</Styled.ButtonText>
          <Styled.IconDown className={height === 'auto' ? 'expanded' : 'collapsed'} />
        </Styled.Button>
      )}
    </Styled.Container>
  );
}
