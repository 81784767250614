import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useGuideCategoryStore } from 'store';
import styled from 'styled-components';
import { Block } from 'utils/blocks';
import { BlocksRender } from 'components/renderers';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { BREAKPOINTS } from 'utils/constants';
import Breadcrumbs from 'components/base-ui/breadcrumbs';

const Div = styled.div`
  height: 100%;
  position: relative;
  padding-top: 56px;
`;

const Section = styled.section`
  width: 100%;
`;

const BreadcrumbsSection = styled(Section)`
  @media screen and (min-width: ${BREAKPOINTS.tablet}) and (max-width: calc(${BREAKPOINTS.laptop} - 1px)) {
    padding: 0 56px;
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    margin: 0 136px;
  }
`;

export const Guide = observer(() => {
  const store = useGuideCategoryStore();
  const { trackPageView } = useMatomo();

  useEffect(() => {
    store.fetchCategory();
    document.title = 'Гид';
    trackPageView({});
  }, []);
  const handleCloseErrorScreen = useCallback(() => store.closeErrorScreen(), [store]);

  return (
    <Div>
      <BreadcrumbsSection>
        <Breadcrumbs />
      </BreadcrumbsSection>

      {store.category?.page && (
        <BlocksRender
          blocks={store.category.page.content as unknown as Block[]}
          loading={store.fetching}
          options={{
            categoryType: 'guide',
            cardType: store.category.cardType,
            productType: 'services',
            section: { compact: true },
          }}
          errorScreen={store.category?.errorScreen ?? undefined}
          closeErrorScreenCallBack={handleCloseErrorScreen}
        />
      )}
    </Div>
  );
});
