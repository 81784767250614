import styled from 'styled-components';
import { Container, Grid } from 'components/base-ui/cards';
import { BREAKPOINTS } from 'utils/constants';

export const StyledContainer = styled(Container)`
  margin-bottom: 144px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin-bottom: 168px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    margin-bottom: 114px;
  }
`;
export const StyledGrid = styled(Grid)`
  row-gap: 60px;
  column-gap: 150px;
  padding: 0 32px 16px 32px;
`;
