import styled from 'styled-components';
import { BASIC_FONT_FAMILY, BREAKPOINTS, COLORS } from 'utils/constants';
import Link from 'components/base-ui/link';
import { ArrowButton as StyledArrowButton } from 'components/base-ui/button';
import { Informer } from 'components/base-ui/typo';

export const StyledFooter = styled.footer`
  display: block;
  max-width: inherit;
  position: relative;
  color: ${COLORS.white};
  background: ${COLORS.linearGradient};
  padding: 16px 16px 24px 16px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    display: grid;
    padding: 41px 56px 32px 56px;
    grid-template-rows: repeat(2, auto);
    grid-template-columns: 1fr auto;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    display: grid;
    padding: 40px 136px;
    grid-template-rows: auto;
    grid-template-columns: auto auto 1fr;
  }
`;

export const BrandLink = styled.div`
  grid-row: 2;
  grid-column: 1;
  margin-bottom: 63px;

  & img {
    height: 30px;
  }

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin-bottom: 0;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    grid-row: 1;
    margin-right: 86px;
  }
`;
export const TechSupportWrapper = styled.div`
  max-width: 208px;
  max-height: 122px;
  font-family: ${BASIC_FONT_FAMILY};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${COLORS.white};
  margin-bottom: 32px; //tmp
`;
export const CopyrightWrapper = styled.div`
  grid-row: 3;
  grid-column: 1;
  max-width: 210px;
  color: ${COLORS.white};

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    grid-row: 2;
    grid-column: 2;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    grid-row: 1;
    grid-column: 3;
    margin-top: 32px;
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    display: none;
  }
`;
export const Nav = styled.nav`
  grid-row: 1;
  grid-column: 1/-1;
  padding-bottom: 27px;
  position: relative;
  column-gap: 16px;

  &:before {
    content: '';
    position: absolute;
    top: -16px;
    height: 1px;
    width: 100%;
    background-color: ${COLORS.primary};
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 16px;
    height: 1px;
    width: 100%;
    background-color: ${COLORS.primary};
  }

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    display: grid;
    padding-bottom: 36px;
    grid-template-columns: repeat(3, minmax(auto, 95px)) minmax(auto, 125px);
    grid-column-gap: 46px;
    &:before {
      opacity: 0;
    }
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    grid-column: 2;
    margin: 0;
    padding: 0 64px;
    grid-column-gap: 68px;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 1px;
      height: 100%;
      opacity: 0.1;
      background-color: ${COLORS.white};
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 1px;
      height: 100%;
      opacity: 0.1;
      background-color: ${COLORS.white};
    }
  }
`;

export const FeedbackWrapper = styled.div`
  grid-row: 3;
  grid-column: 1;
  max-width: 210px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    grid-row: 1;
    grid-column: 2;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    grid-row: 1;
    grid-column: 3;
    margin-left: 64px;
  }
`;
export const NavColumn = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;
export const NavItem = styled.a`
  display: block;
  color: inherit;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 8px;
  max-width: 100px;
  text-decoration: none;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    max-width: 100%;
  }
`;
export const ArrowButton = styled(StyledArrowButton)`
  width: 208px;
`;
export const TechSupportHeader = styled.div`
  font-weight: bold;
  margin-top: 16px;
  padding-bottom: 10px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin-top: 40px;
    margin-bottom: 16px;
  }
`;
export const TechSupportInfo = styled.div``;
export const Copyright = styled(Informer)``;
export const SupportEmail = styled.a`
  color: ${COLORS.white};
  :hover {
    text-decoration: underline;
  }
`;

export const FooterTabletWrapper = styled.div`
  display: none;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) and (max-width: ${BREAKPOINTS.laptop}) {
    display: block;
  }
`;

export const FooterContentWrapper = styled.div`
  @media screen and (max-width: ${BREAKPOINTS.laptop}) {
    display: none;
  }
`;

export const StSocialIconsWrapper = styled.div`
  margin-top: 30px;
  display: flex;
  gap: 8px;
  cursor: pointer;

  @media screen and (max-width: ${BREAKPOINTS.laptop}) {
    display: flex;
    flex-wrap: wrap;
    margin-top: 5px;
    top: 73%;
    right: 160px;
    grid-row: 2;
    grid-column: 2;

    @media screen and (max-width: ${BREAKPOINTS.mobileLandscape}) {
      margin-top: 15px;
    }
  }
`;

export const StSocialIcon = styled.div<{ backgroundLink: string; hoverLink: string }>`
  width: 32px;
  height: 32px;
  background: url(${(props) => props.backgroundLink});
  background-size: cover;

  ${StSocialIconsWrapper}:hover & {
    background: url(${(props) => props.hoverLink});
    background-size: cover;
  }
`;

export const StSocialTitle = styled.div`
  display: flex;
  color: ${COLORS.white};
  align-items: center;
  justify-content: center;
`;
