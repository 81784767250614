import React from 'react';
import styled from 'styled-components';

import { BankingProduct, InsuranceProduct, MediaProduct, ProductList } from 'services';

import { isNull, isUndefined } from 'lodash';
import { COLORS } from './constants';

const Tag = styled.span`
  font-size: 14px;
  line-height: 20px;
  word-break: break-word;
  color: ${COLORS.gray3};

  &:not(:last-of-type) {
    margin-right: 0.5ch;
  }
`;

export const useProperties = (product: ProductList | BankingProduct | InsuranceProduct | MediaProduct) => {
  if (!('properties' in product)) return null;
  return product.properties
    .slice()
    .sort((a, b) => {
      return isNull(a.attribute.sortOrder) ||
        isNull(b.attribute.sortOrder) ||
        isUndefined(a.attribute.sortOrder) ||
        isUndefined(b.attribute.sortOrder)
        ? 1
        : a.attribute.sortOrder - b.attribute.sortOrder;
    })
    .map((property, idx, a) => {
      return <Tag key={property.id}>{property.name + (idx === a.length - 1 ? '' : ',')} </Tag>;
    });
};
