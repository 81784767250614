import React, { useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { NewsPage } from 'services';

import { useNewsStore } from 'store';

import { useQueryParams, usePagination, useWindowDimensions, useDeviceType } from 'utils/hooks';

import Table from 'components/base-ui/table';
import Cards from 'components/news/list/cards';
import { calculateCardsLimit } from 'utils/functions';
import { CARD_GAPS, CARD_WIDTH, PADDING } from './constants';
import { TagsContainer } from './styles';
import { Tags } from '../../base-ui/tags';
import { LikeModal } from '../../base-ui/like-modal';
import { LikeModalContent } from '../../base-ui/like-modal-content';

const Section = styled.section`
  width: 100%;
`;

const NewsPagesRenderer: React.FC = observer(() => {
  const location = useLocation();
  const windowDimension = useWindowDimensions();
  const device = useDeviceType();
  const [likeModalNewsId, setLikeModalNewsId] = React.useState<number | null>(null);
  const [showLikeModal, setShowLikeModal] = React.useState(false);

  const store = useNewsStore();

  const sectionsIds = useMemo(
    () => store.sections?.filter((section) => section.active).map((section) => section.id),
    [store.sections],
  );

  const { page: initialPage } = useQueryParams();

  const ref = useRef<HTMLDivElement>(null);

  const pageLimit = useMemo(() => {
    return calculateCardsLimit(windowDimension.width, CARD_WIDTH, CARD_GAPS, PADDING);
  }, [windowDimension.width]);

  const likesLimit = React.useMemo(() => {
    if (device === 'mobile') return 70;
    return 40;
  }, [device]);

  const { page, onPaginationWithRouter } = usePagination({
    ref,
    initialPage,
    path: location.pathname,
    count: store.count,
    error: store.error,
    limit: pageLimit,
    withRouter: true,
  });

  useEffect(
    () => autorun(() => store.fetchNews({ page, limit: pageLimit, sectionsIdIn: sectionsIds })),
    [store, page, pageLimit, sectionsIds],
  );

  useEffect(
    () =>
      autorun(() => {
        store.fetchSections();
      }),
    [],
  );

  const handleShowModal = React.useCallback((newsId: number) => {
    setLikeModalNewsId(newsId);
    setShowLikeModal(true);
  }, []);

  return (
    <Section ref={ref}>
      {store.sections && (
        <TagsContainer>
          <Tags data={store.sections} toggleDirections={store.toggleSections} />
        </TagsContainer>
      )}
      <Table<NewsPage, never>
        count={store.count}
        limit={pageLimit}
        currentPage={page}
        cards={<Cards items={store.news} handleShowModal={handleShowModal} />}
        onPagination={onPaginationWithRouter}
      />
      <LikeModal onClose={() => setShowLikeModal(false)} visible={showLikeModal}>
        <LikeModalContent cardType="news" id={likeModalNewsId} limitPerPage={likesLimit} />
      </LikeModal>
    </Section>
  );
});

export default NewsPagesRenderer;
