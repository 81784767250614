import React from 'react';

import { PropsWithSectionOptions } from '../blocks';

import Section from '../../base-ui/section';
import HtmlContainer from '../../base-ui/html-container';
import { HtmlRenderer } from '../../../utils/htmlParser';

type TextRendererProps = PropsWithSectionOptions<{
  value: string;
}>;

const TextRenderer: React.FC<TextRendererProps> = ({ value, options }) => {
  return (
    <Section noFirstOfTypeOffset {...options}>
      <HtmlContainer gray>
        <HtmlRenderer html={value} />
      </HtmlContainer>
    </Section>
  );
};

export default TextRenderer;
