import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';
import bg from 'assets/img/creators/listing_bg.png';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  background-color: ${COLORS.gray6};
  padding: 50px 16px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 50px 32px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding: 40px 125px 50px 125px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    .last {
      :after {
        content: '';
        height: 210px;
      }
    }

    background-image: url(${bg});
    background-repeat: no-repeat;
    background-position: right 15px bottom 0;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Header = styled.div`
  display: flex;
  gap: 12px;
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  color: #12519a;
  border-bottom: 4px solid #009cdd;
  padding-bottom: 24px;
`;

export const PointsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    display: grid;
    grid-template-columns: repeat(2, 50%);
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
`;

export const Point = styled.div`
  display: flex;
  gap: 8px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #12519a;
`;
