import styled from 'styled-components';
import { BASIC_FONT_FAMILY, BREAKPOINTS, COLORS } from 'utils/constants';
import { ArrowButton } from 'components/base-ui/button';

export const Container = styled.div`
  position: relative;
  font-family: ${BASIC_FONT_FAMILY};
  font-weight: 400;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;
  overflow-x: hidden;
`;
export const Div = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;
export const BannerImage = styled.img`
  width: auto;
  height: 100%;
`;
export const BannerImageWrapper = styled.div`
  max-width: 1328px;
  height: 320px;
  position: relative;
  z-index: 1;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    height: 632px;
  }
`;
export const BannerContent = styled.div`
  width: 288px;
  text-align: center;
  background-color: ${COLORS.white};
  z-index: 9;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 698px;
    height: 264px;
    margin-top: -184px;
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: 762px;
    height: 264px;
  }
  @media print {
    width: 100%;
  }
`;
export const BigHeader = styled.div`
  margin: 16px 16px 24px 16px;
  font-size: 28px;
  font-weight: 700;
  line-height: 32px;
  color: ${COLORS.gray2};
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin: 31px 19px 40px 45px;
    font-size: 44px;
    line-height: 48px;
  }
`;
export const Annotation = styled.div`
  width: 288px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin: 24px 16px;
  color: ${COLORS.gray3};
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 698px;
    margin: 18px 0 40px 0;
  }
  @media print {
    width: 100%;
  }
`;
export const SmallHeader = styled.div`
  font-weight: 700;
  color: ${COLORS.gray3};
`;
export const Content = styled.div`
  width: 288px;
  font-size: 18px;
  line-height: 28px;
  color: ${COLORS.gray2};
  h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    color: ${COLORS.secondary};
    margin-bottom: 8px;
  }
  img {
    width: 288px;
    object-fit: cover;
    @media screen and (min-width: ${BREAKPOINTS.tablet}) {
      width: 698px;
    }
  }
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 698px;
  }

  @media print {
    width: 100%;
  }
`;
export const QuoteWrapper = styled.div`
  width: 320px;
  position: relative;
  background-color: ${COLORS.gray7};
  padding: 32px 24px 32px 30px;
  margin: 40px 0;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 698px;
    padding: 48px 49px 48px 63px;
  }
  @media print {
    width: 100%;
  }
`;
export const QuoteContent = styled.div`
  position: relative;
  color: ${COLORS.gray3};
  font-size: 22px;
  line-height: 38px;
  z-index: 9;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 586px;
    font-size: 24px;
    line-height: 32px;
  }
  @media print {
    width: 100%;
  }
`;
export const WaterMark = styled.div`
  font-weight: 900;
  position: absolute;
  top: 0;
  left: 16px;
  font-size: 200px;
  line-height: 242px;
  color: ${COLORS.cloud};
  z-index: 1;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    left: 24px;
  }
  @media print {
    display: none;
  }
`;
export const Footer = styled.div`
  position: relative;
  display: flex;
  width: 288px;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
  flex-wrap: revert;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    flex-direction: row;
    width: 698px;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 56px;
  }
  @media print {
    display: none;
  }
`;
export const DoubleImage = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 288px;
  margin: 24px 0;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 698px;
    margin: 40px 0;
  }
`;
export const Image = styled.img`
  width: 288px;
  height: 288px;
  object-fit: cover;
  margin: 16px 0;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 329px;
    height: 356px;
  }
`;
export const Button = styled(ArrowButton)`
  width: 160px;
  height: 48px;
  transform: rotate(180deg);
  cursor: pointer;
  z-index: 9;
`;
export const ButtonText = styled.div`
  transform: rotate(180deg);
  text-transform: uppercase;
  font-size: 14px;
  line-height: 24px;
`;
export const PrintIcon = styled.div<{ isNoLasElement: boolean }>`
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-right: ${(props) => (props.isNoLasElement ? '26px' : '0')};
  position: relative;
  display: inline-block;
  span {
    visibility: hidden;
    width: 120px;
    background-color: ${COLORS.gray3};
    color: ${COLORS.white};
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 200%;
    left: -30%;
    margin-left: -60px;
    :after {
      content: '';
      position: absolute;
      top: 100%;
      left: 60%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: ${COLORS.gray3} transparent transparent transparent;
    }
  }
  :hover {
    span {
      visibility: visible;
    }
  }
`;
export const Count = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${COLORS.gray4};
  margin-right: 26px;
`;
export const ViewIcon = styled.div`
  width: 22px;
  height: 16px;
  margin-right: 10px;
`;

export const PdfIcon = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
  position: relative;
  display: inline-block;
  span {
    visibility: hidden;
    width: 120px;
    background-color: ${COLORS.gray3};
    color: ${COLORS.white};
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 200%;
    left: -30%;
    margin-left: -60px;
    :after {
      content: '';
      position: absolute;
      top: 100%;
      left: 60%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: ${COLORS.gray3} transparent transparent transparent;
    }
  }
  :hover {
    span {
      visibility: visible;
    }
  }
`;

export const FavoriteIcon = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
  position: relative;
  display: inline-block;
  margin-right: 26px;
  span {
    visibility: hidden;
    width: 120px;
    background-color: ${COLORS.gray3};
    color: ${COLORS.white};
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 200%;
    left: -30%;
    margin-left: -60px;
    :after {
      content: '';
      position: absolute;
      top: 100%;
      left: 70%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: ${COLORS.gray3} transparent transparent transparent;
    }
  }
  :hover {
    span {
      visibility: visible;
    }
  }
`;

export const IconsWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 24px;
  width: 288px;
  margin-bottom: 28px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    justify-content: unset;
    width: unset;
    margin-bottom: unset;
  }
`;

export const LikeWrapper = styled.div`
  display: flex;
  margin-right: 10px;
`;
