import { IconSvg } from 'assets/icons';
import React from 'react';
import { VideoItemType } from 'store/projects/types';
import { Image, ImageContainer, Item, PlayIcon, Title } from '../styles';

export const VideoGalleryItem: React.VFC<{ video: VideoItemType; onClick: (data: VideoItemType) => void }> = (
  props,
) => {
  return (
    <Item key={props.video?.id} onClick={() => props.onClick(props.video)}>
      <ImageContainer>
        <Image src={props.video?.preview} />
        <PlayIcon>
          <IconSvg.Play />
        </PlayIcon>
      </ImageContainer>
      {props.video?.title && <Title>{props.video?.title}</Title>}
    </Item>
  );
};
