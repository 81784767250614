import React from 'react';
import { Container } from 'components/base-ui/container';
import { MedicineProgram } from 'services';
import { Grid } from '../../ui';

export function SanatoriumGrid(props: {
  withoutAccommodation: MedicineProgram[];
  withAccommodation: MedicineProgram[];
}) {
  return (
    <Container gap="64px" direction="column" width="100%" align="center">
      {props.withAccommodation.length > 0 && <Grid data={props.withAccommodation} title="Программы с проживанием" />}
      {props.withoutAccommodation.length > 0 && (
        <Grid data={props.withoutAccommodation} title="Программы без проживания" rows={1} />
      )}
    </Container>
  );
}
