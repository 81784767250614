import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { VideoGalleryType, VideoItemType } from 'store/projects/types';
import { useDeviceType } from 'utils/hooks';
import { Nullable } from 'web';
import { VideoBox } from 'components/base-ui/video-box';

import { Button, Container, GalleryWrapper } from './styles';
import { VideoGalleryList } from './video-list';
import { VideoGalleryItem } from './video-list/VideoItem';

interface VideoGalleryProps {
  data: VideoGalleryType;
}

export const VideoGallery: React.FC<VideoGalleryProps> = ({ data }) => {
  const limit = 8;
  const device = useDeviceType();

  const [currentData, setCurrentData] = useState<VideoItemType[]>(data.videos.slice(0, limit));

  const [videoData, setVideoData] = useState<Nullable<VideoItemType>>(null);
  const [showAllButton, setShowAllButton] = useState(true);

  useEffect(() => setShowAllButton(data.videos.length > limit), []);

  const isMobile = useMemo(() => device === 'mobile', [device]);

  const openVideoBox = useCallback(
    (file: VideoItemType) => {
      setVideoData({ ...file, resolutions: file.resolutions.filter((resolution) => resolution.url.length > 0) });
    },
    [data.videos],
  );

  const closeVideoBox = useCallback(() => {
    setVideoData(null);
  }, []);

  const onMoreButtonClick = useCallback(() => {
    setShowAllButton(false);
    setCurrentData(data.videos);
  }, []);

  const dataVideo = useMemo(() => currentData.filter((item) => item !== null), [currentData]);

  return (
    <Container>
      <GalleryWrapper>
        {isMobile ? (
          <VideoGalleryList data={dataVideo} onClick={openVideoBox} />
        ) : (
          dataVideo.map((item) => <VideoGalleryItem key={item?.id} video={item} onClick={openVideoBox} />)
        )}
      </GalleryWrapper>
      {showAllButton && <Button onClick={onMoreButtonClick}>Показать все</Button>}
      <VideoBox
        isLightBoxOpen={!!videoData}
        videos={data.videos}
        video={videoData ?? undefined}
        onClose={closeVideoBox}
      />
    </Container>
  );
};
