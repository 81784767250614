import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { useCartStore } from 'store';

import ProductItem from './item';
import {
  Div,
  Grid,
  Header,
  Heading,
  Body,
  ProductList,
  Footer,
  Total,
  TotalText,
  TotalPrice,
  Actions,
  ActionsButton,
} from './styles';

const About: React.FC = observer(() => {
  const store = useCartStore();
  useEffect(() => store.fetchWineInstruction(), []);
  return (
    <Div>
      <Grid>
        <Header>
          <Heading>Ваш заказ</Heading>
        </Header>

        <Body>
          <ProductList>
            {store.cartProducts.map((c) => (
              <ProductItem cartItem={c} key={c.product?.id} />
            ))}
          </ProductList>
        </Body>

        <Footer>
          <Total>
            <TotalText>Итого:</TotalText>
            <TotalPrice>{store.totalCartPrice} руб.</TotalPrice>
          </Total>

          <Actions>
            <ActionsButton onClick={store.clearCart}>Очистить корзину</ActionsButton>
          </Actions>
        </Footer>
      </Grid>
    </Div>
  );
});

export default About;
