import styled, { css } from 'styled-components';
import { HEADER_HEIGHT } from '../../../utils/constants';

interface PrintLessWrapProps {
  isHeader?: boolean;
}

const headerStyles = css`
  top: 0;
  left: 0;
  height: ${HEADER_HEIGHT};
  z-index: 10000;
  position: fixed;
`;

export const PrintLessWrap = styled.div<PrintLessWrapProps>`
  z-index: 8;
  position: relative;
  ${(props) => props.isHeader && headerStyles};

  @media print {
    display: none;
  }
`;
