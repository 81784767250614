import React from 'react';
import styled from 'styled-components';

import Section from 'components/base-ui/section';

import Alert from 'components/profile/tabs/alert';
import FavoriteProducts from './products';
import FavoriteGuides from './blogs';
import FavoriteNews from './news';
import FavoriteArticles from './articles';

const Div = styled.div``;

const FavoriteTab: React.FC = () => {
  return (
    <Div>
      <Section paddingless marginless noMinHeight noFirstOfTypeOffset>
        <Alert />
      </Section>

      <Section heading="Продукты" paddingless marginless borderless>
        <FavoriteProducts />
      </Section>

      <Section heading="Гид" paddingless marginless borderless>
        <FavoriteGuides />
      </Section>

      <Section heading="Новости" paddingless marginless borderless>
        <FavoriteNews />
      </Section>

      <Section heading='Журнал "Ассоль"' paddingless marginless borderless>
        <FavoriteArticles />
      </Section>
    </Div>
  );
};

export default FavoriteTab;
