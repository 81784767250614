import React, { createContext, useContext } from 'react';

import GuidePagesStore from './store';

const store = new GuidePagesStore();

const GuidePagesStateContext = createContext<GuidePagesStore>(store);

export const GuidePagesStateProvider: React.FC = ({ children }) => (
  <GuidePagesStateContext.Provider value={store}>{children}</GuidePagesStateContext.Provider>
);

export const useGuidePagesStore = (): GuidePagesStore => useContext(GuidePagesStateContext);
