import { format } from 'date-fns';

export const DEFAULT_DATE_FORMAT = 'dd.MM.yyyy';
export const SHORT_DATE_FORMAT = 'dd.MM.yy';

export const formatDate = (date?: string, dateFormat?: string) => {
  return date ? format(new Date(date), dateFormat ?? DEFAULT_DATE_FORMAT) : '';
};

export const formatTime = (date?: string) => {
  return date ? format(new Date(date), 'hh:mm') : '';
};

export const formatInputDate = (date?: string) => {
  return date ? format(new Date(date), 'yyyy-MM-dd') : '';
};
