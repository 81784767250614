import React, { createContext, useContext } from 'react';

import ProductStore from './store';

const store = new ProductStore();

const ProductStateContext = createContext<ProductStore>(store);

export const ProductStateProvider: React.FC = ({ children }) => (
  <ProductStateContext.Provider value={store}>{children}</ProductStateContext.Provider>
);

export const useProductStore = (): ProductStore => useContext(ProductStateContext);
