import React from 'react';

import { DefaultLayout } from '../../layouts';

import BasePageComponent from '../../components/base-page';
import ScrollToTopOnMount from '../../components/base-ui/scroll-to-top';
import { BasePageStateProvider } from '../../store/base-page';

export const BasePage: React.FC = () => {
  return (
    <>
      <ScrollToTopOnMount />

      <DefaultLayout>
        <BasePageStateProvider>
          <BasePageComponent />
        </BasePageStateProvider>
      </DefaultLayout>
    </>
  );
};
