import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';

export const Container = styled.div<{ image: string; visible: boolean }>`
  display: ${(props) => (props.visible ? 'unset' : 'none')};
  width: 280px;
  height: 280px;
  border-radius: 8px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 38.04%, rgba(0, 0, 0, 0.531) 48.37%, rgba(0, 0, 0, 0.9) 69.02%),
    url(${(props) => props.image});
  background-size: cover;
  margin: 0;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 340px;
    height: 340px;
    margin-right: 40px;
    :last-child {
      margin-right: 0;
    }
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: 368px;
    height: 368px;
    margin-right: 0;
  }
`;
export const Content = styled.div`
  position: relative;
  top: 105px;
  height: 175px;
  width: 248px;
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  color: ${COLORS.white};
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    top: 165px;
    width: 308px;
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    top: 193px;
    width: 320px;
  }
`;
export const HeaderWrapper = styled.div`
  width: 248px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 308px;
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: 320px;
  }
`;
export const Header = styled.h2`
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  margin: 0 0 16px 0;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
export const Description = styled.div`
  width: 248px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 0 0 16px 0;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-size: 16px;
    line-height: 24px;
    width: 308px;
    padding-right: 20px;
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: 320px;
  }
`;
export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const FooterIconsWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;
export const FooterItem = styled.div`
  width: 24px;
  height: 24px;
`;

export const Count = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 24px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: white;
  margin: 0 10px;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
`;

export const LikesWrapper = styled(FooterItem)`
  cursor: pointer;
`;
export const Button = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
`;
export const ButtonText = styled.div`
  margin-right: 10px;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${COLORS.white};
  user-select: none;
`;
