import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import styled, { css } from 'styled-components';

import { usePageContentStore, useProfileStore } from '../../../store';

import { api } from '../../../utils/api';
import { useDeviceType } from '../../../utils/hooks';
import { BREAKPOINTS, COLORS } from '../../../utils/constants';

import { microStyles } from '../../../styles/text';

import { H1, H5, Micro } from '../../base-ui/typo';
import Checkbox from '../../base-ui/checkbox';
import { ArrowButton } from '../../base-ui/button';
import HtmlContainer from '../../base-ui/html-container';

import iphoneImg from '../../../assets/img/iphone.png';
import { HtmlRenderer } from '../../../utils/htmlParser';

enum ModalContentAreas {
  welcomeMessage = 'welcomeMessage',
  consent = 'consent',
  linkAgreement = 'linkAgreement',
  backSideHeader = 'backSideHeader',
  toBackSide = 'toBackSide',
  toFrontSide = 'toFrontSide',
}

enum ModalElementsSlugs {
  welcomeMessage = 'welcome-message',
  consent = 'consent-to-processing',
  linkAgreement = 'link-to-agreement',
  backSideHeader = 'back-side-header',
  toBackSide = 'to-back-side',
  toFrontSide = 'to-front-side',
}

const pageSlug = 'welcome-window';

const modalRow = css`
  display: grid;
  row-gap: 16px;
  min-height: 0;
`;

const ModalBriefInfo = styled.div`
  ${modalRow};

  grid-row: 1;
  grid-template-rows: auto 1fr;
`;

const ModalHeader = styled(H1)`
  /* grid-row: 1; */
`;

const ModalPreview = styled(Micro)`
  /* grid-row: 2; */
  display: block;
  max-width: 200px;
`;

const ModalDescription = styled.div`
  ${modalRow};

  grid-column: 1/-1;
  grid-template-rows: auto 1fr;
`;

const ModalDescriptionHeading = styled(H5)`
  font-weight: 400;
`;

const ModalDescriptionTextWrapper = styled.div`
  overflow: auto;
`;

const ModalDescriptionTextContainer = styled(HtmlContainer)`
  ${microStyles}
`;

const ModalActions = styled.div`
  ${modalRow};

  grid-row: 2;
  /* grid-template-rows: repeat*; */
`;

const ModalCheckboxWrapper = styled.div``;

const ModalTermsOfUseLink = styled.div`
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
  color: ${COLORS.secondary};
`;

const ModalButtonWrapper = styled.div``;

const ModalButton = styled(ArrowButton)`
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 240px;
  }
`;

const ModalImageWrapper = styled.div`
  grid-row: 1/3;
  grid-column: 2;
  position: relative;
`;

const ModalImage = styled.img`
  top: -40px;
  left: -49px;
  width: 143%;
  position: absolute;
  z-index: 2000;
`;

const SRC = iphoneImg;

interface InfoContentItemType {
  id: string;
  type: string;
  value: string;
}

const ModalContent: React.FC = observer(() => {
  const deviceType = useDeviceType();
  const profileStore = useProfileStore();
  const history = useHistory();
  const pageContentStore = usePageContentStore<ModalElementsSlugs, ModalContentAreas>();

  const [publicOffer, setPublicOffer] = useState<boolean>(false);

  const [publicOfferText, setPublicOfferText] = useState<string>('');
  useEffect(() => {
    api.userAgreement.userAgreementList().then((res) => setPublicOfferText(res.data.content || ''));
  }, []);

  const isMobile = useMemo(() => deviceType === 'mobile', [deviceType]);

  const handlePublicOffer = (value: boolean) => {
    profileStore.handlePublicOffer(value);
  };

  useEffect(
    () =>
      pageContentStore.fetchContent(
        { slug: pageSlug },
        {
          [ModalElementsSlugs.consent]: ModalContentAreas.consent,
          [ModalElementsSlugs.linkAgreement]: ModalContentAreas.linkAgreement,
          [ModalElementsSlugs.welcomeMessage]: ModalContentAreas.welcomeMessage,
          [ModalElementsSlugs.backSideHeader]: ModalContentAreas.backSideHeader,
          [ModalElementsSlugs.toBackSide]: ModalContentAreas.toBackSide,
          [ModalElementsSlugs.toFrontSide]: ModalContentAreas.toFrontSide,
        },
      ),
    [],
  );

  const ConsentContent = useMemo(() => {
    const info = pageContentStore.content[ModalContentAreas.consent] as unknown as InfoContentItemType[];
    return info?.filter((item) => item.type === 'text').map((item) => <div key={item.id}>{item.value}</div>);
  }, [pageContentStore.content[ModalContentAreas.consent]]);

  const backSideHeader = useMemo(() => {
    const info = pageContentStore.content[ModalContentAreas.backSideHeader] as unknown as InfoContentItemType[];
    return info?.filter((item) => item.type === 'text').map((item) => <div key={item.id}>{item.value}</div>);
  }, [pageContentStore.content[ModalContentAreas.backSideHeader]]);

  const toBackSide = useMemo(() => {
    const info = pageContentStore.content[ModalContentAreas.toBackSide] as unknown as InfoContentItemType[];
    return info?.filter((item) => item.type === 'text').map((item) => <div key={item.id}>{item.value}</div>);
  }, [pageContentStore.content[ModalContentAreas.toBackSide]]);

  const toFrontSide = useMemo(() => {
    const info = pageContentStore.content[ModalContentAreas.toFrontSide] as unknown as InfoContentItemType[];
    return info?.filter((item) => item.type === 'text').map((item) => <div key={item.id}>{item.value}</div>);
  }, [pageContentStore.content[ModalContentAreas.toFrontSide]]);

  const welcomeMessageContent = useMemo(() => {
    const info = pageContentStore.content[ModalContentAreas.welcomeMessage] as unknown as InfoContentItemType[];
    return info?.filter((item) => item.type === 'text').map((item) => <p key={item.id}>{item.value}</p>);
  }, [pageContentStore.content[ModalContentAreas.welcomeMessage]]);

  return (
    <>
      {!publicOffer ? (
        <>
          <ModalBriefInfo>
            <ModalHeader>Добро пожаловать!</ModalHeader>

            <ModalPreview>{welcomeMessageContent}</ModalPreview>
          </ModalBriefInfo>

          {!isMobile && (
            <ModalImageWrapper>
              <ModalImage src={SRC} />
            </ModalImageWrapper>
          )}
        </>
      ) : (
        <ModalDescription>
          <ModalDescriptionHeading>{backSideHeader}</ModalDescriptionHeading>

          <ModalDescriptionTextWrapper>
            <ModalDescriptionTextContainer>
              <HtmlRenderer html={publicOfferText} />
            </ModalDescriptionTextContainer>
          </ModalDescriptionTextWrapper>
        </ModalDescription>
      )}

      <ModalActions>
        <ModalCheckboxWrapper>
          <Checkbox checked={profileStore.agreeWithPublicOffer} onChange={handlePublicOffer}>
            {ConsentContent}
          </Checkbox>

          <ModalTermsOfUseLink onClick={() => setPublicOffer((prevState) => !prevState)}>
            {publicOffer ? toFrontSide : toBackSide}
          </ModalTermsOfUseLink>
        </ModalCheckboxWrapper>

        <ModalButtonWrapper>
          <ModalButton white disabled={!profileStore.agreeWithPublicOffer} onClick={() => history.push('/profile')}>
            Заполнить
          </ModalButton>
        </ModalButtonWrapper>
      </ModalActions>
    </>
  );
});

export default ModalContent;
