import React, { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { useCategoryStore, useProductStore } from 'store';
import { Block } from 'utils/blocks';
import { FrameAdaptiveSize, SubstitutedCategoryType } from 'web';
import { ServicesNavigation } from 'components/services/shared/navigation';
import { BlocksRender } from 'components/renderers';
import { CategoryCardTypeEnum } from 'services';

const Div = styled.div`
  height: 100%;
  position: relative;
`;

const ServicesItem: React.FC = observer(() => {
  const { categoryId, categoryType, productId } = useParams<{
    categoryType: SubstitutedCategoryType;
    categoryId: string;
    productId: string;
  }>();

  const store = useProductStore();
  const categoryStore = useCategoryStore();

  useEffect(() => {
    store.fetchGlobalProduct(+productId);
    store.fetchProduct(+productId);
    return store.cleanUp;
  }, [productId]);

  useEffect(() => {
    categoryStore.fetchCategory({ id: parseInt(categoryId) });
    document.title = store.globalProductName;
  }, [categoryId, store.globalProductName]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    const iFrameBlockSizeArray = (store?.globalProduct?.page?.content
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      .filter(item => item.type === 'iframe_size')
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
      .map(item => item.value) as unknown) as FrameAdaptiveSize[];
    if (iFrameBlockSizeArray && iFrameBlockSizeArray.length > 0) {
      store.setIframeSize(iFrameBlockSizeArray);
    } else {
      store.setIframeSize([]);
    }
  }, [store.globalProductName]);

  const isGuide = useMemo(() => categoryType === 'guide', [categoryType]);

  const handleCloseErrorScreen = useCallback(
    () => store.closeErrorScreen(+productId), [store, productId],
  );

  return (
    <Div>
      <ServicesNavigation white={!isGuide} />

      <BlocksRender
        // @ts-expect-error skip //service/api -> on back need change type page from string to CategoryPage type
        blocks={(store?.globalProduct?.page?.content as unknown) as Block[]}
        options={{ categoryType,
          section: { compact: true },
          productName: store.product?.name,
          productType: 'services',
          cardType: CategoryCardTypeEnum.List }}
        errorScreen={store.product?.errorScreen}
        closeErrorScreenCallBack={handleCloseErrorScreen}
      />
    </Div>
  );
});

export default ServicesItem;
