import React, { useCallback } from 'react';
import { ResolutionItemType } from 'store/projects/types';
import { IconSvg } from 'assets/icons';
import { observer } from 'mobx-react-lite';
import { ContentType } from '../../types';
import { Styled } from '../../styles';
import { compareResolution, transformResolution } from '../../../lib';

interface QualityContentProps {
  resolutions: ResolutionItemType[];
  setContent: React.Dispatch<React.SetStateAction<ContentType>>;
  setResolution: (value: ResolutionItemType) => void;
  setReady: (value: boolean) => void;
  currentResolutionId?: number;
}

export const QualityContent = observer<QualityContentProps>((props) => {
  const handleOnBackClick = useCallback(() => {
    props.setContent('main');
  }, []);

  return (
    <div>
      <Styled.Title>
        <Styled.IconButton size={16} onClick={handleOnBackClick}>
          <IconSvg.ArrowLeft />
        </Styled.IconButton>
        <Styled.TitleText>Качество</Styled.TitleText>
      </Styled.Title>
      <Styled.ContentWrapper>
        {props.resolutions
          ?.slice()
          .sort(compareResolution)
          .map((value) => (
            <Styled.PopperButton
              key={value.id}
              onClick={() => {
                props.setReady(false);
                props.setResolution(value);
                props.setContent('main');
              }}
            >
              <IconSvg.CheckSmall
                style={{ visibility: value.id === props.currentResolutionId ? 'visible' : 'hidden' }}
              />
              <Styled.Value> {transformResolution(value.resolution)}</Styled.Value>
            </Styled.PopperButton>
          ))}
      </Styled.ContentWrapper>
    </div>
  );
});
