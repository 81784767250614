import { makeObservable, observable, action } from 'mobx';

import { AxiosError } from 'axios';
import { api } from '../../utils/api';

import { ProductAttribute, ProductAttributeApiProductAttributeListRequest } from '../../services';

type NullableError = Error | null;

export default class ProductAttributeStore {
  fetching = false;

  error: NullableError = null;

  attributes: ProductAttribute[] = [];

  constructor() {
    makeObservable(this, {
      fetching: observable,
      error: observable,
      attributes: observable,
      fetchAttributes: action,
      setError: action,
      setFetching: action,
      setAttributes: action,
      fetchAttributesIfNeed: action,
    });
  }

  fetchAttributes(props: ProductAttributeApiProductAttributeListRequest = {}, options?: unknown): Promise<void> {
    this.setError(null);
    this.setFetching(true);

    return api.productAttribute
      .productAttributeList(props, options)
      .then((res) => this.setAttributes(res.data.results))
      .catch((err: AxiosError) => this.setError(err))
      .finally(() => this.setFetching(false));
  }

  fetchAttributesIfNeed(props: ProductAttributeApiProductAttributeListRequest = {}, options?: unknown) {
    if (!this.fetching && this.attributes.length === 0) {
      this.fetchAttributes(props, options);
    }
  }

  setFetching(value: boolean): void {
    this.fetching = value;
  }

  setError(value: NullableError): void {
    this.error = value;
  }

  setAttributes(value: ProductAttribute[]): void {
    this.attributes = value;
  }
}
