import styled from 'styled-components';
import { COLORS } from 'utils/constants';

export const Wrapper = styled.div`
  height: auto;
  width: 100%;
  box-sizing: border-box;
`;

export const Label = styled.div`
  display: flex;
  font-size: 16px;
  color: ${COLORS.gray3};
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  position: relative;
  margin-bottom: 1px;
`;
export const RadioButtonLabel = styled.label`
  position: absolute;
  top: 4px;
  left: 0;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: ${COLORS.white};
  border: 1px solid ${COLORS.primary};
`;
export const RadioButton = styled.input`
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  width: 25px;
  height: 25px;
  margin-right: 6px;

  &:hover ~ ${RadioButtonLabel} {
    background: ${COLORS.white};
    &::after {
      content: url("data:image/svg+xml;charset=UTF-8,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='8' fill='%23D9DBDF'/%3e%3c/svg%3e");
      display: block;
      margin: 2px;
    }
  }

  &:checked + ${RadioButtonLabel} {
    background: ${COLORS.white};
    &::after {
      content: url("data:image/svg+xml;charset=UTF-8,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='8' fill='%238798B0'/%3e%3c/svg%3e");
      display: block;
      margin: 2px;
    }
  }
`;

export const IconContainer = styled.div`
  margin-right: 6px;
`;

export const Div = styled.div`
  display: flex;
  margin-right: 11px;
`;
