import React from 'react';
import { AwardWinners } from 'services';
import { ItemList } from './item';
import { Container } from './styles';

interface WinnersListProps {
  winners: AwardWinners[];
}

export const WinnersList: React.FC<WinnersListProps> = ({ winners }) => {
  return (
    <Container>
      {winners.map((item) => (
        <ItemList winner={item} />
      ))}
    </Container>
  );
};
