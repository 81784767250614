import React from 'react';

import { NotFoundLayout } from '../../layouts';

import NotFoundComponent from '../../components/not-found';
import ScrollToTopOnMount from '../../components/base-ui/scroll-to-top';

const NotFoundPage: React.FC = () => {
  document.title = 'Страница не найдена';
  return (
    <>
      <ScrollToTopOnMount />

      <NotFoundLayout>
        <NotFoundComponent />
      </NotFoundLayout>
    </>
  );
};

export default NotFoundPage;
