import React from 'react';
import { Container } from 'components/base-ui/container';
import { IconSvg } from 'assets/icons';
import { Styled } from './styles';

export const Location: React.FC<{ location: string }> = (props) => {
  return (
    <Container gap="16px">
      <Styled.IconWrapper>
        <IconSvg.Place />
      </Styled.IconWrapper>
      <Styled.Location>{props.location}</Styled.Location>
    </Container>
  );
};
