import styled from 'styled-components';
import { BREAKPOINTS } from 'utils/constants';

export const Container = styled.div`
  display: flex;
  column-gap: 12px;
  overflow: hidden;
  overflow-x: auto;
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    overflow: visible;
    justify-content: space-between;
  }
`;

export const Item = styled.div`
  display: inline-block;
  width: 320px;
  height: 224px;
  flex-shrink: 0;
  cursor: pointer;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    overflow: hidden;
    }
  }
`;

export const Image = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    transition: 1s;
    :hover {
      transform: scale(1.2);
    }
  }
`;
