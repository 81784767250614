import React from 'react';
import { AboutOurPeopleElement } from 'web';
import { StAboutOurPeople } from './styled';
import { AboutOurPeopleHeader } from './components/header';
import { Elements } from './components/elements';

interface Props {
  header: string;
  text: string;
  elements: AboutOurPeopleElement[];
}

export const AboutOurPeople: React.FC<Props> = (props) => {
  return (
    <StAboutOurPeople>
      <AboutOurPeopleHeader header={props.header} text={props.text} />
      <Elements elements={props.elements} />
    </StAboutOurPeople>
  );
};
