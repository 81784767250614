import React from 'react';
import styled from 'styled-components';

import { FreeContentValue } from 'web';

import { PropsWithSectionOptions } from '../blocks';

import Section from '../../base-ui/section';
import Offer from '../../services/shared/offer';

type FreeContentRendererProps = PropsWithSectionOptions<{
  value: FreeContentValue;
}>;

const StyledSection = styled(Section)`
  margin-bottom: 0;
`;

const FreeContentRenderer: React.FC<FreeContentRendererProps> = ({ value, options }) => {
  return (
    <StyledSection heading={value.header} compact gradient noLastOfTypeMargin={options?.noLastOfTypeMargin}>
      <Offer items={value.bannerList} />
    </StyledSection>
  );
};

export default FreeContentRenderer;
