import styled from 'styled-components';
import { COLORS, HEADER_HEIGHT } from 'utils/constants';

export const StHeader = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  display: grid;
  width: 100%;
  height: ${HEADER_HEIGHT};
  max-width: inherit;
  z-index: 100000;
  grid-template-columns: auto 1fr;
  background-color: ${COLORS.white};
`;
