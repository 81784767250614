import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  margin-top: 56px;
`;

export const EmailModalContent = styled.div`
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  row-gap: 16px;
`;

export const Text = styled.div`
  h2 {
    font-size: 48px;
  }
  h3 {
    font-size: 36px;
  }
  h4 {
    font-size: 24px;
  }
`;
