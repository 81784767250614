import React from 'react';
import styled from 'styled-components';
import { BREAKPOINTS } from 'utils/constants';
import Breadcrumbs from 'components/base-ui/breadcrumbs';

const Container = styled.div`
  width: 100%;
  padding: 0 16px 0 0;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 0 32px 0 32px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding: 0 32px 0 136px;
  }
`;

const Div = styled.div`
  top: 56px;
  left: 0;
  width: 100%;
  z-index: 100;
  position: absolute;

  > ${Container}:first-of-type {
    margin-top: 12px;
  }
`;

interface ServicesNavigationProps {
  white?: boolean;
  textTransform?: 'lowercase' | 'capitalize';
}

export const ServicesNavigation: React.FC<ServicesNavigationProps> = ({ white, textTransform }) => (
  <Div>
    <Container>
      <Breadcrumbs white={white} textTransform={textTransform} />
    </Container>
  </Div>
);
