import React from 'react';

import { DefaultLayout } from '../../../layouts';

import { NewsListComponent } from '../../../components/news';
import ScrollToTopOnMount from '../../../components/base-ui/scroll-to-top';

const NewsListPage: React.FC = () => {
  return (
    <>
      <ScrollToTopOnMount />

      <DefaultLayout>
        <NewsListComponent />
      </DefaultLayout>
    </>
  );
};

export default NewsListPage;
