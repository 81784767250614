import React, { createContext, useContext } from 'react';

import MedicineProgramStore from './store';

const store = new MedicineProgramStore();

const MedicineProgramStateContext = createContext<MedicineProgramStore>(store);

export const MedicineProgramProvider: React.FC = ({ children }) => (
  <MedicineProgramStateContext.Provider value={store}>{children}</MedicineProgramStateContext.Provider>
);

export const useMedicineProgramStore = (): MedicineProgramStore => useContext(MedicineProgramStateContext);
