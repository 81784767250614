import React, { useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';

import { GuidePage } from 'services';

import { useGuidePagesStore } from 'store';

import { useDeviceType, usePagination, useQueryParams, useWindowDimensions } from 'utils/hooks';

import Section from 'components/base-ui/section';
import Table from 'components/base-ui/table';

import { calculateCardsLimit } from 'utils/functions';
import { LikeModal } from 'components/base-ui/like-modal';
import { GuidePageCard } from './card';
import { CARD_GAPS, CARD_WIDTH, PADDING } from './constants';
import { Tags } from '../../base-ui/tags';
import { TagsContainer } from './styles';
import { LikeModalContent } from '../../base-ui/like-modal-content';

export const GuidePagesRenderer: React.FC = observer(() => {
  const [likeModalGuideId, setLikeModalGuuideId] = React.useState<number | null>(null);
  const [showLikeModal, setShowLikeModal] = React.useState(false);
  const location = useLocation();
  const windowDimension = useWindowDimensions();
  const device = useDeviceType();

  const store = useGuidePagesStore();
  const directionsIds = useMemo(
    () => store.directions?.filter((direction) => direction.active).map((direction) => direction.id),
    [store.directions],
  );

  const { page: initialPage } = useQueryParams();

  const ref = useRef<HTMLDivElement>(null);

  const pageLimit = useMemo(() => {
    return calculateCardsLimit(windowDimension.width, CARD_WIDTH, CARD_GAPS, PADDING);
  }, [windowDimension.width]);

  const { page, onPaginationWithRouter } = usePagination({
    ref,
    initialPage,
    path: location.pathname,
    count: store.count,
    error: store.error,
    limit: pageLimit,
    withRouter: true,
  });

  useEffect(
    () =>
      autorun(() => {
        store.fetchPages({ page, limit: pageLimit, directionsIdIn: directionsIds });
      }),
    [store, page, pageLimit, directionsIds],
  );

  useEffect(
    () =>
      autorun(() => {
        store.fetchDirections();
      }),
    [],
  );

  const handleShowModal = React.useCallback((guideId: number) => {
    setLikeModalGuuideId(guideId);
    setShowLikeModal(true);
  }, []);

  const likesLimit = React.useMemo(() => {
    if (device === 'mobile') return 70;
    return 40;
  }, [device]);

  return (
    <Section ref={ref} paddingless marginless compact>
      {store.directions && (
        <TagsContainer>
          <Tags data={store.directions} toggleDirections={store.toggleDirections} />
        </TagsContainer>
      )}
      <Table<GuidePage, never>
        count={store.count}
        limit={pageLimit}
        currentPage={page}
        cards={<GuidePageCard items={store.pages} handleShowModal={handleShowModal} />}
        onPagination={onPaginationWithRouter}
      />
      <LikeModal visible={showLikeModal} onClose={() => setShowLikeModal(false)}>
        <LikeModalContent cardType="guide" id={likeModalGuideId} limitPerPage={likesLimit} />
      </LikeModal>
    </Section>
  );
});
