import React from 'react';

import { ServicesBannerValue } from 'web';

import { PropsWithSectionOptions } from '../blocks';

import ServicesBannerInfo from '../../services/shared/info';

type ServicesBannerProps = PropsWithSectionOptions<{
  value: ServicesBannerValue;
}>;

const ServicesBanner: React.FC<ServicesBannerProps> = ({ value, options }) => {
  return <ServicesBannerInfo compact={options?.compact} {...value} />;
};

export default ServicesBanner;
