import styled from 'styled-components';
import { BREAKPOINTS } from 'utils/constants';
import { ArrowButton } from 'components/base-ui/button';
import { Textarea } from 'components/base-ui/textarea';

export const ReplyWrapper = styled.div`
  display: grid;
  row-gap: 8px;
  margin: 22px 16px 15px 16px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    column-gap: 8px;
    grid-auto-flow: column;
    grid-template-columns: auto 1fr;
    margin: 22px 88px 15px 88px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    margin: 22px 32px 15px 32px;
  }
`;

export const ReplyArrowButton = styled(ArrowButton)<{ isSub?: boolean }>`
  height: ${(props) => (props.isSub ? '50px' : '65px')};
`;

export const ReplyTextArea = styled(Textarea)<{ isSub?: boolean }>`
  height: ${(props) => (props.isSub ? '48px' : '64px')};
`;
