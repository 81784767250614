import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';
import IconButton from 'components/base-ui/icon-button';

export const ModalWrapper = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  mix-blend-mode: multiply;
  background: ${COLORS.gray3};
`;
export const ModalContainer = styled.div`
  top: 53px;
  left: 0;
  position: absolute;
  z-index: 99;
  isolation: isolate;
  border-radius: 2px;
  color: ${COLORS.white};
  width: 100%;
  padding: 70px 24px 56px 24px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.08);
  background: ${COLORS.linearGradient};

  @media screen and (min-height: ${BREAKPOINTS.mobileLandscape}) {
    top: 60px;
    left: 50%;
    width: calc(100% - 16px * 2);
    height: unset;
    transform: translateX(-50%);
  }

  @media screen and (min-width: ${BREAKPOINTS.tablet}) and (min-height: 400px) {
    top: 25%;
    width: 656px;
    padding: 24px 32px 56px 32px;
    position: fixed;
  }
`;
export const ModalContentWrapper = styled.div`
  height: 192px;
  margin-top: 32px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 1px;
    background-color: ${COLORS.gray5};
  }

  ::-webkit-scrollbar-track {
  }

  ::-webkit-scrollbar-thumb {
    outline: 1px solid ${COLORS.white};
    border-radius: 2px;
    background-color: ${COLORS.white};
  }
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin-left: 32px;
  }
`;
export const ModalCloseButton = styled(IconButton)``;
export const ModalCloseWrapper = styled.div`
  top: 23px;
  right: 32px;
  position: absolute;
  z-index: 2;
`;
export const Header = styled.div`
  font-size: 28px;
  font-weight: 700;
  line-height: 32px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin: 32px;
    font-size: 44px;
    line-height: 48px;
  }
`;
export const ButtonWrapper = styled.div`
  width: 210px;
  margin-top: 20px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin-left: 32px;
  }
`;
