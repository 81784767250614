import styled from 'styled-components';
import { COLORS } from 'utils/constants';

export const StSubMenu = styled.div<{ manyItems: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.manyItems ? 'row' : 'column')};
  flex-wrap: wrap;
  gap: 4px;
`;

export const StSubItem = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px;
  min-height: 56px;
  width: 260px;
  cursor: pointer;
  :hover {
    background-color: ${COLORS.gray6};
  }
  :active {
    background-color: ${COLORS.gray4};
  }
`;
