import React, { createContext, useContext } from 'react';

import GuideCommentsStore from './store';

const store = new GuideCommentsStore();

const GuideStateCommentContext = createContext<GuideCommentsStore>(store);

export const GuideCommentsStateProvider: React.FC = ({ children }) => (
  <GuideStateCommentContext.Provider value={store}>{children}</GuideStateCommentContext.Provider>
);

export const useGuideCommentsStore = (): GuideCommentsStore => useContext(GuideStateCommentContext);
