import styled, { css } from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';

import { ReactComponent as IconDownSvg } from 'assets/icons/icon_down.svg';

const Container = styled.div<{ isTwoColumn?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 248px;
  margin-bottom: auto;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 480px;
  }

  ${(props) =>
    props.isTwoColumn &&
    css`
      @media screen and (min-width: ${BREAKPOINTS.laptop}) {
        width: 849px;
      }
    `}
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 248px;
  margin-bottom: auto;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 480px;
  }
`;

const Divider = styled.div`
  width: 1px;
  background-color: ${COLORS.gray5};
  margin: 0 32px;
`;

const Title = styled.span<{ width?: string }>`
  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `};
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  word-wrap: break-word;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-size: 34px;
    line-height: 40px;
  }
`;

const Header = styled.span<{ expanded?: boolean; collapsable?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${(props) =>
    props.collapsable &&
    css`
      cursor: pointer;
    `};
`;

const IconDown = styled(IconDownSvg)`
  width: 24px;
  height: 24px;
  transition: all 0.3s ease-out;
  & * {
    fill: ${COLORS.secondary};
  }
  &.expanded {
    transform: rotate(180deg);
  }
  &.collapsed {
    transform: rotate(0deg);
  }
`;

const Line = styled.div<{ expanded?: boolean }>`
  margin: 0 auto;
  width: 100%;
  height: 1px;
  background-color: ${COLORS.borderGray};
  transition: width 0.5s ease-out;
  &.expanded {
    width: 0;
  }
`;

export const Styled = { Card, Title, Header, Line, IconDown, Divider, Container };
