import React, { useState, useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components';

import { CarouselButtonWrapperProps, ImageWithDescription } from 'web';

import { useDeviceType } from '../../../utils/hooks';
import { COLORS } from '../../../utils/constants';

import { PropsWithSectionOptions } from '../blocks';

import IconButton from '../../base-ui/icon-button';
import Section from '../../base-ui/section';

import { GalleryItem } from './item';

const Div = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Slides = styled.div`
  width: 100%;
  height: 424px;
  position: relative;
  margin-bottom: 32px;
`;

const StyledButton = styled(IconButton)`
  top: 182px;
  width: inherit;
  height: inherit;
  position: absolute;
`;

const leftStyles = css`
  left: 16px;
`;

const rightStyles = css`
  right: 16px;
`;

const ButtonWrapper = styled.div<CarouselButtonWrapperProps>`
  top: 0;
  z-index: 15;
  width: 48px;
  height: 48px;
  position: absolute;

  ${(props) => (props.direction === 'left' ? leftStyles : rightStyles)}
`;

const Navigation = styled.div`
  display: grid;
  column-gap: 16px;
  grid-auto-flow: column;
  grid-auto-columns: 16px;
`;

interface NavigationItemProps {
  active?: boolean;
}

const NavigationItem = styled.button<NavigationItemProps>`
  width: 16px;
  height: 16px;
  cursor: pointer;
  border: none;
  border-radius: 50%;
  transition: all 0.2s ease-out;
  background-color: ${(props) => (props.active ? COLORS.primary : COLORS.secondary)};

  &:hover,
  &:focus,
  &:active {
    filter: brightness(0.75);
  }
`;

type GalleryProps = PropsWithSectionOptions<{
  items: Pick<ImageWithDescription, 'image'>[];
}>;

const GalleryRenderer: React.FC<GalleryProps> = ({ items, options }) => {
  const [current, setCurrent] = useState(0);

  const decrement = useCallback(() => {
    setCurrent((prev) => (prev === 0 ? items.length - 1 : prev - 1));
  }, [items, setCurrent]);

  const increment = useCallback(() => {
    setCurrent((prev) => (prev === items.length - 1 ? 0 : prev + 1));
  }, [items, setCurrent]);

  const deviceType = useDeviceType();

  const isDesktop = useMemo(() => deviceType === 'desktop', [deviceType]);

  return (
    <Section compact paddingless={!isDesktop} noFirstOfTypeOffset noLastOfTypeMargin={options?.noLastOfTypeMargin}>
      <Div>
        <Slides>
          <ButtonWrapper direction="left">
            <StyledButton name="iconArrowLeft" round shadow gradient onClick={decrement} />
          </ButtonWrapper>

          {items.map((el, idx) => (
            <GalleryItem key={el.image} data={el} active={idx === current} />
          ))}

          <ButtonWrapper direction="right">
            <StyledButton name="iconArrowRight" round shadow gradient onClick={increment} />
          </ButtonWrapper>
        </Slides>

        <Navigation>
          {items.map((el, idx) => (
            <NavigationItem key={el.image} active={idx === current} onClick={() => setCurrent(() => idx)} />
          ))}
        </Navigation>
      </Div>
    </Section>
  );
};

export default GalleryRenderer;
