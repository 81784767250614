import React from 'react';
import styled from 'styled-components';

import { DoubleBannerBlockContent } from 'web';

import { BREAKPOINTS, COLORS } from '../../../utils/constants';

import { smallStyles } from '../../../styles/text';

import Link from '../../base-ui/link';
import HtmlContainer from '../../base-ui/html-container';

import iconArrow from '../../../assets/icons/icon_arrow.svg';
import { HtmlRenderer } from '../../../utils/htmlParser';

const Section = styled.section`
  color: ${COLORS.gray3};
  position: relative;
  margin-bottom: 20px;

  &:first-of-type {
    padding-top: 57px;
    @media screen and (min-width: ${BREAKPOINTS.tablet}) {
      padding-top: 114px;
    }
    @media screen and (min-width: ${BREAKPOINTS.laptop}) {
      padding-top: 208px;
    }
  }

  &:last-of-type {
    @media screen and (min-width: ${BREAKPOINTS.laptop}) {
      padding-bottom: 48px;
    }
  }

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    display: grid;
    grid-template-columns: 1fr 1fr 431px;
    grid-template-rows: 1fr 200px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    grid-template-rows: 234px 295px 55px;
    max-width: 1104px;
    margin: 0 auto;
  }
`;

const BannerInfoTitle = styled.div`
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 8px;
  padding-right: 40px;
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  text-decoration: none;
  color: inherit;
  transition: all 0.2s ease-out;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 5px;
    right: 7px;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-image: url(${iconArrow});
  }
`;

const BannerInfoDescription = styled(HtmlContainer)`
  ${smallStyles}
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const BigBanner = styled(Link)`
  color: inherit;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    display: grid;
    column-gap: 16px;
    grid-auto-flow: column;
    grid-template-columns: 1fr 280px;
    grid-row: 1 / 3;
    grid-column: 1 / 4;
    ${BannerInfoDescription} {
      -webkit-line-clamp: 17;
    }
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    grid-template-columns: 1fr 320px;
    ${BannerInfoDescription} {
      -webkit-line-clamp: 8;
    }
  }
  &:hover ${BannerInfoTitle}, &:focus ${BannerInfoTitle}, &:active ${BannerInfoTitle} {
    color: ${COLORS.primary};
  }
`;

const BigBannerWrapper = styled.div`
  height: 286px;
  overflow: hidden;
  position: relative;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    height: 614px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    height: 528px;
  }
`;

const BigBannerImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    height: 100%;
  }
`;

const BannerInfo = styled.div`
  @media screen and (max-width: calc(${BREAKPOINTS.tablet} - 1px)) {
    margin: 10px 16px 16px 16px;
  }
`;

const SmallBanner = styled(Link)`
  color: inherit;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    display: grid;
    column-gap: 16px;
    grid-auto-flow: column;
    grid-template-columns: 135px 280px;
    grid-row: 2 / 4;
    grid-column: 3 / 4;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    column-gap: 16px;
    grid-template-columns: 208px 207px;
  }

  &:hover ${BannerInfoTitle}, &:focus ${BannerInfoTitle}, &:active ${BannerInfoTitle} {
    color: ${COLORS.primary};
  }
`;

const SmallBannerColumn = styled.div`
  overflow: hidden;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    display: flex;
    flex-direction: column;
    ${BannerInfoDescription} {
      -webkit-line-clamp: 7;
    }
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    justify-content: center;
    ${BannerInfoDescription} {
      -webkit-line-clamp: 14;
    }
  }
`;

const SmallBannerWrapper = styled.div`
  width: 100%;
  height: 200px;
  z-index: 2;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    background-color: #fff;
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    height: 100%;
  }
`;

const SmallBannerImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

interface DoubleBannerProps {
  value: DoubleBannerBlockContent;
}

const DoubleBanner: React.FC<DoubleBannerProps> = ({ value }) => {
  return (
    <Section>
      <BigBanner to={value.firstBanner.buttonLink.link}>
        <BigBannerWrapper>
          <BigBannerImage src={value.firstBanner.image} />
        </BigBannerWrapper>

        <BannerInfo>
          <BannerInfoTitle>{value.firstBanner.header}</BannerInfoTitle>

          <BannerInfoDescription>
            <HtmlRenderer html={value.firstBanner.preview} />
          </BannerInfoDescription>
        </BannerInfo>
      </BigBanner>

      <SmallBanner to={value.secondBanner.buttonLink.link}>
        <SmallBannerColumn>
          <SmallBannerWrapper>
            <SmallBannerImage src={value.secondBanner.image} />
          </SmallBannerWrapper>
        </SmallBannerColumn>

        <SmallBannerColumn>
          <BannerInfo>
            <BannerInfoTitle>{value.secondBanner.header}</BannerInfoTitle>
            <BannerInfoDescription>
              <HtmlRenderer html={value.secondBanner.preview} />
            </BannerInfoDescription>
          </BannerInfo>
        </SmallBannerColumn>
      </SmallBanner>
    </Section>
  );
};

export default DoubleBanner;
