import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import { observer } from 'mobx-react-lite';
import {
  AvatarChangeButton,
  AvatarChangeWrapper,
  AvatarRemoveButton,
  AvatarRemoveText,
  AvatarRemoveWrapper,
  AvatarWrapper,
  Content,
  Div,
  InfoName,
  InfoNameWrapper,
  InfoText,
  InfoTextWrapper,
  InfoWrapper,
} from 'components/profile/banner/styles';

import { useDeviceType } from 'utils/hooks';
import { useProfileStore } from 'store/profile';
import { WarningPopup } from 'components/base-ui/warning-popup';
import avatar from 'assets/img/assol_avatar.png';
import Avatar from '../../base-ui/avatar';

const Banner: React.FC = observer(() => {
  const deviceType = useDeviceType();
  const inputFileRef = useRef<HTMLInputElement>(null);

  const isMobile = useMemo(() => deviceType === 'mobile', [deviceType]);

  const {
    hobbies,
    activity,
    fullName,
    removeProfileImage,
    image,
    setProfileImage,
    errorScreen,
    getErrorScreen,
    closeErrorScreen,
  } = useProfileStore();

  useEffect(() => {
    getErrorScreen();
  }, [getErrorScreen]);

  const userHobbies = useMemo(() => hobbies.map((h) => h.name).join(', '), [hobbies]);

  const userActivity = useMemo(() => activity?.name, [activity]);

  const userInfo = useMemo(() => {
    if (userHobbies && userActivity) {
      return (
        <>
          <InfoText>{userActivity}</InfoText>
          <InfoText>/</InfoText>
          <InfoText>{userHobbies}</InfoText>
        </>
      );
    }

    if (userHobbies) return <InfoText>{userHobbies}</InfoText>;

    if (userActivity) return <InfoText>{userActivity}</InfoText>;

    return null;
  }, [userHobbies, userActivity]);

  const fileHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files?.[0]) {
      setProfileImage(files[0]);
    }
  }, []);

  return (
    <Div>
      <Content>
        <AvatarWrapper>
          <Avatar src={image || avatar} size={isMobile ? 'medium' : 'large'} bordered marginless />

          <AvatarChangeWrapper>
            <input type="file" hidden ref={inputFileRef} onChange={fileHandler} accept=".gif,.jpg,.jpeg,.png" />
            <AvatarChangeButton name="iconFoto" onClick={() => inputFileRef?.current?.click()} />
          </AvatarChangeWrapper>
        </AvatarWrapper>

        <AvatarRemoveWrapper onClick={removeProfileImage}>
          <AvatarRemoveText>Удалить фото</AvatarRemoveText>
          <AvatarRemoveButton name="iconCrossSmallBlue" />
        </AvatarRemoveWrapper>

        <InfoWrapper>
          <InfoNameWrapper>
            <InfoName>{fullName}</InfoName>
          </InfoNameWrapper>

          {errorScreen && (
            <WarningPopup
              header={errorScreen.title}
              description={errorScreen.content}
              link={errorScreen?.detail || ''}
              handleCloseErrorScreen={closeErrorScreen}
            />
          )}

          <InfoTextWrapper>{userInfo}</InfoTextWrapper>
        </InfoWrapper>
      </Content>
    </Div>
  );
});

export default Banner;
