import React, { createContext, useContext } from 'react';

import InsuranceCategoryStore from './store';

const store = new InsuranceCategoryStore();

const InsuranceCategoryStateContext = createContext<InsuranceCategoryStore>(store);

export const InsuranceCategoryStateProvider: React.FC = ({ children }) => (
  <InsuranceCategoryStateContext.Provider value={store}>{children}</InsuranceCategoryStateContext.Provider>
);

export const useInsuranceCategoryStore = (): InsuranceCategoryStore => useContext(InsuranceCategoryStateContext);
