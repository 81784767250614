import React, { createContext, useContext } from 'react';

import BasePageStore from './store';

const store = new BasePageStore();

const BasePageStateContext = createContext<BasePageStore>(store);

export const BasePageStateProvider: React.FC = ({ children }) => (
  <BasePageStateContext.Provider value={store}>{children}</BasePageStateContext.Provider>
);

export const useBasePageStore = (): BasePageStore => useContext(BasePageStateContext);
