import styled from 'styled-components';
import { BREAKPOINTS } from 'utils/constants';

export const Container = styled.div`
  margin-top: 53px;
`;

export const BreadCrumbsWrapper = styled.div`
  position: absolute;
  top: 69px;
  left: 0;
  z-index: 999;

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    left: 125px;
  }
`;
