import React from 'react';
import { DefaultLayout } from 'layouts';
import { NewsItemComponent } from 'components/news';
import { NewsCommentsStateProvider } from 'store';

const NewsItemPage: React.FC = () => {
  return (
    <NewsCommentsStateProvider>
      <DefaultLayout>
        <NewsItemComponent />
      </DefaultLayout>
    </NewsCommentsStateProvider>
  );
};

export default NewsItemPage;
