import styled from 'styled-components';
import { H3 as StyledH3 } from 'components/base-ui/typo';
import { COLORS, BREAKPOINTS } from 'utils/constants';

export const Container = styled.div`
  display: flex;
  column-gap: 48px;
  overflow: hidden;
  overflow-x: auto;
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    border-bottom: 1px solid ${COLORS.gray5};
  }
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    border-bottom: 1px solid ${COLORS.gray5};
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    overflow: visible;
    justify-content: space-between;
  }
`;

export const Item = styled.div`
  flex-grow: 1;
  font-size: 18px;
  line-height: 28px;
  color: ${COLORS.gray};
  flex-shrink: 0;
  max-width: 256px;
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    max-width: none;
    flex-grow: 0;
    flex-shrink: 1;
    width: 25%;
  }
`;
export const Year = styled(StyledH3)`
  padding-bottom: 12px;
  position: relative;
  color: ${COLORS.gray4};
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 55px;
    border-bottom: 8px solid ${COLORS.red};
  }
`;
export const Title = styled.div`
  margin-top: 22px;
`;

export const CarouselBtnRight = styled.div`
  margin-top: 50px;
  width: 11px;
  height: 20px;
  cursor: pointer;
`;

export const CarouselBtnLeft = styled(CarouselBtnRight)`
  transform: rotate(180deg);
`;
