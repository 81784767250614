import React, { createContext, useContext } from 'react';

import ShopsStore from './store';

const store = new ShopsStore();

const ShopsStateContext = createContext<ShopsStore>(store);

export const ShopsStateProvider: React.FC = ({ children }) => (
  <ShopsStateContext.Provider value={store}>{children}</ShopsStateContext.Provider>
);

export const useShopsStore = (): ShopsStore => useContext(ShopsStateContext);
