import React from 'react';
import { Video } from 'web';
import { StIcon, StImageWrapper, StVideo } from './styled';

interface Props {
  video: Video;
  onPlay: () => void;
}

export const ItemVideo: React.FC<Props> = (props) => {
  return (
    <StVideo>
      <StImageWrapper bg={props.video?.preview} onClick={props.onPlay}>
        <StIcon name="playCircle" />
      </StImageWrapper>
    </StVideo>
  );
};
