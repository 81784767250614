import { action, makeObservable, observable } from 'mobx';
import { GuideCategory } from 'services';
import { api } from 'utils/api';
import { AxiosError } from 'axios';
import AbstractStore from '../AbstractStore';

export default class GuideStore extends AbstractStore {
  category: GuideCategory | null = null;

  constructor() {
    super();
    makeObservable(this, {
      ...this.annotations,
      category: observable,
      fetchCategory: action,
      setCategory: action,
      closeErrorScreen: action,
    });
  }

  fetchCategory = async () => {
    const category = await this.fetchData<GuideCategory>(() =>
      api.guide.category.guideCategoryList().then((res) => res.data),
    );

    if (category) {
      this.setCategory(category);
    }
  };

  closeErrorScreen = () => {
    this.setError(null);

    api.closeErrorScreen
      .closeErrorScreenCreate({
        data: {
          screen: this.category?.errorScreen?.id || 0,
        },
      })
      .then(() => this.fetchCategory())
      .catch((err: AxiosError) => this.setError(err));
  };

  setCategory(category: GuideCategory | null) {
    this.category = category;
  }
}
