import React from 'react';
import styled from 'styled-components';

import { BREAKPOINTS } from '../../../../utils/constants';

const BannerWrapper = styled.div`
  width: 100%;
  height: 568px;
  overflow: hidden;
  position: relative;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    height: 800px;
  }
`;

const BannerImage = styled.img`
  top: 50%;
  left: 50%;
  height: 100%;
  position: absolute;
  transform: translate(-50%, -50%);
`;

interface BannerProps {
  image?: string;
}

const Banner: React.FC<BannerProps> = (props) => {
  return props.image ? (
    <BannerWrapper>
      <BannerImage src={props.image} />
    </BannerWrapper>
  ) : null;
};

export default Banner;
