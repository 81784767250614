import React, { useCallback } from 'react';

import { AwardParticipantsTypeEnum } from 'services';
import { api } from 'utils/api';
import { useQuery } from '@tanstack/react-query';
import { ModalWrapper } from './ModalWrapper';
import { TeamContent } from './TeamContent';
import { OneParticipantContent } from './OneParticipantContent';

export const ParticipantModal: React.VFC<{
  visible: boolean;
  onClose: () => void;
  participantId?: number | null;
}> = (props) => {
  const { data: participant } = useQuery(
    ['participant', 'modal', props.participantId],
    async () => {
      if (props.participantId) {
        const { data } = await api.projects.projectsAwardParticipantsRead({ id: props.participantId });
        return data;
      }
    },
    {
      retry: 2,
      enabled: Boolean(props.participantId),
    },
  );

  const handleOnClose = useCallback(() => {
    props.onClose();
  }, [props.onClose]);

  return (
    <ModalWrapper isVisible={props.visible && Boolean(participant)} onClose={handleOnClose}>
      {participant &&
        (participant?.type === AwardParticipantsTypeEnum.Team ? (
          <TeamContent participant={participant} />
        ) : (
          <OneParticipantContent participant={participant} />
        ))}
    </ModalWrapper>
  );
};
