import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { observer } from 'mobx-react-lite';
import { COLORS, BREAKPOINTS } from 'utils/constants';

import Avatar from 'components/base-ui/avatar';
import Stars from 'components/base-ui/stars';
import { Span } from 'components/base-ui/typo';
import { Textarea } from 'components/base-ui/textarea';
import { useProductStore } from 'store/product';
import { useProfileStore } from 'store/profile';
import DEFAULT_SRC from 'assets/img/assol_avatar.png';
import { ArrowButton } from 'components/base-ui/button';

const Container = styled.div`
  display: grid;
  row-gap: 16px;
  grid-auto-flow: row;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    row-gap: unset;
    column-gap: 16px;
    grid-auto-flow: column;
    grid-template-columns: 64px 1fr auto;
  }
`;

const FooterColumn = styled.div`
  height: 64px;
`;

const Rating = styled.div`
  display: grid;
  row-gap: 8px;
  grid-template-rows: 1fr 24px;
`;

const RatingText = styled(Span)`
  margin-top: 8px;
  color: ${COLORS.secondary};
`;

const RatingStars = styled(Stars)`
  opacity: 0.8;
`;

const GoodCommentArrowButton = styled(ArrowButton)`
  height: 81px;
`;

const Footer: React.FC = observer(() => {
  const store = useProductStore();
  const { profile } = useProfileStore();
  const [comment, setComment] = useState('');
  const [rating, setRating] = useState<number | undefined>(Number(store.product?.userRating));

  const changeRating = (v: number) => {
    store.changeRating(v);
    setRating(v);
  };

  useEffect(() => {
    store.fetchAverageRating(store.product?.id || 0);
  }, [rating]);

  return (
    <Container>
      <FooterColumn>
        <Avatar src={profile?.image || DEFAULT_SRC} bordered />
      </FooterColumn>

      <FooterColumn>
        <Textarea value={comment} placeholder="Напишите ваш отзыв" onChange={(e) => setComment(e.target.value)} />
      </FooterColumn>

      <FooterColumn>
        {comment.length > 0 ? (
          <GoodCommentArrowButton
            onClick={() => {
              store.addComment(comment);
              setComment('');
            }}
          >
            Отправить
          </GoodCommentArrowButton>
        ) : (
          <Rating>
            <RatingText>Пожалуйста, оцените:</RatingText>
            <RatingStars value={rating} onChange={changeRating} />
          </Rating>
        )}
      </FooterColumn>
    </Container>
  );
});

export default Footer;
