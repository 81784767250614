import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';

import { COLORS, BREAKPOINTS } from '../../../../utils/constants';

import { microStyles } from '../../../../styles/text';

import { H6 } from '../../typo';
import HtmlContainer from '../../html-container';
import { HtmlRenderer } from '../../../../utils/htmlParser';

const lineClamp = css`
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const paddingRightStyles = css`
  padding-right: 88px;
`;

interface DivProps {
  paddingRight?: boolean;
}

const Div = styled.div<DivProps>`
  display: flex;
  position: relative;
  align-items: flex-start;

  &:not(:last-of-type) {
    &:before {
      content: '';
      top: 48px;
      left: 24px;
      width: 1px;
      height: 100%;
      position: absolute;
      background-color: ${COLORS.gray5};
    }
  }

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    ${(props) => props.paddingRight && paddingRightStyles}

    flex-direction: column;

    &:not(:last-of-type) {
      &:before {
        content: '';
        top: 24px;
        left: 48px;
        height: 1px;
        width: 100%;
        position: absolute;
        background-color: ${COLORS.gray5};
      }
    }
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    flex-direction: column;

    &:not(:last-of-type) {
      &:before {
        content: '';
        top: 24px;
        left: 48px;
        height: 1px;
        width: calc(100% + 80px);
        position: absolute;
        background-color: ${COLORS.gray5};
      }
    }
  }
`;

const Head = styled.div`
  z-index: 5;
  flex: 0 0 48px;
  width: 48px;
  height: 48px;
  padding: 5px;
  margin: 0 16px 24px 0;
  border-radius: 10000px;
  background: ${COLORS.linearGradient};

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin: 0 0 24px 0;
  }
`;

const Number = styled(H6)`
  padding: 5px;
  border-radius: 10000px;
  text-align: center;
  color: ${COLORS.white};
  background: ${COLORS.primary};
`;

const Body = styled.div``;

const Description = styled(HtmlContainer)`
  ${microStyles}

  p {
    ${lineClamp}
  }
`;

interface ItemProps extends DivProps {
  idx: number;
  text: string;
}

const Item: React.FC<ItemProps> = ({ idx, text, paddingRight }) => {
  const num = useMemo(() => idx + 1, [idx]);

  return (
    <Div paddingRight={paddingRight}>
      <Head>
        <Number>{num}</Number>
      </Head>

      <Body>
        <Description>
          <HtmlRenderer html={text} />
        </Description>
      </Body>
    </Div>
  );
};

export default Item;
