import React, { useMemo, useRef } from 'react';
import { PortalDirectionItem } from 'services';
import { useHover, useMenuState } from '@szhsin/react-menu';
import { SubMenu } from '../sub-menu';
import { StItem, StMenu } from './styled';

interface Props {
  item: PortalDirectionItem;
}

export const NavMenu: React.FC<Props> = (props) => {
  const ref = useRef(null);
  const [menuState, toggle] = useMenuState({ transition: true });
  const { anchorProps, hoverProps } = useHover(menuState.state, toggle);

  const withSubItems = useMemo(() => {
    return props.item?.subItems && props.item.subItems.length > 0;
  }, [props.item]);

  return withSubItems ? (
    <>
      <StItem ref={ref} {...anchorProps} href={props.item.link}>
        {props.item.title}
      </StItem>
      <StMenu {...hoverProps} {...menuState} anchorRef={ref} onClose={() => toggle(false)} arrow align="center" gap={7}>
        <SubMenu subItems={props.item.subItems} />
      </StMenu>
    </>
  ) : (
    <StItem href={props.item.link}>{props.item.title}</StItem>
  );
};
