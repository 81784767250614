import React from 'react';

/* eslint-disable max-len */

type Props = {
  mobile?: boolean;
};

export const FooterDecor: React.FC<Props> = ({ mobile }) => {
  return (
    <div>
      <svg
        width="992"
        height={mobile ? '47' : '65'}
        viewBox="0 0 992 65"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M369.685 59.4404C364.219 59.4404 358.875 57.8162 354.33 54.7731C349.785 51.73 346.243 47.4047 344.151 42.3442C342.059 37.2837 341.512 31.7153 342.578 26.3431C343.644 20.9709 346.277 16.0362 350.142 12.1631C354.007 8.28993 358.932 5.65229 364.293 4.58369C369.654 3.51509 375.212 4.06354 380.262 6.15967C385.312 8.25579 389.629 11.8055 392.665 16.3598C395.702 20.9141 397.323 26.2686 397.323 31.746C397.323 39.091 394.412 46.1352 389.228 51.3289C384.045 56.5226 377.015 59.4404 369.685 59.4404ZM369.685 9.82635C365.366 9.83447 361.147 11.1251 357.56 13.5352C353.973 15.9453 351.18 19.3667 349.533 23.3671C347.886 27.3675 347.459 31.7673 348.306 36.0106C349.154 40.2539 351.238 44.1503 354.294 47.2074C357.351 50.2645 361.243 52.3452 365.479 53.1864C369.716 54.0277 374.106 53.5919 378.095 51.9339C382.084 50.276 385.493 47.4705 387.892 43.8717C390.29 40.273 391.571 36.0427 391.571 31.7152C391.572 28.8369 391.006 25.9866 389.907 23.3276C388.807 20.6687 387.194 18.2533 385.161 16.2199C383.128 14.1865 380.714 12.5751 378.059 11.478C375.403 10.3809 372.558 9.81961 369.685 9.82635Z"
          fill="#005CAA"
        />
        <path
          d="M369.688 16.3094C378.179 16.3094 385.063 23.2071 385.063 31.7158C385.063 40.2244 378.179 47.1221 369.688 47.1221C361.196 47.1221 354.312 40.2244 354.312 31.7158C354.312 23.2071 361.196 16.3094 369.688 16.3094Z"
          fill="#EC1066"
        />
        <path
          d="M31.0026 63.707C23.7887 63.7136 16.7985 61.1979 11.2374 56.5935C5.67628 51.9891 1.89271 45.5847 0.539062 38.4846H61.4251C60.0728 45.5778 56.2952 51.9769 50.7425 56.5804C45.1899 61.184 38.2094 63.7041 31.0026 63.707Z"
          fill="#00ADEE"
        />
        <path
          d="M31.0026 1.62031C38.2123 1.62416 45.1951 4.14718 50.7482 8.75481C56.3012 13.3625 60.077 19.7663 61.4251 26.8633H0.539062C1.88849 19.7593 5.67024 13.3502 11.2318 8.74174C16.7933 4.13329 23.7857 1.61461 31.0026 1.62031Z"
          fill="#00ADEE"
        />
        <path
          d="M61.9609 32.6695C61.9577 34.6207 61.7761 36.5674 61.4183 38.4854H0.542541C-0.180847 34.6455 -0.180847 30.7038 0.542541 26.8639H61.4286C61.783 28.7787 61.9612 30.7221 61.9609 32.6695Z"
          fill="#C0CD40"
        />
        <path d="M992 18.9243H969.213H946.438V41.7568V64.5791H969.213H992V41.7568V18.9243Z" fill="#005CAA" />
        <path d="M946.438 64.5791V41.7568V18.9243L969.213 41.7568L946.438 64.5791Z" fill="#EC1066" />
        <path d="M969.211 41.7568L991.997 18.9243V41.7568V64.5791L969.211 41.7568Z" fill="#00ADEE" />
        <path d="M946.438 18.9253H969.213H992L969.213 41.7578L946.438 18.9253Z" fill="#005CAA" />
        <path
          d="M923.214 19.9602C934.719 19.9602 944.045 29.3056 944.045 40.8336C944.045 52.3617 934.719 61.707 923.214 61.707C911.709 61.707 902.383 52.3617 902.383 40.8336C902.383 29.3056 911.709 19.9602 923.214 19.9602Z"
          fill="#C0CD40"
        />
        <path
          d="M902.367 40.8336C902.37 35.2967 904.567 29.9875 908.475 26.0733C912.383 22.1591 917.683 19.9602 923.208 19.9602V61.707C917.683 61.707 912.383 59.5082 908.475 55.594C904.567 51.6797 902.37 46.3705 902.367 40.8336Z"
          fill="#005CAA"
        />
        <path d="M877.052 64.3848H854.297V18.7813H877.052H899.818V41.583V64.3848H877.052Z" fill="#EC1066" />
        <path d="M877.099 64.4053L899.824 18.8018H877.058H854.312L877.099 64.4053Z" fill="#00ADEE" />
        <path
          d="M794.198 60.3838C791.742 60.3838 789.341 59.6538 787.299 58.2862C785.256 56.9186 783.665 54.9748 782.725 52.7008C781.786 50.4267 781.541 47.9245 782.021 45.5107C782.501 43.0969 783.684 40.88 785.422 39.1404C787.159 37.4007 789.373 36.2166 791.782 35.7376C794.191 35.2587 796.688 35.5066 798.957 36.4499C801.225 37.3931 803.164 38.9894 804.527 41.0369C805.89 43.0843 806.617 45.4908 806.615 47.9521C806.615 49.5855 806.293 51.2029 805.669 52.7118C805.045 54.2208 804.13 55.5917 802.977 56.7462C801.824 57.9008 800.455 58.8163 798.949 59.4404C797.442 60.0646 795.828 60.3851 794.198 60.3838ZM794.198 38.1257C792.258 38.1257 790.362 38.702 788.75 39.7817C787.137 40.8615 785.88 42.3961 785.138 44.1917C784.396 45.9872 784.202 47.963 784.58 49.8691C784.958 51.7752 785.892 53.5261 787.264 54.9004C788.635 56.2746 790.383 57.2105 792.285 57.5896C794.187 57.9688 796.159 57.7742 797.951 57.0305C799.743 56.2867 801.274 55.0273 802.352 53.4113C803.429 51.7954 804.004 49.8955 804.004 47.9521C804.004 46.6616 803.751 45.3839 803.258 44.1917C802.765 42.9995 802.043 41.9162 801.132 41.0038C800.222 40.0913 799.141 39.3675 797.951 38.8737C796.761 38.3798 795.486 38.1257 794.198 38.1257Z"
          fill="#005CAA"
        />
        <path
          d="M794.204 41.0395C798.014 41.0395 801.103 44.1347 801.103 47.9529C801.103 51.771 798.014 54.8662 794.204 54.8662C790.394 54.8662 787.305 51.771 787.305 47.9529C787.305 44.1347 790.394 41.0395 794.204 41.0395Z"
          fill="#EC1066"
        />
        <path
          d="M829.973 0.0921192C835.339 0.0928307 840.536 1.96901 844.67 5.39733C848.803 8.82564 851.613 13.5913 852.616 18.873H807.32C808.327 13.5914 811.14 8.8266 815.275 5.39876C819.409 1.97091 824.607 0.0943909 829.973 0.0921192Z"
          fill="#EC1066"
        />
        <path
          d="M829.973 46.3018C824.607 46.3015 819.408 44.4256 815.273 40.9975C811.138 37.5693 808.326 32.8034 807.32 27.5208H852.616C851.615 32.8034 848.805 37.5703 844.672 40.9989C840.538 44.4276 835.34 46.303 829.973 46.3018Z"
          fill="#EC1066"
        />
        <path
          d="M806.914 23.1612C806.92 21.7089 807.057 20.2601 807.324 18.8327H852.62C853.165 21.689 853.165 24.6232 852.62 27.4795H807.324C807.057 26.0555 806.92 24.6101 806.914 23.1612Z"
          fill="#C0CD40"
        />
        <path
          d="M642.218 64.4258C639.023 64.4278 635.899 63.4802 633.241 61.703C630.583 59.9257 628.511 57.3985 627.287 54.4411C626.063 51.4837 625.742 48.2289 626.364 45.0885C626.986 41.9481 628.524 39.0631 630.782 36.7984C633.041 34.5338 635.919 32.9912 639.053 32.3658C642.186 31.7405 645.435 32.0604 648.387 33.2851C651.339 34.5099 653.862 36.5845 655.638 39.2465C657.413 41.9084 658.361 45.0382 658.361 48.2399C658.361 52.5309 656.66 56.6463 653.633 59.6814C650.606 62.7166 646.5 64.4231 642.218 64.4258ZM642.218 35.4697C639.694 35.4677 637.227 36.2158 635.128 37.6193C633.029 39.0229 631.393 41.0188 630.426 43.3546C629.459 45.6903 629.206 48.2609 629.698 50.741C630.19 53.2211 631.404 55.4993 633.189 57.2873C634.973 59.0753 637.247 60.2928 639.722 60.7856C642.197 61.2784 644.762 61.0244 647.093 60.0558C649.424 59.0872 651.416 57.4475 652.817 55.3442C654.218 53.2409 654.964 50.7685 654.962 48.2399C654.962 46.5629 654.633 44.9023 653.992 43.353C653.352 41.8036 652.413 40.3958 651.229 39.21C650.046 38.0242 648.641 37.0835 647.095 36.4418C645.549 35.8 643.892 35.4697 642.218 35.4697Z"
          fill="#EC1066"
        />
        <path d="M435.796 64.5381H435.745H403.531V-0.000205994H403.552L435.796 64.5381Z" fill="#005CAA" />
        <path d="M403.547 -0.000205994H435.73H435.74H435.791V64.5381L403.547 -0.000205994Z" fill="#EC1066" />
        <path d="M435.789 64.5381L467.952 -0.000205994V32.2689V64.5381" fill="#005CAA" />
        <path d="M435.789 64.5381V-0.000205994H467.952L435.789 64.5381Z" fill="#C0CD40" />
        <path
          d="M674.435 63.9131C670.71 63.9102 667.104 62.6039 664.239 60.2197C661.373 57.8356 659.429 54.5235 658.742 50.8557H690.127C689.44 54.5235 687.496 57.8356 684.63 60.2197C681.765 62.6039 678.159 63.9102 674.435 63.9131Z"
          fill="#00ADEE"
        />
        <path
          d="M674.435 31.9001C678.152 31.9007 681.752 33.1998 684.616 35.5737C687.481 37.9476 689.429 41.2479 690.127 44.9062H658.742C659.44 41.2479 661.389 37.9476 664.253 35.5737C667.117 33.1998 670.718 31.9007 674.435 31.9001Z"
          fill="#00ADEE"
        />
        <path
          d="M690.411 47.9009C690.409 48.9095 690.314 49.9156 690.124 50.9062H658.74C658.358 48.9244 658.358 46.8876 658.74 44.9058H690.124C690.313 45.893 690.409 46.8958 690.411 47.9009Z"
          fill="#C0CD40"
        />
        <path
          d="M499.051 1.30283C506.358 1.30434 513.436 3.85878 519.065 8.52626C524.695 13.1937 528.524 19.6821 529.894 26.874H468.25C469.618 19.689 473.441 13.206 479.062 8.53932C484.684 3.87266 491.752 1.31388 499.051 1.30283Z"
          fill="#EC1066"
        />
        <path
          d="M499.051 64.1894C491.755 64.1817 484.69 61.628 479.069 56.9671C473.448 52.3063 469.623 45.8292 468.25 38.649H529.924C528.549 45.8413 524.713 52.3277 519.078 56.99C513.442 61.6523 506.36 64.1984 499.051 64.1894Z"
          fill="#EC1066"
        />
        <path
          d="M467.688 32.7618C467.687 30.7857 467.876 28.8142 468.251 26.8742H529.925C530.662 30.7645 530.662 34.7591 529.925 38.6494H468.251C467.876 36.7094 467.687 34.7378 467.688 32.7618Z"
          fill="#C0CD40"
        />
        <path d="M609.946 32.1259H625.997V64.2822H593.875V32.1259H609.946Z" fill="#00ADEE" />
        <path
          d="M613.938 32.1259L625.976 44.1884L621.964 48.2092H609.926V60.2614L605.913 64.2822L593.875 52.2198V32.1259H613.938Z"
          fill="#065DAA"
        />
        <path d="M625.977 64.2822H605.914V44.1781H625.977V64.2822Z" fill="#EC1066" />
        <path d="M562.001 0.38966H593.867V64.251H562.001H530.125V32.3101V0.38966H562.001Z" fill="#EC1066" />
        <path d="M561.937 0.38966L530.133 64.251H562.009H593.854L561.937 0.38966Z" fill="#C0CD40" />
        <path d="M562.021 64.251L577.928 32.3101H561.99H546.062L562.021 64.251Z" fill="#065DAA" />
        <path d="M746.799 31.8788H730.666H714.523V48.0441V64.2197H730.666H746.799V48.0441V31.8788Z" fill="#065DAA" />
        <path d="M746.799 31.8788H730.666H714.523V48.0441V64.2197L746.799 31.8788Z" fill="#C0CD40" />
        <path d="M779.111 31.8588H762.979H746.836V48.0344V64.21H762.979H779.111V48.0344V31.8588Z" fill="#065DAA" />
        <path d="M746.836 64.21V48.0344V31.8588L762.979 48.0344L746.836 64.21Z" fill="#00ADEE" />
        <path d="M762.977 48.0344L779.109 31.8588V48.0344V64.21L762.977 48.0344Z" fill="#00ADEE" />
        <path d="M746.836 31.8596H762.979H779.111L762.979 48.0352L746.836 31.8596Z" fill="#EC1066" />
        <path d="M746.832 0.0723095H730.966H715.109V15.971V31.8594H730.966H746.832V15.971V0.0723095Z" fill="#EC1066" />
        <path d="M746.832 0.0723095H730.966H715.109V15.971V31.8594L746.832 0.0723095Z" fill="#C0CD40" />
        <path d="M715.052 31.9102H683.258V0.0513039H715.052V31.9102Z" fill="#EC1066" />
        <path
          d="M699.147 0.0513039C707.927 0.0513039 715.044 7.18315 715.044 15.9807C715.044 24.7783 707.927 31.9102 699.147 31.9102C690.367 31.9102 683.25 24.7783 683.25 15.9807C683.25 7.18315 690.367 0.0513039 699.147 0.0513039Z"
          fill="#C0CD40"
        />
        <path
          d="M683.25 15.9807C683.25 13.8902 683.661 11.8201 684.459 9.88872C685.258 7.95732 686.428 6.2024 687.903 4.72417C689.378 3.24594 691.13 2.07334 693.057 1.27333C694.985 0.473316 697.051 0.0615559 699.137 0.0615559V31.9102C694.922 31.9074 690.882 30.228 687.903 27.2409C684.923 24.2539 683.25 20.2037 683.25 15.9807Z"
          fill="#00ADEE"
        />
        <path
          d="M175.148 64.3945C171.985 64.3966 168.893 63.4586 166.262 61.6994C163.631 59.9401 161.58 57.4386 160.368 54.5111C159.157 51.5837 158.839 48.362 159.454 45.2534C160.07 42.1448 161.592 39.289 163.828 37.0473C166.064 34.8056 168.913 33.2787 172.015 32.6596C175.116 32.0406 178.332 32.3573 181.254 33.5696C184.176 34.782 186.674 36.8355 188.432 39.4705C190.189 42.1055 191.127 45.2035 191.127 48.3728C191.127 52.6202 189.444 56.6939 186.447 59.6982C183.451 62.7026 179.387 64.3918 175.148 64.3945ZM175.148 35.7359C172.651 35.7339 170.209 36.4742 168.132 37.8631C166.055 39.252 164.435 41.2271 163.479 43.5385C162.522 45.8498 162.272 48.3936 162.758 50.8479C163.245 53.3021 164.447 55.5566 166.213 57.3259C167.979 59.0953 170.229 60.3 172.678 60.7876C175.127 61.2753 177.666 61.024 179.972 60.0655C182.279 59.107 184.25 57.4844 185.636 55.403C187.022 53.3216 187.761 50.875 187.759 48.3728C187.759 46.7133 187.433 45.07 186.799 43.5369C186.165 42.0037 185.236 40.6106 184.065 39.4372C182.894 38.2637 181.504 37.3329 179.974 36.6978C178.444 36.0628 176.804 35.7359 175.148 35.7359Z"
          fill="#EC1066"
        />
        <path
          d="M206.552 63.8818C202.873 63.8825 199.309 62.5972 196.474 60.2473C193.64 57.8975 191.712 54.6303 191.023 51.0091H222.091C221.398 54.6303 219.468 57.8965 216.632 60.2459C213.796 62.5953 210.232 63.881 206.552 63.8818Z"
          fill="#00ADEE"
        />
        <path
          d="M206.552 32.1975C210.232 32.1984 213.796 33.4841 216.632 35.8335C219.468 38.1829 221.398 41.4491 222.091 45.0703H191.023C191.712 41.449 193.64 38.1819 196.474 35.832C199.309 33.4822 202.873 32.1968 206.552 32.1975Z"
          fill="#00ADEE"
        />
        <path
          d="M222.365 48.0342C222.361 49.032 222.269 50.0275 222.088 51.0088H191.021C190.639 49.0477 190.639 47.031 191.021 45.0699H222.088C222.268 46.0478 222.361 47.0398 222.365 48.0342Z"
          fill="#C0CD40"
        />
        <path d="M143.199 32.4229H159.086V64.251H127.281V32.4229H143.199Z" fill="#00ADEE" />
        <path
          d="M147.15 32.4229L159.065 44.352L155.094 48.3318H143.178V60.2712L139.207 64.251L127.281 52.3116V32.4229H147.15Z"
          fill="#065DAA"
        />
        <path d="M159.062 64.251H139.203V44.352H159.062V64.251Z" fill="#EC1066" />
        <path d="M95.7307 1.00461H127.279V64.2197H95.7307H64.1719V32.607V1.00461H95.7307Z" fill="#EC1066" />
        <path d="M95.6668 1.00461L64.1797 64.2197H95.7385H127.267L95.6668 1.00461Z" fill="#C0CD40" />
        <path d="M95.7581 64.2197L111.491 32.607H95.7172H79.9531L95.7581 64.2197Z" fill="#065DAA" />
        <path d="M330.592 52.9478L315.555 60.374L322.945 45.3267L338.024 37.9518L330.592 52.9478Z" fill="#065DAA" />
        <path d="M326.811 43.747L310.914 49.1218L326.811 54.5273L342.698 49.1218L326.811 43.747Z" fill="#065DAA" />
        <path d="M332.2 49.1424L326.826 33.2847L321.391 49.1424L326.826 65L332.2 49.1424Z" fill="#065DAA" />
        <path d="M322.976 52.9478L338.024 60.374L330.623 45.3267L315.555 37.9518L322.976 52.9478Z" fill="#065DAA" />
        <path
          d="M326.81 41.4804C331.05 41.4804 334.487 44.9109 334.487 49.1426C334.487 53.3742 331.05 56.8047 326.81 56.8047C322.57 56.8047 319.133 53.3742 319.133 49.1426C319.133 44.9109 322.57 41.4804 326.81 41.4804Z"
          fill="#C0CD40"
        />
        <path d="M278.669 32.1665H262.69H246.711V48.178V64.1895H262.69H278.669V48.178V32.1665Z" fill="#065DAA" />
        <path d="M278.669 32.1665H262.69H246.711V48.178V64.1895L278.669 32.1665Z" fill="#C0CD40" />
        <path d="M310.653 32.1562H294.674H278.695V48.1677V64.1689H294.674H310.653V48.1677V32.1562Z" fill="#065DAA" />
        <path d="M278.695 64.1689V48.1677V32.1562L294.674 48.1677L278.695 64.1689Z" fill="#00ADEE" />
        <path d="M294.672 48.1677L310.651 32.1562V48.1677V64.1689L294.672 48.1677Z" fill="#00ADEE" />
        <path d="M278.695 32.1565H294.674H310.653L294.674 48.168L278.695 32.1565Z" fill="#EC1066" />
        <path d="M278.694 0.687164H262.992H247.289V16.4217V32.1562H262.992H278.694V16.4217V0.687164Z" fill="#EC1066" />
        <path d="M278.694 0.687164H262.992H247.289V16.4217V32.1562L278.694 0.687164Z" fill="#C0CD40" />
        <path d="M247.243 32.207H215.766V0.666145H247.243V32.207Z" fill="#EC1066" />
        <path
          d="M231.499 0.676401C240.188 0.676401 247.232 7.73477 247.232 16.4417C247.232 25.1487 240.188 32.207 231.499 32.207C222.81 32.207 215.766 25.1487 215.766 16.4417C215.766 7.73477 222.81 0.676401 231.499 0.676401Z"
          fill="#C0CD40"
        />
        <path
          d="M215.75 16.4212C215.75 14.3509 216.157 12.3008 216.948 10.3881C217.738 8.47534 218.897 6.73738 220.358 5.27344C221.819 3.80949 223.554 2.64823 225.462 1.85595C227.371 1.06367 229.417 0.655882 231.483 0.655882V32.207C229.416 32.2057 227.369 31.7963 225.459 31.0023C223.55 30.2082 221.815 29.0451 220.354 27.5792C218.893 26.1134 217.734 24.3735 216.944 22.459C216.155 20.5445 215.749 18.4929 215.75 16.4212Z"
          fill="#00ADEE"
        />
      </svg>
    </div>
  );
};
