import styled from 'styled-components';
import { BREAKPOINTS } from 'utils/constants';

export const WrapperOfIconButton = styled.div<{ vector: 'left' | 'right' }>`
  position: absolute;

  width: 48px;
  height: 48px;
  top: calc(50% - 24px);
  z-index: 1;
`;
