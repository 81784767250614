import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { GlobalCategory, GlobalCategoryCategoryTypeEnum } from 'services';

import { COLORS } from 'utils/constants';

import { H4 } from 'components/base-ui/typo';
import { ArrowButton } from 'components/base-ui/button';
import HtmlContainer from 'components/base-ui/html-container';
import { HtmlRenderer } from 'utils/htmlParser';

const Div = styled.div`
  display: grid;
  overflow: hidden;
  border-radius: 2px;
  grid-auto-rows: 330px 1fr;
  filter: drop-shadow(0px 6px 20px rgba(0, 0, 0, 0.08));
  &:hover {
    cursor: pointer;
  }
`;

const Head = styled.div`
  height: 100%;
  overflow: hidden;
  position: relative;
  filter: drop-shadow(0px 6px 20px rgba(0, 0, 0, 0.08));
`;

const Image = styled.img`
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const Body = styled.div`
  display: grid;
  color: ${COLORS.white};
  grid-template-rows: 1fr auto;
  padding: 20px 32px 30px 32px;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.08);
  background: ${COLORS.linearGradient};
`;

const BodyInfo = styled.div``;

const lineClamp = css`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const Title = styled(H4)`
  ${lineClamp};
  font-weight: normal;
  margin-bottom: 24px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin-bottom: 8px;
  background-color: ${COLORS.white};
`;

const Description = styled(HtmlContainer)`
  display: block;
  margin-bottom: 12px;

  > p {
    ${lineClamp};
    font-size: 16px;
    line-height: 24px;

    &:last-of-type {
      margin: 0;
    }
  }
`;

const Button = styled(ArrowButton)`
  width: 100%;
`;

interface ItemProps {
  data: GlobalCategory;
}

const Item: React.FC<ItemProps> = ({ data }) => {
  const history = useHistory();

  const cardClickHandler = useCallback(
    (ev) => {
      if (data.categoryType === GlobalCategoryCategoryTypeEnum.Medicine) {
        history.push(`market/${data.categoryType}`);
        return;
      }
      const link = `${data.hasBasket ? 'goods' : 'services'}/${data.categoryType}/${data.id}`;
      history.push(link);
    },
    [data.hasBasket, data.categoryType, data.id],
  );

  return (
    <Div onClick={cardClickHandler}>
      <Head>
        <Image src={data.logo || ''} />
      </Head>

      <Body>
        <BodyInfo>
          <Title>{data.name}</Title>

          <Divider />

          <Description>
            <HtmlRenderer html={data.preview || ''} />
          </Description>
        </BodyInfo>

        <Button white>Подробнее</Button>
      </Body>
    </Div>
  );
};

export default Item;
