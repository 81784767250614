import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { api } from 'utils/api';
import { Block } from 'utils/blocks';
import { parseHtml } from 'utils/htmlParser';
import Icon from 'components/base-ui/icon';
import { MagazineArticle as MagazineArticleType } from 'services/api';
import { observer } from 'mobx-react-lite';
import { useProfileStore, useProjectsStore } from 'store';
import { useHistory } from 'react-router-dom';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { head, isNumber } from 'lodash';

import {
  Annotation,
  BannerContent,
  BannerImage,
  BannerImageWrapper,
  BigHeader,
  Button,
  ButtonText,
  Container,
  Content,
  Count,
  Div,
  DoubleImage,
  FavoriteIcon,
  Footer,
  IconsWrapper,
  Image,
  LikeWrapper,
  PdfIcon,
  PrintIcon,
  QuoteContent,
  QuoteWrapper,
  SmallHeader,
  ViewIcon,
  WaterMark,
} from './styles';
import { ArticleProps, BigHeaderType, ImageType } from './types';
import { IconLikeWithTooltip } from '../../base-ui/icon-like-with-tooltip';
import { useDeviceType } from '../../../utils/hooks';
import { LikeModalContent } from '../../base-ui/like-modal-content';
import { LikeModal } from '../../base-ui/like-modal';
import { IconFavorite } from '../../base-ui/icon-favorite';

// eslint-disable-next-line sonarjs/cognitive-complexity
export const MagazineArticle: React.FC<ArticleProps> = observer((props) => {
  const store = useProjectsStore();
  const profileStore = useProfileStore();
  const history = useHistory();
  const [data, setData] = useState<MagazineArticleType | null>(null);
  const [content, setContent] = useState<Block[]>([]);
  const [showLikeModal, setShowLikeModal] = React.useState(false);
  const device = useDeviceType();

  const { trackPageView } = useMatomo();

  const likesLimit = React.useMemo(() => {
    if (device === 'mobile') return 70;
    return 40;
  }, [device]);

  useEffect(() => {
    api.projects.projectsMagazinesArticlesRead({ id: props.articleId }).then((res) => {
      setData(res.data);
      setContent(res.data.content as unknown as Block[]);
      document.title = `${
        head(store.articles?.filter((article) => article.id === props.articleId))?.header || 'Статья журнала'
      }`;
      trackPageView({});
    });
  }, [store.articles]);

  const bigHeaderData = useMemo(() => {
    return content.filter((item) => item.type === 'banner').map((item) => item.value as unknown as BigHeaderType)[0];
  }, [content]);

  const articleContentTop = useMemo(() => {
    return content.filter((item) => item.type === 'text').map((item) => item.value as string)[0];
  }, [content]);

  const articleContentMid = useMemo(() => {
    return content.filter((item) => item.type === 'text').map((item) => item.value as string)[1];
  }, [content]);

  const articleContentBottom = useMemo(() => {
    return content.filter((item) => item.type === 'text').map((item) => item.value as string)[2];
  }, [content]);

  const quoteData = useMemo(() => {
    return content.filter((item) => item.type === 'big_header').map((item) => item.value)[0];
  }, [content]);

  const doubleImageData = useMemo(() => {
    const images = content
      .filter((item) => item.type === 'image' && Boolean(item.value?.image))
      .map((item) => item.value as unknown as ImageType);
    if (images.length < 2) return null;
    return { firstImage: images[0]?.image, secondImage: images[1]?.image };
  }, [content]);

  const onPrint = () => window.print();

  const onLikeClick = useCallback(() => {
    if (data?.id) {
      if (data.isLikedByUser) {
        store.handleLikeDelete(data.id, profileStore.profile?.id!);
      } else {
        store.handleLikePost(data.id, profileStore.profile);
      }
    }
  }, [data?.id, data?.isLikedByUser]);

  const onFavoriteClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      if (data?.id) {
        if (data.isInUserFavorites) {
          store.handleFavoriteDelete(data.id);
        } else {
          store.handleFavoritePost(data.id);
        }
      }
    },
    [data?.id, data?.isInUserFavorites],
  );

  const onBackClick = useCallback(
    (ev: React.MouseEvent<HTMLButtonElement>) => {
      ev.stopPropagation();
      history.push(`/projects/magazines/${data?.magazine.id || 0}`);
    },
    [data?.id],
  );

  const onDownloadPdf = useCallback(() => {
    if (!data?.pdf) return;

    window.open(data.pdf);
  }, [data?.magazine.pdfFile]);

  const tooltipOffset = React.useMemo(() => {
    if (data?.likesCount && data.likesCount > 6) {
      return {
        left: '-140px',
        leftMobile: '0',
        arrowLeft: '235px',
        arrowLeftMobile: '130px',
      };
    }

    if (data?.likesCount && data.likesCount > 4) {
      return {
        left: '-110px',
        leftMobile: '0',
        arrowLeft: '200px',
        arrowLeftMobile: '120px',
      };
    }

    return {
      left: '-20px',
      leftMobile: '0',
      arrowLeft: '110px',
      arrowLeftMobile: '110px',
    };
  }, [data?.likesCount]);

  return (
    <Container>
      <BannerImageWrapper>
        <BannerImage src={bigHeaderData?.image} />
      </BannerImageWrapper>
      <BannerContent>
        <BigHeader>{bigHeaderData?.header}</BigHeader>
        <SmallHeader>{bigHeaderData?.author}</SmallHeader>
      </BannerContent>
      <Annotation>{bigHeaderData?.description}</Annotation>
      <Content>{parseHtml(articleContentTop)}</Content>
      {doubleImageData && (
        <DoubleImage>
          <Image src={doubleImageData?.firstImage} />
          <Image src={doubleImageData?.secondImage} />
        </DoubleImage>
      )}
      <Content>{parseHtml(articleContentMid)}</Content>
      {quoteData && (
        <QuoteWrapper>
          <WaterMark>“</WaterMark>
          <QuoteContent>{quoteData}</QuoteContent>
        </QuoteWrapper>
      )}
      <Content>{parseHtml(articleContentBottom)}</Content>
      <Footer>
        <Button onClick={onBackClick}>
          <ButtonText>Все статьи</ButtonText>
        </Button>
        <IconsWrapper>
          <Div>
            <ViewIcon>
              <Icon name="iconViewsGrey" />
            </ViewIcon>
            <Count>{data?.viewsCount || 0}</Count>
          </Div>
          <LikeWrapper>
            {isNumber(data?.likesCount) && (
              <IconLikeWithTooltip
                isLiked={!!data?.isLikedByUser}
                onClick={onLikeClick}
                likedUsers={data?.likeAuthors || []}
                likesCount={data?.likesCount || 0}
                newsId={data?.id!}
                onShowModal={() => setShowLikeModal(true)}
                left={tooltipOffset.left}
                leftMobile={tooltipOffset.leftMobile}
                arrowLeft={tooltipOffset.arrowLeft}
                arrowLeftMobile={tooltipOffset.arrowLeftMobile}
              />
            )}
          </LikeWrapper>

          <FavoriteIcon onClick={onFavoriteClick}>
            <IconFavorite active={!!data?.isInUserFavorites} />
            <span>{data?.isInUserFavorites ? 'Удалить из избранного' : 'Добавить в избранное'}</span>
          </FavoriteIcon>

          <PrintIcon isNoLasElement={Boolean(data?.pdf)} onClick={onPrint}>
            <Icon name="iconPrintSolid" />
            {device !== 'mobile' && device !== 'tablet' && <span>Распечатать</span>}
          </PrintIcon>
          {data?.pdf && (
            <Div>
              <PdfIcon onClick={onDownloadPdf}>
                <Icon name="iconPdfGray" />
                {device !== 'mobile' && device !== 'tablet' && <span>Скачать</span>}
              </PdfIcon>
            </Div>
          )}
        </IconsWrapper>
      </Footer>

      <LikeModal visible={showLikeModal} onClose={() => setShowLikeModal(false)}>
        <LikeModalContent cardType="article" id={props.articleId} limitPerPage={likesLimit} />
      </LikeModal>
    </Container>
  );
});
