import React, { createContext, useContext } from 'react';

import GuideStore from './store';

const store = new GuideStore();

const GuideStateContext = createContext<GuideStore>(store);

export const GuideStateProvider: React.FC = ({ children }) => (
  <GuideStateContext.Provider value={store}>{children}</GuideStateContext.Provider>
);

export const useGuideStore = (): GuideStore => useContext(GuideStateContext);
