import React from 'react';
import { Image, Wrapper } from './styled';

type Props = {
  imageLink: string;
};

export const CreatorsImageBlock: React.FC<Props> = ({ imageLink }) => {
  return (
    <Wrapper>
      <Image src={imageLink} />
    </Wrapper>
  );
};
