import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { BREAKPOINTS } from 'utils/constants';

interface WrapperProps {
  withInfo: boolean;
  type: 'modal' | 'winnerCard';
  inResponsiveBox?: boolean;
}
type Types = Record<WrapperProps['type'], FlattenSimpleInterpolation>;

const winnerCardStyles = css`
  border-radius: 0;
  width: 100%;
  height: 100%;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    height: 609px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: 466px;
  }
`;

const modalStyles = css`
  width: 248px;
  height: 200px;

  border-radius: 8px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 480px;
    height: 480px;
  }
`;

const typesMap: Types = {
  modal: modalStyles,
  winnerCard: winnerCardStyles,
};

const Wrapper = styled.div<WrapperProps>`
  ${(props) => typesMap[props.type]}
  position: ${(props) => (props.inResponsiveBox ? 'static' : 'relative')};
  display: flex;
  flex-direction: column;

  overflow: hidden;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  ${(props) =>
    props.withInfo &&
    css`
      ::before {
        content: ' ';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 74.96%, rgba(0, 0, 0, 0.8) 100%);
      }
    `}
`;

const Title = styled.span`
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  word-wrap: break-word;

  color: #ffffff;
`;

const Subtitle = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  word-wrap: break-word;

  color: #d9dbdf;
`;

const Info = styled.span<{ withDots: boolean; type: 'modal' | 'winnerCard' }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  max-width: 100%;

  gap: 4px;
  bottom: ${(props) => (props.withDots ? 32 : 14)}px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    bottom: ${(props) => (props.withDots ? 49 : 24)}px;
  }

  padding: 0 16px;

  ${(props) =>
    props.type === 'modal' &&
    css`
      opacity: 0;
      @media screen and (min-width: ${BREAKPOINTS.tablet}) {
        opacity: 1;
      }
    `}
`;

const Box = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  ::before {
    content: '';
    display: block;
    padding-top: 100%;
  }
`;
const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

export const Styled = { Title, Wrapper, Subtitle, Info, Box, Content };
