import React from 'react';
import { ParticipantItems } from 'services';
import AnimateHeight from 'react-animate-height';
import { useCollapse } from 'components/assol-winners/WinnersAndNominees/hooks/';
import { Participant, TextSize } from 'components/assol-winners/WinnersAndNominees/ui/shared';
import { Styled } from './styles';

export const Participants: React.FC<{
  participants: ParticipantItems[];
  height?: number;
  collapsable?: boolean;
}> = (props) => {
  const { expanded, toggleExpanded } = useCollapse(Boolean(props.collapsable));

  return (
    <Styled.Container height={props.height}>
      <Styled.Header collapsable={props.collapsable} expanded={expanded} onClick={toggleExpanded}>
        <Styled.Subtitle>Состав команды:</Styled.Subtitle>
        {props.collapsable && <Styled.IconDown className={expanded ? 'expanded' : 'collapsed'} />}
      </Styled.Header>

      <Styled.Participants>
        <AnimateHeight defaultValue={0} duration={500} height={expanded ? 'auto' : 0}>
          {props.participants.map((participant) => (
            <Participant
              size={TextSize.Small}
              key={`${participant.id}`}
              name={participant.name}
              company={participant.company?.name}
            />
          ))}
        </AnimateHeight>
      </Styled.Participants>
    </Styled.Container>
  );
};
