import React from 'react';
import { AboutOurPeopleElement } from 'web';
import { HtmlRenderer } from 'utils/htmlParser';
import { VideoBox } from 'components/base-ui/video-box';
import { useHistory } from 'react-router-dom';
import { StHeader, StImageWrapper, StPlayIcon, StSmallElement, StText } from './styled';

interface Props {
  element: AboutOurPeopleElement;
}

export const SmallElement: React.FC<Props> = (props) => {
  const history = useHistory();

  const [showVideo, setShowVideo] = React.useState(false);

  const toggleShowVideo = React.useCallback(() => setShowVideo(!showVideo), [showVideo]);

  const onGoToArticle = React.useCallback(() => {
    if (props.element.blogPage) {
      history.push(`/our-people-article/${props.element.blogPage}`);
    } else if (props.element.journalPage) {
      history.push(`/magazine-article/${props.element.journalPage}`);
    }
  }, [history]);

  return (
    <>
      <StSmallElement>
        <StImageWrapper
          bg={props.element.contentType === 'video' ? props.element.video?.preview! : props.element.image!}
          onClick={props.element.contentType === 'image' ? onGoToArticle : toggleShowVideo}
          showPointer={props.element.contentType === 'video' || !!props.element.blogPage || !!props.element.journalPage}
        >
          {props.element.contentType === 'video' && <StPlayIcon name="playCircle" />}
        </StImageWrapper>
        <StHeader>{props.element.header}</StHeader>
        <StText>
          <HtmlRenderer html={props.element.text} />
        </StText>
      </StSmallElement>
      {props.element.contentType === 'video' && props.element.video && (
        <VideoBox
          video={props.element.video}
          videos={[props.element.video]}
          onClose={toggleShowVideo}
          isLightBoxOpen={showVideo}
        />
      )}
    </>
  );
};
