import styled, { css } from 'styled-components';

import { COLORS } from 'utils/constants';

import iconArrow from 'assets/icons/icon_arrow.svg';

const shared = css`
  cursor: pointer;
  width: 100%;
  min-width: 160px;
  padding: 11px 16px;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  position: relative;
  text-transform: uppercase;
  transition: all 0.2s ease-out;

  &:disabled {
    cursor: not-allowed;
  }
`;

const border = css`
  border-radius: 2px;
  background-color: transparent;

  color: ${COLORS.secondary};
  border: 1px solid ${COLORS.secondary};
`;

export const OutlineButton = styled.button`
  ${shared};
  ${border};

  &:hover,
  &:focus,
  &:active {
    color: ${COLORS.white};
    border-color: ${COLORS.primary};
    background-color: ${COLORS.primary};
  }

  &:active {
    color: rgba(255, 255, 255, 0.4);
  }

  &:disabled {
    pointer-events: none;
    color: ${COLORS.gray5};
    border-color: ${COLORS.gray5};
  }
`;

interface ArrowButtonProps {
  white?: boolean;
  red?: boolean;
  gradient?: boolean;
  borderless?: boolean;
}

type AdjustableButtonProps = ArrowButtonProps & {
  width?: string;
};

const whiteStyles = css`
  color: ${COLORS.white};
  border-color: ${COLORS.white};

  &:before {
    filter: brightness(0) invert(1);
  }
`;

const redStyles = css`
  color: ${COLORS.red};
  border-color: ${COLORS.red};

  &:before {
    filter: grayscale(100%) brightness(80%) sepia(100%) hue-rotate(-50deg) saturate(600%) contrast(0.8);
  }
`;

const gradientStyles = css`
  background: ${COLORS.linearGradient};
`;

const borderlessStyles = css`
  border: none;
`;

export const ArrowButton = styled.button<ArrowButtonProps>`
  ${shared};
  ${border};
  z-index: 9999;

  text-align: start;
  padding-right: 52px;

  &:before {
    content: '';
    top: 50%;
    right: 16px;
    min-width: 24px;
    height: 22px;
    position: absolute;
    transform: translateY(-50%);
    transition: all 0.2s ease-out;
    background-image: url(${iconArrow});
    z-index: 9;
  }

  &:disabled {
    opacity: 0.4;
  }

  ${(props) => props.red && redStyles};
  ${(props) => props.white && whiteStyles};
  ${(props) => props.gradient && gradientStyles};
  ${(props) => props.borderless && borderlessStyles};
`;

export const TextButton = styled.button`
  ${shared};

  padding: 0;
  width: auto;
  min-width: unset;
  border: none;
  text-transform: none;
  background: transparent;
`;

export const AdjustableButton = styled.button<AdjustableButtonProps>`
  ${shared};
  ${border};
  width: ${(props) => (props.width ? props.width : 'auto')};
  text-align: center;

  &:disabled {
    opacity: 0.4;
  }

  ${(props) => props.red && redStyles};
  ${(props) => props.white && whiteStyles};
  ${(props) => props.gradient && gradientStyles};
  ${(props) => props.borderless && borderlessStyles};
`;
