import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';

export const StButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    justify-content: flex-start;
  }
`;

export const StButton = styled.button`
  width: 288px;
  height: 48px;
  background: ${COLORS.linearGradient};
  color: ${COLORS.white};
  border: none;
  border-radius: 4px;
  font-size: 14px !important;
  text-transform: uppercase !important;
  cursor: pointer;

  :hover {
    background: linear-gradient(105deg, ${COLORS.primary}, ${COLORS.primary});
  }

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 256px;
  }
`;
