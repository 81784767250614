import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';

interface OuterProps {
  src: string;
}

interface InnerProps {
  active?: boolean;
}

const offsetX = '-65px';
const bgWidth = '983.5px';
const bgGradient = COLORS.linearGradient;
const maxBannerHeight = '784px';

export const StContainer = styled.div``;

export const BannerWrapper = styled.div`
  height: 500px;
  position: relative;
  color: ${COLORS.white};
  margin-top: 56px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    height: ${maxBannerHeight};
  }
`;
export const BannerOuter = styled.div<OuterProps>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: inherit;
  background-size: cover;
  background-image: url(${(props) => props.src});
  background-position: top center;
  transition: background-image 0.2s ease-out;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    background-position: center;
  }
`;
export const BannerInner = styled.div<InnerProps>`
  position: absolute;
  top: 0;
  left: 0;
  height: inherit;
  width: 100vw;

  &:after {
    content: '';
    top: 0;
    height: inherit;
    width: ${bgWidth};
    position: absolute;
    mix-blend-mode: multiply;
    transform: skewX(-6deg);
    background: ${bgGradient};
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: top 0 left 0;
    left: ${(props) => (props.active ? '-91px' : 'unset')};
    right: ${(props) => (props.active ? 'unset' : '35px')};

    @media screen and (min-width: ${BREAKPOINTS.tablet}) {
      right: unset;
      left: ${(props) => (props.active ? '-91px' : '-436px')};
    }

    @media screen and (min-width: ${BREAKPOINTS.laptop}) {
      left: ${offsetX};
    }
  }
`;

export const BannerNext = styled.div`
  top: 0;
  right: 0;
  z-index: 10;
  display: none;
  position: absolute;
  mix-blend-mode: multiply;
  background: ${bgGradient};

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 56px;
    display: block;
    height: ${maxBannerHeight};
  }

  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    width: 136px;
    height: ${maxBannerHeight};
  }
`;

export const BannerContent = styled.div`
  font-size: 14px;
  line-height: 20px;
  z-index: 5;
  width: 100%;
  height: inherit;
  padding: 156px 0 0 15px;
  a {
    text-decoration: none;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: ${COLORS.white};
    :hover {
      text-decoration: underline;
    }
  }
  p {
    width: 625px;
  }

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 162px 0 0 56px;
    p {
      width: 384px;
    }
  }

  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    padding: 193px 0 0 136px;
    p {
      width: 624px;
    }
  }
`;

export const BannerTitle = styled.div`
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  padding-bottom: 19px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-size: 44px;
    line-height: 48px;
  }
`;
