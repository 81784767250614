import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { usePortal } from 'hooks/usePortal';
import { Styled } from './styles';

interface BaseModalProps {
  isVisible: boolean;
  onClose: () => void;
  centered?: boolean;
  handleOnPlayerMouseOut?: () => void;
}

export const ModalWrapper: React.FC<BaseModalProps> = (props) => {
  const { isVisible, onClose, centered } = props;
  const [scrollBarWidth, setScrollBarWidth] = useState(0);

  const portalRef = usePortal('player-modal');

  useEffect(() => {
    if (!isVisible) return;
    const width = window.innerWidth - document.documentElement.clientWidth;
    document.body.style.overflowY = isVisible ? 'hidden' : 'visible';
    document.body.style.width = `calc(100% - ${width}px)`;
    setScrollBarWidth(width);
    return () => {
      document.body.style.width = 'unset';
      document.body.style.overflowY = 'visible';
    };
  }, [isVisible]);

  return isVisible
    ? portalRef &&
        ReactDOM.createPortal(
          <Styled.ModalOverflow padding={scrollBarWidth}>
            <Styled.ModalMask onClick={onClose} onMouseOver={props.handleOnPlayerMouseOut} />
            <Styled.ModalContainer centered={centered}>
              <Styled.ModalWindow>{props.children}</Styled.ModalWindow>
            </Styled.ModalContainer>
          </Styled.ModalOverflow>,
          portalRef,
        )
    : null;
};
