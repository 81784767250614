import React from 'react';

import { DefaultLayout } from '../../layouts';

import CartComponent from '../../components/cart';
import ScrollToTopOnMount from '../../components/base-ui/scroll-to-top';

const CartPage: React.FC = () => {
  document.title = 'Корзина';

  return (
    <>
      <ScrollToTopOnMount />

      <DefaultLayout>
        <CartComponent />
      </DefaultLayout>
    </>
  );
};

export default CartPage;
