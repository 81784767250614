import React, { useMemo } from 'react';
import styled from 'styled-components';
import { FormProvider, useForm } from 'react-hook-form';
import { H1 } from 'components/base-ui/typo';
import { OutlineButton } from 'components/base-ui/button';
import { COLORS } from 'utils/constants';
import { DynamicApplication } from 'services';
import { HtmlRenderer } from 'utils/htmlParser';
import * as styles from 'styles/text';
import { RenderProps, WidgetType } from './types';
import { CharBockWidget } from './widgets/charBockWidget';
import { TextBlockWidget } from './widgets/textBlockWidget';
import { ChoiceWidget } from './widgets/choiceWidget';
import { DateWidget } from './widgets/dateWidget';
import { TimeWidget } from './widgets/timeWidget';
import { NumberBockWidget } from './widgets/numberBockWidget';
import { EmailWidget } from './widgets/emailWidget';
import { PhoneNumberWidget } from './widgets/phoneNumberWidget';
import { TourDateRangeWidget } from './widgets/tourDateRangeWidget';

const FormField = styled.div``;

const FieldsContainer = styled.div`
  margin-top: 28px;
  display: grid;
  grid-row-gap: 12px;
  width: 100%;
`;

const Title = styled(H1)`
  text-align: center;
`;

const SubmitButton = styled(OutlineButton)`
  color: ${COLORS.gray5};
  border-color: ${COLORS.primary};
  width: auto;
  display: block;
  margin: 22px auto 0;
`;

export const ErrorFormMessage = styled.div`
  margin-top: 5px;
  color: ${COLORS.red};
  font-size: 14px;
`;

const SuccessFragment = styled.div`
  text-align: center;
  max-width: 320px;
  margin: 0 auto;
  color: ${COLORS.white};
  h1 {
    ${styles.h1Styles};
    margin: 0 auto;
  }
  p {
    line-height: 24px;
    margin: 12px 0 0 0;
  }
  img {
    width: 100%;
    max-width: 338px;
  }
`;

export const ApplicationForm: React.FC<RenderProps> = (props) => {
  const { fields, title, successState, onSubmitForm, successHtmlFragment } = props;

  const methods = useForm();

  const onSubmit = (data: React.FormEvent<HTMLFormElement>) => {
    onSubmitForm(data as unknown as DynamicApplication);
  };

  const fieldsList = useMemo(() => {
    if (!fields) return;
    return fields.map((elem) => {
      switch (elem.type) {
        case WidgetType.TextBlock:
          return (
            <FormField>
              <TextBlockWidget values={elem.value} />
            </FormField>
          );
        case WidgetType.NumberBlock:
          return (
            <FormField>
              <NumberBockWidget values={elem.value} />
            </FormField>
          );
        case WidgetType.Phone:
          return (
            <FormField>
              <PhoneNumberWidget values={elem.value} />
            </FormField>
          );
        case WidgetType.Email:
          return (
            <FormField>
              <EmailWidget values={elem.value} />
            </FormField>
          );
        case WidgetType.Choice:
          return (
            <FormField>
              <ChoiceWidget values={elem.value} />
            </FormField>
          );
        case WidgetType.Date:
          return (
            <FormField>
              <DateWidget values={elem.value} formTitle={title} />
            </FormField>
          );
        case WidgetType.Time:
          return (
            <FormField>
              <TimeWidget values={elem.value} formTitle={title} />
            </FormField>
          );
        case WidgetType.TourDateRange:
          return (
            <FormField>
              <TourDateRangeWidget values={elem.value} />
            </FormField>
          );
        case WidgetType.CharBlock:
        case WidgetType.Patronymic:
        case WidgetType.LastName:
        case WidgetType.FirstName:
        default:
          return (
            <FormField>
              <CharBockWidget values={elem.value} />
            </FormField>
          );
      }
    });
  }, [fields, WidgetType]);

  return (
    <>
      {!successState && (
        <>
          <Title>{title}</Title>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <FieldsContainer>{fieldsList}</FieldsContainer>
              <SubmitButton type="submit" disabled={props.loading}>
                {props.loading ? 'Отправка...' : 'Отправить'}
              </SubmitButton>
            </form>
          </FormProvider>
        </>
      )}
      {successState && (
        <SuccessFragment>
          <HtmlRenderer html={successHtmlFragment || ''} />
        </SuccessFragment>
      )}
    </>
  );
};
