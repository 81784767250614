import styled, { css } from 'styled-components';

import RSlider from 'components/base-ui/slider';
import { BREAKPOINTS, COLORS } from 'utils/constants';

const Container = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  .player {
    margin-bottom: -4px;
  }
`;

const ControlContainer = styled.div`
  position: absolute;
  bottom: -1px;
  left: -1px;
  right: -1px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 5;

  visibility: hidden;

  padding: 0 2px;

  &.visible {
    visibility: visible;
  }
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 12px;
  }
`;

const Slider = styled(RSlider)<{ width?: string }>`
  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `};

  height: 16px;

  .thumb {
    background: ${COLORS.white};
    outline: none;
    top: 4px;
    height: 8px;
    width: 8px;
  }

  .track {
    margin: 0;
    cursor: pointer;
    height: 2px;
  }

  .track-0 {
    background: ${COLORS.white};
    outline: none;
  }

  .track-1 {
    background: ${COLORS.gray3};
    outline: none;
    opacity: 0.7;
  }

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    .track {
      margin: 0 4px;
      height: 4px;
    }
    .thumb {
      top: 0;
      width: 16px;
      height: 16px;
    }
  }
`;

export const IconButton = styled.button<{ size?: number; circle?: boolean; background?: string; padding?: number }>`
  cursor: pointer;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: unset;
  padding: ${(props) => props.padding ?? 0}px;
  margin: 0;
  transition: visibility 0s;

  & svg {
    width: ${(props) => props.size ?? 20}px;
    height: ${(props) => props.size ?? 20}px;
    fill: ${COLORS.white};

    @media screen and (min-width: ${BREAKPOINTS.tablet}) {
      width: ${(props) => props.size ?? 24}px;
      height: ${(props) => props.size ?? 24}px;
    }
  }

  &:disabled {
    cursor: default;
    pointer-events: none;
  }

  &:focus {
    outline: none;
  }
`;

const Preview = styled.img`
  width: 100%;
`;

const DurationContainer = styled.div`
  gap: 4px;
  display: flex;
  align-items: center;

  font-weight: 400;
  color: ${COLORS.white};
  font-size: 12px;
  line-height: 16px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-size: 16px;
    line-height: 24px;
  }
`;

const CloseButtonContainer = styled.div`
  top: 0;
  right: 0;
  width: 44px;
  height: 44px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: rgba(35, 35, 35, 0.65);
`;

const BottomLineContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px 8px 10px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 0;
  }
`;

const IphoneFix = styled.div`
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
`;

export const Styled = {
  Container,
  ControlContainer,
  Slider,
  IconButton,
  Preview,
  DurationContainer,
  CloseButtonContainer,
  BottomLineContainer,
  IphoneFix,
};
