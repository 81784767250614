import styled from 'styled-components';
import { BREAKPOINTS } from 'utils/constants';
import { H1 } from 'components/base-ui/typo';

const Description = styled.div`
  display: none;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    display: block;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    margin-bottom: 32px;
  }
`;

const Div = styled.div`
  height: 100%;
  display: grid;
  padding: 168px 34px 108px 16px;
  grid-template-rows: repeat(2, min-content);

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 168px 43.5px 207.5px 56px;
    max-width: calc(447px + 43.5px + 56px);
    grid-template-rows: repeat(3, min-content);
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding: 248px 80px 207.5px 136px;
    max-width: calc(768px + 80px + 136px);
  }
`;

const Head = styled.div`
  margin-bottom: 16px;
`;

const Title = styled(H1)`
  & p {
    margin: 0 0 0.2em 0;
  }
`;

const Body = styled.div`
  max-width: 320px;
  margin: 24px 0;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    max-width: 320px;
    margin-bottom: 24px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    max-width: 656px;
    margin-bottom: 32px;
  }
`;

export const Styled = { Div, Body, Head, Title, Description };
