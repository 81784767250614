import React, { useMemo } from 'react';

type Props = {
  index: number;
  count: number;
};

const svgVariant1 = (
  <svg width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M17.5 0C14.1377 0 10.8509 0.99703 8.05531 2.86501C5.25967 4.733 3.08074 7.38799 1.79405 10.4943C0.507362 13.6007 0.17071 17.0188 0.826659 20.3165C1.48261 23.6142 3.10169 26.6433 5.47918 29.0208C7.85668 31.3983 10.8858 33.0174 14.1835 33.6734C17.4812 34.3293 20.8993 33.9926 24.0056 32.7059C27.112 31.4192 29.767 29.2403 31.635 26.4447C33.503 23.6491 34.5 20.3622 34.5 17C34.4992 12.4915 32.7079 8.16799 29.52 4.98004C26.332 1.79209 22.0085 0.000772456 17.5 0ZM17.5 30.4222C14.8453 30.4222 12.2503 29.6351 10.043 28.1602C7.83573 26.6853 6.11536 24.5891 5.09946 22.1365C4.08356 19.6839 3.81774 16.9851 4.33564 14.3814C4.85354 11.7777 6.1319 9.38611 8.00904 7.50897C9.88618 5.63183 12.2778 4.35354 14.8815 3.83564C17.4851 3.31774 20.1839 3.58353 22.6365 4.59943C25.0891 5.61533 27.1854 7.3357 28.6602 9.54298C30.1351 11.7503 30.9223 14.3453 30.9223 17C30.9223 20.5598 29.5082 23.9738 26.991 26.4909C24.4738 29.0081 21.0598 30.4222 17.5 30.4222Z"
      fill="#12519A"
    />
    <path
      // eslint-disable-next-line max-len
      d="M17.5 26C22.4706 26 26.5 21.9705 26.5 17C26.5 12.0294 22.4706 8 17.5 8C12.5294 8 8.5 12.0294 8.5 17C8.5 21.9705 12.5294 26 17.5 26Z"
      fill="#D12B5E"
    />
  </svg>
);

const svgVariant2 = (
  <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M17 0C13.6377 0 10.3509 0.99703 7.55531 2.86501C4.75967 4.733 2.58074 7.38799 1.29405 10.4943C0.00736243 13.6007 -0.32929 17.0188 0.326659 20.3165C0.982607 23.6142 2.60169 26.6433 4.97918 29.0208C7.35668 31.3983 10.3858 33.0174 13.6835 33.6734C16.9812 34.3293 20.3993 33.9926 23.5056 32.7059C26.612 31.4192 29.267 29.2403 31.135 26.4447C33.003 23.6491 34 20.3622 34 17C33.9992 12.4915 32.2079 8.16799 29.02 4.98004C25.832 1.79209 21.5085 0.000772456 17 0ZM17 30.4222C14.3453 30.4222 11.7503 29.6351 9.54301 28.1602C7.33573 26.6853 5.61536 24.5891 4.59946 22.1365C3.58356 19.6839 3.31774 16.9851 3.83564 14.3814C4.35354 11.7777 5.6319 9.38611 7.50904 7.50897C9.38618 5.63183 11.7778 4.35354 14.3815 3.83564C16.9851 3.31774 19.6839 3.58353 22.1365 4.59943C24.5891 5.61533 26.6854 7.3357 28.1602 9.54298C29.6351 11.7503 30.4223 14.3453 30.4223 17C30.4223 20.5598 29.0082 23.9738 26.491 26.4909C23.9738 29.0081 20.5598 30.4222 17 30.4222Z"
      fill="#C0CD40"
    />
    <path
      // eslint-disable-next-line max-len
      d="M17 26C21.9706 26 26 21.9705 26 17C26 12.0294 21.9706 8 17 8C12.0294 8 8 12.0294 8 17C8 21.9705 12.0294 26 17 26Z"
      fill="#D12B5E"
    />
  </svg>
);

const svgVariant3 = (
  <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M17 0C13.6377 0 10.3509 0.99703 7.55531 2.86501C4.75967 4.733 2.58074 7.38799 1.29405 10.4943C0.00736243 13.6007 -0.32929 17.0188 0.326659 20.3165C0.982607 23.6142 2.60169 26.6433 4.97918 29.0208C7.35668 31.3983 10.3858 33.0174 13.6835 33.6734C16.9812 34.3293 20.3993 33.9926 23.5056 32.7059C26.612 31.4192 29.267 29.2403 31.135 26.4447C33.003 23.6491 34 20.3622 34 17C33.9992 12.4915 32.2079 8.16799 29.02 4.98004C25.832 1.79209 21.5085 0.000772456 17 0ZM17 30.4222C14.3453 30.4222 11.7503 29.6351 9.54301 28.1602C7.33573 26.6853 5.61536 24.5891 4.59946 22.1365C3.58356 19.6839 3.31774 16.9851 3.83564 14.3814C4.35354 11.7777 5.6319 9.38611 7.50904 7.50897C9.38618 5.63183 11.7778 4.35354 14.3815 3.83564C16.9851 3.31774 19.6839 3.58353 22.1365 4.59943C24.5891 5.61533 26.6854 7.3357 28.1602 9.54298C29.6351 11.7503 30.4223 14.3453 30.4223 17C30.4223 20.5598 29.0082 23.9738 26.491 26.4909C23.9738 29.0081 20.5598 30.4222 17 30.4222Z"
      fill="#009CDD"
    />
    <path
      // eslint-disable-next-line max-len
      d="M17 26C21.9706 26 26 21.9705 26 17C26 12.0294 21.9706 8 17 8C12.0294 8 8 12.0294 8 17C8 21.9705 12.0294 26 17 26Z"
      fill="#D12B5E"
    />
  </svg>
);

export const HeaderIcon: React.FC<Props> = ({ index, count }) => {
  const svg = useMemo(() => {
    for (let i = 0; i <= count; i += 3) {
      if (index === i) return svgVariant1;
      if (index === i - 1) return svgVariant3;
    }
    return svgVariant2;
  }, [index, count]);

  return <div style={{ width: '34px', height: '34px' }}>{svg}</div>;
};
