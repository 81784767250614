import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 50px;
  padding: 70px 16px 50px 16px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    align-items: flex-start;
    padding: 50px 32px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 150px;
    padding: 50px 125px;
  }
`;

export const Header = styled.div`
  font-size: 28px;
  line-height: 32px;
  font-weight: 700;
  color: ${COLORS.secondary};

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-size: 44px;
    line-height: 48px;
  }
`;

export const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 50px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    column-gap: 99px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: unset;
    justify-content: flex-start;
    column-gap: 150px;
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
`;

export const Number = styled.div<{ color: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 99px;
  height: 99px;
  border-radius: 50px;
  background-color: ${(props) => props.color};
  font-size: 28px;
  line-height: 32px;
  font-weight: 700;
  color: ${COLORS.white};

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-size: 32px;
    line-height: 48px;
  }
`;

export const Text = styled.div`
  font-size: 20px;
  line-height: 26px;
  font-weight: 400;
  color: ${COLORS.secondary};
`;
