import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';

export const Div = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 250px;
  transition: all 0.3s ease-out;

  &.hidden {
    margin-top: 48px;
  }

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin-top: 179px;
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    margin-top: 134px;
  }
`;

export const DivChild = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.5s ease-out;
`;

export const BrandWrapper = styled.div`
  transition: all 0.3s ease-out;

  &.hidden {
    height: 0;
    margin-bottom: 60px;

    > img {
      height: 35px;
      width: 220px;
    }
  }

  height: 70px;
  margin-bottom: 33px;

  > img {
    height: 44px;
    width: 271px;
  }

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    height: 71px;
    margin-bottom: 33px;

    > img {
      height: 100%;
      width: auto;
    }
  }
`;

export const DividerWrapper = styled.div`
  transition: all 0.3s ease-out;

  > svg {
    width: 164px;
  }

  &.hidden {
    margin-bottom: 20px;
    > svg {
      width: 164px;
    }
  }

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 261px;
    margin-bottom: 22px;
    > svg {
      height: auto;
      width: 100%;
    }
  }
`;
export const HeaderWrapper = styled.div`
  height: 100%;
  margin-top: 40px;
  margin-bottom: 24px;
  color: ${COLORS.white};
  text-transform: uppercase;
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    margin-top: 100px;
  }
`;

export const Container = styled.div`
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    min-height: 500px;
  }
`;
