import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useCartStore, usePortalDirectionsStore, useProfileStore } from 'store';
import { useDeviceType } from 'utils/hooks';
import { authService } from 'utils/authentication';
import Badge from 'components/base-ui/badge';
import Brand from 'components/base-ui/brand';
import IconButton from 'components/base-ui/icon-button';
import { action } from 'mobx';
import { Content, Left, BrandLink, Nav, Item, ProfileLink, Profile, Right, BadgeWrapper } from './styled';
import { NavMenu } from './components/nav-menu';

interface PanelProps {
  onClick: () => void;
}

const Panel: React.FC<PanelProps> = observer((props) => {
  const history = useHistory();
  const device = useDeviceType();
  const profileStore = useProfileStore();
  const cartStore = useCartStore();
  const store = usePortalDirectionsStore();

  useEffect(
    action(() => {
      profileStore.getProfile();
    }),
    [profileStore],
  );

  useEffect(() => {
    cartStore.fetchCartCount();
  }, [cartStore]);

  const profileName = useMemo(() => profileStore.profile?.firstName ?? 'Профиль', [profileStore.profile]);

  const onProfileClick = useCallback(() => history.push('/profile'), [history]);

  const logout = () => {
    history.push('/login');
    authService.onLogout();
  };

  const onCartIconClick = useCallback(() => history.push('/cart'), [history]);

  return (
    <Content>
      <Left>
        <BrandLink to="/">
          <Brand size={device === 'mobile' ? 'small' : 'default'} color="main" />
        </BrandLink>

        {device !== 'mobile' && device !== 'tablet' && (
          <Nav>
            {store.directions
              .filter((direction) => direction.forRightMenu)
              .map((direction) => (
                <NavMenu key={direction.id} item={direction} />
              ))}
          </Nav>
        )}
      </Left>

      <Right>
        <Item>
          <Profile>
            {device !== 'mobile' && device !== 'tablet' ? (
              <ProfileLink to="/profile">{profileName}</ProfileLink>
            ) : (
              <IconButton name="iconAvatar" onClick={onProfileClick} />
            )}
          </Profile>
        </Item>

        {cartStore.showCart && (
          <Item>
            <IconButton name="iconBag" onClick={onCartIconClick} />

            <BadgeWrapper>
              <Badge>{cartStore.count}</Badge>
            </BadgeWrapper>
          </Item>
        )}

        {device !== 'mobile' && device !== 'tablet' && (
          <Item>
            <IconButton name="iconLogout" onClick={logout} />
          </Item>
        )}

        <Item>
          <IconButton name="iconSearch" onClick={props.onClick} />
        </Item>
      </Right>
    </Content>
  );
});

export default Panel;
