import styled from 'styled-components';

interface ContainerProps {
  direction?: 'column' | 'row';
  justify?: 'center' | 'flex-start' | 'flex-end' | 'space-around' | 'space-between' | 'space-evenly';
  align?: 'center' | 'flex-start' | 'flex-end' | 'space-around' | 'space-between' | 'space-evenly';
  gap?: string;
  width?: string;
  wrap?: 'wrap' | 'no-wrap';
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: ${({ direction = 'row' }) => direction};
  justify-content: ${({ justify }) => justify};
  align-items: ${({ align }) => align};
  flex-wrap: ${({ wrap = 'no-wrap' }) => wrap};

  gap: ${({ gap }) => gap};
  width: ${({ width }) => width};
`;
