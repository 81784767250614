import React from 'react';
import AnimateHeight from 'react-animate-height';
import { Nullable } from 'web';
import { AwardNominations } from 'services';
import StickyBox from 'react-sticky-box';
import { useCollapse } from 'components/assol-winners/WinnersAndNominees/hooks/useCollapse';
import { Styled } from './styles';
import { Item } from './Item';

interface NominationsProps {
  nominations: Nullable<AwardNominations[]>;
  current?: number;
  setCurrentNomination: (id: number) => void;
  collapsable?: boolean;
}

const NominationsContent: React.FC<NominationsProps> = (props) => {
  const { expanded, toggleExpanded } = useCollapse(Boolean(props.collapsable));

  if (!props.nominations) return null;
  return (
    <Styled.Container>
      <Styled.Header
        collapsable={props.collapsable}
        expanded={expanded}
        {...(props.collapsable && { onClick: toggleExpanded })}
      >
        <Styled.Title>Номинации</Styled.Title>
        {props.collapsable && <Styled.IconDown className={expanded ? 'expanded' : 'collapsed'} />}
      </Styled.Header>
      <AnimateHeight duration={500} height={expanded ? 'auto' : 1}>
        <Styled.Line className={expanded ? 'expanded' : 'collapsed'} />
        <Styled.List>
          {props.nominations.map((nomination) => {
            if (!nomination.id) return null;
            return (
              <Item
                active={nomination.id === props.current}
                key={nomination.id}
                id={nomination.id}
                onClick={props.setCurrentNomination}
              >
                {nomination.name}
              </Item>
            );
          })}
        </Styled.List>
      </AnimateHeight>
    </Styled.Container>
  );
};

export const Nominations: React.FC<NominationsProps> = (props) => {
  if (props.collapsable) {
    return <NominationsContent {...props} />;
  }
  return (
    <StickyBox offsetTop={75} offsetBottom={30}>
      <NominationsContent {...props} />
    </StickyBox>
  );
};
