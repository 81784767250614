import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useMedicineCenterStore } from 'store/medicine-center';
import { observer } from 'mobx-react-lite';
import { MedicineCenterTypeEnum } from 'services';
import { Styled } from './styles';
import { Tags, Grid, SimpleBanner } from '../ui';

import { SanatoriumGrid } from './SanatoriumGrid';
import { TravelLineWidget } from '../ui/travel-line-widget';

export const MedicineCenterContent = observer(() => {
  const { medCenterId } = useParams<{ medCenterId: string }>();

  const store = useMedicineCenterStore();
  const center = store.medicineCenter;

  useEffect(() => {
    if (medCenterId) {
      store.fetchMedicineCenterPageData(Number(medCenterId));
    }
    return store.cleanUp();
  }, [medCenterId]);

  const toggleDirections = useCallback((id: number) => {
    store.toggleDirections(id);
  }, []);

  if (!center) {
    return null;
  }

  return (
    <Styled.Wrapper>
      <SimpleBanner
        image={center.image}
        header={center.title}
        additionalDescription={{ text: center.mainText, header: center.headerText, spoiler: center.spoiler }}
      />
      {store.medicineCenter?.needTravelLine && <TravelLineWidget />}
      <Styled.Container>
        {store.medicineDirections.length > 0 && (
          <Tags data={store.medicineDirections} toggleDirections={toggleDirections} />
        )}
        {center.type === MedicineCenterTypeEnum.Clinic ? (
          <Grid data={store.programs} title={center.headerProgram ?? ''} />
        ) : (
          <SanatoriumGrid
            withAccommodation={store.withAccommodation}
            withoutAccommodation={store.withoutAccommodation}
          />
        )}
      </Styled.Container>
    </Styled.Wrapper>
  );
});
