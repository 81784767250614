import React, { useCallback } from 'react';
import { isNumber } from 'lodash';
import ReactPlayer from 'react-player';
import { VideoGalleryState } from '../model/model';

const ErrorText = 'Value is not a number';

export const useControlsMethods = (
  store: VideoGalleryState,
  player: ReactPlayer,
  setShowSettings: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const handleSeekMouseDown = useCallback(() => {
    store.setSeeking(true);
  }, []);

  const handleToggleShowSettings = useCallback(() => setShowSettings((prevState) => !prevState), []);

  const handleSeekChange = useCallback(
    (value: number | readonly number[]) => {
      if (!isNumber(value)) throw new Error(ErrorText);
      store.setPlayed(value);
    },
    [player],
  );

  const handleSeekMouseUp = useCallback(
    (value: number | readonly number[]) => {
      if (!isNumber(value)) throw new Error(ErrorText);
      store.setSeeking(false);
      player.seekTo(value);
      if (store.currentVideo?.id) {
        const playedSeconds = player.getDuration() * value;
        store.setProgress(store.currentVideo?.id, playedSeconds);
      }
    },
    [store.currentVideo?.id],
  );

  const handleOnPlayClick = () => {
    store.setPlaying(!store.playing);
  };

  const toggleMute = useCallback(() => {
    store.setMuted(!store.muted);
  }, [store.muted]);

  const handleVolumeChange = useCallback((value: number | readonly number[]) => {
    if (!isNumber(value)) throw new Error(ErrorText);
    store.setVolume(value);
  }, []);

  return {
    handleSeekChange,
    handleSeekMouseDown,
    handleVolumeChange,
    toggleMute,
    handleSeekMouseUp,
    handleOnPlayClick,
    handleToggleShowSettings,
  };
};
