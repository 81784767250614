import React from 'react';
import { ParagraphValue } from 'web';
import Section from '../../base-ui/section';
import Tabs from '../../base-ui/tabs';

interface TabsRendererProps {
  value: ParagraphValue[];
}

const TabsRenderer: React.FC<TabsRendererProps> = ({ value }) => {
  return (
    <Section compact noFirstOfTypeOffset>
      <Tabs items={value} />
    </Section>
  );
};

export default TabsRenderer;
