import React, { useMemo } from 'react';
import styled from 'styled-components';

import { CartProduct } from '../../../../services';

import { COLORS } from '../../../../utils/constants';

import { Micro, Span } from '../../../base-ui/typo';

import HtmlContainer from '../../../base-ui/html-container';
import { HtmlRenderer } from '../../../../utils/htmlParser';

const Div = styled.div`
  display: grid;
  margin-bottom: 8px;
  grid-template-columns: 1fr auto;
`;

const Title = styled(HtmlContainer)`
  color: ${COLORS.secondary};
`;

const Amount = styled(Micro)`
  display: block;
  color: ${COLORS.primary};
`;

const Price = styled(Span)`
  display: block;
  color: ${COLORS.secondary};
`;

interface ProductItemProps {
  cartItem: CartProduct;
}

const Item: React.FC<ProductItemProps> = ({ cartItem }) => {
  const totalPrice = useMemo(() => {
    const price = cartItem.product?.discountPrice || cartItem.product?.price;
    if (price && typeof +price === 'number') {
      return +price * cartItem.count;
    }
    return '---';
  }, [cartItem]);

  return (
    <Div>
      <div>
        <Title>
          <HtmlRenderer html={cartItem.product?.name || ''} />
        </Title>
        <Amount>{cartItem.count} ед. товара</Amount>
      </div>

      <div>
        <Price>{totalPrice}</Price>
      </div>
    </Div>
  );
};

export default Item;
