import React, { useMemo } from 'react';
import styled from 'styled-components';

import Marquee from 'react-marquee-slider';
import { Company } from 'services';

import { useDeviceType } from 'utils/hooks';
import Item from './item';

const Grid = styled.div`
  padding: 15px 0;
  display: grid;
  row-gap: 56px;
  overflow: hidden;
  grid-template-rows: repeat(3, 40px);
  & > div {
    overflow: visible;
  }
`;

interface CompaniesProps {
  items: Company[];
}

const dblArr = (a: Company[]) => {
  return a.concat(a);
};

const Companies: React.FC<CompaniesProps> = ({ items }) => {
  const deviceType = useDeviceType();

  const isMobile = useMemo(() => deviceType === 'mobile', [deviceType]);

  const rowOne = useMemo(() => {
    return dblArr(items.filter((_, idx) => idx % 3 === 0)).map((el) => <Item key={el.id} data={el} />);
  }, [items]);
  const rowTwo = useMemo(() => {
    return dblArr(items.filter((_, idx) => idx % 3 === 1)).map((el) => <Item key={el.id} data={el} />);
  }, [items]);
  const rowThree = useMemo(() => {
    return dblArr(items.filter((_, idx) => idx % 3 === 2)).map((el) => <Item key={el.id} data={el} />);
  }, [items]);

  return (
    <Grid>
      <Marquee
        key={4}
        velocity={isMobile ? 3 : 12}
        direction="rtl"
        scatterRandomly={false}
        resetAfterTries={100}
        onInit={() => null}
        onFinish={() => null}
        children={rowOne}
      />
      <Marquee
        key={2}
        velocity={isMobile ? 4 : 14}
        direction="ltr"
        scatterRandomly={false}
        resetAfterTries={100}
        onInit={() => null}
        onFinish={() => null}
        children={rowTwo}
      />
      <Marquee
        key={3}
        velocity={isMobile ? 4 : 14}
        direction="rtl"
        scatterRandomly={false}
        resetAfterTries={100}
        onInit={() => null}
        onFinish={() => null}
        children={rowThree}
      />
    </Grid>
  );
};

export default Companies;
