import React from 'react';
import { HtmlRenderer } from 'utils/htmlParser';
import { useDeviceType } from 'utils/hooks';
import { BannerOuter, BannerWrapper, BannerInner, BannerNext, BannerContent, BannerTitle } from './styled';

interface Props {
  image: string;
  title: string;
  text: string;
}

export const AboutUsBanner: React.FC<Props> = (props) => {
  const deviceType = useDeviceType();

  return (
    <BannerWrapper>
      <BannerOuter src={props.image}>
        <BannerInner active={false} />
        <BannerNext />
      </BannerOuter>

      <BannerContent>
        <BannerTitle>{props.title}</BannerTitle>
        {deviceType !== 'mobile' && <HtmlRenderer html={props.text} />}
      </BannerContent>
    </BannerWrapper>
  );
};
