import { createGlobalStyle } from 'styled-components';

import InterBlack from '../assets/fonts/Inter-Black.ttf';
import InterBold from '../assets/fonts/Inter-Bold.ttf';
import InterExtraBold from '../assets/fonts/Inter-ExtraBold.ttf';
import InterExtraLight from '../assets/fonts/Inter-ExtraLight.ttf';
import InterLight from '../assets/fonts/Inter-Light.ttf';
import InterMedium from '../assets/fonts/Inter-Medium.ttf';
import InterRegular from '../assets/fonts/Inter-Regular.ttf';
import InterSemiBold from '../assets/fonts/Inter-SemiBold.ttf';
import InterThin from '../assets/fonts/Inter-Thin.ttf';

const GlobalFonts = createGlobalStyle`
  @font-face {
    font-family: 'Inter';
    src: url(${InterBlack}) format('truetype');
    font-weight: 900;
    font-style: black;
  }

  @font-face {
    font-family: 'Inter';
    src: url(${InterBold}) format('truetype');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'Inter';
    src: url(${InterExtraBold}) format('truetype');
    font-weight: 800;
    font-style: normal;
  }

  @font-face {
    font-family: 'Inter';
    src: url(${InterExtraLight}) format('truetype');
    font-weight: 200;
    font-style: normal;
  }

  @font-face {
    font-family: 'Inter';
    src: url(${InterLight}) format('truetype');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Inter';
    src: url(${InterMedium}) format('truetype');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Inter';
    src: url(${InterRegular}) format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Inter';
    src: url(${InterSemiBold}) format('truetype');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'Inter';
    src: url(${InterThin}) format('truetype');
    font-weight: 100;
    font-style: normal;
  }
`;

export default GlobalFonts;
