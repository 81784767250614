import React from 'react';
import { observer } from 'mobx-react-lite';
import { useAboutUsStore } from 'store';
import { BlocksRender } from 'components/renderers';
import { Block } from 'utils/blocks';
import { useMatomo } from '@datapunt/matomo-tracker-react';

export const AboutUs: React.FC = observer(() => {
  const store = useAboutUsStore();
  const { trackPageView } = useMatomo();

  React.useEffect(() => {
    store.fetchContent();
    document.title = 'О нас';
    trackPageView({});
  }, []);

  return (
    <BlocksRender
      blocks={store?.content?.content as unknown as Block[]}
      loading={store?.fetching}
      options={{ section: { compact: true } }}
    />
  );
});
