import React from 'react';

import { Block } from '../../../utils/blocks';

import Collapse from '../../base-ui/collapse';
import Section from '../../base-ui/section';

import BlocksRender, { PropsWithSectionOptions } from '../blocks';

type CollapseRendererProps = PropsWithSectionOptions<{
  value: {
    header: string;
    content: Block[];
  };
}>;

const CollapseRenderer: React.FC<CollapseRendererProps> = ({ value, options }) => {
  return (
    <Section
      paddingless
      borderless
      noFirstOfTypeOffset
      compact={options?.compact}
      noLastOfTypeMargin={options?.noLastOfTypeMargin}
    >
      <Collapse bordered title={value.header}>
        <BlocksRender blocks={value.content} options={{ section: { noLastOfTypeMargin: true } }} />
      </Collapse>
    </Section>
  );
};

export default CollapseRenderer;
