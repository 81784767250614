import React, { useCallback, useState } from 'react';
import { AwardParticipantsList } from 'services';
import { FreeMode } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Styled } from './styles';
import { ParticipantModal } from '../Modal';
import { Card } from '../Card';
import 'swiper/swiper.min.css';

const getCardProps = (item: AwardParticipantsList, handleOnCardClick: (id: number) => void) => {
  return {
    onClick: handleOnCardClick,
    id: item.id,
    subtitle: item.descriptionSmall,
    title: item.name ?? 'Без имени',
    src: item.image,
  };
};

export const CardList: React.VFC<{
  data: AwardParticipantsList[];
  isScrollable?: boolean;
}> = (props) => {
  const [participantId, setParticipantId] = useState<number | null>(null);

  const handleOnCardClick = useCallback((id: number) => {
    setParticipantId(id);
  }, []);

  const handleOnCloseModal = useCallback(() => {
    setParticipantId(null);
  }, []);

  if (props.isScrollable) {
    return (
      <Styled.SwiperContainer>
        <Swiper slidesPerView="auto" freeMode modules={[FreeMode]} className="mySwiper">
          {props.data.map((item) => (
            <SwiperSlide key={item.id}>
              <Card {...getCardProps(item, handleOnCardClick)} />
            </SwiperSlide>
          ))}
        </Swiper>
        <ParticipantModal participantId={participantId} visible={!!participantId} onClose={handleOnCloseModal} />
      </Styled.SwiperContainer>
    );
  }

  return (
    <Styled.Container>
      {props.data.map((item) => (
        <Card key={item.id} {...getCardProps(item, handleOnCardClick)} />
      ))}
      <ParticipantModal participantId={participantId} visible={!!participantId} onClose={handleOnCloseModal} />
    </Styled.Container>
  );
};
