import React, { useCallback, useMemo, useState } from 'react';
import { CategoryProps } from 'web';
import { BankingProduct, InsuranceProduct, MediaProduct, Product, ProductList } from 'services';
import { useSubscribe } from 'components/services/utils';
import { useDeviceType, useToggle } from 'utils/hooks';
import { HtmlRenderer } from 'utils/htmlParser';
import { Feedback } from 'components/feedback';
import { BaseModal } from 'components/base-ui/base-modal';
import {
  StDescription,
  StProductCard,
  Image,
  ImageWrapper,
  StTitle, StInfo, StContent,
} from 'components/services/shared/product/styles';
import { Actions } from './components/actions';

interface ProductListItemProps {
  data: Product | ProductList | BankingProduct | InsuranceProduct | MediaProduct;
  category?: Partial<CategoryProps>;
}

export enum ActionList {
  showOfferButton = 'showOfferButton',
  showDetailButton = 'showDetailButton',
  cardCollapsed = 'cardCollapsed',
}

const ProductListItem: React.FC<ProductListItemProps> = ({ data, category }) => {
  const deviceType = useDeviceType();

  const [active, toggleActive] = useToggle();
  const [applicationFormId, setApplicationFormId] = useState<number | null>(null);

  const applicationModalClose = useCallback(() => setApplicationFormId(null), []);

  const havePage = useMemo(() => {
    if ('pageId' in data) return !!data.pageId;
    if ('page' in data) return !!data.page;
    return false;
  }, [data]);

  const [subscribe, Chip, haveAction] = useSubscribe({
    category: category?.type,
    havePage,
    product: data.id,
    link: `/services/${category?.type}/${category?.id}/${data.id}`,
    subscribeFromPage: false,
    specialService: 'specialService' in data ? data.specialService : undefined,
  });

  const showImage = useMemo(() => {
    return (deviceType === 'desktop' || deviceType === 'laptop' || !active) && data.imageThumbnail;
  }, [deviceType, active]);

  const actions = useMemo(() => {
    const actionsArray = [];

    if (haveAction || data.applicationForm) {
      actionsArray.push(ActionList.showOfferButton);
    }

    if (data.fullDescription && data.fullDescription.length > 0) {
      actionsArray.push(ActionList.showDetailButton);
    }

    if (!active) {
      actionsArray.push(ActionList.cardCollapsed);
    }

    return actionsArray;
  }, [haveAction, data.applicationForm, data.fullDescription, active]);

  const handleSubscribeClick = useCallback(() => {
    if ('externalLink' in data && data.externalLink) {
     window.open(data.externalLink, '_blank');
     return;
    }
    if (data.applicationForm) {
      setApplicationFormId(data.applicationForm);
      return;
    }

      subscribe();
  }, [data.applicationForm, subscribe]);

  const buttonName = useMemo(() => {
    if ('buttonName' in data && data.buttonName) {
      return data.buttonName;
    }
    return 'Оформить';
  }, [data]);

  return (
    <StProductCard>

      <StContent>
        {Chip && <Chip />}

        <StInfo>
          {!active && (
            <StTitle marginless>
              <HtmlRenderer html={data.name} />
            </StTitle>
          )}

          <StDescription>
            <HtmlRenderer html={(active ? data.fullDescription : data.description) ?? ''} />
          </StDescription>

          {(deviceType === 'laptop' || deviceType === 'desktop') && (
            <Actions
              offerButtonName={buttonName}
              actions={actions}
              onDetailClick={toggleActive}
              onOfferClick={handleSubscribeClick}
            />
          )}
        </StInfo>

        {showImage && (
          <ImageWrapper active={active}>
            <Image src={data.imageThumbnail} />
          </ImageWrapper>
        )}

      </StContent>

      {(deviceType === 'mobile' || deviceType === 'tablet') && (
        <Actions
          offerButtonName={buttonName}
          actions={actions}
          onDetailClick={toggleActive}
          onOfferClick={handleSubscribeClick}
        />
      )}

      <BaseModal isVisible={Boolean(applicationFormId)} onClose={applicationModalClose}>
        <Feedback formId={Number(data.applicationForm)} />
      </BaseModal>
    </StProductCard>
  );
};

export default ProductListItem;
