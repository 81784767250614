import { makeObservable, observable, action } from 'mobx';

import { api } from '../../utils/api';

import { Shop, ShopsApiShopsListRequest } from '../../services';

type NullableError = Error | null;

export default class ShopsStore {
  fetching = false;

  error: NullableError = null;

  shops: Shop[] = [];

  constructor() {
    makeObservable(this, {
      fetching: observable,
      error: observable,
      shops: observable,
      fetchShops: action,
      setError: action,
      setFetching: action,
      setShops: action,
    });
  }

  fetchShops(props: ShopsApiShopsListRequest = {}, options?: unknown): Promise<void> {
    this.setError(null);
    this.setFetching(true);

    return api.shops
      .shopsList(props, options)
      .then((res) => this.setShops(res.data.results))
      .catch((err: Error) => this.setError(err))
      .finally(() => this.setFetching(false));
  }

  setFetching(value: boolean): void {
    this.fetching = value;
  }

  setError(value: NullableError): void {
    this.error = value;
  }

  setShops(value: Shop[]): void {
    this.shops = value;
  }
}
