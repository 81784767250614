import React, { createContext, useContext } from 'react';

import GuideCategoryStore from './store';

const store = new GuideCategoryStore();

const GuideCategoryStateContext = createContext<GuideCategoryStore>(store);

export const GuideCategoryStateProvider: React.FC = ({ children }) => (
  <GuideCategoryStateContext.Provider value={store}>{children}</GuideCategoryStateContext.Provider>
);

export const useGuideCategoryStore = (): GuideCategoryStore => useContext(GuideCategoryStateContext);
