import React, { useCallback, useState } from 'react';
import { head } from 'lodash';
import { HtmlRenderer } from 'utils/htmlParser';
import { useDeviceType } from 'utils/hooks';
import Icon from 'components/base-ui/icon';
import { useHistory } from 'react-router-dom';
import {
  Wrapper,
  Footer,
  StButton,
  Description,
  DecorWrapper,
  Columns,
  ColumnItem,
  Column,
  ColumnHeader,
  Body,
} from './styled';
import { Carousel, FooterDecor, AccordionColumns, IconPoint } from './components';

type Data = {
  year: number;
  button: {
    link: string;
    text: string;
  };
  description: string;
  listingColumns: { header: string; points: string[] }[];
};

type Props = {
  data: Data[];
};

export const CreatorsYearsBlock: React.FC<Props> = ({ data }) => {
  const hystory = useHistory();
  const device = useDeviceType();
  const [currentData, setCurrentData] = useState<Data | undefined>(head(data));

  const onClick = useCallback(
    (year: number) => {
      setCurrentData(head(data.filter((y) => y.year === year)));
    },
    [data],
  );

  const onLink = useCallback(() => {
    if (!currentData?.button.link) return;
    hystory.push(currentData.button.link);
  }, [hystory, currentData?.button.link]);

  return (
    <Wrapper>
      <Carousel years={data.map((item) => item.year)} active={currentData?.year} onClick={onClick} />

      <Body>
        <Description>
          <HtmlRenderer html={currentData?.description ?? ''} />
        </Description>
        {device === 'mobile' && <AccordionColumns data={currentData?.listingColumns} />}
        {device !== 'mobile' && (
          <Columns>
            {currentData?.listingColumns?.map((column) => (
              <Column key={column.header}>
                <ColumnHeader>{column.header}</ColumnHeader>
                {column.points.map((point) => (
                  <ColumnItem key={point}>
                    <IconPoint />
                    {point}
                  </ColumnItem>
                ))}
              </Column>
            ))}
          </Columns>
        )}
      </Body>

      <Footer withoutButton={!currentData?.button.text || !currentData?.button.link}>
        {currentData?.button.link && currentData.button.text && (
          <StButton onClick={onLink}>
            {currentData?.button?.text}
            <div style={{ width: '24px', height: '24px' }}>
              <Icon name="iconArrowWhite" />
            </div>
          </StButton>
        )}
        <DecorWrapper>
          <FooterDecor mobile={device === 'mobile'} />
        </DecorWrapper>
      </Footer>
    </Wrapper>
  );
};
