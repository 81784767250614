import { useCallback, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import { useDeviceType } from 'utils/hooks';
import { ASIDE_HEIGHT } from './menu/constants';

export const useColumnsHeight = (totalElementsCount: number) => {
  const [filtersHeights, setFilterHeights] = useState<number[] | null>(null);
  const device = useDeviceType();

  const heightArrayRef = useRef<number[]>([]);

  const updateFiltersHeights = useCallback(
    (index: number, height: number) => {
      if (heightArrayRef.current) {
        heightArrayRef.current[index] = height;
        if (heightArrayRef.current.length === totalElementsCount) {
          setFilterHeights([...heightArrayRef.current]);
        }
      }
    },
    [filtersHeights],
  );

  const columnsHeight = useMemo(() => {
    if (device === 'mobile' || !filtersHeights) return;

    const columnCount = device === 'tablet' ? 2 : 3;
    const columns = Array(columnCount).fill(0) as number[];

    const withAside = [...filtersHeights, ASIDE_HEIGHT];

    const fieldsHeight = withAside.reduce((accumulator, current) => accumulator + current, 0);

    const averageHeight = fieldsHeight / columnCount;

    let currentColumn = columnCount;
    // Iterate over the elements and fill the columns based on the average column size.
    withAside.reverse().forEach((current) => {
      // If the remaining space in the column is more than half the height of the element,
      // then we place it in the current column.
      if (averageHeight >= columns[currentColumn - 1] + current / 2) {
        columns[currentColumn - 1] += current;
        return;
      }
      // If less, then proceed to filling the next column.
      currentColumn = currentColumn > 1 ? currentColumn - 1 : 1;
      columns[currentColumn - 1] += current;
    });

    return _.max(columns) ?? averageHeight;
  }, [filtersHeights]);

  return { columnsHeight, updateFiltersHeights };
};
