import React, { ChangeEventHandler, useCallback, useEffect, useState } from 'react';
import RSelect, { components, OptionsType, OptionTypeBase } from 'react-select';
import { SelectOption } from 'web';
import {
  CheckboxInput,
  CheckboxLabel,
  CheckboxWrapper,
  customStyles,
  InputDiv,
  InputLabel,
  InputLabelHidden,
} from 'components/base-ui/multi-select/styles';
import { parseHtml } from 'utils/htmlParser';
import useResizeObserver from 'use-resize-observer';

interface MultiSelectPropsType {
  name: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  options: SelectOption[];
  label?: string;
  index: number;
  updateFiltersHeights: (index: number, height: number) => void;
  value?: string | readonly string[] | number;
  isSelected?: boolean;
}

const Option: React.FC<MultiSelectPropsType> = (props) => {
  return (
    <div>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-expect-error */}
      <components.Option {...props}>
        <CheckboxLabel htmlFor={props.label}>
          <CheckboxInput
            id={props.label}
            type="checkbox"
            value={props.value}
            checked={props.isSelected}
            onChange={props.onChange}
          />
          <CheckboxWrapper /> {props.label}
        </CheckboxLabel>
      </components.Option>
    </div>
  );
};

const PADDING_HEIGHT = 32;

export const MultiSelect: React.VFC<MultiSelectPropsType> = React.memo(
  ({ options, name, onChange, label, updateFiltersHeights, index }) => {
    const [state, setState] = useState<OptionTypeBase>({ optionSelected: null });

    useEffect(() => {
      const init = sessionStorage.getItem(name);
      if (init) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setState(JSON.parse(init));
      }
    }, []);

    const { ref, height } = useResizeObserver<HTMLDivElement>();

    const handleChange = useCallback((selected: OptionsType<SelectOption[]>) => {
      const newState = { optionSelected: selected };
      setState(newState);
      sessionStorage.setItem(name, JSON.stringify(newState));
    }, []);

    const noOptionsMessage = useCallback(() => 'Нет опций', []);

    useEffect(() => {
      if (height) {
        updateFiltersHeights(index, height + PADDING_HEIGHT);
      }
    }, [height]);

    useEffect(() => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      onChange(state.optionSelected);
    }, [state]);

    return (
      <InputDiv ref={ref}>
        {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
        {state.optionSelected?.length > 0 ? (
          <InputLabel>{label}</InputLabel>
        ) : (
          <InputLabelHidden>{parseHtml('&#8203')}</InputLabelHidden>
        )}

        <RSelect
          styles={customStyles}
          options={options}
          isMulti={true}
          hideSelectedOptions={false}
          closeMenuOnScroll={false}
          closeMenuOnSelect={false}
          placeholder={label}
          components={{
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            Option,
          }}
          onChange={handleChange}
          allowSelectAll={true}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          value={state.optionSelected}
          menuPortalTarget={document.body}
          noOptionsMessage={noOptionsMessage}
          isSearchable={false}
        />
      </InputDiv>
    );
  },
);
