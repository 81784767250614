import React, { useCallback, useLayoutEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import { useUnderConstructionStore } from '../../../../store';
import { PortalDirectionItem } from '../../../../services';
import { COLORS, UNDER_CONSTRUCTION_LINK } from '../../../../utils/constants';
import { smallStyles } from '../../../../styles/text';
import { Span } from '../../../base-ui/typo';
import HtmlContainer from '../../../base-ui/html-container';
import iconArrow from '../../../../assets/icons/icon_arrow.svg';
import { HtmlRenderer } from '../../../../utils/htmlParser';

const ImgWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  display: grid;
  grid-template-rows: 100%;
`;

const Img = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  grid-row: 1;
  grid-column: 1;
`;

const IndexWrapper = styled.div`
  height: 100%;
  overflow: hidden;
  position: relative;
  grid-row: 1;
  grid-column: 1;
  &:before {
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    mix-blend-mode: multiply;
    clip-path: polygon(80% 0, 100% 0%, 100% 100%, 0% 100%);
    background: ${COLORS.secondary};
  }
`;

const Index = styled(Span)`
  right: 22px;
  bottom: 22px;
  opacity: 0.5;
  font-size: 96px;
  line-height: 96px;
  font-weight: bold;
  position: absolute;
  color: ${COLORS.white};
`;

const Title = styled.div`
  position: relative;
  display: block;
  width: 100%;
  padding-right: 40px;
  font-size: 18px;
  line-height: 28px;
  color: ${COLORS.secondary};
  transition: all 0.2s ease-out;
  background: url(${iconArrow}) center right no-repeat;
`;

const CardWrapper = styled.div<{ inActive: boolean }>`
  display: grid;
  grid-template-rows: 392px auto 1fr;
  grid-row-gap: 10px;
  overflow: hidden;
  opacity: ${(props) => (props.inActive ? '1' : '.5')};

  &:hover {
    cursor: pointer;
  }
  &:hover ${Title}, &:focus ${Title}, &:active ${Title} {
    color: ${COLORS.primary};
  }
`;

const Description = styled(HtmlContainer)`
  ${smallStyles};
`;

interface CardProps {
  data: PortalDirectionItem;
  wrapper: HTMLDivElement | null;
  updateVisibleItems: (index: number, visible: number) => void;
}

const Card: React.FC<CardProps> = (props) => {
  const { data, wrapper, updateVisibleItems } = props;
  const history = useHistory();

  const { ref, inView, entry } = useInView({
    threshold: 1,
    root: wrapper,
  });

  const underConstructionStore = useUnderConstructionStore();

  useLayoutEffect(() => {
    if (entry) updateVisibleItems(Number(data.orderMainPage) - 1, inView ? 1 : 0);
  }, [entry]);

  const inActive = useMemo(() => Boolean(wrapper && inView), [wrapper, inView]);

  const cardClickHandler = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (data.link === UNDER_CONSTRUCTION_LINK) {
        event.preventDefault();
        underConstructionStore.setModalVisible(true);
      } else {
        history.push(data.link);
      }
    },
    [data.link],
  );

  return (
    <CardWrapper onClick={cardClickHandler} inActive={inActive} ref={ref}>
      <ImgWrapper>
        <Img src={data.image || ''} />

        <IndexWrapper>
          <Index>{data.orderMainPage}</Index>
        </IndexWrapper>
      </ImgWrapper>

      <Title>{data.title}</Title>

      <Description>
        <HtmlRenderer html={data.preview || ''} />
      </Description>
    </CardWrapper>
  );
};

export default Card;
