import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import { Comment } from '../../../../../services';

import { COLORS, BREAKPOINTS } from '../../../../../utils/constants';

import { Span, Micro, Small } from '../../../../base-ui/typo';
import Avatar from '../../../../base-ui/avatar';

import DEFAULT_SRC from '../../../../../assets/img/assol_avatar.png';

const Container = styled.li<{ isHighlighted: boolean; isSub?: boolean }>`
  border-radius: 4px;
  padding: 16px 16px 24px 16px;
  color: ${COLORS.gray3};
  //background-color: ${COLORS.gray6};
  margin-bottom: 15px;
  margin-left: ${(props) => (props.isSub ? '16px' : '0')};
  background-color: ${(props) => (props.isHighlighted ? COLORS.gray8 : COLORS.gray6)};
`;

const Grid = styled.div`
  display: grid;
  row-gap: 6px;
  grid-template-rows: auto 1fr;
`;

const CommentHead = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    flex-direction: row;
    align-items: center;
  }
`;
const Info = styled.div`
  display: flex;
  flex-direction: column;
`;

const InfoUser = styled(Span)`
  margin-bottom: 14px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin-bottom: unset;
  }
`;

const InfoDate = styled(Micro)``;

const Body = styled.div``;

const CommentText = styled(Small)`
  word-break: break-all;
`;

const ShowMore = styled.span`
  color: ${COLORS.primary};
  cursor: pointer;
`;

interface ItemProps {
  data: Comment;
}

const GoodCommentsItem: React.FC<ItemProps> = ({ data }) => {
  const [collapsed, setCollapsed] = useState(data.text.length > 300);
  const [isHighlight, setIsHighlight] = useState(false);

  const memoizedImage = useMemo(() => {
    return data.author?.image ? data.author.image : DEFAULT_SRC;
  }, [data.author]);

  const memoizedName = useMemo(() => {
    return data.author?.firstName && data.author.lastName
      ? `${data.author.firstName} ${data.author.lastName}`
      : 'Неизвестный автор';
  }, [data.author]);

  const memoizedDate = useMemo(() => {
    return data.created ? new Date(data.created).toLocaleDateString() : '1 Января 1970';
  }, [data.created]);

  const content = useMemo(() => {
    return collapsed ? data.text.slice(0, 300) : data.text;
  }, [data.text, collapsed]);
  return (
    <Container isHighlighted={isHighlight} onMouseOver={() => setIsHighlight(false)}>
      <Grid>
        <CommentHead>
          <Avatar src={memoizedImage} />

          <Info>
            <InfoUser bold>{memoizedName}</InfoUser>
            <InfoDate>{memoizedDate}</InfoDate>
          </Info>
        </CommentHead>

        <Body>
          <CommentText>
            {content}
            {collapsed && <ShowMore onClick={() => setCollapsed(false)}> читать больше...</ShowMore>}
          </CommentText>
        </Body>
      </Grid>
    </Container>
  );
};
export default GoodCommentsItem;
