import React from 'react';
import { AwardWinners } from 'services';
import { Header, Image, ImageWrapper, Item, SubHeader, Text } from './styles';

interface ItemListProps {
  winner: AwardWinners;
}

export const ItemList: React.FC<ItemListProps> = ({ winner }) => {
  return (
    <Item>
      <ImageWrapper>
        <Image src={winner.image} />
      </ImageWrapper>
      <Header>{winner.title}</Header>
      <SubHeader>{winner.subTitle}</SubHeader>
      <Text>{winner.text}</Text>
    </Item>
  );
};
