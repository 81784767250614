import styled, { css } from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';

export const ArrowButton = styled.button<{ direction: 'left' | 'right' }>`
  background: transparent;
  box-shadow: none;
  border: none;
  cursor: pointer;
    svg {
        width: 24px;
        height: 24px;
        & * {
            fill: ${COLORS.secondary};
        }
`;
const common = css`
  width: 30px;
  height: 30px;
  top: calc(50% - 15px);
`;

export const WrapperLeft = styled.div`
  ${common};
  position: absolute;
  left: -64px;

  @media screen and (max-width: ${BREAKPOINTS.wideDesktop}) {
    left: -40px;
  }
`;

export const WrapperRight = styled.div`
  ${common};
  position: absolute;
  right: -64px;

  @media screen and (max-width: ${BREAKPOINTS.wideDesktop}) {
    right: -40px;
  }
`;
