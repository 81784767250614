import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { CategoryProps, SubstitutedCategoryType } from 'web';

import { useProductListStore, useProfileStore } from 'store';

import { useSubscribe } from 'components/services/utils';
import { COLORS } from 'utils/constants';

import { Small } from 'components/base-ui/typo';
import HtmlContainer from 'components/base-ui/html-container';

import iconArrow from 'assets/icons/icon_arrow.svg';
import { useProperties } from 'utils/attributes';
import { HtmlRenderer } from 'utils/htmlParser';
import { BankingProduct, InsuranceProduct, MediaProduct, ProductList } from 'services';
import { IconLikeWithTooltip } from 'components/base-ui/icon-like-with-tooltip';
import { isNumber } from 'lodash';

const ImageWrapper = styled.div<{bg: string}>`
  overflow: visible;
  position: relative;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.35);
  background-image: ${props => `url("${props.bg}")`};
  background-size: cover;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
`;

const ButtonWrapper = styled.div`
  position: absolute;
  display: flex;
  right: 12px;
  bottom: 14px;
`;

const Body = styled.div``;

const Title = styled.div<{ haveAction: boolean }>`
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 13px;
  padding-right: 40px;
  color: inherit;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  text-decoration: none;
  transition: all 0.2s ease-out;
  cursor: pointer;
  ${props =>
    props.haveAction &&
    `
     &:before {
    content: '';
    display: block;
    position: absolute;
    right: 7px;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-image: url(${iconArrow});
  }
    `}
`;

const Div = styled.div`
  display: grid;
  row-gap: 24px;
  color: ${COLORS.gray3};
  grid-template-rows: 366px auto;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  &:hover ${Title}, &:focus ${Title}, &:active ${Title} {
    color: ${COLORS.primary};
  }
`;

interface ItemProps {
  data: ProductList | BankingProduct | InsuranceProduct | MediaProduct;
  category?: Partial<CategoryProps>;
  handleShowModal?: (newsId: number) => void;
}

const Item: React.FC<ItemProps> = ({ data, category, handleShowModal }) => {
  const { name, imageThumbnail } = data;
  const profileStore = useProfileStore();

  const { categoryType } = useParams<{ categoryType: SubstitutedCategoryType }>();

  const havePage = useMemo(() => {
    if ('page' in data) {
      return !!data.page;
    }
    if ('pageId' in data) {
      return !!data.pageId;
    }
  }, ['page' in data && data.page, 'pageId' in data && data.pageId]);

  const [subscribe, Chip, haveAction] = useSubscribe({
    havePage,
    product: data.id,
    category: category?.type,
    link: `/services/${category?.type}/${category?.id}/${data.id}`,
    subscribeFromPage: false,
  });

  const isLikedByUser = useMemo(() => ('isLikedByUser' in data ? data.isLikedByUser : false), [data]);
  const isInUserFavorites = useMemo(() => ('isInUserFavorites' in data ? data.isInUserFavorites : false), [data]);

  const store = useProductListStore();

  const attributes = useProperties(data);

  const cardClickHandler = useCallback((ev: React.MouseEvent<HTMLDivElement>) => {
    subscribe();
  }, []);

  const handleLikeClick = useCallback(() => {
    if (data.id) {
      isLikedByUser ? store.handleLikeDelete(data.id, profileStore.profile?.id!)
        : store.handleLikePost(data.id, profileStore.profile);
    }
  }, [isLikedByUser, data.id]);

  const onShowModal = React.useCallback((ev?: React.MouseEvent<HTMLDivElement>) => {
    if (ev) {
      ev.stopPropagation();
    }

    if (handleShowModal) {
      handleShowModal(data.id!);
    }
  }, [data.id, handleShowModal]);

  const tooltipOffset = React.useMemo(() => {
    if ('likesCount' in data && data.likesCount && data.likesCount > 6) {
      return {
        left: '-140px',
        leftMobile: '-210px',
        arrowLeft: '175px',
        arrowLeftMobile: '243px',
      };
    }

    if ('likesCount' in data && data.likesCount && data.likesCount > 4) {
      return {
        left: '-140px',
        leftMobile: '-150px',
        arrowLeft: '175px',
        arrowLeftMobile: '180px',
      };
    }

    return {
      left: '-120px',
      leftMobile: '-120px',
      arrowLeft: '150px',
      arrowLeftMobile: '150px',
    };
  }, ['likesCount' in data && data.likesCount]);

  return (
    <Div onClick={cardClickHandler}>
      {Chip && <Chip />}

      <ImageWrapper bg={imageThumbnail || ''}>
        {categoryType === 'catalog' && (
          <ButtonWrapper>
            <IconLikeWithTooltip
              isLiked={!!isLikedByUser}
              onClick={handleLikeClick}
              likedUsers={'likeAuthors' in data && data.likeAuthors ? data.likeAuthors : []}
              likesCount={'likesCount' in data && isNumber(data.likesCount) ? data.likesCount : 0}
              newsId={data.id!}
              onShowModal={onShowModal}
              color="white"
              left={tooltipOffset.left}
              leftMobile={tooltipOffset.leftMobile}
              arrowLeft={tooltipOffset.arrowLeft}
              arrowLeftMobile={tooltipOffset.arrowLeftMobile}
            />
          </ButtonWrapper>
        )}
      </ImageWrapper>

      <Body>
        <Title haveAction={haveAction}>
          <HtmlContainer marginless>
            <HtmlRenderer html={name} />
          </HtmlContainer>
        </Title>

        <Small>{attributes}</Small>
      </Body>
    </Div>
  );
};

export default Item;
