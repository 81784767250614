import React from 'react';
import { ParagraphValue } from 'web';
import BaseSection from '../../base-ui/section';
import Content from '../../news/item/content';

interface NewsContentBlock {
  data: ParagraphValue;
  borderLess?: boolean;
}

const NewsContent: React.FC<NewsContentBlock> = (props) => {
  return (
    <BaseSection heading={props.data.header} compact borderless paddingless transparent>
      <Content text={props.data.text} borderLess={props.borderLess} />
    </BaseSection>
  );
};

export default NewsContent;
