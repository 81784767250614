import styled from 'styled-components';
import { BREAKPOINTS } from 'utils/constants';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
    gap: 16px;
  }

  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    gap: 37px;
  }
`;

export const Styled = { Container };
