import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';
import Icon from 'components/base-ui/icon';
/* eslint-disable */

export const StBigElement = styled.div<{ bg: string; showPointer: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;

  width: 656px;
  height: 457px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 45.83%, rgba(0, 0, 0, 0.53) 59.93%, #000000 79.66%, #000000 100%),
    url(${(props) => props.bg});
  background-size: cover;
  cursor: ${(props) => (props.showPointer ? 'pointer' : 'unset')};
  border-radius: 20px;

  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    width: 813px;
    height: 455px;
  }

  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    width: 989px;
    height: 554px;
  }
`;

export const StText = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  color: ${COLORS.white};
  width: 576px;
  margin-bottom: 30px;
  p {
    margin: 0;
  }

  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    width: 747px;
    margin-bottom: 40px;
  }

  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    width: 909px;
  }
`;

export const StHeader = styled(StText)`
  font-weight: 700;
  margin-bottom: 0;
`;

export const StPlayIcon = styled(Icon)`
  width: 60px;
  height: 60px;
  margin-bottom: 30px;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    margin-bottom: 50px;
  }
`;
