import { action, makeObservable, observable } from 'mobx';

import { api } from 'utils/api';

import { Signin, Signup, Token } from 'services';
import { authService } from 'utils/authentication';
import { HTTP_STATUSES } from 'utils/constants';
import { AxiosError } from 'axios';
import AbstractStore, { NullableError } from '../AbstractStore';

type Nullable<T> = T | null;

export default class AuthStore extends AbstractStore {
  token: Nullable<Token> = null;

  email = '';

  captchaImages: string[] = [];

  constructor() {
    super();
    makeObservable(this, {
      ...this.annotations,
      token: observable,
      captchaImages: observable,
      signIn: action,
      signUp: action,
      getCaptcha: action,
      setEmail: action,
      setCaptcha: action,
      checkToken: action,
    });
  }

  signIn(data: Signin): Promise<void> {
    this.setError(null);
    this.setFetching(true);

    return api.auth.signIn
      .signinCreate({ data })
      .then((res) => {
        this.setToken(res.data);
        authService.onAuth(res.data.key);
      })
      .catch((err: AxiosError) => this.setError(err))
      .finally(() => this.setFetching(false));
  }

  checkToken(ldap: string): Promise<string> {
    this.setError(null);
    this.setFetching(true);

    return api.ldapToken
      .ldapTokenCreate({ data: { ldap } })
      .then((res) => {
        this.setToken(res.data);
        authService.onAuth(res.data.key);
        return 'success';
      })
      .catch((err: AxiosError) => {
        this.setError(err);
        return 'error';
      })
      .finally(() => this.setFetching(false));
  }

  getCaptcha(): Promise<void> {
    this.setFetching(true);

    return api.captcha
      .captchaList()
      .then((res) => this.setCaptcha(res.data.map((item) => item.image!)))
      .catch((err: AxiosError) => this.setError(err))
      .finally(() => this.setFetching(false));
  }

  signUp(data: Signup): Promise<void> {
    this.setError(null);
    this.setFetching(true);

    return api.auth.signUp
      .signupCreate({ data })
      .then(() => {
        this.setEmail(data.email);
      })
      .catch((err: AxiosError) => this.setError(err))
      .finally(() => this.setFetching(false));
  }

  setToken(value: Nullable<Token>): void {
    this.token = value;
  }

  setEmail(value: string): void {
    this.email = value;
  }

  setCaptcha(value: string[]): void {
    this.captchaImages = value;
  }

  setError(value: NullableError) {
    if (value !== null && value.hasOwnProperty('response') && value.response?.status === HTTP_STATUSES.THROTTLE) {
      authService.initTimeRateLimit();
    }
    super.setError(value);
  }
}
