import React, { createContext, useContext } from 'react';

import MedicineStore from './store';

const store = new MedicineStore();

const MedicineStateContext = createContext<MedicineStore>(store);

export const MedicineProvider: React.FC = ({ children }) => (
  <MedicineStateContext.Provider value={store}>{children}</MedicineStateContext.Provider>
);

export const useMedicineStore = (): MedicineStore => useContext(MedicineStateContext);
