import styled, { CSSObject } from 'styled-components';
import { BASIC_FONT_FAMILY, COLORS } from 'utils/constants';
import { GroupTypeBase, Styles } from 'react-select';

export const textStyles: CSSObject = {
  margin: '0',
  fontSize: '16px',
  lineHeight: '24px',
  padding: '7px 12px',
  fontFamily: BASIC_FONT_FAMILY,
  fontWeight: 400,
};

export const customStyles: Partial<
  Styles<
    {
      label: string;
      value: string;
    },
    false,
    GroupTypeBase<{
      label: string;
      value: string;
    }>
  >
> = {
  control: (provided, state) => ({
    ...provided,
    cursor: 'pointer',
    backgroundColor: COLORS.white,
    borderRadius: state.menuIsOpen ? '2px 2px 0 0' : '2px',
    borderColor: `${state.menuIsOpen ? COLORS.white : COLORS.gray5} !important`,
    boxShadow: state.menuIsOpen ? '0 6px 20px rgba(0, 0, 0, 0.08)' : 'none',
  }),

  input: (provided) => ({
    ...provided,
    ...textStyles,
  }),

  menu: (provided) => ({
    ...provided,
    margin: '0',
    padding: '0',
    zIndex: 9999999,
    borderRadius: '0 0 2px 2px',
    boxShadow: '0 6px 20px rgba(0, 0, 0, 0.08)',
    fontFamily: BASIC_FONT_FAMILY,
    fontWeight: 400,
  }),

  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999999,
  }),

  option: (provided, state) => ({
    ...provided,
    cursor: 'pointer',
    padding: '4px 12px 8px 12px',
    color: state.isSelected || state.isFocused ? COLORS.secondary : COLORS.gray3,
    backgroundColor: state.isSelected || state.isFocused ? COLORS.gray7 : COLORS.white,
  }),

  valueContainer: (provided) => ({
    ...provided,
    overflow: 'visible',
  }),

  singleValue: (provided, state) => ({
    ...provided,
    ...textStyles,
    color: state.isDisabled ? COLORS.gray4 : COLORS.gray3,
  }),

  multiValue: (provided) => ({
    ...provided,
    background: COLORS.primary,
    color: COLORS.white,
    margin: '4px 8px',
  }),

  multiValueLabel: (provided) => ({
    ...provided,
    color: COLORS.white,
    margin: '4px',
  }),

  multiValueRemove: (provided) => ({
    ...provided,
    ':hover': {
      backgroundColor: COLORS.secondary,
    },
  }),

  placeholder: (provided, state) => ({
    ...provided,
    ...textStyles,
    color: state.hasValue ? COLORS.gray2 : COLORS.gray4,
    position: 'relative',
    top: '20px',
  }),

  indicatorSeparator: () => ({
    display: 'none',
  }),

  menuList: (base) => ({
    ...base,
    marginRight: '13px',
    marginTop: '5px',
    '::-webkit-scrollbar': {
      width: '4px',
      height: '0px',
    },
    '::-webkit-scrollbar-track': {
      background: COLORS.white,
    },
    '::-webkit-scrollbar-thumb': {
      background: COLORS.gray4,
      borderRadius: '3px',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: COLORS.primary,
    },
  }),
};

export const InputLabelHidden = styled.label`
  font-size: 12px;
  line-height: 16px;
  color: ${COLORS.gray};
`;

export const InputLabel = styled.label`
  font-size: 12px;
  line-height: 16px;
  color: ${COLORS.gray};
`;

export const InputDiv = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const CheckboxLabel = styled.label`
  display: block;
  font-size: 16px;
  margin-top: 5px;
  padding-left: 2em;
  width: fit-content;
  pointer-events: none;
`;
export const CheckboxWrapper = styled.span`
  position: absolute;
  margin-left: -2em;
  width: 20px;
  height: 20px;
  border: 1px solid ${COLORS.primary};
  border-radius: 2px;
  cursor: pointer;
`;
export const CheckboxInput = styled.input`
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
  &:focus + ${CheckboxWrapper} {
    border: 2px solid ${COLORS.primary};
  }
  &:checked + ${CheckboxWrapper} {
    background-color: ${COLORS.primary};
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='10' viewBox='0 0 14 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 4.5L5 8.5L12.5 1' stroke='white' stroke-width='2' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
  }
`;
