import React, { useMemo, useState } from 'react';

import { ServicesBannerValue } from 'web';

import { useDeviceType } from 'utils/hooks';
import { HtmlRenderer } from 'utils/htmlParser';
import HtmlContainer from 'components/base-ui/html-container';

import {
  BannerContent,
  BannerInner,
  BannerNext,
  BannerOuter,
  BannerWrapper,
  InnerCircle,
  StyledParagraph,
  StyledSection,
} from './styles';
import Brief from './brief';
import Detail from './detail';

type ServicesBannerProps = ServicesBannerValue & { compact?: boolean };

const ServicesBanner: React.FC<ServicesBannerProps> = ({
                                                         additionalDescription,
                                                         header,
                                                         preview,
                                                         text,
                                                         textColumns,
                                                         link,
                                                         subscribeFromPage,
                                                         image,
                                                         buttonAction,
                                                         dynamicApplicationForm,
                                                         ...props }) => {
  const [active, setActive] = useState(false);

  const deviceType = useDeviceType();

  const isMobile = useMemo(() => deviceType === 'mobile', [deviceType]);

  const showCircle = !isMobile && !active;

  return (
    <>
      <BannerWrapper>
        <BannerOuter src={image}>
          <BannerInner active={active}>{showCircle && <InnerCircle />}</BannerInner>
          <BannerNext />
        </BannerOuter>

        <BannerContent>
          {!active ? (
            <Brief
              header={header}
              preview={preview}
              textColumns={textColumns}
              buttonLink={{ link, text }}
              onClick={() => setActive(() => true)}
              subscribeFromPage={subscribeFromPage}
              buttonAction={buttonAction}
              appFormId={dynamicApplicationForm}
            />
          ) : (
            <Detail
              text={props.secondStateDescription}
              buttonLink={{ link, text }}
              onClick={() => setActive(() => false)}
            />
          )}
        </BannerContent>
      </BannerWrapper>

      <StyledSection compact paddingless noFirstOfTypeOffset>
        <StyledParagraph title={additionalDescription.header} spoiler={additionalDescription.spoiler}>
          <HtmlContainer>
            <HtmlRenderer html={additionalDescription.text} />
          </HtmlContainer>
        </StyledParagraph>
      </StyledSection>
    </>
  );
};

export default ServicesBanner;
