import React from 'react';
import AnimateHeight from 'react-animate-height';
import styled, { css } from 'styled-components';

import { ProductList } from 'services';

import { useToggle } from 'utils/hooks';
import { COLORS } from 'utils/constants';

import Link from 'components/base-ui/link';
import IconButton from 'components/base-ui/icon-button';
import HtmlContainer from 'components/base-ui/html-container';
import { HtmlRenderer } from 'utils/htmlParser';

const Div = styled.div`
  height: 100%;
  position: relative;
`;

const Head = styled.div`
  height: 100%;
  display: grid;
  position: relative;
  padding: 12px 8px 12px 0;
  grid-auto-flow: column;
  grid-template-columns: 1fr auto;

  &:after {
    content: '';
    z-index: 50;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    position: absolute;
    background: ${COLORS.gray5};
  }
`;

const Title = styled(Link)`
  font-size: 17px;
  line-height: 20px;
  color: ${COLORS.secondary};
`;

const TitleContainer = styled(HtmlContainer)`
  font-size: inherit;
  line-height: inherit;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

interface ToggleButtonProps {
  active?: boolean;
}

const activeStyles = css`
  transform: rotate(-90deg);
`;

const ToggleButton = styled(IconButton)<ToggleButtonProps>`
  transform: rotate(90deg);
  transition: transform 0.5s ease-out;

  ${(props) => props.active && activeStyles}
`;

const Body = styled.div`
  top: 100%;
  z-index: 50;
  width: 100%;
  padding-top: 12px;
  margin-bottom: 12px;
  position: absolute;
  text-align: start;
  color: ${COLORS.gray3};
  background-color: ${COLORS.white};
`;

const BodyContent = styled.div`
  overflow: hidden;
  max-height: 260px;
  font-size: 14px !important;
  line-height: 20px !important;

  p {
    &:last-child {
      margin: 0;
    }
  }
`;

interface DropdownProps {
  data: Omit<ProductList, 'description'>;
  to: string;
  className?: string;
  children: React.ReactNode;
}

const Dropdown: React.FC<DropdownProps> = ({ data, to, className, children }) => {
  const [active, toggleActive] = useToggle();

  return (
    <Div className={className}>
      <Head>
        <Title to={to}>
          <TitleContainer marginless>
            <HtmlRenderer html={data.name} />
          </TitleContainer>
        </Title>

        <ToggleButton name="iconPointer" round active={active} small onClick={toggleActive} />
      </Head>

      <Body>
        <AnimateHeight duration={500} height={active ? 'auto' : 0}>
          <BodyContent>{children}</BodyContent>
        </AnimateHeight>
      </Body>
    </Div>
  );
};

export default Dropdown;
