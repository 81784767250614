import React from 'react';
import { declationsOfNumerals } from 'utils/helpers';
import Tooltip from 'react-tooltip';
import { COLORS } from 'utils/constants';
import avatar from 'assets/img/assol_avatar.png';
import { StAvatar, StContainer, StContent, StTitle, StTooltipText } from './styled';
import { useLikedUsers } from './hooks/useLikedUsers';

const DEC = ['человеку', 'людям', 'людям'];

interface Props {
  cardType: 'news' | 'guide' | 'article' | 'product';
  id: number | null;
  limitPerPage: number;
}

export const LikeModalContent: React.FC<Props> = (props) => {
  const element = document.getElementById('liked-users-content');
  const [fetching, setFetching] = React.useState(false);

  const { likedUsers, likesCount, updateData } = useLikedUsers(props.cardType, props.id, props.limitPerPage);

  React.useEffect(() => {
    if (!element) return;

    const handleScroll = () => {
      if (!element) return;
      if (element.clientHeight + element.scrollTop !== element.scrollHeight) return;
      setFetching(true);
    };

    element.addEventListener('scroll', handleScroll);
    return () => element?.removeEventListener('scroll', handleScroll);
  }, [element]);

  React.useEffect(() => {
    if (!fetching) return;
    updateData();
    setFetching(false);
  }, [fetching]);

  return (
    <StContainer>
      {likedUsers?.length > 0 ? (
        <>
          <StTitle>{`Понравилось ${likesCount} ${declationsOfNumerals(likesCount, DEC)}`}</StTitle>
          <StContent id="liked-users-content">
            {likedUsers?.map((user) => (
              <>
                <StAvatar key={user.id} data-tip data-for={user.id.toString()} bg={user.image || avatar} />
                <Tooltip id={user.id.toString()} delayHide={10} effect="solid" backgroundColor={COLORS.secondary}>
                  <StTooltipText> {user.fullName || user.email}</StTooltipText>
                </Tooltip>
              </>
            ))}
          </StContent>
        </>
      ) : (
        'Загрузка ...'
      )}
    </StContainer>
  );
};
