import React, { useState, useEffect } from 'react';
import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { HeadingPreferences } from 'web';

import { NewsPage, ProductList } from 'services';

import { useCategoryStore, useProductStore } from 'store';

import { api } from 'utils/api';
import { BREAKPOINTS, COLORS, DEFAULT_PREFERENCES } from 'utils/constants';

import Breadcrumbs from 'components/base-ui/breadcrumbs';
import Description from 'components/base-ui/description';
import BaseSection from 'components/base-ui/section';
import Addresses from 'components/base-ui/addresses';
import Table from 'components/base-ui/table';

import { MapRenderer } from 'components/renderers';

import Cards from 'components/goods/shared/cards';
import NewsCards from 'components/news/list/cards';
import Content from './content';
import Comments from './comments';

const Div = styled.div`
  position: relative;
  padding: 68px 0 58px 0;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 68px 56px 80px 56px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding: 56px 136px 124px 136px;
  }
`;

const Section = styled.section`
  width: 100%;
`;

const StyledSection = styled(BaseSection)`
  margin-bottom: 36px;

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    margin-bottom: 48px;
  }
`;

const ItemBaseSection = styled(BaseSection)`
  h5 {
    position: relative;
    padding-bottom: 26px;
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      border-bottom: 1px solid ${COLORS.gray5};
    }
  }
`;

const GoodsItem: React.FC = observer(() => {
  const [preferences, setPreferences] = useState<HeadingPreferences>(DEFAULT_PREFERENCES);

  const productStore = useProductStore();
  const categoryStore = useCategoryStore();

  const { productId, categoryId } = useParams<{ productId: string; categoryId: string }>();

  useEffect(() => {
    api.productPagePreferences.productPagePreferencesList().then((res) =>
      setPreferences(() => ({
        articlesTitle: res.data.articlesTitle ?? DEFAULT_PREFERENCES.articlesTitle,
        mayInterestTitle: res.data.mayInterestTitle ?? DEFAULT_PREFERENCES.mayInterestTitle,
      })),
    );
  }, [setPreferences]);

  useEffect(() => {
    if (productStore.product) {
      document.title = productStore.product.name.replace(/(<([^>]+)>)/gi, '') || '';
    }
  }, [productStore.product]);

  useEffect(() => autorun(() => productStore.fetchProduct(+productId)), [productStore, productId]);

  useEffect(() => autorun(() => categoryStore.fetchCategory({ id: +categoryId })), [categoryStore, categoryId]);

  return productStore.productFetching ? null : (
    <Div>
      <Section>
        <Breadcrumbs />
      </Section>

      <Section>{productStore.product && <Content data={productStore.product} />}</Section>

      <StyledSection>
        <Description value={true} title="Описание" text={productStore.product?.description ?? ''} />
      </StyledSection>

      <StyledSection>
        <Comments items={productStore.product?.comments ?? []} />
      </StyledSection>

      <BaseSection heading="Адреса" borderless marginless paddingless>
        <Addresses />
      </BaseSection>

      {categoryStore.category?.mapFrame && (
        <Section>
          <MapRenderer mapFrame={categoryStore.category.mapFrame} />
        </Section>
      )}

      {productStore.relatedProducts.length !== 0 && (
        <ItemBaseSection heading={preferences.mayInterestTitle} borderless marginless paddingless>
          <Table<ProductList, never> cards={<Cards items={productStore.relatedProducts as ProductList[]} />} />
        </ItemBaseSection>
      )}

      {productStore.relatedNews.length !== 0 && (
        <ItemBaseSection heading={preferences.articlesTitle} borderless marginless paddingless>
          <Table<NewsPage, never> cards={<NewsCards items={productStore.relatedNews as NewsPage[]} />} />
        </ItemBaseSection>
      )}
    </Div>
  );
});

export default GoodsItem;
