import { action, computed, makeObservable, observable } from 'mobx';
import _ from 'lodash';
import AbstractStore from 'store/AbstractStore';
import { api } from 'utils/api';
import { SearchResult } from 'services';

export type SearchModel =
  | 'products'
  | 'banking_products'
  | 'media_products'
  | 'insurance_products'
  | 'news_pages'
  | 'guide_pages'
  | 'magazine_article';

interface SearchResultPages {
  products: number;
  bankingProducts: number;
  mediaProducts: number;
  insuranceProducts: number;
  newsPages: number;
  guidePages: number;
  magazineArticle: number;
}

export default class SearchStore extends AbstractStore {
  searchData: SearchResult | null = null;

  pages: SearchResultPages = {
    bankingProducts: 1,
    guidePages: 1,
    insuranceProducts: 1,
    mediaProducts: 1,
    newsPages: 1,
    products: 1,
    magazineArticle: 1,
  };

  limit = 5;

  constructor() {
    super();
    makeObservable(this, {
      ...this.annotations,
      searchData: observable,
      pages: observable,
      setSearchData: action,
      fetchSearch: action,
      guideData: computed,
      groupedProducts: computed,
      notFound: computed,
      setPages: action,
      fetchNext: action,
      paginatedSearch: action,
      onPaginate: action,
      // fieldData: action,
      // fieldCount: action,
    });
  }

  get notFound() {
    if (this.fetching) return false;
    if (!this.searchData) return true;
    // @ts-ignore
    return Object.values(this.searchData).reduce((a, cv) => (a += cv.count), 0) === 0;
  }

  fieldData(field: keyof SearchResult) {
    return this.searchData ? this.searchData[field]?.results || [] : [];
  }

  fieldCount(field: keyof SearchResult) {
    return this.searchData ? this.searchData[field]?.count || 0 : 0;
  }

  onPaginate(searchValue: string, field: keyof SearchResult, model: SearchModel, page: number, categoryId?: number) {
    const pages = this.pages;
    pages[field] = page;
    this.setPages(pages);
    this.paginatedSearch(searchValue, page, model, categoryId);
  }

  paginatedSearch = (searchValue: string, page: number, models: SearchModel, categoryId?: number) => {
    this.fetchData(() =>
      api.search
        .searchList({ searchPhrase: searchValue, limit: this.limit, page, categoryId, models: [models] })
        .then((res) => {
          const currentData = this.searchData;
          const key = _.camelCase(models) as keyof SearchResultPages;
          if (currentData) {
              //@ts-ignore
            currentData[key] = res.data[key];
            this.setSearchData(currentData);
          }
        }),
    );
  };

  fieldPage = (field: keyof SearchResultPages) => {
    return this.pages[field];
  };

  get groupedProducts() {
    return _.groupBy(this.searchData?.products?.results, 'category');
  }

  fetchSearch = (searchValue: string) => {
    this.fetchData(() =>
      api.search.searchList({ searchPhrase: searchValue, limit: this.limit }).then((res) => {
        this.setSearchData(res.data);
      }),
    );
  };

  fetchNext = (searchValue: string, field: keyof SearchResult, categoryId?: number) => {
    this.fetchData(() => {
      const model = _.snakeCase(field) as SearchModel;
      const page = Math.ceil(this.fieldData(field).length / this.limit) + 1;
      return api.search
        .searchList({ searchPhrase: searchValue, models: [model], categoryId, limit: this.limit, page })
        .then(({ data }) => {
          const currentData = this.searchData;
          if (currentData) {
              //@ts-ignore
            const { count, results } = data[field];
            currentData[field]!.count = count;
            currentData[field]!.results?.push(...results);
            this.setSearchData(currentData);
          }
        });
    });
  };

  get guideData() {
    return this.searchData?.guidePages?.results || [];
  }

  setSearchData(data: SearchResult) {
    this.searchData = data;
  }

  setPages(data: SearchResultPages) {
    this.pages = data;
  }
}
