import React, { FC, ReactElement } from 'react';
import { Nullable } from 'web';
import { WarningPropsType } from 'components/base-ui/warning-popup/types';
import { parseHtml } from 'utils/htmlParser';
import {
  WarningWrapper,
  WarningContainer,
  WarningHeader,
  WarningBody,
  WarningDetail,
  WarningCloseButton,
} from './styles';

export const WarningPopup: FC<WarningPropsType> = ({
  header,
  description,
  link,
  handleCloseErrorScreen,
}): Nullable<ReactElement> => {
  return (
    <>
      {' '}
      <WarningWrapper>
        <WarningContainer>
          {header && <WarningHeader>{header}</WarningHeader>}
          <WarningCloseButton name="iconCrossBase" small={true} onClick={handleCloseErrorScreen} />
          {description && <WarningBody>{parseHtml(description)}</WarningBody>}
          {link && <WarningDetail href={link}>Подробнее</WarningDetail>}
        </WarningContainer>
      </WarningWrapper>
    </>
  );
};
