import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';
import { ReactComponent as IconDownSvg } from '../../../../assets/icons/icon_down.svg';

export const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  margin-bottom: 24px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 0 61px;
    margin-bottom: 0;
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    flex-direction: row;
    padding: 0 139px;
    column-gap: 128px;
    align-items: flex-end;
  }
`;

export const StyledButton = styled.div`
  display: flex;
  gap: 8px;

  margin-left: auto;
  margin-top: 16px;

  cursor: pointer;
`;

export const StyledIconDown = styled(IconDownSvg)`
  width: 24px;
  height: 24px;
  transition: all 0.2s ease-out;
  & * {
    fill: ${COLORS.secondary};
  }
  &.expanded {
    transform: rotate(180deg);
  }
  &.collapsed {
    transform: rotate(0deg);
  }
`;

export const StyledButtonText = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  padding-bottom: 8px;

  text-transform: uppercase;
  white-space: nowrap;
  color: ${COLORS.secondary};

  svg {
    width: 24px;
    height: 24px;
    & * {
      fill: ${COLORS.secondary};
    }
  }
`;
