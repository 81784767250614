import React from 'react';
import styled from 'styled-components';

import { Informer } from '../typo';

import { COLORS } from '../../../utils/constants';

const BadgeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  padding: 0 7px;
  border-radius: 20px;
  background-color: ${COLORS.white};
`;

const BadgeText = styled(Informer)`
  line-height: 1em;
  font-weight: 400;
  color: ${COLORS.secondary};
`;

export const Badge: React.FC = ({ children }) => {
  return (
    <BadgeWrapper>
      <BadgeText>{children}</BadgeText>
    </BadgeWrapper>
  );
};

export default Badge;
