import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import TimeField from 'react-simple-timefield';
import styled from 'styled-components';
import Input from 'components/base-ui/input';
import { COLORS } from 'utils/constants';
import { RestaurantSchedule } from 'services';
import { api } from 'utils/api';
import { formatInputDate } from 'utils/date';
import { ErrorFormMessage } from '..';
import { WidgetProps } from '../types';

const Wrapper = styled.label<{ isEdited: boolean }>`
  .time-widget-input {
    ${(props) => {
      if (!props.isEdited) {
        return `color:${COLORS.gray4}!important`;
      }
    }}
`;

export const TimeWidget: React.FC<WidgetProps<string>> = (props) => {
  const { values, formTitle } = props;

  const errorText = 'Недоступное для брони время';

  const [restaurantSchedule, setRestaurantSchedule] = useState<RestaurantSchedule | null>(null);
  const [scheduleError, setScheduleError] = useState<string | null>(null);

  const currentDate = useMemo(() => {
    return new Date();
  }, []);

  const form = useFormContext();
  // @ts-ignore
  const watchDate = form.watch('date');

  const timeSlice = (time: string) => time.slice(0, 5);

  const title = useMemo(() => props.values.description, [props.values]);

  const labelScheduleText = useMemo(() => {
    if (restaurantSchedule && restaurantSchedule.endTime > restaurantSchedule.startTime) {
      return `Ресторан работает с ${timeSlice(restaurantSchedule.startTime)} до ${timeSlice(
        restaurantSchedule.endTime,
      )}`;
    }

    if (restaurantSchedule && restaurantSchedule.endTime < restaurantSchedule.startTime) {
      return `Ресторан работает с ${timeSlice(restaurantSchedule.startTime)} до ${timeSlice(
        restaurantSchedule.endTime,
      )}. Последнее время бронирования - 23:30`;
    }

    return scheduleError;
  }, [restaurantSchedule, scheduleError]);

  const label = useMemo(() => `${title} (${labelScheduleText})`, [title, labelScheduleText]);

  useEffect(() => {
    api.restaurantSheldue
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      .restaurantScheduleDay({ date: watchDate ?? formatInputDate(currentDate.toISOString()) })
      .then((res) => {
        setScheduleError(null);
        setRestaurantSchedule(res.data as unknown as RestaurantSchedule);
      })
      .catch((err) => {
        setRestaurantSchedule(null);
        setScheduleError('Забронировать столик на эту дату невозможно. Пожалуйста, обратитесь в службу поддержки.');
        console.warn(err);
      });
  }, [watchDate]);

  const [isEdited, setIsEdited] = useState(false);

  const rules = useMemo(() => {
    return {
      required: {
        value: values.required,
        message: 'Обязательное поле',
      },
    };
  }, [props.values.required]);

  const isRestaurant = formTitle?.toLocaleLowerCase().includes('блок');

  useEffect(() => {
    const startWorkTime = new Date();
    const endWorkTime = new Date();
    const currentTime = new Date();

    startWorkTime.setHours(Number(restaurantSchedule?.startTime?.slice(0, 2)));
    startWorkTime.setMinutes(Number(restaurantSchedule?.startTime?.slice(3, 5)));

    currentTime.setHours(Number(form.getValues('time')?.slice(0, 2)));
    currentTime.setMinutes(Number(form.getValues('time')?.slice(3, 5)));

    endWorkTime.setHours(
      restaurantSchedule && restaurantSchedule.endTime < restaurantSchedule.startTime
        ? 23
        : Number(restaurantSchedule?.endTime.slice(0, 2)),
    );
    endWorkTime.setMinutes(
      restaurantSchedule && restaurantSchedule.endTime < restaurantSchedule.startTime
        ? 30
        : Number(restaurantSchedule?.endTime.slice(3, 5)),
    );

    if (isRestaurant) {
      if (
        currentTime < startWorkTime ||
        (restaurantSchedule &&
          restaurantSchedule.startTime > restaurantSchedule.endTime &&
          currentTime > endWorkTime) ||
        (restaurantSchedule && restaurantSchedule.startTime < restaurantSchedule.endTime && currentTime >= endWorkTime)
      ) {
        form.setError('timeError', { type: 'custom', message: errorText });
      } else {
        form.clearErrors('timeError');
      }
    }
  }, [form.watch('time'), restaurantSchedule, watchDate]);

  return (
    <div>
      <Wrapper isEdited={isEdited}>
        <Controller
          name={values.slug}
          control={form.control}
          rules={rules}
          render={({ value, onChange }) => (
            <TimeField
              value={value}
              colon=":"
              onChange={(val) => {
                setIsEdited(true);
                onChange(val);
              }}
              input={
                <Input
                  id={values.slug}
                  label={isRestaurant && watchDate ? label : title}
                  value={value}
                  className="time-widget-input"
                  autoComplete="off"
                />
              }
            />
          )}
        />
        <ErrorFormMessage>
          <ErrorMessage errors={form.errors} name={values.slug} />
          <ErrorMessage errors={form.errors} name="timeError" />
        </ErrorFormMessage>
      </Wrapper>
    </div>
  );
};
