import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { Nullable } from 'web';

import { Preferences } from 'services';

import { useProfileStore } from 'store';

import { api } from 'utils/api';
import { BREAKPOINTS, COLORS } from 'utils/constants';

import Icon from 'components/base-ui/icon';
import Section from 'components/base-ui/section';
import { Micro } from 'components/base-ui/typo';
import { OutlineButton } from 'components/base-ui/button';

import Alert from 'components/profile/tabs/alert';
import UserInfo from './user-info';
import UserChildren from './user-children';
import UserHobbies from './user-hobbies';

const Div = styled.div``;

const ActionsWrapper = styled.div`
  display: grid;
  row-gap: 16px;
  column-gap: 16px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    grid-template-columns: repeat(2, 208px);
  }
`;

const HeadingContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr auto;
`;

const HeadingButton = styled(OutlineButton)`
  padding: 0;
  min-width: 0;
  border: none;
  text-transform: none;
  font-size: 12px;
  margin-top: -11px;
  align-items: center;
  display: flex;
  img {
    margin-left: 11px;
  }

  align-items: center;
  display: inline-flex;

  color: ${COLORS.primary};

  &:disabled {
    color: ${COLORS.gray5};
  }

  &:hover,
  &:focus,
  &:active {
    background: #fff;
    color: ${COLORS.secondary};
  }
`;

const HeadingText = styled(Micro)`
  color: inherit;
  margin-right: 8px;
  text-transform: lowercase;
`;

const HeadingIcon = styled(Icon)`
  width: 16px;
  height: 16px;
`;

const ResetButton = styled(OutlineButton)`
  color: ${COLORS.gray5};
  background: transparent;
  border-color: ${COLORS.gray5};
`;

const SaveButton = styled(OutlineButton)`
  color: ${COLORS.gray5};
  border-color: ${COLORS.primary};
  background: ${COLORS.linearGradient};

  &:disabled {
    background: transparent;
  }
`;

const TabSection = styled(Section)`
  position: relative;
  z-index: 1;
`;

const HomeTab: React.FC = observer(() => {
  const profileStore = useProfileStore();
  const [profileSetting, setProfileSetting] = useState<Nullable<Preferences>>(null);

  useEffect(() => {
    api.preferences.preferencesList().then((res) => setProfileSetting(res.data));
  }, []);

  const onSave = () => {
    profileStore.updateUser();
  };

  const onDiscard = () => {
    profileStore.discardChanges();
  };

  return (
    <Div>
      <Section paddingless marginless noMinHeight noFirstOfTypeOffset>
        <Alert />
      </Section>

      <Section paddingless marginless>
        <UserInfo />
      </Section>

      {profileSetting?.showChildren && (
        <Section
          heading={
            <HeadingContainer>
              <div>Дети</div>

              <HeadingButton onClick={profileStore.addEmptyChild}>
                <HeadingText>Добавить ребенка</HeadingText>
                <HeadingIcon name="iconPlus" />
              </HeadingButton>
            </HeadingContainer>
          }
          marginless
          paddinglessHead
          paddingless
        >
          <UserChildren />
        </Section>
      )}

      {profileSetting?.showHobbies && (
        <Section heading="Хобби" marginless paddinglessHead>
          <UserHobbies />
        </Section>
      )}

      <TabSection paddingless marginless borderless>
        <ActionsWrapper>
          <ResetButton onClick={onDiscard}>Сбросить</ResetButton>
          <SaveButton onClick={onSave}>Сохранить</SaveButton>
        </ActionsWrapper>
      </TabSection>
    </Div>
  );
});

export default HomeTab;
