import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { CategoryProps, SubstitutedCategoryType } from 'web';

import { ProductList } from 'services';

import { BREAKPOINTS } from 'utils/constants';

import Item from './item';

const Div = styled.div`
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin-bottom: 121px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    margin-bottom: 112px;
  }
`;

const Grid = styled.div`
  padding: 0 16px 16px 16px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 0 32px 16px 32px;
  }
`;

interface ListProps {
  items: ProductList[];
  category?: CategoryProps;
}

const List: React.FC<ListProps> = ({ items, category }) => {
  const { categoryType, categoryId } = useParams<{ categoryType: SubstitutedCategoryType; categoryId: string }>();

  const memoizedCategory = useMemo((): CategoryProps => {
    return category || { id: categoryId, type: categoryType };
  }, [category, categoryId, categoryType]);

  return (
    <Div>
      <Grid>
        {items.map((el) => (
          <Item key={el.name} data={el} category={memoizedCategory} />
        ))}
      </Grid>
    </Div>
  );
};

export default List;
