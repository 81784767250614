import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';
import { ReactComponent as IconDownSvg } from 'assets/icons/icon_down.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ButtonText = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  text-transform: uppercase;
  white-space: nowrap;
  color: ${COLORS.secondary};

  svg {
    width: 24px;
    height: 24px;
    & * {
      fill: ${COLORS.secondary};
    }
  }
`;

const Button = styled.div`
  display: flex;
  gap: 8px;

  margin-left: auto;
  margin-top: 16px;

  cursor: pointer;
`;

const Columns = styled.div`
  display: flex;
  gap: 8px;
`;

const Title = styled.span`
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  color: ${COLORS.gray2};
  margin-bottom: 40px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-size: 28px;
    line-height: 32px;
  }
`;

const IconDown = styled(IconDownSvg)`
  width: 24px;
  height: 24px;
  transition: all 0.2s ease-out;
  & * {
    fill: ${COLORS.secondary};
  }
  &.expanded {
    transform: rotate(180deg);
  }
  &.collapsed {
    transform: rotate(0deg);
  }
`;

export const Styled = { Columns, Button, ButtonText, Container, Title, IconDown };
