import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { CategoryProps, SubstitutedCategoryType } from 'web';

import { ProductList } from 'services';

import { BREAKPOINTS } from 'utils/constants';

import { Container, Grid } from 'components/base-ui/cards';
import Item from './item';

const StyledContainer = styled(Container)`
  margin-bottom: 144px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin-bottom: 168px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    margin-bottom: 114px;
  }
`;

const StyledGrid = styled(Grid)`
  row-gap: 48px;
  column-gap: 62px;
  grid-auto-rows: 472px;
  padding: 0 32px 16px 32px;
`;

interface CardsProps {
  items: ProductList[];
  category?: CategoryProps;
  onShowLikeModal?: (id: number) => void;
}

const Cards: React.FC<CardsProps> = ({ items, category, onShowLikeModal }) => {
  const { categoryType, categoryId } = useParams<{ categoryType: SubstitutedCategoryType; categoryId: string }>();

  const memoizedCategory = useMemo((): CategoryProps => {
    return category ?? { id: categoryId, type: categoryType };
  }, [category, categoryId, categoryType]);

  return (
    <StyledContainer>
      <StyledGrid mode="auto-columns" width="256px">
        {items.map((el) => (
          <Item key={el.name} data={el} category={memoizedCategory} onShowLikeModal={onShowLikeModal} />
        ))}
      </StyledGrid>
    </StyledContainer>
  );
};

export default Cards;
