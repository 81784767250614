import { observable, makeAutoObservable, runInAction } from 'mobx';

import { Nullable } from 'web';

import { api } from 'utils/api';

import {
  MedicineProgram,
  MedicineCenter,
  MedicineProgramsApiMedicineProgramsListRequest,
  MedicineCategoryPage,
  MedicineDirection,
  MedicineCentersApiMedicineCentersReadRequest,
  MedicineCentersApiMedicineCentersDirectionsRequest,
} from 'services';
import { ExtendedMedicineDirection } from 'components/medicine/types';
import { AxiosError } from 'axios';

export default class MedicineCenterStore {
  fetching = false;

  error: Nullable<Error> = null;

  count = 0;

  page: Nullable<MedicineCategoryPage> = null;

  programs: MedicineProgram[] = [];

  withAccommodation: MedicineProgram[] = [];

  withoutAccommodation: MedicineProgram[] = [];

  medicineCenter: Nullable<MedicineCenter> = null;

  medicineDirections: ExtendedMedicineDirection[] = [];

  activeDirections: Set<number> = observable.set(new Set<number>());

  constructor() {
    makeAutoObservable(this);
  }

  fetchMedicineCenter(props: MedicineCentersApiMedicineCentersReadRequest, options?: unknown): Promise<void> {
    this.setError(null);
    this.setFetching(true);
    return api.medicineCenters
      .medicineCentersRead(props, options)
      .then((res) => {
        runInAction(() => {
          this.setMedicineCenter(res.data);
        });
      })
      .catch((err: Error) => this.setError(err))
      .finally(() => this.setFetching(false));
  }

  fetchMedicineCenterDirections(
    props: MedicineCentersApiMedicineCentersDirectionsRequest,
    options?: unknown,
  ): Promise<void> {
    this.setError(null);
    this.setFetching(true);
    return api.medicineCenters
      .medicineCentersDirections(props, options)
      .then((res) => {
        runInAction(() => {
          this.setMedicineDirections(res.data as unknown as MedicineDirection[]);
        });
      })
      .catch((err: AxiosError) => this.setError(err))
      .finally(() => this.setFetching(false));
  }

  fetchPrograms(props?: MedicineProgramsApiMedicineProgramsListRequest, options?: unknown): Promise<void> {
    this.setError(null);
    this.setFetching(true);
    return api.medicinePrograms
      .medicineProgramsList(props, options)
      .then((res) => {
        runInAction(() => {
          this.setCount(res.data.count);
          this.setPrograms(res.data.results);
          this.setWithoutAccommodation(res.data.results.filter((program) => !program.hasResidence));
          this.setWithAccommodation(res.data.results.filter((program) => program.hasResidence));
        });
      })
      .catch((err: Error) => this.setError(err))
      .finally(() => this.setFetching(false));
  }

  setFetching(value: boolean): void {
    this.fetching = value;
  }

  setError(value: Nullable<Error>): void {
    this.error = value;
  }

  setCount(value: number): void {
    this.count = value;
  }

  setPage(value: MedicineCategoryPage): void {
    this.page = value;
  }

  setPrograms(value: MedicineProgram[]): void {
    this.programs = value;
  }

  private setWithAccommodation(value: MedicineProgram[]): void {
    this.withAccommodation = value;
  }

  private setWithoutAccommodation(value: MedicineProgram[]): void {
    this.withoutAccommodation = value;
  }

  toggleDirections(id: number): void {
    const isActive = this.activeDirections.has(id);
    this.medicineDirections = this.medicineDirections.map((item) => {
      return item.id === id ? { ...item, active: !isActive } : item;
    });
    if (isActive) {
      this.activeDirections.delete(id);
    } else {
      this.activeDirections.add(id);
    }
    if (this.centerId) {
      this.fetchPrograms({
        centerId: Number(this.centerId),
        directionsId: Array.from(this.activeDirections) as unknown as number,
      });
    }
  }

  setMedicineCenter(value: MedicineCenter): void {
    this.medicineCenter = value;
  }

  setMedicineDirections(value: MedicineDirection[]): void {
    this.medicineDirections = value;
  }

  fetchMedicineCenterPageData = (centerId: number, limit?: number): void => {
    this.fetchMedicineCenter({ id: centerId }).catch((err: AxiosError) => this.setError(err));
    this.fetchPrograms({ centerId, page: 1, limit }).catch((err: AxiosError) => this.setError(err));
    this.fetchMedicineCenterDirections({ id: centerId }).catch((err: AxiosError) => this.setError(err));
  };

  get centerId() {
    return this.medicineCenter?.id;
  }

  cleanUp = (): void => {
    this.page = null;
    this.programs = [];
    this.medicineCenter = null;
    this.medicineDirections = [];
    this.activeDirections = new Set<number>();
    this.error = null;
  };
}
