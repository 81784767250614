import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

const StyledMain = styled.main`
  max-width: inherit;
`;

const Main: React.FC = (props) => {
  return <StyledMain>{props.children}</StyledMain>;
};

export default Main;
