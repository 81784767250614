import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import { useDeviceState } from 'hooks/useDeviceProvider';
import { MedicineCenter } from 'services';
import { FreeMode } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperType } from 'swiper/types';

import { Styled } from './styles';
import { CarouselItem } from './CarouselItem';
import 'swiper/swiper.min.css';

export const Carousel: React.VFC<{
  data: MedicineCenter[];
}> = (props) => {
  const swiperRef = useRef<SwiperType>();
  const containerRef = useRef<HTMLDivElement | null>(null);

  const { isLessDesktop } = useDeviceState();
  const slidesPerView = useMemo(() => (isLessDesktop ? 2 : 3), []);
  const withoutSwiper = useMemo(() => {
    return isLessDesktop ? props.data.length <= 2 : props.data.length <= 3;
  }, [props.data.length, isLessDesktop]);

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.update();
    }
  }, [isLessDesktop]);

  const filteredCenters = useMemo(
    () => props.data.filter((item) => typeof item.image === 'string'),
    [props.data, slidesPerView],
  );
  const filledCenters = useMemo(() => {
    return filteredCenters.length % slidesPerView > 0
      ? filteredCenters.slice(0, slidesPerView - (filteredCenters.length % slidesPerView))
      : [];
  }, [props.data, slidesPerView]);

  const handleOnPrevClick = useCallback(() => swiperRef.current?.slidePrev(), [swiperRef.current]);
  const handleOnNextClick = useCallback(() => swiperRef.current?.slideNext(), [swiperRef.current]);

  if (withoutSwiper) {
    return (
      <Styled.CardsWrapper>
        {filteredCenters.map((value) => (
          <CarouselItem key={value.id} src={value.image!} title={value.title} id={value.id} />
        ))}
      </Styled.CardsWrapper>
    );
  }

  return (
    <Styled.SwiperContainer ref={containerRef}>
      <Swiper
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
        slidesPerGroup={2}
        slidesPerView={2}
        loop
        loopFillGroupWithBlank
        spaceBetween={96}
        className="mySwiper"
        speed={500}
        breakpoints={{
          1600: {
            slidesPerGroup: 3,
            slidesPerView: 3,
            spaceBetween: 32,
          },
          1920: {
            slidesPerGroup: 3,
            slidesPerView: 3,
            spaceBetween: 76,
          },
        }}
      >
        {filteredCenters.map((value) => (
          <SwiperSlide key={value.id}>
            <CarouselItem src={value.image!} title={value.title} id={value.id} />
          </SwiperSlide>
        ))}
        {filledCenters.map((value) => (
          <SwiperSlide key={`${value.id}filled`}>
            <CarouselItem src={value.image!} title={value.title} id={value.id} />
          </SwiperSlide>
        ))}
      </Swiper>
      <Styled.SwiperButton onClick={handleOnNextClick} vector="right" />
      <Styled.SwiperButton onClick={handleOnPrevClick} vector="left" />
    </Styled.SwiperContainer>
  );
};

export const ScrollableSwiper: React.VFC<{ data: MedicineCenter[] }> = (props) => {
  const filteredCenters = useMemo(() => props.data.filter((item) => Boolean(item.image)), [props.data]);
  return (
    <Styled.SwiperContainer>
      <Swiper slidesPerView="auto" freeMode modules={[FreeMode]} className="mySwiper">
        {filteredCenters.map((value) => (
          <SwiperSlide key={value.id}>
            <CarouselItem src={value.image!} title={value.title} id={value.id} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Styled.SwiperContainer>
  );
};
