import React, { ComponentProps } from 'react';
import styled from 'styled-components';

import { COLORS } from '../../../utils/constants';

import Link from '../link';
import Collapse from '../collapse';
import HtmlContainer from '../html-container';
import { HtmlRenderer } from '../../../utils/htmlParser';

const More = styled(Link)`
  margin-left: 0.5ch;
  color: ${COLORS.gray4};
  text-decoration: none;
  text-transform: lowercase;
  transition: all 0.2s ease-out;

  &:hover {
    color: ${COLORS.gray3};
  }
`;

interface DescriptionProps extends Omit<ComponentProps<typeof Collapse>, 'children'> {
  text: string;
  link?: string;
}

const Description: React.FC<DescriptionProps> = ({ title, value, text, link, className }) => (
  <Collapse title={title} className={className} value={value} bordered>
    <HtmlContainer>
      <HtmlRenderer html={text} />
    </HtmlContainer>

    {link && <More to={link}>Узнать больше...</More>}
  </Collapse>
);

export default Description;
