import styled, { css } from 'styled-components';

import * as styles from '../../../styles/text';

const headingStyles = css`
  margin: 0;
`;

export const H1 = styled.h1`
  ${headingStyles}
  ${styles.h1Styles}
`;

export const H2 = styled.h2`
  ${headingStyles};
  ${styles.h2Styles}
`;

export const H3 = styled.h3`
  ${headingStyles};
  ${styles.h3Styles}
`;

export const H4 = styled.h4`
  ${headingStyles};
  ${styles.h4Styles}
`;

export const H5 = styled.h5`
  ${headingStyles};
  ${styles.h5Styles}
`;

interface H6Props {
  bold?: boolean;
}

export const H6 = styled.h6<H6Props>`
  ${headingStyles};
  ${styles.h6Styles}

  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
`;

interface SpanProps {
  bold?: boolean;
}

export const Span = styled.span<SpanProps>`
  ${styles.spanStyles};

  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
`;

export const Small = styled.small`
  ${styles.smallStyles}
`;

export const Micro = styled.small`
  ${styles.microStyles}
`;

export const Informer = styled.small`
  ${styles.informerStyles}
`;

export const Caps = styled.span`
  ${styles.capsStyles}
`;
