import styled, { css } from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';
import IconButton from 'components/base-ui/icon-button';
import { H2, H4, Micro } from 'components/base-ui/typo';

export const Div = styled.div`
  min-height: 100%;
  padding: 111px 40px 30px 40px;
  background-size: 100% 168px; // FIXME: doesn't work on mobile
  background: ${COLORS.linearGradient} no-repeat top left;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 120px 0 27px 0;
    background-size: 100% 288px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding: 152px 0 49px 0;
    background-size: 100% 384px;
  }
`;
export const flexColumn = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const Content = styled.div`
  ${flexColumn};
  margin: 0 auto;
  max-width: 400px;

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    max-width: unset;
  }
`;
export const AvatarWrapper = styled.div`
  position: relative;
  margin-bottom: 8px;
`;
export const AvatarChangeWrapper = styled.div`
  right: -4px;
  bottom: 4px;
  position: absolute;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    right: 16px;
    bottom: 16px;
  }
`;
export const AvatarChangeButton = styled(IconButton)`
  width: 48px;
  height: 48px;
`;
export const AvatarRemoveWrapper = styled.div`
  display: flex;
  margin-bottom: 12px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 16px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin-bottom: 24px;
  }
`;
export const AvatarRemoveText = styled(Micro)`
  color: ${COLORS.white};
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    color: ${COLORS.primary};
    &:hover {
      color: ${COLORS.secondary};
    }
  }
`;
export const AvatarRemoveButton = styled(IconButton)`
  margin-left: 4px;
`;
export const InfoWrapper = styled.div`
  ${flexColumn};
`;
export const InfoNameWrapper = styled.div``;
export const InfoName = styled(H2)`
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  text-align: center;
  color: ${COLORS.gray2};

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-size: 34px;
    line-height: 40px;
  }
`;
export const InfoTextWrapper = styled.div`
  display: inline-flex;
`;
export const InfoText = styled(H4)`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  text-align: center;
  color: ${COLORS.gray4};

  &:not(:last-of-type) {
    margin-right: 8px;
  }

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-size: 24px;
    line-height: 32px;
  }
`;
