import { ReactComponent as IconArrow } from 'assets/icons/icon_arrow.svg';
import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { HtmlRenderer } from 'utils/htmlParser';
import { useSwiper } from 'swiper/react';

import { useDeviceState } from 'hooks/useDeviceProvider';
import { Styled } from './styles';

export function CarouselItem(props: { src: string; title: string; id?: number }) {
  const history = useHistory();

  const handleOnItemClick = useCallback(() => {
    history.push(`/market/medicine/${props.id}`);
  }, [props.id]);

  return (
    <Styled.Wrapper onClick={handleOnItemClick}>
      <Styled.ImgWrapper>
        <img src={props.src} alt={props.title} />
      </Styled.ImgWrapper>
      <Styled.Title>
        <HtmlRenderer html={props.title} />
      </Styled.Title>
      <Styled.Button>
        <Styled.ButtonText>ПОДРОБНЕЕ</Styled.ButtonText> <IconArrow />
      </Styled.Button>
    </Styled.Wrapper>
  );
}
