import styled from 'styled-components';
import { COLORS } from 'utils/constants';

export const Div = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Label = styled.label`
  display: block;
  font-size: 16px;
  line-height: 20px;
  color: ${COLORS.gray3};
  margin-top: 6px;
  margin-bottom: 12px;
  margin-right: 18px;
  padding-left: 2em;
  width: fit-content;
`;
export const CheckboxWrapper = styled.span`
  position: absolute;
  margin-left: -2em;
  width: 20px;
  height: 20px;
  border: 1px solid ${COLORS.primary};
  border-radius: 2px;
  cursor: pointer;
`;
export const Input = styled.input`
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
  &:focus + ${CheckboxWrapper} {
    border: 2px solid ${COLORS.primary};
  }
  &:checked + ${CheckboxWrapper} {
    background-color: ${COLORS.primary};
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='10' viewBox='0 0 14 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 4.5L5 8.5L12.5 1' stroke='white' stroke-width='2' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
  }
`;
