import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Block } from 'utils/blocks';

import { BlocksRender } from 'components/renderers';

import BreadcrumbsWrapper from 'components/base-ui/breadcrumbs-wrapper';
import Breadcrumbs from 'components/base-ui/breadcrumbs';
import { observer } from 'mobx-react-lite';
import { autorun } from 'mobx';
import { useBasePageStore } from 'store/base-page';

const Div = styled.div`
  height: 100%;
  position: relative;
`;

const BasePageComponent: React.FC = observer(() => {
  const { basePageId } = useParams<{ basePageId: string }>();

  const basePageStore = useBasePageStore();
  const history = useHistory();

  useEffect(
    () =>
      autorun(() => {
        basePageStore.fetchPage(+basePageId);
      }),
    [basePageId],
  );

  useEffect(() => {
    if (basePageStore.error) history.push('/404-not-found');
  }, [basePageStore.error]);

  document.title = `Страница ${basePageStore.page?.title || ''}`;

  return (
    <Div>
      <BreadcrumbsWrapper absolute>
        <Breadcrumbs white={true} />
      </BreadcrumbsWrapper>
      <BlocksRender
        blocks={basePageStore.page?.content as unknown as Block[]}
        loading={basePageStore.fetching}
        options={{ section: { compact: true } }}
      />
    </Div>
  );
});

export default BasePageComponent;
