import React, { createContext, useContext, useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import { getBreakpointsDimensions } from '../utils/hooks';
import { BREAKPOINTS } from '../utils/constants';

interface DeviceProviderType {
  isDesktop: boolean;
  isLessLaptop: boolean;
  isLessMobile: boolean;
  isLessTablet: boolean;
  isLessDesktop: boolean;
  isWideDesktop: boolean;
}

const defaultValue = {
  isLessMobile: false,
  isLessTablet: false,
  isLessLaptop: false,
  isLessDesktop: false,
  isDesktop: true,
  isWideDesktop: false,
};

const useDeviceProvider = () => {
  const [deviceState, setDeviceState] = useState(defaultValue);

  useEffect(() => {
    const handleResize = () => {
      const { width } = getBreakpointsDimensions();

      const isLessMobile = width < parseInt(BREAKPOINTS.mobile);
      const isLessDesktop = width < parseInt(BREAKPOINTS.desktop);
      const isLessTablet = width < parseInt(BREAKPOINTS.tablet);
      const isLessLaptop = width < parseInt(BREAKPOINTS.laptop);
      const isDesktop = width >= parseInt(BREAKPOINTS.desktop);
      const isWideDesktop = width >= parseInt(BREAKPOINTS.wideDesktop);
      const newDeviceState = { isLessMobile, isLessTablet, isLessLaptop, isLessDesktop, isDesktop, isWideDesktop };

      if (!isEqual(newDeviceState, deviceState)) {
        setDeviceState(newDeviceState);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [deviceState]);

  return deviceState;
};

const DeviceTypeContext = createContext<DeviceProviderType>(defaultValue);

export const DeviceTypeProvider: React.FC = (props) => {
  const devicesState = useDeviceProvider();
  return <DeviceTypeContext.Provider value={devicesState}>{props.children}</DeviceTypeContext.Provider>;
};

export const useDeviceState = (): DeviceProviderType => useContext(DeviceTypeContext);
