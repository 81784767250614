import React, { useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { Product } from 'services';

import { useCartStore, useProductStore } from 'store';

import { BREAKPOINTS, COLORS } from 'utils/constants';

import { H4, H6, Span } from 'components/base-ui/typo';
import { OutlineButton } from 'components/base-ui/button';
import Stars from 'components/base-ui/stars';
import InputAmount from 'components/base-ui/input-amount';
import HtmlContainer from 'components/base-ui/html-container';

import { useChip } from 'components/goods/item/chip';
import { GoodChipContent } from 'components/goods/shared/chip-content';
import { HtmlRenderer } from 'utils/htmlParser';
import Parameters from './parameters';

const Container = styled.aside`
  width: 100%;
  padding: 0 16px;
  display: grid;
  row-gap: 56px;
  grid-template-rows: 1fr auto;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 0;
  }
`;

const Content = styled.div`
  width: 100%;
`;

const Block = styled.div`
  margin-bottom: 14px;
`;

const Title = styled(HtmlContainer)`
  font-size: 24px;
  line-height: 32px;
  color: ${COLORS.secondary};

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-size: 34px;
    line-height: 40px;
  }
`;

const Rating = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 24px;
  position: relative;

  &:after {
    content: '';
    left: 0;
    top: 100%;
    width: 100%;
    height: 1px;
    position: absolute;
    background-color: ${COLORS.gray5};
  }
`;

const RatingStars = styled(Stars)`
  opacity: 0.8;
  pointer-events: none;
`;

const RatingScore = styled(H6)`
  margin-left: 8px;
  line-height: 1em;
  padding-top: 3px;
  font-weight: normal;
  color: ${COLORS.copper};
`;

const Actions = styled.div`
  width: 100%;
  display: grid;
  row-gap: 24px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 240px;
  }
`;

const ActionsRow = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

const ActionsPriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ActionsPriceWithoutDiscount = styled(Span)`
  color: ${COLORS.gray5};
  text-decoration: line-through;
`;

const ActionsPrice = styled(H4)`
  font-weight: 400;
  color: ${COLORS.secondary};
`;

const ActionsPriceCurrency = styled(Span)`
  color: ${COLORS.secondary};
`;

const ActionsButton = styled(OutlineButton)`
  border: none;
  color: ${COLORS.white};
  background: ${COLORS.linearGradient};
`;

interface AsideProps {
  data: Product;
}

const Aside: React.FC<AsideProps> = observer(({ data }) => {
  const [count, setCount] = useState<number>(1);

  const productStore = useProductStore();

  const memoizedRating = useMemo(() => {
    if (productStore.averageRating !== null) {
      return productStore.averageRating;
    }
    if (data.averageRating) {
      return parseFloat(data.averageRating);
    }
    return 0;
  }, [productStore.averageRating, data.averageRating]);

  const memoizedPrice = useMemo(() => {
    return data.discountPrice || data.price || 'Нет цены';
  }, [data.discountPrice, data.price]);

  const [trigger, Chip] = useChip();

  const cartStore = useCartStore();

  const addToCart = useCallback(() => {
    cartStore.addToCart(Number(data.id), count);

    trigger();
  }, [data.id, count, cartStore, trigger]);

  const handleRating = useCallback((rating: number) => productStore.changeRating(rating), [productStore]);

  return (
    <Container>
      <Chip content={<GoodChipContent product={data} />} />

      <Content>
        <Block>
          <Title marginless>
            <HtmlRenderer html={data.name} />
          </Title>
        </Block>

        <Block>
          <Rating>
            <RatingStars value={Math.floor(memoizedRating)} onChange={handleRating} />
            <RatingScore>{memoizedRating || 'Нет рейтинга'}</RatingScore>
          </Rating>
        </Block>

        <Block>
          <Parameters data={data} />
        </Block>
      </Content>

      <Actions>
        <ActionsRow>
          <ActionsPriceWrapper>
            {data.discountPrice && <ActionsPriceWithoutDiscount>{data.price} руб.</ActionsPriceWithoutDiscount>}

            <ActionsPrice>
              {memoizedPrice}
              {memoizedPrice !== 'Нет цены' && <ActionsPriceCurrency> руб.</ActionsPriceCurrency>}
            </ActionsPrice>
          </ActionsPriceWrapper>

          <InputAmount value={count} onChange={setCount} min={1} />
        </ActionsRow>

        <ActionsRow>
          <ActionsButton onClick={addToCart}>В корзину</ActionsButton>
        </ActionsRow>
      </Actions>
    </Container>
  );
});

export default Aside;
