import React from 'react';
import { Image, Item, Video } from './styles';

interface GalleryItemProps {
  data: string;
  type: 'photo' | 'video';
  onClick: (data: string) => void;
}

export const GalleryItem: React.FC<GalleryItemProps> = ({ data, type, onClick }) => {
  return (
    <Item onClick={() => onClick(data)}>
      {type === 'photo' && <Image src={data} />}
      {type === 'video' && <Video src={data} controls={true} />}
    </Item>
  );
};
