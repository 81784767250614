import React from 'react';

import { GlobalCategory } from '../../../services';

import { Container, Grid } from '../../base-ui/cards';
import Item from './item';

interface CardsProps {
  items: GlobalCategory[];
}

const Cards: React.FC<CardsProps> = ({ items }) => {
  return (
    <Container>
      <Grid mode="stretch-whitespace">
        {items.map((el) => (
          <Item key={el.id} data={el} />
        ))}
      </Grid>
    </Container>
  );
};

export default Cards;
