import styled from 'styled-components';
import { COLORS } from 'utils/constants';
import IconButton from 'components/base-ui/icon-button';

export const StMenuWrapper = styled.div<{ isOpen: boolean }>`
  display: flex;
  background-color: ${(props) => (props.isOpen ? COLORS.white : COLORS.primary)};
  transition: all 200ms;
  box-shadow: ${(props) => (props.isOpen ? '1px -2px 3px 0px rgba(135, 152, 176, 0.5)' : 'unset')};
  z-index: 1;
`;

export const StIcon = styled(IconButton)`
  width: 56px;
  padding: 16px;
`;
