import React, { createContext, useContext } from 'react';

import DynamicApplicationStore from './store';

const store = new DynamicApplicationStore();

const DynamicApplicationStateContext = createContext<DynamicApplicationStore>(store);

export const DynamicApplicationStateProvider: React.FC = ({ children }) => (
  <DynamicApplicationStateContext.Provider value={store}>{children}</DynamicApplicationStateContext.Provider>
);

export const useDynamicApplicationStore = (): DynamicApplicationStore => useContext(DynamicApplicationStateContext);
