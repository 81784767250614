import React, { useMemo } from 'react';
import styled from 'styled-components';

import { Product, PropertyForProducts } from 'services';

import { isNull, isUndefined } from 'lodash';
import Item from './item';

const List = styled.ul`
  margin: 0;
  padding: 0;
  display: table;
  list-style: none;
`;

interface ParametersProps {
  data: Product;
}

const Parameters: React.FC<ParametersProps> = ({ data }) => {
  const prepareData = useMemo(() => {
    return data.properties
      .reduce((prev: PropertyForProducts[], curr) => {
        if (!curr.attribute) return prev;
        const doubleData = prev.find((e) => e.attribute.name === curr.attribute.name);
        if (!doubleData) {
          prev.push(curr);
        } else {
          doubleData.name += `, ${curr.name}`;
        }
        return prev;
      }, [])
      .sort((a, b) => {
        return isNull(a.attribute.sortOrder) ||
          isNull(b.attribute.sortOrder) ||
          isUndefined(a.attribute.sortOrder) ||
          isUndefined(b.attribute.sortOrder)
          ? 1
          : a.attribute.sortOrder - b.attribute.sortOrder;
      });
  }, [data]);

  const renderedList = useMemo(
    () => prepareData.map((a) => <Item key={a.id} title={a.attribute?.name || ''} description={a.name} />),
    [prepareData],
  );

  return <List>{renderedList}</List>;
};

export default Parameters;
