import React from 'react';

import { PropsWithSectionOptions } from '../blocks';

import AboutBanner from '../../about/banner';
import Section from '../../base-ui/section';

type AboutProjectBannerProps = PropsWithSectionOptions<{
  image: string;
  text: string;
}>;

export const AboutProjectBanner: React.FC<AboutProjectBannerProps> = (props) => {
  return (
    <Section noLastOfTypeMargin={props.options?.noLastOfTypeMargin}>
      <AboutBanner image={props.image} text={props.text} />
    </Section>
  );
};
