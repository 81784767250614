import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useDynamicApplicationStore } from 'store';
import styled from 'styled-components';
import { COLORS } from 'utils/constants';
import { Widget } from 'components/applicationForm/types';
import { DynamicApplication } from 'services';
import { ApplicationForm } from '../applicationForm';

const Wrapper = styled.div`
  label {
    color: ${COLORS.white}!important;
  }
`;

interface FeedbackProps {
  formId: number | null;
}

export const Feedback: React.FC<FeedbackProps> = observer((props) => {
  const { formId } = props;
  const dynamicApplicationStore = useDynamicApplicationStore();
  const [successState, setSuccessState] = useState(false);

  useEffect(() => {
    if (!formId) return;

    dynamicApplicationStore.fetchDynamicForm(formId);
    return () => {
      dynamicApplicationStore.resetStore();
    };
  }, []);

  const successAction = useCallback(() => setSuccessState(true), []);

  const handleSubmitForm = useCallback(
    (data) => {
      dynamicApplicationStore.createDynamicApplication(data as DynamicApplication, successAction);
    },
    [successAction],
  );

  return (
    <Wrapper>
      <ApplicationForm
        fields={dynamicApplicationStore.formData?.form as unknown as Widget[]}
        title={dynamicApplicationStore.formData?.title}
        onSubmitForm={handleSubmitForm}
        successState={successState}
        successHtmlFragment={dynamicApplicationStore.formData?.submitText}
        loading={dynamicApplicationStore.fetching}
      />
    </Wrapper>
  );
});
