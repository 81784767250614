import React, { useCallback } from 'react';

import { RelatedItem } from 'web';

import { useHistory } from 'react-router-dom';
import { HtmlRenderer } from 'utils/htmlParser';
import { Div, ImageWrapper, Title, Description, Body, Image } from './styled';

interface CardProps {
  data: RelatedItem;
}

export const RelatedItemCard: React.FC<CardProps> = (props) => {
  const history = useHistory();

  const cardClickHandler = useCallback(
    (ev: React.MouseEvent<HTMLDivElement>) => {
      history.push(`/guide/${props.data.id}`);
      window.scrollTo(0, 0);
    },
    [props.data.link],
  );
  return (
    <Div onClick={cardClickHandler}>
      <ImageWrapper>
        <Image src={props.data.image} />
      </ImageWrapper>

      <Body>
        <Title>{props.data.title}</Title>
        <Description>
          <HtmlRenderer html={props.data.description} />
        </Description>
      </Body>
    </Div>
  );
};
