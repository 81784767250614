import React from 'react';
import { Years, Wrapper, Year } from './styled';

type Props = {
  years: number[];
  active?: number;
  onClick: (year: number) => void;
};

export const Carousel: React.FC<Props> = ({ years, active, onClick }) => {
  return (
    <Wrapper>
      <Years>
        {years.map((year) => (
          <Year active={year === active} onClick={() => onClick(year)}>
            {year}
          </Year>
        ))}
      </Years>
    </Wrapper>
  );
};
