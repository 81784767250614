import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Controller, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { isString } from 'lodash';
import { Textarea } from '../../base-ui/textarea';
import { WidgetProps } from '../types';
import { ErrorFormMessage } from '..';

const Label = styled.label`
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 4px;
  display: inline-block;
`;

export const TextBlockWidget: React.FC<WidgetProps<string>> = (props) => {
  const { values } = props;

  const form = useFormContext();

  const rules = useMemo(() => {
    return {
      required: {
        value: values.required,
        message: 'Обязательное поле',
      },
    };
  }, [props.values.required]);

  return (
    <div>
      <Label htmlFor={values.slug}>{values.description}</Label>
      <Controller
        name={values.slug}
        control={form.control}
        rules={rules}
        render={({ value, onChange }) => (
          <Textarea
            id={values.slug}
            onChange={onChange}
            value={isString(value) ? value : ''}
            placeholder={values.placeholder}
            rows={5}
          />
        )}
      />
      <ErrorFormMessage>
        <ErrorMessage errors={form.errors} name={values.slug} />
      </ErrorFormMessage>
    </div>
  );
};
