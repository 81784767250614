import React from 'react';
import { DefaultLayout } from 'layouts';
import ScrollToTopOnMount from 'components/base-ui/scroll-to-top';
import { Creators } from 'components/creators';
import Breadcrumbs from 'components/base-ui/breadcrumbs';
import { BreadCrumbsWrapper, Container } from './styled';

const CreatorsPage: React.FC = () => {
  return (
    <>
      <ScrollToTopOnMount />
      <DefaultLayout>
        <Container>
          <BreadCrumbsWrapper>
            <Breadcrumbs white={false} lineStyle={false} />
          </BreadCrumbsWrapper>
          <Creators />
        </Container>
      </DefaultLayout>
    </>
  );
};

export default CreatorsPage;
