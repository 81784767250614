import React, { useCallback, useMemo } from 'react';
import { ResolutionItemType } from 'store/projects/types';
import { Nullable } from 'web';
import { observer } from 'mobx-react-lite';
import { ContentType } from '../../types';
import { Styled } from '../../styles';
import { transformResolution } from '../../../lib';

interface MainContentProps {
  setContent: React.Dispatch<React.SetStateAction<ContentType>>;
  speed: number;
  currentResolution: Nullable<ResolutionItemType>;
}

export const MainContent = observer<MainContentProps>((props) => {
  const handleOnQualityClick = useCallback(() => props.setContent('quality'), []);

  const selectedQualityValue = useMemo(
    () => props.currentResolution && transformResolution(props.currentResolution.resolution),
    [props.currentResolution?.resolution],
  );

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {selectedQualityValue && (
        <Styled.PopperButton isMain onClick={handleOnQualityClick}>
          <span>Качество:</span>
          <Styled.SelectedValue>{selectedQualityValue}</Styled.SelectedValue>
        </Styled.PopperButton>
      )}
    </>
  );
});
