import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import qs from 'query-string';

import { ViewMode } from 'web';

import { ProductAttribute, ProductList, ProductsApiProductsListRequest } from 'services';

import { useProductListStore, useProductAttributeStore } from 'store';

import { useQueryParams, usePagination, useWindowDimensions, useDeviceType } from 'utils/hooks';
import { stringifyQueryParams } from 'utils/functions';
import { PAGE_LIMIT, BREAKPOINTS } from 'utils/constants';

import Table from 'components/base-ui/table';
import Cards from 'components/goods/shared/cards';
import List from 'components/goods/shared/list';
import { LikeModal } from '../../base-ui/like-modal';
import { LikeModalContent } from '../../base-ui/like-modal-content';

const Container = styled.section`
  @media screen and (min-width: ${BREAKPOINTS.tablet}) and (max-width: calc(${BREAKPOINTS.laptop} - 1px)) {
    padding: 0 56px;
  }
`;

// eslint-disable-next-line sonarjs/cognitive-complexity
const GoodsRenderer: React.FC = observer(() => {
  const productStore = useProductListStore();
  const attributeStore = useProductAttributeStore();
  const [likeModalId, setLikeModalId] = React.useState<number | null>(null);
  const [showLikeModal, setShowLikeModal] = React.useState(false);

  const history = useHistory();
  const location = useLocation();
  const windowDimension = useWindowDimensions();
  const device = useDeviceType();

  const { categoryId } = useParams<{ categoryId: string }>();

  const ref = useRef<HTMLDivElement>(null);

  const {
    page: initialPage,
    priceLte,
    priceGte,
    alcoholContentLte,
    alcoholContentGte,
    discountPriceOrPriceGte,
    discountPriceOrPriceLte,
    likesCountGte,
    propertiesIdContains,
    multiselectPropertiesId,
    averageRatingGte,
    ordering,
    viewMode = 'card',
  } = useQueryParams<
    Pick<
      ProductsApiProductsListRequest,
      | 'priceGte'
      | 'priceLte'
      | 'alcoholContentLte'
      | 'alcoholContentGte'
      | 'likesCountGte'
      | 'averageRatingGte'
      | 'discountPriceOrPriceGte'
      | 'discountPriceOrPriceLte'
    > & {
      propertiesIdContains?: number | number[];
      multiselectPropertiesId?: string;
      viewMode?: ViewMode;
      ordering: string;
    }
  >();

  const pageLimit = useMemo(() => {
    if (windowDimension.width > 3771) return 33;
    if (windowDimension.width > 3453) return 30;
    if (windowDimension.width > 3135) return 27;
    if (windowDimension.width > 2817) return 24;
    if (windowDimension.width > 2499) return 21;
    if (windowDimension.width > 2181) return 18;
    if (windowDimension.width > 1863) return 15;
    return PAGE_LIMIT;
  }, [windowDimension.width]);

  const likesLimit = React.useMemo(() => {
    if (device === 'mobile') return 70;
    return 40;
  }, [device]);

  const { page, onPaginationWithRouter } = usePagination({
    ref,
    initialPage,
    path: location.pathname,
    count: productStore.count,
    error: productStore.error,
    limit: pageLimit,
    withRouter: true,
  });

  const preparedProperties = useMemo(() => {
    if (!propertiesIdContains) return undefined;
    return Array.isArray(propertiesIdContains) ? propertiesIdContains : [propertiesIdContains];
  }, [propertiesIdContains]);

  const preparedMultiProperties = useMemo(() => {
    if (!multiselectPropertiesId) return undefined;
    return multiselectPropertiesId;
  }, [multiselectPropertiesId]);

  useEffect(
    () =>
      autorun(() => {
        // Если в массиве только 1 элемент, то query-string возвращает просто число
        productStore.fetchProducts({
          page,
          priceLte,
          priceGte,
          alcoholContentLte,
          alcoholContentGte,
          discountPriceOrPriceLte,
          discountPriceOrPriceGte,
          likesCountGte,
          averageRatingGte,
          propertiesIdContains: preparedProperties,
          multiselectPropertiesId: preparedMultiProperties,
          limit: pageLimit,
          category: parseInt(categoryId),
          ordering,
        });
      }),
    [
      page,
      priceLte,
      priceGte,
      alcoholContentLte,
      alcoholContentGte,
      discountPriceOrPriceLte,
      discountPriceOrPriceGte,
      likesCountGte,
      averageRatingGte,
      preparedProperties,
      categoryId,
      ordering,
      productStore,
      multiselectPropertiesId,
      pageLimit,
    ],
  );

  useEffect(() => autorun(() => productStore.fetchPriceLimits({ category: categoryId })), [productStore, categoryId]);
  useEffect(() => autorun(() => productStore.fetchAlcoholLimits({ category: categoryId })), [productStore, categoryId]);

  useEffect(
    () => autorun(() => attributeStore.fetchAttributes({ categoryId: parseInt(categoryId) })),
    [attributeStore, categoryId],
  );

  const onSearch = useCallback(
    (props: ProductsApiProductsListRequest) => {
      const stringifiedQueryParams = stringifyQueryParams(props);

      return stringifiedQueryParams
        ? history.push({
            pathname: location.pathname,
            search: `?page=${1}&${stringifiedQueryParams}&viewMode=${viewMode}`,
          })
        : history.push({ pathname: location.pathname, search: `?page=${1}&viewMode=${viewMode}` });
    },
    [history, location.pathname, page, categoryId],
  );

  const onModeChange = useCallback(
    (value: ViewMode) => {
      if (value !== viewMode) {
        history.push({
          pathname: location.pathname,
          search: `${qs.exclude(location.search, ['viewMode'])}&viewMode=${value}`,
        });
      }
    },
    [location.search],
  );

  const onShowLikeModal = useCallback((id: number) => {
    setLikeModalId(id);
    setShowLikeModal(true);
  }, []);

  if (productStore.fetching) {
    return null;
  }

  return (
    <>
      <Container ref={ref}>
        <Table<ProductList, ProductAttribute>
          withList
          withFilters
          limit={pageLimit}
          currentPage={page}
          viewMode={viewMode}
          count={productStore.count}
          defaultValues={{
            priceLte,
            priceGte,
            alcoholContentLte,
            alcoholContentGte,
            discountPriceOrPriceGte,
            discountPriceOrPriceLte,
            likesCountGte,
            averageRatingGte,
            propertiesIdContains: preparedProperties,
            multiselectPropertiesId: preparedMultiProperties,
          }}
          priceLimits={productStore.priceLimits}
          alcoholLimits={productStore.alcoholLimits}
          attributes={attributeStore.attributes}
          cards={<Cards items={productStore.products} onShowLikeModal={onShowLikeModal} />}
          list={<List items={productStore.products} />}
          onSearch={onSearch}
          onModeChange={onModeChange}
          onPagination={onPaginationWithRouter}
        />
      </Container>
      <LikeModal visible={showLikeModal} onClose={() => setShowLikeModal(false)}>
        <LikeModalContent cardType="product" id={likeModalId} limitPerPage={likesLimit} />
      </LikeModal>
    </>
  );
});

export default GoodsRenderer;
