import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';
import { H1 } from 'components/base-ui/typo';
import HtmlContainer from 'components/base-ui/html-container';
import { fade } from 'styles';

const offsetX = '-50px';
const bgWidth = '792px';
const bgGradient = 'linear-gradient(122.74deg, rgba(185, 59, 59, 0.8) 4.19%, rgba(134, 23, 23, 0.8) 90.22%)';

interface OuterProps {
  src: string;
}

export const Div = styled.div`
  position: relative;
  margin: 0 auto;
  margin-top: 56px;
  height: 287px;
  max-width: 1191px;
  color: ${COLORS.white};
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin-top: 96px;
    overflow: hidden;
  }
`;

export const Item = styled.div`
  z-index: 5;
  height: inherit;
  animation: ${fade} 0.2s;
  padding: 71px 0 75px 16px;
  max-width: calc(240px + 63.5px + 16px);
  grid-template-rows: auto 1fr auto;
  display: grid;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 71px 0 80px 72px;
    max-width: calc(384px + 71.5px + 56px);
    grid-template-rows: repeat(3, min-content);
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding: 71px 0 80px 72px;
    max-width: calc(545px + 112px + 136px);
  }
`;

export const Content = styled.div`
  width: 100%;
  min-height: 0;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 488px;
  }
`;

export const Title = styled(H1)`
  margin-bottom: 16px;
  font-size: 27px;
  line-height: 32px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-size: 44px;
    line-height: 48px;
  }
`;

export const Description = styled(HtmlContainer)`
  font-size: 14px;
  line-height: 20px;
  overflow-y: auto;
  & > p {
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-size: 16px;
    line-height: 24px;
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    & > p {
      overflow: visible;
    }
  }
`;

export const ButtonWrapper = styled.div`
  width: 210px;
  margin-top: 20px;
`;

export const Outer = styled.div<OuterProps>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: inherit;
  background-size: cover;
  background-image: url(${(props) => props.src});
  background-position: top center;
  transition: background-image 0.2s ease-out;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    background-position: center;
  }
`;

export const Inner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: inherit;
  width: calc(${bgWidth} - 445px);

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: calc(${bgWidth} - 280px);
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: ${bgWidth};
  }

  &:after {
    content: '';
    top: 0;
    right: 35px;
    position: absolute;
    height: inherit;
    width: ${bgWidth};
    mix-blend-mode: multiply;
    transform: skewX(-6deg);
    background-size: auto 568px;
    background-repeat: no-repeat;
    background-position: top 0 left 0;
    background: ${bgGradient};
    left: calc(${offsetX} - 520px);

    @media screen and (min-width: ${BREAKPOINTS.tablet}) {
      right: unset;
      background-size: auto 896px;
      left: calc(${offsetX} - 320px);
    }

    @media screen and (min-width: ${BREAKPOINTS.laptop}) {
      left: calc(${offsetX} - 130px);
    }
  }
`;
