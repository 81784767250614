import React from 'react';
import { parseHtml } from 'utils/htmlParser';
import { Container, Content, Header } from './styles';

export interface ArticleLeftBlock {
  header: string;
  image: string;
  position: string | null;
  text: string;
}

interface LeftImageBlockProps {
  content: ArticleLeftBlock;
}

export const LeftImageBlock: React.FC<LeftImageBlockProps> = ({ content }) => {
  return (
    <Container>
      <Header>{content.header}</Header>
      <Content>
        <img src={content.image} alt="изображение" />
        {parseHtml(content.text)}
      </Content>
    </Container>
  );
};
