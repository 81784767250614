import styled, { css } from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';
import { IconSvg } from 'assets/icons';

const Header = styled.span<{ expanded?: boolean; collapsable?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${(props) =>
    props.collapsable &&
    css`
      cursor: pointer;
    `};
`;

const Subtitle = styled.span`
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-size: 24px;
    line-height: 32px;
  }
`;

const IconDown = styled(IconSvg.CrossDownSmall)`
  width: 24px;
  height: 24px;
  transition: all 0.3s ease-out;
  & * {
    fill: ${COLORS.secondary};
  }
  &.expanded {
    transform: rotate(180deg);
  }
  &.collapsed {
    transform: rotate(0deg);
  }
`;

const Text = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;

export const Styled = { Subtitle, Text, Header, IconDown };
