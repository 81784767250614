import { action, makeObservable, observable } from 'mobx';

import { Nullable } from 'web';
import { AxiosError } from 'axios';
import {
  GuideDirection,
  GuidePage,
  GuidePageApiGuidePageListRequest,
  Profile,
  User,
  UserProfile,
} from '../../services';

import { api } from '../../utils/api';

import AbstractStore from '../AbstractStore';

export type ExtendedGuideDirection = GuideDirection & { active?: boolean };

export default class GuidePages extends AbstractStore {
  pages: GuidePage[] = [];

  count = 0;

  directions: Nullable<ExtendedGuideDirection[]> = null;

  constructor() {
    super();
    makeObservable(this, {
      ...this.annotations,
      directions: observable,
      pages: observable,
      count: observable,
      setCount: action,
      setPages: action,
      fetchPages: action,
      handleLikePost: action,
      handleLikeDelete: action,
      setDirections: action,
    });
  }

  fetchPages = (params: GuidePageApiGuidePageListRequest) => {
    this.fetchData(() =>
      api.guide.pages
        .guidePageList(params)
        .then((res) => {
          this.setPages(res.data.results);
          this.setCount(res.data.count);
        })
        .catch((err: AxiosError) => this.setError(err)),
    );
  };

  fetchDirections = async () => {
    const directions = await this.fetchData<GuideDirection[]>(() =>
      api.guideDirections.guideDirectionsList().then((res) => res.data.results),
    );
    if (directions) {
      this.setDirections(directions);
    }
  };

  handleLikePost = (pageId: number, profile: Profile | null) => {
    if (!profile) return;

    this.fetchData(() =>
      api.guide.pages.guidePageToggleLikeCreate({ id: pageId }).then(() => {
        this.setPages(
          this.pages.map((p) => {
            p.id === pageId && (p.isLikedByUser = true);
            p.id === pageId && (p.likesCount ? (p.likesCount += 1) : (p.likesCount = 1));
            p.id === pageId &&
              p.likeAuthors &&
              (p.likeAuthors = [
                ...p.likeAuthors,
                {
                  id: profile.id!,
                  image: profile.image,
                  fullName: `${profile?.lastName} ${profile?.firstName}`,
                  email: profile.user?.email!,
                },
              ]);
            return p;
          }),
        );
      }),
    );
  };

  handleLikeDelete = (pageId: number, userId: number) => {
    this.fetchData(() =>
      api.guide.pages.guidePageToggleLikeDelete({ id: pageId }).then(() => {
        this.setPages(
          this.pages.map((p) => {
            p.id === pageId && (p.isLikedByUser = false);
            p.id === pageId && p.likesCount && (p.likesCount -= 1);
            p.id === pageId && p.likeAuthors && (p.likeAuthors = p.likeAuthors.filter((item) => item.id !== userId));
            return p;
          }),
        );
      }),
    );
  };

  handleFavoritePost = (pageId: number) => {
    this.fetchData(() =>
      api.guide.pages.guidePageToggleFavoriteCreate({ id: pageId }).then(() => {
        this.setPages(
          this.pages.map((p) => {
            p.id === pageId && (p.isInUserFavorites = true);
            return p;
          }),
        );
      }),
    );
  };

  handleFavoriteDelete = (pageId: number) => {
    this.fetchData(() =>
      api.guide.pages.guidePageToggleFavoriteDelete({ id: pageId }).then(() => {
        this.setPages(
          this.pages.map((p) => {
            p.id === pageId && (p.isInUserFavorites = false);
            return p;
          }),
        );
      }),
    );
  };

  toggleDirections = (id: number, active: boolean) => {
    if (this.directions) {
      this.directions = this.directions.map((direction) =>
        direction.id === id ? { ...direction, active: !active } : direction,
      );
    }
  };

  setPages(pages: GuidePage[]) {
    this.pages = pages;
  }

  setCount(cnt: number) {
    this.count = cnt;
  }

  setDirections(directions: GuideDirection[] | null) {
    this.directions = directions;
  }
}
