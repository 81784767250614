import React, { createContext, useContext } from 'react';

import NewsCommentsStore from './store';

const store = new NewsCommentsStore();

const NewsStateContext = createContext<NewsCommentsStore>(store);

export const NewsCommentsStateProvider: React.FC = ({ children }) => (
  <NewsStateContext.Provider value={store}>{children}</NewsStateContext.Provider>
);

export const useNewsCommentsStore = (): NewsCommentsStore => useContext(NewsStateContext);
