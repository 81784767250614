import React, { useMemo } from 'react';

import { AdditionalDescription } from 'web';

import { useDeviceType } from 'utils/hooks';
import { HtmlRenderer } from 'utils/htmlParser';
import HtmlContainer from 'components/base-ui/html-container';

import {
  BannerContent,
  BannerInner,
  BannerNext,
  BannerOuter,
  BannerWrapper,
  InnerCircle,
  StyledParagraph,
  StyledSection,
} from './styles';

import { Brief } from './brief';

export interface SimpleBannerProps {
  additionalDescription?: AdditionalDescription;
  image?: string;
  header: string;
  preview?: string;
}

export const SimpleBanner: React.FC<SimpleBannerProps> = (props) => {
  const deviceType = useDeviceType();

  const showCircle = useMemo(() => deviceType !== 'mobile', [deviceType]);

  return (
    <>
      <BannerWrapper>
        {props.image && (
          <BannerOuter src={props.image}>
            <BannerInner>{showCircle && <InnerCircle />}</BannerInner>
            <BannerNext />
          </BannerOuter>
        )}

        <BannerContent>
          <Brief header={props.header} preview={props.preview} />
        </BannerContent>
      </BannerWrapper>

      {props.additionalDescription && (
        <StyledSection compact paddingless>
          <StyledParagraph title={props.additionalDescription.header} spoiler={props.additionalDescription.spoiler}>
            <HtmlContainer>
              <HtmlRenderer html={props.additionalDescription.text} />
            </HtmlContainer>
          </StyledParagraph>
        </StyledSection>
      )}
    </>
  );
};
