import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px 96px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding: 0 32px;
    gap: 40px 80px;
  }

  @media screen and (min-width: ${BREAKPOINTS.wideDesktop}) {
    gap: 40px 56px;
    padding: 0;
  }
`;

const Title = styled.span`
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  color: ${COLORS.gray2};
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-size: 28px;
    line-height: 32px;
  }
`;

export const Styled = { Title, Container };
