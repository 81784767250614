import styled from 'styled-components';
import { BREAKPOINTS } from 'utils/constants';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 0 16px;
  width: 100%;
  gap: 56px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 608px;
    padding: 0;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: 1000px;
  }

  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    width: 1328px;
  }
  @media screen and (min-width: ${BREAKPOINTS.wideDesktop}) {
    width: 1504px;
  }
`;
const Wrapper = styled.div`
  position: relative;
  height: 100%;
  margin-bottom: 56px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin-bottom: 76px;
  }
`;

export const Styled = { Wrapper, Container };
