import React, { useCallback } from 'react';
import { Styled } from './styles';

export const Item: React.FC<{ id: number; onClick: (id: number) => void; active?: boolean }> = (props) => {
  const handleOnClick = useCallback(() => props.onClick(props.id), [props.id, props.onClick]);
  return (
    <Styled.Container active={props.active} onClick={handleOnClick}>
      {props.children}
    </Styled.Container>
  );
};
