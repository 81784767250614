import React from 'react';

import { PropsWithSectionOptions } from '../blocks';

import Section from '../../base-ui/section';

interface HeaderProps {
  compact?: boolean;
}

type HeaderRendererProps = HeaderProps &
  PropsWithSectionOptions<{
    value: string;
  }>;

const HeaderRenderer: React.FC<HeaderRendererProps> = ({ value, options }) => {
  return <Section heading={value} noFirstOfTypeOffset noMinHeight {...options} />;
};

export default HeaderRenderer;
