import React from 'react';
import { Container } from 'components/base-ui/container';
import { Styled } from './styles';
import type { ParticipantProps } from './types';

export const Participant: React.VFC<ParticipantProps> = (props) => {
  return (
    <Container direction="column" gap={props.gap}>
      <Styled.Text size={props.size}>{props.name}</Styled.Text>
      {props.company && <Styled.Company size={props.size}>{props.company}</Styled.Company>}
    </Container>
  );
};

export { TextSize } from './types';
