import styled, { css } from 'styled-components';

import { BREAKPOINTS } from '../../../utils/constants';

const absoluteStyles = css`
  top: 56px;
  left: 0;
  z-index: 100;
  position: absolute;
  margin-top: 12px;
  padding: 0 16px 0 0;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 0 32px 0 32px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding: 0 32px 0 136px;
  }
`;

const relativeStyles = css`
  margin-top: 68px;
`;

interface BreadcrumbsWrapperProps {
  absolute?: boolean;
}

const BreadcrumbsWrapper = styled.section<BreadcrumbsWrapperProps>`
  width: 100%;

  ${(props) => (props.absolute ? absoluteStyles : relativeStyles)}
`;

export default BreadcrumbsWrapper;
