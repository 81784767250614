import { action, computed, makeObservable, observable } from 'mobx';
import _ from 'lodash';

import { Activity, Child, ErrorScreenRelated, Hobby, Profile } from 'services';

import { api } from 'utils/api';

import AbstractStore from '../AbstractStore';

type NullableProfile = Profile | null;

export default class ProfileStore extends AbstractStore {
  profile: NullableProfile = null;

  editedProfile: Partial<Profile> = {};

  newChilds: Child[] = [];

  errorScreen: ErrorScreenRelated | null = null;

  errorScreenId = 0;

  constructor() {
    super();
    makeObservable(this, {
      ...this.annotations,
      profile: observable,
      newChilds: observable,
      setProfile: action,
      getProfile: action,
      errorScreen: observable,
      hobbies: computed,
      activity: computed,
      fullName: computed,
      handlePublicOffer: action,
      agreeWithPublicOffer: computed,
      userCity: computed,
      addEmptyChild: action,
      userChilds: computed,
      setNewChild: action,
      discardChanges: action,
      removeProfileImage: action,
      image: computed,
      setProfileImage: action,
      setEditedProfile: action,
      getErrorScreen: action,
      setErrorScreen: action,
      setErrorScreenId: action,
      closeErrorScreen: action,
    });
  }

  setProfile(profile: Profile) {
    this.profile = profile;
  }

  getProfile() {
    this.fetchData(() =>
      api.profile.profileGetCurrentProfile({}).then((res) => {
        this.setProfile(res.data);
      }),
    );
  }

  getErrorScreen = () => {
    this.fetchData(() =>
      api.errorScreens.errorScreensList({ content: 'PROFILE' }).then((res) => {
        if (res.data.results[0].needShow) {
          this.setErrorScreen(res.data.results[0].errorScreen as ErrorScreenRelated);
          this.setErrorScreenId(res.data.results[0].id || 0);
        } else this.setErrorScreen(null);
      }),
    );
  };

  closeErrorScreen = () => {
    this.fetchData(() =>
      api.closeErrorScreen.closeErrorScreenCreate({ data: { screen: +this.errorScreenId } }).then(() => {
        this.getErrorScreen();
      }),
    );
  };

  get image() {
    return this.profile?.image;
  }

  get hobbies() {
    if (this.profile) {
      return this.profile.hobbies || [];
    }
    return [];
  }

  get userCity() {
    return this.profile?.city ? this.profile.city : undefined;
  }

  get activity() {
    return this.profile?.activity;
  }

  get userChilds() {
    const childs = this.profile?.children || [];
    return [...childs, ...this.newChilds];
  }

  get fullName() {
    if (this.profile) {
      return `${this.profile.firstName || ''} ${this.profile.lastName || ''}`;
    }
    return '';
  }

  get agreeWithPublicOffer() {
    return this.profile ? Boolean(this.profile.publicOfferAgree) : true;
  }

  handlePublicOffer = (value: boolean) => {
    this.fetchData(() =>
      api.profile
        .profilePartialUpdate({ data: { publicOfferAgree: value }, id: Number(this.profile?.id) })
        .then((res) => {
          this.setProfile(res.data);
        }),
    );
  };

  editProfile = <K extends keyof Profile>(key: K, value: Profile[K]) => {
    if (this.profile) {
      this.profile[key] = value || undefined;
      this.editedProfile[key] = value || undefined;
    }
  };

  updateUser = () => {
    this.fetchData(() => {
      const childs = this.userChilds.map((c) => {
        if (Number(c.id) <= 0) {
          c = _.omit(c, 'id');
        }
        return c;
      });
      return api.profile
        .profilePartialUpdate({
          id: Number(this.profile!.id),
          data: {
            ...this.editedProfile,
            children: childs,
          },
        })
        .then((res) => {
          this.setProfile(res.data);
          this.discardChanges();
        });
    });
  };

  removeChild = (childId: number) => {
    if (!this.profile?.children) return;
    this.profile.children = this.profile.children?.filter((c) => c.id !== childId);
    this.editedProfile.children = [...this.profile.children];
    this.newChilds = this.newChilds.filter((c) => c.id !== childId);
  };

  editChild = (child: Child) => {
    const { editedProfile, profile } = this;
    editedProfile.children = this.userChilds.map((c) => {
      c.id === child.id && (c = child);
      return c;
    });
    if (Number(child?.id) <= 0) {
      this.setNewChild(this.newChilds.map((c) => (c.id === child.id ? child : c)));
    } else {
      profile!.children = profile?.children?.map((c) => (c.id === child.id ? child : c));
      this.setProfile(profile!);
    }

    this.setEditedProfile(editedProfile);
  };

  addEmptyChild = () => {
    // add fake id
    this.setNewChild([...this.newChilds, { id: -this.userChilds.length }]);
  };

  addHobby = (hobby: Hobby) => {
    let hobbies = this.hobbies;
    hobbies.push(hobby);
    hobbies = _.uniqBy(hobbies, 'id');
    this.profile!.hobbies = hobbies;
    this.editedProfile.hobbies = hobbies;
  };

  removeHobby = (hobbyId: number) => {
    const updatedHobbies = this.profile!.hobbies?.filter((h) => h.id !== hobbyId);
    this.profile!.hobbies = updatedHobbies;
    this.editedProfile.hobbies = updatedHobbies;
  };

  setActivity = (activity: Activity) => {
    this.profile!.activity = activity;
    this.editedProfile.activity = activity;
  };

  setNewChild = (child: Child[]) => {
    this.newChilds = child;
  };

  discardChanges = () => {
    this.editedProfile = {};
    this.newChilds = [];
    this.getProfile();
  };

  setProfileImage = (image: File) => {
    this.fetchData(async () => {
      const profile = (await api.profile.profileImageCreate({ image })).data;
      if (profile) {
        this.setProfile(profile);
      }
    });
  };

  setEditedProfile(profile: Profile) {
    this.editedProfile = profile;
  }

  setErrorScreen = (value: ErrorScreenRelated | null) => {
    this.errorScreen = value;
  };

  setErrorScreenId = (value: number) => {
    this.errorScreenId = value;
  };

  removeProfileImage = () => {
    this.fetchData(async () => {
      await api.profile.profileImageDelete();
      const profile = this.profile;
      if (profile) {
        profile.image = undefined;
        this.setProfile(profile);
      }
    });
  };
}
