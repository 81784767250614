import React, { useEffect } from 'react';
import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { SubstitutedCategoryType } from 'web';

import {
  ProductAttribute,
  BankingProduct,
  InsuranceProduct,
  MediaProduct,
  ProductList,
  ProductsApiProductsListRequest,
} from 'services';

import { useProductAttributeStore } from 'store';

import { BREAKPOINTS, PAGE_LIMIT } from 'utils/constants';

import Table from 'components/base-ui/table';
import Cards from './cards';
import { useDeviceType } from '../../../../utils/hooks';
import { LikeModal } from '../../../base-ui/like-modal';
import { LikeModalContent } from '../../../base-ui/like-modal-content';

const Div = styled.div`
  padding: 68px 0 58px 0;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 68px 56px 80px 56px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding: 56px 136px 124px 136px;
  }
`;

interface LeasureProps {
  count: number;
  items: (ProductList | BankingProduct | InsuranceProduct | MediaProduct)[];
  limit?: number;
  categoryId: string;
  categoryType?: SubstitutedCategoryType;
  currentPage: number;
  withFilters?: boolean;
  defaultValues?: Omit<Partial<ProductsApiProductsListRequest>, 'page' | 'limit'>;
  onSearch?: (props: ProductsApiProductsListRequest) => void;
  onPagination: (value: number) => void;
}

const Leasure: React.FC<LeasureProps> = observer(
  (props) => {
    const store = useProductAttributeStore();
    const device = useDeviceType();
    const [likeModalId, setLikeModalId] = React.useState<number | null>(null);
    const [showLikeModal, setShowLikeModal] = React.useState(false);

    const likesLimit = React.useMemo(() => {
      if (device === 'mobile') return 70;
      return 40;
    }, [device]);

    useEffect(() => autorun(() => store.fetchAttributes({
      categoryId: parseInt(props.categoryId) })), [store, props.categoryId]);

    const handleShowModal = React.useCallback((newsId: number) => {
      setLikeModalId(newsId);
      setShowLikeModal(true);
    }, []);

    return (
      <Div>
        <Table
          withFilters={props.withFilters}
          count={props.count}
          limit={props.limit}
          currentPage={props.currentPage}
          attributes={store.attributes}
          defaultValues={props.defaultValues}
          cards={
            <Cards
              items={props.items}
              category={{ id: props.categoryId, type: props.categoryType ?? 'catalog' }}
              handleShowModal={handleShowModal}
            />
          }
          onSearch={props.onSearch}
          onPagination={props.onPagination}
        />
        <LikeModal visible={showLikeModal} onClose={() => setShowLikeModal(false)}>
          <LikeModalContent cardType="product" id={likeModalId} limitPerPage={likesLimit} />
        </LikeModal>
      </Div>
    );
  },
);

export default Leasure;
