import React from 'react';

import { ServicesReadComponent } from 'components/services';
import ScrollToTopOnMount from 'components/base-ui/scroll-to-top';
import { DefaultLayout } from 'layouts';

const ServicesReadPage: React.FC = () => {
  return (
    <>
      <ScrollToTopOnMount />

      <DefaultLayout>
        <ServicesReadComponent />
      </DefaultLayout>
    </>
  );
};

export default ServicesReadPage;
