import React, { HTMLProps } from 'react';
import styled from 'styled-components';

import { COLORS } from 'utils/constants';

const StyledThumb = styled.div`
  cursor: grab;
  width: 16px;
  height: 16px;
  line-height: 16px;
  border-radius: 50%;
  text-align: center;
  color: ${COLORS.white};
  background-color: ${COLORS.secondary};
  top: -8px;
`;

function Thumb(props: Omit<HTMLProps<HTMLDivElement>, 'ref' | 'as'>) {
  return <StyledThumb {...props} />;
}

export default Thumb;
