import { MainPage } from 'services';
import { action, makeObservable, observable } from 'mobx';
import { api } from 'utils/api';
import { AxiosError } from 'axios';
import AbstractStore from '../AbstractStore';

export default class CreatorsStore extends AbstractStore {
  fetching = false;

  content: MainPage | null = null;

  constructor() {
    super();
    makeObservable(this, {
      ...this.annotations,
      fetching: observable,
      content: observable,
      fetchContent: action,
    });
  }

  fetchContent(): Promise<void> {
    this.setError(null);
    this.setFetching(true);

    return api.creatorsPage
      .creatorsPageList()
      .then((res) => {
        this.setContent(res.data);
      })
      .catch((err: AxiosError) => this.setError(err))
      .finally(() => this.setFetching(false));
  }

  private setContent(data: MainPage) {
    this.content = data;
  }
}
