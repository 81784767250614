import React, { useCallback, useState } from 'react';
import FsLightbox from 'fslightbox-react';
import { LightboxProps } from './types';

export const Lightbox: React.FC<LightboxProps> = (props) => {
  const { sources, handleClose, showLightBox } = props;
  const [crutchCSS, setCrutchCSS] = useState<string>();

  const onInit = useCallback(() => {
    const scrollWidth = window.innerWidth - document.documentElement.clientWidth;

    // fixed jumping a scroll and fixed flickering after closing

    setCrutchCSS(`
      .fslightbox-open {
        height: unset !important;
      }
      body {
        margin-right: unset !important;
        width: calc(100% - ${scrollWidth}px);
      }
      .fslightbox-fade-out-strong {
        animation: none;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s 0.25s, opacity 0.25s ease;
      }
    `);
  }, []);

  return showLightBox ? (
    <>
      <style>{crutchCSS}</style>
      <FsLightbox toggler={true} openOnMount={true} sources={sources} onClose={handleClose} onInit={onInit} />
    </>
  ) : null;
};
