import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 224px;
  cursor: pointer;

  color: ${COLORS.gray3};
  :hover {
    color: ${COLORS.primary};
    svg & * {
      fill: ${COLORS.primary};
    }
  }

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 340px;
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: 368px;
  }
`;

const ImgWrapper = styled.div`
  height: 136px;
  width: 224px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    height: 184px;
    width: 340px;
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: 368px;
    height: 232px;
  }

  border-radius: 8px;
  overflow: hidden;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

const Title = styled.span`
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;

  margin-top: 16px;

  & p {
    margin: 0 0 0.2em 0;
  }
`;

const ButtonText = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  text-transform: uppercase;
`;

const Button = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;

  margin-top: 8px;
  svg {
    width: 24px;
    height: 24px;
    & * {
      fill: ${COLORS.gray3};
    }
  }
`;

export const Styled = { Button, Title, Wrapper, ImgWrapper, ButtonText };
