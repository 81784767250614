import styled from 'styled-components';
import { BREAKPOINTS } from 'utils/constants';

export const Wrapper = styled.div<{ image: string }>`
  width: 111%;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    background-image: ${({ image }) => `url(${image})`};
    background-repeat: no-repeat;
    background-size: cover;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    max-width: 1232px;
    max-height: 796px;
  }
`;

export const MobileImage = styled.img`
  width: 100%;
`;

export const Text = styled.div`
  width: 100%;
  padding: 16px;
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    background-color: rgba(70, 74, 83, 0.4);
    background-blend-mode: multiply;
    padding: 16px 32px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding: 32px;
  }
`;
