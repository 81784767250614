import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';

import { NewsItemProps } from 'web';

import { formatDate } from 'utils/date';
import { COLORS } from 'utils/constants';

import Link from 'components/base-ui/link';
import { Small } from 'components/base-ui/typo';

import iconArrow from 'assets/icons/icon_arrow.svg';
import { HtmlRenderer } from 'utils/htmlParser';

const Head = styled.div``;

const lineClamp = css`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const Title = styled.div`
  position: relative;
  display: block;
  width: 100%;
  padding-right: 40px;
  color: inherit;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  text-decoration: none;
  transition: all 0.2s ease-out;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 5px;
    right: 7px;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-image: url(${iconArrow});
  }

  > p {
    ${lineClamp};

    &:last-of-type {
      margin: 0;
    }
  }
`;

const Card = styled(Link)`
  display: grid;
  row-gap: 16px;
  color: ${COLORS.gray3};
  grid-template-rows: auto 1fr;
  &:hover ${Title}, &:focus ${Title}, &:active ${Title} {
    color: ${COLORS.primary};
  }
`;

const Date = styled(Small)``;

const Body = styled.div`
  overflow: hidden;
`;

const Description = styled(Small)`
  ${lineClamp};
  -webkit-line-clamp: 20;
`;

interface ItemProps {
  data: NewsItemProps;
}

const Item: React.FC<ItemProps> = ({ data }) => {
  const to = useMemo(() => `/news/${data.id}`, [data.id]);

  return (
    <Card to={to}>
      <Head>
        <Title>
          <HtmlRenderer html={data.paragraph?.header || ''} />
        </Title>
        <Date>{formatDate(data.date)}</Date>
      </Head>

      <Body>
        <Description>
          <HtmlRenderer html={data.paragraph?.text || ''} />
        </Description>
      </Body>
    </Card>
  );
};

export default Item;
