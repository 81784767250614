import React from 'react';

export const usePortal = (id = 'unknown') => {
  const ref = React.useRef<HTMLDivElement | null>(null);
  React.useEffect(() => {
    let element: HTMLDivElement | null = null;

    if (!ref.current) {
      element = document.createElement('div');

      element.setAttribute('id', `Modal_Portal_${id}`);

      document.body.appendChild(element);

      ref.current = element;
    }

    return () => {
      ref.current = null;
      if (element) {
        document.body.removeChild(element);
      }
    };
  }, [id]);

  return ref.current;
};
