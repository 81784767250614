import styled, { css } from 'styled-components';
import { BREAKPOINTS } from 'utils/constants';

export const Container = styled.div<{ height?: number | undefined }>`
  display: flex;
  column-gap: 32px;
  justify-content: flex-start;
  overflow: hidden;
  overflow-x: auto;
  width: 100%;
  padding-bottom: 8px;
  margin: 0 16px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin: 0 60px;
    padding: 55px 0 55px 0;
  }
  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    margin: 0 135px 90px 60px;
    width: 100%;
    padding-bottom: 90px;
    justify-content: center;
    overflow: visible;
    padding-left: 0;
  }
  ${(props) =>
    props.height &&
    css`
      height: ${props.height}px;
    `}
`;

export const SlickWrapper = styled.div<{ width?: number }>`
  position: relative;
  width: 420px;
  margin: 0 auto;

  @media screen and (min-width: 1200px) {
    width: ${(props) => props.width ?? 872}px;
  }

  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    width: ${(props) => props.width ?? 1324}px;
  }
`;

export const ListContainer = styled.div`
  display: flex;
  gap: 36px;
  margin: 0 auto;
`;
