import React from 'react';
import { AboutProjectPeopleItem } from 'web';
import { HtmlRenderer } from 'utils/htmlParser';
import { StImage, StImageWrapper, StItem, StText, StTitle } from './styled';

interface Props {
  people: AboutProjectPeopleItem;
}

export const ProjectGroupItem: React.FC<Props> = (props) => {
  return (
    <StItem>
      <StImageWrapper>
        <StImage src={props.people.image} />
      </StImageWrapper>
      <StTitle>{props.people.header}</StTitle>
      <StText>
        <HtmlRenderer html={props.people.text} />
      </StText>
    </StItem>
  );
};
