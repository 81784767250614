import React, { useMemo } from 'react';

import { Product } from 'services';

import { useDeviceType } from 'utils/hooks';

import { getDocumentExtensions, getDocumentIconName, loadDocument } from 'utils/documents';
import {
  Body,
  Div,
  Footer,
  FooterInfo,
  FooterInfoAvailability,
  FooterInfoAvailabilityLink,
  FooterInfoAvailabilityRow,
  FooterInfoAvailabilityText,
  FooterInfoIcon,
  FooterPrice,
  FooterPriceActions,
  FooterPriceIconButton,
  Image,
  ImageWrapper,
} from 'components/goods/item/content/styles';
import Aside from './aside';

interface ContentProps {
  data: Product;
}

const Content: React.FC<ContentProps> = ({ data }) => {
  const deviceType = useDeviceType();

  const isDesktop = useMemo(() => deviceType === 'desktop', [deviceType]);

  const markets = useMemo(() => data.markets.map((market) => market.name), [data.markets]);

  return (
    <Div>
      <Body>
        <ImageWrapper>{data.image && <Image src={data.image} />}</ImageWrapper>

        <Aside data={data} />
      </Body>

      <Footer>
        {markets.length > 0 && (
          <FooterInfo>
            <FooterInfoIcon name="iconAlertCircle" />

            <FooterInfoAvailability>
              <FooterInfoAvailabilityRow>
                <FooterInfoAvailabilityText>
                  {data.marketsPrefix || 'Этот товар, также, можно приобрести в следующих сетевых магазинах по акции:'}
                </FooterInfoAvailabilityText>

                {markets.map((market, index) => (
                  <FooterInfoAvailabilityLink>
                    {`${market}${index === markets.length - 1 ? '.' : ','}`}
                  </FooterInfoAvailabilityLink>
                ))}
              </FooterInfoAvailabilityRow>

              <FooterInfoAvailabilityRow>
                <FooterInfoAvailabilityText>
                  {data.marketsPostfix || 'Наличие товара зависит от сети предоставляющей товар по акции'}
                </FooterInfoAvailabilityText>
              </FooterInfoAvailabilityRow>
            </FooterInfoAvailability>
          </FooterInfo>
        )}

        {isDesktop && (
          <FooterPrice>
            <FooterPriceActions>
              {data.documents?.map((d) => {
                const docExtension = getDocumentExtensions(d.file || '');
                const docIcon = getDocumentIconName(docExtension);
                return (
                  <FooterPriceIconButton
                    name={docIcon}
                    onClick={() => loadDocument(Number(d.id), d.title, docExtension)}
                  />
                );
              })}
            </FooterPriceActions>
          </FooterPrice>
        )}
      </Footer>
    </Div>
  );
};

export default Content;
