import React from 'react';

interface Props {
  active: boolean;
  color?: string;
}

export const IconFavorite: React.FC<Props> = (props) => {
  return (
    <div>
      {props.active ? (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill={props.color || '#A9AEBA'}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 3.27986L14.4637 8.32753L14.6977 8.8071L15.2264 8.87963L20.8089 9.64542L16.7509 13.5183L16.3619
            13.8896L16.4572 14.4188L17.4491 19.9289L12.4691 17.2836L12 17.0344L11.5309 17.2836L6.55024 19.9289L7.54283
            14.419L7.63819 13.8896L7.24908 13.5183L3.1911 9.64542L8.77357 8.87963L9.30227
            8.8071L9.53634 8.32753L12 3.27986Z"
            stroke={props.color || '#A9AEBA'}
            strokeWidth="2"
          />
        </svg>
      ) : (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12 3.27986L14.4637 8.32753L14.6977 8.8071L15.2264 8.87963L20.8089 9.64542L16.7509 13.5183L16.3619
            13.8896L16.4572 14.4188L17.4491 19.9289L12.4691 17.2836L12 17.0344L11.5309 17.2836L6.55024 19.9289L7.54283
            14.419L7.63819 13.8896L7.24908 13.5183L3.1911 9.64542L8.77357 8.87963L9.30227 8.8071L9.53634 8.32753L12
            3.27986Z"
            stroke={props.color || '#A9AEBA'}
            strokeWidth="2"
          />
        </svg>
      )}
    </div>
  );
};
