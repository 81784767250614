import React, { useEffect, useState, useCallback, useRef, RefObject } from 'react';
import styled from 'styled-components';

import { MagazineArticle, NewsPage } from 'services';

import { useProjectsStore } from 'store';

import { PAGE_LIMIT } from 'utils/constants';

import Table from 'components/base-ui/table';
import ArticleCards from 'components/articles/list/cards';
import { useDeviceType } from '../../../../../utils/hooks';
import { LikeModal } from '../../../../base-ui/like-modal';
import { LikeModalContent } from '../../../../base-ui/like-modal-content';

const Container = styled.div``;

const FavoriteArticles: React.FC = () => {
  const store = useProjectsStore();

  const ref: RefObject<HTMLDivElement> = useRef(null);
  const [data, setData] = useState<MagazineArticle[]>([]);
  const [page, setPage] = useState<number>(1);
  const [likeModalId, setLikeModalId] = React.useState<number | null>(null);
  const [showLikeModal, setShowLikeModal] = React.useState(false);
  const device = useDeviceType();

  const likesLimit = React.useMemo(() => {
    if (device === 'mobile') return 70;
    return 40;
  }, [device]);

  const handleShowModal = useCallback((id: number) => {
    setLikeModalId(id);
    setShowLikeModal(true);
  }, []);

  useEffect(() => {
    // @ts-expect-error skip
    store.fetchArticles({ page, limit: PAGE_LIMIT, isInUserFavorites: true }).then(() => setData(store.articles));
  }, [store, page]);

  const onPagination = useCallback(
    (idx: number) => {
      store
        // @ts-expect-error skip
        .fetchArticles({ page: idx, limit: PAGE_LIMIT, isInUserFavorites: true })
        .then(() => setData(store.articles));
      setPage(() => idx);

      if (ref.current) {
        ref.current.scrollIntoView();
      }
    },
    [ref, store, setPage],
  );

  return (
    <Container ref={ref}>
      <Table<NewsPage, never>
        count={store.countArticles}
        currentPage={page}
        cards={<ArticleCards onShowModal={handleShowModal} items={data} />}
        onPagination={onPagination}
      />
      <LikeModal visible={showLikeModal} onClose={() => setShowLikeModal(false)}>
        <LikeModalContent cardType="article" id={likeModalId} limitPerPage={likesLimit} />
      </LikeModal>
    </Container>
  );
};

export default FavoriteArticles;
