import React, { useCallback } from 'react';
import { Container } from 'components/base-ui/container';
import { useHistory } from 'react-router-dom';
import { HtmlRenderer } from 'utils/htmlParser';
import { GuidePage } from 'services';
import { NewsItemProps, ParagraphValue } from 'web';
import { Block, BlockTypes } from 'utils/blocks';
import { Styled } from './styles';

interface CardProps {
  title: string;
  article: GuidePage;
}

const getItemData = (article: GuidePage): NewsItemProps => {
  const res: NewsItemProps = { id: Number(article.id) };
  const content = article.content as unknown as Block[];
  const previewImage = content.find((c) => c.type === BlockTypes.PreviewImage);
  const preview = content.find((c) => c.type === BlockTypes.Preview);
  const mainPageImage = content.find((c) => c.type === BlockTypes.GuideMainPageImage);
  res.date = article.publishedAt || '';
  res.image = previewImage ? String(previewImage.value) : '';
  res.paragraph = preview ? (preview.value as ParagraphValue) : undefined;
  res.mainPageImage = mainPageImage ? String(mainPageImage.value) : undefined;
  return res;
};

export const PreviewCard: React.VFC<CardProps> = (props) => {
  const history = useHistory();
  const articleData = getItemData(props.article);

  const handleOnClick = useCallback(() => {
    history.push(`/guide/${articleData.id}`);
  }, [articleData.id]);

  return (
    <Styled.Wrapper onClick={handleOnClick}>
      <Styled.ImgWrapper>
        <img src={articleData.mainPageImage ?? articleData.image} alt={props.title} />
      </Styled.ImgWrapper>
      <Container direction="column">
        {articleData.paragraph?.header && (
          <Styled.Title>
            <HtmlRenderer html={articleData.paragraph.header} />
          </Styled.Title>
        )}
        {articleData.paragraph?.text && (
          <Styled.Subtitle>
            <HtmlRenderer html={articleData.paragraph.text} />
          </Styled.Subtitle>
        )}
      </Container>
    </Styled.Wrapper>
  );
};
