import React, { createContext, useContext } from 'react';

import FinanceCategoryStore from './store';

const store = new FinanceCategoryStore();

const FinanceCategoryStateContext = createContext<FinanceCategoryStore>(store);

export const FinanceCategoryStateProvider: React.FC = ({ children }) => (
  <FinanceCategoryStateContext.Provider value={store}>{children}</FinanceCategoryStateContext.Provider>
);

export const useFinanceCategoryStore = (): FinanceCategoryStore => useContext(FinanceCategoryStateContext);
