import styled, { css } from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';

import { ReactComponent as IconDownSvg } from 'assets/icons/icon_down.svg';

const Card = styled.div`
  display: flex;
  flex-direction: column;
  background: ${COLORS.gray6};

  overflow: hidden;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    border-radius: 8px;
    width: 608px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: 928px;
    flex-direction: row;
  }
`;

const Title = styled.span`
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  margin: 0 16px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-size: 34px;
    line-height: 40px;
    margin: 0;
  }
`;

const Header = styled.span<{ expanded?: boolean; collapsable?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${(props) =>
    props.collapsable &&
    css`
      cursor: pointer;
    `};
`;

const IconDown = styled(IconDownSvg)`
  width: 24px;
  height: 24px;
  transition: all 0.3s ease-out;
  & * {
    fill: ${COLORS.secondary};
  }
  &.expanded {
    transform: rotate(180deg);
  }
  &.collapsed {
    transform: rotate(0deg);
  }
`;

const Line = styled.div<{ expanded?: boolean }>`
  margin: 0 auto;
  width: 100%;
  height: 1px;
  background-color: ${COLORS.borderGray};
  transition: width 0.5s ease-out;
  &.expanded {
    width: 0;
  }
`;

export const Styled = { Card, Title, Header, Line, IconDown };
