import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import ReactDOM from 'react-dom';
import IconButton from '../icon-button';
import { BREAKPOINTS, COLORS } from '../../../utils/constants';
import { usePortal } from '../../../hooks/usePortal';

const ModalOverflow = styled.div<{ padding: number }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 16px;
  z-index: 100001;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.73);
  transition: background-color 0.1s linear;
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding-right: ${(props) => props.padding}px;
    padding-left: ${(props) => props.padding}px;
  }
`;

const ModalMask = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
`;

const ModalWindow = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 658px;
  min-height: 80px;
  padding: 50px 24px 24px 24px;
  background: ${COLORS.linearGradient};
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.08);
  border-radius: 2px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 32px 70px;
  }
`;

const ModalCloseWrapper = styled.div`
  top: 19px;
  right: 19px;
  position: absolute;
  z-index: 2;
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    top: 30px;
    right: 32px;
  }
`;

const ModalContainer = styled.div<{ centered?: boolean }>`
  max-width: 656px;
  margin: 1.75rem auto;
  display: flex;
  align-items: center;
  min-height: ${(props) => (props.centered ? 'calc(100% - (1.75rem * 2))' : 0)};
  width: 100%;
`;

const ModalContent = styled.div`
  color: ${COLORS.white};
`;

const ModalCloseButton = styled(IconButton)``;

interface BaseModalProps {
  isVisible: boolean;
  onClose: () => void;
  centered?: boolean;
}

export const BaseModal: React.FC<BaseModalProps> = (props) => {
  const { isVisible, onClose, centered } = props;
  const [scrollBarWidth, setScrollBarWidth] = useState(0);

  const portalRef = usePortal('base-modal');

  useEffect(() => {
    if (!isVisible) return;
    const width = window.innerWidth - document.documentElement.clientWidth;
    document.body.style.overflowY = isVisible ? 'hidden' : 'visible';
    document.body.style.width = `calc(100% - ${width}px)`;
    setScrollBarWidth(width);
  }, [isVisible]);

  const handleModalClose = useCallback(() => {
    document.body.style.width = 'unset';
    document.body.style.overflowY = 'visible';
    onClose();
  }, []);

  return isVisible
    ? portalRef &&
        ReactDOM.createPortal(
          <ModalOverflow padding={scrollBarWidth}>
            <ModalMask onClick={handleModalClose} />
            <ModalContainer centered={centered}>
              <ModalWindow>
                <ModalCloseWrapper>
                  <ModalCloseButton name="iconCrossBaseWhite" onClick={handleModalClose} />
                </ModalCloseWrapper>
                <ModalContent>{props.children}</ModalContent>
              </ModalWindow>
            </ModalContainer>
          </ModalOverflow>,
          portalRef,
        )
    : null;
};
