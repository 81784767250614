import styled, { css } from 'styled-components';
import { COLORS } from 'utils/constants';

const Container = styled.li<{ active?: boolean }>`
  color: ${COLORS.white};
  padding: 8px 0 8px 24px;
  list-style: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  position: relative;
  margin: 0;
  cursor: pointer;
  transition:
    background-color,
    color 0.2s ease-out;
  ${(props) => props.active && `background-color: ${COLORS.secondary}`};
  border-radius: 8px;
  ::before {
    transition: background-color 0.2s ease-out;
    content: ' ';
    display: block;
    position: absolute;
    width: 5px;
    height: 5px;
    left: 9.5px;
    top: 17.5px;
    border-radius: 50%;
    background-color: ${COLORS.gray5};
  }

  ${(props) =>
    !props.active &&
    css`
        :hover {
            color:  ${COLORS.secondary};
            ::before {
                background-color: ${COLORS.secondary};
            }
    `};
`;

export const Styled = { Container };
