import React, { useMemo } from 'react';
import { StInput, StVerifyInput } from './styled';

interface Props {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error: boolean;
}

export const VerifyInput: React.FC<Props> = (props) => {
  const isAppleDevice = useMemo(() => {
    const userAgent = navigator.userAgent.toLowerCase();

    return (
      userAgent.includes('ios') &&
      !(userAgent.includes('android') || userAgent.includes('linux') || userAgent.includes('windows'))
    );
  }, [navigator.userAgent]);

  return (
    <StVerifyInput>
      <StInput
        type="text"
        placeholder="&bull;&bull;&bull;&bull;"
        maxLength={4}
        inputMode="numeric"
        value={props.value}
        onChange={props.onChange}
        error={props.error}
        autoComplete="off"
        isSafari={isAppleDevice}
      />
    </StVerifyInput>
  );
};
