import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { useMainPageStore, useProfileStore } from 'store';

import { BlocksRender } from '../renderers';

import Modal from '../base-ui/modal';
import ModalContent from './modal-content';
import { parseHtml } from '../../utils/htmlParser';
import { Container, EmailModalContent, Text } from './styles';
import { BaseModal } from '../base-ui/base-modal';
import { WarningPopup } from '../base-ui/warning-popup';

const MainComponent: React.FC = observer(() => {
  const store = useMainPageStore();
  const profileStore = useProfileStore();

  const { agreeWithPublicOffer } = useProfileStore();
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    store.fetchData();
  }, [store]);

  useEffect(() => {
    if (!profileStore.agreeWithPublicOffer) {
      setIsModalVisible(!profileStore.agreeWithPublicOffer);
    }
  }, [profileStore.agreeWithPublicOffer]);

  const onModalClick = useCallback(() => setIsModalVisible((prev) => !prev), [setIsModalVisible, agreeWithPublicOffer]);
  const onPopupClick = useCallback(() => store.closeErrorScreen(), [store]);
  const onCloseEmailModal = useCallback(() => {
    store.emailModalStore.fetchContent();
    store.emailModalStore.setShow(false);
  }, []);
  const onCloseError = useCallback(() => {
    store.emailModalStore.setShow(false);
    store.setError(null);
  }, []);

  return (
    <Container>
      {isModalVisible && (
        <Modal onClick={onModalClick}>
          <ModalContent />
        </Modal>
      )}

      <BaseModal isVisible={store.emailModalStore.isShowing && !store.error} onClose={onCloseEmailModal}>
        <EmailModalContent>
          <Text>{parseHtml(store.emailModalStore?.content?.content || '')}</Text>
          <img src={store.emailModalStore.content?.image} width="100%" alt="Изображение заголовок" />
        </EmailModalContent>
      </BaseModal>

      {store.error && (
        <WarningPopup
          handleCloseErrorScreen={onCloseError}
          header="Ошибка!"
          description="Не заполнен шаблон письма - поздравление"
          link=""
        />
      )}

      <BlocksRender
        blocks={store?.content || []}
        loading={store.fetching}
        errorScreen={store?.errorScreen || undefined}
        closeErrorScreenCallBack={onPopupClick}
      />
    </Container>
  );
});

export default MainComponent;
