import React, { createContext, useContext } from 'react';

import PortalDirectionsStore from './store';

const store = new PortalDirectionsStore();

const PortalDirectionsStateContext = createContext<PortalDirectionsStore>(store);

export const PortalDirectionsStateProvider: React.FC = ({ children }) => (
  <PortalDirectionsStateContext.Provider value={store}>{children}</PortalDirectionsStateContext.Provider>
);

export const usePortalDirectionsStore = (): PortalDirectionsStore => useContext(PortalDirectionsStateContext);
