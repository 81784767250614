import { makeObservable, observable, action } from 'mobx';

import { AxiosError } from 'axios';
import { api } from '../../utils/api';

import { GlobalCategory, GlobalCategoriesApiGlobalCategoriesListRequest } from '../../services';

type NullableError = Error | null;

export default class GlobalCategoriesStore {
  fetching = false;

  error: NullableError = null;

  categories: GlobalCategory[] = [];

  constructor() {
    makeObservable(this, {
      fetching: observable,
      error: observable,
      categories: observable,
      fetchCategories: action,
      setError: action,
      setFetching: action,
      setCategories: action,
    });
  }

  fetchCategories(
    { page, limit }: GlobalCategoriesApiGlobalCategoriesListRequest = {},
    options?: unknown,
  ): Promise<void> {
    this.setError(null);
    this.setFetching(true);

    return api.globalCategories
      .globalCategoriesList({ page, limit }, options)
      .then((res) => this.setCategories(res.data.results))
      .catch((err: AxiosError) => this.setError(err))
      .finally(() => this.setFetching(false));
  }

  setFetching(value: boolean): void {
    this.fetching = value;
  }

  setError(value: NullableError): void {
    this.error = value;
  }

  setCategories(value: GlobalCategory[]): void {
    this.categories = value;
  }
}
