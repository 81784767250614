import React from 'react';
import Icon from 'components/base-ui/icon';
import { useDeviceType } from 'utils/hooks';
import { StImage, StImageGallery, StImageWrapper, StSlider } from './styled';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

interface Props {
  images: string[];
}

export const AboutImageGallery: React.FC<Props> = (props) => {
  const device = useDeviceType();

  return (
    <StImageGallery>
      <StSlider
        dots={device !== 'mobile' && device !== 'tablet'}
        slidesToShow={1}
        slidesToScroll={1}
        arrows={true}
        nextArrow={<Icon name="iconNavRight" />}
        prevArrow={<Icon name="iconNavLeft" />}
        adaptiveHeight={true}
      >
        {props.images.map((image, index) => (
          <StImageWrapper key={index}>
            <StImage src={image} alt={`Изображение галереи ${index}`} />
          </StImageWrapper>
        ))}
      </StSlider>
    </StImageGallery>
  );
};
