import React, { useCallback, useEffect, useMemo } from 'react';
import { useMedicineStore } from 'store/medicine-main';
import { observer } from 'mobx-react-lite';
import { Block, BlockTypes, ServicesBannerBlock } from 'utils/blocks';
import { useDeviceState } from 'hooks/useDeviceProvider';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Carousel, Grid, Tags } from './ui';
import { Container, Wrapper } from './styles';
import { ScrollableSwiper } from './ui/Carousel';
import ServicesBanner from '../renderers/services-banner';

export const MedicineContent = observer(() => {
  const store = useMedicineStore();
  const { trackPageView } = useMatomo();

  const content = (store.page?.content ?? []) as unknown as Block[];

  const bannerData = useMemo(() => {
    return content.find((block) => block.type === BlockTypes.ServicesBanner) as ServicesBannerBlock;
  }, [store.page?.content]);

  useEffect(() => {
    store.fetchMainPageData();
    document.title = 'Медицина';
    trackPageView({});
    return store.cleanUp();
  }, []);

  const toggleDirections = useCallback((id: number) => {
    store.toggleDirections(id);
  }, []);

  const { isLessLaptop } = useDeviceState();

  return (
    <Wrapper>
      {bannerData && <ServicesBanner {...bannerData} />}

      {store.medicineCenters.length > 0 && !isLessLaptop && <Carousel data={store.medicineCenters} />}

      {store.medicineCenters.length > 0 && isLessLaptop && <ScrollableSwiper data={store.medicineCenters} />}
      <Container>
        {store.medicineDirections.length > 0 && (
          <Tags data={store.medicineDirections} toggleDirections={toggleDirections} />
        )}
        {store.programs.length > 0 && <Grid data={store.programs} title="Программы" isMain />}
      </Container>
    </Wrapper>
  );
});
