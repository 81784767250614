import React, { useCallback, useMemo, useState } from 'react';
import { AwardPageMainBanner } from 'store/projects/types';

import { useDeviceType } from 'utils/hooks';
import { BannerContent } from './content';
import { Container } from './styles';
import { VideoBox } from '../../../base-ui/video-box';

interface BannerProps {
  data: AwardPageMainBanner;
}

export const Banner: React.FC<BannerProps> = ({ data }) => {
  const deviceType = useDeviceType();
  const [isBackSide, setIsBackSide] = useState(false);
  const [isLightBoxOpen, setIsLightBoxOpen] = useState(false);

  const isMobileOrTablet = useMemo(() => deviceType === 'mobile' || deviceType === 'tablet', [deviceType]);

  const onDetailClick = useCallback(() => setIsBackSide(!isBackSide), [isBackSide]);
  const handleToggleLightBox = useCallback(() => setIsLightBoxOpen(!isLightBoxOpen), [isLightBoxOpen]);

  return (
    <>
      <Container bg={data.image}>
        {!isMobileOrTablet && (
          <BannerContent
            data={data}
            handleToggleLightBox={handleToggleLightBox}
            onDetailClick={onDetailClick}
            isBackSide={isBackSide}
          />
        )}
        <VideoBox
          isLightBoxOpen={isLightBoxOpen}
          videos={data.videoFile ? [data.videoFile] : []}
          video={data.videoFile ?? undefined}
          onClose={handleToggleLightBox}
        />
      </Container>
      {isMobileOrTablet && (
        <BannerContent
          data={data}
          handleToggleLightBox={handleToggleLightBox}
          onDetailClick={onDetailClick}
          isBackSide={isBackSide}
        />
      )}
    </>
  );
};
