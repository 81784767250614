import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { CartProduct } from 'services';

import { useCartStore } from 'store';

import { validateNumber } from 'utils/validators';
import { BREAKPOINTS, COLORS } from 'utils/constants';

import Link from 'components/base-ui/link';
import { H6 } from 'components/base-ui/typo';
import IconButton from 'components/base-ui/icon-button';
import InputAmount from 'components/base-ui/input-amount';
import HtmlContainer from 'components/base-ui/html-container';
import { HtmlRenderer } from 'utils/htmlParser';

const Div = styled.div`
  padding: 16px 0 24px;
  position: relative;

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding: 16px 0;
  }

  &:after {
    content: '';
    left: 0;
    bottom: 0;
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: ${COLORS.gray6};

    @media screen and (min-width: ${BREAKPOINTS.laptop}) {
      left: 32px;
      width: calc(100% - 32px);
    }
  }
`;

const Grid = styled.div`
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    display: grid;
    column-gap: 16px;
    grid-auto-flow: column;
    grid-template-columns: auto 1fr;
  }
`;

const ImageWrapper = styled.div`
  height: 112px;
  overflow: hidden;
  text-align: center;
  margin-top: 6px;
`;

const Image = styled.img`
  height: 100%;
`;

const InfoWrapper = styled.div`
  margin: auto 0;
`;

const ItemTitleWrapper = styled.div`
  margin-bottom: 8px;
`;

const ItemTitle = styled(HtmlContainer)`
  color: ${COLORS.secondary};
`;

const ItemTags = styled.div`
  margin-bottom: 2px;
`;

const TagList = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 11px;
`;

const TagItem = styled(Link)`
  font-size: 14px;
  line-height: 20px;
  color: ${COLORS.gray3};
  overflow-wrap: anywhere;
  pointer-events: none;

  &:not(:last-of-type) {
    margin-right: 0.5ch;
  }
`;

const ItemFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 14px;
`;

const ItemInputAmount = styled(InputAmount)``;

const ItemPriceWrapper = styled.div`
  width: 120px;
`;

const ItemPrice = styled(H6)`
  color: ${COLORS.secondary};
`;

const RemoveButtonWrapper = styled.div`
  top: 0;
  right: 0;
  width: 23px;
  position: absolute;
`;

interface ItemProps {
  data: CartProduct;
}

const Item: React.FC<ItemProps> = ({ data: { product, count, id } }) => {
  const store = useCartStore();

  const properties = useMemo(() => product?.properties || [], [product]);

  const price = useMemo(() => {
    const price = product?.discountPrice || product?.price;
    if (price) {
      return +price * count;
    }
    return '---';
  }, [product, count]);

  const changeCount = useCallback(
    (cnt: number) => store.editCount(Number(id), Number(product?.id), validateNumber(cnt, { min: 1 })),
    [product, id, store],
  );

  const removeFromCart = useCallback(() => store.removeProductFromCart(Number(id)), [id, store]);

  return (
    <Div>
      <Grid>
        <ImageWrapper>
          <Image src={product?.imageThumbnail || ''} />
        </ImageWrapper>

        <InfoWrapper>
          <ItemTitleWrapper>
            <ItemTitle marginless>
              <HtmlRenderer html={product?.name || ''} />
            </ItemTitle>
          </ItemTitleWrapper>

          <ItemTags>
            <TagList>
              {properties.map((el, idx) => (
                <TagItem key={el.id} to="/">
                  {el.name + (idx === properties.length - 1 ? '' : ',')}
                </TagItem>
              ))}
            </TagList>
          </ItemTags>
          <ItemFooter>
            <ItemInputAmount value={count} onChange={changeCount} />

            <ItemPriceWrapper>
              <ItemPrice>{price} руб.</ItemPrice>
            </ItemPriceWrapper>
          </ItemFooter>
        </InfoWrapper>
      </Grid>

      <RemoveButtonWrapper>
        <IconButton name="iconCrossSmallBlue" onClick={removeFromCart} />
      </RemoveButtonWrapper>
    </Div>
  );
};

export default Item;
