import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';

export const StElements = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
  margin: 50px 0 60px 0;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    gap: 40px;
  }

  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 1514px;
  }
`;

export const StMoreButton = styled.button`
  width: 256px;
  height: 48px;
  background: ${COLORS.linearGradient};
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-transform: uppercase;
  color: ${COLORS.white};
  border: none;
  border-radius: 2px;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
`;

export const StHideButton = styled.button`
  width: 256px;
  height: 48px;
  border: 1px solid ${COLORS.secondary};
  background-color: ${COLORS.white};
  font-weight: 400;
  line-height: 24px;
  text-transform: uppercase;
  color: ${COLORS.secondary};
  border-radius: 2px;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
`;

export const StButtonWrapper = styled.div`
  display: flex;
  width: 288px;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin: 60px auto 100px auto;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin: 60px auto 120px auto;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: unset;
  }

  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    margin: 60px auto 0 auto;
  }
`;
