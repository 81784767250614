import { DynamicApplication } from 'services';

export enum WidgetType {
  TextBlock = 'text_block',
  CharBlock = 'char_block',
  NumberBlock = 'number_block',
  Choice = 'choice',
  TimeInterval = 'time_interval',
  FirstName = 'first_name',
  LastName = 'last_name',
  Patronymic = 'patronymic',
  Phone = 'phone',
  Email = 'email',
  Date = 'date',
  Time = 'time',
  TourDateRange = 'tour_date_range',
}

export interface WidgetValues {
  slug: string;
  description: string;
  placeholder: string;
  required: boolean;
  choices?: string[];
}

export interface Widget {
  id: string;
  type: WidgetType;
  value: WidgetValues;
}

export interface WidgetProps<T> {
  values: WidgetValues;
  formTitle?: string;
}

export interface RenderProps {
  fields: Widget[];
  onSubmitForm: (data: DynamicApplication) => void;
  title?: string;
  successState: boolean;
  successHtmlFragment?: string | null;
  loading?: boolean;
}

export type InputsFormInterface = Record<string, string>;
