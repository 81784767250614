import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';

export const StContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StBrandWrapper = styled.div`
  width: 220px;
  height: 35px;
  margin-top: 48px;
`;

export const StDividerWrapper = styled.div`
  width: 164px;
  margin-top: 24px;
`;

export const StHrDiv = styled.div<{ error: boolean }>`
  height: 1px;
  width: 239px;
  margin-top: 14px;
  background-color: ${(props) => (props.error ? `${COLORS.red}` : `${COLORS.gray5}`)};

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin-top: 16px;
  }
`;

export const StHeader = styled.div`
  margin-top: 16px;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  text-transform: uppercase;
  color: ${COLORS.primary};
`;

export const StButton = styled.button`
  width: 240px;
  height: 48px;
  margin: 24px 0 54px 0;
  border: none;
  background: var(--BG_grad, ${COLORS.linearGradient});
  color: ${COLORS.white};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-transform: uppercase;
  cursor: pointer;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin: 24px 0;
  }

  :disabled {
    background: ${COLORS.gray6};
  }
`;

export const StDescription = styled.div`
  width: 240px;
  margin-top: 18px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  color: ${COLORS.secondary};

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 374px;
  }
`;

export const StErrorMessage = styled.div`
  width: 374px;
  font-size: 12px;
  font-weight: 400;
  color: ${COLORS.red};
  text-align: center;
  margin-bottom: -12px;
`;
