import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { SelectOption } from 'web';
import useResizeObserver from 'use-resize-observer';
import { CheckboxWrapper, Div, Input, Label } from './styles';

interface CheckBoxPropsType {
  options: SelectOption[];
  name: string;
  onChange: (values: SelectOption[]) => void;
  index: number;
  updateFiltersHeights: (index: number, height: number) => void;
}

const PADDING_HEIGHT = 63;

export const Checkbox: React.VFC<CheckBoxPropsType> = React.memo(
  ({ options, name, onChange, updateFiltersHeights, index }) => {
    const [values, setValues] = useState<SelectOption[]>([]);
    const [isCheck, setIsCheck] = useState<string[]>([]);

    useEffect(() => {
      const init = sessionStorage.getItem(name);
      if (init) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setValues(JSON.parse(init));
      }
    }, []);

    const onChangeHandler = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        const newValues = e.currentTarget.checked
          ? [...values, { label: e.currentTarget.name, value: e.currentTarget.value }]
          : values.filter((val) => val.value !== e.currentTarget.value);

        setValues(newValues);
        sessionStorage.setItem(name, JSON.stringify(newValues));
      },
      [values, name],
    );

    const { ref, height } = useResizeObserver<HTMLDivElement>();

    useEffect(() => {
      if (height) {
        updateFiltersHeights(index, height + PADDING_HEIGHT);
      }
    }, [height]);

    useEffect(() => {
      const newCheckValues = values.map((el) => el.value);
      setIsCheck(newCheckValues);
      if (onChange) {
        onChange(values);
      }
    }, [values]);

    return (
      <Div ref={ref}>
        {options.map((option) => (
          <Label key={option.value}>
            <Input
              name={option.label}
              type="checkbox"
              value={option.value}
              onChange={onChangeHandler}
              checked={isCheck.includes(option.value)}
            />
            <CheckboxWrapper /> {option.label}
          </Label>
        ))}
      </Div>
    );
  },
);
