import styled, { css } from 'styled-components';
import { COLORS } from 'utils/constants';

const hoverStyle = css`
  @media (hover: hover) and (pointer: fine) {
    :hover {
      background-color: ${COLORS.primary};
      color: ${COLORS.white};
    }
  }
`;

const Container = styled.div<{ active?: boolean }>`
  height: 42px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-flex;
  padding: 8px 16px;
  border: 1px solid ${COLORS.primary};
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease-out;
  ${(props) => props.active && `background-color: ${COLORS.secondary}; color: ${COLORS.white}`};
  ${(props) => !props.active && hoverStyle}
`;

const Text = styled.div<{ active?: boolean }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: inherit;
`;

export const Styled = { Text, Container };
