import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { WidgetProps } from 'components/applicationForm/types';
import Calendar from 'components/base-ui/calendar';
import { ErrorFormMessage } from 'components/applicationForm';
import { parseISO, isWithinInterval, eachDayOfInterval } from 'date-fns';
import { TourSchedule } from 'services';
import { api } from 'utils/api';
import styled from 'styled-components';

import { ReactComponent as Icon } from 'assets/icons/icon_cal.svg';

const TourRangeName = styled.div`
  font-weight: 700;
  font-size: 16px;
  padding: 8px 0;

  svg {
    width: 8px;
    height: 8px;
    margin-right: 10px;

    path {
      fill: #fff;
    }
  }
`;

export const TourDateRangeWidget: React.FC<WidgetProps<string>> = (props) => {
  const [busSchedule, setBusSchedule] = useState<TourSchedule[] | null>(null);
  const [busScheduleName, setBusScheduleName] = useState<string | null>(null);

  useEffect(() => {
    api.toursSchedule
      .toursScheduleList()
      .then((res) => setBusSchedule(res.data.results))
      .catch(() => setBusSchedule(null));
  }, []);

  const currentDate = useMemo(() => {
    const cDate = new Date();
    cDate.setHours(0, 0, 0, 0);
    return cDate;
  }, []);

  const ranges = useMemo(() => {
    if (busSchedule) {
      return busSchedule
        .map((el) => el.ranges)
        .flat()
        .map((el) => ({ id: el.id, start: parseISO(el.startDate), end: parseISO(el.endDate) }))
        .filter((el) => el.start > currentDate);
    }
  }, [busSchedule]);

  const includeDates = useMemo(() => {
    if (ranges) {
      const dates = ranges.map((el) => eachDayOfInterval(el));
      return dates.flat();
    }
  }, [ranges]);

  const getIntervalId = (val: Date | null) => {
    if (ranges && val) {
      const interval = ranges.filter((el) => (isWithinInterval(val, el) ? el : null));
      return interval[0].id;
    }
  };

  const { values } = props;

  const form = useFormContext();

  const rules = useMemo(() => {
    return {
      required: {
        value: values.required,
        message: 'Обязательное поле',
      },
    };
  }, [props.values.required]);

  return (
    <div>
      <Controller
        name={values.slug}
        control={form.control}
        rules={rules}
        render={({ value, onChange }) => (
          <Calendar
            id={values.slug}
            value={value ? new Date(String(value)) : null}
            label={values.description}
            onChange={(val: Date | null) => {
              const id = getIntervalId(val);
              const schedule = busSchedule?.filter((x) => x.ranges.filter((y) => y.id === id).length > 0)[0];
              onChange(id);
              setBusScheduleName(schedule?.name || null);
            }}
            placeholderText={values.placeholder}
            isBusTour={true}
            includeTourDates={includeDates}
            tourRanges={ranges}
          />
        )}
      />
      {busScheduleName && (
        <TourRangeName>
          <Icon />
          {busScheduleName}
        </TourRangeName>
      )}
      <ErrorFormMessage>
        <ErrorMessage errors={form.errors} name={values.slug} />
      </ErrorFormMessage>
    </div>
  );
};
