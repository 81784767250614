import { makeAutoObservable } from 'mobx';
import { Nullable } from 'web';
import { ResolutionItemType, VideoItemType } from 'store/projects/types';
import { api } from 'utils/api';
import { UserVideoHistory } from 'services';
import { timeCodeFormat, timeCodeToSeconds } from '../lib';

export class VideoGalleryState {
  constructor() {
    makeAutoObservable(this);
  }

  getController = new AbortController();

  setController = new AbortController();

  error: Nullable<string> = null;

  fetching = false;

  currentVideo: Nullable<VideoItemType> = null;

  currentResolution: Nullable<ResolutionItemType> = null;

  seeking = false;

  played = 0;

  playedSeconds = 0;

  duration = 0;

  loaded = 0;

  volume = 100;

  playing = false;

  ready = false;

  muted = false;

  speed = 1;

  videoHistory: Nullable<UserVideoHistory> = null;

  getProgress = async () => {
    if (!this.currentVideo?.id) return;
    this.fetching = true;
    this.getController.abort();
    try {
      const { data: videoHistory } = await api.videoHistoriesApi.videoHistoriesRead(
        { id: String(this.currentVideo.id) },
        { signal: this.getController.signal },
      );
      this.setUserVideoHistory(videoHistory);

      const currentResolution =
        this.currentVideo.resolutions.find(
          (resolution: ResolutionItemType) => resolution.id === videoHistory.resolutionId,
        ) ??
        this.currentVideo.resolutions.find((resolution) => resolution.resolution.includes('720')) ??
        this.currentVideo.resolutions[0];
      const playedSeconds = timeCodeToSeconds(String(videoHistory.timeCode));
      this.setPlayedSeconds(playedSeconds);
      this.setCurrentResolution(currentResolution);
    } catch (e) {
      if (this.currentVideo?.resolutions) {
        this.setCurrentResolution(
          this.currentVideo.resolutions.find((resolution) => resolution.resolution.includes('720')) ??
            this.currentVideo?.resolutions[0],
        );
      }
      this.error = e as string;
    } finally {
      this.fetching = false;
    }
  };

  setProgress = async (videoId: number, playedSeconds?: number) => {
    if (!this.currentResolution) return;
    this.fetching = true;
    this.setController.abort();
    try {
      await api.videoHistoriesApi.videoHistoriesCreate(
        {
          data: {
            videoId,
            resolutionId: this.currentResolution.id,
            timeCode: timeCodeFormat(playedSeconds ?? this.playedSeconds),
          },
          id: String(videoId),
        },
        { signal: this.setController.signal },
      );
    } catch (e) {
      this.error = e as string;
    } finally {
      this.fetching = false;
    }
  };

  setCurrentVideo = (value: VideoItemType) => {
    this.currentVideo = value;
  };

  setCurrentResolution = (value: ResolutionItemType) => {
    this.ready = false;
    this.currentResolution = value;
  };

  setSeeking = (value: boolean) => {
    this.seeking = value;
  };

  setPlayed = (value: number) => {
    this.played = value;
  };

  setPlayedSeconds = (value: number) => {
    this.playedSeconds = value;
  };

  setDuration = (value: number) => {
    this.duration = value;
  };

  setLoaded = (value: number) => {
    this.loaded = value;
  };

  setPlaying = (value: boolean) => {
    this.playing = value;
  };

  setReady = (value: boolean) => {
    this.ready = value;
  };

  setSpeed = (value: number) => {
    this.speed = value;
  };

  setVolume = (value: number) => {
    this.volume = value;
  };

  setMuted = (value: boolean) => {
    this.muted = value;
  };

  setUserVideoHistory = (value: UserVideoHistory) => {
    this.videoHistory = value;
  };

  cleanUp = () => {
    this.getController.abort();
    this.error = null;
    this.fetching = false;
    this.currentVideo = null;
    this.currentResolution = null;
    this.seeking = false;
    this.played = 0;
    this.playedSeconds = 0;
    this.duration = 0;
    this.loaded = 0;
    this.playing = false;
    this.ready = false;
    this.speed = 1;
    this.videoHistory = null;
  };
}
