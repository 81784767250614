import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import iconPointerWhite from 'assets/icons/icon_pointer_white.svg';

import { useBasePageStore, useGlobalCategoriesStore } from 'store';

import { api } from 'utils/api';
import { BREAKPOINTS, COLORS } from 'utils/constants';

import Link from 'components/base-ui/link';

import iconPointer from 'assets/icons/icon_pointer_grey.svg';
import { HtmlRenderer } from '../../../utils/htmlParser';
import { useDeviceState } from '../../../hooks/useDeviceProvider';

interface WhiteProps {
  $white?: boolean;
}

interface CapitalizedProps {
  textTransform?: 'lowercase' | 'capitalize';
}

const Div = styled.div<WhiteProps>`
  column-gap: 4px;
  padding-left: 16px;
  margin-bottom: 28px;
  display: inline-grid;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  color: ${(props) => (props.$white ? COLORS.white : COLORS.gray2)};

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding-left: 32px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding-left: 0;
  }
`;

const DivLine = styled.div<WhiteProps>`
  height: 48px;
  column-gap: 4px;
  padding-left: 16px;
  display: inline-grid;
  align-items: center;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  color: ${(props) => (props.$white ? COLORS.white : COLORS.gray3)};

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding-left: 88px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding-left: 136px;
  }
`;

const StyledLinkLine = styled(Link)<WhiteProps>`
  color: inherit;
  position: relative;
  font-size: 12px;
  line-height: 16px;
  padding-right: 20px;
  white-space: nowrap;
  text-decoration: none;
  text-transform: capitalize;

  &:after {
    content: '';
    top: 0;
    right: 0;
    position: absolute;
    width: 16px;
    height: 16px;
    background-image: url(${(props) => (props.$white ? iconPointerWhite : iconPointer)});
  }

  &:hover {
    color: ${COLORS.primary};
    font-weight: 400;
  }

  &:last-child {
    color: ${COLORS.gray4};

    &:after {
      background-image: none;
    }
  }
`;

const StyledLink = styled(Link)<WhiteProps & CapitalizedProps>`
  color: inherit;
  position: relative;
  font-size: 12px;
  line-height: 16px;
  padding-right: 20px;
  white-space: nowrap;
  text-decoration: none;
  text-transform: ${(props) => (props.textTransform ? props.textTransform : 'lowercase')};

  &:after {
    content: '';
    top: 0;
    right: 0;
    position: absolute;
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    background-image: url(${(props) => (props.$white ? iconPointerWhite : iconPointer)});
  }
  &:last-child {
    color: ${COLORS.gray5};

    &:after {
      background-image: none;
    }
  }
`;

const InlineContainer = styled.div`
  & * {
    display: inline !important;
  }
`;
interface BreadcrumbsProps {
  className?: string;
  white?: boolean;
  lineStyle?: boolean;
  textTransform?: 'lowercase' | 'capitalize';
}

interface Breadcrumb {
  name: string;
  link: string;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
const Breadcrumbs: React.FC<BreadcrumbsProps> = observer(({ white, className, lineStyle = false, textTransform }) => {
  const location = useLocation();
  const { isLessTablet } = useDeviceState();
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([]);
  const goodsRegexp = /^\/(services|goods)\/[a-zA-Z]+\/[0-9]+\/[0-9]+$/;
  const {
    productId,
    categoryType,
    newsId,
    categoryId,
    guidePageId,
    basePageId,
    magazineId,
    articleId,
    awardId,
    medCenterId,
    nominationId,
  } = useParams<Record<string, string>>();

  const store = useGlobalCategoriesStore();
  const basePageStore = useBasePageStore();

  useEffect(() => {
    store.fetchCategories();
  }, []);

  const currentCategory = useMemo(
    () => store.categories.find((c) => c.id === +categoryId),
    [store.categories, categoryId],
  );

  const currentCategoryLink = useMemo(
    () => ({
      name: currentCategory ? currentCategory.name : 'Категория',
      link:
        currentCategory && currentCategory.hasBasket
          ? `/goods/${categoryType}/${categoryId}`
          : `/services/${categoryType}/${categoryId}`,
    }),
    [currentCategory],
  );

  const getBreadCrumbs = async () => {
    const res: Breadcrumb[] = [{ name: 'Главная', link: '/' }];

    const market: Breadcrumb = { name: 'Маркет', link: '/market' };

    const news = { name: 'Новости', link: '/news' };

    const aboutUs = { name: 'О нас', link: '/about-us' };

    if (location.pathname.startsWith('/cart')) {
      res.push({ name: 'Корзина', link: '/cart' });
      return res;
    }

    if (location.pathname.startsWith('/goods/') || location.pathname.startsWith('/services/')) {
      currentCategory?.forMarketMenu ? res.push(market, currentCategoryLink) : res.push(currentCategoryLink);

      if (location?.pathname.match(goodsRegexp)) {
        await api.globalProducts.globalProductsRead({ id: +productId }).then(({ data }) => {
          res.push({ name: data.name, link: `${location.pathname}` });
        });
      }
      return res;
    }

    if (location.pathname.startsWith('/market')) {
      res.push(market);
    }

    if (location.pathname.startsWith('/market/medicine')) {
      res.push({ name: 'Медицина', link: '/market/medicine' });
    }

    if (location.pathname.startsWith(`/market/medicine/${medCenterId}`) && !isLessTablet) {
      await api.medicineCenters.medicineCentersRead({ id: Number(medCenterId) }).then(({ data }) => {
        res.push({ name: data.title, link: `/market/medicine/${medCenterId}` });
      });
    }

    if (location.pathname.startsWith('/news')) {
      res.push(news);
    }

    if (location.pathname.startsWith('/about-us')) {
      res.push(aboutUs);
    }

    if (location.pathname.startsWith('/profile/orders')) {
      res.push({ name: 'Заказы', link: '/profile/orders' });
      return res;
    }

    if (location.pathname.startsWith('/profile/favorite')) {
      res.push({ name: 'Избранное', link: '/profile/favorite' });
      return res;
    }

    if (location.pathname.startsWith('/profile/notifications')) {
      res.push({ name: 'Уведомления', link: '/profile/notifications' });
      return res;
    }

    if (location.pathname.startsWith('/profile')) {
      res.push({ name: 'Профиль', link: '/profile' });
      return res;
    }
    if (location.pathname.startsWith('/page')) {
      res.push({ name: basePageStore.page?.title || 'Страница', link: `/page/${basePageId}` });
      return res;
    }
    if (location.pathname.startsWith('/projects')) {
      res.push({ name: 'Проекты', link: '/projects' });
    }
    if (location.pathname.startsWith('/projects/scarlet')) {
      res.push({ name: '«Алые паруса»', link: '/projects/scarlet' });
    }
    if (location.pathname.startsWith('/projects/award')) {
      res.push({ name: 'Премия «Ассоль»', link: '/projects/award' });
    }
    if (location.pathname.startsWith('/projects/magazines')) {
      res.push({ name: 'Журнал «Ассоль»', link: '/projects/magazines' });
    }
    if (location.pathname.startsWith('/projects/creators')) {
      res.push({ name: '«Создатели»', link: '/projects/creators' });
    }
    if (location.pathname.startsWith('/guide')) {
      res.push({ name: 'Гид', link: '/guide' });
    }
    if (location.pathname.startsWith(`/projects/magazines/${magazineId}`)) {
      await api.projects.projectsMagazinesRead({ id: +magazineId }).then(({ data }) => {
        res.push({ name: data.year.toString(), link: `/projects/magazines/${magazineId}` });
      });
    }
    if (location.pathname.startsWith(`/projects/magazines/${magazineId}/${articleId}`)) {
      await api.projects.projectsMagazinesArticlesRead({ id: +articleId }).then(({ data }) => {
        res.push({ name: data.header, link: `${location.pathname}` });
      });
    }
    if (location.pathname.startsWith(`/projects/award/${awardId}`)) {
      await api.projects.projectsAwardsList().then(({ data }) => {
        const year = data?.results?.filter((item) => item.id === +awardId).map((item) => item.year)[0];
        res.push({ name: year, link: `${location.pathname}` });
      });
    }
    if (location.pathname.startsWith(`/projects/award/${awardId}/${nominationId}`) && !isLessTablet) {
      await api.projects.projectsAwardNominationsRead({ id: Number(nominationId) }).then(({ data }) => {
        const nomination = data?.name;
        res.push({ name: nomination, link: `${location.pathname}` });
      });
    }
    return res;
  };

  useEffect(() => {
    getBreadCrumbs().then((res) => setBreadcrumbs(res));
  }, [
    location.pathname,
    productId,
    categoryType,
    newsId,
    categoryId,
    guidePageId,
    currentCategory,
    currentCategoryLink,
    isLessTablet,
  ]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {lineStyle ? (
        <DivLine $white={white}>
          {breadcrumbs.map((el) => (
            <StyledLinkLine key={el.name} to={el.link} $white={white}>
              <InlineContainer>
                <HtmlRenderer html={el.name ?? ''} />
              </InlineContainer>
            </StyledLinkLine>
          ))}
        </DivLine>
      ) : (
        <Div $white={white} className={className}>
          {breadcrumbs.map((el) => (
            <StyledLink key={el.name} to={el.link} $white={white} textTransform={textTransform}>
              <InlineContainer>
                <HtmlRenderer html={el.name ?? ''} />
              </InlineContainer>
            </StyledLink>
          ))}
        </Div>
      )}
    </>
  );
});

export default Breadcrumbs;
