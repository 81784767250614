import styled from 'styled-components';
import { COLORS } from 'utils/constants';

const Container = styled.div<{ visible: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${COLORS.white};
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  padding: 10px;
`;

export const Styled = { Container };
