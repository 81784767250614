import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { FooterLink, FooterSocials, FooterSocialsActionTypeEnum } from 'services';

import { useFooterStore, useUnderConstructionStore } from 'store';

import { useWindowDimensions } from 'utils/hooks';
import { authService } from 'utils/authentication';
import { BREAKPOINTS, CURRENT_YEAR, UNDER_CONSTRUCTION_LINK } from 'utils/constants';
import {
  ArrowButton,
  BrandLink,
  Copyright,
  CopyrightWrapper,
  FeedbackWrapper,
  FooterContentWrapper,
  FooterTabletWrapper,
  Nav,
  NavColumn,
  NavItem,
  StSocialIcon,
  StSocialIconsWrapper,
  StSocialTitle,
  StyledFooter,
  SupportEmail,
  TechSupportHeader,
  TechSupportInfo,
  TechSupportWrapper,
} from 'components/base-ui/footer/styles';
import { runInAction } from 'mobx';
import Brand from '../brand';
import { Feedback } from '../../feedback';
import { BaseModal } from '../base-modal';
import Link from '../link';

const Footer: React.FC = observer(() => {
  const store = useFooterStore();
  const underConstructionStore = useUnderConstructionStore();
  const [formId, setFormId] = useState<number | null>(null);

  const { width } = useWindowDimensions();

  useEffect(
    () =>
      runInAction(() => {
        store.fetchFooterLinks();
        store.fetchSupportForm();
        store.fetchSupportContacts();
        store.fetchSocialNetworks();
      }),
    [store],
  );

  const isMobile = useMemo(() => {
    return width < parseInt(BREAKPOINTS.tablet);
  }, [width]);

  const mapLinks = useCallback((el: FooterLink, onClick?: () => void) => {
    const handleClick = (event: React.MouseEvent<Element, MouseEvent>) => {
      if (onClick) onClick();
      if (el.link === UNDER_CONSTRUCTION_LINK) {
        event.preventDefault();
        underConstructionStore.setSectionTitle(el.name);
        underConstructionStore.setModalVisible(true);
      }
      if (el.applicationForm) {
        event.preventDefault();
        setFormId(el.applicationForm);
      }
    };

    return <NavItem key={el.id} href={el.link} onClick={handleClick} children={el.name} />;
  }, []);

  const onSocialButtonClick = useCallback((socialNetwork: FooterSocials) => {
    if (socialNetwork.actionType === FooterSocialsActionTypeEnum.ApplicationForm && socialNetwork.applicationForm) {
      setFormId(socialNetwork.applicationForm);
    } else {
      window.open(socialNetwork.link ?? '/');
    }
  }, []);

  const feedbackModalClose = useCallback(() => setFormId(null), []);
  const onFeedbackModalClick = () => {
    setFormId(store.supportForm?.id ?? null);
  };

  return (
    <>
      <StyledFooter>
        {!isMobile && (
          <BrandLink>
            <Link to="/">
              <Brand color="fullWhite" />
              <CopyrightWrapper>
                <Copyright children={`© ${CURRENT_YEAR}, Ассоль`} />
              </CopyrightWrapper>
            </Link>
            <FooterContentWrapper>
              {store.socialNetworks.map((sn) => (
                <StSocialIconsWrapper>
                  <StSocialIcon
                    key={sn.id}
                    title={sn.title}
                    backgroundLink={sn.image ?? ''}
                    hoverLink={sn.hoverImage ?? sn.image ?? ''}
                    onClick={() => onSocialButtonClick(sn)}
                  />
                  <StSocialTitle onClick={() => onSocialButtonClick(sn)}>{sn.title}</StSocialTitle>
                </StSocialIconsWrapper>
              ))}
            </FooterContentWrapper>
          </BrandLink>
        )}

        <FooterTabletWrapper>
          {store.socialNetworks.map((sn) => (
            <StSocialIconsWrapper>
              <StSocialIcon
                key={sn.id}
                title={sn.title}
                backgroundLink={sn.image ?? ''}
                hoverLink={sn.hoverImage ?? sn.image ?? ''}
                onClick={() => onSocialButtonClick(sn)}
              />
              <StSocialTitle onClick={() => onSocialButtonClick(sn)}>{sn.title}</StSocialTitle>
            </StSocialIconsWrapper>
          ))}
        </FooterTabletWrapper>

        {!isMobile && (
          <Nav>
            <>
              <NavColumn>{store.links.filter((el) => el.column === 1).map((c) => mapLinks(c))}</NavColumn>
              <NavColumn>{store.links.filter((el) => el.column === 2).map((c) => mapLinks(c))}</NavColumn>
              <NavColumn>
                <>
                  {store.links.filter((el) => el.column === 3).map((c) => mapLinks(c))}
                  {mapLinks({ link: '/login', name: 'Выйти' }, authService.onLogout)}
                </>
              </NavColumn>
              <NavColumn />
            </>
          </Nav>
        )}

        <FeedbackWrapper>
          <ArrowButton white onClick={onFeedbackModalClick}>
            Обратная связь
          </ArrowButton>
          <TechSupportWrapper>
            <TechSupportHeader>{store.supportHeader}</TechSupportHeader>
            <TechSupportInfo>{store.supportPhone}</TechSupportInfo>
            <TechSupportInfo>
              <SupportEmail href={`mailto:${store.supportEmail}`}>{store.supportEmail}</SupportEmail>
            </TechSupportInfo>
          </TechSupportWrapper>
        </FeedbackWrapper>

        {isMobile && (
          <>
            <Nav>
              <NavColumn>
                {store.links.filter((el) => el.column === 3).map((c) => mapLinks(c))}{' '}
                {mapLinks({ link: '/login', name: 'Выйти' }, authService.onLogout)}
              </NavColumn>
            </Nav>{' '}
            <BrandLink>
              <Link to="/">
                <Brand color="fullWhite" />
                <CopyrightWrapper>
                  <Copyright children={`© ${CURRENT_YEAR}, Ассоль`} />
                </CopyrightWrapper>
              </Link>
              {store.socialNetworks.map((sn) => (
                <StSocialIconsWrapper>
                  <StSocialIcon
                    key={sn.id}
                    title={sn.title}
                    backgroundLink={sn.image ?? ''}
                    hoverLink={sn.hoverImage ?? sn.image ?? ''}
                    onClick={() => onSocialButtonClick(sn)}
                  />
                  <StSocialTitle onClick={() => onSocialButtonClick(sn)}>{sn.title}</StSocialTitle>
                </StSocialIconsWrapper>
              ))}
            </BrandLink>
          </>
        )}
      </StyledFooter>

      <BaseModal isVisible={Boolean(formId)} onClose={feedbackModalClose}>
        <Feedback formId={Number(formId)} />
      </BaseModal>
    </>
  );
});

export default Footer;
