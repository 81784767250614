import AbstractStore, { NullableError } from 'store/AbstractStore';
import { EmailModal, ErrorScreenRelated, MainPage } from 'services';
import { action, makeObservable, observable } from 'mobx';
import { api } from 'utils/api';
import { Block } from 'utils/blocks';
import { AxiosError } from 'axios';

class EmailModalStore {
  isShowing = false;

  setError: (error: NullableError) => void;

  content: EmailModal | null = null;

  constructor(onError: (error: NullableError) => void) {
    this.setError = onError;

    makeObservable(this, {
      isShowing: observable,
      content: observable,
      setShow: action,
    });
  }

  fetchContent = async (): Promise<void> => {
    this.setError(null);
    try {
      const { data } = await api.emailModalsApi.emailModalsRead({ mailType: 'congratulation' });
      if (!data) throw Error('Expected that data is exist');
      this.setContent(data);
    } catch (err) {
      this.setError(err as NullableError);
    }
  };

  setShow(flag: boolean): void {
    this.isShowing = flag;
  }

  setContent(content: EmailModal): void {
    this.content = content;
  }
}

class MainPageStore extends AbstractStore {
  emailModalStore = new EmailModalStore(this.setError.bind(this));

  fetching = false;

  errorScreen: ErrorScreenRelated | null = null;

  content: Block[] | null = null;

  data: MainPage | null = null;

  error: NullableError = null;

  constructor() {
    super();
    makeObservable(this, {
      ...this.annotations,
      fetching: observable,
      errorScreen: observable,
      content: observable,
      data: observable,
      error: observable,
      fetchData: action,
      setErrorScreen: action,
      setData: action,
      setContent: action,
      setError: action,
      closeErrorScreen: action,
    });
  }

  fetchData(): Promise<void> {
    this.setError(null);
    this.setFetching(true);

    return api.mainPage
      .mainPageList()
      .then((res) => {
        this.setData(res.data);
        this.setContent(res.data.content as unknown as Block[]);
        this.setErrorScreen(res.data.errorScreen as unknown as ErrorScreenRelated);
      })
      .catch((err: AxiosError) => this.setError(err))
      .finally(() => this.setFetching(false));
  }

  closeErrorScreen = () => {
    this.setError(null);

    api.closeErrorScreen
      .closeErrorScreenCreate({
        data: {
          screen: this.errorScreen?.id || 0,
        },
      })
      .then(() => this.fetchData())
      .catch((err: AxiosError) => this.setError(err));
  };

  setData(data: MainPage): void {
    this.data = data;
  }

  setContent(content: Block[]) {
    this.content = content;
  }

  setError(value: NullableError): void {
    this.error = value;
  }

  setFetching(value: boolean): void {
    this.fetching = value;
  }

  setErrorScreen(errorScreen: ErrorScreenRelated): void {
    this.errorScreen = errorScreen;
  }
}

export default MainPageStore;
