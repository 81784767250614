import styled from 'styled-components';
import { BREAKPOINTS } from 'utils/constants';

export const Wrapper = styled.div`
  position: relative;
  margin-bottom: 30px;
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    border-bottom: 2px solid #c0cd40;
  }
`;

export const Years = styled.div`
  padding: 12px 0;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  overflow-x: auto;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 12px 16px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    margin-bottom: 0;
    padding: 12px 0;
    gap: 50px;
  }
`;

export const Year = styled.div<{ active?: boolean }>`
  text-align: center;
  min-width: 100px;
  position: relative;
  font-size: 26px;
  line-height: 48px;
  font-weight: 700;
  color: ${({ active }) => (active ? '#D12B5E' : '#8798b0')};
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  :hover {
    color: #d12b5e;
  }

  &:before {
    content: '';
    position: absolute;
    bottom: -12px;
    left: 0;
    width: 100px;
    border-bottom: ${({ active }) => (active ? '6px solid #d12b5e;' : 'unset')};
    z-index: 555;
  }

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    min-width: 135px;
    font-size: 30px;

    &:before {
      width: 135px;
    }
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    font-size: 52px;
  }
`;
