import styled from 'styled-components';
import { COLORS } from 'utils/constants';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Text = styled.div`
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  color: ${COLORS.secondary};
`;

export const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
`;
