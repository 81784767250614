import React from 'react';
import { useCarousel } from 'utils/hooks';
import { VideoItemType } from 'store/projects/types';
import { Container } from './styles';
import { VideoGalleryItem } from './VideoItem';

export interface GalleryCarouselProps {
  data: VideoItemType[];
  onClick: (data: VideoItemType) => void;
}

export const VideoGalleryList: React.FC<GalleryCarouselProps> = ({ data, onClick }) => {
  const { list } = useCarousel(data);

  return (
    <Container>
      {list.map((video) => (
        <VideoGalleryItem key={video?.id} video={video} onClick={onClick} />
      ))}
    </Container>
  );
};
