import React, { useMemo, useCallback } from 'react';
import styled, { css } from 'styled-components';

import { COLORS, PAGE_LIMIT } from '../../../utils/constants';

import { TextButton } from '../button';
import IconButton from '../icon-button';

const Div = styled.div`
  display: grid;
  column-gap: 8px;
  grid-auto-flow: column;
  grid-auto-columns: 16px;
`;

interface PointerButtonProps {
  disabled?: boolean;
}

const disabledStyles = css`
  cursor: not-allowed;
`;

const PointerButton = styled(IconButton)<PointerButtonProps>`
  display: inline-flex;
  align-items: center;

  > img {
    height: 16px !important;
  }

  ${(props) => props.disabled && disabledStyles}

  &:first-of-type {
    transform: rotate(180deg);
  }

  &:disabled {
    background: transparent !important;
  }
`;

interface StyledTextButtonProps {
  active?: boolean;
}

const activeStyles = css`
  pointer-events: none;
  color: ${COLORS.primary};
`;

const StyledTextButton = styled(TextButton)<StyledTextButtonProps>`
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  color: ${COLORS.secondary};

  ${(props) => props.active && activeStyles}

  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
  }
`;

interface PaginationProps {
  count?: number;
  limit?: number;
  current?: number;
  onPagination: (value: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({ count = 1, limit = PAGE_LIMIT, current = 1, onPagination }) => {
  const num = useMemo(() => Math.ceil(count / limit), [count, limit]);

  const [min, max] = useMemo((): [number, number] => [1, num], [num]);

  // Число страниц для отображения
  const numberOfPagesToShow = useMemo(() => (num < 5 ? num : 5), [num]);

  // Настоящее число страниц
  const numberOfPages = useMemo(() => Math.ceil(count / limit), [count, limit]);

  const getPageIndex = useCallback(
    (idx: number) => {
      const idxPlusOne = idx + 1;

      // Если страниц меньше 5 или индекс текущей страницы меньше 3, то показывать индекс в начале
      if (numberOfPages < 5 || current < 3) {
        return idxPlusOne;
      }

      // Если разница между текущей и последней страницами больше либо равна 2, то показывать индекс по центру
      if (Math.abs(current - numberOfPages) >= 2) {
        return idxPlusOne + current - 3;
      }

      // Иначе показывать индекс в конце
      return idxPlusOne + numberOfPages - 5;
    },
    [current, numberOfPages],
  );

  const renderButtons = useMemo(
    () =>
      new Array<number>(numberOfPagesToShow).fill(0).map((_, idx) => {
        const index = getPageIndex(idx);

        return (
          <StyledTextButton key={index} active={current === index} onClick={() => onPagination(index)}>
            {index}
          </StyledTextButton>
        );
      }),
    [current, numberOfPagesToShow, getPageIndex, onPagination],
  );

  return (
    <Div>
      {renderButtons.length > 0 && (
        <>
          <PointerButton name="iconPointer" disabled={current <= min} onClick={() => onPagination(current - 1)} />

          {renderButtons}

          <PointerButton name="iconPointer" disabled={current >= max} onClick={() => onPagination(current + 1)} />
        </>
      )}
    </Div>
  );
};

export default Pagination;
