import { api } from './api';

export const getDocumentExtensions = (path: string) => {
  const splitedPath = path.split('.');
  return splitedPath[splitedPath.length - 1];
};

export const getDocumentIconName = (extension: string, dark?: boolean) => {
  switch (extension) {
    case 'pdf':
      return dark ? 'iconPdfDark' : 'iconPdf';
    case 'zip':
      return dark ? 'iconZipDark' : 'iconZip';
    default:
      return dark ? 'iconDocDark' : 'iconDoc';
  }
};

export const loadDocument = (documentId: number, documentName: string, documentExtension: string) => {
  api.documents
    .documentsDownload(
      {
        id: documentId,
      },
      { responseType: 'blob' },
    )
    .then((res) => {
      const fullName = `${documentName}.${documentExtension}`;
      const file = new File([res.data as unknown as Blob], fullName);
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(file);
      link.setAttribute('download', fullName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
};
