import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';

const ModalOverflow = styled.div<{ padding: number }>`
  touch-action: manipulation;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100001;

  display: flex;
  justify-content: center;
  align-items: center;

  overflow-x: auto;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.73);
  transition: background-color 0.1s linear;
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding-right: ${(props) => props.padding}px;
    padding-left: ${(props) => props.padding}px;
  }
`;

const ModalMask = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
`;

const ModalWindow = styled.div`
  width: 100%;
  height: 100%;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.08);
`;

const ModalCloseWrapper = styled.div`
  top: 8px;
  right: 8px;
  position: absolute;
  z-index: 2;
  cursor: pointer;

  svg {
    width: 14px;
    height: 14px;
  }

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    svg {
      width: 24px;
      height: 24px;
    }

    top: 19px;
    right: 19px;
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    top: 30px;
    right: 32px;
  }
`;

const ModalContainer = styled.div<{ centered?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 100%;

  @media screen and (min-width: 450px) {
    padding: 0 70px;
  }

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 0 100px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding: 0 180px;
  }

  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    padding: 0 300px;
  }
`;

const ModalContent = styled.div`
  color: ${COLORS.white};
`;

export const Styled = {
  ModalContainer,
  ModalWindow,
  ModalContent,
  ModalMask,
  ModalOverflow,
  ModalCloseWrapper,
};
