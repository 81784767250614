import React, { useMemo } from 'react';

type Props = {
  index: number;
  count: number;
};

const svgVariant1 = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16 16H8H0V7.99928V0L16 16Z" fill="#C0CD40" />
    <path
      d="M3.30611e-06 5.08584e-07L8 1.20797e-06L16 1.90735e-06L16 8.00072L16 16L3.30611e-06 5.08584e-07Z"
      fill="#009CDD"
    />
  </svg>
);

const svgVariant2 = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16 16H8H0V7.99928V0L16 16Z" fill="#D12B5E" />
    <path
      d="M3.30611e-06 5.08584e-07L8 1.20797e-06L16 1.90735e-06L16 8.00072L16 16L3.30611e-06 5.08584e-07Z"
      fill="#C0CD40"
    />
  </svg>
);

const svgVariant3 = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16 16H8H0V7.99928V0L16 16Z" fill="#12519A" />
    <path
      d="M3.30611e-06 5.08584e-07L8 1.20797e-06L16 1.90735e-06L16 8.00072L16 16L3.30611e-06 5.08584e-07Z"
      fill="#D12B5E"
    />
  </svg>
);

export const PointIcon: React.FC<Props> = ({ index, count }) => {
  const svg = useMemo(() => {
    for (let i = 0; i <= count; i += 3) {
      if (index === i) return svgVariant1;
      if (index === i - 1) return svgVariant3;
    }
    return svgVariant2;
  }, [index, count]);

  return <div style={{ width: '16px', height: '16px' }}>{svg}</div>;
};
