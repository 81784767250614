import React, { createContext, useContext } from 'react';

import AssolParticipantsStore from './store';

const store = new AssolParticipantsStore();

const WinnersAndParticipantsStateContext = createContext<AssolParticipantsStore>(store);

export const AssolWinnersAndParticipantsProvider: React.FC = ({ children }) => (
  <WinnersAndParticipantsStateContext.Provider value={store}>{children}</WinnersAndParticipantsStateContext.Provider>
);

export const useAssolParticipantsStore = (): AssolParticipantsStore => useContext(WinnersAndParticipantsStateContext);
