import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';

export const StContainer = styled.div``;

export const StTitle = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: ${COLORS.white};
`;

export const StContent = styled.div`
  margin: 24px 0 0 0;
  width: 100%;
  height: 80vh;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  align-content: flex-start;
  gap: 24px;
  overflow: auto;

  scrollbar-width: thin;
  scrollbar-color: ${COLORS.gray2}, ${COLORS.gray4};

  ::-webkit-scrollbar {
    width: 3px;
  }

  ::-webkit-scrollbar-track {
    background: ${COLORS.gray2};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${COLORS.gray4};
    border-radius: 3px;
  }

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    height: 240px;
    margin-top: 16px;
    gap: 16px;
  }
`;

export const StAvatar = styled.div<{ bg: string }>`
  width: 48px;
  height: 48px;
  border-radius: 24px;
  background: ${(props) => `url(${props.bg})`};
  background-size: cover;

  :hover {
    border: 3px solid ${COLORS.secondary};
  }
`;

export const StTooltipText = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: ${COLORS.white};
`;
