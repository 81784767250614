import React from 'react';
import { ImageWithDescription } from 'web';

import { PropsWithSectionOptions } from '../blocks';

import Section from '../../base-ui/section';
import { ImageWithDescriptionBlock } from '../../base-ui/image-with-description';

type ImageWithDescriptionRendererProps = PropsWithSectionOptions<{
  value: ImageWithDescription;
}>;

const ImageWithDescriptionRenderer: React.FC<ImageWithDescriptionRendererProps> = ({ value, options }) => {
  return (
    <Section compact noFirstOfTypeOffset noLastOfTypeMargin={options?.noLastOfTypeMargin}>
      <ImageWithDescriptionBlock image={value.image} text={value.text} />
    </Section>
  );
};

export default ImageWithDescriptionRenderer;
