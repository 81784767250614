import { action, computed, makeObservable, observable } from 'mobx';

import { NewsItemProps, Nullable, ParagraphValue } from 'web';

import { MainNewsPage, NewsPage, NewsPageApiNewsPageListRequest, NewsSection, Profile, ProfileLike } from 'services';

import { api } from 'utils/api';

import { Block, BlockTypes } from 'utils/blocks';
import { AxiosError } from 'axios';
import AbstractStore from '../AbstractStore';

export type ExtendedNewsSections = NewsSection & { active?: boolean };

class NewsStore extends AbstractStore {
  news: NewsPage[] = [];

  mainNews: MainNewsPage | null = null;

  currentNews: NewsPage | null = null;

  count = 0;

  sections: Nullable<ExtendedNewsSections[]> = null;

  constructor() {
    super();
    makeObservable(this, {
      ...this.annotations,
      news: observable,
      mainNews: observable,
      currentNews: observable,
      count: observable,
      sections: observable,
      setCount: action,
      fetchNews: action,
      setNewsList: action,
      setSections: action,
      handleLikeCurrentNewsPost: action,
      handleLikeCurrentNewsDelete: action,
      handleLikePost: action,
      handleLikeDelete: action,
      handleFavoriteCurrentNewsPost: action,
      handleFavoriteCurrentNewsDelete: action,
      closeErrorScreen: action,
      cleanUp: action,
      preparedNews: computed,
      newsForPreview: computed,
    });
  }

  fetchNews(requestParams: NewsPageApiNewsPageListRequest) {
    this.fetchData(() =>
      api.news.pages
        .newsPageList(requestParams)
        .then(({ data: { results, count } }) => {
          this.setNewsList(results);
          this.setCount(count);
        })
        .catch((err: AxiosError) => this.setError(err)),
    );
  }

  fetchMainNews() {
    this.fetchData(() =>
      api.news.mainPage
        .mainNewsPageList()
        .then((res) => {
          const data = res.data as unknown as MainNewsPage;
          this.setMainNews(data);
        })
        .catch((err: AxiosError) => this.setError(err)),
    );
  }

  fetchCurrentNews(newsId: number) {
    this.fetchData(() => {
      return api.news.pages.newsPageRead({ id: newsId }).then((res) => this.setNews(res.data));
    });
  }

  fetchSections = async () => {
    const sections = await this.fetchData<NewsSection[]>(() =>
      api.news.newsSections.newsSectionsList().then((res) => res.data.results),
    );
    if (sections) {
      this.setSections(sections);
    }
  };

  getItemData = (news: NewsPage): NewsItemProps => {
    const res: NewsItemProps = { id: Number(news.id) };
    const content = news.content as unknown as Block[];
    const previewImage = content.find((c) => c.type === BlockTypes.PreviewImage);
    const mainImage = content.find((c) => c.type === BlockTypes.MainImage);
    const mainPageImage = content.find((c) => c.type === BlockTypes.MainPageImage);
    const preview = content.find((c) => c.type === BlockTypes.Preview);
    res.date = news.publishedAt || '';
    res.mainPageImage = mainPageImage?.value ? String(mainPageImage.value) : undefined;
    res.image = previewImage ? String(previewImage.value) : '';
    res.fullImage = mainImage ? String(mainImage.value) : '';
    res.paragraph = preview ? (preview.value as ParagraphValue) : undefined;
    res.isLiked = !!news.isLikedByUser;
    res.isInUserFavorites = !!news.isInUserFavorites;
    return res;
  };

  get preparedNews() {
    return this.news.map(this.getItemData);
  }

  get newsForPreview() {
    return this.preparedNews.filter((item) => item.mainPageImage);
  }

  handleLikePost = (newsId: number, profile: Profile | null) => {
    api.news.pages.newsPageToggleLikeCreate({ id: newsId }).then(() => {
      this.setNewsList(
        this.news.map((n) => {
          n.id === newsId && (n.isLikedByUser = true);
          n.id === newsId && (n.likesCount ? (n.likesCount += 1) : (n.likesCount = 1));
          n.id === newsId &&
            (n.likeAuthors =
              profile?.user && n.likeAuthors && n.likeAuthors.length < 6
                ? [
                    ...n.likeAuthors,
                    {
                      id: profile.id,
                      image: profile.image,
                      email: profile.user.email,
                      fullName: `${profile.lastName} ${profile.firstName}`,
                    },
                  ]
                : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-expect-error
                  [...n.likeAuthors]);
          return n;
        }),
      );
    });
  };

  handleLikeDelete = (newsId: number, userId: number) => {
    api.news.pages.newsPageToggleLikeDelete({ id: newsId }).then(() => {
      this.setNewsList(
        this.news.map((n) => {
          n.id === newsId && (n.isLikedByUser = false);
          n.id === newsId && (n.likesCount ? (n.likesCount -= 1) : (n.likesCount = 0));
          n.id === newsId && n.likeAuthors && (n.likeAuthors = n.likeAuthors.filter((item) => item.id !== userId));
          return n;
        }),
      );
    });
  };

  handleFavoritePost = (newsId: number) => {
    api.news.pages.newsPageToggleFavoriteCreate({ id: newsId }).then(() => {
      this.setNewsList(
        this.news.map((n) => {
          n.id === newsId && (n.isInUserFavorites = true);
          return n;
        }),
      );
    });
  };

  handleFavoriteDelete = (newsId: number) => {
    api.news.pages.newsPageToggleFavoriteDelete({ id: newsId }).then(() => {
      this.setNewsList(
        this.news.map((n) => {
          n.id === newsId && (n.isInUserFavorites = false);
          return n;
        }),
      );
    });
  };

  handleLikeCurrentNewsPost = (profile: Profile | null) => {
    if (this.currentNews) {
      api.news.pages.newsPageToggleLikeCreate({ id: Number(this.currentNews?.id) }).then(() => {
        if (this.currentNews) {
          const likesCount = this.currentNews.likesCount || 0;
          this.currentNews.likesCount = likesCount + 1;
          this.currentNews.isLikedByUser = true;
          this.currentNews.likeAuthors =
            this.currentNews?.likeAuthors && this.currentNews?.likeAuthors?.length < 6
              ? [
                  ...this.currentNews.likeAuthors,
                  {
                    id: profile?.id!,
                    email: profile?.user?.email!,
                    fullName: `${profile?.lastName} ${profile?.firstName}`,
                    image: profile?.image,
                  },
                ]
              : [...this.currentNews.likeAuthors!];
        }
      });
    }
  };

  handleLikeCurrentNewsDelete = (userId: number) => {
    if (this.currentNews) {
      api.news.pages.newsPageToggleLikeDelete({ id: Number(this.currentNews?.id) }).then(() => {
        if (this.currentNews) {
          const likesCount = this.currentNews.likesCount ?? 0;
          this.currentNews.likesCount = likesCount - 1;
          this.currentNews.isLikedByUser = false;
          this.currentNews.likeAuthors = this.currentNews.likeAuthors
            ? this.currentNews.likeAuthors?.filter((item) => item.id !== userId)
            : [];
        }
      });
    }
  };

  handleFavoriteCurrentNewsPost = () => {
    if (this.currentNews) {
      api.news.pages.newsPageToggleFavoriteCreate({ id: Number(this.currentNews?.id) }).then(() => {
        if (this.currentNews) {
          this.currentNews.isInUserFavorites = true;
        }
      });
    }
  };

  handleFavoriteCurrentNewsDelete = () => {
    if (this.currentNews) {
      api.news.pages.newsPageToggleFavoriteDelete({ id: Number(this.currentNews?.id) }).then(() => {
        if (this.currentNews) {
          this.currentNews.isInUserFavorites = false;
        }
      });
    }
  };

  setNewsList(news: NewsPage[]) {
    this.news = news;
  }

  setNews(news: NewsPage) {
    this.currentNews = news;
  }

  setMainNews(news: MainNewsPage) {
    this.mainNews = news;
  }

  setCount(count: number) {
    this.count = count;
  }

  setSections(sections: NewsSection[] | null) {
    this.sections = sections;
  }

  toggleSections = (id: number, active: boolean) => {
    if (this.sections) {
      this.sections = this.sections.map((section) => (section.id === id ? { ...section, active: !active } : section));
    }
  };

  closeErrorScreen() {
    this.fetchData(() =>
      api.closeErrorScreen
        .closeErrorScreenCreate({ data: { screen: this.mainNews?.errorScreen?.id || 0 } })
        .then(() => this.fetchMainNews())
        .catch((err: AxiosError) => this.setError(err)),
    );
  }

  cleanUp = () => {
    this.news = [];
    this.mainNews = null;
    this.currentNews = null;
    this.count = 0;
  };
}

export default NewsStore;
