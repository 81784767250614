import React, { useEffect } from 'react';
import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';

import { usePortalDirectionsStore } from '../../../store';

import { PropsWithSectionOptions } from '../blocks';

import BaseSection from '../../base-ui/section';
import Description from '../../base-ui/description';

type AboutPortalDirectionsProps = PropsWithSectionOptions<object>;

export const AboutPortalDirections: React.FC<AboutPortalDirectionsProps> = observer(({ options }) => {
  const portalDirectionsStore = usePortalDirectionsStore();

  useEffect(
    () =>
      autorun(() => {
        portalDirectionsStore.fetchDirections();
      }),
    [portalDirectionsStore],
  );

  return (
    <>
      {portalDirectionsStore.forProjectPage.map((p) => (
        <BaseSection compact paddingless noLastOfTypeMargin={options?.noLastOfTypeMargin}>
          <Description title={p.title} text={p.description || ''} />
        </BaseSection>
      ))}
    </>
  );
});
