import React from 'react';
import { Images } from 'web';
import Icon from 'components/base-ui/icon';
import { head } from 'lodash';
import { StImage, StImageWrapper, StItem, StSlider } from './styled';

interface Props {
  images: Images;
}

export const ItemImage: React.FC<Props> = (props) => {
  const images = props.images.imageItems.map((item) => item.image);

  return (
    <StItem>
      {images.length > 1 ? (
        <StSlider
          slidesToShow={1}
          slidesToScroll={1}
          arrows={images.length > 1}
          nextArrow={<Icon name="iconNavRight" />}
          prevArrow={<Icon name="iconNavLeft" />}
          adaptiveHeight={true}
        >
          {images.map((image) => (
            <StImageWrapper key={image}>
              <StImage src={image} />
            </StImageWrapper>
          ))}
        </StSlider>
      ) : (
        <StImageWrapper>
          <StImage src={head(images)} />
        </StImageWrapper>
      )}
    </StItem>
  );
};
