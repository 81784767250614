import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';

export const StModalOverflow = styled.div<{ padding: number }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0;
  z-index: 100001;
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: rgba(70, 74, 83, 0.9);
  transition: background-color 0.1s linear;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 0 16px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding-right: ${(props) => props.padding}px;
    padding-left: ${(props) => props.padding}px;
  }
`;

export const StModalMask = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
`;

export const StModalWindow = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  padding: 16px 16px 24px 16px;
  background: ${COLORS.gray1};
  border-radius: 2px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    max-width: 487px;
    height: 320px;
  }
`;

export const StModalCloseWrapper = styled.div`
  top: 19px;
  right: 19px;
  position: absolute;
  z-index: 2;
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    top: 16px;
    right: 16px;
  }
`;

export const StModalContainer = styled.div<{ centered?: boolean }>`
  height: 100%;
  width: 100%;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    max-width: 485px;
    margin: 1.75rem auto;
    display: flex;
    align-items: center;
    min-height: ${(props) => (props.centered ? 'calc(100% - (1.75rem * 2))' : 0)};
  }
`;

export const StModalContent = styled.div`
  color: ${COLORS.white};
`;
