import React, { useState, useCallback } from 'react';
import AnimateHeight from 'react-animate-height';
import styled from 'styled-components';

import StepOne from './one';
import StepTwo from './two';

const Div = styled.div`
  width: 280px;
`;

const Steps: React.FC = () => {
  const [active, setActive] = useState(false);

  const onClick = useCallback(() => {
    if (!active) setActive(true);
  }, [active, setActive]);

  const onCancel = useCallback(() => {
    setActive(false);
  }, []);

  return (
    <AnimateHeight duration={500} height="auto">
      <Div>{!active ? <StepOne next={onClick} /> : <StepTwo next={onClick} cancel={onCancel} />}</Div>
    </AnimateHeight>
  );
};

export default Steps;
