import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import styled, { css } from 'styled-components';

import { MediaOfferValue } from 'web';

import { usePromocodeStore } from 'store/promocode';

import { getWindowDimensions, useToggle } from 'utils/hooks';
import { BREAKPOINTS, COLORS } from 'utils/constants';

import Steps from 'components/base-ui/steps';
import { ArrowButton } from 'components/base-ui/button';
import IconButton from 'components/base-ui/icon-button';
import Brief from 'components/services/shared/media/brief';
import Detail from 'components/services/shared/media/detail';
import { useChip } from 'components/goods/item/chip';

const Container = styled.section`
  overflow: hidden;
  border-radius: 2px;
  padding-right: 16px;
  margin: 0 0 18px 0;
  position: relative;
  color: ${COLORS.gray3};
  background-color: ${COLORS.gray8};

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding-right: 32px;
    margin: 0 56px 18px 56px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding-right: 144px;
    margin: 0 136px 24px 136px;
  }
`;

interface PropsWithActive {
  active?: boolean;
}

const Grid = styled.div<PropsWithActive>`
  display: grid;
  grid-template-rows: ${(props) => (props.active ? '1fr auto auto auto' : '1fr auto 156px auto')};

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    max-height: 800px;
    grid-template-rows: 1fr auto;
    grid-template-columns: 1fr auto;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    max-height: 544px;
  }
`;

const Body = styled.div`
  min-width: 0;
  min-height: 0;
`;

const StepsWrapper = styled.div`
  margin-top: 32px;
  padding: 0 48px 0 64px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    grid-column: 1/-1;
    padding: 0 0 0 32px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    grid-column: 1;
  }
`;

const Actions = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-top: 16px;
  padding: 0 0 8px 16px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin-top: 16px;
    padding: 0 0 8px 32px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    margin-top: 24px;
    padding: 0 0 24px 32px;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

const ActionsControlWrapper = styled.div`
  width: 100%;
  margin-bottom: 16px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 240px;
  }
`;
const ActionsButton = styled(ArrowButton)`
  white-space: nowrap;
`;

const ImageWrapper = styled.div<PropsWithActive>`
  position: relative;
  grid-column: 1;
  margin: 0 0 0 16px;
  grid-row: ${(props) => (props.active ? 2 : 3)};
  overflow: ${(props) => (props.active ? 'visible' : 'hidden')};

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 288px;
    grid-row: 1/-1;
    grid-column: 2;
    margin: 24px 0 0 16px;
  }
`;

const Image = styled.img`
  width: 100%;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    top: 0;
    left: 0;
    position: absolute;
  }
`;

const ToggleWrapper = styled.div`
  top: 24px;
  right: 16px;
  position: absolute;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    right: 32px;
  }
`;

interface ToggleButtonProps {
  active?: boolean;
}

const activeStyles = css`
  transform: rotate(-90deg);
`;

const ToggleButton = styled(IconButton)<ToggleButtonProps>`
  transform: rotate(90deg);
  transition: transform 0.5s ease-out;

  ${(props) => props.active && activeStyles}
`;

interface MediaOfferProps {
  data: MediaOfferValue;
  type: 'MoreTv' | 'SportExpress';
}

const MediaOffer: React.FC<MediaOfferProps> = observer((props) => {
  const store = usePromocodeStore();
  const [active, toggleActive] = useToggle(false);
  const [trigger, Chip] = useChip();

  const onGetPromocodeClick = useCallback(() => store.fetchPromocode().then(trigger), [store, trigger]);

  const openSportExpress = () => {
    const { width } = getWindowDimensions();
    if (width < parseInt(BREAKPOINTS.laptop)) {
      window.open(props.data.buttonLink?.link, '_blank');
    } else {
      trigger();
    }
  };

  const onClick = useCallback(
    () => (props.data.buttonLink ? openSportExpress() : onGetPromocodeClick()),
    [props.type, onGetPromocodeClick],
  );

  const chipContent = useMemo(() => {
    return props.type === 'MoreTv'
      ? 'Промокод отправлен вам на почту'
      : 'Контент при переходе по данной ссылке возможно посмотреть только с мобильных устройств';
  }, [props.type]);

  return (
    <Container>
      <Chip content={chipContent} />

      <Grid active={active}>
        <Body>
          {!active ? (
            <Brief data={{ name: props.data.preview.header, description: props.data.preview.text }} />
          ) : (
            <Detail title={props.data.mainContent.header} description={props.data.mainContent.text} />
          )}
        </Body>

        {active && (
          <StepsWrapper>
            <Steps items={props.data.steps} />
          </StepsWrapper>
        )}

        <Actions>
          <ActionsControlWrapper>
            <ActionsButton white gradient onClick={onClick}>
              {props.data.buttonLink ? props.data.buttonLink.text : 'Получить промокод'}
            </ActionsButton>
          </ActionsControlWrapper>
        </Actions>

        <ImageWrapper active={active}>
          <Image src={props.data.image || ''} />
        </ImageWrapper>
      </Grid>

      <ToggleWrapper>
        <ToggleButton name="iconPointer" round active={active} small onClick={toggleActive} />
      </ToggleWrapper>
    </Container>
  );
});

export default MediaOffer;
