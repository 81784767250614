import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDeviceType } from 'utils/hooks';
import { Button, GalleryWrapper, Header, Wrapper, Item, ItemWrapper, ButtonsWrapper, LightBoxWrapper } from './styles';
import { FullImage, Lightbox, MobileCarousel } from './components';

export type ItemType = { image: string; text: string };

type Props = {
  data: ItemType[];
};

const TABLET_LIMIT = 8;
const DESKTOP_LIMIT = 12;

export const CreatorsImagesBlock: React.FC<Props> = ({ data }) => {
  const device = useDeviceType();

  const [isLightBoxOpen, setIsLightBoxOpen] = useState(false);
  const [currentData, setCurrentData] = useState<ItemType[]>([]);
  const [lightBoxData, setLightBoxData] = useState<ItemType[]>([]);

  const isMobile = useMemo(() => device === 'mobile', [device]);

  const limit = useMemo(() => {
    return device === 'tablet' ? TABLET_LIMIT : DESKTOP_LIMIT;
  }, [device]);

  useEffect(() => {
    if (currentData.length > limit) return;
    setCurrentData(data.slice(0, limit));
  }, [limit]);

  const showMoreButton = useMemo(() => data.length > currentData.length, [data, currentData]);
  const showLessButton = useMemo(() => currentData.length > limit, [currentData, limit]);

  const openLightBox = useCallback(
    (file: ItemType) => {
      const indexCurrentElement = data.indexOf(file);
      setLightBoxData(data);
      if (indexCurrentElement && indexCurrentElement !== 0) {
        const elementsBeforeCurrent = data.slice(0, indexCurrentElement);
        const elementsAfterCurrent = data.slice(indexCurrentElement + 1, data.length);
        setLightBoxData([file, ...elementsAfterCurrent, ...elementsBeforeCurrent]);
      }
      setIsLightBoxOpen(true);
    },
    [data],
  );

  const closeLightBox = useCallback(() => {
    setIsLightBoxOpen(false);
  }, []);

  const onMoreButtonClick = useCallback(() => {
    if (data.length - currentData.length <= limit) {
      setCurrentData(data);
    } else {
      setCurrentData(data.slice(0, currentData.length + limit));
    }
  }, [data, currentData, limit]);

  const onLessButtonClick = useCallback(() => setCurrentData(data.slice(0, limit)), [limit]);

  const lightBoxCustomImages = useMemo(() => {
    return lightBoxData.map(
      (item, index) =>
        (
          <LightBoxWrapper style={{ width: '100vw', height: '100vh' }}>
            <FullImage key={index} image={item.image} text={item.text} />
          </LightBoxWrapper>
        ) as unknown as string,
    );
  }, [lightBoxData]);

  return (
    <Wrapper>
      <Header>Фото</Header>
      <GalleryWrapper>
        {isMobile && <MobileCarousel data={data} onClick={openLightBox} />}
        {!isMobile &&
          currentData.map((item, index) => (
            <ItemWrapper key={index} onClick={() => openLightBox(item)}>
              <Item src={item.image} />
            </ItemWrapper>
          ))}
      </GalleryWrapper>

      <ButtonsWrapper>
        {showMoreButton && !isMobile && <Button onClick={onMoreButtonClick}>Больше фото</Button>}
        {showLessButton && !isMobile && <Button onClick={onLessButtonClick}>Скрыть</Button>}
      </ButtonsWrapper>

      <Lightbox
        showLightBox={isLightBoxOpen}
        toggler={isLightBoxOpen}
        sources={lightBoxCustomImages}
        handleClose={closeLightBox}
      />
    </Wrapper>
  );
};
