import { ControlledMenu } from '@szhsin/react-menu';
import styled from 'styled-components';
import { COLORS } from 'utils/constants';

export const StItem = styled.a`
  padding: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  border: none;
  background-color: transparent;
  color: ${COLORS.secondary};
  cursor: pointer;
  white-space: nowrap;
`;

export const StMenu = styled(ControlledMenu)`
  .szh-menu {
    max-width: 556px;
    margin: 0;
    padding: 16px;
    list-style: none;
    box-sizing: border-box;
    width: max-content;
    z-index: 100;
    background-color: ${COLORS.white};
    user-select: none;
    border: none;
    box-shadow:
      0 3px 7px rgba(0, 0, 0, 0.133),
      0 0.6px 2px rgba(0, 0, 0, 0.1);
  }
  .szh-menu:focus {
    outline: none;
  }
  .szh-menu__arrow {
    box-sizing: border-box;
    width: 0.75rem;
    height: 0.75rem;
    background-color: ${COLORS.white};
    border: 1px solid transparent;
    border-left-color: rgba(0, 0, 0, 0.1);
    border-top-color: rgba(0, 0, 0, 0.1);
    z-index: -1;
  }
  .szh-menu__arrow--dir-left {
    right: -0.375rem;
    transform: translateY(-50%) rotate(135deg);
  }
  .szh-menu__arrow--dir-right {
    left: -0.375rem;
    transform: translateY(-50%) rotate(-45deg);
  }
  .szh-menu__arrow--dir-top {
    bottom: -0.375rem;
    transform: translateX(-50%) rotate(-135deg);
  }
  .szh-menu__arrow--dir-bottom {
    top: -0.375rem;
    transform: translateX(-50%) rotate(45deg);
  }

  @keyframes szh-menu-show-slide-left {
    from {
      opacity: 0;
      transform: translateX(0.75rem);
    }
  }
  @keyframes szh-menu-hide-slide-left {
    to {
      opacity: 0;
      transform: translateX(0.75rem);
    }
  }
  @keyframes szh-menu-show-slide-right {
    from {
      opacity: 0;
      transform: translateX(-0.75rem);
    }
  }
  @keyframes szh-menu-hide-slide-right {
    to {
      opacity: 0;
      transform: translateX(-0.75rem);
    }
  }
  @keyframes szh-menu-show-slide-top {
    from {
      opacity: 0;
      transform: translateY(0.75rem);
    }
  }
  @keyframes szh-menu-hide-slide-top {
    to {
      opacity: 0;
      transform: translateY(0.75rem);
    }
  }
  @keyframes szh-menu-show-slide-bottom {
    from {
      opacity: 0;
      transform: translateY(-0.75rem);
    }
  }
  @keyframes szh-menu-hide-slide-bottom {
    to {
      opacity: 0;
      transform: translateY(-0.75rem);
    }
  }
  .szh-menu--state-opening.szh-menu--dir-left {
    animation: szh-menu-show-slide-left 250ms ease-out;
  }

  .szh-menu--state-closing.szh-menu--dir-left {
    animation: szh-menu-hide-slide-left 100ms ease-in forwards;
  }

  .szh-menu--state-opening.szh-menu--dir-right {
    animation: szh-menu-show-slide-right 250ms ease-out;
  }

  .szh-menu--state-closing.szh-menu--dir-right {
    animation: szh-menu-hide-slide-right 100ms ease-in forwards;
  }

  .szh-menu--state-opening.szh-menu--dir-top {
    animation: szh-menu-show-slide-top 250ms ease-out;
  }

  .szh-menu--state-closing.szh-menu--dir-top {
    animation: szh-menu-hide-slide-top 100ms ease-in forwards;
  }

  .szh-menu--state-opening.szh-menu--dir-bottom {
    animation: szh-menu-show-slide-bottom 250ms ease-out;
  }

  .szh-menu--state-closing.szh-menu--dir-bottom {
    animation: szh-menu-hide-slide-bottom 100ms ease-in forwards;
  }
`;
