import { makeObservable, observable, action, computed } from 'mobx';

import { GraphData, Nullable } from 'web';
import { api } from '../../utils/api';

import { Company, CompanyApiCompanyListRequest } from '../../services';

type NullableError = Error | null;

export default class CompanyStore {
  fetching = false;

  error: NullableError = null;

  companies: Company[] = [];

  constructor() {
    makeObservable(this, {
      fetching: observable,
      error: observable,
      companies: observable,
      fetchCompanies: action,
      setError: action,
      setFetching: action,
      setCompanies: action,
      graphData: computed,
      forMainPage: computed,
      forProjectPage: computed,
    });
  }

  get forMainPage() {
    return this.companies.filter((c) => c.inMainPage);
  }

  get forProjectPage() {
    return this.companies.filter((c) => c.inProjectPage);
  }

  fetchCompanies({ page, limit }: CompanyApiCompanyListRequest = {}, options?: unknown): Promise<void> {
    this.setError(null);
    this.setFetching(true);

    return api.company
      .companyList({ page, limit }, options)
      .then((res) => this.setCompanies(res.data.results))
      .catch((err: Error) => this.setError(err))
      .finally(() => this.setFetching(false));
  }

  setFetching(value: boolean): void {
    this.fetching = value;
  }

  setError(value: NullableError): void {
    this.error = value;
  }

  setCompanies(value: Company[]): void {
    this.companies = value;
  }

  getRoot = () => {
    return this.forProjectPage.find((c) => !c.parent);
  };

  getChilds = (graphItem: GraphData) => {
    return this.forProjectPage
      .filter((c) => c.parent === graphItem.id)
      .map((c) => this.companyToGraphData(c, graphItem.level + 1));
  };

  companyToGraphData = (company: Company, level: number): GraphData => {
    const graphItem: GraphData = {
      id: company.id,
      name: company.name,
      level,
    };
    graphItem.children = this.getChilds(graphItem);
    return graphItem;
  };

  get graphData(): Nullable<GraphData> {
    const rootCompany = this.getRoot();
    if (!rootCompany) {
      this.setError(new Error('Не удалось найти корневую компанию'));
      return null;
    }
    const rootGraphItem = this.companyToGraphData(rootCompany, 1);
    rootGraphItem.children = this.getChilds(rootGraphItem);
    return rootGraphItem;
  }
}
