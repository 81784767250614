import React from 'react';
import { api } from 'utils/api';
import { ProfileLike } from 'services';
import { AxiosError } from 'axios';

type CardType = 'news' | 'guide' | 'article' | 'product';

export const useLikedUsers = (cardType: CardType, id: number | null, limitPerPage: number) => {
  const [error, setError] = React.useState<Error | null>(null);
  const [loading, setLoading] = React.useState(false);
  const [likedUsers, setLikedUsers] = React.useState<ProfileLike[]>([]);
  const [likesCount, setLikesCount] = React.useState(0);
  const [page, setPage] = React.useState(1);

  React.useEffect(() => {
    if (!id) return;

    if (cardType === 'news') {
      setLoading(true);

      api.news.pages
        .newsPageListLikes({ id, page, limit: limitPerPage })
        .then((res) => {
          setLikedUsers(res.data.results);
          setLikesCount(res.data.count);
        })
        .catch((err: Error) => setError(err))
        .finally(() => setLoading(false));
    }

    if (cardType === 'guide') {
      setLoading(true);
      api.guide.pages
        .guidePageListLikes({ id, limit: limitPerPage })
        .then((res) => {
          setLikedUsers(res.data.results);
          setLikesCount(res.data.count);
        })
        .catch((err: Error) => setError(err))
        .finally(() => setLoading(false));
    }

    if (cardType === 'article') {
      setLoading(true);
      api.projects
        .projectsMagazinesArticlesListLikes({ id, limit: limitPerPage })
        .then((res) => {
          setLikedUsers(res.data.results);
          setLikesCount(res.data.count);
        })
        .catch((err: Error) => setError(err))
        .finally(() => setLoading(false));
    }

    if (cardType === 'product') {
      setLoading(true);
      api.products
        .productsListLikes({ id, limit: limitPerPage })
        .then((res) => {
          setLikedUsers(res.data.results);
          setLikesCount(res.data.count);
        })
        .catch((err: Error) => setError(err))
        .finally(() => setLoading(false));
    }
  }, []);

  const updateData = () => {
    if (!id) return;
    if (page * limitPerPage >= likesCount) return;

    if (cardType === 'news') {
      setLoading(true);
      setPage(page + 1);

      api.news.pages
        .newsPageListLikes({ id, page: page + 1, limit: limitPerPage })
        .then((res) => {
          setLikedUsers([...likedUsers, ...res.data.results]);
        })
        .catch((err: Error) => setError(err))
        .finally(() => setLoading(false));
    }

    if (cardType === 'guide') {
      setLoading(true);
      api.guide.pages
        .guidePageListLikes({ id, page: page + 1, limit: limitPerPage })
        .then((res) => {
          setLikedUsers(res.data.results);
        })
        .catch((err: Error) => setError(err))
        .finally(() => setLoading(false));
    }

    if (cardType === 'article') {
      setLoading(true);
      api.projects
        .projectsMagazinesArticlesListLikes({ id, page: page + 1, limit: limitPerPage })
        .then((res) => {
          setLikedUsers(res.data.results);
        })
        .catch((err: Error) => setError(err))
        .finally(() => setLoading(false));
    }

    if (cardType === 'product') {
      setLoading(true);
      api.products
        .productsListLikes({ id, page: page + 1, limit: limitPerPage })
        .then((res) => {
          setLikedUsers(res.data.results);
        })
        .catch((err: Error) => setError(err))
        .finally(() => setLoading(false));
    }
  };

  return { loading, error, likedUsers, likesCount, updateData };
};
