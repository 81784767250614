import React from 'react';
import { HtmlRenderer } from 'utils/htmlParser';
import { Styled } from '../styles';
import { Location } from './Location';

export const MedCenterInfo: React.VFC<{
  title?: string;
  address?: string | null;
}> = (props) => {
  if (!(props.title || props.address)) return null;
  return (
    <Styled.Container direction="column" gap="24px">
      {props.title && (
        <Styled.Title>
          <HtmlRenderer html={props.title} />
        </Styled.Title>
      )}
      {props.address && <Location location={props.address} />}
    </Styled.Container>
  );
};
