import React from 'react';
import { IconSvg } from 'assets/icons';
import { Styled } from './styles';

export const DownloadButton: React.VFC<{ href: string }> = (props) => {
  return (
    <a href={props.href} download target="_blank" rel="noreferrer">
      <Styled.Container>
        <Styled.IconWrapper>
          <IconSvg.Doc />
        </Styled.IconWrapper>
        <Styled.Title>Скачать презентацию</Styled.Title>
      </Styled.Container>
    </a>
  );
};
