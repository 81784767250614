import styled, { css } from 'styled-components';

import { h1Styles, h2Styles, h3Styles, h4Styles, h5Styles, h6Styles, spanStyles } from '../../../styles/text';

import { COLORS } from '../../../utils/constants';

const headingStyles = css`
  color: ${COLORS.secondary};
`;

const marginlessStyles = css`
  & > * {
    margin: 0 !important;
  }
`;

interface HtmlContainerProps {
  gray?: boolean;
  marginless?: boolean;
}

const HtmlContainer = styled.div<HtmlContainerProps>`
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => (props.gray ? COLORS.gray2 : 'inherit')};
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  a {
    color: ${COLORS.secondary};
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.2s ease-out;

    &:hover {
      text-decoration: underline;
    }
  }

  p {
    &:last-of-type {
      margin: 0;
    }
  }

  h1 {
    ${h1Styles}
    ${headingStyles}
  }

  h2 {
    ${h2Styles}
    ${headingStyles}
  }

  h3 {
    ${h3Styles}
    ${headingStyles}
  }

  h4 {
    ${h4Styles}
    ${headingStyles}
  }

  h5 {
    ${h5Styles}
    ${headingStyles}
    font-weight: 400;
  }

  h6 {
    ${h6Styles}
    ${headingStyles}
  }

  span {
    ${spanStyles}
  }

  hr {
    border-top: none;
    border-bottom: 1px solid ${COLORS.gray5};
  }

  ${(props) => props.marginless && marginlessStyles}
`;

export default HtmlContainer;
