import React, { useMemo } from 'react';
import Avatar from 'components/base-ui/avatar';
import { ProfileDataForComment } from 'services';
import DEFAULT_SRC from 'assets/img/assol_avatar.png';
import { Container, UserInfo, UserInfoWrapper } from './styles';

interface Props {
  profile?: ProfileDataForComment;
  createDate?: string;
}

export const CommentHeader: React.FC<Props> = (props) => {
  const username = useMemo(() => {
    return props.profile?.firstName && props.profile?.lastName
      ? `${props.profile.firstName} ${props.profile.lastName}`
      : 'Неизвестный автор';
  }, []);

  const createDate = useMemo(() => {
    return props.createDate ? new Date(props.createDate).toLocaleDateString() : '---';
  }, []);

  return (
    <Container>
      <Avatar src={props.profile?.image ?? DEFAULT_SRC} />
      <UserInfoWrapper>
        <UserInfo bold>{username}</UserInfo>
        <UserInfo style={{ fontSize: '12px' }}>{createDate}</UserInfo>
      </UserInfoWrapper>
    </Container>
  );
};
