import React from 'react';
import { HtmlRenderer } from 'utils/htmlParser';
import { Content, Description, Header, Wrapper, ColumnWrapper, ColumnHeader, ColumnText, Column } from './styled';
import { Icon } from './components';

type ColumnType = {
  header: string;
  text: string;
};

type Props = {
  description: string;
  columns: ColumnType[];
};

export const CreatorsAboutProgramBlock: React.FC<Props> = (props) => {
  return (
    <Wrapper>
      <Content>
        <Header>О программе</Header>
        <Description>
          <HtmlRenderer html={props.description} />
        </Description>
      </Content>

      <ColumnWrapper>
        {props.columns.map((column, index) => (
          <Column key={column.header}>
            <Icon index={index} count={props.columns.length} />
            <div>
              <ColumnHeader>{column.header}</ColumnHeader>
              <ColumnText>{column.text}</ColumnText>
            </div>
          </Column>
        ))}
      </ColumnWrapper>
    </Wrapper>
  );
};
