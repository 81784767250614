import React, { useCallback } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { H1 } from '../base-ui/typo';
import { COLORS, BREAKPOINTS } from '../../utils/constants';
import underConstructionImg from '../../assets/img/under-construction.png';
import IconButton from '../base-ui/icon-button';

import { useUnderConstructionStore } from '../../store';

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100001;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  background: rgba(0, 0, 0, 0.74);
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 0 56px;
  }
`;

const ModalOverflow = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
`;

const ModalContainer = styled.div`
  background: ${COLORS.linearGradient}
  max-width: 656px;
  width: 100%;
  position: relative;
  z-index: 2;
`;

const ModalContent = styled.div`
  padding: 32px 32px 0 32px;
  color: ${COLORS.white};
  text-align: center;
`;

const ModalImage = styled.img`
  max-width: 156px;
  margin-top: 30px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    max-width: 256px;
    margin-top: 30px;
  }
`;

const Header = styled.div`
  width: 100%;
  h1 {
    margin-bottom: 20px;
  }
`;
const ModalCloseButton = styled(IconButton)`
  position: absolute;
  top: 10px;
  right: 10px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    top: 26px;
    right: 35px;
  }
`;

export const UnderConstructionModal: React.FC = observer(() => {
  const store = useUnderConstructionStore();

  const modalClose = useCallback(() => store.setModalVisible(false), []);

  return (
    <div>
      {store.modalState && (
        <Wrapper>
          <ModalOverflow onClick={modalClose} />
          <ModalContainer>
            <ModalCloseButton name="iconCrossBaseWhite" onClick={modalClose} />
            <ModalContent>
              <Header>
                <H1>Раздел находится в разработке</H1>
                <p>Следите за новостями на платформе</p>
              </Header>
              <ModalImage src={underConstructionImg} />
            </ModalContent>
          </ModalContainer>
        </Wrapper>
      )}
    </div>
  );
});
