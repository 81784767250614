import { intervalToDuration } from 'date-fns';
import { ResolutionItemType } from '../../../store/projects/types';

const pad = (value: number) => String(value).padStart(2, '0');

export const format = (seconds: number) => {
  const duration = intervalToDuration({ start: 0, end: seconds * 1000 });
  if (duration.hours) {
    return `${duration.hours}:${pad(duration.minutes ?? 0)}:${pad(duration.seconds ?? 0)}`;
  }
  return `${duration.minutes ?? 0}:${pad(duration.seconds ?? 0)}`;
};

export const timeCodeFormat = (seconds: number) => {
  const duration = intervalToDuration({ start: 0, end: seconds * 1000 });
  return `${duration.hours ?? 0}:${pad(duration.minutes ?? 0)}:${pad(duration.seconds ?? 0)}`;
};

export const timeCodeToSeconds = (timeCode: string) => {
  const [hours, minutes, seconds] = timeCode.split(':');
  return Number(hours) * 60 * 60 + Number(minutes) * 60 + Number(seconds);
};

export const transformResolution = (resolution: string) => {
  const [_, height] = resolution.split('x');
  return `${height}p`;
};

export const compareResolution = (item1: ResolutionItemType, item2: ResolutionItemType) => {
  return Number(item1.resolution.split('x')[1]) - Number(item2.resolution.split('x')[1]);
};
