import { useCallback, useEffect, useState } from 'react';

export const useCollapse = (isCollapsable: boolean) => {
  const [expanded, setExpanded] = useState(!isCollapsable);

  const toggleExpanded = useCallback(() => {
    if (!isCollapsable) throw Error('It should be called only for collapsable component');
    setExpanded((prev) => !prev);
  }, [expanded, isCollapsable]);

  useEffect(() => {
    setExpanded(!isCollapsable);
  }, [isCollapsable]);

  return { expanded, toggleExpanded };
};
