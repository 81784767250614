import styled from 'styled-components';
import { BREAKPOINTS } from 'utils/constants';

export const StAboutOurPeople = styled.div`
  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
