import React, { useEffect, useCallback } from 'react';
import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { CategoryProps } from 'web';

import { ProductList, BankingProduct, InsuranceProduct, MediaProduct } from 'services';

import { useGlobalCategoriesStore } from 'store';

import { BREAKPOINTS } from 'utils/constants';

import { Container, Grid } from 'components/base-ui/cards';

import GoodsCard from 'components/goods/shared/cards/item';
import ServicesCard from 'components/services/shared/leasure/cards/item';

// TODO: extend card props and styles for better css reuse
// as these styles below are repeated in goods/shared/cards
const StyledContainer = styled(Container)`
  margin-bottom: 144px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin-bottom: 168px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    margin-bottom: 114px;
  }
`;

const StyledGrid = styled(Grid)`
  row-gap: 40px;
  column-gap: 62px;
  grid-auto-rows: 472px;
  padding: 0 32px 16px 32px;
`;

interface CardsProps<T> {
  items: T[];
  onShowModal?: (id: number) => void;
}

const Cards = observer(
  <T extends ProductList | BankingProduct | InsuranceProduct | MediaProduct>({ items, onShowModal }: CardsProps<T>) => {
    const globalCategoriesStore = useGlobalCategoriesStore();

    useEffect(() => autorun(() => globalCategoriesStore.fetchCategories()), [globalCategoriesStore]);

    const getItemCard = useCallback(
      (item: T) => {
        const currentCategory = globalCategoriesStore.categories.find((el) => el.id === item.category);

        const category: Partial<CategoryProps> = {
          id: currentCategory?.id?.toString(),
          type: currentCategory?.categoryType,
        } as const;

        return currentCategory?.hasBasket ? (
          <GoodsCard
            key={item.imageThumbnail}
            data={item as ProductList}
            category={category}
            onShowLikeModal={onShowModal}
          />
        ) : (
          <ServicesCard key={item.imageThumbnail} data={item} category={category} handleShowModal={onShowModal} />
        );
      },
      [globalCategoriesStore.categories],
    );

    return (
      <StyledContainer>
        <StyledGrid mode="auto-columns" width="256px">
          {items.map(getItemCard)}
        </StyledGrid>
      </StyledContainer>
    );
  },
);

export default Cards;
