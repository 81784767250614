import React from 'react';
import IconButton from 'components/base-ui/icon-button';
import { IconName } from 'web';
import { capitalizeFirstLetter } from 'utils/functions';
import { WrapperOfIconButton } from './styles';

export const ArrowButton: React.VFC<{
  vector: 'left' | 'right';
  onClick: () => void;
  className?: string;
}> = (props) => {
  const buttonSettings = React.useMemo(() => {
    return {
      iconButtonName: `iconArrow${capitalizeFirstLetter(props.vector)}` as IconName,
      wrapperClassName: `slick-arrow-${props.vector}`,
    };
  }, [props.vector]);

  return (
    <WrapperOfIconButton {...props} className={`${buttonSettings.wrapperClassName} ${props.className ?? ''}`}>
      <IconButton name={buttonSettings.iconButtonName} round shadow gradient />
    </WrapperOfIconButton>
  );
};
