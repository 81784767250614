import ReactHtmlParser, { convertNodeToElement, Options as ParserOptions, Transform } from 'react-html-parser';
import React, { ReactElement } from 'react';
import { PageContentLiteral } from 'web';
import { Link } from 'react-router-dom';
import routes from '../router';

export const MAIN_PAGE = 'mainpage';
export const MEDIA_PAGE = 'mediapage';
export const CATEGORY_PAGE = 'categorypage';
export const PRODUCT_PAGE = 'productpage';
export const GLOBAL_CATEGORY_PAGE = 'globalcategorypage';
export const MAIN_NEWS_PAGE = 'mainnewspage';
export const NEWS_PAGE = 'newspage';
export const MAIN_GUIDE_PAGE = 'mainguidepage';
export const GUIDE_PAGE = 'guidepage';
export const ABOUT_PROJECT_PAGE = 'aboutprojectpage';
export const BASE_PAGE = 'basepage';

const PAGE_TYPES: PageContentLiteral[] = [
  MAIN_PAGE,
  MEDIA_PAGE,
  CATEGORY_PAGE,
  PRODUCT_PAGE,
  GLOBAL_CATEGORY_PAGE,
  MAIN_NEWS_PAGE,
  NEWS_PAGE,
  MAIN_GUIDE_PAGE,
  GUIDE_PAGE,
  ABOUT_PROJECT_PAGE,
  BASE_PAGE,
];

function isValidHttpUrl(string: string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
}

function isDocument(url: string) {
  if (url) {
    const fileName = url.split('/').pop();
    const regExp = /\.([0-9a-z]+)(?:[?#]|$)/i;
    return !!fileName && regExp.test(fileName);
  }
  return false;
}

const pageLinkParser: Transform = (node, index) => {
  const requiredAttribs = ['content_type', 'linktype', 'id'];

  if (
    (typeof node.attribs === 'object' && isDocument(node.attribs.href)) ||
    (isValidHttpUrl(node.attribs.href) && new URL(node.attribs.href).hostname !== window.location.hostname)
  ) {
    node.attribs.target = '_blank';
  }

  if (
    typeof node.attribs === 'object' &&
    requiredAttribs.every((ra) => node.attribs.hasOwnProperty(ra)) &&
    PAGE_TYPES.includes(node.attribs.content_type)
  ) {
    const routes1 = routes[0];
    const route = routes1.routes.find((r) => r.pageContentType?.includes(node.attribs.content_type));
    if (route?.getLink) {
      return (
        <Link key={index} to={route.getLink(node.attribs.id, { ...node.attribs })}>
          {Array.isArray(node.children) && node.children.length > 0 ? node.children[0].data : 'Ссылка'}
        </Link>
      );
    }
  }
};
// add parsers here. Parser must be instance of Transform
const tagParsers: Record<string, Transform[]> = {
  a: [pageLinkParser],
};

export const parseHtml = (html: string): ReactElement[] => {
  const transform: Transform = (node, index) => {
    if (node.type === 'tag' && tagParsers.hasOwnProperty(node.name)) {
      for (const parser of tagParsers[node.name]) {
        const res = parser(node, index);
        if (typeof res !== 'undefined') {
          return res;
        }
      }
    }

    return convertNodeToElement(node, index, transform);
  };
  const options: ParserOptions = {
    decodeEntities: true,
    transform,
  };

  return ReactHtmlParser(html, options);
};

export const HtmlRenderer: React.FC<{ html: string }> = (props) => {
  return <>{parseHtml(props.html)}</>;
};
