import React from 'react';

import { DefaultLayout } from '../../../layouts';

import { GoodsItemComponent } from '../../../components/goods';
import ScrollToTopOnMount from '../../../components/base-ui/scroll-to-top';

const GoodsReadPage: React.FC = () => {
  return (
    <>
      <ScrollToTopOnMount />

      <DefaultLayout>
        <GoodsItemComponent />
      </DefaultLayout>
    </>
  );
};

export default GoodsReadPage;
