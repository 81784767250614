import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { DefaultLayout } from 'layouts';
import ScrollToTopOnMount from 'components/base-ui/scroll-to-top';
import Breadcrumbs from 'components/base-ui/breadcrumbs';
import { AssolWinners } from 'components/assol-winners';
import { BASIC_FONT_FAMILY } from 'utils/constants';
import { useParams } from 'react-router-dom';
import { api } from 'utils/api';
import { WinnersAndNominees } from 'components/assol-winners/WinnersAndNominees';

const Container = styled.div`
  margin-top: 53px;
  font-family: ${BASIC_FONT_FAMILY};
`;

function AssolWinnersPage() {
  const [year, setYear] = useState<number>();
  const { awardId } = useParams<{ awardId: string }>();

  useEffect(() => {
    api.projects.projectsAwardsList().then(({ data }) => {
      const AwardYear = data?.results?.find((item) => item.id === Number(awardId))?.year;
      if (AwardYear) setYear(Number(AwardYear));
    });
  }, []);

  if (!year) return null;

  return (
    <DefaultLayout>
      <ScrollToTopOnMount />
      <Container>
        <Breadcrumbs lineStyle />
        {year > 2018 ? <AssolWinners /> : <WinnersAndNominees />}
      </Container>
    </DefaultLayout>
  );
}

export default AssolWinnersPage;
