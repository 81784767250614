import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { DefaultLayout } from 'layouts';
import ScrollToTopOnMount from 'components/base-ui/scroll-to-top';
import Breadcrumbs from 'components/base-ui/breadcrumbs';
import { useDeviceType } from 'utils/hooks';
import { useProjectsStore } from 'store';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { head } from 'lodash';
import { ArticlesDesktop } from './desktop';
import { ArticlesTablet } from './tablet';
import { ArticlesMobile } from './mobile';

const BreadcrumbsWrapper = styled.div`
  position: relative;
  margin-top: 56px;
  color: #464a53;
`;

const Articles: React.FC = observer(() => {
  const store = useProjectsStore();
  const { magazineId } = useParams<{ magazineId: string }>();
  const { trackPageView } = useMatomo();

  const deviceType = useDeviceType();
  const isMobile = useMemo(() => deviceType === 'mobile', [deviceType]);
  const isTablet = useMemo(() => deviceType === 'tablet', [deviceType]);
  const isLaptop = useMemo(() => deviceType === 'laptop', [deviceType]);
  const isDesktop = useMemo(() => deviceType === 'desktop', [deviceType]);

  useEffect(() => {
    store.fetchArticles({ magazineId: Number(magazineId) });
    document.title = `Статьи журнала ${
      head(store.magazineData?.filter((magazine) => magazine.id === Number(magazineId)))?.year || ''
    }`;
    trackPageView({});
  }, [store]);

  return (
    <DefaultLayout>
      <ScrollToTopOnMount />
      <BreadcrumbsWrapper>
        <Breadcrumbs lineStyle={true} />
      </BreadcrumbsWrapper>
      {(isLaptop || isDesktop) && !store.fetching && <ArticlesDesktop data={store.articles} />}
      {isTablet && !store.fetching && <ArticlesTablet data={store.articles} />}
      {isMobile && !store.fetching && <ArticlesMobile data={store.articles} />}
    </DefaultLayout>
  );
});

export default Articles;
