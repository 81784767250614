import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const Header = styled.div`
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  color: ${COLORS.gray2};
  padding-left: 16px;
  margin-bottom: 16px;
  width: 100%;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding-left: 60px;
    margin-bottom: 28px;
    font-size: 44px;
    line-height: 48px;
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding-left: 135px;
  }
`;
