import React, { HTMLProps, ReactElement } from 'react';
import styled from 'styled-components';

import { COLORS } from '../../../../utils/constants';

interface StyledTrackProps {
  index: number;
}

const StyledTrack = styled.div<StyledTrackProps>`
  top: 50%;
  bottom: 0;
  height: 1px;
  border-radius: 1000px;
  transform: translateY(-50%);
  background: ${(props) => (props.index === 1 ? COLORS.secondary : COLORS.gray6)};
`;

function Track(
  props: Omit<HTMLProps<HTMLDivElement>, 'ref' | 'as'>,
  state: { index: number; value: number | readonly number[]; valueNow?: number },
): ReactElement {
  return <StyledTrack index={state.index} {...props} />;
}

export default Track;
