import React from 'react';
import { StActions, StDetailButton, StOfferButton } from './styled';
import { ActionList } from '../../index';

interface IProps {
  actions: ActionList[];
  offerButtonName: string;
  onDetailClick: () => void;
  onOfferClick: () => void;
}

export const Actions: React.FC<IProps> = (props) => {
  return (
    <StActions
      showActions={props.actions.includes(ActionList.showOfferButton) ||
      props.actions.includes(ActionList.showDetailButton)}
    >
      {props.actions.includes(ActionList.showDetailButton) && (
        <StDetailButton onClick={props.onDetailClick}>
          {props.actions.includes(ActionList.cardCollapsed) ? 'подробнее' : 'назад'}
        </StDetailButton>
      )}
      {props.actions.includes(ActionList.showOfferButton) && (
        <StOfferButton white gradient onClick={props.onOfferClick}>
          {props.offerButtonName}
        </StOfferButton>
      )}
    </StActions>
  );
};
