import { action, makeObservable, observable } from 'mobx';

import { NewsComment, NewsCommentChildren } from 'services';

import { api } from 'utils/api';

import AbstractStore from '../AbstractStore';

interface FetchSubCommentsParams {
  newsId: number;
  parentId: number;
  count?: number;
}

export interface NewsCommentWithChildrens extends NewsComment {
  childrens?: NewsCommentChildren[];
}
class NewsCommentsStore extends AbstractStore {
  comments: NewsCommentWithChildrens[] = [];

  commentsCount = 0;

  newCommentsIds: number[] = [];

  constructor() {
    super();
    makeObservable(this, {
      ...this.annotations,
      comments: observable,
      commentsCount: observable,
      fetchComments: action,
      fetchNextComments: action,
      setComments: action,
      insertNewComment: action,
      addComment: action,
      toggleLikePost: action,
      toggleLikeDelete: action,
      fetchSubComments: action,
      addSubComments: action,
    });
  }

  fetchComments(newsId: number, count: number) {
    this.fetchData(() => {
      return api.news.comments.newsCommentsList({ newsId, parentCommentIdIsnull: true, count }).then(({ data }) => {
        this.setComments(data.results);
        this.setCommentsCount(data.count);
      });
    });
  }

  fetchSubComments(params: FetchSubCommentsParams) {
    this.fetchData(() => {
      return api.news.comments
        .newsCommentsList({
          newsId: params.newsId,
          parentCommentId: params.parentId,
          count: params.count,
          ordering: 'created',
        })
        .then(({ data: { results } }) => {
          this.addSubComments(results);
        });
    });
  }

  addSubComments(comments: NewsCommentChildren[]) {
    if (comments?.[0]?.id) {
      this.setComments(
        this.comments.map((item) => {
          if (item.id === comments[0].parentComment) {
            item.childrens = comments;
          }
          return item;
        }),
      );
    }
  }

  addComment(comment: NewsComment) {
    this.fetchData(() => {
      return api.news.comments
        .newsCommentsCreate({
          data: comment,
        })
        .then((res) => {
          if (res.data.parentComment) {
            this.fetchSubComments({
              newsId: Number(res.data.news),
              count: 999,
              parentId: res.data.parentComment,
            });
          } else {
            this.insertNewComment(res.data);
          }
        });
    });
  }

  fetchNextComments(newsId: number, page: number, count: number) {
    this.fetchData(() => {
      return api.news.comments
        .newsCommentsList({ newsId, parentCommentIdIsnull: true, page, count })
        .then(({ data: { results } }) => {
          this.setNextComments(results);
        });
    });
  }

  setComments(comments: NewsComment[]) {
    this.comments = comments;
  }

  private setCommentsCount(value: number) {
    this.commentsCount = value;
  }

  setNextComments(comments: NewsComment[]) {
    this.comments = [...this.comments, ...comments];
  }

  insertNewComment(comment: NewsComment) {
    let updatedComments = [];
    if (comment.parentComment) {
      updatedComments = this.comments.map((c) => {
        if (c.id === comment.parentComment) c.childrens = [...(c.childrens || []), comment];
        return c;
      });
    } else {
      updatedComments = [comment, ...this.comments];
    }
    if (comment.id) this.newCommentsIds = [...this.newCommentsIds, comment.id];
    if (updatedComments) this.setComments(updatedComments);
  }

  toggleLikePost(id: number, parendId?: number | null) {
    // eslint-disable-next-line sonarjs/cognitive-complexity
    this.fetchData(() => {
      return api.news.comments.newsCommentsToggleLikeCreate({ id }).then(() => {
        this.setComments(
          parendId
            ? this.comments.map((c) => {
                if (c.id === parendId && c.childrens) {
                  c.childrens = c.childrens.map((ch) => {
                    if (ch.id === id) {
                      ch.likesCount = ch.likesCount ? ch.likesCount + 1 : 1;
                      ch.isLikedByUser = true;
                    }
                    return ch;
                  });
                }
                return c;
              })
            : this.comments.map((c) => {
                if (c.id !== id) return c;
                c.likesCount = c.likesCount ? c.likesCount + 1 : 1;
                c.isLikedByUser = true;
                return c;
              }),
        );
      });
    });
  }

  toggleLikeDelete(id: number, parendId?: number | null) {
    // eslint-disable-next-line sonarjs/cognitive-complexity
    this.fetchData(() => {
      return api.news.comments.newsCommentsToggleLikeDelete({ id }).then(() => {
        this.setComments(
          parendId
            ? this.comments.map((c) => {
                if (c.id === parendId && c.childrens) {
                  c.childrens = c.childrens.map((ch) => {
                    if (ch.id === id) {
                      ch.likesCount = ch.likesCount ? ch.likesCount - 1 : 1;
                      ch.isLikedByUser = false;
                    }
                    return ch;
                  });
                }
                return c;
              })
            : this.comments.map((c) => {
                if (c.id !== id) return c;
                c.likesCount = c.likesCount ? c.likesCount - 1 : 1;
                c.isLikedByUser = false;
                return c;
              }),
        );
      });
    });
  }
}

export default NewsCommentsStore;
