import React, { useCallback } from 'react';
import styled from 'styled-components';

import { FreeContentBannerListItem } from 'web';

import { useHistory } from 'react-router-dom';
import { BREAKPOINTS, COLORS } from '../../../../../utils/constants';

import { smallStyles } from '../../../../../styles/text';

import IconButton from '../../../../base-ui/icon-button';
import HtmlContainer from '../../../../base-ui/html-container';

import iconArrowWhite from '../../../../../assets/icons/icon_arrow_white.svg';
import { HtmlRenderer } from '../../../../../utils/htmlParser';

const Head = styled.div`
  height: 366px;
  margin-bottom: 24px;
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  border-radius: 8px;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.35));
`;

const Image = styled.img`
  top: 50%;
  left: 50%;
  height: 100%;
  position: absolute;
  transform: translate(-50%, -50%);

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: auto;
    height: 100%;
  }
`;

const LikeButton = styled(IconButton)`
  right: 12px;
  bottom: 14px;
  position: absolute;
`;

const Body = styled.div``;

const Title = styled.div`
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 6px;
  padding-right: 40px;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  text-decoration: none;
  color: ${COLORS.white};
  transition: all 0.2s ease-out;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 5px;
    right: 7px;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-image: url(${iconArrowWhite});
  }
`;

const Div = styled.div`
  display: grid;
  row-gap: 24px;
  grid-template-rows: 366px min-content;
  cursor: pointer;
  &:hover ${Title}, &:focus ${Title}, &:active ${Title} {
    color: ${COLORS.gray7};
  }
`;

const Description = styled(HtmlContainer)`
  ${smallStyles}
`;

interface CardProps {
  data: FreeContentBannerListItem;
}

const Card: React.FC<CardProps> = ({ data }) => {
  const history = useHistory();

  const cardClickHandler = useCallback(
    ev => {
      window.open(data.buttonLink.link, '_blank');
    },
    [data.buttonLink.link],
  );

  return (
    <Div onClick={cardClickHandler}>
      <Head>
        <ImageWrapper>
          <Image src={data.image} />
          <LikeButton name="iconLikeWhite" />
        </ImageWrapper>
      </Head>

      <Body>
        <Title>{data.paragraph.header}</Title>
        <Description>
          <HtmlRenderer html={data.paragraph.text || ''} />
        </Description>
      </Body>
    </Div>
  );
};

export default Card;
