import React from 'react';
import { format } from '../lib';

export interface DurationProps {
  className?: string;
  seconds: number;
}

export const Duration: React.FunctionComponent<DurationProps> = ({ className, seconds }) => (
  <time dateTime={`P${Math.round(seconds)}S`} className={className}>
    {format(seconds)}
  </time>
);
