import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { useGuidePagesStore, useNewsStore, useSearchStore } from 'store';

import Section from 'components/base-ui/section';
import { GuideSearchTable } from './guide';
import { NewsSearchTable } from './news';
import { ProductsSearchRenderer } from './products';
import { SearchPaginatedList } from './paginated-list';
import { ArticleSearchTable } from './article';

const NoData = styled.div`
  text-align: center;
  font-size: 24px;
`;

export const SearchSection = styled(Section)`
  position: relative;
  z-index: 1;
`;

const SearchComponent: React.FC = observer(() => {
  const store = useSearchStore();
  const guideStore = useGuidePagesStore();
  const newsStore = useNewsStore();

  const { searchValue } = useParams<{ searchValue: string }>();

  useEffect(() => store.fetchSearch(searchValue), [searchValue, guideStore.pages, newsStore.news]);

  return (
    <Section noFirstOfTypeOffset>
      <ProductsSearchRenderer />

      <SearchPaginatedList field="bankingProducts" category="finance" heading="Финансы" />
      <SearchPaginatedList field="insuranceProducts" category="insurance" heading="Страхование" />
      <SearchPaginatedList field="mediaProducts" category="media" heading="Медиа" />

      <GuideSearchTable />
      <NewsSearchTable />
      <ArticleSearchTable />

      {store.notFound && (
        <Section noFirstOfTypeOffset>
          <NoData>Ничего не найдено</NoData>
        </Section>
      )}
    </Section>
  );
});

export default SearchComponent;
