import styled, { css } from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';

import { ReactComponent as IconDownSvg } from 'assets/icons/icon_down.svg';
import { H1 } from '../../../../base-ui/typo';

const List = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 24px;
  margin: 0;

  background: ${COLORS.primary};
  border-radius: 8px;

  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    width: 320px;
  }
`;

const Title = styled(H1)`
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-size: 34px;
    line-height: 40px;
  }
`;

const Header = styled.header<{ expanded?: boolean; collapsable?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${(props) =>
    props.collapsable &&
    css`
      cursor: pointer;
    `};
`;

const IconDown = styled(IconDownSvg)`
  width: 24px;
  height: 24px;
  transition: all 0.3s ease-out;
  & * {
    fill: ${COLORS.secondary};
  }
  &.expanded {
    transform: rotate(180deg);
  }
  &.collapsed {
    transform: rotate(0deg);
  }
`;

const Line = styled.div<{ expanded?: boolean }>`
  margin: 0 auto;
  width: 100%;
  height: 1px;
  background-color: ${COLORS.borderGray};
  transition: width 0.5s ease-out;
  &.expanded {
    width: 0;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 0 16px 32px 16px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin: 0 0 32px 0;
  }

  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    margin: 0;
  }
`;

export const Styled = { List, Title, Header, Line, IconDown, Container };
