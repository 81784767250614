import styled from 'styled-components';
import { Micro } from 'components/base-ui/typo';
import { COLORS } from 'utils/constants';
import Input from 'components/base-ui/input';

export const Div = styled.div`
  position: relative;
`;
export const Title = styled(Micro)`
  left: 0;
  top: -20px;
  position: absolute;
  color: ${COLORS.gray};
`;
export const Header = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(2, minmax(auto, 50%));
  margin-top: 16px;

  > div {
    &:first-child > input {
      border-right: none;
      border-radius: 2px 0 0 2px;
    }

    &:last-child > input {
      border-radius: 0 2px 2px 0;
    }
  }
`;
export const StyledInput = styled(Input)`
  width: unset;
`;
export const Body = styled.div<{ display: string }>`
  display: ${(props) => props.display};

  .RSlider {
    width: 100%;

    .thumb {
      top: -8px;
    }
  }
`;
