import React from 'react';
import styled from 'styled-components';
import { BREAKPOINTS } from '../../../utils/constants';

interface DivProps {
  active?: boolean;
}

const Div = styled.div<DivProps>`
  width: 100%;
  height: inherit;
  overflow: hidden;
  position: relative;
  display: ${(props) => (props.active ? 'block' : 'none')};
`;

const Img = styled.img`
  top: 50%;
  left: 50%;
  height: 100%;
  position: absolute;
  transform: translate(-50%, -50%);

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 100%;
    height: auto;
  }
`;

interface ItemProps extends DivProps {
  data: {
    image: string;
  };
}

export const GalleryItem: React.FC<ItemProps> = ({ data, active }) => {
  return (
    <Div active={active}>
      <Img src={data.image} />
    </Div>
  );
};
