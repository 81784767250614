import React from 'react';
import styled from 'styled-components';

import { COLORS } from '../../../utils/constants';

import { Micro } from '../typo';

const Label = styled.label`
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

interface CheckmarkProps {
  checked: boolean;
}

const Checkmark = styled.span<CheckmarkProps>`
  top: 6px;
  left: 2px;
  width: 20px;
  height: 20px;
  position: absolute;
  border-radius: 2px;
  border: 1px solid ${COLORS.white};
  background-color: transparent;

  &:after {
    content: '';
    top: 3px;
    left: 6px;
    position: absolute;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);

    display: ${(props) => (props.checked ? 'block' : 'none')};
  }
`;

const Text = styled(Micro)`
  display: block;
  max-width: 176px;
`;

interface CheckboxProps extends CheckmarkProps {
  onChange?: (checked: boolean) => void;
}

const Checkbox: React.FC<CheckboxProps> = ({ checked, onChange, children }) => {
  return (
    <Label>
      <Input type="checkbox" checked={checked} onChange={() => onChange && onChange(!checked)} />
      <Checkmark checked={checked} />
      <Text>{children}</Text>
    </Label>
  );
};

export default Checkbox;
