import React, { useMemo } from 'react';

type Props = {
  index: number;
  count: number;
};
const svgVariant1 = (
  <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.36068e-06 3.08341e-06L26 5.3564e-06L52 7.62939e-06L52 26.0024L52 52L8.36068e-06 3.08341e-06Z"
      fill="#D12B5E"
    />
    <path d="M52 52H26H0V25.9977V0L52 52Z" fill="#C0CD40" />
  </svg>
);

const svgVariant2 = (
  <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M52 0H0V52H52V0Z" fill="#12519A" />
    <path d="M38.9965 38.9988L51.9977 25.9977L38.9965 13.0011L26 0V52L38.9965 38.9988Z" fill="#009CDD" />
    <path d="M13.0035 13.0012L0.00232542 26.0023L13.0035 38.9989L26 52L26 0L13.0035 13.0012Z" fill="#D12B5E" />
  </svg>
);

const svgVariant3 = (
  <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M52 0H0V52H52V0Z" fill="#12519A" />
    <path
      // eslint-disable-next-line max-len
      d="M1.6553e-06 26.0023C-0.000607578 29.4161 0.671447 32.7967 1.9778 35.9508C3.28414 39.105 5.19918 41.971 7.61356 44.3851C10.0279 46.7993 12.8944 48.7144 16.0491 50.0209C19.2039 51.3275 22.5852 52 26 52V0C22.5849 -2.17356e-07 19.2031 0.672622 16.0481 1.97947C12.893 3.28633 10.0264 5.20179 7.61192 7.61645C5.19747 10.0311 3.28257 12.8976 1.97649 16.0523C0.670418 19.207 -0.00121695 22.5881 1.6553e-06 26.0023Z"
      fill="#009CDD"
    />
    <path
      // eslint-disable-next-line max-len
      d="M52 25.9977C52.0006 22.5839 51.3286 19.2033 50.0222 16.0492C48.7159 12.895 46.8008 10.029 44.3864 7.61487C41.9721 5.2007 39.1056 3.28564 35.9509 1.97908C32.7961 0.672518 29.4148 1.84023e-06 26 1.54171e-06L26 52C29.4151 52 32.7969 51.3274 35.9519 50.0205C39.107 48.7137 41.9736 46.7982 44.3881 44.3835C46.8025 41.9689 48.7174 39.1024 50.0235 35.9477C51.3296 32.793 52.0012 29.4119 52 25.9977Z"
      fill="#C0CD40"
    />
  </svg>
);

export const Icon: React.FC<Props> = ({ index, count }) => {
  const svg = useMemo(() => {
    for (let i = 0; i <= count; i += 3) {
      if (index === i) return svgVariant1;
      if (index === i - 1) return svgVariant3;
    }
    return svgVariant2;
  }, [index, count]);

  return <div style={{ width: '52px', height: '52px' }}>{svg}</div>;
};
