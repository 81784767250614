import React, { useMemo, useCallback, useRef } from 'react';
import { AwardWinners } from 'services';

import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperType } from 'swiper/types';
import { useBreakpointsDimensions } from 'utils/hooks';
import { observer } from 'mobx-react-lite';
import { Arrow } from './button';
import { Container, ListContainer, SlickWrapper } from './styles';
import { Item } from './item';
import 'swiper/swiper.min.css';

interface WinnersCarouselProps {
  winners: AwardWinners[];
  currentBusiness?: number;
}

export const WinnersCarousel: React.VFC<WinnersCarouselProps> = observer((props) => {
  const swiperRef = useRef<SwiperType>();
  const { width } = useBreakpointsDimensions();

  const viewCount = useMemo(() => {
    if (width > 1600) return 3;
    if (width > 1200) return 2;
    return 1;
  }, [props.winners.length, width]);

  const withoutSwiper = useMemo(() => viewCount >= props.winners.length, [props.winners.length, viewCount]);

  const handleOnPrevClick = useCallback(() => swiperRef.current?.slidePrev(), [swiperRef.current]);
  const handleOnNextClick = useCallback(() => swiperRef.current?.slideNext(), [swiperRef.current]);

  if (withoutSwiper) {
    return (
      <Container>
        <ListContainer>
          {props.winners.map((item) => (
            <Item key={JSON.stringify(item)} winner={item} />
          ))}
        </ListContainer>
      </Container>
    );
  }

  return (
    <Container>
      <SlickWrapper width={viewCount * 452 - 32}>
        <Swiper
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper;
          }}
          watchOverflow
          slidesPerGroup={1}
          slidesPerView={1}
          loop
          spaceBetween={32}
          modules={[Navigation]}
          className="mySwiper"
          speed={500}
          navigation={{ prevEl: '.slick-arrow-left', nextEl: '.slick-arrow-right', enabled: true }}
          breakpoints={{
            1200: {
              slidesPerGroup: 2,
              slidesPerView: 2,
            },
            1600: {
              slidesPerGroup: 3,
              slidesPerView: 3,
            },
          }}
        >
          {props.winners.map((item) => (
            <SwiperSlide key={JSON.stringify(item)}>
              <Item winner={item} />
            </SwiperSlide>
          ))}
        </Swiper>
        <Arrow onClick={handleOnNextClick} vector="right" />
        <Arrow onClick={handleOnPrevClick} vector="left" />
      </SlickWrapper>
    </Container>
  );
});
