import React from 'react';
import { PortalDirectionItem, PortalDirectionSubItem } from 'services';
import chevronDown from 'assets/icons/chevron_down.svg';
import defaultItemIcon from 'assets/icons/icon_menu_item_default.svg';
import { StLink, StTitle } from './styled';

interface Props {
  item: PortalDirectionItem | PortalDirectionSubItem;
}

export const ItemHeader: React.FC<Props> = (props) => {
  return (
    <>
      <StTitle>
        {'parentItem' in props.item && <img src={props.item.icon ?? defaultItemIcon} alt="icon" />}
        <StLink to={props.item.link} nested={'parentItem' in props.item}>
          {props.item.title}
        </StLink>
      </StTitle>
      {'subItems' in props.item && props.item.subItems?.length && props.item.subItems?.length > 0 ? (
        <img className="chevron-down" src={chevronDown} alt="Chevron Down" />
      ) : null}
    </>
  );
};
