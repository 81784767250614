import React from 'react';
import { observer } from 'mobx-react-lite';
import { useCreatorsStore } from 'store';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Block } from 'utils/blocks';
import { BlocksRender } from '../renderers';

export const Creators: React.FC = observer(() => {
  const store = useCreatorsStore();
  const { trackPageView } = useMatomo();

  React.useEffect(() => {
    store.fetchContent();
    document.title = 'Создатели';
    trackPageView({});
  }, []);

  return (
    <BlocksRender
      blocks={store?.content?.content as unknown as Block[]}
      loading={store?.fetching}
      options={{ section: { compact: true } }}
    />
  );
});
