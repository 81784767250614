import React from 'react';
import ScrollToTopOnMount from 'components/base-ui/scroll-to-top';
import { DefaultLayout } from 'layouts';
import { MedicineCenterContent } from 'components/medicine/medicine-center';
import { MedicineCenterProvider } from 'store/medicine-center';
import { ServicesNavigation } from 'components/services/shared/navigation';

export const MedicineCenterPage: React.FC = () => {
  return (
    <>
      <ScrollToTopOnMount />
      <DefaultLayout>
        <ServicesNavigation textTransform="capitalize" white />
        <MedicineCenterProvider>
          <MedicineCenterContent />
        </MedicineCenterProvider>
      </DefaultLayout>
    </>
  );
};
