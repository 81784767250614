import React, { useState, useMemo, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { CategoryProps } from 'web';

import { ProductList } from 'services';

import { useCartStore } from 'store/cart-store';

import { COLORS } from 'utils/constants';
import { validateNumber } from 'utils/validators';

import { H6, Small, Micro } from 'components/base-ui/typo';
import InputAmount from 'components/base-ui/input-amount';
import IconButton from 'components/base-ui/icon-button';

import { useChip } from 'components/goods/item/chip';
import { GoodChipContent } from 'components/goods/shared/chip-content';
import { useProperties } from 'utils/attributes';
import { Link } from 'react-router-dom';

const Div = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 6px;
  position: relative;

  &:after {
    content: '';
    left: 0;
    bottom: 100%;
    width: 100%;
    height: 1px;
    position: absolute;
    background: ${COLORS.gray5};
  }
`;

const Head = styled(Link)`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  height: 40px;
  overflow: hidden;
`;

const Body = styled.div`
  display: flex;
  align-items: center;
`;

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const Price = styled(H6)`
  color: ${COLORS.secondary};
`;

const PriceCurrency = styled(Small)`
  color: ${COLORS.secondary};
`;

const PriceWithoutDiscount = styled(Micro)`
  color: ${COLORS.gray4};
  text-decoration: line-through;
`;

const InputWrapper = styled.div`
  margin: 0 14px 0 auto;
`;

const StyledInput = styled(InputAmount)`
  text-align: center;
`;

const BagButton = styled(IconButton)`
  width: 48px;
  height: 48px;
  padding: 13px 14.9px;

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

interface FooterProps {
  data: ProductList;
  category?: Partial<CategoryProps>;
}

const Footer: React.FC<FooterProps> = observer(({ data, category }) => {
  const [amount, setAmount] = useState(1);

  const cartStore = useCartStore();
  const attributes = useProperties(data);

  const to = useMemo(() => {
    return category?.id && category.type ? `/goods/${category.type}/${category.id}/${data.id}` : '/';
  }, [data.id, category]);

  const memoizedPrice = useMemo(() => {
    return data.discountPrice || data.price || 'Нет цены';
  }, [data.discountPrice, data.price]);

  const onChange = useCallback(
    (value: number) => {
      setAmount(() => validateNumber(value, { min: 1 }));
    },
    [setAmount],
  );

  const [trigger, Chip] = useChip();

  const addToCart = useCallback(() => {
    cartStore.addToCart(Number(data.id), amount);

    trigger();
  }, [data.id, amount, cartStore, trigger]);

  return (
    <Div>
      <Chip content={<GoodChipContent product={data} />} />

      <Head to={to}>{attributes}</Head>

      <Body>
        <PriceWrapper>
          {data.discountPrice && <PriceWithoutDiscount>{data.price} руб.</PriceWithoutDiscount>}
          <Price>
            {memoizedPrice}
            {memoizedPrice !== 'Нет цены' && <PriceCurrency> руб.</PriceCurrency>}
          </Price>
        </PriceWrapper>

        <InputWrapper>
          <StyledInput value={amount} onChange={onChange} min={1} />
        </InputWrapper>

        <BagButton name="iconBag" round gradient onClick={addToCart} />
      </Body>
    </Div>
  );
});

export default Footer;
