import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { ServicesBannerValue, SubstitutedCategoryType } from 'web';

import { Feedback } from 'components/feedback';
import { BaseModal } from 'components/base-ui/base-modal';
import { useSubscribe } from 'components/services/utils';
import { BREAKPOINTS } from 'utils/constants';

import { smallStyles } from 'styles/text';

import { H1, H6 } from 'components/base-ui/typo';
import { ArrowButton } from 'components/base-ui/button';
import HtmlContainer from 'components/base-ui/html-container';
import { HtmlRenderer } from 'utils/htmlParser';
import { debounce } from 'lodash';
import { useDynamicApplicationStore } from 'store';
import { ApplicationFormIntegrationEnum, DynamicApplication } from 'services';
import { useChip } from 'components/goods/item/chip';

const Div = styled.div`
  height: 100%;
  display: grid;
  padding: 168px 34px 108px 16px;
  grid-template-rows: repeat(2, min-content);

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 168px 43.5px 207.5px 56px;
    max-width: calc(447px + 43.5px + 56px);
    grid-template-rows: repeat(3, min-content);
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding: 248px 80px 207.5px 136px;
    max-width: calc(768px + 80px + 136px);
  }
`;

const Head = styled.div`
  margin-bottom: 16px;
`;

const Title = styled(H1)``;

const Body = styled.div`
  max-width: 320px;
  margin-bottom: 24px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    max-width: 320px;
    margin-bottom: 24px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    max-width: 656px;
    margin-bottom: 32px;
  }
`;

const Description = styled.div`
  display: none;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    display: block;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    margin-bottom: 32px;
  }
`;

const Grid = styled.div`
  display: none;

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    display: grid;
    column-gap: 24px;
    grid-auto-flow: column;
    grid-auto-columns: minmax(200px, 1fr);
  }
`;

const Column = styled.div``;

const Subtitle = styled(H6)`
  margin-bottom: 8px;
`;

const Subdescription = styled(HtmlContainer)`
  ${smallStyles}
`;

const Footer = styled.div`
  display: grid;
  row-gap: 8px;
  column-gap: 16px;
  grid-auto-rows: 48px;
  grid-auto-columns: 235px;

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    grid-auto-flow: column;
  }
`;

type BriefProps = Pick<ServicesBannerValue, 'header' | 'buttonLink' | 'preview' | 'textColumns'> & {
  onClick: () => void;
  subscribeFromPage: boolean;
  buttonAction?: string;
  appFormId: number | null;
};

const Brief: React.FC<BriefProps> = (
  {
    header,
    buttonLink,
    preview,
    textColumns,
    onClick,
    subscribeFromPage,
    buttonAction,
    appFormId,
  }) => {
  const { categoryType } = useParams<{ categoryType: SubstitutedCategoryType; productId: string }>();
  const [showForm, setShowForm] = useState(false);
  const dynamicApplicationStore = useDynamicApplicationStore();

  const [subscribe, Chip] = useSubscribe({
    havePage: true,
    subscribeFromPage,
    link: buttonLink.link,
    buttonAction,
    promocode: categoryType === 'media',
  });

  const [trigger, Notify] = useChip();

  useEffect(() => {
    if (!appFormId) return;

    dynamicApplicationStore.fetchDynamicForm(appFormId);
    return () => {
      dynamicApplicationStore.resetStore();
    };
  }, []);

  const handleFormWithoutFields = useCallback(() => {
    if (!appFormId) return;
    dynamicApplicationStore.createDynamicApplication({ form: appFormId } as DynamicApplication);
    trigger();
  }, [appFormId]);

  const handleShowForm = useCallback(() => {
    if (!appFormId) return;
    if (dynamicApplicationStore.integrationType === ApplicationFormIntegrationEnum.MoreTv
      || dynamicApplicationStore.integrationType === ApplicationFormIntegrationEnum.Litres) {
      handleFormWithoutFields();
    } else {
      setShowForm(true);
    }
  }, [dynamicApplicationStore.integrationType]);

  const handleCloseForm = useCallback(() => setShowForm(false), []);

  const notifyText = React.useMemo(() => {
    if (dynamicApplicationStore.error) {
      return 'Не удалось отправить промокод';
    }
    if (dynamicApplicationStore.integrationType === ApplicationFormIntegrationEnum.Litres) {
      return 'Логин и пароль для входа в Литрес отправлены вам на почту';
    }
    if (dynamicApplicationStore.integrationType === ApplicationFormIntegrationEnum.MoreTv) {
      return 'Промокод отправлен вам на почту';
    }
    return '';
  }, [dynamicApplicationStore.error, dynamicApplicationStore.integrationType]);

  return (
    <Div>
      {Chip && <Chip />}
      {Notify && <Notify
        content={notifyText}
        error={Boolean(dynamicApplicationStore.error)}
      />}
      <BaseModal isVisible={showForm} onClose={handleCloseForm}>
        <Feedback formId={appFormId || null} />
      </BaseModal>
      <Head>
        <Title>
          <HtmlRenderer html={header} />
        </Title>
      </Head>

      <Body>
        <Description>
          <HtmlRenderer html={preview} />
        </Description>

        <Grid>
          {textColumns.map(el => (
            <Column key={el.header}>
              <Subtitle>{el.header}</Subtitle>
              <Subdescription>
                <HtmlRenderer html={el.text} />
              </Subdescription>
            </Column>
          ))}
        </Grid>
      </Body>

      <Footer>
        {subscribeFromPage && (
          <ArrowButton white gradient onClick={buttonAction === 'link' ? subscribe : handleShowForm}>
            {buttonLink.text}
          </ArrowButton>
        )}

        <ArrowButton white onClick={onClick}>
          Подробнее
        </ArrowButton>
      </Footer>
    </Div>
  );
};

export default Brief;
