import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import Section from 'components/base-ui/section';
import { useQuery } from '@tanstack/react-query';
import { api } from 'utils/api';
import { useDeviceState } from 'hooks/useDeviceProvider';
import { useHistory } from 'react-router-dom';
import { Container } from 'components/base-ui/container';
import { Button } from 'pages/articles/mobile/styles';
import { useMainPageStore } from 'store';
import { BlockTypes, GuidePreviewBlock } from 'utils/blocks';
import { Styled } from './styles';
import { PreviewCard } from './PreviewCard';

export const GuidePreview: React.VFC = observer(() => {
  const { isLessLaptop } = useDeviceState();
  const history = useHistory();
  const limit = useMemo(() => {
    return isLessLaptop ? 2 : 3;
  }, [isLessLaptop]);

  const { data: articles } = useQuery(
    ['guide-preview', 'main', limit],
    async () => {
      const { data } = await api.guide.pages.guidePageList({ limit, isShowOnMainPage: 'true' });
      return data.results;
    },
    {
      retry: 2,
    },
  );

  const handleOnClick = useCallback(() => {
    history.push('/guide');
  }, []);

  const store = useMainPageStore();

  const header = useMemo(() => {
    const guideBlock = store.content?.find((block) => block.type === BlockTypes.GuidePreview) as GuidePreviewBlock;
    return guideBlock?.value?.header;
  }, [store.content]);

  if (!articles?.length) return null;

  return (
    <Section compact paddingless heading={header}>
      <Styled.Content>
        <Styled.Container>
          {articles?.map((article) => <PreviewCard article={article} key={article.id} title={article.title} />)}
        </Styled.Container>
        <Container width="100%" justify="center">
          <Button onClick={handleOnClick}>Все статьи</Button>
        </Container>
      </Styled.Content>
    </Section>
  );
});
