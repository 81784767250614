import styled from 'styled-components';
import { BREAKPOINTS } from 'utils/constants';

export const Container = styled.div`
  display: flex;
  column-gap: 48px;
  overflow: hidden;
  overflow-x: auto;
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    overflow: visible;
    justify-content: space-between;
  }
`;

export const Items = styled.div`
  display: flex;
  column-gap: 16px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    justify-content: center;
  }
`;
export const Item = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
  width: 432px;
  height: 300px;
  cursor: pointer;
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: 432px;
    height: 300px;
    flex-grow: 0;
    flex-shrink: 1;
  }
`;
export const Image = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const CarouselBtnRight = styled.div`
  margin-top: 158px;
  width: 11px;
  height: 20px;
  cursor: pointer;
`;

export const CarouselBtnLeft = styled(CarouselBtnRight)`
  transform: rotate(180deg);
`;
