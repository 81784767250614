import React from 'react';
import styled from 'styled-components';

import { COLORS } from 'utils/constants';

import { StarsRadio } from 'components/base-ui/stars-radio';
import { Micro } from 'components/base-ui/typo';

const Div = styled.div``;

const Header = styled.div`
  display: flex;
`;

const Heading = styled(Micro)`
  color: ${COLORS.gray};
`;

const Body = styled.div``;

interface RatingProps {
  value?: number;
  onChange?: (value: number) => void;
}

const Rating: React.FC<RatingProps> = React.memo(({ value, onChange }) => {
  return (
    <Div>
      <Header>
        <Heading>Рейтинг</Heading>
      </Header>
      <Body>
        <StarsRadio value={value} onChange={onChange} />
      </Body>
    </Div>
  );
});

export default Rating;
