import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Product, ProductList } from 'services';

import { Span } from 'components/base-ui/typo';
import { ArrowButton } from 'components/base-ui/button';
import HtmlContainer from 'components/base-ui/html-container';
import { HtmlRenderer } from 'utils/htmlParser';

interface GoodChipContentProps {
  product: ProductList | Product;
}

const ChipContainer = styled.div`
  line-height: 30px;
`;

const ChipText = styled(Span)`
  display: block;
`;

const ChipButton = styled(ArrowButton)`
  display: block;
  width: 240px;
  margin: 10px auto;
`;

export const GoodChipContent: React.FC<GoodChipContentProps> = ({ product }) => {
  const history = useHistory();
  return (
    <ChipContainer>
      <HtmlContainer marginless>
        <HtmlRenderer html={product.name} />
      </HtmlContainer>

      <ChipText>добавлен в корзину</ChipText>

      <ChipButton white onClick={() => history.push('/cart')}>
        В корзину
      </ChipButton>
    </ChipContainer>
  );
};
