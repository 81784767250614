import React from 'react';
import { MagazineArticle } from 'components/articles/magazine-article';
import styled from 'styled-components';
import ScrollToTopOnMount from 'components/base-ui/scroll-to-top';
import Breadcrumbs from 'components/base-ui/breadcrumbs';
import { DefaultLayout } from 'layouts';
import { useParams } from 'react-router-dom';

const BreadcrumbsWrapper = styled.div`
  position: relative;
  margin-top: 56px;
  color: #464a53;
`;

function MagazineArticlePage() {
  const { articleId } = useParams<{ articleId: string }>();

  return (
    <DefaultLayout>
      <ScrollToTopOnMount />
      <BreadcrumbsWrapper>
        <Breadcrumbs lineStyle={true} />
      </BreadcrumbsWrapper>
      <div>
        <MagazineArticle articleId={Number(articleId)} />
      </div>
    </DefaultLayout>
  );
}

export default MagazineArticlePage;
