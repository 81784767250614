import React, { ReactElement } from 'react';
import { Div, IconContainer } from 'components/base-ui/stars-radio/styles';
import { IconEmptyRate, IconRate } from 'components/base-ui/icon-rate';

interface StarRenderPropsType {
  count: number;
}

export const StarRender: React.FC<StarRenderPropsType> = ({ count }): ReactElement => {
  const starArray = new Array(5).fill(0);
  return (
    <Div>
      {starArray.map((item, index) =>
        index < count ? (
          <IconContainer>
            <IconRate />
          </IconContainer>
        ) : (
          <IconContainer>
            <IconEmptyRate />
          </IconContainer>
        ),
      )}
    </Div>
  );
};
