import React, { createContext, useContext } from 'react';

import AuthStore from './store';

const store = new AuthStore();

const AuthStateContext = createContext<AuthStore>(store);

export const AuthStateProvider: React.FC = ({ children }) => (
  <AuthStateContext.Provider value={store}>{children}</AuthStateContext.Provider>
);

export const useAuthStore = (): AuthStore => useContext(AuthStateContext);
