import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';
import iconArrowWhite from 'assets/icons/icon_arrow_white.svg';
import { smallStyles } from 'styles/text';
import HtmlContainer from 'components/base-ui/html-container';

export const ImageWrapper = styled.div`
  overflow: hidden;
  position: relative;
  border-radius: 8px;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.35));
`;

export const Image = styled.img`
  top: 50%;
  left: 50%;
  height: 100%;
  position: absolute;
  transform: translate(-50%, -50%);

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: auto;
    height: 100%;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
`;

export const Like = styled.div`
  width: 20px;
  height: 20px;
`;

export const Body = styled.div``;

export const Title = styled.div`
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 6px;
  padding-right: 40px;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  text-decoration: none;
  color: ${COLORS.gray3};
  transition: all 0.2s ease-out;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 5px;
    right: 7px;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-image: url(${iconArrowWhite});
  }
`;

export const Div = styled.div`
  display: grid;
  row-gap: 24px;
  color: ${COLORS.gray3};
  grid-template-rows: 366px min-content;
  cursor: pointer;
  &:hover ${Title}, &:focus ${Title}, &:active ${Title} {
    color: ${COLORS.primary};
  }
`;

export const Description = styled(HtmlContainer)`
  ${smallStyles};
  margin-bottom: 16px;
`;

export const Count = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 20px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: ${COLORS.gray4};
  margin: 0 10px;
`;
