import React from 'react';
import { Modal } from './Modal';
import { Container, Img, ModalContainer } from './styles';

interface CoverPropsType {
  id: number;
  cover: string;
  link: string;
  title: string;
}

export const Cover: React.FC<CoverPropsType> = ({ id, cover, link, title }) => {
  return (
    <Container>
      <Img src={cover} alt="обложка журнала" />
      <ModalContainer>
        <Modal id={id} link={link} title={title} />
      </ModalContainer>
    </Container>
  );
};
