import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import BaseSection from 'components/base-ui/section';
import { api } from 'utils/api';
import { useCategoryStore, useProductStore } from 'store';
import { useParams } from 'react-router-dom';
import { SubstitutedCategoryType } from 'web';
import { useWindowDimensions } from '../../../utils/hooks';

interface IframeLinkRendererProps {
  value: string;
}

type CategoryStore = ReturnType<typeof useCategoryStore> | ReturnType<typeof useProductStore>;

const Iframe = styled.iframe`
  overflow: hidden;
  width: 100%;
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  height: calc(100% + 60px);
  border: none;
`;

const StyledBaseSection = styled(BaseSection)<{ height: string }>`
  height: ${(props) => props.height};
  position: relative;
  min-height: 84%;
  margin-bottom: 110px !important;
`;

const TEMPLATE_FOR_REPLACE = '{token}';

// eslint-disable-next-line sonarjs/cognitive-complexity
const IframeLinkRenderer: React.FC<IframeLinkRendererProps> = observer((props) => {
  const { categoryType, productId } = useParams<{ categoryType: SubstitutedCategoryType; productId: string }>();
  const [source, setSource] = useState<string>(props.value);

  const store = useMemo((): CategoryStore => {
    if (categoryType !== 'catalog' || productId) {
      return useProductStore();
    }
    return useCategoryStore();
  }, [categoryType]);

  const displayRes = useWindowDimensions();

  const iFrameHeight = useMemo(() => {
    const iFrameSizeArray = store.iframeSize;
    if (iFrameSizeArray && iFrameSizeArray.length > 0) {
      for (let i = 0; i < iFrameSizeArray.length; i++) {
        if (displayRes.width < iFrameSizeArray[i].iframePageMinWidth) {
          return {
            height: iFrameSizeArray[i].iframeSize,
            sizeUnit: iFrameSizeArray[i].sizeUnit,
          };
        }
      }
      return null;
    }
  }, [displayRes, store.iframeSize]);

  useEffect(() => {
    const haveToken = props.value.includes(TEMPLATE_FOR_REPLACE);
    if (haveToken) {
      api.oauth.oauthTokenAuthCreate().then((res) => {
        setSource(props.value.replace(TEMPLATE_FOR_REPLACE, res.data.key));
      });
    }
  }, [props.value]);

  useEffect(() => {
    console.log(iFrameHeight);
  });

  return (
    <StyledBaseSection
      noFirstOfTypeOffset
      height={iFrameHeight ? `${iFrameHeight.height}${iFrameHeight.sizeUnit}` : '84%'}
    >
      <Iframe src={source} width="100%" height="100%" />
    </StyledBaseSection>
  );
});

export default IframeLinkRenderer;
