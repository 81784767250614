import React from 'react';
import { Container } from 'components/base-ui/container';
import { IconSvg } from 'assets/icons';
import { Styled } from './styles';

export const UniqueSellingPoint: React.VFC<{ title: string }> = (props) => {
  return (
    <Container gap="8px">
      <Styled.IconWrapper>
        <IconSvg.Tag />
      </Styled.IconWrapper>
      <Styled.Title>{props.title}</Styled.Title>
    </Container>
  );
};
