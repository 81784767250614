import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { useNewsStore, useProfileStore } from 'store';

import { COLORS, BREAKPOINTS } from 'utils/constants';

import IconButton from 'components/base-ui/icon-button';
import { IconViews } from 'components/base-ui/icon-views';
import { formatDate } from 'utils/date';
import Icon from 'components/base-ui/icon';
import { useHistory } from 'react-router-dom';
import { debounce } from 'lodash';
import { IconLikeWithTooltip } from '../../../base-ui/icon-like-with-tooltip';
import { IconFavorite } from '../../../base-ui/icon-favorite';

const Div = styled.div`
  padding: 16px 0;
  margin: 0 16px 16px 16px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  border-bottom: 1px solid ${COLORS.gray5};

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin: 0 88px 16px 88px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    margin: 0 32px 16px 32px;
  }
`;

const Wrapper = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: ${COLORS.gray3};
`;

const DateWrapper = styled.div`
  position: relative;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: ${COLORS.gray4};
  margin-right: 10px;
`;

const IconViewsCount = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 5px;
`;

const ActionIcon = styled(IconViewsCount)`
  cursor: pointer;
  margin-right: 10px;
`;

const Count = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 20px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: ${COLORS.gray4};
  margin-right: 10px;
`;

interface Props {
  onShowLikeModal?: (id: number) => void;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
const Actions: React.FC<Props> = observer((props) => {
  const newsStore = useNewsStore();
  const profileStore = useProfileStore();
  const history = useHistory<{ pathname: string; scroll: boolean }>();

  const commentsDivElement = document.getElementById('newsCommentsDivId');

  React.useEffect(() => {
    if (history.location.state?.scroll) {
      debounce(() => {
        commentsDivElement?.scrollIntoView({ block: 'start' });
      }, 300)();
    }
  }, [history]);

  const handleLikeCurrentNews = useCallback(() => {
    if (newsStore.currentNews?.isLikedByUser) {
      newsStore.handleLikeCurrentNewsDelete(profileStore.profile?.id!);
    } else {
      newsStore.handleLikeCurrentNewsPost(profileStore.profile);
    }
  }, [newsStore.currentNews?.isLikedByUser, newsStore.currentNews]);

  const handleFovariteNews = useCallback(() => {
    if (newsStore.currentNews?.isInUserFavorites) {
      newsStore.handleFavoriteCurrentNewsDelete();
    } else {
      newsStore.handleFavoriteCurrentNewsPost();
    }
  }, [newsStore.currentNews?.isInUserFavorites, newsStore.currentNews]);

  const onPrint = () => window.print();

  const scrollToRef = React.useCallback(() => {
    commentsDivElement?.scrollIntoView({ block: 'start' });
  }, []);

  const handleShowModal = useCallback(() => {
    if (newsStore.currentNews?.id && props.onShowLikeModal) {
      props.onShowLikeModal(newsStore.currentNews.id);
    }
  }, [newsStore.currentNews?.id, props.onShowLikeModal]);

  const tooltipOffset = React.useMemo(() => {
    if (newsStore.currentNews?.likesCount && newsStore.currentNews?.likesCount > 6) {
      return newsStore.currentNews?.commentsCount && newsStore.currentNews?.commentsCount > 0
        ? { left: '-140px', leftMobile: '-155px', arrowLeft: '175px', arrowLeftMobile: '190px' }
        : {
            left: '-140px',
            leftMobile: '-180px',
            arrowLeft: '175px',
            arrowLeftMobile: '220px',
          };
    }

    if (newsStore.currentNews?.likesCount && newsStore.currentNews?.likesCount > 4) {
      return newsStore.currentNews?.commentsCount && newsStore.currentNews?.commentsCount > 0
        ? { left: '-140px', leftMobile: '-120px', arrowLeft: '175px', arrowLeftMobile: '150px' }
        : {
            left: '-140px',
            leftMobile: '-150px',
            arrowLeft: '175px',
            arrowLeftMobile: '180px',
          };
    }

    return {
      left: '-120px',
      leftMobile: '-120px',
      arrowLeft: '150px',
      arrowLeftMobile: '150px',
    };
  }, [newsStore.currentNews?.likesCount, newsStore.currentNews?.commentsCount]);

  return (
    <Div>
      <Wrapper>
        <DateWrapper>
          {newsStore.currentNews?.publishedAt ? formatDate(newsStore.currentNews?.publishedAt) : ''}
        </DateWrapper>
        <IconViewsCount>
          <IconViews width="24px" height="24px" color={COLORS.gray4} />
        </IconViewsCount>
        <Count>{newsStore.currentNews?.viewsCount ?? 0}</Count>
      </Wrapper>

      <Wrapper>
        <IconLikeWithTooltip
          isLiked={!!newsStore.currentNews?.isLikedByUser}
          onClick={handleLikeCurrentNews}
          likedUsers={newsStore.currentNews?.likeAuthors || []}
          likesCount={newsStore.currentNews?.likesCount || 0}
          newsId={newsStore.currentNews?.id!}
          onShowModal={handleShowModal}
          leftMobile={tooltipOffset.leftMobile}
          arrowLeftMobile={tooltipOffset.arrowLeftMobile}
          left={tooltipOffset.left}
          arrowLeft={tooltipOffset.arrowLeft}
        />

        <ActionIcon onClick={handleFovariteNews}>
          <IconFavorite active={!!newsStore.currentNews?.isInUserFavorites} />
        </ActionIcon>

        {newsStore.currentNews?.commentsCount! > 0 && (
          <>
            <ActionIcon onClick={scrollToRef}>
              <Icon name="iconChatBubbleOutline" />
            </ActionIcon>
            <Count>{newsStore.currentNews?.commentsCount}</Count>
          </>
        )}
        <IconButton name="iconPrint" onClick={onPrint} />
      </Wrapper>
    </Div>
  );
});

export default Actions;
