import React from 'react';
import { observer } from 'mobx-react-lite';
import { IconSvg } from 'assets/icons';
import { Styled } from './styles';

export const SwipeButton: React.VFC<{
  vector: 'right' | 'left';
  onClick: () => void;
}> = observer((props) => {
  return (
    <Styled.Button onClick={props.onClick} reverse={props.vector === 'left'} vector={props.vector}>
      <IconSvg.ArrowRight />
    </Styled.Button>
  );
});
