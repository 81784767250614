import React, { useMemo } from 'react';

import { Container } from 'components/base-ui/container';
import { AwardParticipants, AwardParticipantsTypeEnum } from 'services';
import { observer } from 'mobx-react-lite';
import { Styled } from './styles';
import { AloneInfo, Info } from './Info';
import { Gallery, PhotoResponsive } from '../shared';

export const WinnerCard: React.VFC<{ winner: AwardParticipants | null }> = observer((props) => {
  const isAlone = useMemo(() => props.winner?.type === AwardParticipantsTypeEnum.Alone, [props.winner]);

  const isGallery = useMemo(() => {
    if (props.winner && props.winner.items.length > 4) return false;
    return props.winner?.items.some((item) => !!item.image);
  }, [props.winner]);

  if (!props.winner) return null;

  if (isAlone) return <WinnerCardAlone winner={props.winner} />;

  return (
    <Container direction="column" gap="24px">
      <Styled.Title>Победитель</Styled.Title>
      <Styled.Card>
        {isGallery && <Gallery type="winnerCard" participants={props.winner.items} />}
        {!isGallery && <PhotoResponsive withDots={false} variant="winnerCard" src={props.winner.image!} />}
        <Info
          title={props.winner.projectName}
          description={props.winner.description}
          participants={props.winner?.items}
          team={props.winner.name}
        />
      </Styled.Card>
    </Container>
  );
});

const WinnerCardAlone: React.VFC<{ winner?: AwardParticipants | null }> = (props) => {
  const company = useMemo(() => props.winner?.items[0].company?.name, [props.winner]);
  if (!props.winner) return null;
  return (
    <Container direction="column" gap="24px">
      <Styled.Title>Победитель</Styled.Title>
      <Styled.Card>
        {props.winner.image && <PhotoResponsive withDots={true} variant="winnerCard" src={props.winner.image} />}
        <AloneInfo
          title={props.winner.projectName}
          description={props.winner.description}
          name={props.winner.name}
          company={company}
        />
      </Styled.Card>
    </Container>
  );
};
