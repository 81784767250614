import React, { useCallback, useState } from 'react';
import { MediaBlockType } from 'store/projects/types';
import Icon from 'components/base-ui/icon';
import { VideoBox } from 'components/base-ui/video-box';
import { Lightbox } from 'components/base-ui/lightbox';
import { Button, ButtonWrapper, Container, Content, Header, Media, PlayIcon, Text } from './styles';

interface MediaBlockProps {
  data: MediaBlockType;
}

export const MediaBlock: React.FC<MediaBlockProps> = ({ data }) => {
  const [isLightBoxOpen, setIsLightBoxOpen] = useState(false);

  const handleToggleLightBox = useCallback(() => {
    setIsLightBoxOpen((prevState) => !prevState);
  }, []);

  return (
    <Container>
      <Media bg={data.media === 'hostingVideo' && typeof data.video !== 'string' ? data.video?.preview : data.image}>
        {(data.media === 'video' || data.media === 'hostingVideo') && (
          <>
            <ButtonWrapper />
            <Button onClick={handleToggleLightBox}>
              <PlayIcon>
                <Icon name="iconPlay" />
              </PlayIcon>
            </Button>
          </>
        )}
      </Media>
      <Content>
        <Header>{data.header}</Header>
        <Text>{data.text}</Text>
      </Content>
      {data.media === 'hostingVideo' && typeof data.video !== 'string' && (
        <VideoBox
          isLightBoxOpen={isLightBoxOpen}
          videos={[data.video]}
          video={data.video}
          onClose={handleToggleLightBox}
        />
      )}
      {data.media === 'video' && typeof data.video === 'string' && (
        <Lightbox
          showLightBox={isLightBoxOpen}
          toggler={isLightBoxOpen}
          sources={[data.video]}
          handleClose={handleToggleLightBox}
        />
      )}
    </Container>
  );
};
