import styled from 'styled-components';
import { BREAKPOINTS } from 'utils/constants';

export const Container = styled.div`
  display: flex;
  column-gap: 12px;
  overflow: hidden;
  overflow-x: auto;
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    overflow: visible;
    justify-content: space-between;
  }
`;
