import React from 'react';
import styled from 'styled-components';

import { NewsPage } from 'services';

import { BREAKPOINTS } from 'utils/constants';

import { Container, Grid } from 'components/base-ui/cards';

import { Block, BlockTypes } from 'utils/blocks';
import { IApplicationFormBlock, NewsItemProps, ParagraphValue } from 'web';
import { blocksToMap } from 'utils/helpers';
import { useDeviceType } from 'utils/hooks';
import Item from './item';
import ItemWithImage from './item-with-image';

const StyledContainer = styled(Container)`
  margin-bottom: 144px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin-bottom: 168px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    margin-bottom: 114px;
  }
`;

const StyledGrid = styled(Grid)`
  row-gap: 32px;
  column-gap: 40px;
  padding: 0 32px 16px 32px;
  justify-content: space-around;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    row-gap: 24px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    row-gap: 16px;
  }
`;

interface CardsProps {
  items: NewsPage[];
  handleShowModal?: (newsId: number) => void;
}

const NewsCards: React.FC<CardsProps> = ({ items, handleShowModal }) => {
  const device = useDeviceType();
  const getItemData = (news: NewsPage): NewsItemProps => {
    const blocksMap = blocksToMap(news.content as unknown as Block[]);

    const res: NewsItemProps = { id: Number(news.id) };
    res.date = news.publishedAt ?? '';
    res.image = blocksMap[BlockTypes.PreviewImage] ? String(blocksMap[BlockTypes.PreviewImage].value) : undefined;
    res.paragraph = blocksMap[BlockTypes.Preview] ? (blocksMap[BlockTypes.Preview].value as ParagraphValue) : undefined;
    res.isLiked = !!news.isLikedByUser;
    res.likesCount = news.likesCount;
    res.viewsCount = news.viewsCount;
    res.commentsCount = news.commentsCount;
    res.isInUserFavorites = !!news.isInUserFavorites;
    res.likeAuthors = news.likeAuthors;
    res.applicationForm = blocksMap[BlockTypes.ApplicationFormBlock]
      ? (blocksMap[BlockTypes.ApplicationFormBlock].value as IApplicationFormBlock)
      : undefined;

    return res;
  };

  return (
    <StyledContainer>
      <StyledGrid mode="auto-columns" width={device === ('mobile' || 'tablet') ? '256px' : '272px'}>
        {items.map((el) => {
          const item = getItemData(el);
          return item.image ? (
            <ItemWithImage key={item.id} data={item} handleShowModal={handleShowModal} />
          ) : (
            <Item key={item.id} data={item} />
          );
        })}
      </StyledGrid>
    </StyledContainer>
  );
};

export default NewsCards;
