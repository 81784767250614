import React from 'react';
import styled, { css } from 'styled-components';

import { BREAKPOINTS } from '../../../utils/constants';

import Item from './item';

interface DivProps {
  scrollable?: boolean;
  paddingRight?: boolean;
}

const scrollableStyles = css`
  overflow-x: auto;
`;

const noMinWidthStyles = css`
  grid-auto-columns: 1fr;
`;

const minWidthStyles = css`
  grid-auto-columns: minmax(144px, 1fr);
`;

const Div = styled.div<DivProps>`
  display: grid;
  position: relative;
  row-gap: 16px;
  grid-auto-flow: row;
  grid-auto-rows: 1fr;

  ${(props) => props.scrollable && scrollableStyles}

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    column-gap: 42px;
    grid-auto-flow: column;
    grid-auto-columns: ${minWidthStyles};
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    column-gap: 80px;
  }
`;

interface StepsProps extends DivProps {
  items: string[];
}

const Steps: React.FC<StepsProps> = ({ items, scrollable, paddingRight }) => {
  return (
    <Div scrollable={scrollable}>
      {items.map((el, idx) => (
        <Item key={el} idx={idx} text={el} paddingRight={paddingRight && idx === items.length - 1} />
      ))}
    </Div>
  );
};

export default Steps;
