import React, { useState, useEffect, useCallback } from 'react';
import styled, { css } from 'styled-components';

import IconButton from '../icon-button';

const Div = styled.div`
  flex-shrink: 0;
  font-size: 0;
  white-space: nowrap;
`;

interface StarButtonProps {
  filled?: boolean;
}

const filledStyles = css`
  & > img {
    filter: invert(30%) sepia(12%) saturate(793%) hue-rotate(9deg) brightness(100%) contrast(88%);
  }
`;

const StarButton = styled(IconButton)<StarButtonProps>`
  opacity: 0.8;
  ${(props) => props.filled && filledStyles};
`;
const ButtonContainer = styled.div`
  display: inline-block;
  width: 24px;
  height: 24px;
`;

interface RatingProps {
  value?: number;
  className?: string;
  onChange?: (value: number) => void;
}

const Rating: React.FC<RatingProps> = ({ value = 0, className, onChange }) => {
  const [rating, setRating] = useState(value);

  useEffect(() => setRating(() => value), [value]);

  const isFilled = useCallback((val: number) => Math.round(val) <= rating, [rating]);

  const onClick = useCallback(
    (val: number) => {
      return onChange === undefined ? setRating(() => val) : onChange(val);
    },
    [onChange, setRating],
  );

  return (
    <Div className={className}>
      <ButtonContainer>
        <StarButton name="iconRate" filled={isFilled(1)} onClick={() => onClick(1)} />
      </ButtonContainer>
      <ButtonContainer>
        <StarButton name="iconRate" filled={isFilled(2)} onClick={() => onClick(2)} />
      </ButtonContainer>
      <ButtonContainer>
        <StarButton name="iconRate" filled={isFilled(3)} onClick={() => onClick(3)} />
      </ButtonContainer>
      <ButtonContainer>
        <StarButton name="iconRate" filled={isFilled(4)} onClick={() => onClick(4)} />
      </ButtonContainer>
      <ButtonContainer>
        <StarButton name="iconRate" filled={isFilled(5)} onClick={() => onClick(5)} />
      </ButtonContainer>
    </Div>
  );
};

export default Rating;
