export interface RatingProps {
  value?: number;
  onChange?: (value: number) => void;
}

export enum RatingfValue {
  zeroStars,
  oneStar,
  twoStars,
  threeStars,
  fourStars,
  fiveStars,
}
