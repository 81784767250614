import React, { useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';

import { SubstitutedCategoryType } from 'web';

import { useProductListStore } from 'store';
import {
  CategoryCardTypeEnum,
  FinanceCategoryCardTypeEnum,
  InsuranceCategoryCardTypeEnum,
  MediaCategoryCardTypeEnum,
  GuideCategoryCardTypeEnum,
} from 'services';

import { Styled } from './styles';
import { Tags } from '../../base-ui/tags';

interface ProductsRenderProps {
  categoryType?: SubstitutedCategoryType;
  cardType?:
    | CategoryCardTypeEnum
    | FinanceCategoryCardTypeEnum
    | InsuranceCategoryCardTypeEnum
    | MediaCategoryCardTypeEnum
    | GuideCategoryCardTypeEnum;
}

export const DirectionsRenderer: React.FC<ProductsRenderProps> = observer(() => {
  const { categoryId } = useParams<{ categoryId: string }>();
  const store = useProductListStore();

  useEffect(() => {
    store.fetchDirections({ category: categoryId });
  }, [categoryId]);

  const handleToggleDirections = useCallback(
    (id: number, active: boolean) => {
      store.toggleDirections(id, active);
    },
    [store.toggleDirections],
  );

  return (
    <Styled.Container>
      <Tags toggleDirections={handleToggleDirections} data={store.directions} />
    </Styled.Container>
  );
});
