import styled from 'styled-components';
import { BREAKPOINTS } from 'utils/constants';

export const StItem = styled.div<{ reversed?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: ${(props) => (props.reversed ? 'wrap-reverse' : 'wrap')};
  gap: 24px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    flex-direction: row;
  }

  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    gap: 40px;
  }

  @media screen and (min-width: ${BREAKPOINTS.wideDesktop}) {
  }
`;
