import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';

export const Wrapper = styled.div`
  touch-action: manipulation;
  padding: 70px 16px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 50px 32px;
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding: 50px 125px 150px 125px;
  }
`;

export const Header = styled.div`
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  color: ${COLORS.gray3};
  padding: 20px 0;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-size: 44px;
    line-height: 48px;
    color: ${COLORS.secondary};
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding: 50px 0;
  }
`;

export const GalleryWrapper = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  width: 100%;
  overflow-x: auto;
  gap: 16px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
  }
`;

export const ItemWrapper = styled.div`
    display: inline-block;
    width: 288px;
    height: 200px;
    flex-shrink: 0;
    cursor: pointer;
    @media screen and (min-width: ${BREAKPOINTS.tablet}) {
        overflow: hidden;
        width: 344px;
        height: 241px;
    }
    @media screen and (min-width: ${BREAKPOINTS.laptop}) {
        width: 320px;
        height: 224px;
    }
}
`;

export const Item = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    transition: 1s;
    :hover {
      transform: scale(1.2);
    }
  }
`;

export const ButtonsWrapper = styled.div`
  display: none;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    display: flex;
    justify-content: center;
    gap: 50px;
    margin: 70px auto 0 auto;
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    margin-top: 90px;
  }
`;

export const Button = styled('button')`
  width: 160px;
  height: 48px;
  font-size: 14px;
  line-height: 48px;
  text-transform: uppercase;
  border: 1px solid ${COLORS.secondary};
  color: ${COLORS.secondary};
  background-color: ${COLORS.white};
  text-align: center;
  cursor: pointer;
`;

export const LightBoxWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
