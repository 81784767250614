import { action, makeObservable, observable } from 'mobx';
import AbstractStore from '../AbstractStore';
import {
  Application,
  ApplicationApiApplicationListRequest,
  BankingApplication,
  BankingApplicationsApiBankingApplicationsListRequest,
  MediaApplication,
  MediaApplicationsApiMediaApplicationsListRequest,
} from '../../services';
import { api } from '../../utils/api';

export default class ApplicationStore extends AbstractStore {
  applications: Application[] = [];

  mediaApplication: MediaApplication[] = [];

  bankingApplication: BankingApplication[] = [];

  constructor() {
    super();
    makeObservable(this, {
      ...this.annotations,
      applications: observable,
      mediaApplication: observable,
      bankingApplication: observable,
      setApplications: action,
      fetchApplications: action,
      createApplication: action,
      setBankingApplications: action,
      fetchBankingApplications: action,
      setMediaApplications: action,
      fetchMediaApplications: action,
      createMediaApplication: action,
    });
  }

  fetchApplications = (params: ApplicationApiApplicationListRequest) => {
    this.fetchData(() =>
      api.applications.applicationList(params).then((res) => this.setApplications(res.data.results)),
    );
  };

  createApplication = (application: Application) => {
    this.fetchData(() => api.applications.applicationCreate({ data: application }));
  };

  setApplications(application: Application[]) {
    this.applications = application;
  }

  fetchBankingApplications = (params: BankingApplicationsApiBankingApplicationsListRequest) => {
    this.fetchData(() =>
      api.bankingApplications
        .bankingApplicationsList(params)
        .then((res) => this.setBankingApplications(res.data.results)),
    );
  };

  setBankingApplications(application: BankingApplication[]) {
    this.bankingApplication = application;
  }

  fetchMediaApplications = (params: MediaApplicationsApiMediaApplicationsListRequest) => {
    this.fetchData(() =>
      api.mediaApplications.mediaApplicationsList(params).then((res) => this.setMediaApplications(res.data.results)),
    );
  };

  setMediaApplications(applications: MediaApplication[]) {
    this.mediaApplication = applications;
  }

  createMediaApplication = (application: MediaApplication) => {
    this.fetchData(() => api.mediaApplications.mediaApplicationsCreate({ data: application }));
  };
}
