import React from 'react';
import { AboutPartnersPartner } from 'web';
import { useDeviceType } from 'utils/hooks';
import { VideoBox } from 'components/base-ui/video-box';
import { StItem } from './styled';
import { ItemInfo } from './components/ItemInfo';
import { Media } from './components/media';

interface Props {
  partner: AboutPartnersPartner;
}

export const PartnersItem: React.FC<Props> = (props) => {
  const device = useDeviceType();
  const [showVideo, setShowVideo] = React.useState(false);

  const toggleVideo = React.useCallback(() => {
    setShowVideo(!showVideo);
  }, [showVideo]);

  return (
    <>
      <StItem reversed={props.partner.mediaAlign !== 'left'}>
        {props.partner.mediaAlign === 'left' || device === 'mobile' ? (
          <Media onPlay={toggleVideo} partner={props.partner} />
        ) : (
          <ItemInfo header={props.partner.header} text={props.partner.text} />
        )}
        {props.partner.mediaAlign === 'left' || device === 'mobile' ? (
          <ItemInfo header={props.partner.header} text={props.partner.text} />
        ) : (
          <Media partner={props.partner} onPlay={toggleVideo} />
        )}
      </StItem>
      <VideoBox
        video={props.partner.video}
        videos={[props.partner.video]}
        onClose={toggleVideo}
        isLightBoxOpen={showVideo}
      />
    </>
  );
};
