import React, { createContext, useContext } from 'react';

import PromocodeStore from './store';

const store = new PromocodeStore();

const PromocodeStateContext = createContext<PromocodeStore>(store);

export const PromocodeStateProvider: React.FC = ({ children }) => (
  <PromocodeStateContext.Provider value={store}>{children}</PromocodeStateContext.Provider>
);

export const usePromocodeStore = (): PromocodeStore => useContext(PromocodeStateContext);
