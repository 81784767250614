import React, { createContext, useContext } from 'react';
import { VideoGalleryState } from './model';

const store = new VideoGalleryState();

const VideoGalleryStateContext = createContext<VideoGalleryState>(store);

export const VideoGalleryStateProvider: React.FC = ({ children }) => (
  <VideoGalleryStateContext.Provider value={store}>{children}</VideoGalleryStateContext.Provider>
);

export const VideoGalleryStore = (): VideoGalleryState => useContext(VideoGalleryStateContext);
