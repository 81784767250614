import React, { useCallback, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ru } from 'date-fns/locale';
import { isWithinInterval, eachDayOfInterval, subDays } from 'date-fns';

import { now } from 'lodash';
import { CalendarWrapper, DatePickerWrapper, InputLabel } from './styles';

registerLocale('ru', ru);

interface CalendarProps {
  disabled?: boolean;
  value?: Date | null;
  label: string;
  id: string;
  onChange: (value: Date | null) => void;
  placeholderText?: string;
  isBusTour?: boolean;
  includeTourDates?: Date[];
  tourRanges?: { id?: number; start: Date; end: Date }[];
  unlimitedChoiseDates?: boolean;
}

const Calendar: React.FC<CalendarProps> = (props) => {
  const calendarContainer = useCallback(({ children }) => {
    return <CalendarWrapper>{children}</CalendarWrapper>;
  }, []);

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [highLightDays, setHighLightDays] = useState<Date[]>([]);
  const [isOpen, setIsOpen] = useState(false);

  const onChange = useCallback(
    (date: [Date, Date]) => {
      if (props.tourRanges) {
        const intervals = props.tourRanges.filter((el) => (isWithinInterval(date[0], el) ? el : null));
        setStartDate(intervals[0].start);
        setEndDate(intervals[0].end);
        props.onChange(intervals[0].start);
        setIsOpen(false);
      }
    },
    [props.tourRanges, props.onChange],
  );

  const onFocus = useCallback(
    (date: Date) => {
      if (props.tourRanges && date) {
        const intervals = props.tourRanges.filter((el) => (isWithinInterval(date, el) ? el : null));
        setHighLightDays(eachDayOfInterval(intervals[0]));
      }
    },
    [props.tourRanges],
  );

  const today = new Date(now());

  const includeDates = React.useMemo(() => {
    if (props.unlimitedChoiseDates) {
      return undefined;
    }
    if (props.isBusTour && props.includeTourDates) {
      return [...props.includeTourDates];
    }
    return eachDayOfInterval({ start: new Date(today), end: subDays(new Date(today), -365) });
  }, [props.includeTourDates, props.isBusTour, props.unlimitedChoiseDates]);

  return (
    <DatePickerWrapper>
      {props.label.length && <InputLabel htmlFor={props.id}>{props.label}</InputLabel>}
      <DatePicker
        id={props.id}
        onChange={props.isBusTour ? onChange : props.onChange}
        shouldCloseOnSelect={props.isBusTour}
        locale="ru"
        calendarContainer={calendarContainer}
        selected={props.isBusTour ? startDate : props.value}
        disabled={props.disabled}
        todayButton="Сегодня"
        highlightDates={props.isBusTour ? highLightDays : undefined}
        dateFormat="dd.MM.yyyy"
        includeDates={includeDates}
        startDate={props.isBusTour ? startDate : undefined}
        onDayMouseEnter={props.isBusTour ? onFocus : undefined}
        endDate={props.isBusTour ? endDate : undefined}
        selectsRange={props.isBusTour || undefined}
        placeholderText={props.placeholderText}
        autoComplete="off"
        onInputClick={props.isBusTour ? () => setIsOpen(true) : undefined}
        onClickOutside={props.isBusTour ? () => setIsOpen(false) : undefined}
        open={props.isBusTour ? isOpen : undefined}
      />
    </DatePickerWrapper>
  );
};

export default Calendar;
