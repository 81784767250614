import React from 'react';
import styled, { css } from 'styled-components';

import { COLORS } from '../../../utils/constants';

const Div = styled.div`
  cursor: pointer;
  position: relative;
  min-height: 20px;
  display: inline-flex;
  align-items: center;

  &:not(:last-of-type) {
    margin-right: 10px;
  }
`;

const Input = styled.input`
  width: 20px;
  height: 20px;
  margin: 0 8px 0 0;
`;

interface CheckmarkProps {
  checked?: boolean;
  disabled?: boolean;
}

const checkedStyles = css`
  border-color: ${COLORS.secondary};
  background-color: ${COLORS.secondary};

  &:after {
    content: '';
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    position: absolute;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    background-color: ${COLORS.white};
  }
`;

const Checkmark = styled.span<CheckmarkProps>`
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  position: absolute;
  border-radius: 50%;
  transition: all 0.2s ease-out;
  border: 1px solid ${COLORS.gray5};
  background-color: ${COLORS.white};

  ${(props) => props.checked && checkedStyles}
`;

const Label = styled.label`
  color: ${COLORS.gray4};
`;

interface RadioProps extends CheckmarkProps {
  id: string;
  name: string;
  value?: string;
  label?: string;
  onClick?: (value?: string) => void;
  disabled?: boolean;
}

const Radio: React.FC<RadioProps> = ({ id, name, value, label, checked, onClick, disabled = false }) => {
  return (
    <Div>
      <Input type="radio" id={id} value={value} name={name} checked={checked} />
      <Checkmark checked={checked} onClick={() => onClick && !disabled && onClick(value)} />

      {label && <Label htmlFor={id}>{label}</Label>}
    </Div>
  );
};

export default Radio;
