import React, { createContext, useContext } from 'react';

import ProjectsStore from './store';

const store = new ProjectsStore();

const ProjectsStateContext = createContext<ProjectsStore>(store);

export const ProjectsStateProvider: React.FC = ({ children }) => (
  <ProjectsStateContext.Provider value={store}>{children}</ProjectsStateContext.Provider>
);

export const useProjectsStore = (): ProjectsStore => useContext(ProjectsStateContext);
