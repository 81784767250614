import { makeObservable, observable, action, computed } from 'mobx';

import { api } from '../../utils/api';

import { PortalDirectionItem, PortalDirectionItemsApiPortalDirectionItemsListRequest } from '../../services';
import AbstractStore from '../AbstractStore';

export default class PortalDirectionsStore extends AbstractStore {
  directions: PortalDirectionItem[] = [];

  constructor() {
    super();
    makeObservable(this, {
      ...this.annotations,
      directions: observable,
      fetchDirections: action,
      setDirections: action,
      forMainPage: computed,
      forMainMenu: computed,
      forProjectPage: computed,
    });
  }

  fetchDirections(props: PortalDirectionItemsApiPortalDirectionItemsListRequest = {}, options?: unknown) {
    if (this.directions.length === 0) {
      this.fetchData(() =>
        api.portalDirections
          .portalDirectionItemsList(props, options)
          .then((res) => this.setDirections(res.data.results)),
      );
    }
  }

  get forMainMenu() {
    return this.directions
      .filter((d) => d.forTopMenu)
      .sort((a, b) => (a.sortOrder && b.sortOrder ? a.sortOrder - b.sortOrder : 1));
  }

  get forMainPage() {
    return this.directions
      .filter((d) => d.forMainPage)
      .sort((a, b) => (a.orderMainPage && b.orderMainPage ? a.orderMainPage - b.orderMainPage : 1));
  }

  get forProjectPage() {
    return this.directions
      .filter((d) => d.forProjectPage)
      .sort((a, b) => (a.orderProjectPage && b.orderProjectPage ? a.orderProjectPage - b.orderProjectPage : 1));
  }

  setDirections(value: PortalDirectionItem[]): void {
    this.directions = value;
  }
}
