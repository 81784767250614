import React from 'react';
import { renderRoutes } from 'react-router-config';

import { AppProps } from 'web';

import { createInstance, MatomoProvider } from '@datapunt/matomo-tracker-react';
import { useLocation } from 'react-router-dom';
import { GlobalFonts, GlobalStyle } from './styles';
import { UnderConstructionModal } from './components/under-construction-modal';
import { api } from './utils/api';

const App: React.FC<AppProps> = ({ route }) => {
  const [email, setEmail] = React.useState<string | undefined>(undefined);
  const location = useLocation();

  React.useEffect(() => {
    if (location.pathname === '/ldap-auth') {
      setEmail('unauthorized user');
    }
  }, [location.pathname]);

  React.useEffect(() => {
    if (location.pathname === '/ldap-auth') return;

    api.profile
      .profileGetCurrentProfile()
      .then((res) => setEmail(res.data.user?.email))
      .catch((err) => {
        console.error(err);
        setEmail('unauthorized user');
        if (location.pathname !== '/login') {
          localStorage.setItem('redirect_link', location.pathname);
        }
      });
  }, [location.pathname]);

  const getInstance = React.useCallback(() => {
    return createInstance({
      urlBase: `//${window.location.hostname}/matomo/`,
      siteId: 1,
      userId: email,

      heartBeat: {
        active: true,
        seconds: 10,
      },
      linkTracking: false,
      configurations: {
        disableCookies: true,
        setSecureCookie: true,
        setRequestMethod: 'POST',
      },
    });
  }, [email]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {email && (
        <MatomoProvider value={getInstance()}>
          <GlobalFonts />
          <GlobalStyle />
          <UnderConstructionModal />
          {route && renderRoutes(route.routes)}
        </MatomoProvider>
      )}
    </>
  );
};

export default App;
