import React, { createContext, useContext } from 'react';

import MediaCategoryStore from './store';

const store = new MediaCategoryStore();

const MediaCategoryStateContext = createContext<MediaCategoryStore>(store);

export const MediaCategoryStateProvider: React.FC = ({ children }) => (
  <MediaCategoryStateContext.Provider value={store}>{children}</MediaCategoryStateContext.Provider>
);

export const useMediaCategoryStore = (): MediaCategoryStore => useContext(MediaCategoryStateContext);
