import React from 'react';
import styled, { css } from 'styled-components';

import { IconName } from 'web';

import { COLORS } from 'utils/constants';

import Icon from '../icon';

const roundStyles = css`
  padding: 12px;
  border-radius: 50%;
  background: ${COLORS.white};
  border: 1px solid ${COLORS.gray5};
`;

const gradientBlueStyles = css`
  border: none;
  background: ${COLORS.linearGradient};
`;

const gradientRedStyles = css`
  border: none;
  background: linear-gradient(122.74deg, ${COLORS.red3} 4.19%, ${COLORS.red2} 90.22%);
`;

const shadowValue = 'drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.35))';

const shadowStyles = css`
  filter: ${shadowValue};

  &:hover,
  &:focus,
  &:active {
    filter: ${shadowValue} brightness(0.9);
  }
`;

const disabledStyles = css`
  cursor: not-allowed;
  background: ${COLORS.gray6} !important;
`;

const likeDisabledStyle = css`
  background: ${COLORS.gray6} !important;
`;

const smallStyles = css`
  width: 32px !important;
  height: 32px !important;
  padding: 8px !important;
`;

interface ButtonProps {
  round?: boolean;
  shadow?: boolean;
  gradient?: boolean;
  disabled?: boolean;
  disabledStyle?: boolean;
  small?: boolean;
  color?: 'blue' | 'red';
}

const Button = styled.button<ButtonProps>`
  display: flex;
  padding: 0;
  border: none;
  cursor: pointer;
  background: transparent;
  transition: all 0.2s ease-out;

  ${(props) => props.round && roundStyles};
  ${(props) => props.shadow && shadowStyles};
  ${(props) => props.disabled && disabledStyles};
  ${(props) => props.disabledStyle && likeDisabledStyle};
  ${(props) => props.small && smallStyles}

  ${(props) => props.gradient && props.color === 'blue' && gradientBlueStyles}
  ${(props) => props.gradient && props.color === 'red' && gradientRedStyles}
`;

interface IconButtonProps extends ButtonProps {
  name: IconName;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
}

const IconButton: React.FC<IconButtonProps> = ({
  name,
  disabled,
  round,
  shadow,
  gradient,
  small,
  className,
  onClick,
  disabledStyle,
  color = 'blue',
  onMouseOut,
  onMouseOver,
}) => {
  return (
    <Button
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      type="button"
      disabled={disabled}
      round={round}
      shadow={shadow}
      gradient={gradient}
      small={small}
      className={className}
      onClick={onClick}
      disabledStyle={disabledStyle}
      color={color}
    >
      <Icon name={name} />
    </Button>
  );
};

export default IconButton;
