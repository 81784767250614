// @ts-nocheck

import React, { useCallback, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Controller, useForm } from 'react-hook-form';

import { useAuthStore, usePageContentStore } from 'store';
import { REGEXP } from 'utils/constants';

import { authService } from 'utils/authentication';
import { InputFieldset } from 'components/base-ui/InputFieldset/InputFieldset';
import { Signup } from 'services';
import { Form, FormField, FormLabel, StyledButton } from 'components/login/form';
import { StepText } from 'components/login/steps/text';
import RVerify from 'rverify';
import 'rverify/dist/RVerify.min.css';
import avatar from 'assets/img/assol_avatar.png';

interface StepProps {
  next: () => void;
}

enum ParagraphAreas {
  loginInfo = 'loginInfo',
}

enum ParagraphElementsSlugs {
  loginInfo = 'login-form',
}

const pageSlug = 'login';

const StepOne: React.FC<StepProps> = observer(({ next }) => {
  const store = useAuthStore();
  const pageContentStore = usePageContentStore<ParagraphElementsSlugs, ParagraphAreas>();

  RVerify.configure({
    tolerance: 10,
    duration: 500,
    mask: 0.5,
    title: 'Подтверждение входа',
    text: 'Выровняйте изображение',
    album: store.captchaImages || [avatar],
  });

  useEffect(() => {
    store.getCaptcha();
  }, []);

  const onSubmit = useCallback(
    (data: Inputs) => {
      RVerify.action(async (res: ResCaptchaType) => {
        if (res === 1) {
          const submData: Signup = {
            email: data.email,
          };
          await store.signUp(submData);
          if (store.error === null) next();
        }
      });
    },
    [store],
  );

  useEffect(() => {
    pageContentStore.fetchContent(
      { slug: pageSlug },
      {
        [ParagraphElementsSlugs.loginInfo]: ParagraphAreas.loginInfo,
      },
    );
  }, []);

  const { control, handleSubmit, errors } = useForm<Inputs>({
    defaultValues: { email: '', captchaValue: '' },
  });

  const emailError = useMemo(() => {
    if (!store.error) return;

    const errorPayload = store.getErrorResponsePayload();

    if (errorPayload?.data?.detail === 'Domain is not allowed') {
      return 'Вход возможен только через корпоративную почту';
    }

    if (errorPayload?.data?.email) {
      return errorPayload.data.email[0];
    }

    if (errorPayload?.data?.ldap) {
      return errorPayload.data.ldap[0];
    }

    return errors.email?.message;
  }, [errors.email, store.error]);

  const onInput = useCallback(() => {
    if (store.error !== null) store.setError(null);
  }, [store.error]);

  const Content = useMemo(() => {
    const info = pageContentStore.content[ParagraphAreas.loginInfo];
    return info?.filter((item) => item.type === 'text').map((item) => <StepText key={item.key}>{item.value}</StepText>);
  }, [pageContentStore.content[ParagraphAreas.loginInfo]]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {authService.isUser() && <StepText>Время сессии истекло. Необходимо повторно пройти авторизацию</StepText>}
      <FormLabel>Корпоративная почта</FormLabel>
      <FormField>
        <Controller
          name="email"
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Обязательное поле',
            },
            pattern: {
              value: REGEXP.email,
              message: 'Некорректный формат',
            },
          }}
          render={({ value, onChange }) => (
            <InputFieldset id="email" label="" error={emailError} value={value} onChange={onChange} onInput={onInput} />
          )}
        />
      </FormField>
      <FormField>
        <StyledButton type="submit">Получить код доступа</StyledButton>
      </FormField>
      <StepText>{Content}</StepText>
    </Form>
  );
});

export default StepOne;
