import React, { useEffect, useMemo, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { Nullable } from 'web';
import { ResolutionItemType } from 'store/projects/types';
import { observer } from 'mobx-react-lite';
import { VideoGalleryStore } from '../model';
import { ContentType } from './types';
import { Styled } from './styles';
import { MainContent, QualityContent, SpeedContent } from './content';
import { useDeviceType } from '../../../../utils/hooks';

interface DropdownProps {
  visible: boolean;
  onVisibleChange: (visible: boolean) => void;
  resolutions?: ResolutionItemType[];
}

export const SettingsDropdown: React.FC<DropdownProps> = observer((props) => {
  const popperElement = useRef<Nullable<HTMLDivElement>>(null);
  const referenceElement = useRef<Nullable<HTMLDivElement>>(null);
  const device = useDeviceType();
  const offsetTop = device === 'mobile' ? 5 : 32;

  const { setCurrentResolution, currentResolution, setSpeed, speed, setReady } = VideoGalleryStore();

  const { styles, attributes } = usePopper(referenceElement.current, popperElement.current, {
    placement: 'top-end',
    modifiers: [{ name: 'offset', options: { offset: [-4, offsetTop] } }],
  });
  const [open, setOpen] = useState(props.visible);
  const [content, setContent] = useState<ContentType>('main');

  const handleClick = (e: MouseEvent) => {
    if (popperElement?.current?.contains(e.target as Node) || referenceElement?.current?.contains(e.target as Node)) {
      return;
    }
    setOpen(false);
    props.onVisibleChange(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const contentComponent = useMemo(() => {
    switch (content) {
      case 'quality':
        return props.resolutions ? (
          <QualityContent
            setContent={setContent}
            setReady={setReady}
            setResolution={setCurrentResolution}
            resolutions={props.resolutions}
            currentResolutionId={currentResolution?.id}
          />
        ) : null;
      case 'main':
        return <MainContent setContent={setContent} currentResolution={currentResolution} speed={speed} />;
      case 'speed':
        return <SpeedContent speed={speed} setContent={setContent} setSpeed={setSpeed} />;
      default:
        break;
    }
  }, [content, speed, currentResolution, props.resolutions]);

  useEffect(() => {
    setOpen(props.visible);
    setContent('main');
  }, [props.visible]);

  return (
    <>
      <div ref={referenceElement}>{props.children}</div>

      <Styled.SettingsPopper
        className={open ? 'visibly' : ''}
        ref={popperElement}
        style={styles.popper}
        {...attributes.popper}
      >
        {contentComponent}
      </Styled.SettingsPopper>
    </>
  );
});
