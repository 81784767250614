import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid ${COLORS.gray6};
  flex-wrap: wrap-reverse;
  padding-top: 24px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding-top: 42px;
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    height: 676px;
    padding-top: unset;
  }
`;
export const Media = styled.div<{ bg: string }>`
  width: 100%;
  height: 180px;
  background: url(${(props) => props.bg}) no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  isolation: isolate;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    height: 433px;
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: 870px;
    height: 490px;
    margin-right: 43px;
  }
`;
export const ButtonWrapper = styled.div`
  position: absolute;
  z-index: 1;
  width: 128px;
  height: 128px;
  border-radius: 64px;
  background: ${COLORS.primary};
  mix-blend-mode: multiply;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Button = styled.div`
  position: absolute;
  z-index: 5;
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 32px;
  background: ${COLORS.linearGradient};
  cursor: pointer;
`;
export const PlayIcon = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: flex-end;
`;
export const Content = styled.div`
  width: 100%;
  padding: 0 16px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 0 24px;
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding: 0;
    height: 490px;
    width: 413px;
  }
`;
export const Header = styled.div`
  color: ${COLORS.gray2};
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  margin-bottom: 24px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-size: 44px;
    line-height: 48px;
  }
`;
export const Text = styled.div`
  color: ${COLORS.gray3};
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 24px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-size: 20px;
    line-height: 26px;
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    margin-bottom: unset;
  }
`;
