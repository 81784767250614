import React, { useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
  Tab as UnstyledTab,
  Tabs as UnstyledTabs,
  TabList as UnstyledTabList,
  TabPanel as UnstyledTabPanel,
} from 'react-tabs';

import { ProfileTab } from 'web';

import { COLORS, BREAKPOINTS } from '../../../utils/constants';

import HomeTab from './home';
import OrdersTab from './orders';
import FavoriteTab from './favorite';
import { ApplicationStateProvider } from '../../../store/application';

interface TabsRoleProps {
  tabsRole?: 'Tab' | 'Tabs' | 'TabPanel' | 'TabList';
}

const Tabs = styled(UnstyledTabs)<TabsRoleProps>`
  background: ${COLORS.white};
`;

const TabList = styled(UnstyledTabList)<TabsRoleProps>`
  margin: 0;
  display: flex;
  overflow: auto;
  flex-wrap: nowrap;
  border-top: 1px solid ${COLORS.gray6};
  border-bottom: 1px solid ${COLORS.gray6};
  padding: 12px 16px 0 16px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 36px 56px 13px 56px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding: 38px 136px 13px 168px;
  }
`;

const Tab = styled(UnstyledTab).attrs<TabsRoleProps>({
  selectedClassName: 'selected',
  disabledClassName: 'disabled',
})`
  cursor: pointer;
  margin-bottom: 0;
  font-weight: 400;
  list-style: none;
  text-align: center;
  white-space: nowrap;
  color: ${COLORS.secondary};

  &:first-child {
    border-left: none;
  }

  &.selected {
    color: ${COLORS.primary};
  }

  &.disabled {
    cursor: not-allowed;
    color: ${COLORS.gray4};
  }

  &:not(:last-of-type) {
    margin-right: 40px;
  }

  &:nth-child(-n + 2) {
    min-width: 50%;
    margin: 0;
    @media screen and (min-width: ${BREAKPOINTS.tablet}) {
      min-width: 0;
      margin-right: 40px;
    }
  }

  &:nth-child(1) {
    text-align: left;
  }

  &:nth-child(2) {
    text-align: right;
    margin-right: 40px;
  }

  &:nth-child(n + 3) {
    padding-right: 16px;
    @media screen and (min-width: ${BREAKPOINTS.tablet}) {
      padding: 0;
    }
  }
`;

const TabPanel = styled(UnstyledTabPanel).attrs<TabsRoleProps>({ selectedClassName: 'selected' })`
  display: none;

  &.selected {
    display: block;
  }

  padding: 36px 16px 0 16px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 28px 56px 0 56px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding: 28px 136px 0 168px;
  }
`;

// FIXME: typescript typechecker can't infer types
// code from https://github.com/reactjs/react-tabs/issues/148
(Tab as typeof Tab & TabsRoleProps).tabsRole = 'Tab';
(Tabs as typeof Tabs & TabsRoleProps).tabsRole = 'Tabs';
(TabPanel as typeof TabPanel & TabsRoleProps).tabsRole = 'TabPanel';
(TabList as typeof TabList & TabsRoleProps).tabsRole = 'TabList';

interface ProfileTabsProps {
  selected: ProfileTab;
}

const TAB_LIST_ITEMS: Readonly<Record<ProfileTab, string>> = {
  home: 'Основная информация',
  orders: 'Заказы',
  favorite: 'Избранное',
  // notifications: 'Уведомления',
};

const ProfileTabs: React.FC<ProfileTabsProps> = ({ selected }) => {
  const history = useHistory();

  const selectedIndex = useMemo(() => {
    const idx = Object.keys(TAB_LIST_ITEMS).indexOf(selected);
    return idx === -1 ? 0 : idx;
  }, [selected]);

  const onSelect = useCallback(
    (idx: number, last: number) => {
      if (idx === last) return;

      const el = Object.keys(TAB_LIST_ITEMS)[idx];

      return el === 'home' ? history.push('/profile') : history.push(`/profile/${el}`);
    },
    [history],
  );

  return (
    <Tabs selectedIndex={selectedIndex} onSelect={onSelect}>
      <TabList>
        {Object.entries(TAB_LIST_ITEMS).map(([key, value]) => (
          <Tab key={key}>{value}</Tab>
        ))}
      </TabList>

      <TabPanel>
        <HomeTab />
      </TabPanel>

      <TabPanel>
        <ApplicationStateProvider>
          <OrdersTab />
        </ApplicationStateProvider>
      </TabPanel>

      <TabPanel>
        <FavoriteTab />
      </TabPanel>

      {/* <TabPanel> */}
      {/*  <NotificationsTab /> */}
      {/* </TabPanel> */}
    </Tabs>
  );
};

export default ProfileTabs;
