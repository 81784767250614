import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import Section from '../../../base-ui/section';

import Alert from '../alert';
import OrdersItem from './item';
import { useApplicationStore } from '../../../../store/application';
import { useProfileStore } from '../../../../store/profile';
import { ServiceOrder } from './item/ServiceOrder';
import { Application } from '../../../../services';

const Div = styled.div``;

const OrdersList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const OrdersTab: React.FC = observer(() => {
  const applicationStore = useApplicationStore();

  const { profile } = useProfileStore();

  useEffect(() => {
    if (profile) {
      applicationStore.fetchApplications({ profile: String(profile?.id) });
      applicationStore.fetchBankingApplications({ profile: String(profile?.id) });
      applicationStore.fetchMediaApplications({ profile: String(profile?.id) });
    }
  }, [profile]);

  return (
    <Div>
      <Section paddingless marginless noMinHeight noFirstOfTypeOffset>
        <Alert />
      </Section>

      <Section paddingless marginless>
        <OrdersList>
          {applicationStore.applications.map((a) => (
            <OrdersItem application={a} key={a.id} />
          ))}
          {applicationStore.bankingApplication.map((a) => (
            <OrdersItem application={a as Application} key={a.id} />
          ))}
          {applicationStore.mediaApplication.map((a) => (
            <OrdersItem application={a as Application} key={a.id} />
          ))}
        </OrdersList>
      </Section>
    </Div>
  );
});

export default OrdersTab;
