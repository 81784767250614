import React from 'react';
import { COLORS } from 'utils/constants';
import { ProfileLike } from 'services';
import { useDeviceType } from 'utils/hooks';
import { useLongPress } from 'use-long-press';
import { IconEmptyLike, IconLike } from '../icon-like';
import { StLike, StCount } from './styled';
import { TooltipContent } from './tooltip-content';

interface IconLikeProps {
  isLiked: boolean;
  onClick: (ev?: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => void;
  likedUsers: ProfileLike[];
  likesCount: number;
  newsId: number;
  onShowModal: (ev?: React.MouseEvent<HTMLDivElement>) => void;
  color?: string;
  iconWidth?: string;
  iconHeight?: string;
  position?: 'left' | 'right' | 'center';
  left?: string;
  leftMobile?: string;
  arrowLeft?: string;
  arrowLeftMobile?: string;
}

const TOOLTIP_DELAY = 1200;
const TOOLTIP_MOBILE_DELAY = 2000;

export const IconLikeWithTooltip: React.FC<IconLikeProps> = (props) => {
  const [showTooltip, setShowTooltip] = React.useState(false);
  const deviceType = useDeviceType();

  const tooltipDelay = React.useMemo(() => {
    if (deviceType === 'mobile' || deviceType === 'tablet') return TOOLTIP_MOBILE_DELAY;
    return TOOLTIP_DELAY;
  }, [deviceType]);

  const hideTooltip = () => setShowTooltip(false);

  const bind = useLongPress(() => {
    if (props.likesCount < 1) return;
    setShowTooltip(true);
  });

  const handleShowTooltip = React.useCallback(
    (ev: React.MouseEvent<HTMLDivElement>) => {
      ev.stopPropagation();

      if (props.likesCount < 1) return;
      setShowTooltip(true);
    },

    [tooltipDelay, props.likesCount],
  );

  const handleLike = (ev: React.MouseEvent<HTMLDivElement>) => {
    ev.stopPropagation();
    props.onClick();
  };

  return (
    <>
      <StLike
        {...bind()}
        onClick={handleLike}
        iconHeight={props.iconHeight || '24px'}
        iconWidth={props.iconWidth || '24px'}
        onMouseEnter={handleShowTooltip}
        onMouseLeave={hideTooltip}
      >
        {props.isLiked ? (
          <IconLike
            width={props.iconWidth || '24px'}
            height={props.iconHeight || '24px'}
            color={props.color || COLORS.gray4}
          />
        ) : (
          <IconEmptyLike
            width={props.iconWidth || '24px'}
            height={props.iconHeight || '24px'}
            color={props.color || COLORS.gray4}
          />
        )}
      </StLike>
      <StCount
        showTooltip={showTooltip}
        onMouseEnter={handleShowTooltip}
        onMouseLeave={hideTooltip}
        onClick={handleShowTooltip}
        color={props.color}
        left={props.left}
        leftMobile={props.leftMobile}
        arrowLeft={props.arrowLeft}
        arrowLeftMobile={props.arrowLeftMobile}
      >
        {props.likesCount}
        <span>
          <TooltipContent likedUsers={props.likedUsers} likesCount={props.likesCount} onShowModal={props.onShowModal} />
        </span>
      </StCount>
    </>
  );
};
