import styled, { css } from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';
import HtmlContainer from 'components/base-ui/html-container';

export const StProductCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 16px 16px 16px;
  padding: 24px 16px;
  color: ${COLORS.gray3};
  background-color: ${COLORS.gray8};
  border-radius: 2px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin: 0 56px 16px 56px;
    padding: 24px 24px 32px 24px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    margin: 0 136px 24px 136px;
    padding: 24px 64px 32px 32px;
  }
`;

export const StContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    justify-content: space-between;
    flex-wrap: nowrap;
    border-bottom: 1px solid ${COLORS.gray5};
    padding-bottom: 24px;
    margin-bottom: 24px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    gap: 84px;
    border: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }
`;

export const StInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 848px;
`;

export const StTitle = styled(HtmlContainer)`
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  margin-bottom: 16px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-size: 24px;
    line-height: 32px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
  }
`;

export const StDescription = styled(HtmlContainer)`
  width: 100%;
  border-bottom: 1px solid ${COLORS.gray5};
  padding-bottom: 16px;
  margin-bottom: 32px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    border: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    border-bottom: 1px solid ${COLORS.gray5};
    padding-bottom: 16px;
    margin-bottom: 24px;
  }
`;

export const ImageWrapperState = css`
  display: none;
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    display: block;
  }
`;

export const ImageWrapper = styled.div<{ active?: boolean }>`
  position: relative;
  width:calc(100% + 28px);
  padding-top: 80%;
  margin-bottom: 32px;
  overflow: hidden;
  opacity: ${props => (props.active ? '0.4' : '1')};
  clip-path: polygon(15% 0, 100% 0, 100% 100%, 0 100%);

   @media screen and (min-width: ${BREAKPOINTS.tablet}) {
      padding-top: 0;
      margin-bottom: 0;
      width: 299px;
      height: 248px;
   }
`;

export const Image = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
