import styled, { css } from 'styled-components';
import { COLORS } from 'utils/constants';

export const baseStyle = css`
  margin: 0 auto;
  max-width: 1070px;
  padding: 0 5px;
`;
export const TlBlock = styled.div`
  ${baseStyle};
`;
export const TlBlockLabel = styled.p`
  ${baseStyle};
  text-decoration: none;
  font:
    16px 'PFDinTextPro',
    sans-serif;
  color: rgb(109, 109, 109);
  line-height: 1.2;
`;
export const TlBlockSelect = styled.div<{ visible?: boolean }>`
  ${baseStyle};
  color: rgb(109, 109, 109) !important;
  background-color: rgb(255, 255, 255);
  margin: 8px 0 10px;
  font:
    16px 'PFDinTextPro',
    sans-serif;
  line-height: 1.2;
  max-width: 300px;
  width: 100%;
  display: ${(props) => (props.visible ? undefined : 'none')};
`;
export const TlHotelSelect = styled.select`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  width: 100%;
  -webkit-transition:
    box-shadow 0.3s,
    opacity 0.3s;
  transition:
    box-shadow 0.3s,
    opacity 0.3s;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 15px;
  font:
    16px 'PFDinTextPro',
    sans-serif;
  color: rgb(109, 109, 109);
  line-height: 1.2;
  background: rgb(255, 255, 255)
    url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAJCAYAAAAGuM1UAAAAvklEQVQYlX3MMSuFARgF4Od+JItbBtOtOynjTb
    2DhE3xKyQmI5tfIKPFalIyGcxEtlMWmax+gMUgg+VTn7rXWc9zTi/JLt6q6tE/SbKKpV6ST3xjpapeJ+ARHjDT4ABzuEkyPwYPcIs+DpuqusAZFnG
    VZKqD+y0e4KSqzpu2O8I9NnHa4mlcY4RLHEOv87aAYIgdbGAPd9iqqq8/g3a0jCfMtt0L1qrq49c03UFVPWO/xe/Y7uKJSbKeZDiu+wHtZD+PFO/o
    SwAAAABJRU5ErkJggg==')
    no-repeat 270px 20px;
  height: auto;
  &:hover,
  &:active {
    -webkit-appearance: none;
    outline: none;
  }
  &::-ms-expand {
    display: none;
  }
`;

export const Header = styled.h2`
  margin: 60px 0 33px;
  color: ${COLORS.gray9};
`;
