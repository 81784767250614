import React, { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { WidgetProps } from 'components/applicationForm/types';
import Calendar from 'components/base-ui/calendar';
import { ErrorFormMessage } from 'components/applicationForm';
import { formatInputDate } from 'utils/date';

export const DateWidget: React.FC<WidgetProps<string>> = (props) => {
  const { values } = props;

  const form = useFormContext();

  const rules = useMemo(() => {
    return {
      required: {
        value: values.required,
        message: 'Обязательное поле',
      },
    };
  }, [props.values.required]);

  return (
    <div>
      <Controller
        name={values.slug}
        control={form.control}
        rules={rules}
        render={({ value, onChange }) => (
          <Calendar
            id={values.slug}
            value={value ? new Date(String(value)) : null}
            label={values.description}
            onChange={(val) => {
              onChange(formatInputDate(val?.toString()));
            }}
            placeholderText={values.placeholder}
          />
        )}
      />
      <ErrorFormMessage>
        <ErrorMessage errors={form.errors} name={values.slug} />
      </ErrorFormMessage>
    </div>
  );
};
