import React, { useMemo } from 'react';

import { ReactComponent as IconArrowRight } from 'assets/icons/icon_arrow_right.svg';
import { ReactComponent as IconArrowLeft } from 'assets/icons/icon_arrow_left.svg';
import { ArrowButton, WrapperLeft, WrapperRight } from './styles';

export function Arrow(props: { vector: 'left' | 'right'; onClick?: () => void }) {
  if (props.vector === 'left') {
    return (
      <WrapperLeft {...props}>
        <ArrowButton direction={props.vector}>
          <IconArrowLeft />
        </ArrowButton>
      </WrapperLeft>
    );
  }
  return (
    <WrapperRight {...props}>
      <ArrowButton direction={props.vector}>
        <IconArrowRight />
      </ArrowButton>
    </WrapperRight>
  );
}
