import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { SelectOption } from 'web';

import { useProfileStore } from '../../../../../store';

import { api } from '../../../../../utils/api';
import { BREAKPOINTS } from '../../../../../utils/constants';

import Select from '../../../../base-ui/select';

import HobbiesItem from './item';

const Div = styled.div``;

const Container = styled.div`
  display: grid;
  column-gap: 32px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    grid-template-columns: repeat(2, 256px);
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    grid-template-columns: repeat(2, 288px);
  }
`;

const StyledSelect = styled(Select)`
  margin-bottom: 8px;
`;

const HobbiesList = styled.ul`
  margin: 0;
  padding: 0;
  max-width: 425px;
  list-style: none;
`;

const UserHobbies: React.FC = observer(() => {
  const [hobby, setHobby] = useState<SelectOption[]>([]);
  const [activity, setActivity] = useState<SelectOption[]>([]);

  const profileStore = useProfileStore();

  useEffect(() => {
    api.hobbies
      .hobbyList()
      .then((res) => setHobby(res.data.results.map((h) => ({ label: String(h.name), value: String(h.id) }))));

    api.activities
      .activityList()
      .then((res) => setActivity(res.data.results.map((h) => ({ label: String(h.name), value: String(h.id) }))));
  }, [setHobby, setActivity]);

  const selectHobby = useCallback(
    (newHobby: SelectOption | null) => {
      if (newHobby) {
        profileStore.addHobby({ name: newHobby.label, id: +newHobby.value });
      }
    },
    [profileStore],
  );

  const selectActivity = useCallback(
    (newActivity: SelectOption | null) => {
      if (newActivity) {
        profileStore.setActivity({ name: newActivity.label, id: +newActivity.value });
      }
    },
    [profileStore],
  );

  return (
    <Div>
      <Container>
        <StyledSelect label="Хобби" options={hobby} onChange={selectHobby} />
        <StyledSelect label="Профдеятельность" options={activity} onChange={selectActivity} />
      </Container>

      <HobbiesList>
        {profileStore.hobbies.map((h) => (
          <HobbiesItem key={h.id} name={String(h.name)} id={Number(h.id)} />
        ))}
      </HobbiesList>
    </Div>
  );
});

export default UserHobbies;
