import React from 'react';
import { Container } from 'components/base-ui/container';
import { HtmlRenderer } from 'utils/htmlParser';
import AnimateHeight from 'react-animate-height';
import { useCollapse } from 'components/assol-winners/WinnersAndNominees/hooks';
import { Styled } from './styles';

export const Description: React.FC<{
  description: string;
  collapsable?: boolean;
}> = (props) => {
  const { expanded, toggleExpanded } = useCollapse(Boolean(props.collapsable));

  return (
    <Container direction="column" gap="12px">
      <Styled.Header collapsable={props.collapsable} expanded={expanded} onClick={toggleExpanded}>
        <Styled.Subtitle>Описание проекта:</Styled.Subtitle>
        {props.collapsable && <Styled.IconDown className={expanded ? 'expanded' : 'collapsed'} />}
      </Styled.Header>

      <AnimateHeight duration={500} height={expanded ? 'auto' : 0}>
        <Styled.Text>
          <HtmlRenderer html={props.description} />
        </Styled.Text>
      </AnimateHeight>
    </Container>
  );
};
