import styled, { css } from 'styled-components';
import { NavigationItemProps } from './types';
import { COLORS } from '../../../utils/constants';

export const Navigation = styled.div`
  display: flex;
  justify-content: center;
  column-gap: 16px;
`;
export const NavigationItem = styled.div<Pick<NavigationItemProps, 'active'>>`
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid ${COLORS.red};
  background: linear-gradient(122.74deg, #b93b3b 4.19%, ${COLORS.red} 90.22%);
  &:hover {
    background: transparent;
  }
  ${(props) =>
    props.active &&
    css`
      background: transparent;
      pointer-events: none;
    `}
`;
