import React, { useEffect, useMemo } from 'react';
import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';

import { isEmpty } from 'lodash';
import Section from '../../base-ui/section';
import Companies from '../../main/companies';

import { useCompanyStore, usePageContentStore } from '../../../store';

enum CompaniesPageAreas {
  title = 'title',
}

enum CompaniesPageElementsSlugs {
  title = 'companies-list',
}

const pageSlug = 'index';

const GroupCompaniesRenderer: React.FC = observer(() => {
  const store = useCompanyStore();
  const pageContentStore = usePageContentStore<CompaniesPageElementsSlugs, CompaniesPageAreas>();

  useEffect(() => autorun(() => store.fetchCompanies()), [store]);

  useEffect(
    () =>
      pageContentStore.fetchContent(
        { slug: pageSlug },
        {
          [CompaniesPageElementsSlugs.title]: CompaniesPageAreas.title,
        },
      ),
    [],
  );

  const Content = useMemo(() => {
    const info = pageContentStore.content[CompaniesPageAreas.title];

    if (!info || isEmpty(info)) return;
    //@ts-ignore
    return info.filter((item) => item.type === 'text').map((item) => <div key={item.key}>{item.value}</div>);
  }, [pageContentStore.content[CompaniesPageAreas.title]]);

  return (
    <Section heading={Content}>
      <Companies items={store.forMainPage} />
    </Section>
  );
});

export default GroupCompaniesRenderer;
