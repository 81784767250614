import React from 'react';
import logoBlack from 'assets/img/brand/black.svg';
import logoWhite from 'assets/img/brand/white.svg';
import logoMain from 'assets/img/brand/main.svg';
import logoSmall from 'assets/img/brand/small.svg';
import logoFullWhite from 'assets/img/brand/full_white.svg';

interface BrandProps {
  size?: 'small' | 'default';
  color?: 'black' | 'white' | 'main' | 'fullWhite';
}

const logoScheme: Record<'small' | 'default', Record<'black' | 'white' | 'main' | 'fullWhite', string>> = {
  small: {
    black: logoSmall,
    white: logoSmall,
    main: logoSmall,
    fullWhite: logoSmall,
  },
  default: {
    black: logoBlack,
    white: logoWhite,
    main: logoMain,
    fullWhite: logoFullWhite,
  },
};

const Brand: React.FC<BrandProps> = ({ size = 'default', color = 'black' }) => {
  return <img src={logoScheme[size][color] ?? logoMain} alt="service logo" />;
};

export default Brand;
