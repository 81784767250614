import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';

export const ModalContainer = styled.div`
  display: none;
  z-index: 999;
`;
export const Container = styled.div`
  width: 320px;
  height: 382px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS.white};
  :hover {
    img {
      opacity: 0.2;
    }
    ${ModalContainer} {
      display: block;
    }
  }
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 385px;
    height: 459px;
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: 790px;
    height: 952px;
  }
`;
export const Img = styled.img`
  width: 320px;
  height: 382px;
  position: absolute;
  object-fit: cover;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 385px;
    height: 459px;
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: 790px;
    height: 952px;
  }
`;
