import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { Div, Item, Label, RadioButton, RadioButtonLabel, Wrapper } from 'components/base-ui/stars-radio/styles';
import { StarRender } from 'components/base-ui/stars-radio/icon';
import { RatingfValue, RatingProps } from 'components/base-ui/stars-radio/types';

export const StarsRadio: React.FC<RatingProps> = ({ value = 0, onChange }): ReactElement => {
  const [rating, setRating] = useState(value);

  useEffect(() => setRating(() => value), [value]);

  const onClick = useCallback(
    (val: number) => {
      return onChange === undefined ? setRating(() => val) : onChange(val);
    },
    [onChange, setRating],
  );

  return (
    <Wrapper>
      <Item>
        <RadioButton
          name="radio"
          type="radio"
          value={RatingfValue.fourStars}
          checked={rating === RatingfValue.fourStars}
          onChange={() => onClick(RatingfValue.fourStars)}
        />
        <RadioButtonLabel />
        <Label>
          <Div>От</Div> <StarRender count={RatingfValue.fourStars} />
        </Label>
      </Item>
      <Item>
        <RadioButton
          name="radio"
          type="radio"
          value={RatingfValue.threeStars}
          checked={rating === RatingfValue.threeStars}
          onChange={() => onClick(RatingfValue.threeStars)}
        />
        <RadioButtonLabel />
        <Label>
          <Div>От</Div> <StarRender count={RatingfValue.threeStars} />
        </Label>
      </Item>
      <Item>
        <RadioButton
          name="radio"
          type="radio"
          value={RatingfValue.twoStars}
          checked={rating === RatingfValue.twoStars}
          onChange={() => onClick(RatingfValue.twoStars)}
        />
        <RadioButtonLabel />
        <Label>
          <Div>От</Div> <StarRender count={RatingfValue.twoStars} />
        </Label>
      </Item>
      <Item>
        <RadioButton
          name="radio"
          type="radio"
          value={RatingfValue.zeroStars}
          checked={rating === RatingfValue.zeroStars}
          onChange={() => onClick(RatingfValue.zeroStars)}
        />
        <RadioButtonLabel />
        <Label>
          <Div>Любой</Div>
        </Label>
      </Item>
    </Wrapper>
  );
};
