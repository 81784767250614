import React, { useState, useCallback, useMemo, useEffect } from 'react';
import styled, { css } from 'styled-components';

import { ViewMode } from 'web';

import { useParams } from 'react-router-dom';
import {
  ProductAttribute,
  ProductsApiProductsListRequest,
  ProductPriceLimit,
  GlobalCategory,
  AlcoholContentsLimit,
} from 'services';

import { COLORS, BREAKPOINTS } from 'utils/constants';

import { Span } from 'components/base-ui/typo';
import IconButton from 'components/base-ui/icon-button';
import { useQueryParams } from 'utils/hooks';
import { useGlobalCategoriesStore } from 'store/global-categories';
import { Dropdown } from 'components/base-ui/dropdown/Dropdown';
import Menu from './menu';
import { OrderingContent, ProductOrdering, ProductOrderingEnum } from './ordering/OrderingContent';

const Div = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 16px 0;
  margin: 0 16px;
  border-top: 1px solid ${COLORS.gray5};
  border-bottom: 1px solid ${COLORS.gray5};

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin: 0 32px;
  }
`;

const MenuWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 16px 0;
  margin: 0 16px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin: 59px 32px;
  }
`;

const group = css`
  display: grid;
  grid-auto-flow: column;
  color: ${COLORS.gray4};
`;

const Left = styled.div`
  ${group};
  cursor: pointer;
  column-gap: 8px;
`;

const Right = styled.div`
  ${group};
  column-gap: 16px;
`;

const Text = styled(Span)`
  display: inline-block;
`;

interface FiltersProps<T extends ProductAttribute> {
  attributes?: T[];
  withList?: boolean;
  priceLimits: ProductPriceLimit;
  alcoholLimits: AlcoholContentsLimit;
  // TODO: make it generic
  defaultValues: Omit<Partial<ProductsApiProductsListRequest>, 'page' | 'limit'>;
  // onClear: () => void;
  onClick: (value: ViewMode) => void;
  onSearch: (value: ProductsApiProductsListRequest) => void;
}

function Filters<T extends ProductAttribute>({
  withList,
  priceLimits,
  alcoholLimits,
  defaultValues,
  attributes = [],
  onClick,
  onSearch,
}: FiltersProps<T>) {
  const [filterActive, setFilterActive] = useState(false);

  const [hasFilter, setHasFilter] = useState<GlobalCategory['hasFilter']>();
  const toggleFilterActive = useCallback(() => {
    setFilterActive((prev) => !prev); // TODO: fix state update after unmount
  }, [setFilterActive]);
  const [orderingDropdownVisible, setOrderingDropdownVisible] = useState<boolean>(false);
  const { ordering } = useQueryParams<{ ordering: string }>();
  const handleOrdering = () => {
    setOrderingDropdownVisible((prevState) => !prevState);
  };
  const onOrderingSelect = (ord: ProductOrderingEnum) => {
    setOrderingDropdownVisible(false);
    onSearch({ ...defaultValues, ordering: ord });
  };

  const { categoryId } = useParams<{ categoryId: string }>();
  const categoryStore = useGlobalCategoriesStore();

  const categoryType = useMemo(() => {
    const [currentCategory] = categoryStore.categories.filter((c) => c.id === +categoryId);
    if (currentCategory) setHasFilter(currentCategory.hasFilter);
    return currentCategory && currentCategory.hasBasket ? 'product' : 'service';
  }, [categoryId, categoryStore]);

  useEffect(() => {
    if (categoryType === 'product') {
      setFilterActive(true);
    }
  }, []);

  const onOrderingDirectionChange = useCallback(() => {
    if (ordering) {
      onSearch({ ...defaultValues, ordering: ordering.startsWith('-') ? ordering.substring(1) : `-${ordering}` });
    }
  }, [ordering]);
  const orderingDirectionIcon = useMemo(
    () => (ordering && ordering.startsWith('-') ? 'iconArrowsRevert' : 'iconArrows'),
    [ordering],
  );

  const currentOrdering = useMemo(() => {
    const clearOrdering = ordering ? ordering.replace('-', '') : ordering;
    return ProductOrdering[clearOrdering as ProductOrderingEnum]
      ? ProductOrdering[clearOrdering as ProductOrderingEnum]
      : 'Сортировка';
  }, [ordering]);

  return (
    <>
      <Div>
        <Dropdown
          visible={orderingDropdownVisible}
          dropdownContent={<OrderingContent type={categoryType} onChange={onOrderingSelect} />}
          onVisibleChange={(visible) => setOrderingDropdownVisible(visible)}
        >
          <Left>
            <Text onClick={handleOrdering}>{currentOrdering}</Text>
            <IconButton name={orderingDirectionIcon} onClick={onOrderingDirectionChange} />
          </Left>
        </Dropdown>

        <Right>
          {withList && (
            <>
              <IconButton name="iconGrid" onClick={() => onClick('card')} />
              <IconButton name="iconList" onClick={() => onClick('list')} />
            </>
          )}
          {hasFilter && <IconButton name="iconFltr" onClick={toggleFilterActive} />}
        </Right>
      </Div>
      <MenuWrapper>
        <Menu<T>
          visible={filterActive}
          priceLimits={priceLimits}
          alcoholLimits={alcoholLimits}
          attributes={attributes}
          defaultValues={defaultValues}
          // onClear={onClear}
          onSearch={onSearch}
          onClose={toggleFilterActive}
        />
      </MenuWrapper>
    </>
  );
}

export default Filters;
