import React from 'react';
import { HtmlRenderer } from 'utils/htmlParser';
import { StInfo, StText, StTitle } from './styled';

interface Props {
  header: string;
  text: string;
}

export const ItemInfo: React.FC<Props> = (props) => {
  return (
    <StInfo>
      <StTitle>{props.header}</StTitle>
      <StText>
        <HtmlRenderer html={props.text} />
      </StText>
    </StInfo>
  );
};
