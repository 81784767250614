import React, { createContext, useContext } from 'react';

import CartStore from './store';

const store = new CartStore();

const CartStateContext = createContext<CartStore>(store);

export const CartStateProvider: React.FC = ({ children }) => (
  <CartStateContext.Provider value={store}>{children}</CartStateContext.Provider>
);

export const useCartStore = (): CartStore => useContext(CartStateContext);
