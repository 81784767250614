import styled from 'styled-components';
import { COLORS } from 'utils/constants';

const Container = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;

  background: ${COLORS.gray6};
  border-radius: 2px;
`;

const Location = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: ${COLORS.gray2};
`;

const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  svg {
    width: 24px;
    height: 24px;
    & * {
      fill: ${COLORS.secondary};
    }
  }
`;

export const Styled = { Location, IconWrapper, Container };
