import React, { useEffect, useState } from 'react';
import { MagazineArticle } from 'services';
import { SmallCard } from 'components/articles/cards/small';
import { observer } from 'mobx-react-lite';
import { useProjectsStore } from 'store';
import { StyledContainer, StyledGrid } from './styles';

interface CardsProps {
  items: MagazineArticle[];
  onShowModal?: (id: number) => void;
}

export const ArticleCards: React.FC<CardsProps> = observer(({ items, onShowModal }) => {
  const store = useProjectsStore();
  const [data, setData] = useState<MagazineArticle[]>([]);

  useEffect(() => {
    store.fetchArticles({}).then(() => setData(store.articles));
  }, []);

  const handleShowModal = React.useCallback(
    (id: number) => {
      if (onShowModal) onShowModal(id);
    },
    [onShowModal],
  );

  return (
    <StyledContainer>
      <StyledGrid mode="auto-columns" width="256px">
        {items.map((el) => {
          const item = data?.filter((storeEl) => storeEl.id === el.id)[0];
          return (
            <SmallCard
              id={item?.id}
              image={item?.image}
              header={item?.header}
              description={item?.description}
              magazine={item?.magazine}
              isLikedByUser={item?.isLikedByUser}
              likesCount={item?.likesCount}
              viewsCount={item?.viewsCount}
              content={item?.content}
              isInUserFavorites={item?.isInUserFavorites}
              onShowModal={handleShowModal}
              likeAuthors={item?.likeAuthors}
            />
          );
        })}
      </StyledGrid>
    </StyledContainer>
  );
});

export default ArticleCards;
