import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { SubstitutedCategoryType } from 'web';

import {
  BankingProduct,
  CategoryCardTypeEnum,
  FinanceCategoryCardTypeEnum,
  GuideCategoryCardTypeEnum,
  InsuranceCategoryCardTypeEnum,
  InsuranceProduct,
  MediaCategoryCardTypeEnum,
  Product,
  ProductsApiProductsListRequest,
} from '../../../services';

import {
  useBankingProductsStore,
  useCategoryStore,
  useInsuranceProductsStore,
  useMediaProductsStore,
  useProductListStore,
} from '../../../store';

import { usePagination, useQueryParams, useWindowDimensions } from '../../../utils/hooks';
import { calculateCardsLimit, stringifyQueryParams } from '../../../utils/functions';

import Section from '../../base-ui/section';
import Leasure from '../../services/shared/leasure';
import ProductListItem from '../../services/shared/product';
import { CARD_GAPS, CARD_WIDTH, PADDING } from './constants';
import { Block, BlockTypes } from '../../../utils/blocks';

type ServicesProduct = Product | BankingProduct | InsuranceProduct;

type ServicesProductsStore =
  | ReturnType<typeof useProductListStore>
  | ReturnType<typeof useBankingProductsStore>
  | ReturnType<typeof useInsuranceProductsStore>
  | ReturnType<typeof useMediaProductsStore>;

interface ProductsRenderProps {
  categoryType?: SubstitutedCategoryType;
  cardType?:
    | CategoryCardTypeEnum
    | FinanceCategoryCardTypeEnum
    | InsuranceCategoryCardTypeEnum
    | MediaCategoryCardTypeEnum
    | GuideCategoryCardTypeEnum;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
const ProductsRender: React.FC<ProductsRenderProps> = observer((props) => {
  const history = useHistory();
  const location = useLocation();
  const windowDimension = useWindowDimensions();

  const pageLimit = useMemo(() => {
    return calculateCardsLimit(windowDimension.width, CARD_WIDTH, CARD_GAPS, PADDING);
  }, [windowDimension.width]);

  const { categoryId, productId } = useParams<{ categoryId: string; productId: string }>();

  const ref = useRef<HTMLDivElement>(null);

  const categoryStore = useCategoryStore();

  const store = useMemo((): ServicesProductsStore => {
    switch (props.categoryType) {
      case 'finance':
        return useBankingProductsStore();

      case 'insurance':
        return useInsuranceProductsStore();

      case 'media':
        return useMediaProductsStore();

      default:
        return useProductListStore();
    }
  }, [props.categoryType]);

  const withFilters = useMemo(() => {
    const blocks = categoryStore?.category?.page.content as unknown as Block[];
    return blocks?.some((block) => block.type === BlockTypes.WithSorting && block.value);
  }, [categoryStore?.category?.page]);

  const isGrid = useMemo(
    () => props.cardType && props.cardType.valueOf() === CategoryCardTypeEnum.Grid.valueOf(),
    [props.cardType],
  );

  const {
    page: initialPage,
    propertiesIdContains,
    ordering,
    ...otherParams
  } = useQueryParams<{
    propertiesIdContains?: number | number[];
  }>();

  const directions = 'directions' in store ? store.directions : undefined;

  const preparedProperties = useMemo(() => {
    if (!propertiesIdContains) return undefined;
    return Array.isArray(propertiesIdContains) ? propertiesIdContains : [propertiesIdContains];
  }, [propertiesIdContains]);

  const { page, onPaginationWithRouter } = usePagination({
    ref,
    initialPage,
    path: location.pathname,
    count: store.count,
    error: store.error,
    limit: pageLimit,
    withRouter: true,
  });

  useEffect(
    () =>
      autorun(() =>
        store.fetchProducts({
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          category: productId ? undefined : categoryId,
          page: isGrid ? page : undefined,
          limit: isGrid ? pageLimit : undefined,
          propertiesIdContains: preparedProperties,
          ordering: String(ordering),
          parent: +productId || undefined,
        }),
      ),
    [store, categoryId, page, isGrid, ordering, preparedProperties, pageLimit, directions],
  );

  useEffect(() => {
    if ('cleanUp' in store) {
      return store.cleanUp;
    }
  }, [categoryId]);

  const onSearch = useCallback(
    (props: ProductsApiProductsListRequest) => {
      const stringifiedQueryParams = stringifyQueryParams(props);
      return stringifiedQueryParams
        ? history.push({ pathname: location.pathname, search: `?page=${1}&${stringifiedQueryParams}` })
        : history.push({ pathname: location.pathname, search: `?page=${1}` });
    },
    [history, location.pathname, page, categoryId],
  );

  return (
    <Section ref={ref}>
      {isGrid ? (
        <Leasure
          withFilters={withFilters}
          count={store.count}
          items={store.products}
          limit={pageLimit}
          categoryId={categoryId}
          currentPage={page}
          defaultValues={{
            ...otherParams,
            propertiesIdContains: preparedProperties,
          }}
          onSearch={onSearch}
          onPagination={onPaginationWithRouter}
        />
      ) : (
        (store.products as ServicesProduct[]).map((el: ServicesProduct) => (
          <ProductListItem key={el.id} data={el} category={{ id: categoryId, type: props.categoryType }} />
        ))
      )}
    </Section>
  );
});

export default ProductsRender;
