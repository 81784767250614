import React from 'react';
import { Container } from 'components/base-ui/container';
import { ReactComponent as IconArrowRight } from 'assets/icons/icon_arrow_grey.svg';
import { MedicineCenterShort } from 'services';
import { useHistory } from 'react-router-dom';
import { HtmlRenderer } from 'utils/htmlParser';
import { Styled } from './styles';

interface CardProps {
  title: string;
  center: MedicineCenterShort;
  id: number;
  withSubtitle?: boolean;
  src?: string;
}

export const Card: React.VFC<CardProps> = (props) => {
  const history = useHistory();

  const handleOnClick = () => {
    history.push(`/market/medicine/${props.center.id}/${props.id}`);
  };

  return (
    <Styled.Wrapper onClick={handleOnClick}>
      <Styled.ImgWrapper>
        <img src={props.src} alt={props.title} />
      </Styled.ImgWrapper>
      <Container gap="16px">
        <Container direction="column">
          <Styled.Title>{props.title}</Styled.Title>
          {props.withSubtitle && (
            <Styled.Subtitle>
              <HtmlRenderer html={props.center.title} />
            </Styled.Subtitle>
          )}
        </Container>
        <Styled.IconWrapper>
          <IconArrowRight />
        </Styled.IconWrapper>
      </Container>
    </Styled.Wrapper>
  );
};
