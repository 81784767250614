import React from 'react';
import styled from 'styled-components';

import { ProfileTab } from 'web';

import BreadcrumbsWrapper from 'components/base-ui/breadcrumbs-wrapper';
import Breadcrumbs from 'components/base-ui/breadcrumbs';

import Banner from './banner';
import Tabs from './tabs';

const Div = styled.div`
  height: 100%;
  position: relative;
`;

const Section = styled.section`
  width: 100%;
`;

interface ProfileProps {
  selected: ProfileTab;
}

const Profile: React.FC<ProfileProps> = ({ selected = 'home' }) => {
  return (
    <Div>
      <BreadcrumbsWrapper absolute>
        <Breadcrumbs white={true} />
      </BreadcrumbsWrapper>

      <Section>
        <Banner />
      </Section>

      <Section>
        <Tabs selected={selected} />
      </Section>
    </Div>
  );
};

export default Profile;
