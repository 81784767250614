import React from 'react';
import AnimateHeight from 'react-animate-height';

import Brand from 'components/base-ui/brand';
import { H6 } from 'components/base-ui/typo';

import { ReactComponent as Divider } from 'assets/img/shapes/line.svg';
import { BrandWrapper, Div, DivChild, DividerWrapper, HeaderWrapper, Container } from 'components/login/styles';

import { useDeviceState } from 'hooks/useDeviceProvider';
import Steps from './steps';

export const LoginComponent: React.VFC<{ active: boolean }> = (props) => {
  const { isLessTablet } = useDeviceState();
  return (
    <Container>
      <AnimateHeight duration={500} height="auto">
        <Div className={props.active && isLessTablet ? 'hidden' : 'visibly'}>
          <DivChild>
            <BrandWrapper className={props.active && isLessTablet ? 'hidden' : 'visibly'}>
              <Brand color="white" />
            </BrandWrapper>
            {!isLessTablet && (
              <DividerWrapper className={props.active && isLessTablet ? 'hidden' : 'visibly'}>
                <Divider />
              </DividerWrapper>
            )}
            <HeaderWrapper>
              <H6 bold={true}>Платформа</H6>
            </HeaderWrapper>
          </DivChild>
          <AnimateHeight duration={500} height={props.active ? 'auto' : 0}>
            <DivChild>
              <Steps />
            </DivChild>
          </AnimateHeight>
        </Div>
      </AnimateHeight>
    </Container>
  );
};
