import React, { useEffect, useState, useCallback, useRef, RefObject } from 'react';
import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { ProductList } from 'services';

import { useProductListStore } from 'store';

import { PAGE_LIMIT } from 'utils/constants';

import Table from 'components/base-ui/table';
import Cards from './cards';
import { useDeviceType } from '../../../../../utils/hooks';
import { LikeModal } from '../../../../base-ui/like-modal';
import { LikeModalContent } from '../../../../base-ui/like-modal-content';

const Container = styled.div``;

const FavoriteProducts: React.FC = observer(() => {
  const store = useProductListStore();

  const ref: RefObject<HTMLDivElement> = useRef(null);

  const [page, setPage] = useState<number>(1);
  const [likeModalId, setLikeModalId] = React.useState<number | null>(null);
  const [showLikeModal, setShowLikeModal] = React.useState(false);
  const device = useDeviceType();

  const likesLimit = React.useMemo(() => {
    if (device === 'mobile') return 70;
    return 40;
  }, [device]);

  const handleShowModal = useCallback((id: number) => {
    setLikeModalId(id);
    setShowLikeModal(true);
  }, []);
  useEffect(
    // @ts-expect-error skip
    () => autorun(() => store.fetchProducts({ page, limit: PAGE_LIMIT, isInUserFavorites: true })),
    [store, page],
  );

  const onPagination = useCallback(
    (idx: number) => {
      // @ts-expect-error skip
      store.fetchProducts({ page: idx, limit: PAGE_LIMIT, isInUserFavorites: true });
      setPage(() => idx);

      if (ref.current) {
        ref.current.scrollIntoView();
      }
    },
    [ref, store, setPage],
  );

  return (
    <Container ref={ref}>
      <Table<ProductList, never>
        count={store.count}
        currentPage={page}
        cards={<Cards items={store.products} onShowModal={handleShowModal} />}
        onPagination={onPagination}
      />
      <LikeModal visible={showLikeModal} onClose={() => setShowLikeModal(false)}>
        <LikeModalContent cardType="product" id={likeModalId} limitPerPage={likesLimit} />
      </LikeModal>
    </Container>
  );
});

export default FavoriteProducts;
