import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { City, Profile, ProfileSexEnum } from 'services';

import { useProfileStore } from 'store/profile';

import { api } from 'utils/api';
import { useDeviceType } from 'utils/hooks';
import { BREAKPOINTS, COLORS } from 'utils/constants';
import { formatInputDate } from 'utils/date';

import Input from 'components/base-ui/input';
import InputGroup from 'components/base-ui/input-group';
import Select from 'components/base-ui/select';
import Radio from 'components/base-ui/radio';
import Calendar from 'components/base-ui/calendar';

const Div = styled.div`
  position: relative;
  padding-top: 40px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding-top: 0;
    grid-template-columns: repeat(2, 256px);
  }
`;

const Grid = styled.div`
  display: grid;
  row-gap: 12px;
  column-gap: 32px;
  grid-template-columns: repeat(1, 100%);

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    grid-template-columns: repeat(2, 256px);
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    grid-template-columns: repeat(3, 288px);
  }
`;

interface InputWrapperProps {
  row: '1' | '2' | '3' | '4';
  column: '1' | '2' | '3';
}

const InputWrapper = styled.div<InputWrapperProps>`
  color: ${COLORS.gray3};

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    grid-row: ${(props) => props.row};
    grid-column: ${(props) => props.column};
  }
`;

const UserInfo: React.FC = observer(() => {
  const deviceType = useDeviceType();

  const isDesktop = useMemo(() => deviceType === 'desktop', [deviceType]);
  const { profile, editProfile, userCity } = useProfileStore();
  const [cityList, setCityList] = useState<City[]>([]);

  useEffect(() => {
    api.cities.cityList().then((res) => setCityList(res.data.results));
  }, []);

  const onChange = useCallback(
    (key: keyof Profile) => {
      return (e?: React.ChangeEvent<HTMLInputElement>) => {
        if (profile) {
          editProfile(key, e?.target.value);
        }
      };
    },
    [profile, editProfile],
  );

  const handleSex = useCallback(
    (value?: string) => {
      if (profile) {
        editProfile('sex', value as ProfileSexEnum);
      }
    },
    [profile, editProfile],
  );

  const handleCity = useCallback(
    (cityId?: string) => {
      if (profile && cityId) {
        editProfile('city', { id: +cityId, name: cityList.find((c) => c.id === +cityId)?.name });
      }
    },
    [profile, cityList, editProfile],
  );

  const handleBirthday = useCallback(
    (birthday: Date | null) => {
      if (birthday) editProfile('birthday', formatInputDate(birthday.toString()));
    },
    [editProfile],
  );

  return (
    <Div>
      <Grid>
        <InputWrapper row="1" column="1">
          <Input
            id="user__second-name"
            label="Фамилия"
            value={profile?.lastName || ''}
            onChange={onChange('lastName')}
          />
        </InputWrapper>

        <InputWrapper row="2" column="1">
          <Input id="user__first-name" label="Имя" value={profile?.firstName || ''} onChange={onChange('firstName')} />
        </InputWrapper>

        <InputWrapper row="3" column="1">
          <Input
            id="user__patronymic"
            label="Отчество"
            value={profile?.patronymic || ''}
            onChange={onChange('patronymic')}
          />
        </InputWrapper>

        <InputWrapper row="1" column="2">
          <Input id="user__phone" label="Телефон" value={profile?.phone || ''} onChange={onChange('phone')} />
        </InputWrapper>

        <InputWrapper row="2" column="2">
          <Select
            label="Город"
            options={cityList.map((c) => {
              return { label: String(c.name), value: String(c.id) };
            })}
            value={userCity ? { value: String(userCity.id), label: String(userCity.name) } : null}
            onChange={(value) => handleCity(value?.value)}
          />
        </InputWrapper>

        <InputWrapper row="3" column="2">
          <Calendar
            id="user__birth-date"
            value={profile?.birthday ? new Date(profile.birthday) : null}
            label="Дата рождения"
            onChange={(value) => {
              handleBirthday(value);
            }}
            unlimitedChoiseDates={true}
          />
        </InputWrapper>

        <InputWrapper row={isDesktop ? '1' : '4'} column={isDesktop ? '3' : '1'}>
          <InputGroup label="Пол">
            <Radio
              id="user__gender--male"
              name="gender"
              value={ProfileSexEnum.Male}
              label="Мужской"
              checked={profile?.sex === ProfileSexEnum.Male}
              onClick={handleSex}
            />

            <Radio
              id="user__gender--female"
              name="gender"
              value={ProfileSexEnum.Female}
              label="Женский"
              checked={profile?.sex === ProfileSexEnum.Female}
              onClick={handleSex}
            />
          </InputGroup>
        </InputWrapper>
      </Grid>
    </Div>
  );
});

export default UserInfo;
