import React, { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { isString } from 'lodash';
import Input from '../../base-ui/input';
import { WidgetProps } from '../types';
import { ErrorFormMessage } from '..';
import { REGEXP } from '../../../utils/constants';

export const EmailWidget: React.FC<WidgetProps<string>> = (props) => {
  const { values } = props;

  const form = useFormContext();

  const rules = useMemo(() => {
    return {
      required: {
        value: values.required,
        message: 'Обязательное поле',
      },
      pattern: {
        value: REGEXP.email,
        message: 'Некорректный адрес',
      },
    };
  }, [props.values.required]);

  return (
    <div>
      <Controller
        name={values.slug}
        control={form.control}
        rules={rules}
        render={({ value, onChange }) => (
          <Input
            id={values.slug}
            label={values.description}
            placeholder={values.placeholder}
            onChange={onChange}
            value={isString(value) ? value : ''}
          />
        )}
      />
      <ErrorFormMessage>
        <ErrorMessage errors={form.errors} name={values.slug} />
      </ErrorFormMessage>
    </div>
  );
};
