import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useProjectsStore } from 'store';
import { observer } from 'mobx-react-lite';
import { Businesses, WinnersCarousel, WinnersList } from 'components/renderers/assol-winners';
import { AwardBusiness } from 'services';
import { useParams } from 'react-router-dom';
import { useDeviceType } from 'utils/hooks';
import { Container, Header } from './styles';

export const AssolWinners = observer(() => {
  const store = useProjectsStore();
  const device = useDeviceType();
  const { awardId } = useParams<{ awardId: string }>();
  const [currentBusiness, setCurrentBusiness] = useState<AwardBusiness | null>(null);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    store.fetchAwardBusiness({ id: +awardId }).then(() => {
      setCurrentBusiness(store.awardBusiness[0]);
    });
    return store.cleanUp;
  }, [awardId]);

  useEffect(() => {
    if (awardId && currentBusiness?.id) {
      store.fetchAwards();
      store.fetchAwardsWinners({ businessId: currentBusiness?.id, awardId: +awardId });
    }
  }, [currentBusiness]);

  const isMobile = useMemo(() => device === 'mobile', [device]);

  const year = useMemo(() => {
    return store.awardWinners.filter((item) => item.award.id === +awardId).map((item) => item.award.year)[0];
  }, [awardId, store.awardWinners]);

  const onBusinessClick = useCallback(
    (id: number) => {
      setFetching(true);
      const newCurrentBusiness = store.awardBusiness.filter((item) => item.id === id)[0];
      setCurrentBusiness(newCurrentBusiness);
      setFetching(false);
    },
    [currentBusiness],
  );

  return (
    <Container>
      {year && <Header>{`Победители премии ${year}`}</Header>}
      <Businesses data={store.awardBusiness} currentBusiness={currentBusiness} onBusinessClick={onBusinessClick} />
      {!fetching && !isMobile && Boolean(store.awardWinners.length) && <WinnersCarousel winners={store.awardWinners} />}
      {!fetching && isMobile && <WinnersList winners={store.awardWinners} />}
    </Container>
  );
});
