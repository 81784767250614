import styled from 'styled-components';
import { AccordionItem } from '@szhsin/react-accordion';
import { COLORS } from 'utils/constants';

export const Wrapper = styled.div`
  padding: 0 16px;
`;

export const StAccordionItem = styled(AccordionItem)`
  border-bottom: 1px solid ${COLORS.secondary};
  .szh-accordion__item {
    &-btn {
      width: 100%;
      height: 56px;
      margin: 0;
      padding: 0;
      border: none;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;
      background-color: ${COLORS.gray6};
    }
    &-content {
      transition: height 0.25s cubic-bezier(0, 0, 0, 1);
    }
  }

  .chevron-down {
    margin-left: auto;
    transition: transform 0.25s cubic-bezier(0, 0, 0, 1);
  }

  &.szh-accordion__item--expanded {
    .szh-accordion__item-btn {
      //панель с вложенными при раскрытие
    }
    .chevron-down {
      transform: rotate(180deg);
    }
  }
`;

export const Item = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 20px;
`;
