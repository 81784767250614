import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';
import IconButton from 'components/base-ui/icon-button';

export const WarningWrapper = styled.div`
  position: fixed;
  z-index: 9999999;
  max-width: 544px;
  font-size: 14px;
  line-height: 20px;
  color: ${COLORS.gray2};
  border-radius: 2px;
  box-shadow: 0 4px 8px rgba(59, 82, 117, 0.4);
  background: ${COLORS.white};
  bottom: 0;
  right: 0;

  @media screen and (min-width: ${BREAKPOINTS.mobile}) {
    padding: 16px;
  }

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    right: 8.5%;
    bottom: 8.5%;
  }
`;

export const WarningContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-left: 24px;
  margin-right: 42px;
`;

export const WarningHeader = styled.div`
  margin: 24px 0 8px 0;
  color: ${COLORS.red};
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
`;

export const WarningBody = styled.div`
  font-size: 14px;
  line-height: 20px;
`;

export const WarningDetail = styled.a`
  font-size: 14px;
  line-height: 24px;
  font-weight: bold;
  color: ${COLORS.secondary};
  :hover {
    text-decoration: underline;
  }
`;

export const WarningCloseButton = styled(IconButton)`
  position: absolute;
  top: 23px;
  right: 23px;
`;
