import React from 'react';

import { ArrowButton } from 'components/base-ui/button';
import { Div, Content, Title, Description, ButtonWrapper, Outer, Inner, Item } from './styles';

interface SmallBannerPropsType {
  header: string;
  description?: string;
  image: string;
  buttonsVisibility: boolean;
  onClick: () => void;
}

export const SmallBanner: React.FC<SmallBannerPropsType> = ({
  header,
  description,
  image,
  buttonsVisibility,
  onClick,
}) => {
  return (
    <Div>
      <Outer src={image}>
        <Inner />
      </Outer>
      <Item>
        <Content>
          <Title>{header.toUpperCase()}</Title>
          <Description>{description}</Description>
        </Content>
        {buttonsVisibility && (
          <ButtonWrapper>
            <ArrowButton white gradient onClick={onClick}>
              ПОДРОБНЕЕ
            </ArrowButton>
          </ButtonWrapper>
        )}
      </Item>
    </Div>
  );
};
