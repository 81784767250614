import React, { useMemo } from 'react';
import { AwardType } from 'store/projects/types';
import { ReactComponent as IconArrow } from 'assets/icons/icon_arrow.svg';
import { useCarousel, useDeviceType } from 'utils/hooks';
import { AwardItem } from './item';
import { ArrowButton, Container } from './styles';

interface AwardCarouselProps {
  data: AwardType[];
}

export const AwardCarousel: React.FC<AwardCarouselProps> = ({ data }) => {
  const { list, decrement, increment } = useCarousel(data);

  const deviceType = useDeviceType();

  const itemsToShow = useMemo(() => {
    return deviceType === 'desktop' ? list.slice(0, 3) : list;
  }, [list, deviceType]);

  const listRender = useMemo(() => itemsToShow.map((item) => <AwardItem key={item.id} data={item} />), [itemsToShow]);

  return (
    <Container>
      <ArrowButton direction="left" onClick={decrement}>
        <IconArrow />
      </ArrowButton>
      {listRender}
      <ArrowButton direction="right" onClick={increment}>
        <IconArrow />
      </ArrowButton>
    </Container>
  );
};
