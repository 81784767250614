import React from 'react';
import styled from 'styled-components';

import { COLORS, BREAKPOINTS } from '../../../../../utils/constants';

import { smallStyles } from '../../../../../styles/text';

import { H5 } from '../../../../base-ui/typo';
import HtmlContainer from '../../../../base-ui/html-container';
import { HtmlRenderer } from '../../../../../utils/htmlParser';

const Div = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  min-height: 0;
  color: ${COLORS.gray2};
  padding: 28px 0 0 16px;
  grid-template-rows: auto 1fr auto;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    min-height: 440px;
    padding: 28px 0 0 32px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    min-height: unset;
  }
`;

const Head = styled.div`
  margin-bottom: 20px;
`;

const Title = styled(H5)`
  font-weight: 400;
  color: ${COLORS.secondary};
`;

const Body = styled.div`
  overflow: auto;
  margin-bottom: 24px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin-bottom: 0;
  }
`;

const Description = styled(HtmlContainer)`
  ${smallStyles}
  overflow-y: auto;
`;

interface DetailProps {
  title: string;
  description: string;
}

const Detail: React.FC<DetailProps> = ({ title, description }) => {
  return (
    <Div>
      <Head>
        <Title>{title}</Title>
      </Head>

      <Body>
        <Description>
          <HtmlRenderer html={description} />
        </Description>
      </Body>
    </Div>
  );
};

export default Detail;
