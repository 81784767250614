import React from 'react';
import { Styled } from './styles';

export interface DurationProps {
  total: number;
  current: number;
  visible: boolean;
}

export const Counter: React.FunctionComponent<DurationProps> = ({ visible, total, current }) => (
  <Styled.Container visible={visible}>{`${current} / ${total}`}</Styled.Container>
);
