import React, { useMemo, ChangeEvent, forwardRef, RefObject, PropsWithRef } from 'react';
import styled, { css } from 'styled-components';

import { COLORS, BASIC_FONT_FAMILY } from 'utils/constants';
import { Informer } from '../typo';

const InputLabel = styled.label`
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 4px;
`;

const InputField = styled.input`
  display: block;
  font-size: 16px;
  line-height: 24px;
  padding: 7px 12px;
  border: 1px solid;
  border-radius: 2px;
  transition: all 0.2s ease-out;
  color: ${COLORS.gray2} !important;
  background-color: ${COLORS.white};
  font-family: ${BASIC_FONT_FAMILY};

  &::placeholder {
    color: ${COLORS.gray4} !important;
  }

  &:disabled {
    color: ${COLORS.gray4} !important;

    &::placeholder {
      color: ${COLORS.gray6} !important;
    }
  }
`;

const InputError = styled(Informer)`
  position: absolute;
  right: 0;
  bottom: -8px;
  padding: 0 4px;
  display: inline-block;
  color: ${COLORS.white};
  background-color: ${COLORS.red};
`;

interface InputDivProps {
  valid: boolean;
  color?: string;
  small?: boolean;
}

const smallStyles = css`
  width: unset !important;
`;

const InputDiv = styled.div<InputDivProps>`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  ${(props) => props.small && smallStyles};

  > ${InputLabel} {
    color: ${(props) => props.color || COLORS.gray};
  }

  > ${InputField} {
    color: ${(props) => (props.valid ? COLORS.gray4 : COLORS.red)};
    border-color: ${(props) => (props.valid ? COLORS.gray5 : COLORS.red)};
  }
`;

interface InputProps extends Omit<InputDivProps, 'valid'> {
  id: string;
  type?: string;
  label?: string;
  value?: string | number;
  maxLength?: number;
  placeholder?: string;
  error?: string;
  min?: number;
  max?: number;
  disabled?: boolean;
  className?: string;
  autoFocus?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  defaultValue?: string;
  autoComplete?: string;
  pattern?: string;
}

const Input: React.FC<PropsWithRef<InputProps>> = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      id,
      type,
      label,
      value,
      placeholder,
      maxLength,
      error,
      color,
      small,
      min,
      max,
      disabled,
      className,
      onChange,
      defaultValue,
      autoFocus,
      autoComplete,
      pattern,
    },
    ref,
  ) => {
    const valid = useMemo(() => {
      return error === undefined;
    }, [error]);

    return (
      <InputDiv valid={valid} color={color} small={small}>
        {label && <InputLabel htmlFor={id}>{label}</InputLabel>}

        <InputField
          ref={ref}
          id={id}
          type={type}
          value={value}
          maxLength={maxLength}
          placeholder={placeholder}
          min={min}
          max={max}
          disabled={disabled}
          className={className}
          onChange={onChange}
          defaultValue={defaultValue}
          autoFocus={autoFocus}
          autoComplete={autoComplete}
          pattern={pattern}
        />

        {error && <InputError>{error}</InputError>}
      </InputDiv>
    );
  },
);

export default Input;
