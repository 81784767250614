// @ts-nocheck

import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';

const BlockSearch = styled.section`
  width: 100%;
  padding: 0 30px;
  background: -moz-linear-gradient(to right, rgb(122, 51, 92) 0%, rgb(237, 28, 36) 100%);
  background: -webkit-linear-gradient(to right, rgb(122, 51, 92) 0%, rgb(237, 28, 36) 100%);
  background: -ms-linear-gradient(to right, rgb(122, 51, 92) 0%, rgb(237, 28, 36) 100%);
  background: linear-gradient(to right, rgb(122, 51, 92) 0%, rgb(237, 28, 36) 100%);
  position: relative;
  z-index: 5;
`;

const TravelLineSearchForm = styled.div`
  width: 100%;
  max-width: 1290px;
  margin: 0 auto;
`;

type TravelLineType = {
  name?: string;
};

export const TravelLineWidget: React.FC<TravelLineType> = (props: TravelLineType) => {
  const searchHotel = useCallback((name: string) => {
    if (name.includes('Винтера')) {
      return 7311;
    }
    if (name.includes('Лодейное')) {
      return 21997;
    }
    if (name.includes('Приозерск')) {
      return 7492;
    }
    if (name.includes('Сортавала')) {
      return 7491;
    }
    if (name.includes('Видлица')) {
      return 16563;
    }
    if (name.includes('Времена')) {
      return 29646;
    }
    return 6634;
  }, []);

  const hotelId = useMemo(() => searchHotel(props.name || ''), [props.name]);

  const loadWidget = (w: Window) => {
    const q = [
      ['setContext', `TL-INT-igora-assol.6634,${hotelId}`, 'ru'],
      [
        'embed',
        'search-form',
        {
          container: 'tl-search-form',
        },
      ],
    ];
    // @ts-ignore
    const t = (w.travelline = w.travelline || {});
    const h = ['ru-ibe.tlintegration.ru', 'ibe.tlintegration.ru', 'ibe.tlintegration.com'];
    const ti = (t.integration = t.integration || {});
    ti.__cq = ti.__cq ? ti.__cq.concat(q) : q;
    if (!ti.__loader) {
      ti.__loader = true;
      const d = w.document;
      var c = d.getElementsByTagName('head')[0] || d.getElementsByTagName('body')[0];
      //@ts-ignore
      function e(s, f) {
        return function() {
          //@ts-ignore
          w.TL || (c.removeChild(s), f());
        };
      }
      (function l(h) {
        if (h.length === 0) return;
        const s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = !0;
        s.src = `https://${h[0]}/integration/loader.js`;
        s.onerror = s.onload = e(s, () => {
          l(h.slice(1, h.length));
        });
        c.appendChild(s);
      })(h);
    }
  };
  // @ts-ignore
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    loadWidget(window);
  }, [hotelId]);

  return (
    <BlockSearch id="block-search" ref={ref}>
      <TravelLineSearchForm id="tl-search-form" className="tl-container">
        <noindex>
          <a target="_blank" href="http://www.travelline.ru/products/tl-hotel/" rel="nofollow">
            Модуль онлайн-бронирования
          </a>
        </noindex>
      </TravelLineSearchForm>
    </BlockSearch>
  );
};
