import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';

export const StContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  padding: 8px 0;
`;

export const StText = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: ${COLORS.white};
  white-space: nowrap;
`;

export const StContent = styled.div`
  display: flex;
  align-items: center;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    display: flex;
    column-gap: 6px;
    align-items: center;
  }
`;

export const StAvatarWrapper = styled.div<{ background: string }>`
  width: 48px;
  height: 48px;
  border-radius: 24px;
  background: ${(props) => `url(${props.background})`};
  background-size: cover;
  margin-left: -8px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin-left: 0;
  }
`;

export const StAvatar = styled.img`
  width: 100%;
  height: 100%;
`;

export const StLikesCount = styled(StText)`
  margin-left: 14px;
  cursor: pointer;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin-left: 0;
  }
`;
