import React, { createContext, useContext } from 'react';

import ProfileStore from './store';

const store = new ProfileStore();

const ProfileStateContext = createContext<ProfileStore>(store);

export const ProfileStateProvider: React.FC = ({ children }) => (
  <ProfileStateContext.Provider value={store}>{children}</ProfileStateContext.Provider>
);

export const useProfileStore = (): ProfileStore => useContext(ProfileStateContext);
