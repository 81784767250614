import styled from 'styled-components';
import { TextButton } from 'components/base-ui/button';
import { BASIC_FONT_FAMILY, BREAKPOINTS, COLORS } from 'utils/constants';

export const Container = styled.div`
  font-family: ${BASIC_FONT_FAMILY};
  color: ${COLORS.secondary};
  font-weight: 400;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const Header = styled.div`
  font-size: 20px;
  line-height: 26px;
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    font-size: 28px;
    line-height: 32px;
  }
`;
export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    flex-direction: row;
  }
`;
export const Button = styled(TextButton)`
  width: 160px;
  height: 48px;
  border: 1px solid ${COLORS.secondary};
  margin-top: 20px;
  margin-right: 20px;
  color: ${COLORS.secondary};
  text-transform: uppercase;
  :hover {
    background: ${COLORS.linearGradient};
    color: ${COLORS.white};
  }
`;
export const Link = styled.a`
  display: inline-block;
  padding-top: 12px;
  font: inherit;
  color: inherit;
  text-transform: uppercase;
  width: 160px;
  height: 48px;
  :hover {
    text-decoration: none;
  }
`;
