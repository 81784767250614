import React from 'react';
import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';

export const QuoteWrapper = styled.div`
  width: 320px;
  position: relative;
  background-color: ${COLORS.gray7};
  padding: 32px 24px 32px 30px;
  margin: 40px 0;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 698px;
    padding: 48px 49px 48px 63px;
  }
`;
export const QuoteContent = styled.div`
  position: relative;
  color: ${COLORS.gray3};
  font-size: 22px;
  line-height: 38px;
  z-index: 9;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 586px;
    font-size: 24px;
    line-height: 32px;
  }
`;
export const WaterMark = styled.div`
  font-weight: 900;
  position: absolute;
  top: 0;
  left: 16px;
  font-size: 200px;
  line-height: 242px;
  color: ${COLORS.cloud};
  z-index: 1;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    left: 24px;
  }
`;

export const ArticleQuote: React.FC<{ text: string }> = ({ text }) => {
  return (
    <QuoteWrapper>
      <WaterMark>“</WaterMark>
      <QuoteContent>{text}</QuoteContent>
    </QuoteWrapper>
  );
};
