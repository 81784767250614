import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';
import Icon from 'components/base-ui/icon';
import { Micro } from 'components/base-ui/typo';
import IconButton from 'components/base-ui/icon-button';

export const Div = styled.div`
  width: 100%;
  margin-bottom: 128px;
`;

export const Body = styled.div`
  margin-bottom: 20px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    display: grid;
    column-gap: 16px;
    grid-template-columns: 1fr 320px;
    margin-bottom: 32px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    margin-bottom: unset;
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: 254px;
  overflow: hidden;
  position: relative;
  margin-bottom: 24px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    height: 649px;
    margin-bottom: 0;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    height: 576px;
    margin-bottom: 32px;
  }
`;

export const Footer = styled.div`
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 0 32px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding: 0 80px 0 0;
  }
`;

export const FooterInfo = styled.div`
  padding: 14px 0 18px 0;
  display: grid;
  column-gap: 8px;
  grid-template-columns: 24px 1fr;
  border-top: 1px solid ${COLORS.gray5};
  border-bottom: 1px solid ${COLORS.gray5};
`;

export const Image = styled.img`
  top: 50%;
  left: 50%;
  height: 100%;
  position: absolute;
  transform: translate(-50%, -50%);
`;

export const FooterInfoIcon = styled(Icon)`
  width: 24px;
  height: 24px;
`;

export const FooterInfoAvailability = styled.div`
  color: ${COLORS.gray4};

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    max-width: 512px;
  }
`;

export const FooterInfoAvailabilityRow = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const FooterInfoAvailabilityText = styled(Micro)`
  margin-right: 0.5ch;
`;

export const FooterInfoAvailabilityLink = styled.span`
  font-size: 12px;
  line-height: 16px;
  margin-right: 0.5ch;
  color: ${COLORS.secondary};
`;

export const FooterPrice = styled.div`
  width: 240px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const FooterPriceActions = styled.div`
  display: grid;
  column-gap: 8px;
  grid-auto-flow: column;
  grid-auto-columns: 24px;
`;

export const FooterPriceIconButton = styled(IconButton)`
  filter: invert(25%) sepia(13%) saturate(557%) hue-rotate(176deg) brightness(82%) contrast(87%);
`;
