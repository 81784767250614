import React, { useEffect, useRef, useState, useCallback } from 'react';
import styled from 'styled-components';

import { BREAKPOINTS } from '../../../utils/constants';

interface MapRendererProps {
  mapFrame?: string | null;
}

const REG_EXP_SELECT_QUOTES = /"([^"]*)"/g; // select all values in quotes;

const MapWrapper = styled.div`
  width: 100vw;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin-left: -56px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    margin-left: -136px;
    width: calc(100vw - 16px);
  }
`;

const ErrorMessage = styled.div`
  text-align: center;
  font-size: 20px;
`;

// eslint-disable-next-line sonarjs/cognitive-complexity
export const MapRenderer: React.FC<MapRendererProps> = ({ mapFrame }) => {
  const ref = useRef<HTMLIFrameElement | null>(null);
  const [loadingError, setLoadingError] = useState(false);

  const addMap = useCallback(
    (token: string) => {
      if (ref) {
        try {
          if (ref.current?.contentWindow?.document) {
            ref?.current?.contentWindow.document.open();
            ref?.current?.contentWindow.document.write(atob(token));
            ref?.current?.contentWindow.document.close();
          }
        } catch (e) {
          setLoadingError(true);
        }
      }
    },
    [ref],
  );

  // TODO find more safe solution
  const getTokenFromInlineCode = useCallback((inlineCode: string) => {
    const re = new RegExp(REG_EXP_SELECT_QUOTES);
    const valuesInQuotes = inlineCode.match(re);
    if (valuesInQuotes && valuesInQuotes.length > 0) {
      // get last value ant remove quotes
      return valuesInQuotes[valuesInQuotes.length - 1].replaceAll('"', '');
    }
  }, []);

  useEffect(() => {
    if (mapFrame) {
      const token = getTokenFromInlineCode(mapFrame);
      if (token) {
        addMap(token);
      } else {
        setLoadingError(true);
      }
    }
  }, [ref, mapFrame]);

  return mapFrame ? (
    <MapWrapper>
      {loadingError ? (
        <ErrorMessage>Ошибка загрузки карты</ErrorMessage>
      ) : (
        // eslint-disable-next-line jsx-a11y/iframe-has-title
        <iframe
          id="map_test"
          ref={ref}
          sandbox="allow-modals
            allow-forms
            allow-scripts
            allow-same-origin
            allow-popups
            allow-top-navigation-by-user-activation"
          width="100%"
          height="600px"
          frameBorder="0"
        />
      )}
    </MapWrapper>
  ) : null;
};
