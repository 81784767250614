import styled from 'styled-components';
import { COLORS } from 'utils/constants';

const Container = styled.button`
  display: flex;
  padding: 12px 24px;
  gap: 4px;
  width: min-content;

  background: ${COLORS.gray6};
  border: none;
  border-radius: 2px;

  cursor: pointer;
`;

const Title = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  white-space: nowrap;

  text-align: center;
  text-transform: uppercase;

  color: ${COLORS.gray3};
`;

const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  svg {
    width: 24px;
    height: 24px;
    & * {
      fill: ${COLORS.secondary};
    }
  }
`;

export const Styled = { Title, IconWrapper, Container };
