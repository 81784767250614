import React, { TouchEvent, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { SliderItem } from 'web';
import { useDeviceType } from 'utils/hooks';
import { BREAKPOINTS, COLORS } from 'utils/constants';
import { useSliderData } from 'components/renderers/huge-slider/useSliderData';
import { useSwipeable } from 'react-swipeable';
import SlidesItem from './item';
import { Styled } from './styles';

interface SliderProps {
  items: SliderItem[];
  isActive?: boolean | null;
  interval?: number | null;
}

const Div = styled.div`
  height: 500px;
  position: relative;
  color: ${COLORS.white};
  overflow-x: hidden;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    height: 784px;
  }
`;

const Slider: React.FC<SliderProps> = ({ items, isActive, interval }) => {
  const deviceType = useDeviceType();

  const images = useMemo(() => items.map((i) => i.image), [items]);
  const periodicity = (interval ?? 0) * 2000;

  const { current, setCurrent, ref, slideToNext, slideToPrev, loop } = useSliderData({ isActive, periodicity, items });

  const swipeHandlers = useSwipeable({
    onSwipedRight: slideToPrev,
    onSwipedLeft: slideToNext,
    delta: 50,
  });

  const [currentImage, setCurrentImage] = useState<string>(images[current]);

  useEffect(() => {
    setCurrentImage(images[current]);
  }, [current]);

  const isMobile = useMemo(() => deviceType === 'mobile', [deviceType]);

  const currentIndex = useMemo(() => {
    const idx = current + 1;
    return idx.toString().padStart(2, '0');
  }, [current]);

  const setCurrentSlide = useCallback((idx: number, e: MouseEvent<HTMLElement> | TouchEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setCurrent(idx);
  }, []);

  return (
    <Div {...swipeHandlers} onClick={deviceType === 'mobile' || deviceType === 'tablet' ? slideToNext : undefined}>
      <Styled.Outer src={currentImage}>
        {isMobile ? (
          <Styled.Inner />
        ) : (
          <>
            <Styled.Inner>
              <Styled.IndexCircle>
                <Styled.Index>{currentIndex}</Styled.Index>
              </Styled.IndexCircle>
            </Styled.Inner>

            <Styled.Next />
          </>
        )}
      </Styled.Outer>

      <Styled.Slides ref={ref}>
        {items.map((el, idx) => (
          <SlidesItem key={el.title} data={el} active={idx === current} />
        ))}
        <Styled.Navigation>
          {items.map((el, idx) => (
            <Styled.NavigationItemWrapper key={el.title} onClick={(e) => setCurrentSlide(idx, e)}>
              <Styled.NavigationItem active={idx === current} />
            </Styled.NavigationItemWrapper>
          ))}
        </Styled.Navigation>
      </Styled.Slides>

      {!isMobile && (
        <Styled.NextButton
          onMouseOver={() => loop(false)}
          onMouseOut={() => loop(true)}
          name="iconArrowRight"
          round
          gradient
          onClick={(e) => {
            e.stopPropagation();
            slideToNext();
          }}
        />
      )}
    </Div>
  );
};

export default Slider;
