import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';

export const StVerifyInput = styled.div`
  margin-top: 32px;
  width: 288px;
`;

export const StInput = styled.input<{ error: boolean; isSafari?: boolean }>`
  display: block;
  width: 100%;
  font-size: 44px;
  font-style: normal;
  font-weight: 300;
  line-height: 55px;
  letter-spacing: 28px;
  text-indent: ${(props) => (props.isSafari ? '30px' : '48px')};
  color: ${(props) => (props.error ? `${COLORS.red}` : `${COLORS.primary}`)};
  border: none;

  ::placeholder {
    text-indent: ${(props) => (props.isSafari ? '44px' : '66px')};
    letter-spacing: 28px;
    color: ${COLORS.gray5};
  }

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    text-indent: 48px;
    ::placeholder {
      text-indent: 66px;
    }
  }
`;
