import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';

const TitleContainer = styled.div`
  position: absolute;
  height: 50px;
  left: -1px;
  top: -1px;
  right: -1px;
  padding: 10px 8px;
  background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 100%);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${COLORS.white};

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    height: 100px;
    padding: 22px 20px;
  }
`;

const TitleText = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-size: 20px;
    line-height: 26px;
  }
`;

export const Styled = { TitleContainer, TitleText };
