import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';

export const Wrapper = styled.div`
  background-color: ${COLORS.gray6};
  padding: 28px 0;

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 125px 70px 125px;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px; //temp

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const Description = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: ${COLORS.secondary};
  padding: 0 16px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 0 32px;
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding: 0;
  }
`;

export const Columns = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 40px;
  padding: 0 16px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 0 32px;
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding: 0;
  }
`;

export const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 382px;
  }
`;

export const ColumnHeader = styled.div`
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  color: #12519a;
`;

export const ColumnItem = styled.div`
  display: flex;
  gap: 15px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: ${COLORS.secondary};
`;

export const Footer = styled.div<{ withoutButton?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 16px;
  gap: 60px;
  margin-top: 40px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 0 32px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: 100%;
    padding: 0;
    flex-direction: row;
    justify-content: ${(props) => (props.withoutButton ? 'center' : 'space-between')};
    flex-wrap: nowrap;
    align-items: center;
  }
`;

export const DecorWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  overflow: paged-x;
`;

export const StButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 100%;
  height: 48px;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  color: ${COLORS.white};
  background-color: #d12b5e;
  border: none;
  cursor: pointer;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 164px;
  }
`;
