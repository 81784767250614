import React from 'react';
import styled from 'styled-components';
import iconAlertCircle from 'assets/icons/icon_alert_circle.svg';
import iconArrowLeft from 'assets/icons/icon_arrow_left.svg';
import iconArrowRight from 'assets/icons/icon_arrow_right.svg';
import iconArrowWhite from 'assets/icons/icon_arrow_white.svg';
import iconArrowGrey from 'assets/icons/icon_arrow_grey.svg';
import iconArrow from 'assets/icons/icon_arrow.svg';
import iconArrows from 'assets/icons/icon_arrows.svg';
import iconArrowsRevert from 'assets/icons/icon_arrows_revert.svg';
import iconAvatar from 'assets/icons/icon_avatar.svg';
import iconBag from 'assets/icons/icon_bag.svg';
import iconCrossBase from 'assets/icons/icon_cross_base.svg';
import iconCrossBaseWhite from 'assets/icons/icon_cross_base_white.svg';
import iconCrossSmall from 'assets/icons/icon_cross_small.svg';
import iconCrossSmallBlue from 'assets/icons/icon_cross_small_blue.svg';
import iconDoc from 'assets/icons/icon_doc.svg';
import iconDocDark from 'assets/icons/icon_doc_dark.svg';
import iconEdit from 'assets/icons/icon_edit.svg';
import iconFltr from 'assets/icons/icon_fltr.svg';
import iconFoto from 'assets/icons/icon_foto.svg';
import iconGrid from 'assets/icons/icon_grid.svg';
import iconLike from 'assets/icons/icon_like.svg';
import iconLikeGrey from 'assets/icons/icon_like_grey.svg';
import iconLikeRed from 'assets/icons/icon_like_red.svg';
import iconLikeWhite from 'assets/icons/icon_like_white.svg';
import iconList from 'assets/icons/icon_list.svg';
import iconLogout from 'assets/icons/icon_logout.svg';
import iconMail from 'assets/icons/icon_mail.svg';
import iconMenu from 'assets/icons/icon_menu.svg';
import iconMinus from 'assets/icons/icon_minus.svg';
import iconPawPrint from 'assets/icons/icon_paw_print.svg';
import iconPdf from 'assets/icons/icon_pdf.svg';
import iconPdfDark from 'assets/icons/icon_pdf_dark.svg';
import iconPlus from 'assets/icons/icon_plus.svg';
import iconPlusBig from 'assets/icons/icon_plus_big.svg';
import iconPointer from 'assets/icons/icon_pointer.svg';
import iconPrint from 'assets/icons/icon_print.svg';
import iconRate from 'assets/icons/icon_rate.svg';
import iconSearch from 'assets/icons/icon_search.svg';
import iconSettings from 'assets/icons/icon_settings.svg';
import iconShare from 'assets/icons/icon_share.svg';
import iconThumb from 'assets/icons/icon_thumb.svg';
import iconZip from 'assets/icons/icon_zip.svg';
import iconZipDark from 'assets/icons/icon_zip_dark.svg';
import iconViewmore from 'assets/icons/icon_viewmore.svg';
import iconRateFill from 'assets/icons/icon_rate_fill.svg';
import iconViews from 'assets/icons/icon_views.svg';
import iconViewsGrey from 'assets/icons/icon_views_grey.svg';
import iconPrintSolid from 'assets/icons/icon_print_solid.svg';
import iconArrowBlack from 'assets/icons/icon_arrow_black.svg';
import iconPlay from 'assets/icons/icon_play.svg';
import iconNext from 'assets/icons/icon_next.svg';
import iconNavRight from 'assets/icons/icon_nav_right.svg';
import iconNavLeft from 'assets/icons/icon_nav_left.svg';
import playCircle from 'assets/icons/play_circle.svg';
import iconChatBubbleOutline from 'assets/icons/icon_chat_bubble_outline.svg';
import iconPdfGray from 'assets/icons/icon_pdf_gray.svg';

import { IconName } from 'web';

const icons: Record<IconName, string> = {
  iconAlertCircle,
  iconArrowLeft,
  iconArrowRight,
  iconArrowWhite,
  iconArrowGrey,
  iconArrow,
  iconArrows,
  iconArrowsRevert,
  iconAvatar,
  iconBag,
  iconCrossBase,
  iconCrossBaseWhite,
  iconCrossSmall,
  iconCrossSmallBlue,
  iconDoc,
  iconDocDark,
  iconEdit,
  iconFltr,
  iconFoto,
  iconGrid,
  iconLike,
  iconLikeGrey,
  iconLikeRed,
  iconLikeWhite,
  iconList,
  iconLogout,
  iconMail,
  iconMenu,
  iconMinus,
  iconPawPrint,
  iconPdf,
  iconPdfDark,
  iconPlus,
  iconPlusBig,
  iconPointer,
  iconPrint,
  iconRate,
  iconSearch,
  iconSettings,
  iconShare,
  iconThumb,
  iconZip,
  iconZipDark,
  iconViewmore,
  iconRateFill,
  iconViews,
  iconViewsGrey,
  iconPrintSolid,
  iconArrowBlack,
  iconPlay,
  iconNext,
  iconNavRight,
  iconNavLeft,
  playCircle,
  iconChatBubbleOutline,
  iconPdfGray,
};

interface IconProps {
  name: IconName;
  className?: string;
  onClick?: () => void;
}

const Img = styled.img`
  height: 100%;
`;

const Icon: React.FC<IconProps> = ({ name, className, onClick }) => {
  return <Img src={icons[name]} className={className} onClick={onClick} />;
};

export default Icon;
