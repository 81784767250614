import styled from 'styled-components';

import { COLORS, BREAKPOINTS } from 'utils/constants';

export const StepText = styled.small`
  display: block;
  width: 240px;
  margin-bottom: 16px;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  color: ${COLORS.white};

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 430px;
    font-size: 12px;
    line-height: 16px;
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: 280px;
  }
`;
