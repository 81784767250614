import { action, makeObservable, observable } from 'mobx';

import { api } from 'utils/api';

import {
  AlcoholContentsLimit,
  DirectionsApiDirectionsListRequest,
  ProductList,
  ProductPriceLimit,
  ProductsApiProductsListRequest,
  ProductsApiProductsPriceLimitsListRequest,
  Profile,
} from 'services';

import { AxiosError } from 'axios';
import AbstractStore, { NullableError } from '../AbstractStore';
import { ExtendedDirection } from '../../components/renderers/directions/types';

export default class ProductListStore extends AbstractStore {
  count = 0;

  products: ProductList[] = [];

  priceLimits: ProductPriceLimit = { minPrice: '0', maxPrice: '0' };

  alcoholLimits: AlcoholContentsLimit = { minAlcoholContent: '0', maxAlcoholContent: '0' };

  directions: ExtendedDirection[] = [];

  constructor() {
    super();
    makeObservable(this, {
      ...this.annotations,
      count: observable,
      products: observable,
      priceLimits: observable,
      alcoholLimits: observable,
      directions: observable,
      fetchProducts: action,
      setCount: action,
      setProducts: action,
      setPriceLimits: action,
      setAlcoholLimits: action,
      changeRating: action,
      handleLikePost: action,
      handleLikeDelete: action,
      handleFavoritePost: action,
      handleFavoriteDelete: action,
      toggleDirections: action,
      setDirections: action,
      fetchDirections: action,
    });
  }

  fetchProducts(props: ProductsApiProductsListRequest = {}, options?: unknown) {
    const directionsIds = this.directions.filter((direction) => direction.active).map((direction) => direction.id);
    this.fetchData(() =>
      api.products
        .productsList({ ...props, directionsIdIn: directionsIds }, options)
        .then((res) => {
          this.setCount(res.data.count);
          this.setProducts(res.data.results);
        })
        .catch((err: AxiosError) => this.setError(err)),
    );
  }

  fetchPriceLimits(props: ProductsApiProductsPriceLimitsListRequest = {}, options?: unknown) {
    this.fetchData(() =>
      api.products.productsPriceLimitsList(props, options).then((res) => {
        this.setPriceLimits(res.data);
      }),
    );
  }

  fetchAlcoholLimits(props: ProductsApiProductsPriceLimitsListRequest = {}, options?: unknown) {
    this.fetchData(() =>
      api.products.productsAlcoholContentLimitsList(props, options).then((res) => {
        this.setAlcoholLimits(res.data);
      }),
    );
  }

  changeRating = (productId: number, ratingValue: number) => {
    api.productsRating.productRatingsCreate({ data: { product: String(productId), ratingValue } }).then(() => {
      this.setProducts(
        this.products.map((p) => {
          p.id === productId && (p.userRating = ratingValue);
          return p;
        }),
      );
    });
  };

  handleLikePost = (productId: number, profile: Profile | null) => {
    api.products.productsToggleLikeCreate({ id: productId }).then(() => {
      this.setProducts(
        this.products.map((p) => {
          p.id === productId && (p.isLikedByUser = true);
          p.id === productId && (p.likesCount ? (p.likesCount += 1) : (p.likesCount = 1));
          p.id === productId &&
            p.likeAuthors &&
            (p.likeAuthors =
              p.likeAuthors.length < 6
                ? [
                    ...p.likeAuthors,
                    {
                      id: profile?.id!,
                      email: profile?.user?.email!,
                      fullName: `${profile?.lastName} ${profile?.firstName}`,
                      image: profile?.image,
                    },
                  ]
                : [...p.likeAuthors]);
          return p;
        }),
      );
    });
  };

  handleLikeDelete = (productId: number, userId: number) => {
    api.products.productsToggleLikeDelete({ id: productId }).then(() => {
      this.setProducts(
        this.products.map((p) => {
          p.id === productId && (p.isLikedByUser = false);
          p.id === productId && (p.likesCount ? (p.likesCount -= 1) : (p.likesCount = 1));
          p.id === productId && (p.likeAuthors = p.likeAuthors?.filter((item) => item.id !== userId));
          return p;
        }),
      );
    });
  };

  handleFavoritePost = (productId: number) => {
    api.products.productsToggleFavoriteCreate({ id: productId }).then(() => {
      this.setProducts(
        this.products.map((p) => {
          p.id === productId && (p.isInUserFavorites = true);
          return p;
        }),
      );
    });
  };

  handleFavoriteDelete = (productId: number) => {
    api.products.productsToggleFavoriteDelete({ id: productId }).then(() => {
      this.setProducts(
        this.products.map((p) => {
          p.id === productId && (p.isInUserFavorites = false);
          return p;
        }),
      );
    });
  };

  setCount(value: number): void {
    this.count = value;
  }

  setProducts(value: ProductList[]): void {
    this.products = value;
  }

  setPriceLimits(value: ProductPriceLimit): void {
    this.priceLimits = value;
  }

  setAlcoholLimits(value: AlcoholContentsLimit): void {
    this.alcoholLimits = value;
  }

  setDirections(value: ExtendedDirection[]): void {
    this.directions = value;
  }

  fetchDirections = async (props?: DirectionsApiDirectionsListRequest, options?: unknown) => {
    this.setError(null);
    this.setFetching(true);

    try {
      const directions = await api.directions
        .directionsList(props, options)
        .then((response) => response.data.results.filter((direction) => direction.id) as ExtendedDirection[]);
      this.setDirections(directions);
    } catch (error) {
      this.setError(error as NullableError);
    } finally {
      this.setFetching(false);
    }
  };

  toggleDirections = (id: number, active: boolean): void => {
    this.directions = this.directions.map((item) => {
      return item.id === id ? { ...item, active: !active } : item;
    });
  };

  cleanUp = () => {
    this.products = [];
    this.priceLimits = { minPrice: '0', maxPrice: '0' };
    this.alcoholLimits = { minAlcoholContent: '0', maxAlcoholContent: '0' };
    this.directions = [];
  };
}
