import React, { useMemo } from 'react';
import { parseHtml } from 'utils/htmlParser';
import { Description, State, StateInfo, StateSection, StateTitle } from './styles';
import { HeaderStatsProps } from './types';

export const HeaderStats: React.FC<HeaderStatsProps> = ({ stats, description }) => {
  const stateListItems = useMemo(
    () =>
      stats.map((item) => (
        <StateSection>
          <StateTitle>{item.number}</StateTitle>
          <StateInfo>{parseHtml(item.description)}</StateInfo>
        </StateSection>
      )),
    [stats],
  );

  return (
    <>
      <State>{stateListItems}</State>
      <Description>{description}</Description>
    </>
  );
};
