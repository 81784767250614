import { makeObservable, observable, action } from 'mobx';

import { Nullable } from 'web';
import { api } from '../../utils/api';

import { FooterLink, FooterApiFooterListRequest, ApplicationForm, FooterContacts, FooterSocials } from '../../services';

type NullableError = Error | null;

export default class FooterStore {
  fetching = false;

  error: NullableError = null;

  supportContacts: FooterContacts[] = [];

  socialNetworks: FooterSocials[] = [];

  supportHeader = '';

  supportPhone = '';

  supportEmail = '';

  links: FooterLink[] = [];

  supportForm: Nullable<ApplicationForm> = null;

  constructor() {
    makeObservable(this, {
      fetching: observable,
      error: observable,
      links: observable,
      supportForm: observable,
      supportContacts: observable,
      supportHeader: observable,
      supportEmail: observable,
      supportPhone: observable,
      socialNetworks: observable,
      fetchFooterLinks: action,
      fetchSocialNetworks: action,
      fetchSupportContacts: action,
      setSupportContacts: action,
      setError: action,
      setFetching: action,
      setFooterLinks: action,
    });
  }

  setFetching(value: boolean): void {
    this.fetching = value;
  }

  setError(value: NullableError): void {
    this.error = value;
  }

  callPromise(cb: Promise<void>): Promise<void> {
    this.setError(null);
    this.setFetching(true);
    return cb.catch((err: Error) => this.setError(err)).finally(() => this.setFetching(false));
  }

  fetchFooterLinks({ page, limit }: FooterApiFooterListRequest = {}, options?: unknown): Promise<void> {
    return this.callPromise(
      api.footer.footerList({ page, limit }, options).then((res) => this.setFooterLinks(res.data.results)),
    );
  }

  fetchSupportContacts(): Promise<void> {
    return this.callPromise(api.footerContacts.footerContactsList().then((res) => this.setSupportContacts(res.data)));
  }

  fetchSupportForm(): Promise<void> {
    return this.callPromise(
      api.applicationForm
        .applicationFormsList({ slug: 'обратная-связь' })
        .then((res) => this.setForm(res.data.results)),
    );
  }

  fetchSocialNetworks(): Promise<void> {
    return this.callPromise(
      api.footerSocials.footerSocialsList().then((res) => this.setSocialNetworks(res.data.results)),
    );
  }

  setFooterLinks(value: FooterLink[]): void {
    this.links = value;
  }

  setForm(form: ApplicationForm[]): void {
    this.supportForm = form[0];
  }

  setSocialNetworks(socials: FooterSocials[]): void {
    this.socialNetworks = socials;
  }

  setSupportContacts(contacts: FooterContacts[]): void {
    this.supportContacts = contacts;
    this.supportHeader = contacts[0].name;
    this.supportPhone = contacts[1].name;
    this.supportEmail = contacts[2].name;
  }
}
