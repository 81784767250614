import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';
import Icon from 'components/base-ui/icon';
import { TextButton } from 'components/base-ui/button';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  color: ${COLORS.secondary};
  width: 320px;
  height: 100%;
  padding-left: 16px;
  padding-top: 16px;
  margin-bottom: 32px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding-top: 24px;
    padding-left: 24px;
    width: 768px;
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: 800px;
    background-color: rgba(255, 255, 255, 0.8);
    padding-top: 209px;
    padding-left: 91px;
  }
`;
export const SmallHeader = styled.div`
  font-size: 20px;
  line-height: 24px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-size: 32px;
    line-height: 38px;
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    font-size: 44px;
    line-height: 48px;
  }
`;
export const BigHeader = styled.div`
  font-weight: 500;
  font-size: 48px;
  line-height: 58px;
  text-transform: uppercase;
  margin-bottom: 8px;
  background: ${COLORS.linearGradient};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-size: 64px;
    line-height: 78px;
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    font-size: 120px;
    line-height: 144px;
  }
`;
export const BackSideHeader = styled.div`
  font-size: 24px;
  line-height: 32px;
  color: ${COLORS.secondary};
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;
export const IconArrow = styled(Icon)`
  transform: rotate(180deg);
  margin-right: 27px;
  cursor: pointer;
`;
export const BackSideHeaderText = styled.div`
  font-size: 24px;
  line-height: 32px;
  color: ${COLORS.secondary};
  cursor: pointer;
`;
export const Description = styled.div`
  display: none;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    display: unset;
    font-size: 16px;
    line-height: 24px;
    color: ${COLORS.gray3};
  }
`;
export const BackDescription = styled.div`
  width: 100%;
  font-size: 18px;
  line-height: 28px;
  color: ${COLORS.gray3};
`;
export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 288px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    display: block;
    width: unset;
  }
`;
export const Button = styled(TextButton)<{ gradient?: boolean }>`
  width: 288px;
  height: 48px;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  color: ${(props) => (props.gradient ? COLORS.white : COLORS.secondary)};
  border: 1px solid ${COLORS.secondary};
  border-radius: 2px;
  margin-top: 16px;
  background: ${(props) => (props.gradient ? COLORS.linearGradient : 'unset')};
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 160px;
    margin-top: 24px;
    margin-right: 24px;
  }
`;
