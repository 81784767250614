import React from 'react';

import { DefaultLayout } from 'layouts';

import MarketComponent from 'components/market';
import ScrollToTopOnMount from 'components/base-ui/scroll-to-top';
import { useMatomo } from '@datapunt/matomo-tracker-react';

const MarketPage: React.FC = () => {
  const { trackPageView } = useMatomo();

  React.useEffect(() => {
    document.title = 'Маркет';
    trackPageView({});
  }, []);

  return (
    <>
      <ScrollToTopOnMount />

      <DefaultLayout>
        <MarketComponent />
      </DefaultLayout>
    </>
  );
};

export default MarketPage;
