import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';

export const Container = styled.li<{ isSub?: boolean; hasChildren: boolean }>`
  border-radius: 4px;
  padding: 16px;
  color: ${COLORS.gray3};
  margin-bottom: ${(props) => (props.isSub || props.hasChildren ? '15px' : '30px')};
  margin-left: ${(props) => (props.isSub ? '20px' : '0')};
  background-color: ${COLORS.gray6};

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin-left: ${(props) => (props.isSub ? '50px' : '0')};
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    margin-left: ${(props) => (props.isSub ? '70px' : '0')};
  }
`;

export const Grid = styled.div`
  display: grid;
  row-gap: 6px;
  grid-template-rows: auto 1fr;
`;

export const SubCommentsShowMoreButton = styled.div`
  color: ${COLORS.primary};
  cursor: pointer;
  margin-bottom: 30px;
  margin-left: 20px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin-left: 50px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    margin-left: 70px;
  }
`;
