import React from 'react';
import IconButton from 'components/base-ui/icon-button';
import { StModalCloseWrapper, StModalContainer, StModalMask, StModalOverflow, StModalWindow } from './styled';

interface Props {
  onCancel: () => void;
}

export const VerifyModal: React.FC<Props> = (props) => {
  return (
    <StModalOverflow>
      <StModalMask>
        <StModalContainer>
          <StModalWindow>
            <StModalCloseWrapper>
              <IconButton name="iconCrossBase" onClick={props.onCancel} />
            </StModalCloseWrapper>
            {props.children}
          </StModalWindow>
        </StModalContainer>
      </StModalMask>
    </StModalOverflow>
  );
};
