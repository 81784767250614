import React, { useEffect, useState } from 'react';

import { LoginComponent } from 'components/login';
import ScrollToTopOnMount from 'components/base-ui/scroll-to-top';
import { LoginLayout } from 'layouts';
import { useHistory, useLocation } from 'react-router-dom';
import { useQueryParams } from 'utils/hooks';
import { useAuthStore } from 'store';

const LoginPage: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const { ldap } = useQueryParams<{ ldap: string }>();
  const store = useAuthStore();

  useEffect(() => {
    document.title = 'Платформа АССОЛЬ';
  }, []);

  const [active, setActive] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setActive(() => true), 1000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (location.pathname.startsWith('/ldap-auth')) {
      store.checkToken(ldap).then((response) => {
        if (response === 'success') {
          history.push('/');
        } else history.push('/login');
      });
    }
  }, [location.pathname, ldap]);

  if (location.pathname.startsWith('/ldap-auth')) return null;

  return (
    <>
      <ScrollToTopOnMount />

      <LoginLayout collapsed={!active}>
        <LoginComponent active={active} />
      </LoginLayout>
    </>
  );
};

export default LoginPage;
