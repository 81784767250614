import React, { createContext, useContext } from 'react';

import ApplicationStore from './store';

const store = new ApplicationStore();

const ApplicationStateContext = createContext<ApplicationStore>(store);

export const ApplicationStateProvider: React.FC = ({ children }) => (
  <ApplicationStateContext.Provider value={store}>{children}</ApplicationStateContext.Provider>
);

export const useApplicationStore = (): ApplicationStore => useContext(ApplicationStateContext);
