import React, { createContext, useContext } from 'react';
import MainPageStore from './store';

const store = new MainPageStore();

const MainPageStateContext = createContext<MainPageStore>(store);

export const MainPageStateProvider: React.FC = ({ children }) => (
  <MainPageStateContext.Provider value={store}>{children}</MainPageStateContext.Provider>
);

export const useMainPageStore = (): MainPageStore => useContext(MainPageStateContext);
