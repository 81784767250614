import React, { useCallback } from 'react';
import { Container } from 'components/base-ui/container';
import { IconSvg } from 'assets/icons';
import { Styled } from '../../styles';
import { ContentType } from '../../types';

interface SpeedContentProps {
  speed: number;
  setSpeed: (value: number) => void;
  setContent: React.Dispatch<React.SetStateAction<ContentType>>;
}

export const SpeedContent: React.VFC<SpeedContentProps> = (props) => {
  const speed = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2];

  const handleOnBackClick = useCallback(() => {
    props.setContent('main');
  }, []);

  return (
    <Container direction="column">
      <Styled.Title>
        <Styled.IconButton size={16} onClick={handleOnBackClick}>
          <IconSvg.ArrowLeft />
        </Styled.IconButton>
        <Styled.TitleText>Скорость</Styled.TitleText>
      </Styled.Title>

      {speed.map((value) => (
        <Styled.PopperButton
          key={value}
          onClick={() => {
            props.setSpeed(value);
            props.setContent('main');
          }}
        >
          <IconSvg.CheckSmall style={{ visibility: value === props.speed ? 'visible' : 'hidden' }} />
          <Styled.Value> {value === 1 ? 'Обычная' : `x${value}`}</Styled.Value>
        </Styled.PopperButton>
      ))}
    </Container>
  );
};
