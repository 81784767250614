import React from 'react';
import { Styled } from './styles';

export function TagItem(props: { text: string; active?: boolean; onClick: () => void }) {
  return (
    <Styled.Container onClick={props.onClick} active={props.active}>
      <Styled.Text>{props.text}</Styled.Text>
    </Styled.Container>
  );
}
