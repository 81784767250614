import React, { useMemo } from 'react';
import AnimateHeight from 'react-animate-height';
import styled, { css } from 'styled-components';

import { Application, BankingApplication, MediaApplication } from '../../../../../services';

import { useToggle } from '../../../../../utils/hooks';
import { formatDate, formatTime } from '../../../../../utils/date';
import { BREAKPOINTS, COLORS } from '../../../../../utils/constants';

import { H6, Small, Span } from '../../../../base-ui/typo';
import IconButton from '../../../../base-ui/icon-button';
import HtmlContainer from '../../../../base-ui/html-container';
import { HtmlRenderer } from '../../../../../utils/htmlParser';

const Container = styled.li`
  margin-bottom: 0;
  position: relative;
  color: ${COLORS.gray3};
  border-bottom: 1px solid ${COLORS.gray5};

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    display: flex;
    align-items: flex-start;
    /* column-gap: 16px; */
    /* grid-template-columns: 1fr auto; */
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    /* column-gap: 128px; */
    /* max-height: 480px; */
  }
`;

const Content = styled.div`
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    flex: 1;
    /* padding-right: 16px; */
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    //max-height: 480px;
    /* padding-right: 128px; */
  }
`;

const ItemWrapper = styled.div`
  display: inline-flex;
  flex-wrap: nowrap;
  margin-right: 8px;
  align-items: flex-start;
  grid-auto-flow: column;
`;

const ItemKey = styled(Span)`
  margin-right: 8px;
  white-space: nowrap;
  color: ${COLORS.secondary};
`;

const ItemValue = styled(Small)`
  line-height: 24px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    white-space: nowrap;
  }
`;

interface HeadProps {
  active?: boolean;
}

const Head = styled.div<HeadProps>`
  position: relative;
  padding: 36px 0 20px 0;
  transition: border 0.5s ease-out;
  max-width: calc(100% - 42px - 16px);
  transition: border-color 0.5s ease-out;

  border-bottom: 1px solid;
  border-color: ${(props) => (props.active ? COLORS.gray5 : 'transparent')};

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    display: grid;
    max-width: unset;
    column-gap: 14px;
    padding: 28px 0 24px 0;
    grid-auto-flow: column;
    grid-template-columns: 48px min-content min-content min-content;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    column-gap: 16px;
    grid-template-columns: 176px min-content min-content min-content;
  }
`;

const HeadingWrapper = styled.div``;

const Heading = styled(H6)`
  font-weight: 400;
  color: ${COLORS.secondary};
  transition: margin 0.5s ease-out;
`;

const HeadItem = styled(ItemWrapper)``;

const HeadItemName = styled(ItemKey)``;

const HeadItemText = styled(ItemValue)``;

interface BodyProps {
  active?: boolean;
}

const Body = styled.div<BodyProps>`
  position: relative;
  padding: 22px 0 36px 0;
  color: ${COLORS.gray2};
  border-bottom: 1px solid ${COLORS.gray5};

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    display: grid;
    row-gap: 18px;
    column-gap: 48px;
    grid-template-columns: repeat(2, 1fr);
    padding: 22px 0 32px 0;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    display: block;
    padding: 22px 32px 48px 192px;
  }
`;

const BodyItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 4px;
  flex-direction: column;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    flex-direction: row;
  }
`;

const BodyItemProperty = styled(ItemWrapper)`
  margin-right: 16px;
`;

const BodyItemPropertyKey = styled(ItemKey)``;

const BodyItemPropertyValue = styled(ItemValue)``;

const StyledHtmlContainer = styled(HtmlContainer)`
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    display: inline-flex;

    > * {
      margin-right: 0.5ch !important;
    }
  }
`;

const Footer = styled.div`
  position: relative;
  padding: 8px 32px 16px 0;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    display: grid;
    row-gap: 24px;
    column-gap: 16px;
    grid-template-columns: 176px 1fr;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    grid-template-columns: 176px 432px auto;
  }
`;

const FooterPickupPointKey = styled(ItemKey)`
  display: block;
  /* margin-bottom: 12px; */
`;

const FooterPickupPointValue = styled(ItemValue)`
  max-width: 432px;
  white-space: normal;
  margin-bottom: 20px;
`;

const FooterTotalPriceWrapper = styled.div`
  display: flex;
  align-items: center;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    grid-row: 2;
    grid-column: 1;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    grid-row: 1;
    grid-column: 3;
    display: flex;
    padding-right: 32px;
    justify-content: flex-end;
  }
`;

const FooterTotalPriceKey = styled(ItemKey)``;

const FooterTotalPriceValue = styled(ItemValue)``;

// const SubFooter = styled.div`
//   ${border};

//   display: flex;
//   position: relative;
//   flex-direction: column;
//   padding: 20px 32px 28px 0;
// `;

// const SubFooterHeading = styled(Micro)`
//   max-width: 289px;
//   margin-bottom: 8px;
//   color: ${COLORS.secondary};
// `;

// const SubFooterText = styled(Micro)`
//   max-width: 135px;
//   color: ${COLORS.primary};
// `;

const ToggleButtonWrapper = styled.div`
  @media screen and (max-width: calc(${BREAKPOINTS.tablet} - 1px)) {
    top: 24px;
    right: 0;
    position: absolute;
    padding-left: 32px;
  }

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 24px 32px;
    /* border-left: 1px solid ${COLORS.gray5}; */
  }
`;

interface ToggleButtonProps {
  active?: boolean;
}

const activeStyles = css`
  transform: rotate(-90deg);
`;

const ToggleButton = styled(IconButton)<ToggleButtonProps>`
  transform: rotate(90deg);
  transition: transform 0.5s ease-out;

  ${(props) => props.active && activeStyles}
`;

interface ItemProps {
  value?: boolean;
  application: Application | BankingApplication | MediaApplication;
}

const Item: React.FC<ItemProps> = ({ value, application }) => {
  const [active, toggleActive] = useToggle(value);

  const category = useMemo(() => {
    const [product] = 'applicationProducts' in application ? application.applicationProducts : [];
    return product ? product.productObject?.category : '';
  }, [application]);

  const totalPrice = useMemo(
    () =>
      'applicationProducts' in application
        ? application.applicationProducts.reduce((a, cv) => {
            const discountPrice = cv.productObject?.discountPrice ? +cv.productObject.discountPrice : null;
            const price = cv.productObject?.price ? +cv.productObject.price : null;
            return a + (discountPrice || price || 0) * cv.count;
          }, 0)
        : application.productObject?.price || null,
    [application],
  );

  return (
    <Container>
      <Content>
        <Head active={active}>
          <HeadingWrapper>
            <Heading>№{application.id}</Heading>
          </HeadingWrapper>

          <HeadItem>
            <HeadItemName>Дата оформления</HeadItemName>
            <HeadItemText>{formatDate(application.created)}</HeadItemText>
          </HeadItem>

          <HeadItem>
            <HeadItemName>Время</HeadItemName>
            <HeadItemText>{formatTime(application.created)}</HeadItemText>
          </HeadItem>

          <HeadItem>
            <HeadItemName>Категория</HeadItemName>
            <HeadItemText>{category || ''}</HeadItemText>
          </HeadItem>
        </Head>

        <AnimateHeight duration={500} height={active ? 'auto' : 0}>
          <Body active={active}>
            {'applicationProducts' in application &&
              application.applicationProducts.map((p) => (
                <BodyItem>
                  <BodyItemProperty>
                    <BodyItemPropertyKey>Название:</BodyItemPropertyKey>
                    <BodyItemPropertyValue>
                      <StyledHtmlContainer marginless>
                        <HtmlRenderer html={p.productObject?.name || ''} />
                      </StyledHtmlContainer>
                    </BodyItemPropertyValue>
                  </BodyItemProperty>

                  {/* <BodyItemProperty> */}
                  {/*  <BodyItemPropertyKey>Завод:</BodyItemPropertyKey> */}
                  {/*  <BodyItemPropertyValue>Название завода</BodyItemPropertyValue> */}
                  {/* </BodyItemProperty> */}

                  <BodyItemProperty>
                    <BodyItemPropertyKey>Кол-во:</BodyItemPropertyKey>
                    <BodyItemPropertyValue>{p.count}</BodyItemPropertyValue>
                  </BodyItemProperty>

                  <BodyItemProperty>
                    <BodyItemPropertyKey>Стоимость:</BodyItemPropertyKey>
                    <BodyItemPropertyValue>
                      {p.productObject?.discountPrice || p.productObject?.price || ''}
                    </BodyItemPropertyValue>
                  </BodyItemProperty>
                </BodyItem>
              ))}
          </Body>

          <Footer>
            {application.shop && (
              <>
                <FooterPickupPointKey>Пункт самовывоза:</FooterPickupPointKey>

                <FooterPickupPointValue>
                  <div>{application.shopObject?.name || ''}</div>
                  <div>{application.shopObject?.address || ''}</div>
                </FooterPickupPointValue>
              </>
            )}

            <FooterTotalPriceWrapper>
              <FooterTotalPriceKey>Сумма:</FooterTotalPriceKey>
              <FooterTotalPriceValue>{totalPrice} руб.</FooterTotalPriceValue>
            </FooterTotalPriceWrapper>
          </Footer>

          {/* <SubFooter>
            <SubFooterHeading>Не получили ответа по заявке? Контактные данные: </SubFooterHeading>
            <SubFooterText>+7 000 000 00 00 Имя Фамилия email@corporatemail.ru</SubFooterText>
          </SubFooter> */}
        </AnimateHeight>
      </Content>

      <ToggleButtonWrapper>
        <ToggleButton name="iconPointer" round active={active} small onClick={toggleActive} />
      </ToggleButtonWrapper>
    </Container>
  );
};

export default Item;
