import React from 'react';

import { DefaultLayout } from '../../../layouts';

import { BlogItemComponent } from '../../../components/blog';
import ScrollToTopOnMount from '../../../components/base-ui/scroll-to-top';
import { GuideCommentsStateProvider } from '../../../store/guide-comments';

const BlogReadPage: React.FC = () => {
  return (
    <>
      <ScrollToTopOnMount />

      <GuideCommentsStateProvider>
        <DefaultLayout>
          <BlogItemComponent />
        </DefaultLayout>
      </GuideCommentsStateProvider>
    </>
  );
};

export default BlogReadPage;
