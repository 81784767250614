import React from 'react';
import { useCarousel } from 'utils/hooks';
import { Container, Image, Item } from './styled';
import { ItemType } from '../../index';

type Props = {
  data: ItemType[];
  onClick: (data: ItemType) => void;
};

export const MobileCarousel: React.FC<Props> = ({ data, onClick }) => {
  const { list } = useCarousel(data);

  return (
    <Container>
      {list.map((item, index) => (
        <Item key={index} onClick={() => onClick(item)}>
          <Image src={item.image} />
        </Item>
      ))}
    </Container>
  );
};
