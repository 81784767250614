import styled from 'styled-components';
import { COLORS } from 'utils/constants';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 420px;
`;

export const ImageWrapper = styled.div`
  width: 420px;
  height: 420px;
`;
export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
export const Header = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: ${COLORS.secondary};
  margin-top: 8px;
`;
export const SubHeader = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: ${COLORS.gray3};
  margin-bottom: 8px;
`;
export const Text = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${COLORS.gray3};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  line-clamp: 5;
`;
