import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';

export const StLike = styled.div<{ iconWidth: string; iconHeight: string }>`
  width: ${(props) => props.iconWidth};
  height: ${(props) => props.iconHeight};
  cursor: pointer;
  position: relative;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  user-select: none;
`;

export const StCount = styled.div<{
  showTooltip: boolean;
  color?: string;
  left?: string;
  leftMobile?: string;
  arrowLeft?: string;
  arrowLeftMobile?: string;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 24px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: ${(props) => props.color || COLORS.gray4};
  margin: 0 10px;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  overflow: visible;

  span {
    visibility: ${(props) => (props.showTooltip ? 'visible' : 'hidden')};
    opacity: ${(props) => (props.showTooltip ? 1 : 0)};
    max-width: 320px;
    background-color: ${COLORS.black};
    color: ${COLORS.white};
    border-radius: 4px;
    padding: 8px 16px;
    position: absolute;
    z-index: 999;
    bottom: 200%;
    left: ${(props) => props.leftMobile || '-30px'};
    :after {
      content: '';
      position: absolute;
      top: 100%;
      left: ${(props) => props.arrowLeftMobile || '65px'};
      border-width: 5px;
      border-style: solid;
      border-color: black transparent transparent transparent;
    }
    :hover {
      visibility: visible;
      opacity: 1;
    }
    transition: all 300ms 50ms;
  }

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    span {
      max-width: 382px;
      left: ${(props) => props.left || '-30px'};
      :after {
        left: ${(props) => props.arrowLeft || '65px'};
      }
    }
  }
`;
