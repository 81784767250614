import React, { useRef, RefObject } from 'react';
import * as d3 from 'd3';
import { cross3 } from '@thi.ng/vectors';
import styled from 'styled-components';

import { useD3, useWindowDimensions } from '../../../utils/hooks';
import { measureTextWidthOnCanvas, randomNumberUntilMax } from '../../../utils/functions';
import { COLORS } from '../../../utils/constants';

const COMPANIES_GRAPH_CONFIG = Object.freeze({
  debug: false,
  fontSize: 12,
  lineHeight: 14,
  forceStrength: -50,
  maxBigNodeLevel: 2,
  defaultCollideRadius: 5,
  defaultRootNodeVelocity: 0.05,
});

const Container = styled.div`
  position: relative;
`;

const Canvas = styled.canvas`
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  opacity: 0;
  position: absolute;
  visibility: hidden;
  font-size: ${COMPANIES_GRAPH_CONFIG.fontSize}px;
  line-height: ${COMPANIES_GRAPH_CONFIG.lineHeight}px;
`;

const SVG = styled.svg`
  width: 100%;
  height: 896px;
  margin: 0 auto;
  display: block;

  .gnode text {
    font-size: ${COMPANIES_GRAPH_CONFIG.fontSize}px;
    line-height: ${COMPANIES_GRAPH_CONFIG.lineHeight}px;
  }

  // ONLY FOR DEBUG: Stroke shapes of 'g' tags
  g {
    outline: ${() => (COMPANIES_GRAPH_CONFIG.debug ? '1px solid red' : 'none')};
  }
`;

interface GraphData {
  name: string;
  level: number;
  value?: number;
  children?: GraphData[];
}

type NodeDatum = d3.HierarchyNode<GraphData> & d3.SimulationNodeDatum;
type LinkDatum = d3.SimulationLinkDatum<NodeDatum>;

const forceCircularMotion = () => {
  let nodes: NodeDatum[];

  const force = () => {
    const rootNode = nodes.find((el) => el.data.level === 1);

    if (rootNode && rootNode.x !== undefined && rootNode.y !== undefined) {
      const vect = cross3(
        null,
        [0, 0, COMPANIES_GRAPH_CONFIG.defaultRootNodeVelocity],

        [rootNode.x, rootNode.y, 0],
      );

      rootNode.vx = vect[0];
      rootNode.vy = vect[1];
    }
  };

  force.initialize = (arr: NodeDatum[]) => {
    nodes = arr;
  };

  return force;
};

const isNodeBig = (level: number) => level <= COMPANIES_GRAPH_CONFIG.maxBigNodeLevel;

const getNodeMetaByLevel = (level: number): { r: number; distance: number } => {
  switch (level) {
    case 1:
      return { r: 74, distance: 125 };
    case 2:
      return { r: 56, distance: 75 + randomNumberUntilMax(75) };
    default:
      return { r: 7.5, distance: 30 + randomNumberUntilMax(30) };
  }
};

const getNumberOfTextLines = (name: string) => name.split(' ').length;

const getNodeTextHeight = (name: string) => getNumberOfTextLines(name) * COMPANIES_GRAPH_CONFIG.lineHeight;

const getNodeCollisionRadius = ({ name, level }: GraphData, ref: RefObject<HTMLCanvasElement>): number => {
  const { r: circleRadius } = getNodeMetaByLevel(level);

  const textCollisionRadius = Math.ceil(
    Math.max(...name.split(' ').map((el) => measureTextWidthOnCanvas(el, ref) || 0)) / 2,
  );

  const circleCollisionRadius = isNodeBig(level) ? circleRadius : circleRadius + getNodeTextHeight(name) / 2;

  const radius = Math.max(textCollisionRadius, circleCollisionRadius);

  return radius + COMPANIES_GRAPH_CONFIG.defaultCollideRadius;
};

// Separate name of node by words
const getNodeHtmlText = ({ name, level }: GraphData): string => {
  const isBig = isNodeBig(level);
  const textHeight = getNodeTextHeight(name) / 2;
  const { r: circleRadius } = getNodeMetaByLevel(level);

  const dy = isBig ? getNumberOfTextLines(name) * -4 : COMPANIES_GRAPH_CONFIG.lineHeight;

  const getY = (index: number): number => {
    return isBig
      ? index * COMPANIES_GRAPH_CONFIG.lineHeight
      : index * COMPANIES_GRAPH_CONFIG.lineHeight - textHeight + circleRadius;
  };

  return name
    .split(' ')
    .map((el, idx) => `<tspan x="0px" dy=${dy}px y=${getY(idx)}px>${el}</tspan>`)
    .join(' ');
};

const forceCustomBoundary = (width: number, height: number, ref: RefObject<HTMLCanvasElement>) => {
  let nodes: NodeDatum[];

  const force = () => {
    nodes.forEach((node) => {
      const radius = getNodeCollisionRadius(node.data, ref);

      node.x = Math.max(-width / 2 + radius, Math.min(width / 2 - radius, node.x || 0));
      node.y = Math.max(-height / 2 + radius, Math.min(height / 2 - radius, node.y || 0));
    });
  };

  force.initialize = (arr: NodeDatum[]) => {
    nodes = arr;
  };

  return force;
};

const drag = <T extends Element>(
  simulation: d3.Simulation<NodeDatum, LinkDatum>,
  [width, height]: [number, number],
  ref: RefObject<HTMLCanvasElement>,
) => {
  const validateCoordinate = (x: number, min: number, max: number): number => {
    if (x < min) return min;
    if (x > max) return max;
    return x;
  };

  const onDragStart = (event: d3.D3DragEvent<T, d3.SimulationNodeDatum, NodeDatum>, d: d3.SimulationNodeDatum) => {
    if (!event.active) simulation.alphaTarget(0.3).restart();
    d.fx = d.x;
    d.fy = d.y;
  };

  const onDrag = (event: d3.D3DragEvent<T, d3.SimulationNodeDatum, NodeDatum>, d: d3.SimulationNodeDatum) => {
    const radius = getNodeCollisionRadius(event.subject.data, ref);

    d.fx = validateCoordinate(event.x, -width / 2 + radius, width / 2 - radius);
    d.fy = validateCoordinate(event.y, -height / 2 + radius, height / 2 - radius);
  };

  const onDragEnd = (event: d3.D3DragEvent<T, d3.SimulationNodeDatum, NodeDatum>, d: d3.SimulationNodeDatum) => {
    if (!event.active) simulation.alphaTarget(0);
    d.fx = null;
    d.fy = null;
  };

  return d3.drag<T, NodeDatum>().on('start', onDragStart).on('drag', onDrag).on('end', onDragEnd);
};

// eslint-disable-next-line prettier/prettier
const isNodeObject = <T, >(node: number | string | T): node is T => {
  return typeof node !== 'number' && typeof node !== 'string';
};

interface CompaniesGraphProps {
  data: GraphData;
}

const CompaniesGraph: React.FC<CompaniesGraphProps> = ({ data }) => {
  const height = 896;
  const { width } = useWindowDimensions();

  const canvasRef: RefObject<HTMLCanvasElement> = useRef<HTMLCanvasElement | null>(null);

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const svgRef = useD3<SVGSVGElement, d3.SimulationNodeDatum>((svg) => {
    const root = d3.hierarchy(data);
    const links: LinkDatum[] = root.links();
    const nodes: NodeDatum[] = root.descendants();

    const simulation = d3
      .forceSimulation<NodeDatum, LinkDatum>(nodes)
      .force('circular', forceCircularMotion())
      .force('boundary', forceCustomBoundary(width, height, canvasRef))
      .force('charge', d3.forceManyBody().strength(COMPANIES_GRAPH_CONFIG.forceStrength))
      .force(
        'link',
        d3
          .forceLink(links)
          .distance((d) => (isNodeObject(d.source) ? getNodeMetaByLevel(d.source.data.level).distance : 0))
          .strength(0.1),
      )
      .force(
        'collide',
        d3
          .forceCollide<d3.SimulationNodeDatum & { data: GraphData }>()
          .radius((d) => getNodeCollisionRadius(d.data, canvasRef)),
      )
      .force('x', d3.forceX().strength(0.001))
      .force('y', d3.forceY().strength(0.001))
      // .force('center', d3.forceCenter(0.001));
      .force('center', d3.forceCenter())
      .alphaDecay(0);

    svg.attr('viewBox', [-width / 2, -height / 2, width, height].join(' '));

    // Create the links between nodes
    const link = svg
      .selectAll('line')
      .data(links)
      .join('line')
      // Hide links coming out of root element
      .attr('stroke', (d) => (isNodeObject(d.source) && d.source.data.level === 1 ? 'none' : COLORS.cloud2))
      .attr('stroke-opacity', 0.4);

    // Create the groups under svg
    const gnodes = svg
      .selectAll('g.gnode')
      .data(nodes)
      .enter()
      .append('g')
      .classed('gnode', true)
      .call(drag(simulation, [width, height], canvasRef));

    // ONLY FOR DEBUG: Stroke actual collide radius of node
    if (COMPANIES_GRAPH_CONFIG.debug) {
      gnodes
        .append('circle')
        .attr('fill', 'none')
        .attr('stroke', 'green')
        .attr('strokeWidth', '1')
        .attr('r', (d) => getNodeCollisionRadius(d.data, canvasRef));
    }

    // Add one circle in each group
    gnodes
      .append('circle')
      .attr('class', 'node')
      .attr('fill', COLORS.primary)
      .attr('r', (d) => getNodeMetaByLevel(d.data.level).r)
      .attr('cy', (d) => (isNodeBig(d.data.level) ? 0 : (getNodeTextHeight(d.data.name) / 2) * -1));

    // Append the labels to each group
    gnodes
      .append('text')
      .html((d) => getNodeHtmlText(d.data))
      .attr('text-anchor', 'middle')
      .style('fill', (d) => (isNodeBig(d.data.level) ? COLORS.white : COLORS.secondary));

    simulation.on('tick', () => {
      // Update the links
      link
        .attr('x1', (d) => (isNodeObject(d.source) && d.source.x) || 0)
        .attr('y1', (d) => (isNodeObject(d.source) && d.source.y) || 0)
        .attr('x2', (d) => (isNodeObject(d.target) && d.target.x) || 0)
        .attr('y2', (d) => (isNodeObject(d.target) && d.target.y) || 0);

      // Translate the groups
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      gnodes.attr('transform', (d) => `translate(${[d.x, d.y]})`);
    });
  }, []);

  return (
    <Container>
      <SVG ref={svgRef} />
      <Canvas ref={canvasRef} />
    </Container>
  );
};

export default CompaniesGraph;
