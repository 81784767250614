import React from 'react';
import { ProfileLike } from 'services';
import { declationsOfNumerals } from 'utils/helpers';
import avatar from 'assets/img/assol_avatar.png';
import { StAvatarWrapper, StContainer, StContent, StLikesCount, StText } from './styled';

interface Props {
  likedUsers: ProfileLike[];
  likesCount: number;
  onShowModal: () => void;
}

const DEC = ['человеку', 'людям', 'людям'];

export const TooltipContent: React.FC<Props> = (props) => {
  return (
    <StContainer>
      <StText onClick={props.onShowModal}>
        {`Понравилось ${props.likesCount} ${declationsOfNumerals(props.likesCount, DEC)}`}
      </StText>
      <StContent>
        {props.likedUsers.map((item) => (
          <StAvatarWrapper
            key={item.id}
            background={item.image && item.image.length > 0 ? item.image : avatar}
            title={item.fullName || item.email}
          />
        ))}
        {props.likesCount > 6 && (
          <StLikesCount onClick={props.onShowModal}>{`+${props.likesCount - props.likedUsers.length}`}</StLikesCount>
        )}
      </StContent>
    </StContainer>
  );
};
