import React from 'react';
import { AdjustableButton } from 'components/base-ui/button';
import { Container } from 'components/base-ui/container';
import { Styled } from '../styles';

export const ContactsInfo: React.VFC<{
  title?: string | null;
  phone?: string | null;
  description?: string | null;
  buttonText?: string | null;
  onButtonClick: () => void;
}> = (props) => {
  if (!(props.title && props.phone && props.description && props.buttonText)) return null;
  return (
    <Styled.Container direction="column" gap="16px">
      <Container direction="column">
        {props.title && <Styled.Title>{props.title}</Styled.Title>}
        {props.phone && <Styled.Title>{props.phone}</Styled.Title>}
      </Container>
      {props.description && <Styled.Description>{props.description}</Styled.Description>}
      {props.buttonText && (
        <Styled.FeedbackButton onClick={props.onButtonClick} gradient borderless white>
          {props.buttonText}
        </Styled.FeedbackButton>
      )}
    </Styled.Container>
  );
};
