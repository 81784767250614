import styled from 'styled-components';
import { BASIC_FONT_FAMILY, BREAKPOINTS, COLORS } from '../../../utils/constants';
import iconCal from '../../../assets/icons/icon_cal.svg';
import iconArrow from '../../../assets/icons/icon_arrow.svg';

export const DatePickerWrapper = styled.div`
  .react-datepicker-wrapper {
    display: block;
  }
  .react-datepicker-popper {
    z-index: 9 !important;
  }

  input {
    display: block;
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    padding: 7px 12px;
    border-radius: 2px;
    transition: all 0.2s ease-out;
    font-family: ${BASIC_FONT_FAMILY};
    color: ${COLORS.gray2};
    border: 1px solid ${COLORS.gray5};
    background-color: ${COLORS.white};
    background-repeat: no-repeat;
    background-position: center right 9px;

    &::placeholder {
      color: ${COLORS.gray4};
    }

    &:disabled {
      color: ${COLORS.gray4};

      &::placeholder {
        color: ${COLORS.gray6};
      }
    }
    &:not(.react-datepicker-time__input) {
      background-image: url(${iconCal});
    }
  }
`;

export const CalendarWrapper = styled.div`
  background-color: ${COLORS.white};
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.08);
  border-radius: 2px;
  font-size: 14px;
  line-height: 20px;

  .react-datepicker__day {
    border-radius: 2px;
    background-color: ${COLORS.primary};
    color: ${COLORS.white};
    font-weight: normal;
    font-size: 13px;
    width: 24px;
    height: 24px;
    @media screen and (min-width: ${BREAKPOINTS.tablet}) {
      font-size: 14px;
      width: 28px;
      height: 28px;
    }
  }

  .react-datepicker__day--selected {
    background-color: ${COLORS.secondary};
  }

  .react-datepicker__day:hover {
    background-color: ${COLORS.secondary};
    color: ${COLORS.white};
  }

  .react-datepicker__day--today {
    background-color: ${COLORS.primary};
    font-weight: bold;
    border: 1px solid ${COLORS.secondary};
  }

  .react-datepicker__day--in-range {
    background-color: ${COLORS.secondary};
    color: ${COLORS.white};
  }

  .react-datepicker__day--in-selecting-range {
    background-color: ${COLORS.secondary};
    color: ${COLORS.white};
  }

  .react-datepicker__day--today.react-datepicker__day--disabled {
    background-color: ${COLORS.white};
    border: 1px solid ${COLORS.secondary};
    color: ${COLORS.secondary};
    font-weight: bold;
  }

  .react-datepicker__day--disabled {
    background-color: ${COLORS.white};
    color: ${COLORS.secondary};
  }
  .react-datepicker__day--disabled:hover {
    background-color: ${COLORS.white};
    color: ${COLORS.secondary};
  }

  .react-datepicker__day--outside-month {
    color: ${COLORS.gray5};
    background-color: ${COLORS.white};
  }
  .react-datepicker__day--outside-month:hover {
    color: ${COLORS.gray5};
  }

  .react-datepicker__header {
    background-color: ${COLORS.white};
    border: none;
  }
  .react-datepicker__today-button {
    background-color: ${COLORS.white};
    padding: 19px;
    border-top: 1px solid ${COLORS.gray5};
    font-weight: normal;
    color: ${COLORS.secondary};
  }
  .react-datepicker__current-month {
    padding: 18px;
    border-bottom: 1px solid ${COLORS.gray5};
  }
  .react-datepicker__day-names {
    margin: 12px 36px 10px 36px;
    border-bottom: 1px solid ${COLORS.gray5};
  }

  .react-datepicker__day--highlighted {
      background-color: ${COLORS.secondary};
  }

  .react-datepicker__month-container {
    padding-bottom: 10px;
  }
  .react-datepicker__navigation {
    width: 25px;
    height: 25px;
    background: url(${iconArrow}) no-repeat center;
    top: 24px;
    &--next {
      right: 16px;
    }
    &--previous {
      transform: rotate(180deg);
      left: 16px;
      top: 25px;
    }
  }
    .react-datepicker__navigation-icon {
        opacity: 0;
    }
  .react-datepicker__current-month {
    font-weight: normal;
    color: ${COLORS.secondary};
    font-size: 14px;
  }
  .react-datepicker-time__caption{
    display: none;
  }
  .react-datepicker__input-time-container, .react-datepicker-time__input-container, .react-datepicker-time__input{
    margin: 0 !important;
  }
  .react-datepicker-time__input-container, .react-datepicker-time__input,
  .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input{
    width:100%;
  }
  .react-datepicker-time__input {
    ::-webkit-calendar-picker-indicator{opacity: .4};
   }
  }
`;

export const InputLabel = styled.label`
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 4px;
  display: block;
`;
