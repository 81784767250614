import React from 'react';
import chevronDown from 'assets/icons/chevron_down.svg';
import { IconWrapper, Text, Wrapper } from './styled';

type Props = {
  text: string;
};

export const Header: React.FC<Props> = ({ text }) => {
  return (
    <Wrapper>
      <Text>{text}</Text>
      <IconWrapper>
        <img className="chevron-down" src={chevronDown} alt="Chevron Down" />
      </IconWrapper>
    </Wrapper>
  );
};
