//@ts-nocheck
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import {COLORS} from "utils/constants";

const BlockSearch = styled.section`
  width: 100%;
  padding: 0 30px;
  margin-bottom: 64px;
  background: ${COLORS.linearGradient}
  position: relative;
  z-index: 5;
  input {
    height: 50px !important;
  }
  .sfl-submit-button .sfl-submit-button-input .standard-view-button {
    background-color: ${COLORS.secondary};
    background-image: ${COLORS.linearGradient} !important;
  }
`;

const TravelLineSearchForm = styled.div`
  width: 100%;
  max-width: 1290px;
  margin: 0 auto;
`;

type TravelLineType = {
  name?: string;
};

export const TravelLineWidget: React.FC<TravelLineType> = (props: TravelLineType) => {
  const loadWidget = (w: Window) => {
    const q = [
      ['setContext', 'TL-INT-white-nights-mmc_2022-11-23', 'ru'],
      [
        'embed',
        'search-form',
        {
          container: 'tl-search-form',
        },
      ],
    ];
    // @ts-ignore
    const t = (w.travelline = w.travelline || {});
    const h = ['ru-ibe.tlintegration.ru', 'ibe.tlintegration.ru', 'ibe.tlintegration.com'];
    const ti = (t.integration = t.integration || {});
    ti.__cq = ti.__cq ? ti.__cq.concat(q) : q;
    if (!ti.__loader) {
      ti.__loader = true;
      const d = w.document;
      var c = d.getElementsByTagName('head')[0] || d.getElementsByTagName('body')[0];
      //@ts-ignore
      function e(s, f) {
        return function() {
          // @ts-expect-error
          w.TL || (c.removeChild(s), f());
        };
      }
      (function l(h) {
        if (h.length === 0) return;
        const s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = !0;
        s.src = `https://${h[0]}/integration/loader.js`;
        s.onerror = s.onload = e(s, () => {
          l(h.slice(1, h.length));
        });
        c.appendChild(s);
      })(h);
    }
  };

  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    loadWidget(window);
  }, [ref.current]);

  return (
    <BlockSearch id="tl-search-form" ref={ref}>
      <TravelLineSearchForm id="tl-search-form" className="tl-container">
        <noindex>
          <a target="_blank" href="http://www.travelline.ru/products/tl-hotel/" rel="nofollow">
            Модуль онлайн-бронирования
          </a>
        </noindex>
      </TravelLineSearchForm>
    </BlockSearch>
  );
};
