import { makeObservable, observable, action, computed } from 'mobx';
import _ from 'lodash';

import { ListRequestParams } from 'web';

import { Application, CartProduct, Profile, Cart, WineCartInstruction, ErrorScreenRelated } from 'services';

import { api } from 'utils/api';
import AbstractStore from '../AbstractStore';

export default class CartStore extends AbstractStore {
  cart: Cart = {} as Cart;

  cartProducts: CartProduct[] = [];

  count = 0;

  showCart = true;

  application: Application | null = null;

  successAction = false;

  wineInstruction: WineCartInstruction = {} as WineCartInstruction;

  errorScreen: ErrorScreenRelated | null = null;

  errorScreenId = 0;

  constructor() {
    super();
    makeObservable(this, {
      ...this.annotations,
      cart: observable,
      cartProducts: observable,
      wineInstruction: observable,
      count: observable,
      showCart: observable,
      successAction: observable,
      errorScreen: observable,
      fetchCart: action,
      setCartProducts: action,
      updateProductCount: action,
      removeProductFromCart: action,
      setWineInstruction: action,
      fetchWineInstruction: action,
      setCount: action,
      totalCartPrice: computed,
      editCount: action,
      addToCart: action,
      gropedCart: computed,
      application: observable,
      setApplication: action,
      initApplicationFromProfile: action,
      editApplication: action,
      createApplication: action,
      resetSuccessState: action,
      resetCard: action,
      fetchErrorScreen: action,
      setErrorScreen: action,
      closeErrorScreen: action,
    });
  }

  fetchCart(requestParams?: ListRequestParams) {
    this.fetchData(() =>
      api.cart.cartList(requestParams).then((res) => {
        this.setCart(res.data);
        if (res.data.products) this.setCartProducts(res.data.products);
        if (res.data.hasWine) this.fetchWineInstruction();
      }),
    );
  }

  fetchErrorScreen = () => {
    this.fetchData(() =>
      api.errorScreens.errorScreensList({ content: 'CART' }).then((res) => {
        if (res.data.results[0].needShow) {
          this.setErrorScreen(res.data.results[0].errorScreen as ErrorScreenRelated);
          this.setErrorScreenId(res.data.results[0].id || 0);
        } else this.setErrorScreen(null);
      }),
    );
  };

  closeErrorScreen = () => {
    this.fetchData(() =>
      api.closeErrorScreen.closeErrorScreenCreate({ data: { screen: +this.errorScreenId } }).then(() => {
        this.fetchErrorScreen();
      }),
    );
  };

  fetchWineInstruction = () => {
    api.wineCartInstruction.wineCartInstuctionList().then((res) => {
      this.setWineInstruction(res.data);
    });
  };

  initApplicationFromProfile = (profile: Profile) => {
    if (profile) {
      const application: Application = {
        email: profile.user?.email!,
        firstName: profile.firstName,
        lastName: profile.lastName,
        phone: profile.phone,
        patronymic: profile.patronymic,
        applicationProducts: [],
      };
      this.setApplication(application);
    }
  };

  get gropedCart() {
    return _.groupBy(this.cartProducts, 'product.category');
  }

  editApplication = <K extends keyof Application>(key: K, value: Application[K]) => {
    const application = this.application;
    if (application) {
      application[key] = value;
      this.setApplication(application);
    }
  };

  createApplication = (categoryId: number, callback?: () => void) => {
    if (this.application) {
      const categoryCart = this.cartProducts.filter((c) => Number(c.product?.category) === categoryId);
      const application = this.application;
      application.applicationProducts = categoryCart.map((c: CartProduct) => ({
        count: c.count,
        product: String(c.product?.id),
      }));
      this.fetchData(() =>
        api.applications
          .applicationCreate({ data: application })
          .then(() => {
            categoryCart.forEach((c: CartProduct) => this.removeProductFromCart(Number(c.id)));
          })
          .then(() => {
            if (callback) callback();
            this.successAction = true;
          }),
      );
    }
  };

  clearCart = () => {
    this.fetchData(() => api.cart.cartDelete().then(() => this.setCartProducts([])));
  };

  addToCart = (productId: number, count: number) => {
    this.fetchData(() =>
      api.cart.cartCartProductCreate({ data: { count, productId } }).then(() => this.fetchCartCount()),
    );
  };

  editCount = (cartProductId: number, productId: number, count: number) => {
    this.fetchData(() =>
      api.cart
        .cartCartProductPartialUpdate({ id: cartProductId, data: { count, productId } })
        .then((res) => this.updateProductCount(cartProductId, Number(res.data.count))),
    );
  };

  removeProductFromCart = (cartProductId: number) => {
    this.fetchData(() =>
      api.cart.cartCartProductDelete({ id: cartProductId }).then(() => {
        this.setCartProducts(this.cartProducts.filter((el) => el.id !== cartProductId));
        if (!this.cartProducts.length) this.clearCart();
      }),
    );
  };

  fetchCartCount() {
    this.fetchData(() =>
      api.cart.cartInfoList().then((res) => {
        this.setCount(res.data.count);
        this.setShowCart(res.data.enabled);
      }),
    );
  }

  get totalCartPrice() {
    return this.cartProducts.reduce(
      (acc, cv) => (acc += +(cv.product?.discountPrice ?? cv.product?.price ?? 0) * cv.count),
      0,
    );
  }

  setCount(count: number) {
    this.count = count;
  }

  setCart(cart: Cart) {
    this.cart = cart;
    this.fetchCartCount();
  }

  setCartProducts(cart: CartProduct[]) {
    this.cartProducts = cart;
    this.fetchCartCount();
  }

  setApplication(application: Application) {
    this.application = application;
  }

  setShowCart(value: boolean) {
    this.showCart = value;
  }

  updateProductCount = (cartProductId: number, count: number) => {
    this.setCartProducts(
      this.cartProducts.map((el) => {
        el.id === cartProductId && (el.count = count);
        return el;
      }),
    );
  };

  setWineInstruction = (data: WineCartInstruction) => {
    this.wineInstruction = data;
  };

  setErrorScreen = (data: ErrorScreenRelated | null) => {
    this.errorScreen = data;
  };

  setErrorScreenId = (value: number) => {
    this.errorScreenId = value;
  };

  resetCard = () => {
    this.setCart({} as Cart);
    this.setWineInstruction({} as WineCartInstruction);
  };

  resetSuccessState = () => {
    this.successAction = false;
  };
}
