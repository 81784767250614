import styled from 'styled-components';
import { Small } from 'components/base-ui/typo';
import { COLORS } from 'utils/constants';

export const StSmall = styled(Small)`
  word-break: break-word !important;
`;

export const ShowMore = styled.span`
  color: ${COLORS.primary};
  cursor: pointer;
`;
