import React, { createContext, useContext } from 'react';

import SearchStore from './store';

const store = new SearchStore();

const SearchStateContext = createContext<SearchStore>(store);

export const SearchStateProvider: React.FC = ({ children }) => (
  <SearchStateContext.Provider value={store}>{children}</SearchStateContext.Provider>
);

export const useSearchStore = (): SearchStore => useContext(SearchStateContext);
