import React, { createContext, useContext } from 'react';

import UnderConstructionStore from './store';

const store = new UnderConstructionStore();

const UnderConstructionStateContext = createContext<UnderConstructionStore>(store);

export const UnderConstructionStateProvider: React.FC = ({ children }) => (
  <UnderConstructionStateContext.Provider value={store}>{children}</UnderConstructionStateContext.Provider>
);

export const useUnderConstructionStore = (): UnderConstructionStore => useContext(UnderConstructionStateContext);
