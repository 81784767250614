import React, { useMemo } from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import styled from 'styled-components';

const AnchorLink = styled.a``;

interface LinkProps extends Omit<RouterLinkProps, 'component'> {
  to: string;
  className?: string;
  onClick?: () => void;
}

const Link: React.FC<LinkProps> = ({ to, className, onClick, children, ...props }) => {
  const isAbsolute = useMemo(() => {
    const r = /^(?:[a-z]+:)?\/\//i;
    return r.test(to);
  }, [to]);

  return isAbsolute ? (
    <AnchorLink href={to} className={className} onClick={onClick} {...props}>
      {children}
    </AnchorLink>
  ) : (
    <RouterLink to={to} className={className} onClick={onClick} {...props}>
      {children}
    </RouterLink>
  );
};

export default Link;
