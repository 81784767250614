import { makeAutoObservable } from 'mobx';

import { Nullable } from 'web';

import { api } from 'utils/api';

import { AwardNominations, AwardParticipants, AwardParticipantsList } from 'services';

export default class AssolParticipantsStore {
  fetching = false;

  error: Nullable<Error> = null;

  winners: Nullable<AwardParticipantsList[]> = null;

  nominees: Nullable<AwardParticipantsList[]> = null;

  aloneWinner: Nullable<AwardParticipants> = null;

  nominations: Nullable<AwardNominations[]> = null;

  currentNomination: Nullable<AwardNominations> = null;

  constructor() {
    makeAutoObservable(this);
  }

  fetchNominations = async (id?: number, nominationId?: number): Promise<void> => {
    this.setError(null);
    this.setFetching(true);
    try {
      const nominations = await api.projects.projectsAwardNominationsList(id).then((res) => res.data);
      this.currentNomination = nominationId
        ? nominations.find((nomination) => nomination.id === nominationId) ?? nominations[0]
        : nominations[0];
      this.setNominations(nominations);
    } catch (e) {
      this.setError(e as Nullable<Error>);
    } finally {
      this.setFetching(false);
    }
  };

  fetchWinners = (awardId: number, nominationId: number): Promise<AwardParticipantsList[]> => {
    return api.projects
      .projectsAwardParticipantsList({ awardId, nominationId, status: 'winner' })
      .then((res) => res.data.results);
  };

  fetchNominees = (awardId: number, nominationId: number): Promise<AwardParticipantsList[]> => {
    return api.projects
      .projectsAwardParticipantsList({ awardId, nominationId, status: 'nominee' })
      .then((res) => res.data.results);
  };

  fetchWinner = (id: number): Promise<AwardParticipants> => {
    return api.projects.projectsAwardParticipantsRead({ id }).then((res) => res.data);
  };

  setFetching(value: boolean): void {
    this.fetching = value;
  }

  setError(value: Nullable<Error>): void {
    this.error = value;
  }

  setWinners(value: Nullable<AwardParticipantsList[]>): void {
    this.winners = value;
  }

  setNominees(value: Nullable<AwardParticipantsList[]>): void {
    this.nominees = value;
  }

  setNominations(value: AwardNominations[]): void {
    this.nominations = value;
  }

  setCurrentNomination = (id: number): void => {
    const currentNomination = this.nominations?.find((nomination) => nomination.id === id);
    if (currentNomination) {
      this.currentNomination = currentNomination;
    }
  };

  setWinner = (value: Nullable<AwardParticipants>): void => {
    this.aloneWinner = value;
  };

  fetchNominationData = async (nomination: AwardNominations, awardId: number) => {
    if (!nomination.id) return;
    this.setError(null);
    this.setFetching(true);

    try {
      const winners = await this.fetchWinners(awardId, nomination.id);
      const winnerId = winners?.[0]?.id;

      const winner = winnerId && nomination.isAloneWinner && (await this.fetchWinner(winnerId));
      const nominees = nomination.showNominee && (await this.fetchNominees(awardId, nomination.id));

      this.setWinners(winners && !nomination.isAloneWinner ? winners : null);
      this.setWinner(winner && nomination.isAloneWinner ? winner : null);
      this.setNominees(nominees && nomination.showNominee ? nominees : null);
    } catch (e) {
      this.setError(e as Nullable<Error>);
    } finally {
      this.setFetching(false);
    }
  };

  cleanUpNomination = (): void => {
    this.winners = null;
    this.aloneWinner = null;
    this.nominees = null;
  };

  cleanUpAll = (): void => {
    this.cleanUpNomination();
    this.error = null;
    this.fetching = false;
    this.nominations = null;
    this.currentNomination = null;
  };
}
