import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { AwardParticipants } from 'services';
import { Container } from 'components/base-ui/container';
import { Participant, Photo, TextSize } from 'components/assol-winners/WinnersAndNominees/ui/shared';
import { useDeviceState } from 'hooks/useDeviceProvider';
import { Styled } from '../styles';
import { Description } from '../Description';

export const OneParticipantContent: React.VFC<{
  participant: AwardParticipants;
}> = observer((props) => {
  const { isLessTablet } = useDeviceState();

  const hasPhoto = useMemo(() => {
    return !!props.participant?.image;
  }, [props.participant]);

  const name = useMemo(() => props.participant.items[0].name, [props.participant]);
  const company = useMemo(() => props.participant?.items[0].company?.name, [props.participant]);

  return (
    <Styled.Container>
      <Styled.Title>{props.participant.projectName}</Styled.Title>
      <Container>
        <Styled.Card>
          {hasPhoto ? (
            <Container gap="8px" direction="column">
              <Photo variant="modal" src={props.participant.image!} company={company} name={name} withDots={false} />
              {isLessTablet && <Participant size={TextSize.Default} name={name} company={company} gap="4px" />}
            </Container>
          ) : (
            <Participant size={TextSize.Default} name={name} company={company} gap="4px" />
          )}
          {props.participant.description && (
            <Description description={props.participant.description} collapsable={isLessTablet} />
          )}
        </Styled.Card>
      </Container>
    </Styled.Container>
  );
});
