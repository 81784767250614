import { action, observable } from 'mobx';
import { AxiosError, AxiosResponse } from 'axios';

export type NullableError = AxiosError | null;

export default class AbstractStore {
  fetching = false;

  error: NullableError = null;

  annotations = {
    fetching: observable,
    error: observable,
    setError: action,
    setFetching: action,
  };

  setFetching(value: boolean): void {
    this.fetching = value;
  }

  setError(value: NullableError): void {
    this.error = value;
  }

  fetchData<T>(fetchCallback: () => Promise<T>) {
    this.setError(null);
    this.setFetching(true);

    return fetchCallback()
      .catch((err: AxiosError) => this.setError(err))
      .finally(() => this.setFetching(false));
  }

  getErrorResponsePayload(): AxiosResponse | undefined {
    if (this.error) {
      return this.error.response;
    }
  }
}
