import React, { useCallback, useState } from 'react';

import { SliderItem } from 'web';

import { useLink } from 'utils/api';
import { ArrowButton } from 'components/base-ui/button';
import { HtmlRenderer } from 'utils/htmlParser';
import { ButtonWrapper, Content, Description, Div, Title } from 'components/main/slider/item/styles';
import { observer } from 'mobx-react-lite';

import { useMainPageStore } from 'store';
import { BaseModal } from 'components/base-ui/base-modal';
import { Feedback } from 'components/feedback';
import { debounce } from 'lodash';

export interface DivProps {
  active?: boolean;
}

interface ItemProps extends DivProps {
  data: SliderItem;
}

enum ActionType {
  ApplicationForm = 'application_form',
  Link = 'link',
}

const Item: React.FC<ItemProps> = (props) => {
  const mainPageStore = useMainPageStore();
  const openLink = useLink();

  const [showForm, setShowForm] = useState(false);

  const handleFormClick = useCallback(() => {
    debounce(() => setShowForm(true), 300)();
  }, []);

  const handleLinkClick = useCallback(async () => {
    if (props.data.showModal) {
      await mainPageStore.emailModalStore.fetchContent();
      mainPageStore.emailModalStore.setShow(true);
    } else {
      openLink(props.data.link.link);
    }
  }, [openLink, props.data.link.link, mainPageStore.emailModalStore.isShowing, props.data.showModal]);

  const onButtonClick = useCallback(
    (ev: React.MouseEvent<HTMLButtonElement> | React.TouchEvent<HTMLButtonElement>) => {
      ev.stopPropagation();
      if (props.data.buttonAction === ActionType.Link) {
        handleLinkClick();
      } else {
        handleFormClick();
      }
    },
    [props.data.buttonAction],
  );

  const showButton =
    (props.data.buttonAction === ActionType.Link && props.data.link.link) ||
    (props.data.buttonAction === ActionType.ApplicationForm && props.data.dynamicForm);

  return (
    <div>
      <Div active={props.active}>
        <Content>
          <Title>{props.data.title}</Title>

          <Description>
            <HtmlRenderer html={props.data.description} />
          </Description>
        </Content>

        {showButton && (
          <ButtonWrapper>
            <ArrowButton white gradient onClick={onButtonClick} onTouchEnd={onButtonClick}>
              {props.data.link.text}
            </ArrowButton>
          </ButtonWrapper>
        )}
      </Div>
      <BaseModal isVisible={showForm} onClose={() => setShowForm(false)}>
        <Feedback formId={Number(props.data.dynamicForm)} />
      </BaseModal>
    </div>
  );
};

export default observer(Item);
