import React from 'react';
import ScrollToTopOnMount from 'components/base-ui/scroll-to-top';
import { DefaultLayout } from 'layouts';
import { MedicineContent } from 'components/medicine';
import { MedicineProvider } from 'store/medicine-main';
import { ServicesNavigation } from 'components/services/shared/navigation';

export const MedicinePage: React.FC = () => {
  return (
    <>
      <ScrollToTopOnMount />
      <DefaultLayout>
        <ServicesNavigation textTransform="capitalize" white />
        <MedicineProvider>
          <MedicineContent />
        </MedicineProvider>
      </DefaultLayout>
    </>
  );
};
