import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';
import creatorsTitle from 'assets/img/creators/banner_title.svg';
import creatorsBg from 'assets/img/creators/banner_bg.png';
import creatorsBgTablet from 'assets/img/creators/banner_bg_tablet.png';
import creatorsBgMobile from 'assets/img/creators/banner_bg_mobile.png';

export const Wrapper = styled.div`
  height: 695px;
  position: relative;
  color: ${COLORS.white};
  overflow-x: hidden;

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    height: 796px;
  }
`;

export const Outer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: inherit;

  background-color: ${COLORS.gray6};
  background-image: url(${creatorsBgMobile});
  background-repeat: no-repeat;
  background-position: top 16px right 16px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    background-image: url(${creatorsBgTablet});
    background-position: top 16px right 32px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    background-position: top 70px right 125px;
    background-color: ${COLORS.white};
    background-image: url(${creatorsBg});
  }
`;

const offsetX = '-50px';
const bgWidth = '912px';

export const Inner = styled.div`
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    position: absolute;
    top: 0;
    left: 0;
    height: inherit;
    width: ${bgWidth};
  }

  &:after {
    @media screen and (min-width: ${BREAKPOINTS.laptop}) {
      content: '';
      top: 0;
      position: absolute;
      height: inherit;
      width: ${bgWidth};
      mix-blend-mode: multiply;
      background-size: auto 568px;
      background-repeat: no-repeat;
      background-position: top 0 left 0;
      background: ${COLORS.gray6};
      transform: skewX(-8deg);
      background-size: auto 896px;
      left: ${offsetX};
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 200px 16px 30px 16px;
  gap: 24px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 200px 32px 30px 32px;
    gap: 55px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding: 100px 125px 0 125px;
  }
`;

export const Header = styled.div`
  font-size: 28px;
  line-height: 32px;
  font-weight: 700;
  color: ${COLORS.secondary};

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-size: 44px;
    line-height: 48px;
  }
`;

export const Description = styled.div`
  max-height: 168px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: ${COLORS.secondary};

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    height: 96px;
    width: 533px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    height: 96px;
    width: 609px;
  }
`;

export const Title = styled.div`
  width: 288px;
  height: 51px;
  background-image: url(${creatorsTitle});
  background-size: cover;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 523px;
    height: 93px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: 652px;
    height: 116px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    flex-direction: row;
    justify-content: flex-start;
    gap: 30px;
  }

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    gap: 20px;
  }
`;

export const ButtonLink = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 100%;
  height: 48px;
  border: none;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  color: ${COLORS.white};
  background-color: #009cdd;
  cursor: pointer;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 193px;
  }

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 208px;
  }
`;

export const ButtonVideo = styled(ButtonLink)`
  background-color: #d12b5e;
`;
