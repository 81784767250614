import React, { ComponentProps } from 'react';
import RSlider from 'react-slider';
import styled from 'styled-components';

import Thumb from './thumb';
import Track from './track';

const StyledSlider = styled(RSlider)`
  width: 100%;
`;

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
  width: 100%;
`;

type SliderProps = Omit<ComponentProps<typeof RSlider>, 'renderThumb' | 'renderTrack' | 'renderMark'>;

const Slider: React.FC<SliderProps> = (props) => {
  return (
    <StyledContainer>
      <StyledSlider renderThumb={Thumb} renderTrack={Track} {...props} />
    </StyledContainer>
  );
};

export default Slider;
